import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DefaultTooltip from '../../Tooltips/DefaultTooltip';

import {
  PricingSheetRow,
  Nullable,
  IndustryName,
  ItemIndustryPercent,
} from '../../../../../shared/types';

import { percentualNumberChange } from '../../../../../shared/percentualNumberChange';

import { getSuffix } from '../../../../../shared/utils/getSuffix';
import { Typography } from '@mui/material';
import { ChangeType } from './types';

const commonRows = [
  { name: 'Rental Ready', column: 'rentalReadyPrice' },
  { name: 'Tavoitekäyttöaste', column: 'targetUtilRate' },
  { name: 'Poistoaika', column: 'depreciationPeriod' },
];

const dayRows = [
  { name: 'Hintakategoria 1 (pv)', column: 'dayPriceCoefficient1' },
  { name: 'Hintakategoria 2 (pv)', column: 'dayPriceCoefficient2' },
  { name: 'Hintakategoria 3 (pv)', column: 'dayPriceCoefficient3' },
  { name: 'Hintakategoria 4 (pv)', column: 'dayPriceCoefficient4' },
  { name: 'Hintakategoria 5 (pv)', column: 'dayPriceCoefficient5' },
  { name: 'Hintakategoria 6 (pv)', column: 'dayPriceCoefficient6' },
  { name: 'Hintakategoria 7 (pv)', column: 'dayPriceCoefficient7' },
  { name: 'Listahinta (pv)', column: 'dayPriceCoefficientList' },
];

const monthRows = [
  { name: 'Hintakategoria 1 (kk)', column: 'monthPriceCoefficient1' },
  { name: 'Hintakategoria 2 (kk)', column: 'monthPriceCoefficient2' },
  { name: 'Hintakategoria 3 (kk)', column: 'monthPriceCoefficient3' },
  { name: 'Hintakategoria 4 (kk)', column: 'monthPriceCoefficient4' },
  { name: 'Hintakategoria 5 (kk)', column: 'monthPriceCoefficient5' },
  { name: 'Hintakategoria 6 (kk)', column: 'monthPriceCoefficient6' },
  { name: 'Hintakategoria 7 (kk)', column: 'monthPriceCoefficient7' },
  { name: 'Listahinta (kk)', column: 'monthPriceCoefficientList' },
];

const dayIndustryPercentsRows = [
  { name: 'Hintakategoria 1 (pv)', column: 'dayPricePercent1' },
  { name: 'Hintakategoria 2 (pv)', column: 'dayPricePercent2' },
  { name: 'Hintakategoria 3 (pv)', column: 'dayPricePercent3' },
  { name: 'Hintakategoria 4 (pv)', column: 'dayPricePercent4' },
  { name: 'Hintakategoria 5 (pv)', column: 'dayPricePercent5' },
  { name: 'Hintakategoria 6 (pv)', column: 'dayPricePercent6' },
  { name: 'Hintakategoria 7 (pv)', column: 'dayPricePercent7' },
  { name: 'Listahinta (pv)', column: 'dayPricePercentList' },
];

const monthIndustryPercentsRows = [
  { name: 'Hintakategoria 1 (kk)', column: 'monthPricePercent1' },
  { name: 'Hintakategoria 2 (kk)', column: 'monthPricePercent2' },
  { name: 'Hintakategoria 3 (kk)', column: 'monthPricePercent3' },
  { name: 'Hintakategoria 4 (kk)', column: 'monthPricePercent4' },
  { name: 'Hintakategoria 5 (kk)', column: 'monthPricePercent5' },
  { name: 'Hintakategoria 6 (kk)', column: 'monthPricePercent6' },
  { name: 'Hintakategoria 7 (kk)', column: 'monthPricePercent7' },
  { name: 'Listahinta (kk)', column: 'monthPricePercentList' },
];

interface SummaryTableProps {
  changeType: ChangeType;

  rows: {
    name: string;
    column: string;
  }[];
  highlightedItem: Nullable<PricingSheetRow>;
  targetUtilRate: {
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  };
  depreciationPeriod: {
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  };
  rentalReadyPrice: {
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  };
  dayPriceCoefficients: any;
  monthPriceCoefficients: any;
  dayPriceIndustryPercents: any;
  monthPriceIndustryPercents: any;
  itemIndustryPercents: ItemIndustryPercent[] | undefined;
  industry: IndustryName;
}

const SummaryTable = ({
  changeType,
  rows,
  highlightedItem,
  targetUtilRate,
  depreciationPeriod,
  rentalReadyPrice,
  dayPriceCoefficients,
  monthPriceCoefficients,
  dayPriceIndustryPercents,
  monthPriceIndustryPercents,
  itemIndustryPercents,
  industry,
}: SummaryTableProps) => {
  const getAbsoluteNewValue = (column: string) => {
    switch (column) {
      case 'targetUtilRate':
        return targetUtilRate.absoluteValue
          ? `${targetUtilRate.absoluteValue} %`
          : false;
      case 'depreciationPeriod':
        return depreciationPeriod.absoluteValue
          ? `${depreciationPeriod.absoluteValue} kk`
          : false;
      case 'rentalReadyPrice':
        return rentalReadyPrice.absoluteValue
          ? `${rentalReadyPrice.absoluteValue} €`
          : false;
      case 'dayPriceCoefficient1':
      case 'dayPriceCoefficient2':
      case 'dayPriceCoefficient3':
      case 'dayPriceCoefficient4':
      case 'dayPriceCoefficient5':
      case 'dayPriceCoefficient6':
      case 'dayPriceCoefficient7':
      case 'dayPriceCoefficientList':
        return dayPriceCoefficients &&
          dayPriceCoefficients.find(
            (c: { key: string }) => c.key === column,
          )?.absoluteValue
          ? `${
              dayPriceCoefficients.find(
                (c: { key: string }) => c.key === column,
              ).absoluteValue
            }`
          : false;
      case 'monthPriceCoefficient1':
      case 'monthPriceCoefficient2':
      case 'monthPriceCoefficient3':
      case 'monthPriceCoefficient4':
      case 'monthPriceCoefficient5':
      case 'monthPriceCoefficient6':
      case 'monthPriceCoefficient7':
      case 'monthPriceCoefficientList':
        return monthPriceCoefficients &&
          monthPriceCoefficients.find(
            (c: { key: string }) => c.key === column,
          )?.absoluteValue
          ? `${
              monthPriceCoefficients.find(
                (c: { key: string }) => c.key === column,
              ).absoluteValue
            }`
          : false;
      case 'dayPricePercent1':
      case 'dayPricePercent2':
      case 'dayPricePercent3':
      case 'dayPricePercent4':
      case 'dayPricePercent5':
      case 'dayPricePercent6':
      case 'dayPricePercent7':
      case 'dayPricePercentList':
        return dayPriceIndustryPercents &&
          dayPriceIndustryPercents.find(
            (c: { key: string }) => c.key === column,
          )?.absoluteValue
          ? `${
              dayPriceIndustryPercents.find(
                (c: { key: string }) => c.key === column,
              ).absoluteValue
            } %`
          : false;
      case 'monthPricePercent1':
      case 'monthPricePercent2':
      case 'monthPricePercent3':
      case 'monthPricePercent4':
      case 'monthPricePercent5':
      case 'monthPricePercent6':
      case 'monthPricePercent7':
      case 'monthPricePercentList':
        return monthPriceIndustryPercents &&
          monthPriceIndustryPercents.find(
            (c: { key: string }) => c.key === column,
          )?.absoluteValue
          ? `${
              monthPriceIndustryPercents.find(
                (c: { key: string }) => c.key === column,
              ).absoluteValue
            } %`
          : false;
    }
  };

  const getPercentualNewValue = (column: string) => {
    switch (column) {
      case 'targetUtilRate':
        return targetUtilRate.percentValue
          ? `${targetUtilRate.percentValue} %`
          : false;
      case 'depreciationPeriod':
        return depreciationPeriod.percentValue
          ? `${depreciationPeriod.percentValue} %`
          : false;
      case 'rentalReadyPrice':
        return rentalReadyPrice.percentValue
          ? `${rentalReadyPrice.percentValue} %`
          : false;
      case 'dayPriceCoefficient1':
      case 'dayPriceCoefficient2':
      case 'dayPriceCoefficient3':
      case 'dayPriceCoefficient4':
      case 'dayPriceCoefficient5':
      case 'dayPriceCoefficient6':
      case 'dayPriceCoefficient7':
      case 'dayPriceCoefficientList':
        return dayPriceCoefficients &&
          dayPriceCoefficients.find(
            (c: { key: string }) => c.key === column,
          )?.percentValue
          ? `${
              dayPriceCoefficients.find(
                (c: { key: string }) => c.key === column,
              ).percentValue
            } %`
          : false;
      case 'monthPriceCoefficient1':
      case 'monthPriceCoefficient2':
      case 'monthPriceCoefficient3':
      case 'monthPriceCoefficient4':
      case 'monthPriceCoefficient5':
      case 'monthPriceCoefficient6':
      case 'monthPriceCoefficient7':
      case 'monthPriceCoefficientList':
        return monthPriceCoefficients &&
          monthPriceCoefficients.find(
            (c: { key: string }) => c.key === column,
          )?.absoluteValue
          ? `${
              monthPriceCoefficients.find(
                (c: { key: string }) => c.key === column,
              ).absoluteValue
            }`
          : false;
      case 'dayPricePercent1':
      case 'dayPricePercent2':
      case 'dayPricePercent3':
      case 'dayPricePercent4':
      case 'dayPricePercent5':
      case 'dayPricePercent6':
      case 'dayPricePercent7':
      case 'dayPricePercentList':
        return dayPriceIndustryPercents &&
          dayPriceIndustryPercents.find(
            (c: { key: string }) => c.key === column,
          )?.percentValue
          ? `${
              dayPriceIndustryPercents.find(
                (c: { key: string }) => c.key === column,
              ).percentValue
            } %`
          : false;
      case 'monthPricePercent1':
      case 'monthPricePercent2':
      case 'monthPricePercent3':
      case 'monthPricePercent4':
      case 'monthPricePercent5':
      case 'monthPricePercent6':
      case 'monthPricePercent7':
      case 'monthPricePercentList':
        return monthPriceIndustryPercents &&
          monthPriceIndustryPercents.find(
            (c: { key: string }) => c.key === column,
          )?.percentValue
          ? `${
              monthPriceIndustryPercents.find(
                (c: { key: string }) => c.key === column,
              ).percentValue
            } %`
          : false;
    }
  };

  const getPercentualCalculatedValue = (column: string) => {
    // for industryPercents
    const itemToLookup =
      highlightedItem &&
      itemIndustryPercents?.find(
        (i) =>
          i.catClass === highlightedItem.catClass &&
          i.industry.name === industry,
      );

    switch (column) {
      case 'targetUtilRate':
        return highlightedItem && targetUtilRate.percentValue
          ? `${percentualNumberChange(
              Number(highlightedItem?.targetUtilRate),
              rentalReadyPrice.percentValue,
            )} %`
          : false;
      case 'depreciationPeriod':
        return highlightedItem && depreciationPeriod.percentValue
          ? `${percentualNumberChange(
              Number(highlightedItem?.depreciationPeriod),
              depreciationPeriod.percentValue,
            )} kk`
          : false;
      case 'rentalReadyPrice':
        return highlightedItem && rentalReadyPrice.percentValue
          ? `${percentualNumberChange(
              Number(highlightedItem?.rentalReadyPrice),
              rentalReadyPrice.percentValue,
            )} €`
          : false;
      case 'dayPriceCoefficient1':
      case 'dayPriceCoefficient2':
      case 'dayPriceCoefficient3':
      case 'dayPriceCoefficient4':
      case 'dayPriceCoefficient5':
      case 'dayPriceCoefficient6':
      case 'dayPriceCoefficient7':
      case 'dayPriceCoefficientList':
        const dayCoefficient = highlightedItem
          ? percentualNumberChange(
              Number(highlightedItem[column]),
              dayPriceCoefficients &&
                dayPriceCoefficients.find(
                  (c: { key: string }) => c.key === column,
                )?.percentValue,
            )
          : null;
        return highlightedItem &&
          dayPriceCoefficients &&
          dayPriceCoefficients.find(
            (c: { key: string }) => c.key === column,
          )?.percentValue
          ? `${dayCoefficient ? dayCoefficient / 100 : null}`
          : false;
      case 'monthPriceCoefficient1':
      case 'monthPriceCoefficient2':
      case 'monthPriceCoefficient3':
      case 'monthPriceCoefficient4':
      case 'monthPriceCoefficient5':
      case 'monthPriceCoefficient6':
      case 'monthPriceCoefficient7':
      case 'monthPriceCoefficientList':
        const monthCoefficient = highlightedItem
          ? percentualNumberChange(
              Number(highlightedItem[column]),
              monthPriceCoefficients &&
                dayPriceCoefficients.find(
                  (c: { key: string }) => c.key === column,
                )?.percentValue,
            )
          : null;
        return highlightedItem &&
          monthPriceCoefficients &&
          monthPriceCoefficients.find(
            (c: { key: string }) => c.key === column,
          )?.percentValue
          ? `${monthCoefficient ? monthCoefficient / 100 : null}`
          : false;
      case 'dayPricePercent1':
      case 'dayPricePercent2':
      case 'dayPricePercent3':
      case 'dayPricePercent4':
      case 'dayPricePercent5':
      case 'dayPricePercent6':
      case 'dayPricePercent7':
      case 'dayPricePercentList':
        const newDayPercent =
          dayPriceIndustryPercents &&
          dayPriceIndustryPercents.find(
            (c: { key: string }) => c.key === column,
          )?.percentValue
            ? `${
                dayPriceIndustryPercents.find(
                  (c: { key: string }) => c.key === column,
                ).percentValue
              } `
            : false;

        const currentDayPercent =
          itemToLookup &&
          itemToLookup[column as keyof PricingSheetRow]
            ? Number(itemToLookup[column as keyof PricingSheetRow]) /
              100
            : null;
        const newCalculatedDayValue =
          typeof currentDayPercent === 'number' && highlightedItem
            ? percentualNumberChange(
                currentDayPercent,
                Number(newDayPercent),
              )
            : null;
        return newCalculatedDayValue && newDayPercent
          ? `${
              newCalculatedDayValue
                ? newCalculatedDayValue + ' %'
                : null
            }`
          : false;
      case 'monthPricePercent1':
      case 'monthPricePercent2':
      case 'monthPricePercent3':
      case 'monthPricePercent4':
      case 'monthPricePercent5':
      case 'monthPricePercent6':
      case 'monthPricePercent7':
      case 'monthPricePercentList':
        const newMonthPercent =
          monthPriceIndustryPercents &&
          monthPriceIndustryPercents.find(
            (c: { key: string }) => c.key === column,
          )?.percentValue
            ? `${
                monthPriceIndustryPercents.find(
                  (c: { key: string }) => c.key === column,
                ).percentValue
              } `
            : false;

        const currentMonthPercent =
          itemToLookup &&
          itemToLookup[column as keyof PricingSheetRow]
            ? Number(itemToLookup[column as keyof PricingSheetRow]) /
              100
            : null;
        const newCalculatedMonthValue =
          typeof currentMonthPercent === 'number' && highlightedItem
            ? percentualNumberChange(
                currentMonthPercent,
                Number(newMonthPercent),
              )
            : null;
        return newCalculatedMonthValue && newMonthPercent
          ? `${
              newCalculatedMonthValue
                ? newCalculatedMonthValue + ' %'
                : null
            }`
          : false;
    }
  };

  const getNewValue = (column: string) => {
    if (changeType === 'ABSOLUTE') {
      switch (column) {
        case 'targetUtilRate':
          return targetUtilRate.absoluteValue
            ? `${targetUtilRate.absoluteValue} %`
            : null;

        case 'depreciationPeriod':
          return depreciationPeriod.absoluteValue
            ? `${depreciationPeriod.absoluteValue} kk`
            : null;

        case 'rentalReadyPrice':
          return rentalReadyPrice.absoluteValue
            ? `${rentalReadyPrice.absoluteValue} €`
            : null;
        case 'dayPriceCoefficient1':
        case 'dayPriceCoefficient2':
        case 'dayPriceCoefficient3':
        case 'dayPriceCoefficient4':
        case 'dayPriceCoefficient5':
        case 'dayPriceCoefficient6':
        case 'dayPriceCoefficient7':
        case 'dayPriceCoefficientList':
        case 'monthPriceCoefficient1':
        case 'monthPriceCoefficient2':
        case 'monthPriceCoefficient3':
        case 'monthPriceCoefficient4':
        case 'monthPriceCoefficient5':
        case 'monthPriceCoefficient6':
        case 'monthPriceCoefficient7':
        case 'monthPriceCoefficientList':
          return getAbsoluteNewValue(column)
            ? `${getAbsoluteNewValue(column)}`
            : null;
        case 'dayPricePercent1':
        case 'dayPricePercent2':
        case 'dayPricePercent3':
        case 'dayPricePercent4':
        case 'dayPricePercent5':
        case 'dayPricePercent6':
        case 'dayPricePercent7':
        case 'dayPricePercentList':
        case 'monthPricePercent1':
        case 'monthPricePercent2':
        case 'monthPricePercent3':
        case 'monthPricePercent4':
        case 'monthPricePercent5':
        case 'monthPricePercent6':
        case 'monthPricePercent7':
        case 'monthPricePercentList':
          return getAbsoluteNewValue(column)
            ? `${getAbsoluteNewValue(column)}`
            : null;
      }
    }
  };

  const getOldValue = (highlightedItem: any, column: string) => {
    switch (column) {
      case 'targetUtilRate':
        return highlightedItem[column as keyof PricingSheetRow];
      case 'depreciationPeriod':
        return highlightedItem[column as keyof PricingSheetRow];
      case 'rentalReadyPrice':
        return highlightedItem[column as keyof PricingSheetRow];
      case 'dayPriceCoefficient1':
      case 'dayPriceCoefficient2':
      case 'dayPriceCoefficient3':
      case 'dayPriceCoefficient4':
      case 'dayPriceCoefficient5':
      case 'dayPriceCoefficient6':
      case 'dayPriceCoefficient7':
      case 'dayPriceCoefficientList':
      case 'monthPriceCoefficient1':
      case 'monthPriceCoefficient2':
      case 'monthPriceCoefficient3':
      case 'monthPriceCoefficient4':
      case 'monthPriceCoefficient5':
      case 'monthPriceCoefficient6':
      case 'monthPriceCoefficient7':
      case 'monthPriceCoefficientList':
        return highlightedItem[column as keyof PricingSheetRow] / 100;
      case 'dayPricePercent1':
      case 'dayPricePercent2':
      case 'dayPricePercent3':
      case 'dayPricePercent4':
      case 'dayPricePercent5':
      case 'dayPricePercent6':
      case 'dayPricePercent7':
      case 'dayPricePercentList':
      case 'monthPricePercent1':
      case 'monthPricePercent2':
      case 'monthPricePercent3':
      case 'monthPricePercent4':
      case 'monthPricePercent5':
      case 'monthPricePercent6':
      case 'monthPricePercent7':
      case 'monthPricePercentList':
        const item = itemIndustryPercents?.find(
          (i) =>
            i.catClass === highlightedItem.catClass &&
            i.industry.name === industry,
        );
        return item && item[column as keyof PricingSheetRow]
          ? Number(item[column as keyof PricingSheetRow]) / 100
          : '-';
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 460 }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">Vanha arvo</TableCell>
            <TableCell align="right">
              <DefaultTooltip
                title={`Syötetty muutos`}
                placement="top"
              >
                <>Muutos ?</>
              </DefaultTooltip>
            </TableCell>
            <TableCell align="right">Uusi arvo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.column}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                <b>{row.name}</b>
              </TableCell>
              <TableCell align="right">
                {highlightedItem
                  ? `${getOldValue(
                      highlightedItem,
                      row.column,
                    )} ${getSuffix(row.column)}`
                  : '?'}
              </TableCell>
              <TableCell>
                <Box
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <>
                    {getNewValue(row.column) &&
                      changeType === 'ABSOLUTE' && (
                        <>{getNewValue(row.column)}</>
                      )}
                    {getPercentualNewValue(row.column) &&
                      changeType === 'PERCENT' && (
                        <>{getPercentualNewValue(row.column)}</>
                      )}
                  </>
                </Box>
              </TableCell>
              <TableCell align="right">
                <>
                  {getNewValue(row.column) &&
                    changeType === 'ABSOLUTE' && (
                      <>{getNewValue(row.column)}</>
                    )}
                  {getPercentualCalculatedValue(row.column) &&
                    changeType === 'PERCENT' && (
                      <>{getPercentualCalculatedValue(row.column)}</>
                    )}
                </>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface ItemsEditSummaryTableProps {
  changeTypeCommon: ChangeType;
  changeTypeCoefficients: ChangeType;
  changeTypeIndustryPercents: ChangeType;
  highlightedItem: Nullable<PricingSheetRow>;
  targetUtilRate: {
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  };
  depreciationPeriod: {
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  };
  rentalReadyPrice: {
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  };
  dayPriceCoefficients: {
    key: string;
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  }[];
  monthPriceCoefficients: {
    key: string;
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  }[];
  dayPriceIndustryPercents: {
    key: string;
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  }[];
  monthPriceIndustryPercents: {
    key: string;
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  }[];
  itemIndustryPercents: ItemIndustryPercent[] | undefined;
  industry: IndustryName;
}

const ItemsEditSummaryTable = ({
  changeTypeCommon,
  changeTypeCoefficients,
  changeTypeIndustryPercents,
  highlightedItem,
  targetUtilRate,
  depreciationPeriod,
  rentalReadyPrice,
  dayPriceCoefficients,
  monthPriceCoefficients,
  dayPriceIndustryPercents,
  monthPriceIndustryPercents,
  itemIndustryPercents,
  industry,
}: ItemsEditSummaryTableProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Typography>Yleinen</Typography>

          <SummaryTable
            changeType={changeTypeCommon}
            rows={commonRows}
            highlightedItem={highlightedItem}
            targetUtilRate={targetUtilRate}
            depreciationPeriod={depreciationPeriod}
            rentalReadyPrice={rentalReadyPrice}
            dayPriceCoefficients={dayPriceCoefficients}
            monthPriceCoefficients={monthPriceCoefficients}
            dayPriceIndustryPercents={dayPriceIndustryPercents}
            monthPriceIndustryPercents={monthPriceIndustryPercents}
            itemIndustryPercents={itemIndustryPercents}
            industry={industry}
          />
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Typography>PV-kertoimet</Typography>

          <SummaryTable
            changeType={changeTypeCoefficients}
            rows={dayRows}
            highlightedItem={highlightedItem}
            targetUtilRate={targetUtilRate}
            depreciationPeriod={depreciationPeriod}
            rentalReadyPrice={rentalReadyPrice}
            dayPriceCoefficients={dayPriceCoefficients}
            monthPriceCoefficients={monthPriceCoefficients}
            dayPriceIndustryPercents={dayPriceIndustryPercents}
            monthPriceIndustryPercents={monthPriceIndustryPercents}
            itemIndustryPercents={itemIndustryPercents}
            industry={industry}
          />
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Typography>KK-kertoimet</Typography>

          <SummaryTable
            changeType={changeTypeCoefficients}
            rows={monthRows}
            highlightedItem={highlightedItem}
            targetUtilRate={targetUtilRate}
            depreciationPeriod={depreciationPeriod}
            rentalReadyPrice={rentalReadyPrice}
            dayPriceCoefficients={dayPriceCoefficients}
            monthPriceCoefficients={monthPriceCoefficients}
            dayPriceIndustryPercents={dayPriceIndustryPercents}
            monthPriceIndustryPercents={monthPriceIndustryPercents}
            itemIndustryPercents={itemIndustryPercents}
            industry={industry}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'flex-end',
        }}
      >
        <Box width={'100%'} />
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Typography>PV-toimialamuutosprosentit</Typography>
          <SummaryTable
            changeType={changeTypeIndustryPercents}
            rows={dayIndustryPercentsRows}
            highlightedItem={highlightedItem}
            targetUtilRate={targetUtilRate}
            depreciationPeriod={depreciationPeriod}
            rentalReadyPrice={rentalReadyPrice}
            dayPriceCoefficients={dayPriceCoefficients}
            monthPriceCoefficients={monthPriceCoefficients}
            dayPriceIndustryPercents={dayPriceIndustryPercents}
            monthPriceIndustryPercents={monthPriceIndustryPercents}
            itemIndustryPercents={itemIndustryPercents}
            industry={industry}
          />
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Typography>KK-toimialamuutosprosentit</Typography>
          <SummaryTable
            changeType={changeTypeIndustryPercents}
            rows={monthIndustryPercentsRows}
            highlightedItem={highlightedItem}
            targetUtilRate={targetUtilRate}
            depreciationPeriod={depreciationPeriod}
            rentalReadyPrice={rentalReadyPrice}
            dayPriceCoefficients={dayPriceCoefficients}
            monthPriceCoefficients={monthPriceCoefficients}
            dayPriceIndustryPercents={dayPriceIndustryPercents}
            monthPriceIndustryPercents={monthPriceIndustryPercents}
            itemIndustryPercents={itemIndustryPercents}
            industry={industry}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ItemsEditSummaryTable;
