import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

import TextField from '@mui/material/TextField';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { CoefficientState } from '../EditPricesDialog';

interface EditDiscountPercentProps {
  coefficientState: CoefficientState;
  setCoefficientState: Dispatch<SetStateAction<CoefficientState>>;
}

const EditCoefficient = ({
  coefficientState,
  setCoefficientState,
}: EditDiscountPercentProps) => {
  const [basis, setBasis] = useState(coefficientState.basis);

  const handleBasisChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const newBasis = (event.target as HTMLInputElement)
      .value as CoefficientState['basis'];
    setBasis(newBasis);

    setCoefficientState((state) => ({
      ...state,
      basis: newBasis,
    }));
  };

  const handleValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = Number(event.target.value);

    setCoefficientState((state) => ({
      ...state,
      value: newValue,
    }));
  };

  return (
    <>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Käytä laskennan perusteena:</FormLabel>
        <RadioGroup row value={basis} onChange={handleBasisChange}>
          <FormControlLabel
            value={'DAY'}
            control={<Radio />}
            label="Päivähintaa"
          />
          <FormControlLabel
            value={'MONTH'}
            control={<Radio />}
            label="Kuukausihintaa"
          />
        </RadioGroup>
      </FormControl>
      <TextField
        sx={{ mt: 2 }}
        size="small"
        id="percent-change"
        label="PV->KK kerroin"
        type="number"
        value={coefficientState.value || ''}
        onKeyDown={(event) => {
          // prevent negative numbers
          if (event.key === '-') {
            event.preventDefault();
          }
        }}
        onChange={handleValueChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          min: '1',
        }}
      />
    </>
  );
};

export default EditCoefficient;
