import React, { useContext, useState, useEffect } from 'react';

import { TextField } from '@mui/material';
import { Box } from '@mui/system';

import BackgroundProvider from '../../../../providers/Background/BackgroundProvider';
import PricingProvider from '../../../../providers/Pricing/PricingProvider';

import FormControl from '@mui/material/FormControl';

import NumberFormat from 'react-number-format';
import { currencyMultiplier } from '../../../../../../shared/constants';

interface RentalPotentialFormProps {
  changeValuesEnabled: boolean;
}
const RentalPotentialForm: React.FC<RentalPotentialFormProps> = ({
  changeValuesEnabled,
}) => {
  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );
  const { pricingSheetId } = useContext(PricingProvider);

  const [value, setValue] = useState(
    backgroundInfo?.potential?.top
      ? backgroundInfo?.potential?.top
      : null,
  );

  useEffect(() => {
    setValue(backgroundInfo?.potential?.top);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundInfo]);

  const handleMaxInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const trimmed =
      Number(
        event.target.value
          .substring(0, event.target.value.length - 1)
          .replace(/\s/g, ''),
      ) / 5000;

    const newBottom =
      (backgroundInfo?.commitment / 100) * trimmed
        ? (backgroundInfo?.commitment / 100) * trimmed
        : backgroundInfo?.bottom;

    const newCommitment = Math.floor(backgroundInfo?.commitment)
      ? Math.floor(backgroundInfo?.commitment)
      : backgroundInfo?.commitment;
    setValue(Number(trimmed.toPrecision(3)));
    setBackgroundInfo({
      ...backgroundInfo,
      commitment: newCommitment,
      potential: {
        bottom: isNaN(newBottom) ? 0 : newBottom,
        top: Number(trimmed.toPrecision(3)),
      },
      potentialOver100k: trimmed > 200 ? true : false,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <FormControl
        focused
        className="rental-max-potential"
        variant="outlined"
      >
        <NumberFormat
          customInput={TextField}
          thousandSeparator={' '}
          value={Math.floor(value * currencyMultiplier)}
          onChange={handleMaxInputChange}
          autoComplete="off"
          suffix={' €'}
          allowedDecimalSeparators={['.', ',']}
          disabled={
            pricingSheetId && !changeValuesEnabled ? true : false
          }
        />
      </FormControl>
    </Box>
  );
};

export default RentalPotentialForm;
