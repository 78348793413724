import { ValueGetterParams } from 'ag-grid-community';

const dayPricePercentGetter = (
  params: ValueGetterParams,
  comparisonDayPrice: number | null | undefined,
) => {
  const dayPrice = params.data?.dayPrice;

  const comparisonDayPricePercent =
    comparisonDayPrice && dayPrice
      ? (((comparisonDayPrice - dayPrice) / dayPrice) * 100).toFixed(
          2,
        )
      : null;

  return comparisonDayPricePercent;
};

export default dayPricePercentGetter;
