import React, { useContext, useState } from 'react';

import { Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { Box } from '@mui/system';

import BackgroundProvider from '../../../../providers/Background/BackgroundProvider';
import PricingProvider from '../../../../providers/Pricing/PricingProvider';

interface CommitmentSliderProps {
  initial: number;
  changeValuesEnabled: boolean;
}
const CommitmentSlider: React.FC<CommitmentSliderProps> = ({
  initial,
  changeValuesEnabled,
}) => {
  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );

  const { pricingSheetId } = useContext(PricingProvider);

  const [value, setValue] = useState(
    backgroundInfo?.commitment ? backgroundInfo?.commitment : initial,
  );

  const handleChange = (_: Event, value: number | number[]) => {
    setValue(value as number[]);
    setBackgroundInfo({
      ...backgroundInfo,
      commitment: value,
      potential: {
        bottom:
          ((value as number) / 100) * backgroundInfo?.potential?.top,
        top: Math.floor(backgroundInfo?.potential?.top),
      },
    });
  };

  return (
    <Box display="flex">
      <Box width={300}>
        <Slider
          aria-label="commitment"
          value={value !== undefined ? value : initial}
          defaultValue={initial}
          onChange={handleChange}
          valueLabelDisplay={'auto'}
          valueLabelFormat={`${value} %`}
          min={0}
          max={100}
          disabled={
            pricingSheetId && !changeValuesEnabled ? true : false
          }
        />
      </Box>

      <Box sx={{ marginLeft: 2 }} display="flex" alignItems="center">
        <Typography color="#202020">{`${value} %`}</Typography>
      </Box>
    </Box>
  );
};

export default CommitmentSlider;
