import { PRICE_FACTOR } from '../../../../../shared/constants';
import {
  CriticalEquipmentRowKeys,
  PricingSheetRow,
  SurplusEquipmentRowKeys,
} from '../../../../../shared/types';

type NewRowFieldType =
  | keyof PricingSheetRow
  | CriticalEquipmentRowKeys
  | SurplusEquipmentRowKeys
  | 'discountDay'
  | 'discountMonth'
  | 'priceThreshold'
  | string;

const constructNewRow = (
  field: NewRowFieldType,
  row: PricingSheetRow,
  value: any,
  itemIndustryPercentRow?: any,
) => {
  switch (field) {
    case 'rentalReadyPrice':
      return {
        ...row,
        rentalReadyPrice: value,
      } as PricingSheetRow;
    case 'targetUtilRate':
      return {
        ...row,
        targetUtilRate: typeof value === 'number' ? value : null,
      } as PricingSheetRow;
    case 'depreciationPeriod':
      return {
        ...row,
        depreciationPeriod:
          typeof value === 'number' ? Math.round(value) : null,
      } as PricingSheetRow;
    case 'dayPriceCoefficient1':
      return {
        ...row,
        dayPriceCoefficient1:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'dayPriceCoefficient2':
      return {
        ...row,
        dayPriceCoefficient2:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'dayPriceCoefficient3':
      return {
        ...row,
        dayPriceCoefficient3:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'dayPriceCoefficient4':
      return {
        ...row,
        dayPriceCoefficient4:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'dayPriceCoefficient5':
      return {
        ...row,
        dayPriceCoefficient5:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'dayPriceCoefficient6':
      return {
        ...row,
        dayPriceCoefficient6:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'dayPriceCoefficient7':
      return {
        ...row,
        dayPriceCoefficient7:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'dayPriceCoefficientList':
      return {
        ...row,
        dayPriceCoefficientList:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'monthPriceCoefficient1':
      return {
        ...row,
        monthPriceCoefficient1:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'monthPriceCoefficient2':
      return {
        ...row,
        monthPriceCoefficient2:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'monthPriceCoefficient3':
      return {
        ...row,
        monthPriceCoefficient3:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'monthPriceCoefficient4':
      return {
        ...row,
        monthPriceCoefficient4:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'monthPriceCoefficient5':
      return {
        ...row,
        monthPriceCoefficient5:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'monthPriceCoefficient6':
      return {
        ...row,
        monthPriceCoefficient6:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'monthPriceCoefficient7':
      return {
        ...row,
        monthPriceCoefficient7:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'monthPriceCoefficientList':
      return {
        ...row,
        monthPriceCoefficientList:
          typeof value === 'number' ? value * 100 : null,
      } as PricingSheetRow;
    case 'dayPrice':
      return {
        ...row,
        dayPrice: typeof value === 'number' ? value * 1000000 : null,
      } as PricingSheetRow;
    case 'monthPrice':
      return {
        ...row,
        monthPrice:
          typeof value === 'number' ? value * 1000000 : null,
      } as PricingSheetRow;
    case 'discountDay':
      const newDayPrice =
        typeof value === 'number' && row?.proposalDayPrice
          ? (1 + Number(value / 100)) * row?.proposalDayPrice
          : null;
      return {
        ...row,
        dayPrice: newDayPrice,
      } as PricingSheetRow;
    case 'discountMonth':
      const newMonthPrice =
        typeof value === 'number' && row?.proposalMonthPrice
          ? (1 + Number(value / 100)) * row?.proposalMonthPrice
          : null;
      return {
        ...row,
        monthPrice: newMonthPrice,
      } as PricingSheetRow;
    case 'discount':
      let newDiscount = null;
      if (typeof value === 'number') {
        const priceToApply =
          row.pricingBasis === 'MONTH'
            ? row?.proposalMonthPrice
            : row?.proposalDayPrice;

        if (priceToApply) {
          newDiscount = Math.round(
            (1 + Number(value / 100)) * priceToApply,
          );
        }
      }
      return {
        ...row,
        monthPrice:
          row.pricingBasis === 'MONTH' ? newDiscount : row.monthPrice,
        dayPrice:
          row.pricingBasis === 'DAY' ? newDiscount : row.dayPrice,
      } as PricingSheetRow;
    case 'priceThreshold':
      return {
        ...row,
        monthPrice:
          value && row?.dayPrice
            ? Number(value) * row?.dayPrice
            : null,
      } as PricingSheetRow;
    // handle surplus equipment fields
    case 'changePercentage': {
      let updatedSurplusEquipmentItem = {
        ...row.surplusEquipmentItem,
        changePercentage: value,
      };
      if (!row.surplusEquipmentItem) {
        updatedSurplusEquipmentItem.surplusFrom =
          new Date().toISOString();
      }
      const updatedRow = {
        ...row,
        surplusEquipmentItem: updatedSurplusEquipmentItem,
      };
      return updatedRow as PricingSheetRow;
    }
    case 'surplusFrom': {
      let updatedSurplusEquipmentItem = {
        ...(row.surplusEquipmentItem || {}),
        surplusFrom: value,
      };
      if (!row.surplusEquipmentItem) {
        updatedSurplusEquipmentItem.changePercentage = '0';
        updatedSurplusEquipmentItem.surplusTo = null;
      }

      const updatedRow = {
        ...row,
        surplusEquipmentItem: updatedSurplusEquipmentItem,
      };
      return updatedRow as PricingSheetRow;
    }
    case 'surplusTo': {
      let updatedSurplusEquipmentItem = {
        ...(row.surplusEquipmentItem || {}),
        surplusTo: value,
      };
      if (!row.surplusEquipmentItem) {
        updatedSurplusEquipmentItem.changePercentage = '0';
        updatedSurplusEquipmentItem.surplusFrom =
          new Date().toISOString() <=
          new Date(
            updatedSurplusEquipmentItem.surplusTo,
          ).toISOString()
            ? new Date().toISOString()
            : updatedSurplusEquipmentItem.surplusTo;
      }

      const updatedRow = {
        ...row,
        surplusEquipmentItem: updatedSurplusEquipmentItem,
      };
      return updatedRow as PricingSheetRow;
    }
    // handle critical equipment fields
    case 'minPriceDay':
    case 'minPriceMonth':
    case 'from':
    case 'to':
      return {
        ...row,
        criticalEquipmentItems: [
          {
            ...(row.criticalEquipmentItems[0] || {}),
            [field]:
              value instanceof Date ? value.toISOString() : value,
          },
        ],
      } as PricingSheetRow;
    case 'dayPricePercent1':
    case 'dayPricePercent2':
    case 'dayPricePercent3':
    case 'dayPricePercent4':
    case 'dayPricePercent5':
    case 'dayPricePercent6':
    case 'dayPricePercent7':
    case 'dayPricePercentList':
    case 'monthPricePercent1':
    case 'monthPricePercent2':
    case 'monthPricePercent3':
    case 'monthPricePercent4':
    case 'monthPricePercent5':
    case 'monthPricePercent6':
    case 'monthPricePercent7':
    case 'monthPricePercentList':
      const newRow = {
        ...itemIndustryPercentRow,
        field: value,
      };
      return newRow;
    case 'kitName':
      return {
        ...row,
        [field]: value,
      };
    case 'quantity':
    case 'comment':
    case 'leasePeriodStart':
    case 'leasePeriodEnd':
    case 'pricingBasis':
    case 'avgRentalPeriod':
    case 'unit':
      return {
        ...row,
        [field]: value,
      };
    case 'ramiturvaDayPrice':
    case 'ramiturvaMonthPrice':
      return {
        ...row,
        [field]:
          typeof value === 'number' ? value * PRICE_FACTOR : null,
      };
    default:
      console.error('Not found...');
  }
};

export default constructNewRow;
