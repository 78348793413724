import List from '@mui/material/List';
import { PricingSheetRow } from '../../../../shared/types';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

type SelectedItemsListBaseProps<T> = {
  selectedItems: T[];
  primaryText: keyof T;
  maxDisplayedItems?: number;
  secondaryText: keyof T;
};
type NonClickableSelectedItemsListProps<T> =
  SelectedItemsListBaseProps<T> & {
    onClick: undefined;
  };
type ClickableSelectedItemsListProps<T> =
  SelectedItemsListBaseProps<T> & {
    onClick?: (item: T) => void;
    selected?: (item: T) => boolean;
  };
type SelectedItemsListProps<T> =
  | ClickableSelectedItemsListProps<T>
  | NonClickableSelectedItemsListProps<T>;

const SelectedItemsList = <T extends PricingSheetRow>(
  props: SelectedItemsListProps<T>,
) => {
  const {
    maxDisplayedItems,
    primaryText,
    secondaryText,
    selectedItems = [],
  } = props;
  return (
    <Container
      itemCount={selectedItems.length}
      maxDisplayedItems={maxDisplayedItems}
    >
      <List dense>
        {selectedItems.map((item) => (
          <Item key={item.catClass}>
            {props.onClick ? (
              <ClickableItem item={item} props={props}>
                <ItemText
                  primary={item[primaryText]}
                  secondary={item[secondaryText]}
                />
              </ClickableItem>
            ) : (
              <ItemText
                primary={item[primaryText]}
                secondary={item[secondaryText]}
              />
            )}
          </Item>
        ))}
      </List>
    </Container>
  );
};

type ClickableItemProps<T> = PropsWithChildren<{
  item: T;
  props: ClickableSelectedItemsListProps<T>;
}>;
const ClickableItem = <T,>({
  children,
  item,
  props: { onClick = () => null, selected = () => false },
}: ClickableItemProps<T>) => (
  <ListItemButton
    selected={selected(item)}
    onClick={() => onClick(item)}
  >
    {children}
  </ListItemButton>
);

type ContainerProps = {
  itemCount: number;
  maxDisplayedItems?: number;
};
const Container = styled(Box, {
  // don't forward props that are used for styling
  shouldForwardProp: isPropValid,
})<ContainerProps>(({ itemCount, maxDisplayedItems = 5 }) => {
  const height =
    itemCount <= maxDisplayedItems
      ? itemCount * 30 + 16 // 16 is the combined top and bottom padding of the list
      : maxDisplayedItems * 30 + 16;
  return {
    border: '1px solid gainsboro',
    height,
    minHeight: '46px',
    maxHeight: '400px',
    marginBottom: '1rem',
    overflow: 'auto',
    resize: itemCount > maxDisplayedItems ? 'vertical' : 'none',
  };
});

const Item = styled(ListItem)`
  height: 30px;
  padding: 0;
`;

const ItemText = styled(ListItemText)`
  display: inline-grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-template-columns: 50px 1fr;
  justify-content: start;
  margin: 0;
`;

export default SelectedItemsList;
