import React from 'react';
import Box from '@mui/material/Box';

import {
  ExternalFilter,
  ExternalFilterName,
  ExternalFilterType,
} from '../../hooks/useFilters';
import PricingRowCheckBoxFilter from '../../components/Filters/PricingRowCheckBoxFilter';
import { Divider } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Switch } from '@mui/material';

interface AdminFiltersProps {
  toggleActiveFilter: (filter: ExternalFilter) => void;
  isChecked: (filter: ExternalFilter) => boolean;
}

const AdminFilters: React.FC<AdminFiltersProps> = ({
  toggleActiveFilter,
  isChecked,
}) => {
  return (
    <>
      <Box sx={{ ml: 2 }}>
        <PricingRowCheckBoxFilter
          label="Näytä vain hinnoiteltavat"
          checked={isChecked}
          handleChecked={toggleActiveFilter}
          filterType={{
            name: ExternalFilterName.ShowOnlyInPricingRows,
            type: ExternalFilterType.AND,
          }}
        />
        <PricingRowCheckBoxFilter
          label="Puuttuva hinnoitteluparametri"
          checked={isChecked}
          handleChecked={toggleActiveFilter}
          filterType={{
            name: ExternalFilterName.ShowOnlyPricingParameterMissingRows,
            type: ExternalFilterType.AND,
          }}
        />

        <PricingRowCheckBoxFilter
          label="Tuote puuttuu PIM:stä"
          checked={isChecked}
          handleChecked={toggleActiveFilter}
          filterType={{
            name: ExternalFilterName.ShowItemIsNotInPim,
            type: ExternalFilterType.AND,
          }}
        />
        <PricingRowCheckBoxFilter
          label="Tuote on PIM:ssä"
          checked={isChecked}
          handleChecked={toggleActiveFilter}
          filterType={{
            name: ExternalFilterName.ShowItemIsInPim,
            type: ExternalFilterType.AND,
          }}
        />
        <PricingRowCheckBoxFilter
          label="Kriittisen kaluston tuote"
          checked={isChecked}
          handleChecked={toggleActiveFilter}
          filterType={{
            name: ExternalFilterName.ShowCriticalEquipment,
            type: ExternalFilterType.AND,
          }}
        />
        <PricingRowCheckBoxFilter
          label="Ramigreen -tuote"
          checked={isChecked}
          handleChecked={toggleActiveFilter}
          filterType={{
            name: ExternalFilterName.ShowRamiGreen,
            type: ExternalFilterType.AND,
          }}
        />
        <Divider />
        <Box sx={{ mb: 2, ml: 1, mt: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={
                  !isChecked({
                    name: ExternalFilterName.ShowRemovedItems,
                    type: ExternalFilterType.AND,
                  })
                }
                onChange={() => {
                  toggleActiveFilter({
                    name: ExternalFilterName.ShowRemovedItems,
                    type: ExternalFilterType.AND,
                  });
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
              />
            }
            label={'Näytä myös poistuneet'}
          />
        </Box>
      </Box>
    </>
  );
};

export default AdminFilters;
