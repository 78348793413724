export const getSuffix = (type: string) => {
  switch (type) {
    case "rentalReadyPrice":
    case "dayPrice":
    case "monthPrice":
      return " €";
    case "targetUtilRate":
    case "discountDay":
    case "discountMonth":
      return " %";
    case "depreciationPeriod":
      return " kk";
    case "dayPricePercent1":
    case "dayPricePercent2":
    case "dayPricePercent3":
    case "dayPricePercent4":
    case "dayPricePercent5":
    case "dayPricePercent6":
    case "dayPricePercent7":
    case "dayPricePercentList":
    case "monthPricePercent1":
    case "monthPricePercent2":
    case "monthPricePercent3":
    case "monthPricePercent4":
    case "monthPricePercent5":
    case "monthPricePercent6":
    case "monthPricePercent7":
    case "monthPricePercentList":
      return " %";
    case "avgRentalPeriod":
      return " pv";
    default:
      return "";
  }
};
