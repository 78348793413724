import { RoundingBasis } from "./types";
import { PRICE_FACTOR } from "./constants";

export const getPriceRounded = (
  price: number | null | undefined,
  roundingBasis: RoundingBasis,
) => {
  if (price === null || price === undefined || price === 0) {
    return 0;
  }

  const dividedPrice = price / PRICE_FACTOR;

  if (roundingBasis === "THREESIGNIFICANT") {
    return Number(dividedPrice.toPrecision(3));
  }

  const digits = roundingBasis === "TWODECIMALS" ? 2 : 6;

  return Number(dividedPrice.toFixed(digits));
};
