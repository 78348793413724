import React from 'react';

import { createTheme } from '@mui/material/styles';

import { Divider } from '@mui/material';
import { Box } from '@mui/system';

import { SubtleText } from '../InitialInformationStep';

import GenericCheckbox from './Checkboxes/GenericCheckbox';
import RevenueSlider from './Sliders/RevenueSlider';
import { initRevenue } from '../../../providers/Background/BackgroundProvider';

import DefaultTooltip from '../../Tooltips/DefaultTooltip';

const revenueToolTipText = `
Arvio asiakkaan liikevaihdosta
`;

interface RevenueProps {
  changeValuesEnabled: boolean;
}
const Revenue: React.FC<RevenueProps> = ({ changeValuesEnabled }) => {
  const theme = createTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <DefaultTooltip title={revenueToolTipText} placement="top">
          <div>
            <SubtleText variant="subtitle2">
              Asiakkaan liikevaihto*
            </SubtleText>
          </div>
        </DefaultTooltip>
        <Box marginLeft={1}>
          <GenericCheckbox
            label={'Liikevaihto yli miljoona'}
            propName={'revenueOverMillion'}
            changeValuesEnabled={changeValuesEnabled}
          />
        </Box>
      </Box>
      <Box minWidth={700}>
        <RevenueSlider
          initial={initRevenue}
          changeValuesEnabled={changeValuesEnabled}
        />
      </Box>
      <Divider
        sx={{
          mt: 1,
          mb: 1,
          [theme.breakpoints.down('lg')]: {
            m: 0,
          },
        }}
      />
    </>
  );
};

export default Revenue;
