import {
  PricingSheetRow,
  TableType,
} from '../../../../../../shared/types';

import getFilteredRows from '../../../../helpers/getFilteredRows';
import { TableFilter } from '../../../../components/Pricing';
import { itemFilter } from '../../../../utils/filters';

const updateSelectedStatuses = (
  id: string,
  pricingSheetRows: PricingSheetRow[],
  selectedStatus: boolean,
  tableType?: string,
  itemFilterValue?: string,
  activeFilters?: TableFilter[] | undefined,
) => {
  // filtered is an array where we collect all rows which passes different conditions
  let filtered: PricingSheetRow[];

  const rowIdIncluded = (pricingSheetRow: PricingSheetRow) => {
    if (id === 'allRows') {
      return true;
    } else {
      return (
        pricingSheetRow['prefixProductGroup'] === id ||
        pricingSheetRow['prefixProductLine'] === id ||
        pricingSheetRow['prefixCategory'] === id ||
        pricingSheetRow['catClass'] === id ||
        pricingSheetRow['prefixPimProductGroup'] === id ||
        pricingSheetRow['prefixPimProductLine'] === id ||
        pricingSheetRow['prefixPimCategory'] === id
      );
    }
  };

  if (tableType === TableType.AdminTable) {
    filtered = pricingSheetRows.filter((pricingSheetRow) =>
      rowIdIncluded(pricingSheetRow),
    );
  } else {
    filtered = pricingSheetRows
      .filter(
        (pricingSheetRow: PricingSheetRow) =>
          pricingSheetRow.preSelected,
      )
      .filter((pricingSheetRow) => rowIdIncluded(pricingSheetRow));
  }

  if (id === 'allRows') {
    if (tableType === TableType.AdminTable) {
      filtered = pricingSheetRows;
    } else {
      filtered = pricingSheetRows.filter(
        (pricingSheetRow) => pricingSheetRow.preSelected,
      );
    }
  }

  if (itemFilterValue) {
    filtered = filtered.filter(
      (pricingSheetRow: PricingSheetRow) =>
        itemFilter(pricingSheetRow, itemFilterValue) &&
        rowIdIncluded(pricingSheetRow),
    );
  }

  // get final rows, update selected statuses depending if they are included in filtered array
  const finalRows = pricingSheetRows.map((pricingSheetRow) => {
    if (filtered.includes(pricingSheetRow)) {
      return {
        ...pricingSheetRow,
        selected: !selectedStatus,
      };
    } else {
      return pricingSheetRow;
    }
  });

  // handle rows with checkbox filters
  if (activeFilters && activeFilters?.length > 0) {
    const rowsWithCheckBoxFilters = getFilteredRows(
      finalRows,
      activeFilters,
    );
    if (rowsWithCheckBoxFilters.length > 0) {
      const newRows = pricingSheetRows.map((pricingSheetRow) => {
        if (
          rowsWithCheckBoxFilters
            .map((row) => row.catClass)
            .includes(pricingSheetRow.catClass) &&
          itemFilter(pricingSheetRow, itemFilterValue) &&
          rowIdIncluded(pricingSheetRow)
        ) {
          return {
            ...pricingSheetRow,
            selected: !selectedStatus,
          };
        } else {
          return pricingSheetRow;
        }
      });
      return newRows;
    }
    return finalRows;
  }

  return finalRows;
};

export default updateSelectedStatuses;
