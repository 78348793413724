import { ValueGetterParams } from 'ag-grid-community';
import { PricingSheetRow } from '../../../../../shared/types';
import discountDayGetter from './discountDayGetter';
import discountMonthGetter from './discountMonthGetter';

const discountGetter = (
  params: ValueGetterParams<PricingSheetRow>,
) => {
  if (!params.data) return;
  const { data } = params;
  const pricingBasis = data.pricingBasis;

  if (pricingBasis === 'MONTH') {
    return discountMonthGetter(params);
  } else if (pricingBasis === 'DAY') {
    return discountDayGetter(params);
  } else {
    return 0;
  }
};

export default discountGetter;
