import { countDaysInDateRange } from "./countDaysInDateRange";

type DateRange = {
  start: Date;
  end: Date;
};

// A function that checks two date ranges and returns the number of days that are in both ranges
export const countDaysInBothRanges = (
  range1: DateRange,
  range2: DateRange,
): number => {
  // Extract only the date portion of the start and end
  const start = new Date(
    Math.max(range1.start.getTime(), range2.start.getTime()),
  );
  start.setHours(0, 0, 0, 0);

  const end = new Date(Math.min(range1.end.getTime(), range2.end.getTime()));
  end.setHours(0, 0, 0, 0);

  return countDaysInDateRange(start, end);
};
