import {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useColumns } from '../../hooks/useColumns';
import {
  AgGridColumnView,
  OfferItem,
  PartialBy,
  PricingSheetRow,
  TableType,
} from '../../../../shared/types';
import { Grid, Typography } from '@mui/material';
import EditViewDialog from '../../components/PricingTable/EditViewDialog';
import AgGrid, {
  CustomAgGridMethods,
} from '../../components/PricingTable/AgGrid';
import LoadingRenderer from '../../components/Common/LoadingRenderer';
import OfferStepActionContainer from './OfferStepActionContainer';
import { useItems } from '../../hooks/useItems';
import {
  DatabaseOfferItem,
  UpdatedDatabaseOfferItem,
  DatabaseOfferWithItems,
} from '../../../../shared/types/offers';
import { OfferStepTitle } from './OfferStepTitle';
import {
  MiraItemSearch,
  MiraItemSearchOption,
} from '../../components/Common/MiraItemSearch';
import { useSortedGroupHierarchy } from '../../hooks/useSortedGroupHierarchy';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import { FirstDataRenderedEvent, GridApi } from 'ag-grid-community';
import { MIRA_PRODUCT_GROUP } from '../../../../shared/constants';
import { useDeleteOfferItems } from '../../hooks/useDeleteOfferItems';
import { useUpdateOfferItems } from '../../hooks/useUpdateOfferItems';
import { chunk } from 'lodash';
import { composeMiraOfferItem } from './helpers';
import { isSalesItem } from '../../../../shared/helpers/isSalesItem';
import useRamiturvaPrices from '../../hooks/useRamiturvaPrices';
import useRamiturvaNames from '../../hooks/useRamiturvaNames';

type OfferItemsProps = {
  offerSheet: DatabaseOfferWithItems;
  renderStepActions: (selectionCount: number) => ReactNode;
};

export const OfferItems = ({
  offerSheet,
  renderStepActions = () => null,
}: OfferItemsProps) => {
  // grid ref
  const agGridRef = useRef<CustomAgGridMethods>(null);
  const gridApiRef = useRef<GridApi<PricingSheetRow> | null>(null);
  const [selectionCount, setSelectionCount] = useState(0);
  const [gridKey, setGridKey] = useState<string>('grid-items');
  const selectedItemsQueue = useRef<OfferItem[]>([]);
  const {
    data: ramiturvaPricesMira = {},
    isPending: isLoadingRamiturvaPrices,
  } = useRamiturvaPrices();

  const {
    data: ramiturvaNames = {},
    isPending: isLoadingRamiturvaNames,
  } = useRamiturvaNames();

  // fetch items
  const { data: databaseItems = [], isPending: isLoadingItems } =
    useItems({
      includeHidden: true,
      offerSheet,
      ramiturvaPricesMira,
      ramiturvaNames,
    });
  const { isPending: isLoadingHierarchy } = useSortedGroupHierarchy();
  // delete offer sheet item(s) mutation
  const { mutate: deleteOfferItems, isPending: isDeletingItems } =
    useDeleteOfferItems({
      offerSheetId: Number(offerSheet.id),
    });
  // create/update offer sheet items mutation
  const { mutate: updateOfferItems, isPending: isUpdatingItems } =
    useUpdateOfferItems();

  const isLoading = isDeletingItems || isUpdatingItems;

  // offer sheet items that don't exist in the items database
  const offerSheetMiraItems = useMemo(() => {
    const offerSheetItemsWithoutItemId = offerSheet.items.flatMap(
      (item) =>
        !item.itemId
          ? composeMiraOfferItem({
              catClass: item.details?.miraItemNumber || '',
              name: item.details?.miraName,
              type: item.details?.miraItemType,
              leasePeriodStart: new Date(
                offerSheet.offerLeasePeriodStart,
              ),
              leasePeriodEnd: new Date(
                offerSheet.offerLeasePeriodEnd,
              ),
            })
          : [],
    );
    return uniqBy(offerSheetItemsWithoutItemId, 'catClass');
  }, [offerSheet]);

  const items = [...databaseItems, ...offerSheetMiraItems];
  const selectRowNode = (id: string | number) => {
    const rowNode = gridApiRef?.current?.getRowNode(String(id));
    rowNode?.setSelected(true);
    return rowNode;
  };

  const hasMatchingId =
    (item: OfferItem) => (databaseItem: DatabaseOfferItem) =>
      item.id === databaseItem.itemId ||
      item.catClass === databaseItem.details?.miraItemNumber;

  const hasMissingId = (id: number) => id === -1;

  const handleSelection = useCallback(
    (forceRerender = false) => {
      if (!offerSheet) return;
      const selectedItems: UpdatedDatabaseOfferItem[] =
        selectedItemsQueue.current.flatMap((item) => {
          const existingItem = offerSheet.items.find(
            hasMatchingId(item),
          );
          // item not yet selected, select it
          if (!existingItem) {
            const newOfferItem: PartialBy<
              UpdatedDatabaseOfferItem,
              'pricingBasis'
            > = {
              offerSheetId: Number(offerSheet.id),
              itemId: hasMissingId(item.id) ? null : item.id,
              approvalApproverComment: item.approvalApproverComment,
              dayPrice: item.dayPrice,
              proposalDayPrice: item.proposalDayPrice,
              monthPrice: item.monthPrice,
              proposalMonthPrice: item.proposalMonthPrice,
              quantity: 1,
              leasePeriodStart: new Date(
                offerSheet.offerLeasePeriodStart,
              ),
              leasePeriodEnd: new Date(
                offerSheet.offerLeasePeriodEnd,
              ),
              details: hasMissingId(item.id)
                ? {
                    miraName: item.name,
                    miraItemNumber: item.catClass,
                    miraItemType: item.type,
                  }
                : null,
              unit: item.unit,
              includeRamiturva: offerSheet.includeRamiturva,
              ramiturvaName: item.ramiturvaName,
              ramiturvaProposalDayPrice:
                item.ramiturvaProposalDayPrice,
              ramiturvaProposalMonthPrice:
                item.ramiturvaProposalMonthPrice,
              ramiturvaDayPrice: item.ramiturvaDayPrice,
              ramiturvaMonthPrice: item.ramiturvaMonthPrice,
            };
            return isSalesItem(item.type)
              ? [{ ...newOfferItem, pricingBasis: 'MONTH' }]
              : offerSheet.pricingBasis === 'BOTH'
                ? [
                    { ...newOfferItem, pricingBasis: 'DAY' },
                    { ...newOfferItem, pricingBasis: 'MONTH' },
                  ]
                : [
                    {
                      ...newOfferItem,
                      pricingBasis: offerSheet.pricingBasis,
                    },
                  ];
          }
          return [];
        });
      if (selectedItems.length > 0) {
        // update to database
        updateOfferItems(
          {
            id: String(offerSheet.id),
            items: selectedItems,
          },
          {
            onSuccess: () => {
              if (forceRerender) {
                // reset grid key to force re-render if mira product group node doesn't exist
                // note: this was the only way to sort groups correctly after adding a new item,
                // as group sorting is only done on initial render
                setGridKey(`grid-items-${Date.now()}`);
              }
              const itemNumber =
                selectedItems[0].details?.miraItemNumber || '';
              // if itemNumber is defined, then a mira item was added and we need to select it
              if (!forceRerender && itemNumber) {
                setTimeout(() => {
                  selectRowNode(itemNumber);
                });
              }
            },
          },
        );
      }
      // clear the queue after processing
      selectedItemsQueue.current = [];
    },
    [offerSheet, updateOfferItems],
  );

  const handleRemove = useCallback(() => {
    if (!offerSheet) return;
    const removedOfferItemIds = selectedItemsQueue.current.flatMap(
      (item) =>
        offerSheet.items
          .filter(hasMatchingId(item))
          .map(({ id }) => id),
    );
    if (deleteOfferItems.length > 0) {
      // split into chunks of 200, otherwise the request url might get too long, causing it to fail
      chunk(removedOfferItemIds, 200).forEach((items) => {
        deleteOfferItems({ id: items });
      });
      // clear the queue after processing
      selectedItemsQueue.current = [];
    }
  }, [deleteOfferItems, offerSheet]);

  const debouncedHandleSelection = useMemo(
    () => debounce(handleSelection),
    [handleSelection],
  );

  const debouncedHandleRemove = useMemo(
    () => debounce(handleRemove),
    [handleRemove],
  );

  const handleSelectionChange = useCallback(
    (type: 'SELECT' | 'REMOVE' = 'SELECT', forceRerender = false) =>
      (selectedItem: OfferItem) => {
        // add the selected item to the queue
        selectedItemsQueue.current.push(selectedItem);
        // debounce the processing of the queue
        type === 'SELECT'
          ? debouncedHandleSelection(forceRerender)
          : debouncedHandleRemove();
      },
    [debouncedHandleSelection, debouncedHandleRemove],
  );

  const {
    columnDefs,
    gridColumnView,
    groupingStyle,
    groupingValues,
    setDefaultGroupingValues,
    updateGroupingValues,
  } = useColumns(AgGridColumnView.OFFER_PRESELECT);

  if (
    isLoadingItems ||
    isLoadingHierarchy ||
    isLoadingRamiturvaPrices ||
    isLoadingRamiturvaNames
  ) {
    return (
      <LoadingRenderer label="Ladataan tuotteita" sx={{ mt: 8 }} />
    );
  }

  const getMiraProductGroupNode = () => {
    return gridApiRef.current
      ?.getRenderedNodes()
      .find(({ key }) => key === MIRA_PRODUCT_GROUP);
  };

  const handleFirstDataRender = ({ api }: FirstDataRenderedEvent) => {
    // store api reference
    gridApiRef.current = api;
  };

  const handleMiraItemSelect = async (
    selectedOption: MiraItemSearchOption | null,
  ) => {
    if (selectedOption) {
      const gridApi = gridApiRef?.current;
      if (gridApi) {
        // check if item data exists
        const selectedItem = items.find(
          (item) => item.catClass === selectedOption.id,
        );
        // if it does, select it in the grid
        if (selectedItem) {
          // this will also trigger addSelectedRow, which saves the item to the database
          const rowNode = selectRowNode(selectedItem.id);
          if (rowNode) {
            gridApi.setRowNodeExpanded(rowNode, true, true);
          }
          // otherwise, create a new item under the "MIRA tuotteet" group
        } else {
          const newItem = composeMiraOfferItem({
            catClass: selectedOption.id,
            name: selectedOption.value,
            type: selectedOption.type,
            leasePeriodStart: new Date(
              offerSheet.offerLeasePeriodStart,
            ),
            leasePeriodEnd: new Date(offerSheet.offerLeasePeriodEnd),
          });
          const miraProductGroupNode = getMiraProductGroupNode();
          // if mira product group node exists, select the item.
          // otherwise, select the item and force grid re-render to preserve sorting
          handleSelectionChange(
            'SELECT',
            !miraProductGroupNode,
          )(newItem);
        }
      }
    }
  };

  return (
    <>
      <OfferStepActionContainer>
        {renderStepActions(selectionCount)}
      </OfferStepActionContainer>
      <OfferStepTitle
        title={'Valitse tuotteet tarjoukseen'}
        offerSheet={offerSheet}
      />
      <Grid container direction={'row'} alignItems={'center'}>
        <MiraItemSearch onSelect={handleMiraItemSelect} />
        <Typography ml={'auto'} mr={1}>
          Olet valinnut
          <b>{` ${selectionCount} `}</b>
          tuotetta tarjoukseen
        </Typography>
        <EditViewDialog
          groupingStyle={groupingStyle}
          setDefaultGroupingValues={setDefaultGroupingValues}
          updateGroupingValues={updateGroupingValues}
          groupingValues={groupingValues}
        />
      </Grid>
      <AgGrid
        className={isLoading ? 'loading' : ''}
        gridKey={gridKey}
        hideSideBar
        ref={agGridRef}
        gridColumnView={gridColumnView}
        columnDefs={columnDefs}
        type={TableType.PreSelectTable}
        rows={items}
        loading={false}
        addSelectedRow={handleSelectionChange()}
        removeSelectedRow={handleSelectionChange('REMOVE')}
        externalFilters={[]}
        onSelectionChange={setSelectionCount}
        isSelectable={true}
        isGroupOpenByDefault={({ key }) => key === MIRA_PRODUCT_GROUP}
        onFirstDataRendered={handleFirstDataRender}
      />
    </>
  );
};
