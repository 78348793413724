import {
  ImportType,
  ImportTableColumn,
} from '../../../../../shared/types/import';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { DialogLabel } from './ExcelImportDialogBase';
import TextField from '@mui/material/TextField/TextField';
import styled from '@emotion/styled';
import { useImportStore } from '../../../stores/importStore';
import Collapse from '@mui/material/Collapse';
import { Icon } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';

type HeaderMapperProps = {
  disabled: boolean;
  renderHeaderMapper: (disabled: boolean) => ReactNode;
};

const ExcelImportHeaderMapper = ({
  disabled = false,
  renderHeaderMapper = () => null,
}: HeaderMapperProps) => {
  const [open, setOpen] = useState(true);

  // toggle open state when the disabled state changes
  useEffect(() => {
    setOpen(!disabled);
  }, [disabled]);

  return (
    <Box>
      <Button
        fullWidth
        endIcon={
          <Icon
            component={
              open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon
            }
          />
        }
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          textTransform: 'none',
          p: 0,
          color: 'inherit',
          ml: -1,
          width: 'calc(100% + 16px)',
          px: 1,
          py: 0,
        }}
        onClick={() => setOpen(!open)}
      >
        <DialogLabel variant={'body1'}>
          Excel-tiedoston sarakkeiden kohdistus oikeisiin arvoihin
        </DialogLabel>
      </Button>
      <Collapse in={open} timeout={0} unmountOnExit>
        {renderHeaderMapper(disabled)}
      </Collapse>
    </Box>
  );
};

type HeaderMapperTableProps<T> = {
  columns: ImportTableColumn[];
  disabled: boolean;
  importType: T;
};

export const HeaderMapperTable = <T extends ImportType>({
  columns,
  disabled = false,
  importType,
}: HeaderMapperTableProps<T>) => {
  const [headerMapping, setHeaderMapping] = useImportStore(
    (state) => [state.headerMapping, state.setHeaderMapping],
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setHeaderMapping(importType, name, value);
  };

  return (
    <Table
      sx={{
        border: '1px solid #e0e0e0',
        borderBottom: 0,
        borderRadius: '2px',
        '&:first-of-type': {
          mt: 0.25,
        },
        '&:not(:last-of-type)': {
          mb: 2,
        },
        '& tr': {
          '& td': {
            borderBottom: 0,
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#fafafa',
          },
        },
        '& th': {
          backgroundColor: '#fafafa',
          color: 'primary.main',
          fontWeight: 'bold',
          p: '2px 4px',
        },
        '& th, & td': {
          fontSize: '0.75rem',
          '&:not(:first-of-type)': {
            borderLeft: '1px solid #e0e0e0',
          },
        },
      }}
    >
      <TableHead>
        <TableRow>
          {columns.map(({ key, name }) => {
            const minWidth = key.endsWith('List') ? 100 : 50;
            return (
              <TableCell
                key={key}
                sx={{ minWidth }}
                align={'center'}
                padding={'none'}
              >
                {name}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {columns.map(({ key }) => (
            <TableCell key={key} padding={'none'}>
              <Input
                disabled={disabled}
                name={key}
                variant={'filled'}
                size={'small'}
                onChange={handleChange}
                value={headerMapping[importType][key] || ''}
              />
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

const Input = styled(TextField)`
  display: flex;
  .MuiInputBase-root {
    background-color: transparent;
    border-radius: 0;
    font-size: 0.75rem;
    &:hover {
      background-color: #fafafa;
    }
    &::before {
      border-bottom-color: #e0e0e0;
    }
    &.Mui-disabled {
      background-color: transparent;
    }
  }
  .MuiInputBase-input {
    padding: 0.5rem;
    text-align: center;
  }
`;

export default ExcelImportHeaderMapper;
