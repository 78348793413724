import {
  CoefficientType,
  PercentCoefficientType,
} from '../../../../../shared/types';
import {
  DAY_PRICE_COEFFICIENT_KEYS,
  DAY_PRICE_PERCENT_COEFFICIENT_KEYS,
  MONTH_PRICE_COEFFICIENT_KEYS,
  MONTH_PRICE_PERCENT_COEFFICIENT_KEYS,
} from '../../../../../shared/constants';
import { EditItemsDialogChangeWithKey } from './types';

const initialValue = <T>(
  key: T,
): EditItemsDialogChangeWithKey<T> => ({
  key,
  absoluteValue: null,
  percentValue: null,
});

export const initialDayPriceCoefficients: EditItemsDialogChangeWithKey<
  CoefficientType<'DAY'>
>[] = DAY_PRICE_COEFFICIENT_KEYS.map(initialValue);

export const initialMonthPriceCoefficients: EditItemsDialogChangeWithKey<
  CoefficientType<'MONTH'>
>[] = MONTH_PRICE_COEFFICIENT_KEYS.map(initialValue);

export const initialDayIndustryPercents: EditItemsDialogChangeWithKey<
  PercentCoefficientType<'DAY'>
>[] = DAY_PRICE_PERCENT_COEFFICIENT_KEYS.map(initialValue);

export const initialMonthIndustryPercents: EditItemsDialogChangeWithKey<
  PercentCoefficientType<'MONTH'>
>[] = MONTH_PRICE_PERCENT_COEFFICIENT_KEYS.map(initialValue);
