import React from 'react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import HistorySlider from './HistorySlider';
import UndoButton from './UndoButton';
import RedoButton from './RedoButton';

import {
  ItemIndustryPercent,
  PricingSheetRow,
  TableType,
} from '../../../../shared/types';

import { RowsHistoryType } from '../../hooks/useRowsHistory';

import { formatDateTimeWithSeconds } from '../../utils/formatDateTimes';

interface HistoryProps {
  rowsHistory: RowsHistoryType[];
  currentIndex: number;
  undo: () =>
    | {
        rows: PricingSheetRow[];
        itemIndustryPercents: ItemIndustryPercent[];
      }
    | undefined;
  undoToIndex: (index: number) =>
    | {
        rows: PricingSheetRow[];
        itemIndustryPercents: ItemIndustryPercent[];
      }
    | undefined;
  redo: () =>
    | {
        rows: PricingSheetRow[];
        itemIndustryPercents: ItemIndustryPercent[];
      }
    | undefined;
  redoToIndex: (index: number) =>
    | {
        rows: PricingSheetRow[];
        itemIndustryPercents: ItemIndustryPercent[];
      }
    | undefined;
  save: (
    rows: PricingSheetRow[],
    moveInHistory?: boolean,
    itemIndustryPercents?: ItemIndustryPercent[],
  ) => void;
  tableType: TableType;
}

const History: React.FC<HistoryProps> = ({
  rowsHistory,
  currentIndex,
  undo,
  undoToIndex,
  redo,
  redoToIndex,
  save,
  tableType,
}) => {
  const theme = useTheme();

  // save rows to history
  const saveRowsToHistory = (
    rows: PricingSheetRow[],
    itemIndustryPercents: ItemIndustryPercent[] = [],
  ) => {
    const newRows =
      tableType === TableType.AdminTable
        ? rows
        : rows.filter((row: PricingSheetRow) => row.preSelected);
    save([...newRows], true, [...itemIndustryPercents]);
  };

  const Undo = () => {
    return (
      <UndoButton
        disabled={rowsHistory.length < 1 || !currentIndex}
        undo={undo}
        saveRowsToHistory={saveRowsToHistory}
      />
    );
  };

  const Redo = () => {
    return (
      <RedoButton
        disabled={
          rowsHistory.length === 0 ||
          currentIndex + 1 === rowsHistory.length
        }
        redo={redo}
        saveRowsToHistory={saveRowsToHistory}
      />
    );
  };

  return (
    <>
      <Card variant="outlined" sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                {rowsHistory.length > 1 ? (
                  <>
                    Muutos:{` `}
                    {formatDateTimeWithSeconds(
                      new Date(rowsHistory[currentIndex]?.timeStamp),
                    )}{' '}
                  </>
                ) : (
                  'Ei muutoksia historiassa!'
                )}
              </Typography>
            </>
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box pl={1} pr={1} aria-label="undo">
              {theme.direction === 'rtl' ? <Redo /> : <Undo />}
            </Box>
            <Box pl={1} pr={1}>
              <HistorySlider
                history={rowsHistory}
                currentIndex={currentIndex}
                undoToIndex={undoToIndex}
                redoToIndex={redoToIndex}
                saveRowsToHistory={saveRowsToHistory}
              />
            </Box>

            <Box pl={1} pr={1} aria-label="redo">
              {theme.direction === 'rtl' ? <Undo /> : <Redo />}
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default History;
