import { Container, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const StepperContainer = ({ children }: Props) => {
  const theme = useTheme();

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: 'white',
        borderRadius: 2,
        py: 3,
        my: 4,
        width: '92.5%',
        [theme.breakpoints.down('xl')]: {
          width: '100%',
        },
      }}
    >
      {children}
    </Container>
  );
};

export default StepperContainer;
