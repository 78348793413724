import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { deleteOfferSheetItems } from '../services/offerSheets';
import {
  DatabaseOfferWithItems,
  DeleteOfferItemsResponseBody,
} from '../../../shared/types/offers';
import { AxiosError } from 'axios';
import { OFFER_SHEET_QUERY_KEY } from '../../../shared/constants';
import { produce } from 'immer';

type DataResponseType = DeleteOfferItemsResponseBody;
type ErrorType = AxiosError;
type VariablesType = Parameters<typeof deleteOfferSheetItems>[0];

type UseDeleteOfferItemsParams = Omit<
  UseMutationOptions<DataResponseType, ErrorType, VariablesType>,
  'mutationFn'
> & {
  offerSheetId?: number;
};

export const useDeleteOfferItems = ({
  offerSheetId,
  ...options
}: UseDeleteOfferItemsParams = {}) => {
  const queryClient = useQueryClient();
  return useMutation<DataResponseType, ErrorType, VariablesType>({
    ...options,
    mutationFn: deleteOfferSheetItems,
    onSuccess: (data, variables, context) => {
      const { id: removedIds } = variables;
      if (offerSheetId) {
        // update items to offer query cache
        queryClient.setQueryData<DatabaseOfferWithItems>(
          [OFFER_SHEET_QUERY_KEY, `${offerSheetId}`],
          (oldData) =>
            produce(oldData, (updatedData) => {
              if (updatedData) {
                updatedData.items = updatedData.items.filter(
                  (item) => !removedIds.includes(item.id),
                );
              }
            }),
        );
      }
      options?.onSuccess?.(data, variables, context);
    },
  });
};
