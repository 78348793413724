import { styled } from '@mui/material/styles';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';

const ValidationTooltip = styled(
  ({
    arrow = true,
    children,
    className,
    placement = 'top',
    TransitionProps = { timeout: 0 },
    ...props
  }: TooltipProps) => (
    <Tooltip
      arrow={arrow}
      placement={placement}
      TransitionProps={TransitionProps}
      {...props}
      classes={{ popper: className }}
    >
      {children}
    </Tooltip>
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
    fontSize: 12,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main,
  },
  [`&.${tooltipClasses.popper}`]: {
    zIndex: 1200,
  },
}));

export default ValidationTooltip;
