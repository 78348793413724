import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Dialog,
} from '@mui/material';
import { useContext } from 'react';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import { restorePricingSheetById } from '../../../services/pricingSheets';
import { PricingSheet } from '../../../../../shared/types';

const RestoreDialog = ({
  pricingSheet,
  updateSheets,
  isOpenRestoreDialog,
  handleOpenRestoreDialog,
  handleCloseRestoreDialog,
}: {
  pricingSheet: PricingSheet;
  updateSheets: () => Promise<void>;
  isOpenRestoreDialog: boolean;
  handleOpenRestoreDialog: () => void;
  handleCloseRestoreDialog: () => void;
}) => {
  const { setNotification } = useContext(NotificationProvider);
  const restoreSheet = async (id: string) => {
    try {
      await restorePricingSheetById(id);
      await updateSheets();
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: `Hinnasto ${pricingSheet.name} palautettu onnistuneesti`,
      });
    } catch (error) {
      setNotification({
        type: 'SNACKBAR',
        duration: 6000,
        severity: 'error',
        message: `Hinnastoa ${pricingSheet.name} palauttaessa tapahtui virhe!`,
      });
    }
  };
  return (
    <Dialog
      disableScrollLock
      open={isOpenRestoreDialog}
      onClose={handleCloseRestoreDialog}
    >
      <DialogTitle>{'Haluatko palauttaa hinnaston?'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography color="text.primary">
            Olet palauttamassa hinnastoa {pricingSheet.name}{' '}
            arkistoista
            <br />
            Haluatko jatkaa?
            <br />
            <br />
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          m: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button color="inherit" onClick={handleCloseRestoreDialog}>
          Peruuta
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            restoreSheet(pricingSheet.id);
            handleCloseRestoreDialog();
          }}
          autoFocus
        >
          Palauta hinnasto
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestoreDialog;
