import {
  Alert,
  AlertColor,
  AlertProps,
  Typography,
} from '@mui/material';

type SheetCommentProps = Omit<AlertProps, 'severity'> & {
  comment?: string;
  severity?: AlertColor;
  title: string;
};

export const SheetComment = ({
  comment = '',
  severity = 'info',
  title,
  ...alertProps
}: SheetCommentProps) => {
  return (
    <Alert
      severity={severity}
      {...alertProps}
      sx={{
        alignItems: 'center',
        flex: 1,
        ...alertProps.sx,
      }}
    >
      <Typography fontWeight={'bold'}>{title}</Typography>
      <Typography fontStyle={'italic'}>{`"${comment}"`}</Typography>
    </Alert>
  );
};
