import React, { useContext } from 'react';

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import { Typography } from '@mui/material';
import BackgroundProvider from '../../../../providers/Background/BackgroundProvider';

export interface SiteOption {
  readonly value: string | undefined;
  readonly label: string | undefined;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const CustomerSiteSelector: React.FC = () => {
  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );

  const handleChange = (
    newValue: OnChangeValue<SiteOption, false>,
    actionMeta: ActionMeta<SiteOption>,
  ) => {
    if (newValue) {
      setBackgroundInfo({
        ...backgroundInfo,
        site: newValue?.value,
      });
    }
  };
  const handleInputChange = (inputValue: any, actionMeta: any) => {
    if (inputValue) {
      setBackgroundInfo({
        ...backgroundInfo,
        site: inputValue,
      });
    }
  };

  return (
    <>
      {backgroundInfo?.customerName ? (
        <>
          {backgroundInfo?.site ? (
            <CreatableSelect
              defaultValue={{
                value: backgroundInfo?.customerName,
                label: backgroundInfo?.customerName,
              }}
              placeholder="Valitse tai kirjoita työmaa..."
              formatCreateLabel={(inputValue) =>
                `Lisää ${inputValue}`
              }
              styles={{
                menu: (styles) =>
                  Object.assign(styles, { zIndex: 1000 }),
              }}
              isClearable
              onChange={handleChange}
              onInputChange={handleInputChange}
              options={[]}
            />
          ) : (
            <CreatableSelect
              placeholder="Valitse tai kirjoita työmaa..."
              formatCreateLabel={(inputValue) =>
                `Lisää ${inputValue}`
              }
              styles={{
                menu: (styles) =>
                  Object.assign(styles, { zIndex: 1000 }),
              }}
              isClearable
              onChange={handleChange}
              onInputChange={handleInputChange}
              options={[]}
            />
          )}
        </>
      ) : (
        <Typography>Valitse ensin asiakas!</Typography>
      )}
    </>
  );
};

export default CustomerSiteSelector;
