import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import {
  ItemIndustryPercent,
  PricingSheetRow,
} from '../../../../shared/types';
import { RowsHistoryType } from '../../hooks/useRowsHistory';

import { formatDateTimeWithSeconds } from '../../utils/formatDateTimes';

interface HistorySliderProps {
  history: Array<RowsHistoryType>;
  currentIndex: number;
  undoToIndex: (index: number) =>
    | {
        rows: PricingSheetRow[];
        itemIndustryPercents: ItemIndustryPercent[];
      }
    | undefined;
  redoToIndex: (index: number) =>
    | {
        rows: PricingSheetRow[];
        itemIndustryPercents: ItemIndustryPercent[];
      }
    | undefined;
  saveRowsToHistory: (
    rows: PricingSheetRow[],
    itemIndustryPercents: ItemIndustryPercent[],
  ) => void;
}

const HistorySlider: React.FC<HistorySliderProps> = ({
  history,
  currentIndex,
  undoToIndex,
  redoToIndex,
  saveRowsToHistory,
}) => {
  const getSliderValueText = (sliderValue: number) => {
    return history[sliderValue]
      ? `${formatDateTimeWithSeconds(
          new Date(history[sliderValue].timeStamp),
        )}`
      : null;
  };

  const [sliderValue, setSliderValue] = useState<number>(
    history.length > 0
      ? history.indexOf(history[history.length - 1])
      : 0,
  );

  const handleChange = (
    _event: Event,
    newSliderValue: number | number[],
  ) => {
    if (typeof newSliderValue === 'number') {
      setSliderValue(newSliderValue);
    }
  };

  const handleChangeCommit = () => {
    if (sliderValue > currentIndex) {
      const returnedRows = redoToIndex(sliderValue);
      if (returnedRows) {
        saveRowsToHistory(
          returnedRows.rows,
          returnedRows.itemIndustryPercents,
        );
      }
    } else {
      const returnedRows = undoToIndex(sliderValue);
      if (returnedRows) {
        saveRowsToHistory(
          returnedRows.rows,
          returnedRows.itemIndustryPercents,
        );
      }
    }
  };

  useEffect(() => {
    setSliderValue(history.indexOf(history[history.length - 1]));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    setSliderValue(currentIndex);
  }, [currentIndex]);

  return (
    <Box sx={{ width: 200 }}>
      <Slider
        disabled={history.length < 2}
        aria-label="Välitallennus"
        value={sliderValue}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommit}
        valueLabelFormat={getSliderValueText}
        valueLabelDisplay="auto"
        step={1}
        marks
        min={history.indexOf(history[0])}
        max={
          history.length > 0
            ? history.indexOf(history[history.length - 1])
            : 0
        }
      />
    </Box>
  );
};

export default HistorySlider;
