import {
  IndustryName,
  ItemIndustryPercent,
} from '../../../../../shared/types';

export enum ItemIndustryPercentActions {
  UpdateItems,
  AddItems,
  ChangeItem,
}

export type ItemIndustryPercentReducerAction =
  | {
      type: ItemIndustryPercentActions.UpdateItems;
      itemIndustryPercents: ItemIndustryPercent[];
    }
  | {
      type: ItemIndustryPercentActions.AddItems;
      newItems: ItemIndustryPercent | ItemIndustryPercent[];
    }
  | {
      type: ItemIndustryPercentActions.ChangeItem;
      catClass: string;
      field: string;
      industry: { id: number; name: IndustryName };
      newValue: string;
    };

const itemIndustryPercentsReducer = (
  itemIndustryPercents: ItemIndustryPercent[] | undefined,
  action: ItemIndustryPercentReducerAction,
) => {
  switch (action.type) {
    case ItemIndustryPercentActions.UpdateItems: {
      return action.itemIndustryPercents
        ? [...action.itemIndustryPercents]
        : itemIndustryPercents;
    }
    case ItemIndustryPercentActions.AddItems: {
      const copy = itemIndustryPercents
        ? [...itemIndustryPercents]
        : [];
      return Array.isArray(action.newItems)
        ? [...copy, ...(action.newItems || [])]
        : [...copy, action.newItems];
    }
    case ItemIndustryPercentActions.ChangeItem: {
      return itemIndustryPercents
        ? [
            ...itemIndustryPercents.map((itemIndustryPercentItem) => {
              if (
                itemIndustryPercentItem.catClass ===
                  action.catClass &&
                itemIndustryPercentItem.industry.name ===
                  action.industry.name
              ) {
                return {
                  ...itemIndustryPercentItem,
                  [action.field]: action.newValue,
                };
              } else {
                return itemIndustryPercentItem;
              }
            }),
          ]
        : [];
    }
    default: {
      return itemIndustryPercents;
    }
  }
};

export default itemIndustryPercentsReducer;
