export enum StorageKeys {
  ShowLeaseDateChangedDialog = 'showLeaseDateChangedDialog',
}

export const addToSessionStorageArray = (
  key: StorageKeys,
  id: number,
) => {
  let showDialogArray = getSessionStorageArray(key);

  if (!showDialogArray.includes(id)) {
    showDialogArray.push(id);
  }

  sessionStorage.setItem(key, JSON.stringify(showDialogArray));
};

export const removeFromSessionStorageArray = (
  key: StorageKeys,
  id: number,
) => {
  const showDialogArray = getSessionStorageArray(key).filter(
    (item: number) => item !== id,
  );

  sessionStorage.setItem(key, JSON.stringify(showDialogArray));
};

export const getSessionStorageArray = (
  key: StorageKeys,
): number[] => {
  const storedValue = sessionStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : [];
};
