import FormControl from '@mui/material/FormControl';
import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import DefaultTooltip from '../Tooltips/DefaultTooltip';
import { PricingBasis } from '../../../../shared/types';

type EditPricingBasisProps = {
  handlePricingBasisChange: (
    event: SelectChangeEvent<PricingBasis>,
  ) => void;
  label?: string;
  tooltipText?: string;
  pricingBasis: PricingBasis;
  showOptions: PricingBasis[];
};

const EditPricingBasis: React.FC<EditPricingBasisProps> = ({
  handlePricingBasisChange,
  label = 'Hinnaston tyyppi',
  tooltipText = 'Valitse mitkä hinnat tulevat hinnastoon',
  pricingBasis,
  showOptions,
}) => {
  const showOption = (option: PricingBasis) => {
    return showOptions.includes(option);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
      <InputLabel>{label}</InputLabel>
      <DefaultTooltip placement="top" title={tooltipText}>
        <Select
          sx={{ minWidth: 220 }}
          label={label}
          value={pricingBasis}
          onChange={handlePricingBasisChange}
        >
          {showOption('BOTH') && (
            <MenuItem value={'BOTH'}>
              Päivä- ja kuukausihinnat
            </MenuItem>
          )}
          {showOption('DAY') && (
            <MenuItem value={'DAY'}>Vain päivähinnat</MenuItem>
          )}
          {showOption('MONTH') && (
            <MenuItem value={'MONTH'}>Vain kuukausihinnat</MenuItem>
          )}
        </Select>
      </DefaultTooltip>
    </FormControl>
  );
};

export default EditPricingBasis;
