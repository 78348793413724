import React from 'react';

import { createTheme } from '@mui/material/styles';

import { Box } from '@mui/system';

import PriceSheetTypeSelector from './Selectors/PriceSheetTypeSelector';

import { SubtleText } from '../InitialInformationStep';

interface LevelProps {
  handlers: <T>(propName: string) => {
    value: any;
    onChange: (value: T) => void;
  };
}
const Level: React.FC<LevelProps> = ({ handlers }) => {
  const theme = createTheme();

  return (
    <Box
      sx={{
        [theme.breakpoints.down('lg')]: {
          maxWidth: '480px',
        },
      }}
    >
      <SubtleText variant="body1">Hinnaston taso*</SubtleText>
      <PriceSheetTypeSelector
        {...handlers<string>('priceSheetType')}
      />
    </Box>
  );
};

export default Level;
