import _ from "lodash";

import { NodeItem, NodeFieldValue } from "../types";

export const formatPimPath = (pimPath: string, pathCount: number) => {
  const pathArray = pimPath.split("/");
  if (pathCount === 1 && pathArray.length >= 1) {
    return pathArray[0];
  }
  if (pathCount === 2 && pathArray.length >= 2) {
    return pathArray.slice(0, 2).join("/");
  }
  if (pathCount === 3 && pathArray.length >= 3) {
    return pathArray.slice(0, 3).join("/");
  }
  return "";
};

export const formatPimNode = (node: any) => {
  return {
    value: node.displayName,
    label: node.displayName,
    path: node.path,
  };
};

export const formatPimCellValue = (path: string, value?: string) => {
  return value ? value + "|" + path : "";
};

export const parseNodeItem = (nodeItem: NodeItem, currentPath: string) => {
  return {
    nodeId: nodeItem.entityId,
    path: currentPath?.split("/").slice(2).join("/"),
    url: nodeItem.summary.resourceUrl,
    nodeName:
      nodeItem.fieldValues.find(
        (fieldValue: NodeFieldValue) =>
          fieldValue.fieldTypeId === "ChannelNodeName",
      )?.value ?? "",
    displayName: nodeItem.fieldValues.find(
      (fieldValue: NodeFieldValue) =>
        fieldValue.fieldTypeId === "ChannelNodeDisplayName",
    )?.value?.["fi-FI"],
  };
};

export const buildHierarchyTree = (
  pimHierarchy: any,
  detailedNodeList: any,
) => {
  var result: any = {};

  const handleNode = (nodeId: string, path: string) => {
    const pathItems = path.split("/").slice(1);
    let parsedPath = pathItems.map((item) => item + ".nodes").join(".");
    const currentPath = path + "/" + nodeId;
    const nodeItem: NodeItem = detailedNodeList.find(
      (nodeItem: NodeItem) => nodeItem.entityId === Number(nodeId),
    );
    const lodashPath =
      parsedPath.length === 0 ? nodeId : parsedPath + "." + nodeId;

    _.setWith(result, lodashPath, parseNodeItem(nodeItem, currentPath), Object);
    const nodes = Object.keys(_.get(pimHierarchy, lodashPath + ".nodes", {}));
    if (nodes.length > 0) {
      nodes.forEach((nodeId: string) => {
        handleNode(nodeId, currentPath);
      });
    }
  };

  handleNode("29395", "28740");
  return result["29395"].nodes;
};
