import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { OfferItem } from '../../../../../shared/types';
import { useUpdateOfferItem } from '../../../hooks/useUpdateOfferItem';
import { memo } from 'react';
import { isSalesItem } from '../../../../../shared/helpers/isSalesItem';

type OfferPricingBasisRendererProps = ICellRendererParams<OfferItem>;

export const OfferPricingBasisRenderer = memo(
  (params: OfferPricingBasisRendererProps) => {
    const { column, data } = params;
    const { offerItemId, type } = data || {};
    const field = column?.getColId() as keyof OfferItem;

    // update offer sheet item mutation
    const { mutate: updateOfferItem } = useUpdateOfferItem();

    // show empty cell for sales items
    if (isSalesItem(type)) {
      return <></>;
    }

    const handleChange = (event: SelectChangeEvent) => {
      const value = event.target.value;
      if (value && field) {
        updateOfferItem({
          id: String(offerItemId),
          item: {
            [field]: value,
          },
        });
      }
    };

    return (
      <FormControl fullWidth variant="standard">
        <Select
          sx={{
            fontSize: 13,
            height: 30,
            pl: '11px',
            '&:after, &:before': {
              display: 'none',
            },
            '.MuiSelect-select.MuiSelect-select': {
              backgroundColor: 'transparent',
              pb: 0,
            },
          }}
          value={field ? data?.[field] || '' : ''}
          size="small"
          onChange={handleChange}
        >
          <MenuItem dense value="DAY">
            Päivähinta
          </MenuItem>
          <MenuItem dense value="MONTH">
            Kuukausihinta
          </MenuItem>
        </Select>
      </FormControl>
    );
  },
);
