import React, {
  useContext,
  useState,
  KeyboardEventHandler,
} from 'react';

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';

import NotificationProvider from '../../../providers/Notification/NotificationProvider';

export type ClientIdType = {
  readonly label: string;
  readonly value: string;
};

export const getClientIdValues = (
  clientids: readonly ClientIdType[] | [],
) => {
  return clientids
    ? clientids?.map((id: { value: string }) => id.value)
    : [];
};

export const createOption = (label: string) => ({
  label,
  value: label,
});

interface CustomerSelectorProps {
  disabled: boolean;
  clientIds: [] | readonly ClientIdType[];
  setClientIds: React.Dispatch<
    React.SetStateAction<[] | readonly ClientIdType[]>
  >;
}

const CustomerSelector: React.FC<CustomerSelectorProps> = ({
  disabled,
  clientIds,
  setClientIds,
}) => {
  const { setNotification } = useContext(NotificationProvider);
  const [clientIdInput, setClientIdInput] = useState<string>();

  const handleInputChange = (inputValue: string) => {
    setClientIdInput(inputValue);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (
    event,
  ) => {
    if (!clientIdInput) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const newClientId = createOption(clientIdInput.toString());
        if (
          getClientIdValues(clientIds).includes(newClientId.value)
        ) {
          setNotification({
            type: 'SNACKBAR',
            duration: 3000,
            severity: 'error',
            message: 'Asiakasnumero on jo lisätty!',
          });
          setClientIdInput('');
          return;
        }

        if (!Number(clientIdInput)) {
          setNotification({
            type: 'SNACKBAR',
            duration: 3000,
            severity: 'error',
            message: 'Asiakasnumero ei voi sisältää kirjaimia!',
          });
          setClientIdInput('');
          return;
        } else {
          setClientIdInput('');
          setClientIds([...clientIds, newClientId]);
          event?.preventDefault();
        }
    }
  };

  const handleChange = (
    value: OnChangeValue<ClientIdType, true>,
    _actionMeta: ActionMeta<ClientIdType>,
  ) => {
    setClientIds(value);
  };

  const invalidClientId: boolean = clientIdInput
    ? clientIdInput?.length > 0 && !!!Number(clientIdInput)
    : false;

  return (
    <>
      <CreatableSelect
        isDisabled={disabled}
        components={{
          DropdownIndicator: null,
        }}
        inputValue={clientIdInput}
        isMulti
        menuIsOpen={false}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        placeholder="Lisää asiakasnumerot... *"
        value={clientIds}
        aria-invalid={invalidClientId}
        aria-errormessage="client-id-error"
      />
      {invalidClientId && (
        <span
          className="client-id-error"
          style={{
            color: '#e00000',
          }}
        >
          Asiakasnumero ei voi sisältää kirjaimia!
        </span>
      )}
    </>
  );
};

export default CustomerSelector;
