import { createContext, useState } from 'react';

const Notification = createContext<any | null>(null);

type messageType = 'error' | 'warning' | 'info' | 'success';
type NotificationType = {
  type: 'SNACKBAR' | 'MODAL';
  duration?: number;
  severity: messageType;
  message: string;
  title?: string;
  onAccept?: () => void;
};

const NotificationProvider = (props: any) => {
  const [notification, setNotification] = useState<
    NotificationType | undefined
  >(undefined);
  const children = props.children;

  return (
    <Notification.Provider
      value={{
        notification,
        setNotification,
      }}
    >
      {children}
    </Notification.Provider>
  );
};

export default Notification;

export { NotificationProvider };
