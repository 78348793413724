import { MenuItem, Select } from '@mui/material';
import { PricingBasis } from '../../../../../../shared/types';

interface PricingBasisSelectorProps {
  value: PricingBasis;
  onPricingBasisChange: (value: PricingBasis) => void;
}

const PricingBasisSelector: React.FC<PricingBasisSelectorProps> = ({
  value,
  onPricingBasisChange,
}) => {
  return (
    <Select
      value={value}
      onChange={(event) =>
        onPricingBasisChange(event.target.value as PricingBasis)
      }
      sx={{
        width: '100%',
        my: 1,
      }}
    >
      <MenuItem value={'DAY'}>Vain päivähinnat</MenuItem>
      <MenuItem value={'MONTH'}>Vain kuukausihinnat</MenuItem>
      <MenuItem value={'BOTH'}>Molemmat</MenuItem>
    </Select>
  );
};

export default PricingBasisSelector;
