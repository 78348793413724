import React from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import DefaultTooltip from '../Tooltips/DefaultTooltip';
import {
  AgGridColumnView,
  PricingBasis,
} from '../../../../shared/types';

type SelectPricingColumnsProps = {
  gridColumnView: AgGridColumnView;
  pricingBasis: PricingBasis;
  selectableColumns: { [key in PricingBasis]: AgGridColumnView };
  handleColumnViewChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
};

const SelectPricingColumns: React.FC<SelectPricingColumnsProps> = ({
  gridColumnView,
  pricingBasis,
  selectableColumns,
  handleColumnViewChange,
}) => {
  const isDisabled =
    pricingBasis === 'DAY' || pricingBasis === 'MONTH';
  return (
    <RadioGroup
      value={gridColumnView}
      onChange={handleColumnViewChange}
      row
      style={{ display: 'inline' }}
    >
      <DefaultTooltip title="Näytä vain päivähinnat">
        <FormControlLabel
          disabled={isDisabled}
          value={selectableColumns.DAY}
          control={<Radio />}
          label="Päivähinnat"
        />
      </DefaultTooltip>
      <DefaultTooltip title="Näytä vain kuukausihinnat">
        <FormControlLabel
          disabled={isDisabled}
          value={selectableColumns.MONTH}
          control={<Radio />}
          label="Kuukausihinnat"
        />
      </DefaultTooltip>
      <DefaultTooltip title="Näytä päivä- ja kuukausihinnat">
        <FormControlLabel
          disabled={isDisabled}
          value={selectableColumns.BOTH}
          control={<Radio />}
          label="Molemmat"
        />
      </DefaultTooltip>
    </RadioGroup>
  );
};

export default SelectPricingColumns;
