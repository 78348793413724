import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { updateOfferSheetItem } from '../services/offerSheets';
import {
  DatabaseOfferWithItems,
  UpdateOfferItemResponseBody,
} from '../../../shared/types/offers';
import { OFFER_SHEET_QUERY_KEY } from '../../../shared/constants';
import { produce } from 'immer';
import { AxiosError } from 'axios';

type DataResponseType = UpdateOfferItemResponseBody;
type ErrorType = AxiosError;
type VariablesType = Parameters<typeof updateOfferSheetItem>[0];

type UseUpdateOfferItemParams = Omit<
  UseMutationOptions<DataResponseType, ErrorType, VariablesType>,
  'mutationFn'
>;

export const useUpdateOfferItem = (
  options: UseUpdateOfferItemParams = {},
) => {
  const queryClient = useQueryClient();
  return useMutation<DataResponseType, ErrorType, VariablesType>({
    ...options,
    mutationFn: updateOfferSheetItem,
    onSuccess: (data, variables, context) => {
      // update item to offer query cache
      queryClient.setQueryData<DatabaseOfferWithItems>(
        [OFFER_SHEET_QUERY_KEY, `${data.offerSheetId}`],
        (oldData) =>
          produce(oldData, (updatedData) => {
            if (updatedData) {
              const updatedItemIndex = updatedData.items.findIndex(
                (item) => item.id === data.id,
              );
              if (updatedItemIndex !== -1) {
                updatedData.items[updatedItemIndex] = data;
              }
            }
          }),
      );
      options?.onSuccess?.(data, variables, context);
    },
  });
};
