import { useContext, useRef } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {
  ExternalPricingSheetItem,
  PreSelectionType,
  PricingSheetItemResponse,
  PricingSheetRow,
} from '../../../../shared/types';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import PricingProvider from '../../providers/Pricing/PricingProvider';

import SearchExternalSheet from './SearchExternalSheet';

import DefaultTooltip from '../Tooltips/DefaultTooltip';
import RowsProvider from '../../providers/Rows/RowsProvider';

const PreSelectionToolTipText =
  'Esivalinta on valmiiksi valikoitu joukko tuotteita, jotka voit valita hinnoiteltavaksi hinnastoon. Voit valita esivalinnan ja täydentää tai supistaa sitä haluamillasi tuotteilla.';

const compactPreSelectionToolTipText = `
Valitse valmiiksi yleisimmin käytetyt tuotteet. Soveltuu parhaiten pienille ja keskisuurille asiakkaille.
`;

const largePreSelectionToolTipText = `
Valitse valmiiksi suurille asiakkaille soveltuvat tuotteet. Ei sisällä esimerkiksi lisäosia, erikoistuotteita tai vastaavia, jotka hinnoitellaan vain yleishinnastoissa.
`;

const clearPreSelectionToolTioText = `Poista kaikki esivalinnat tuotteista`;

interface SelectPreSelectionsProps {
  rows: PricingSheetRow[];
  handlePreSelectionByCatClass: any;
  selectedRows: PricingSheetRow[];
}

const SelectPreSelection: React.FC<SelectPreSelectionsProps> = ({
  rows,
  handlePreSelectionByCatClass,
  selectedRows,
}) => {
  const { setNotification } = useContext(NotificationProvider);
  const {
    setSelectedPreSelection,
    selectedPreSelection,
    externalPricingSheetId,
    setExternalPricingSheetId,
    comparisonPricingSheets,
  } = useContext(PricingProvider);
  const { clearPreSelectedRows } = useContext(RowsProvider);

  const selectExternalSheetRef: any = useRef();

  const getFilteredRows = (id: PreSelectionType) => {
    switch (id) {
      case PreSelectionType.COMPACT:
        return rows.filter(
          (row: PricingSheetRow) => row.belongsToCompactPreSelection,
        );
      case PreSelectionType.LARGE:
        return rows.filter(
          (row: PricingSheetRow) => row.belongsToLargePreSelection,
        );
      case PreSelectionType.EMPTY:
        return [];
      default:
        return rows;
    }
  };

  const setPreSelectionNotification = (id: PreSelectionType) => {
    switch (id) {
      case PreSelectionType.COMPACT:
        setNotification({
          type: 'SNACKBAR',
          severity: 'success',
          message: `Valittu tuotteet suppean esivalinnan pohjalta!`,
          duration: 2000,
        });
        break;
      case PreSelectionType.LARGE:
        setNotification({
          type: 'SNACKBAR',
          severity: 'success',
          message: `Valittu tuotteet laajan esivalinnan pohjalta!`,
          duration: 2000,
        });
        break;
      case PreSelectionType.EMPTY:
        setNotification({
          type: 'SNACKBAR',
          severity: 'success',
          message: `Tuotevalinnat poistettu!`,
          duration: 2000,
        });
        break;
      default:
        setNotification({
          type: 'SNACKBAR',
          severity: 'error',
          message: `Tuntematon esivalintatyyppi!`,
          duration: 2000,
        });
    }
  };

  const selectPreSelectionItems = (
    preSelectionType: PreSelectionType,
  ) => {
    setPreSelectionNotification(preSelectionType);
    setSelectedPreSelection(preSelectionType);

    const rowsInPreSelection = getFilteredRows(preSelectionType);
    const inSelection = rowsInPreSelection.map(
      (baseRow) => baseRow.catClass,
    );

    handlePreSelectionByCatClass(inSelection);
    selectExternalSheetRef?.current?.resetState();
  };

  const onClickClear = () => {
    selectPreSelectionItems(PreSelectionType.EMPTY);
    selectExternalSheetRef?.current?.resetState();
  };

  const setPreselectedValuesByExternalItems = (
    externalPricingSheetItems: ExternalPricingSheetItem[],
  ) => {
    const catClassesToSelect = externalPricingSheetItems.map(
      (externalItem) => {
        return externalItem.item.catClass;
      },
    );
    handlePreSelectionByCatClass(catClassesToSelect);
  };

  const setPreselectedValuesByPricingSheet = (
    pricingSheetItems: PricingSheetItemResponse[],
  ) => {
    const catClassesToSelect = pricingSheetItems.map(
      (pricingSheetItem) => {
        return pricingSheetItem.item.catClass;
      },
    );
    handlePreSelectionByCatClass(catClassesToSelect);
  };

  return (
    <>
      <Grid
        sx={{
          mt: 1,
          mb: 2,
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          color="primary"
          sx={{ mb: 2 }}
        >
          <DefaultTooltip
            title={PreSelectionToolTipText}
            placement="top"
          >
            <span>Esivalinta</span>
          </DefaultTooltip>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: 1 / 1,
          }}
        >
          <Box
            sx={{
              width: 6 / 7,
            }}
          >
            <Grid
              container
              sx={{
                gap: 1,
                flexDirection: 'row',
              }}
            >
              <DefaultTooltip
                title={compactPreSelectionToolTipText}
                placement="top"
              >
                <Button
                  variant="outlined"
                  onClick={() =>
                    selectPreSelectionItems(PreSelectionType.COMPACT)
                  }
                >
                  Suppea esivalinta
                </Button>
              </DefaultTooltip>
              <DefaultTooltip
                title={largePreSelectionToolTipText}
                placement="top"
              >
                <Button
                  variant="outlined"
                  onClick={() =>
                    selectPreSelectionItems(PreSelectionType.LARGE)
                  }
                >
                  Laaja esivalinta
                </Button>
              </DefaultTooltip>

              <Box>
                <SearchExternalSheet
                  ref={selectExternalSheetRef}
                  comparisonPricingSheets={comparisonPricingSheets}
                  tooltipTitle="Voit valita hinnaston tuotteet toisen hinnaston tuotteiden pohjalta."
                  selectPlaceholder="Valitse tuotteet toisesta hinnastosta"
                  handleExternalItemsResponse={
                    setPreselectedValuesByExternalItems
                  }
                  handlePricingSheetItemsResponse={
                    setPreselectedValuesByPricingSheet
                  }
                  externalPricingSheetId={externalPricingSheetId}
                  setExternalPricingSheetId={
                    setExternalPricingSheetId
                  }
                  handleClear={() => {
                    if (
                      !selectedPreSelection ||
                      !(
                        selectedPreSelection !==
                          PreSelectionType.COMPACT ||
                        selectedPreSelection !==
                          PreSelectionType.LARGE ||
                        selectedPreSelection !==
                          PreSelectionType.EMPTY
                      )
                    ) {
                      clearPreSelectedRows();
                    }
                  }}
                />
              </Box>
            </Grid>
          </Box>
          <Box
            sx={{
              width: 1 / 7,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <DefaultTooltip
              title={clearPreSelectionToolTioText}
              placement="top"
            >
              <Button
                disabled={!(selectedRows.length > 0)}
                variant="outlined"
                onClick={() => onClickClear()}
              >
                Tyhjennä
              </Button>
            </DefaultTooltip>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default SelectPreSelection;
