import { PricingSheetRow } from '../../../../../../shared/types';
import { itemFilter } from '../../../../utils/filters';

const updatePreSelectedStatuses = (
  id: string,
  pricingSheetRows: PricingSheetRow[],
  selectedStatus: boolean,
  itemFilterValue?: string,
) => {
  let filtered: PricingSheetRow[];

  filtered = pricingSheetRows.filter(
    (pricingSheetRow) =>
      pricingSheetRow['prefixProductGroup'] === id ||
      pricingSheetRow['prefixProductLine'] === id ||
      pricingSheetRow['prefixCategory'] === id ||
      pricingSheetRow['catClass'] === id ||
      pricingSheetRow['prefixPimProductGroup'] === id ||
      pricingSheetRow['prefixPimProductLine'] === id ||
      pricingSheetRow['prefixPimCategory'] === id,
  );

  if (id === 'allRows') {
    filtered = pricingSheetRows;
  }

  if (itemFilterValue) {
    filtered = filtered.filter((pricingSheetRow: PricingSheetRow) =>
      itemFilter(pricingSheetRow, itemFilterValue),
    );
  }

  const newHierarchy = pricingSheetRows.map((obj) => {
    if (filtered.includes(obj)) {
      return {
        ...obj,
        preSelected: !selectedStatus,
      };
    } else {
      return obj;
    }
  });

  return newHierarchy;
};

export default updatePreSelectedStatuses;
