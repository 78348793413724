import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

type OfferStepActionButtonProps = Omit<ButtonProps, 'type'> & {
  loading?: boolean;
  type: 'BACK' | 'NEXT';
};

const OfferStepActionButton = ({
  disabled,
  loading = false,
  onClick,
  type,
  ...props
}: OfferStepActionButtonProps) => {
  const buttonLabel = type === 'NEXT' ? 'Seuraava' : 'Edellinen';
  const buttonProps: ButtonProps =
    type === 'NEXT'
      ? {
          variant: 'contained',
          sx: { ml: 'auto' },
        }
      : {
          variant: 'outlined',
        };
  return (
    <Button
      {...props}
      {...buttonProps}
      disabled={loading || disabled}
      endIcon={
        loading && <CircularProgress thickness={4} size={16} />
      }
      onClick={onClick}
    >
      {buttonLabel}
    </Button>
  );
};

export default OfferStepActionButton;
