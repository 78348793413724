import React from 'react';
import { api } from '../index';

export enum LoadingState {
  IsLoading,
  Initial,
}

export const getText = async (url: string) => {
  return await api.get(url).then((response) => {
    return response.data;
  });
};

const getJson = async <T>(url: string) =>
  await api.get<T>(url).then((response) => {
    return response.data;
  });

export const useApi = <T>(
  url: string,
  conditionalFn?: () => boolean,
): [T | null, LoadingState, any | null] => {
  const [data, setData] = React.useState<T | null>(null);
  const [loading, setLoading] = React.useState<LoadingState>(
    LoadingState.Initial,
  );
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    return () => {
      setData(null);
      setLoading(LoadingState.Initial);
    };
  }, []);

  React.useEffect(() => {
    if (conditionalFn && !conditionalFn()) {
      return;
    }
    (async () => {
      try {
        setError(null);
        setLoading(LoadingState.IsLoading);
        if (url) {
          const form = await getJson<T>(url);

          setData(form);
        }
      } catch (e: any) {
        setError(e.toJSON());
      } finally {
        setLoading(LoadingState.Initial);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
  return [data, loading, error];
};
