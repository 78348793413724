import {
  Nullable,
  PricingSheetRow,
  RoundingBasis,
} from '../../../../../shared/types';

import { PRICE_FACTOR } from '../../../../../shared/constants';
import { ValueGetterParams } from 'ag-grid-community';

const contractPriceValueGetter = (
  params: ValueGetterParams<PricingSheetRow>,
  roundingBasis: RoundingBasis,
  value: Nullable<number>,
) => {
  const showByOfferText = params.data?.showByOfferText;

  if (showByOfferText) {
    return showByOfferText
      ? 'Tarjouksen mukaan'
      : value
        ? value
        : null;
  }

  const dividedDayPrice = value ? value / PRICE_FACTOR : value;

  if (roundingBasis === 'THREESIGNIFICANT') {
    return dividedDayPrice
      ? Number(dividedDayPrice.toPrecision(3))
      : null;
  }

  const digits = roundingBasis === 'TWODECIMALS' ? 2 : 6;

  return dividedDayPrice
    ? Number(dividedDayPrice.toFixed(digits))
    : null;
};

export default contractPriceValueGetter;
