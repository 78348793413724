import React, { useContext, useState, useEffect } from 'react';

import Slider from '@mui/material/Slider';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';

import FormControl from '@mui/material/FormControl';

import NumberFormat from 'react-number-format';

import BackgroundProvider from '../../../../providers/Background/BackgroundProvider';
import PricingProvider from '../../../../providers/Pricing/PricingProvider';

import { formatRevenue } from '../../InitialInformationStep';
import { currencyMultiplier } from '../../../../../../shared/constants';

interface RevenueSliderProps {
  initial: number;
  changeValuesEnabled: boolean;
}

const convertRevenue = (value: string | number) =>
  typeof value === 'string' ? Number(value) / 5000 : value;

const RevenueSlider: React.FC<RevenueSliderProps> = ({
  initial,
  changeValuesEnabled,
}) => {
  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );
  const { pricingSheetId } = useContext(PricingProvider);

  const [value, setValue] = useState<number>(
    backgroundInfo?.revenue
      ? convertRevenue(backgroundInfo.revenue)
      : initial,
  );

  const minMarks = [
    {
      value: 20 * currencyMultiplier,
      label: '100 000 €',
    },
    {
      value: 50 * currencyMultiplier,
      label: '250 000 €',
    },
    {
      value: 100 * currencyMultiplier,
      label: '500 000 €',
    },
    {
      value: 150 * currencyMultiplier,
      label: '750 000 €',
    },
    {
      value: 200 * currencyMultiplier,
      label: '1 000 000 €',
    },
  ];

  const largeMarks = [
    {
      value: 200 * currencyMultiplier,
      label: '1 000 000 €',
    },
    {
      value: 50000 * currencyMultiplier,
      label: '250 000 000 €',
    },
    {
      value: 100000 * currencyMultiplier,
      label: '500 000 000 €',
    },
    {
      value: 150000 * currencyMultiplier,
      label: '750 000 000 €',
    },
    {
      value: 200000 * currencyMultiplier,
      label: '1 000 000 000 €',
    },
  ];

  useEffect(() => {
    setBackgroundInfo({
      ...backgroundInfo,
      revenue: Number(value.toPrecision(2)),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (backgroundInfo?.revenueOverMillion && value < 200) {
      setValue(200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundInfo]);

  const handleChange = (_event: any, newValue: any) => {
    setValue(Number((newValue / currencyMultiplier).toPrecision(2)));
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const trimmed =
      Number(
        event.target.value
          .substring(0, event.target.value.length - 1)
          .replace(/\s/g, ''),
      ) / 5000;

    setValue(Number(trimmed.toFixed(2)));
    setBackgroundInfo({
      ...backgroundInfo,
      revenue: Number(trimmed.toFixed(2)),
      revenueOverMillion: trimmed > 200,
    });
  };

  useEffect(() => {
    if (backgroundInfo && !pricingSheetId) {
      const { revenueOverMillion } = backgroundInfo;
      const revenue = convertRevenue(backgroundInfo.revenue);
      if (revenueOverMillion && revenue <= 200) {
        setValue(200);
      }
      if (!revenueOverMillion && revenue > 200) {
        setValue(200);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundInfo]);

  return (
    <Box display="flex">
      <Box
        sx={{
          pl: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box>
          <FormControl
            focused
            className="col "
            variant="outlined"
            sx={{ width: 140 }}
          >
            <NumberFormat
              customInput={TextField}
              thousandSeparator={' '}
              value={Math.floor(value * currencyMultiplier)}
              onChange={handleTextFieldChange}
              autoComplete="off"
              suffix={' €'}
              allowedDecimalSeparators={['.', ',']}
              disabled={
                pricingSheetId && !changeValuesEnabled ? true : false
              }
            />
          </FormControl>
        </Box>
        {backgroundInfo?.revenueOverMillion ||
        backgroundInfo?.revenue > 200 ? (
          <Slider
            valueLabelDisplay={'auto'}
            valueLabelFormat={`${formatRevenue(value)}`}
            sx={{ ml: 5, mr: 5 }}
            value={value * currencyMultiplier}
            onChange={handleChange}
            aria-label="revenue"
            step={10000 * currencyMultiplier}
            marks={largeMarks}
            min={200 * currencyMultiplier}
            max={200000 * currencyMultiplier}
          />
        ) : (
          <Slider
            valueLabelDisplay={'auto'}
            valueLabelFormat={`${formatRevenue(value)}`}
            sx={{ ml: 2, mr: 2 }}
            value={value * currencyMultiplier}
            onChange={handleChange}
            aria-label="revenue"
            step={2 * currencyMultiplier}
            marks={minMarks}
            min={0}
            max={200 * currencyMultiplier}
            disabled={
              pricingSheetId && !changeValuesEnabled ? true : false
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default RevenueSlider;
