import { ValueGetterParams } from 'ag-grid-community';
import {
  OfferItem,
  RoundingBasis,
} from '../../../../../shared/types';
import { getPriceRounded } from '../../../../../shared/getPriceRounded';

export const ramiturvaPriceGetter = (
  params: ValueGetterParams<OfferItem>,
  roundingBasis: RoundingBasis,
) => {
  if (!params.data) return;

  const {
    data: { pricingBasis, ramiturvaMonthPrice, ramiturvaDayPrice },
  } = params;

  let price;

  if (pricingBasis === 'MONTH') {
    price = ramiturvaMonthPrice;
  } else if (pricingBasis === 'DAY') {
    price = ramiturvaDayPrice;
  } else {
    return 0;
  }

  return getPriceRounded(price, roundingBasis);
};
