import { Tooltip } from '@mui/material';
import { OfferItem } from '../../../../../shared/types';
import { ICellRendererParams } from 'ag-grid-community';
import { memo } from 'react';

export const ProposalPriceRenderer = memo(
  ({ value, data }: ICellRendererParams<OfferItem>) => {
    const { surplusEquipmentItem, showSurplusPercentage } =
      data || {};
    const changePercentage = surplusEquipmentItem?.changePercentage
      ? Number(surplusEquipmentItem.changePercentage)
      : undefined;
    return (
      <>
        {value ? value + ' €' : 0 + ' €'}
        {showSurplusPercentage && changePercentage ? (
          <Tooltip title="Ylitarjonta muutosprosentti">
            <span
              style={{
                color: changePercentage >= 0 ? 'green' : 'red',
              }}
            >
              {' (' + changePercentage + '%)'}
            </span>
          </Tooltip>
        ) : null}
      </>
    );
  },
);
