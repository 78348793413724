import { useState, useCallback } from 'react';
import { AgGridColumnView, ItemGroup } from '../../../shared/types';
import { ColDef } from 'ag-grid-community';

import { groupedTableClasses } from '..';
import { useLocalStorage } from './useLocalStorage';
import { viewToColumns } from '../components/AgGrid/Columns/ColumnsBase';

export const miraGroupingOptions = [
  {
    name: 'Tuoteryhmä',
    value: 'productGroup',
  },
  {
    name: 'Tuotelinja',
    value: 'productLine',
  },
  {
    name: 'Kategoria',
    value: 'category',
  },
] as ItemGroup[];

export const pimGroupingOptions = [
  {
    name: 'Tuoteryhmä',
    value: 'pimProductGroup',
  },
  {
    name: 'Tuotelinja',
    value: 'pimProductLine',
  },
  {
    name: 'Kategoria',
    value: 'pimCategory',
  },
] as ItemGroup[];

export type GroupingType = 'MIRA' | 'PIM';

const getGridGroupingValues = (vals: ItemGroup[]) => {
  return vals.map((v: ItemGroup) => v.value);
};

const getDefaultGroupingValues = (groupingStyle: GroupingType) => {
  return groupingStyle === 'MIRA'
    ? miraGroupingOptions
    : pimGroupingOptions;
};

export const useColumns = (
  defaultColumns: AgGridColumnView,
  optionalColumnProps?: any,
) => {
  const classes = groupedTableClasses();

  const [groupingStyle, setGroupingStyle] = useLocalStorage(
    'groupingStyle',
    'PIM',
  ); // default PIM

  const [gridColumnView, setGridColumnView] =
    useState<AgGridColumnView>(defaultColumns);

  const [groupingValues, setGroupingValues] = useState<ItemGroup[]>(
    getDefaultGroupingValues(groupingStyle),
  );

  const setDefaultColumnDefs = () => {
    const columns = viewToColumns(gridColumnView);

    return columns({
      classes: classes,
      gridGroupingValues: getGridGroupingValues(groupingValues),
      optionalColumnProps,
    });
  };

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(
    setDefaultColumnDefs(),
  );

  const updateColumnDefs = (
    newTableView: AgGridColumnView,
    newGroupingValues: ItemGroup[],
  ) => {
    const columns = viewToColumns(newTableView);
    setColumnDefs(
      columns({
        classes: classes,
        gridGroupingValues: getGridGroupingValues(newGroupingValues),
        optionalColumnProps,
      }),
    );
  };

  const updateGroupingValues = (newGroupingValues: ItemGroup[]) => {
    setGroupingValues(newGroupingValues);
    updateColumnDefs(gridColumnView, newGroupingValues);
  };

  const updateGridColumnView = (newTableView: AgGridColumnView) => {
    setGridColumnView(newTableView);
    updateColumnDefs(newTableView, groupingValues);
  };

  const setDefaultGroupingValues = (groupingType: GroupingType) => {
    let newGroupingValues;
    if (groupingType === 'MIRA') {
      newGroupingValues = miraGroupingOptions;
      setGroupingValues(miraGroupingOptions);
    } else {
      newGroupingValues = pimGroupingOptions;
      setGroupingValues(pimGroupingOptions);
    }
    updateColumnDefs(gridColumnView, newGroupingValues);
    setGroupingStyle(groupingType);
  };

  const getMainMenuItems = useCallback((_params) => {
    const menuItems = [
      'pinSubMenu',
      'resetColumns',
      'expandAll',
      'contractAll',
    ];
    return menuItems;
  }, []);

  return {
    columnDefs,
    groupingStyle,
    groupingValues,
    gridColumnView,
    updateGroupingValues,
    setDefaultGroupingValues,
    updateGridColumnView,
    getMainMenuItems,
  } as const;
};
