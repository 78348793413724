import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  CoefficientColumn,
  CoefficientType,
  IndustryName,
  ItemIndustryPercent,
  PercentCoefficientType,
} from '../../../../../shared/types';
import {
  calculateDayPrice,
  calculateMonthPrice,
} from '../../../../../shared/calculatePrices';
import {
  DAY_PRICE_COEFFICIENTS,
  MONTH_PRICE_COEFFICIENTS,
  PRICE_FACTOR,
} from '../../../../../shared/constants';

import { getCalculationValue } from './helpers/valueHelpers';
import {
  ChangeType,
  EditItemsDialogChangeWithKey,
  EditItemsDialogPricingBasis,
  TabProps,
} from './types';
import { percentualNumberChange } from '../../../../../shared/percentualNumberChange';
import { getPrice } from '../../AgGrid/Renderers/CoefficientPercentRenderer';
import { roundToTwoDecimal } from '../../../../../shared/roundToTwoDecimal';

type IndustryPercentagesTabProps<
  Basis extends EditItemsDialogPricingBasis,
> = TabProps<Basis> & {
  changeTypeIndustryPercents: ChangeType;
  priceCoefficients: EditItemsDialogChangeWithKey<
    CoefficientType<Basis>
  >[];
  priceIndustryPercents: EditItemsDialogChangeWithKey<
    PercentCoefficientType<Basis>
  >[];
  setPriceIndustryPercents: (
    change: EditItemsDialogChangeWithKey<
      PercentCoefficientType<Basis>
    >[],
  ) => void;
  industry: IndustryName;
  itemIndustryPercents?: ItemIndustryPercent[];
};

const IndustryPercentagesTab = <
  Basis extends EditItemsDialogPricingBasis,
>(
  props: IndustryPercentagesTabProps<Basis>,
) => {
  const { changeTypeIndustryPercents, pricingBasis = 'DAY' } = props;
  const unit = pricingBasis === 'DAY' ? 'pv' : 'kk';
  const tabLabel =
    changeTypeIndustryPercents === ChangeType.ABSOLUTE
      ? `Syötä uudet muutosprosentit (${unit}):`
      : `Syötä prosentuaalinen (%) muutos (${unit}):`;
  const coefficients =
    pricingBasis === 'DAY'
      ? DAY_PRICE_COEFFICIENTS
      : MONTH_PRICE_COEFFICIENTS;

  return (
    <FormControl
      component={'fieldset'}
      variant={'standard'}
      fullWidth
    >
      <FormLabel component={'legend'}>{tabLabel}</FormLabel>
      <Box sx={{ mt: 2 }}>
        <List sx={{ display: 'flex', alignItems: 'flex-start' }}>
          {coefficients.map((coefficient) => (
            <ListItem
              key={coefficient.key}
              disableGutters
              sx={{
                pl: 1,
                pr: 1,
                '&:first-of-type': {
                  pl: 0,
                },
              }}
            >
              <IndustryPercentageListItem
                coefficient={coefficient}
                {...props}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </FormControl>
  );
};

type CoefficientListItemProps<
  Basis extends EditItemsDialogPricingBasis,
> = IndustryPercentagesTabProps<Basis> & {
  coefficient: CoefficientColumn<EditItemsDialogPricingBasis>;
};

const IndustryPercentageListItem = <
  Basis extends EditItemsDialogPricingBasis,
>({
  changeType,
  changeTypeCommon,
  changeTypeIndustryPercents,
  coefficient,
  depreciationPeriod,
  highlightedItem,
  industry,
  itemIndustryPercents,
  priceCoefficients,
  priceIndustryPercents,
  pricingBasis,
  rentalReadyPrice,
  setPriceIndustryPercents,
  targetUtilRate,
}: CoefficientListItemProps<Basis>) => {
  const calculatePriceFn =
    pricingBasis === 'DAY' ? calculateDayPrice : calculateMonthPrice;
  // current percent value in input
  const value =
    changeTypeIndustryPercents === ChangeType.ABSOLUTE
      ? priceIndustryPercents.find(
          ({ key }) => key === coefficient.industryPercentKey,
        )?.absoluteValue ?? undefined
      : priceIndustryPercents.find(
          ({ key }) => key === coefficient.industryPercentKey,
        )?.percentValue ?? undefined;

  // current coefficient value in input
  const coefficientValue =
    changeType === ChangeType.ABSOLUTE
      ? priceCoefficients.find(({ key }) => key === coefficient.key)
          ?.absoluteValue ?? undefined
      : priceCoefficients.find(({ key }) => key === coefficient.key)
          ?.percentValue ?? undefined;

  const rentalReadyPriceForCalculation = getCalculationValue(
    rentalReadyPrice,
    changeTypeCommon,
    highlightedItem,
    'rentalReadyPrice',
  );

  const depreciationPeriodForCalculation = getCalculationValue(
    depreciationPeriod,
    changeTypeCommon,
    highlightedItem,
    'depreciationPeriod',
  );

  const targetUtilRateForCalculation = getCalculationValue(
    targetUtilRate,
    changeTypeCommon,
    highlightedItem,
    'targetUtilRate',
  );

  // current itemIndustryPercent item (saved in db)
  const itemToLookup =
    highlightedItem &&
    itemIndustryPercents?.find(
      (i) =>
        i.catClass === highlightedItem.catClass &&
        i.industry?.name === industry,
    );

  // current itemIndustryPercent value (saved in db)
  const currentPercent =
    itemToLookup && itemToLookup[coefficient.industryPercentKey]
      ? Number(itemToLookup[coefficient.industryPercentKey]) / 100
      : null;

  // current absolute value in input
  const absoluteInputPercent =
    priceIndustryPercents?.find(
      (p) => p.key === coefficient.industryPercentKey,
    )?.absoluteValue ?? null;

  // current percentual value in input
  const percentualInputPercent =
    priceIndustryPercents?.find(
      (p) => p.key === coefficient.industryPercentKey,
    )?.percentValue ?? null;

  const newItemIndustryPercent =
    highlightedItem && percentualInputPercent
      ? percentualNumberChange(currentPercent, percentualInputPercent)
      : currentPercent;

  const coefficientValueForCalc =
    coefficientValue ??
    (highlightedItem
      ? Number(highlightedItem[coefficient.key]) / 100
      : null);

  // calculate new price if change type is percentual
  const getCalculatedNewPricePercent = () => {
    const newCoefficient = highlightedItem
      ? percentualNumberChange(
          Number(highlightedItem[coefficient.key]),
          priceIndustryPercents &&
            Number(
              priceIndustryPercents.find(
                (c) => c.key === coefficient.industryPercentKey,
              )?.percentValue,
            ),
        )
      : null;

    const newPercent = itemToLookup
      ? percentualNumberChange(
          Number(itemToLookup[coefficient.industryPercentKey]),
          priceIndustryPercents &&
            Number(
              priceIndustryPercents.find(
                (c) => c.key === coefficient.industryPercentKey,
              )?.percentValue,
            ),
        )
      : null;

    return newCoefficient &&
      newItemIndustryPercent &&
      coefficientValueForCalc &&
      newPercent
      ? calculatePriceFn(
          Number(
            percentualNumberChange(
              Number(highlightedItem?.rentalReadyPrice),
              rentalReadyPriceForCalculation,
            ) ?? highlightedItem?.rentalReadyPrice,
          ),
          Number(
            percentualNumberChange(
              Number(highlightedItem?.depreciationPeriod),
              depreciationPeriodForCalculation,
            ) ?? highlightedItem?.depreciationPeriod,
          ),
          Number(
            percentualNumberChange(
              Number(highlightedItem?.targetUtilRate),
              targetUtilRateForCalculation,
            ) ?? highlightedItem?.targetUtilRate,
          ) / 100,
          coefficientValueForCalc * (newPercent / 10000 + 1),
        )
      : null;
  };

  // current item price (based on values in db)
  const calculatedOldPrice = highlightedItem
    ? getPrice(highlightedItem, coefficient.key, currentPercent)
    : null;

  const changeCoefficient = absoluteInputPercent
    ? (100 + absoluteInputPercent) / 100
    : 1;

  const calculatedNewPriceAbsolute = calculatePriceFn(
    Number(
      rentalReadyPriceForCalculation ??
        highlightedItem?.rentalReadyPrice,
    ),
    Number(
      depreciationPeriodForCalculation ??
        highlightedItem?.depreciationPeriod,
    ),
    Number(
      targetUtilRateForCalculation ?? highlightedItem?.targetUtilRate,
    ) / 100,
    Number(coefficientValueForCalc),
  );

  const calculatedNewPriceAbsoluteWithItemIndustry =
    calculatedNewPriceAbsolute
      ? roundToTwoDecimal(
          calculatedNewPriceAbsolute * changeCoefficient,
        )
      : calculatedNewPriceAbsolute;

  const calculatedNewPricePercent = getCalculatedNewPricePercent();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TextField
        sx={{ minWidth: 140 }}
        label={
          changeTypeIndustryPercents === ChangeType.ABSOLUTE
            ? coefficient.name
            : `${coefficient.name} %-muutos`
        }
        id={`${pricingBasis.toLowerCase()}-coefficient-input-${
          coefficient.key
        }`}
        size={'small'}
        type={'number'}
        value={value ?? ''}
        onChange={(event) => {
          const updatedValue = event.target.value
            ? Number(event.target.value)
            : null;
          setPriceIndustryPercents(
            priceIndustryPercents.map((x) => {
              const key =
                changeTypeIndustryPercents === ChangeType.ABSOLUTE
                  ? 'absoluteValue'
                  : 'percentValue';
              return x.key === coefficient.industryPercentKey
                ? {
                    ...x,
                    [key]: updatedValue,
                  }
                : x;
            }),
          );
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[+0-9]*',
          min:
            changeTypeIndustryPercents === ChangeType.ABSOLUTE
              ? 0
              : undefined,
        }}
        InputProps={{
          endAdornment:
            changeTypeIndustryPercents === ChangeType.PERCENT ? (
              <InputAdornment position={'end'}>%</InputAdornment>
            ) : undefined,
        }}
      />
      <Typography sx={{ mt: 1, fontSize: '0.75rem' }}>
        <span>Vanha arvo: </span>
        <span>{currentPercent ? `${currentPercent} %` : '-'}</span>
        <Typography
          component={'span'}
          color={'text.secondary'}
          fontSize={'inherit'}
        >
          {calculatedOldPrice
            ? ` (${calculatedOldPrice.trimStart()})`
            : ''}
        </Typography>
      </Typography>
      {getDisplayValue(
        changeTypeIndustryPercents,
        calculatedNewPriceAbsoluteWithItemIndustry,
        calculatedNewPricePercent,
        absoluteInputPercent,
        percentualInputPercent,
        newItemIndustryPercent,
      )}
    </Box>
  );
};

const getDisplayValue = (
  changeType: ChangeType,
  absolutePrice: number | null,
  percentualPrice: number | null,
  absolutePercentage: number | null,
  percentualPercentage: number | null,
  industryPercentage: number | null,
) => {
  let content: ReactNode | string = 'Uusi arvo: -';
  if (
    changeType === 'ABSOLUTE' &&
    absolutePrice &&
    absolutePercentage
  ) {
    content = (
      <>
        <span>Uusi arvo: </span>
        <span>{`${absolutePercentage} %`}</span>
        <Typography
          component={'span'}
          color={'text.secondary'}
          fontSize={'inherit'}
        >
          {' '}
          ({roundToTwoDecimal(absolutePrice / PRICE_FACTOR)} €)
        </Typography>
      </>
    );
  }
  if (
    changeType === 'PERCENT' &&
    percentualPrice &&
    percentualPercentage &&
    industryPercentage
  ) {
    content = (
      <>
        <span>Uusi arvo: </span>
        <span>{roundToTwoDecimal(industryPercentage)} % </span>
        <Typography
          component={'span'}
          color={'text.secondary'}
          fontSize={'inherit'}
        >
          {' '}
          ({roundToTwoDecimal(percentualPrice / PRICE_FACTOR)} €)
        </Typography>
      </>
    );
  }
  return (
    <Typography sx={{ fontSize: '0.75rem' }}>{content}</Typography>
  );
};

export default IndustryPercentagesTab;
