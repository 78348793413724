import styled from '@emotion/styled';
import {
  CriticalEquipmentDateKey,
  CriticalEquipmentPriceKey,
  CriticalEquipmentRowState,
  PricingSheetRow,
} from '../../../../../shared/types';
import { ICellEditorParams } from 'ag-grid-community';
import React, {
  forwardRef,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import CellDatePicker, {
  CellDatePickerValue,
} from '../../Cells/common/CellDatePicker';
import validateDatePickerValue from '../../../utils/validateDatePickerValue';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';
import { Save, Clear } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import RowsProvider from '../../../providers/Rows/RowsProvider';
import ValidationTooltip from '../../Tooltips/ValidationTooltip';
import { validateCriticalEquipmentItem } from '../../../utils/validateCriticalEquipmentItem';
import { setDatetoStartOfUTCDay } from '../../../utils/formatDateTimes';

const initialState: CriticalEquipmentRowState = {
  from: new Date(),
  minPriceDay: '',
  minPriceMonth: '',
  to: new Date(),
};

const commonPriceFieldProps: Pick<
  NumberFormatProps,
  | 'thousandSeparator'
  | 'allowedDecimalSeparators'
  | 'autoComplete'
  | 'suffix'
> = {
  thousandSeparator: ' ',
  allowedDecimalSeparators: ['.', ','],
  autoComplete: 'off',
  suffix: ' €',
};

const CriticalEquipmentEditor = forwardRef(
  (
    {
      api,
      column,
      context,
      data,
      node,
      rowIndex,
    }: ICellEditorParams<PricingSheetRow>,
    ref,
  ) => {
    const { dispatchPricingSheetRows } = useContext(RowsProvider);
    const { setNotification } = useContext(NotificationProvider);

    const firstFocusableEl = useRef<HTMLInputElement>(null);
    const lastFocusableEl = useRef<HTMLButtonElement>(null);

    const [state, setState] = useState(initialState);
    const { from, to } = state;
    const minPriceDay = Number(state.minPriceDay);
    const minPriceMonth = Number(state.minPriceMonth);
    const isValid = validateCriticalEquipmentItem(state);

    const handlePriceChange =
      (field: CriticalEquipmentPriceKey) =>
      ({ floatValue }: NumberFormatValues) => {
        setState({ ...state, [field]: floatValue });
      };

    const handleDateChange =
      (field: CriticalEquipmentDateKey) =>
      (value: CellDatePickerValue) =>
        setState((prevState) => {
          // ensure that the 'from' value is before the 'to' value
          if (
            field === 'from' &&
            !!prevState.to &&
            !!value &&
            prevState.to < value
          ) {
            return {
              ...prevState,
              from: value,
              to: value,
            };
          }
          return {
            ...prevState,
            [field]: value,
          };
        });

    const handleClose = useCallback(() => {
      // enable grid cell focus
      context?.suppressCellFocus(false);
      api.stopEditing(true);
    }, [api, context]);

    const handleSave = useCallback(() => {
      // enable grid cell focus
      context?.suppressCellFocus(false);
      api.stopEditing();
    }, [api, context]);

    const focusFirstElement = (
      event: KeyboardEvent<HTMLButtonElement>,
    ) => {
      if (!event.shiftKey && event.code === 'Tab') {
        // prevent default tab behavior and move focus manually to the first element
        event.preventDefault();
        firstFocusableEl.current?.focus();
      }
    };

    const focusLastElement = (
      event: KeyboardEvent<HTMLInputElement>,
    ) => {
      if (event.shiftKey && event.code === 'Tab') {
        // prevent default tab behavior and move focus manually to the last element
        event.preventDefault();
        lastFocusableEl.current?.focus();
      }
    };

    useEffect(() => {
      const handleKeyDown = ({ key }: KeyboardEventInit) => {
        if (key === 'Enter') {
          handleSave();
        }
        if (key === 'Escape') {
          handleClose();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        // we have to redraw the row that was edited, so that colSpan is updated in colDefs
        api.redrawRows({ rowNodes: [node] });
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [
      api,
      column,
      context,
      handleClose,
      handleSave,
      node,
      rowIndex,
    ]);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          if (!isValid) {
            return;
          }
          const newRow: PricingSheetRow = {
            ...data,
            criticalEquipmentItems: [
              {
                itemId: data.id,
                minPriceDay,
                minPriceMonth,
                from:
                  from instanceof Date
                    ? setDatetoStartOfUTCDay(from).toISOString()
                    : from!,
                to:
                  to instanceof Date
                    ? setDatetoStartOfUTCDay(to).toISOString()
                    : to!,
              },
            ],
          };
          if (newRow) {
            dispatchPricingSheetRows({
              id: newRow?.catClass,
              type: 'updateRow',
              row: newRow,
            });
            return true;
          } else {
            setNotification({
              type: 'SNACKBAR',
              duration: 4000,
              severity: 'error',
              message: 'Virhe päivittäessä uutta arvoa!',
            });
            return false;
          }
        },
        isCancelAfterEnd() {
          return !isValid;
        },
      };
    });

    return (
      <Container>
        <ValidationTooltip
          title={
            minPriceDay < 0
              ? 'Arvon täytyy olla suurempi kuin nolla'
              : 'Kenttä on pakollinen'
          }
          arrow
          open={!minPriceDay || minPriceDay < 0}
        >
          <Cell>
            <PriceInput
              autoFocus
              value={minPriceDay}
              onValueChange={handlePriceChange('minPriceDay')}
              onKeyDown={focusLastElement}
              getInputRef={firstFocusableEl}
              {...commonPriceFieldProps}
            />
          </Cell>
        </ValidationTooltip>
        <ValidationTooltip
          title={
            minPriceMonth < 0
              ? 'Arvon täytyy olla suurempi kuin nolla'
              : 'Kenttä on pakollinen'
          }
          arrow
          open={!minPriceMonth || minPriceMonth < 0}
        >
          <Cell>
            <PriceInput
              value={minPriceMonth}
              onValueChange={handlePriceChange('minPriceMonth')}
              {...commonPriceFieldProps}
            />
          </Cell>
        </ValidationTooltip>
        <Cell>
          <CellDatePicker
            error={validateDatePickerValue({
              maxDate: to,
              value: from,
            })}
            value={from}
            onChange={handleDateChange('from')}
          />
        </Cell>
        <Cell>
          <CellDatePicker
            error={validateDatePickerValue({
              minDate: from,
              value: to,
            })}
            value={to}
            minDate={from}
            onChange={handleDateChange('to')}
          />
        </Cell>
        <ActionCell>
          <ActionButton
            color={'primary'}
            disabled={!isValid}
            size={'small'}
            onClick={handleSave}
          >
            <Save />
          </ActionButton>
          <ActionButton
            color={'error'}
            size={'small'}
            ref={lastFocusableEl}
            onClick={handleClose}
            onKeyDown={focusFirstElement}
          >
            <Clear />
          </ActionButton>
        </ActionCell>
      </Container>
    );
  },
);

const Container = styled.div`
  background-color: #fff;
  border: 1px solid #888;
  box-sizing: border-box;
  display: flex;
  height: inherit;
  .ag-cell-focus &:focus-within {
    border-color: var(--ag-input-focus-border-color);
  }
`;

const Cell = styled.div`
  border-left: 1px solid #ccc;
  flex: 1;
`;

const ActionCell = styled(Cell)`
  align-items: center;
  display: grid;
  flex: 0;
  gap: 0.5rem;
  grid-auto-flow: column;
  padding: 0 11px;
`;

const ActionButton = styled(IconButton)`
  svg {
    height: 20px;
    width: 20px;
  }
`;

const PriceInput = styled(NumberFormat)`
  border: 0;
  box-sizing: border-box;
  height: 100%;
  outline: none;
  padding: 0 11px;
  width: 100%;
`;

export default CriticalEquipmentEditor;
