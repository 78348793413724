export function assert(condition: unknown, msg?: string): asserts condition {
  if (condition === false) {
    throw new Error(msg);
  }
}

export function assertObjectKey<Union extends string, Type extends object>(
  key: string,
  value: Type,
): asserts key is Union {
  if (!(key in value)) {
    throw new Error(`Invalid key: ${key}`);
  }
}
