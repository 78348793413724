import { ValueGetterParams } from 'ag-grid-community';
import {
  PricingSheetRow,
  RoundingBasis,
} from '../../../../../shared/types';
import { dayPriceGetter } from './dayPriceGetter';
import { monthPriceGetter } from './monthPriceGetter';

export const priceGetter = (
  params: ValueGetterParams<PricingSheetRow>,
  roundingBasis: RoundingBasis,
) => {
  if (!params.data) return;

  const { data } = params;
  const pricingBasis = data.pricingBasis;

  if (pricingBasis === 'MONTH') {
    return monthPriceGetter(params, roundingBasis);
  } else if (pricingBasis === 'DAY') {
    return dayPriceGetter(params, roundingBasis);
  } else {
    return 0;
  }
};
