import { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import PricingApproval from './PricingApproval';
import { SheetType } from '../../../../../shared/types';
import { OfferApproval } from './OfferApproval';
import { useHistory, useParams } from 'react-router-dom';

export const Approval = () => {
  const { type } = useParams<{ type?: SheetType }>();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState<SheetType>(
    type || SheetType.PRICING,
  );

  // set active tab from path params
  useEffect(() => {
    if (type && type !== activeTab) {
      setActiveTab(type);
    }
  }, [activeTab, type]);

  return (
    <TabContext value={activeTab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          onChange={(_, value) => {
            setActiveTab(value);
            history.push(`/admin/1/${value}`);
          }}
        >
          <Tab label="Hinnastot" value={SheetType.PRICING} />
          <Tab label="Tarjoukset" value={SheetType.OFFER} />
        </TabList>
      </Box>
      <TabPanel value={SheetType.PRICING}>
        <PricingApproval />
      </TabPanel>
      <TabPanel value={SheetType.OFFER} sx={{ p: 0 }}>
        <OfferApproval />
      </TabPanel>
    </TabContext>
  );
};
