import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ChangeType } from './AdminDialogTabs/types';

interface SelectPriceTypeProps {
  changeType: ChangeType;
  handleChangeTypeChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

const SelectChangeType: React.FC<SelectPriceTypeProps> = ({
  changeType,
  handleChangeTypeChange,
}) => {
  return (
    <>
      <RadioGroup
        aria-label="changeType"
        defaultValue="BOTH"
        name="radio-buttons-group"
      >
        <RadioGroup
          aria-label="changeType"
          name="controlled-radio-buttons-group"
          value={changeType}
          onChange={handleChangeTypeChange}
          row
        >
          <FormControlLabel
            value="ABSOLUTE"
            control={<Radio />}
            label="Absoluuttisesti"
          />
          <FormControlLabel
            value="PERCENT"
            control={<Radio />}
            label="Prosentuaalisesti (%)"
          />
        </RadioGroup>
      </RadioGroup>
    </>
  );
};

export default SelectChangeType;
