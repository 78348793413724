import React, { useCallback } from 'react';
import {
  AgGridColumnView,
  IndustryName,
} from '../../../../../shared/types';
import ExcelImportCoefficientDialog from './ExcelImportCoefficientDialog';
import ExcelImportIndustryPercentDialog from './ExcelImportIndustryPercentDialog';
import ExcelImportCommonDialog from './ExcelImportCommonDialog';
import ExcelImportCriticalEquipmentDialog from './ExcelImportCriticalEquipmentDialog';
import ExcelImportSurplusEquipmentDialog from './ExcelImportSurplusEquipmentDialog';

export type ExcelImportDialogCommonProps = {
  open: boolean;
  onClose: () => void;
};

type ExcelImportDialogProps = ExcelImportDialogCommonProps & {
  activeIndustry: IndustryName;
  gridColumnView: AgGridColumnView;
};

const ExcelImportDialog = ({
  activeIndustry,
  gridColumnView,
  onClose,
  open,
}: ExcelImportDialogProps) => {
  const renderDialog = useCallback(() => {
    const commonProps = {
      open,
      onClose,
    };
    switch (gridColumnView) {
      case AgGridColumnView.ADMIN_COMMON:
        return <ExcelImportCommonDialog {...commonProps} />;
      case AgGridColumnView.ADMIN_DAY:
      case AgGridColumnView.ADMIN_MONTH:
      case AgGridColumnView.ADMIN_BOTH:
        return <ExcelImportCoefficientDialog {...commonProps} />;
      case AgGridColumnView.ADMIN_INDUSTRY_DAY:
      case AgGridColumnView.ADMIN_INDUSTRY_MONTH:
        return (
          <ExcelImportIndustryPercentDialog
            activeIndustry={activeIndustry}
            {...commonProps}
          />
        );
      case AgGridColumnView.CRITICAL_EQUIPMENT:
        return (
          <ExcelImportCriticalEquipmentDialog {...commonProps} />
        );
      case AgGridColumnView.SURPLUS_EQUIPMENT:
        return <ExcelImportSurplusEquipmentDialog {...commonProps} />;
      default:
        return null;
    }
  }, [activeIndustry, gridColumnView, onClose, open]);

  return renderDialog();
};

export default ExcelImportDialog;
