import React, { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { formatDateTimeWithSeconds } from '../../utils/formatDateTimes';
import { RowsHistoryType } from '../../hooks/useRowsHistory';

interface SavingProps {
  saveButton: ReactElement;
  savingTimeStamp: Date | undefined;
  rowsHistory: RowsHistoryType[];
}

const Saving: React.FC<SavingProps> = ({
  saveButton,
  savingTimeStamp,
  rowsHistory,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {saveButton}
        <Typography variant="body1" color={'text.secondary'}>
          {savingTimeStamp && rowsHistory.length > 1 ? (
            <>
              Tallennus:{` `}
              {formatDateTimeWithSeconds(savingTimeStamp)}
            </>
          ) : savingTimeStamp ? (
            <>
              {' '}
              Ladattu:{` `}
              {formatDateTimeWithSeconds(savingTimeStamp)}
            </>
          ) : null}
        </Typography>
      </Box>
    </>
  );
};

export default Saving;
