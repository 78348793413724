import { MenuItem, Select } from '@mui/material';
import { PriceCategory } from '../../../../../../shared/types';
import { PRICE_CATEGORIES } from '../../../../../../shared/constants';

interface PriceCategoryProps {
  value: PriceCategory;
  onCategoryChange: (value: PriceCategory) => void;
}

const PriceCategorySelector: React.FC<PriceCategoryProps> = ({
  value,
  onCategoryChange,
}) => {
  const options = Object.entries(PRICE_CATEGORIES)
    .map(([value, label]) => ({ value, label }))
    .reverse();
  return (
    <>
      <Select
        value={value}
        onChange={(event) =>
          onCategoryChange(event.target.value as PriceCategory)
        }
        sx={{
          width: '100%',
        }}
      >
        {options.map((priceCategory) => (
          <MenuItem
            key={priceCategory.value}
            value={priceCategory.value}
          >
            {priceCategory.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default PriceCategorySelector;
