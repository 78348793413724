import {
  createContext,
  useReducer,
  useState,
  useContext,
} from 'react';
import pricingSheetRowReducer from './PricingSheetRows/pricingSheetRowReducer';
import { PricingSheetRow } from '../../../../shared/types';
import { clone } from '../../utils/genericUtils';
import BackgroundProvider from '../Background/BackgroundProvider';
import calculateWarningCounts from '../../utils/calculateWarningCounts';

const Rows = createContext<any | null>(null);

const RowsProvider = (props: any) => {
  const { backgroundInfo } = useContext(BackgroundProvider);
  const [pricingSheetRows, dispatchPricingSheetRows] = useReducer(
    pricingSheetRowReducer,
    [],
  );

  const preSelectedRows = pricingSheetRows?.filter(
    (row) => row.preSelected,
  );

  const clearPreSelectedRows = () => {
    const copy = clone(pricingSheetRows);
    for (const pricingSheetRow of copy) {
      if (pricingSheetRow) {
        pricingSheetRow.preSelected = false;
      }
    }

    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: copy,
    });
  };

  const [selectedRows, setSelectedRows] = useState<PricingSheetRow[]>(
    [],
  );

  const commentRows = preSelectedRows?.filter(
    (row: PricingSheetRow) => row.approverComment,
  );
  // update single row, this is used in aggrid and aggridadmin
  const updateRow = (row: PricingSheetRow) => {
    dispatchPricingSheetRows({
      id: row?.catClass,
      type: 'updateRow',
      row: row,
    });
  };

  const children = props.children;

  const warningCounts = calculateWarningCounts(
    preSelectedRows.filter((row) => !row.showByOfferText),
    backgroundInfo.pricingBasis,
  );

  return (
    <Rows.Provider
      value={{
        pricingSheetRows: pricingSheetRows,
        dispatchPricingSheetRows,
        preSelectedRows,
        clearPreSelectedRows,
        warningCounts,
        commentRows,
        selectedRows,
        setSelectedRows,
        updateRow,
      }}
    >
      {children}
    </Rows.Provider>
  );
};

export default Rows;

export { RowsProvider };
