import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ExternalFilter } from '../../hooks/useFilters';

interface PricingRowCheckBoxFilterProps {
  checked: (filterType: ExternalFilter) => boolean;
  handleChecked: (filterType: ExternalFilter) => void;
  label: string;
  labelStyle?: object;
  filterType: ExternalFilter;
}
const PricingRowCheckBoxFilter: React.FC<
  PricingRowCheckBoxFilterProps
> = ({
  checked,
  handleChecked,
  label,
  labelStyle = {},
  filterType,
}) => {
  const handleChange = (_event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    handleChecked(filterType);
  };

  return (
    <>
      <FormControl component="fieldset">
        <FormControlLabel
          style={labelStyle}
          value="start"
          control={
            <Checkbox
              checked={checked(filterType)}
              onChange={handleChange}
            />
          }
          label={label}
          labelPlacement="end"
        />
      </FormControl>
    </>
  );
};

export default PricingRowCheckBoxFilter;
