import React from 'react';

import DoneIcon from '@mui/icons-material/Done';
import ModeIcon from '@mui/icons-material/Mode';
import PendingIcon from '@mui/icons-material/Pending';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { createTheme } from '@mui/material/styles';

import { PricingSheetState } from '../../../../shared/types';
import { OfferState } from '../../../../shared/types/offers';

interface SheetIconProps {
  state: PricingSheetState | OfferState;
}
const getSheetIcon: React.FC<SheetIconProps> = ({ state }) => {
  const theme = createTheme();

  if (state === PricingSheetState.WarningDraft) {
    return (
      <ModeIcon
        sx={{ color: theme.palette.text.primary, fontSize: 20 }}
      />
    );
  }
  if (state === PricingSheetState.OkDraft) {
    return (
      <ModeIcon
        sx={{ color: theme.palette.text.primary, fontSize: 20 }}
      />
    );
  }
  if (state === PricingSheetState.Pending) {
    return (
      <PendingIcon
        sx={{ color: theme.palette.text.primary, fontSize: 20 }}
      />
    );
  }
  if (state === PricingSheetState.Approved) {
    return (
      <DoneIcon
        sx={{ color: theme.palette.text.primary, fontSize: 20 }}
      />
    );
  }
  if (state === PricingSheetState.Ready) {
    return (
      <ImportExportIcon
        sx={{ color: theme.palette.text.primary, fontSize: 20 }}
      />
    );
  }
  if (state === PricingSheetState.InUse) {
    return (
      <CheckCircleOutlineIcon
        sx={{ color: theme.palette.success.main, fontSize: 20 }}
      />
    );
  }

  return (
    <QuestionMarkIcon
      sx={{ color: theme.palette.text.primary, fontSize: 20 }}
    />
  );
};

export default getSheetIcon;
