import { PricingSheetRow } from '../../../shared/types';
import {
  COEFFICIENT_LIMIT,
  COEFFICIENT_LIMIT_LIGHT,
  DISCOUNT_LIMIT,
  getCoefficient,
  getRemovalDayPrice,
  getRemovalMonthPrice,
} from './pricingFormulas';

import {
  PRICE_FACTOR,
  PRICE_THRESHOLD_MAX,
  PRICE_THRESHOLD_MIN,
} from '../../../shared/constants';

export type PricingSheetRowFilter = (row: PricingSheetRow) => boolean;

export const isZeroPriceRow: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  // In offer, each row might have a different pricingBasis
  if (row.pricingBasis) {
    return row.pricingBasis === 'MONTH'
      ? isZeroMonthPriceRow(row)
      : isZeroDayPriceRow(row);
  } else {
    return isZeroDayPriceRow(row) || isZeroMonthPriceRow(row);
  }
};

export const isZeroDayPriceRow: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  return row.dayPrice === null || row.dayPrice === 0;
};

export const isZeroMonthPriceRow: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  return row.monthPrice === null || row.monthPrice === 0;
};

export const isWarningRow: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  if (row.pricingBasis) {
    // In offer, each row might have a different pricingBasis
    if (row.pricingBasis === 'MONTH') {
      return (
        isMonthLightWarningPrice(row) ||
        isBelow70PercentMonthPriceRow(row) ||
        isMonthWarningPrice(row) ||
        isZeroPriceRow(row)
      );
    } else {
      return (
        isDayLightWarningPrice(row) ||
        isBelow70PercentDayPriceRow(row) ||
        isDayWarningPrice(row) ||
        isZeroPriceRow(row)
      );
    }
  } else {
    return (
      isDayLightWarningPrice(row) ||
      isBelow70PercentDayPriceRow(row) ||
      isMonthLightWarningPrice(row) ||
      isBelow70PercentMonthPriceRow(row) ||
      isDayWarningPrice(row) ||
      isMonthWarningPrice(row) ||
      isZeroPriceRow(row)
    );
  }
};

export const isDayWarningRow: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  return (
    isDayLightWarningPrice(row) ||
    isBelow70PercentDayPriceRow(row) ||
    isDayWarningPrice(row) ||
    isZeroDayPriceRow(row)
  );
};

export const isMonthWarningRow: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  return (
    isMonthLightWarningPrice(row) ||
    isBelow70PercentMonthPriceRow(row) ||
    isMonthWarningPrice(row) ||
    isZeroMonthPriceRow(row)
  );
};

export const isComparisonDayPriceEmpty: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  if (
    row.comparisonDayPrice === null ||
    row.comparisonDayPrice === undefined
  ) {
  }
  return (
    row.comparisonDayPrice === null ||
    row.comparisonDayPrice === undefined
  );
};

export const isComparisonMonthPriceIsEmpty: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  return (
    row.comparisonMonthPrice === null ||
    row.comparisonMonthPrice === undefined
  );
};

export const isComparisonPriceEmpty: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  return (
    isComparisonDayPriceEmpty(row) ||
    isComparisonMonthPriceIsEmpty(row)
  );
};

// red row
export const isWarningPrice: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  // In offer, each row might have a different pricingBasis
  if (row.pricingBasis) {
    return row.pricingBasis === 'MONTH'
      ? isMonthWarningPrice(row)
      : isDayWarningPrice(row);
  }
  return isDayWarningPrice(row) || isMonthWarningPrice(row);
};

// red row
export const isDayWarningPrice: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  const coefficient = getCoefficient(
    row.dayPrice ? row.dayPrice / PRICE_FACTOR : row.dayPrice,
    getRemovalDayPrice(row),
  );
  return typeof row.dayPrice === 'number' && coefficient
    ? coefficient < COEFFICIENT_LIMIT
    : false;
};

// red row
export const isMonthWarningPrice: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  const coefficient = getCoefficient(
    row.monthPrice ? row.monthPrice / PRICE_FACTOR : row.monthPrice,
    getRemovalMonthPrice(row),
  );
  return typeof row.monthPrice === 'number' && coefficient
    ? coefficient < COEFFICIENT_LIMIT
    : false;
};

// orange row
export const isLightWarningPrice: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  // In offer, each row might have a different pricingBasis
  if (row.pricingBasis) {
    return row.pricingBasis === 'MONTH'
      ? isMonthLightWarningPrice(row)
      : isDayLightWarningPrice(row);
  }
  return isDayLightWarningPrice(row) || isMonthLightWarningPrice(row);
};

// orange row
export const isDayLightWarningPrice: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  const coefficient = getCoefficient(
    row.dayPrice ? row.dayPrice / PRICE_FACTOR : row.dayPrice,
    getRemovalDayPrice(row),
  );

  return typeof row.dayPrice === 'number' && coefficient
    ? coefficient < COEFFICIENT_LIMIT_LIGHT &&
        coefficient >= COEFFICIENT_LIMIT
    : false;
};

// orange row
export const isMonthLightWarningPrice: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  const coefficient = getCoefficient(
    row.monthPrice ? row.monthPrice / PRICE_FACTOR : row.monthPrice,
    getRemovalMonthPrice(row),
  );
  return typeof row.monthPrice === 'number' && coefficient
    ? coefficient < COEFFICIENT_LIMIT_LIGHT &&
        coefficient >= COEFFICIENT_LIMIT
    : false;
};

// pink row
export const isBelow70PercentPriceRow: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  // In offer, each row might have a different pricingBasis
  if (row.pricingBasis) {
    return row.pricingBasis === 'MONTH'
      ? isBelow70PercentMonthPriceRow(row)
      : isBelow70PercentDayPriceRow(row);
  }
  return (
    isBelow70PercentDayPriceRow(row) ||
    isBelow70PercentMonthPriceRow(row)
  );
};

// pink row
export const isBelow70PercentDayPriceRow: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  if (row.pricingBasis && row.pricingBasis === 'MONTH') return false;
  const dayPriceBelow70Percent =
    row.preSelected &&
    typeof row.dayPrice === 'number' &&
    typeof row.proposalDayPrice === 'number' &&
    row.dayPrice < DISCOUNT_LIMIT * row.proposalDayPrice;
  if (dayPriceBelow70Percent) {
    return true;
  }
  return false;
};

// pink row
export const isBelow70PercentMonthPriceRow: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  if (row.pricingBasis && row.pricingBasis === 'DAY') return false;
  const monthPriceBelow70Percent =
    row.preSelected &&
    typeof row.monthPrice === 'number' &&
    typeof row.proposalMonthPrice === 'number' &&
    row.monthPrice < DISCOUNT_LIMIT * row.proposalMonthPrice;
  if (monthPriceBelow70Percent) {
    return true;
  }
  return false;
};

// critical day price row
export const isBelowCriticalPriceDay: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  return !!(
    row.preSelected &&
    typeof row.dayPrice === 'number' &&
    typeof row.minDayPrice === 'number' &&
    row.dayPrice < row.minDayPrice
  );
};

// critical month price row
export const isBelowCriticalPriceMonth: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  return !!(
    row.preSelected &&
    typeof row.monthPrice === 'number' &&
    typeof row.minMonthPrice === 'number' &&
    row.monthPrice < row.minMonthPrice
  );
};

export const isCriticalPrice: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  // In offer, each row might have a different pricingBasis
  if (row.pricingBasis) {
    return row.pricingBasis === 'MONTH'
      ? isBelowCriticalPriceMonth(row)
      : isBelowCriticalPriceDay(row);
  } else {
    return (
      isBelowCriticalPriceDay(row) || isBelowCriticalPriceMonth(row)
    );
  }
};

export const showKitTypeOnly: PricingSheetRowFilter = (
  row: PricingSheetRow,
) => {
  return row.type === 'KIT';
};

export const inPricingFilter: PricingSheetRowFilter = (row) =>
  row.includeInPricing;

export const missingPricingParameter: PricingSheetRowFilter = (
  row,
) => {
  return !(
    !!row.dayPriceCoefficient1 &&
    !!row.dayPriceCoefficient2 &&
    !!row.dayPriceCoefficient3 &&
    !!row.dayPriceCoefficient4 &&
    !!row.dayPriceCoefficient5 &&
    !!row.dayPriceCoefficient6 &&
    !!row.dayPriceCoefficient7 &&
    !!row.monthPriceCoefficient1 &&
    !!row.monthPriceCoefficient2 &&
    !!row.monthPriceCoefficient3 &&
    !!row.monthPriceCoefficient4 &&
    !!row.monthPriceCoefficient5 &&
    !!row.monthPriceCoefficient6 &&
    !!row.monthPriceCoefficient7 &&
    !!row.targetUtilRate &&
    !!row.depreciationPeriod &&
    !!row.rentalReadyPrice
  );
};

export const itemNotInPimFilter: PricingSheetRowFilter = (row) => {
  return !row.pimUpdatedAt;
};

export const itemInPimFilter: PricingSheetRowFilter = (row) => {
  return !!row.pimUpdatedAt;
};

export const removedItemFilter: PricingSheetRowFilter = (row) => {
  return !row.removedAt;
};

export const criticalEquipmentFilter: PricingSheetRowFilter = (
  row,
) => {
  return row.criticalEquipmentItems.length > 0;
};

export const ramigreenFilter: PricingSheetRowFilter = (row) => {
  return !!row.isRamigreen;
};

/**
 * PV->KK kerroin -filter
 */
export const isInvalidPriceThreshold = (
  pricingSheetRow: PricingSheetRow,
) => {
  if (!pricingSheetRow.monthPrice || !pricingSheetRow.dayPrice) {
    return false;
  }
  const priceThreshold =
    pricingSheetRow.monthPrice / pricingSheetRow.dayPrice;
  return (
    (priceThreshold < PRICE_THRESHOLD_MIN ||
      priceThreshold > PRICE_THRESHOLD_MAX) &&
    !pricingSheetRow.showByOfferText
  );
};
