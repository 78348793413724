import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import EditIcon from '@mui/icons-material/Edit';

interface ChangeValuesButtonProps {
  changeValuesEnabled: boolean;
  setChangeValuesEnabled: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}
const ChangeValuesButton: React.FC<ChangeValuesButtonProps> = ({
  changeValuesEnabled,
  setChangeValuesEnabled,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYesClick = () => {
    handleClose();
    setChangeValuesEnabled(true);
  };

  return (
    <Box>
      {!changeValuesEnabled ? (
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={handleClickOpen}
        >
          Muokkaa lukittuja arvoja
        </Button>
      ) : null}

      <Dialog
        disableScrollLock
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Haluatko varmasti muokata alkuarvoja?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Alkuarvojen muokkaaminen vaikuttaa työkalun tarjoamiin
            ehdotushintoihin ja voi mahdollisesti muokata hinnaston
            hintoja!
            <br />
            <br />
            Muutos vaikuttaa kuitenkin vain tuoteriveille joiden
            hintaa <b>et</b> ole muokannut aiemmin.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button color="inherit" onClick={handleClose}>
            Peru
          </Button>
          <Button
            variant="contained"
            onClick={handleYesClick}
            autoFocus
          >
            Kyllä
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChangeValuesButton;
