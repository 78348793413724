import { IndustryName, PricingSheetRow } from '../../../shared/types';

import { flatItems } from '../utils/flatItems';
import { api } from '../index';

type ItemIndustryPercentDb = {
  item: PricingSheetRow;
  id: number;
  dayPricePercent1: number | string;
  dayPricePercent2: number | string;
  dayPricePercent3: number | string;
  dayPricePercent4: number | string;
  dayPricePercent5: number | string;
  dayPricePercent6: number | string;
  dayPricePercent7: number | string;
  dayPricePercentList: number | string;
  monthPricePercent1: number | string;
  monthPricePercent2: number | string;
  monthPricePercent3: number | string;
  monthPricePercent4: number | string;
  monthPricePercent5: number | string;
  monthPricePercent6: number | string;
  monthPricePercent7: number | string;
  monthPricePercentList: number | string;
  industry: { id: number; name: IndustryName };
  industryId: number;
  itemId: number;
};

export const getItemIndustryPercents = async <
  ItemIndustryPercent,
>(): Promise<ItemIndustryPercent[]> => {
  const response = await api.get(`/itemindustrypercents`);

  const percents = response?.data as ItemIndustryPercentDb[];
  const flattedPercents = flatItems(percents);
  return flattedPercents as ItemIndustryPercent[];
};

export const getItemIndustryPercentsByIndustry = async <
  ItemIndustryPercent,
>(
  id: number,
): Promise<ItemIndustryPercent[]> => {
  const response = await api.get(`/itemindustrypercents/${id}`);

  const percents = response?.data as ItemIndustryPercentDb[];
  const flattedPercents = flatItems(percents);
  return flattedPercents as ItemIndustryPercent[];
};
