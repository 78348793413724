import React, {
  useEffect,
  useContext,
  useState,
  SetStateAction,
} from 'react';
import { processEnvs } from '../../config/processEnvs';
import { Grid } from '@mui/material';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CircularProgress from '@mui/material/CircularProgress';

import GenericContainer from '../../GenericContainer';

import PricingFilters from '../../components/PricingTable/PricingFilters';
import MultiLineComment from '../../components/Common/MultiLineComment';
import CommentPresenter from '../../components/Common/CommentPresenter';

import PricingProvider from '../../providers/Pricing/PricingProvider';
import RowsProvider from '../../providers/Rows/RowsProvider';
import BackgroundProvider from '../../providers/Background/BackgroundProvider';
import NotificationProvider from '../../providers/Notification/NotificationProvider';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  getApprovalRequestById,
  updateApprovalRequest,
} from '../../services/approvalRequests';

import {
  AgGridColumnView,
  ApprovalRequestState,
  PricingBasis,
  PricingSheetRow,
  TableType,
} from '../../../../shared/types';

import EditViewDialog from '../../components/PricingTable/EditViewDialog';
import { ApprovalLockedDialog } from './ApprovalLockedDialog';
import { formatReason } from './Approval/PricingApproval';

import AgGrid from '../../components/PricingTable/AgGrid';
import getRowsForPricingSheet from '../../helpers/getRowsForPricingSheet';

import { useFilters } from '../../hooks/useFilters';
import { useColumns } from '../../hooks/useColumns';
import WarningCounts from '../../components/Cells/common/WarningCounts';
import CoefficientChips from '../../components/Cells/common/CoefficientChips';
import { getGroupFilterForGroup } from '../../helpers/groupFilter';
import calculateMeansByGroup from '../../utils/calculateMeansByGroup';
import { formatPricingBasis } from '../../helpers/formatPricingBasis';

let intervalId: any = null;

const getInitialColumns = (pricingBasis: PricingBasis) => {
  if (pricingBasis === 'DAY') {
    return AgGridColumnView.INSPECT_DAY;
  }
  return AgGridColumnView.INSPECT_BOTH;
};

const InspectPricingSheet: React.FC = () => {
  let history = useHistory();
  let { state: { readOnly } = {} } = useLocation<{
    readOnly?: boolean;
  }>();

  const params = useParams<{ id: string }>();

  const approvalId = params.id;

  const [comment, setComment] = useState<string | undefined>(
    undefined,
  );

  const handleCommentChange = (event: {
    target: { value: SetStateAction<string | undefined> };
  }) => {
    setComment(event.target.value);
  };

  const { pricingSheetId, setPricingSheetId } =
    useContext(PricingProvider);
  const { dispatchPricingSheetRows, preSelectedRows, warningCounts } =
    useContext(RowsProvider);
  const { backgroundInfo, resetBackgroundInfo } = useContext(
    BackgroundProvider,
  );
  const { setNotification } = useContext(NotificationProvider);
  const {
    dayWarnings,
    dayLightWarnings,
    dayBelow70Warnings,
    dayZeroPriceWarnings,
    monthWarnings,
    monthLightWarnings,
    monthBelow70Warnings,
    monthZeroPriceWarnings,
  } = warningCounts;

  const {
    columnDefs,
    gridColumnView,
    groupingStyle,
    setDefaultGroupingValues,
    updateGroupingValues,
    groupingValues,
  } = useColumns(getInitialColumns(backgroundInfo.pricingBasis), {
    roundingBasisSheet: backgroundInfo.roundingBasisSheet,
    ...(readOnly && { hideInspectComment: true }),
  });

  const {
    activeFilters,
    addFilter,
    applyActiveFilters,
    toggleActiveFilter,
    isChecked,
    clearFilters,
  } = useFilters();

  const [showApprovalLockedDialog, setShowApprovalLockedDialog] =
    useState(false);
  const [lockedBy, setLockedBy] = useState(null);
  const [lockedReason, setLockedReason] = useState(null);

  useEffect(() => {
    const getApprovalRequestStatus = async () => {
      const approvalRequest =
        await getApprovalRequestById(approvalId);
      return approvalRequest.data;
    };

    clearInterval(intervalId);

    if (!readOnly) {
      intervalId = setInterval(async () => {
        const approvalRequest = await getApprovalRequestStatus();

        if (approvalRequest.state === ApprovalRequestState.Locked) {
          clearInterval(intervalId);
          setLockedBy(approvalRequest.lockedBy);
          setLockedReason(approvalRequest.lockedReason);
          setShowApprovalLockedDialog(true);
        }
      }, 1000 * 30);
    }
    return () => clearInterval(intervalId);
  }, [approvalId, readOnly]);

  useEffect(() => {
    if (!pricingSheetId) {
      history.push('/index');
    }
    // clear pricing sheet id and background info when component is unmounted
    return () => {
      setPricingSheetId(null);
      resetBackgroundInfo();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoadingCombinedRows, setIsLoadingCombinedRows] =
    useState(true);

  // Initial row data fetch
  useEffect(() => {
    if (!pricingSheetId) {
      return;
    }

    (async () => {
      try {
        setIsLoadingCombinedRows(true);

        const pricingSheetRows =
          await getRowsForPricingSheet(pricingSheetId);

        dispatchPricingSheetRows({
          type: 'updatePricingSheetRows',
          newRows: pricingSheetRows.filter((row) => row.preSelected),
        });
      } catch (error: any) {
        console.error(error);
        setNotification({
          type: 'SNACKBAR',
          duration: 4000,
          severity: 'error',
          message: `Hinnaston rivejä ladatessa tapahtui virhe!`,
        });
      } finally {
        setIsLoadingCombinedRows(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingSheetId]);

  const onClickApprove = async () => {
    const updateRequests = await updateApprovalRequest(approvalId, {
      state: ApprovalRequestState.Approved,
      comment: comment,
    });

    if (updateRequests.status === 200) {
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: 'Hinnasto hyväksytty!',
      });
      history.push('/admin/1');
    } else {
      setNotification({
        severity: 'error',
        message: 'Hinnaston hyväksyminen epäonnistui!',
      });
    }
  };

  const onClickDisapprove = async () => {
    if (!comment) {
      setNotification({
        severity: 'error',
        message:
          'Jos haluat palauttaa hinnaston sen tekijälle, sinun täytyy antaa myös palaute!',
      });
    } else {
      const updateRequest = await updateApprovalRequest(approvalId, {
        state: ApprovalRequestState.Discarded,
        comment: comment,
      });

      if (updateRequest.status === 200) {
        setNotification({
          type: 'SNACKBAR',
          duration: 4000,
          severity: 'success',
          message: 'Hinnasto palautettu tekijälle!',
        });
        history.push('/admin/1');
      } else {
        setNotification({
          severity: 'error',
          message: 'Hinnaston palautus epäonnistui!',
        });
      }
    }
  };

  const groupHeaderInnerRenderer = (row: any, filteredRows: any) => {
    const groupFilter = getGroupFilterForGroup(
      row.node.field,
      row.value,
      groupingStyle,
    );

    const {
      meanFilledCoefficientOfAllDayRows,
      meanProposalCoefficientOfAllDayRows,
      meanFilledCoefficientOfAllMonthRows,
      meanProposalCoefficientOfAllMonthRows,
    } = calculateMeansByGroup({
      rows: filteredRows,
      groupingParams: {
        field: row.node.field,
        value: row.value,
        groupingStyle: groupingStyle,
      },
    });
    return (
      <>
        <WarningCounts
          countDayPriceWarnings={
            dayWarnings.filter(groupFilter).length
          }
          countMonthPriceWarnings={
            monthWarnings.filter(groupFilter).length
          }
          countDayPriceLightWarnings={
            dayLightWarnings.filter(groupFilter).length
          }
          countMonthPriceLightWarnings={
            monthLightWarnings.filter(groupFilter).length
          }
          countBelow70PercentDayPriceRows={
            dayBelow70Warnings.filter(groupFilter).length
          }
          countBelow70PercentMonthPriceRows={
            monthBelow70Warnings.filter(groupFilter).length
          }
          countZeroDayPriceRows={
            dayZeroPriceWarnings.filter(groupFilter).length
          }
          countZeroMonthPriceRows={
            monthZeroPriceWarnings.filter(groupFilter).length
          }
          pricingBasis={backgroundInfo.pricingBasis}
        />
        <CoefficientChips
          means={{
            meanFilledDay: meanFilledCoefficientOfAllDayRows,
            meanProposalDay: meanProposalCoefficientOfAllDayRows,
            meanFilledMonth: meanFilledCoefficientOfAllMonthRows,
            meanProposalMonth: meanProposalCoefficientOfAllMonthRows,
          }}
          pricingBasis={backgroundInfo.pricingBasis}
          gridColumnView={gridColumnView}
        />
      </>
    );
  };

  const customHeaderInnerRenderer = (filteredRows: any) => {
    const rowsForMeanCalculations = filteredRows
      ? filteredRows?.filter(
          (row: PricingSheetRow) => !row.showByOfferText,
        )
      : [];

    const {
      meanFilledCoefficientOfAllDayRows,
      meanProposalCoefficientOfAllDayRows,
      meanFilledCoefficientOfAllMonthRows,
      meanProposalCoefficientOfAllMonthRows,
    } = calculateMeansByGroup({
      rows: rowsForMeanCalculations,
    });

    return (
      <>
        <WarningCounts
          countDayPriceWarnings={dayWarnings.length}
          countMonthPriceWarnings={monthWarnings.length}
          countDayPriceLightWarnings={dayLightWarnings.length}
          countMonthPriceLightWarnings={monthLightWarnings.length}
          countBelow70PercentDayPriceRows={dayBelow70Warnings.length}
          countBelow70PercentMonthPriceRows={
            monthBelow70Warnings.length
          }
          countZeroDayPriceRows={dayZeroPriceWarnings.length}
          countZeroMonthPriceRows={monthZeroPriceWarnings.length}
          pricingBasis={backgroundInfo.pricingBasis}
        />
        <CoefficientChips
          means={{
            meanFilledDay: meanFilledCoefficientOfAllDayRows,
            meanProposalDay: meanProposalCoefficientOfAllDayRows,
            meanFilledMonth: meanFilledCoefficientOfAllMonthRows,
            meanProposalMonth: meanProposalCoefficientOfAllMonthRows,
          }}
          pricingBasis={backgroundInfo.pricingBasis}
          gridColumnView={gridColumnView}
        />
      </>
    );
  };

  // ensure that table is empty after unmount
  useEffect(() => {
    return () => {
      dispatchPricingSheetRows({
        type: 'updatePricingSheetRows',
        newRows: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GenericContainer>
      <ApprovalLockedDialog
        lockedReason={formatReason(lockedReason ?? '')}
        lockedBy={lockedBy}
        open={showApprovalLockedDialog}
        handleButtonClick={() => history.push('/')}
      />
      <Typography
        sx={{ m: 1 }}
        variant="h4"
        component="div"
        gutterBottom
        color="primary"
      >
        {readOnly ? 'Hinnaston tarkastelu' : 'Hinnaston hyväksyntä'}
      </Typography>
      {processEnvs.MAINTENANCE ? (
        <span>Hinnaston hyväksynnässä huoltokatko. </span>
      ) : (
        <>
          <Typography
            sx={{ ml: 1, mb: 0 }}
            variant="h5"
            component="div"
            gutterBottom
          >
            {backgroundInfo.name}
          </Typography>
          <Typography
            sx={{ ml: 1, mb: 1 }}
            variant="body1"
            component="div"
            gutterBottom
          >
            {formatPricingBasis(backgroundInfo.pricingBasis)}
          </Typography>
          <Box>
            {isLoadingCombinedRows ? (
              <CircularProgress />
            ) : (
              <>
                {backgroundInfo?.creatorComment && !readOnly && (
                  <CommentPresenter
                    header={'Hinnaston tekijän perustelut'}
                    comment={backgroundInfo.creatorComment}
                  />
                )}
                <PricingFilters
                  addFilter={addFilter}
                  pricingBasis={backgroundInfo.pricingBasis}
                  toggleActiveFilter={toggleActiveFilter}
                  isChecked={isChecked}
                  clearFilters={clearFilters}
                />
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <EditViewDialog
                    groupingStyle={groupingStyle}
                    setDefaultGroupingValues={
                      setDefaultGroupingValues
                    }
                    updateGroupingValues={updateGroupingValues}
                    groupingValues={groupingValues}
                  />
                </Grid>

                <AgGrid
                  isSelectable={false}
                  gridColumnView={gridColumnView}
                  columnDefs={columnDefs}
                  type={TableType.InspectTable}
                  rows={preSelectedRows}
                  loading={false}
                  externalFilters={activeFilters}
                  applyActiveFilters={applyActiveFilters}
                  groupHeaderInnerRenderer={groupHeaderInnerRenderer}
                  customHeaderInnerRenderer={
                    customHeaderInnerRenderer
                  }
                />
                {!readOnly && (
                  <Box
                    sx={{
                      mt: 2,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Box sx={{ pr: 2, width: '100%' }}>
                      <MultiLineComment
                        label="Palaute"
                        placeholder="Lisää palaute"
                        handleCommentChange={handleCommentChange}
                        required={true}
                      />
                    </Box>

                    <Box sx={{ pr: 2 }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={onClickDisapprove}
                      >
                        Palauta myyjälle
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        onClick={onClickApprove}
                      >
                        Hyväksy hinnasto
                      </Button>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        </>
      )}
    </GenericContainer>
  );
};

export default InspectPricingSheet;
