import styled from '@emotion/styled';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import {
  AgGridColumnView,
  PricingSheetRow,
} from '../../../../../shared/types';
import { getColSpanValue } from '../Columns/ColumnsBase';

export type CriticalEquipmentRendererParams =
  ICellRendererParams<PricingSheetRow> & {
    actionColumnMaxWidth?: number;
  };

const CriticalEquipmentRenderer = ({
  actionColumnMaxWidth,
  data,
  valueFormatted,
}: CriticalEquipmentRendererParams) => {
  const placeholderCellCount =
    getColSpanValue(AgGridColumnView.CRITICAL_EQUIPMENT) - 1;
  return data?.criticalEquipmentItems.length === 0 ? (
    <PlaceholderContainer>
      {Array.from(Array(placeholderCellCount).keys()).map((key) => {
        const isActionCell = Number(key) + 1 === placeholderCellCount;
        return (
          <PlaceholderCell
            key={key}
            maxWidth={isActionCell ? actionColumnMaxWidth : undefined}
          />
        );
      })}
    </PlaceholderContainer>
  ) : (
    <span>{valueFormatted}</span>
  );
};

const PlaceholderContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  height: inherit;
  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
`;

const PlaceholderCell = styled.div<{ maxWidth?: number }>`
  border-left: 1px solid #ccc;
  box-sizing: inherit;
  flex: 1;
  max-width: ${({ maxWidth }) =>
    maxWidth ? `${maxWidth}px` : 'none'};
  // focus outline for the placeholder row
  .ag-cell-focus & {
    border: solid var(--ag-input-focus-border-color);
    border-left-color: #ccc;
    border-width: 1px 0 1px 1px;
    &:first-of-type {
      border-left-color: var(--ag-input-focus-border-color);
      border-left-width: 1px;
    }
    &:last-of-type {
      border-right-color: var(--ag-input-focus-border-color);
      border-right-width: 1px;
    }
  }
`;

export default CriticalEquipmentRenderer;
