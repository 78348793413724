import { SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {
  TableType,
  PricingSheetRow,
  AgGridColumnView,
} from '../../../../../shared/types';

interface CustomHeaderProps {
  displayName: string;
  expanded: boolean;
  onCellExpand: (e: SyntheticEvent<Element, Event>) => void;
  type: TableType;
  getFilteredRows: () => PricingSheetRow[];
  gridColumnView: AgGridColumnView;
  customHeaderInnerRenderer?: (
    rows: PricingSheetRow[],
  ) => JSX.Element;
}

const CustomHeader: React.FC<CustomHeaderProps> = ({
  displayName,
  expanded,
  onCellExpand,
  type,
  getFilteredRows,
  gridColumnView,
  customHeaderInnerRenderer,
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      onCellExpand(e);
    }
  };

  const filteredRows = getFilteredRows();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <span onClick={onCellExpand} onKeyDown={handleKeyDown}>
          {expanded ? (
            <KeyboardDoubleArrowDownIcon
              style={{ fontSize: 18, display: 'inherit' }}
            />
          ) : (
            <KeyboardDoubleArrowRightIcon
              style={{ fontSize: 18, display: 'inherit' }}
            />
          )}
        </span>
        <span style={{ marginLeft: 12 }}> {displayName}</span>
        {customHeaderInnerRenderer &&
          customHeaderInnerRenderer(filteredRows)}
      </Box>
    </>
  );
};

export default CustomHeader;
