import { roundToTwoDecimal } from "./roundToTwoDecimal";

export const countRemovalDayPrice = (
  rentalReadyPrice: number,
  depreciationPeriod: number,
  targetUtilRate: number,
) =>
  rentalReadyPrice && depreciationPeriod && targetUtilRate
    ? roundToTwoDecimal(
        Number(rentalReadyPrice) /
          (Number(depreciationPeriod) * 30) /
          Number(targetUtilRate),
      )
    : null;
