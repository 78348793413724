import { useContext } from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {
  PricingSheetRow,
  RowFieldType,
} from '../../../../../shared/types';
import RowsProvider from '../../../providers/Rows/RowsProvider';

interface CustomHeaderProps {
  filteredRows: PricingSheetRow[];
  toggleAll?: boolean;
  toggleField?: RowFieldType;
  toggleValue?: string;
}

const SelectByOfferToggler: React.FC<CustomHeaderProps> = ({
  filteredRows,
  toggleField,
  toggleValue,
  toggleAll,
}) => {
  const { dispatchPricingSheetRows } = useContext(RowsProvider);

  const isAtLeastOnePreSelected = () => {
    if (toggleAll) {
      return true;
    } else if (toggleField && toggleValue) {
      return (
        filteredRows
          .filter((row) => row[toggleField] === toggleValue)
          .filter((row) => row.preSelected).length > 0
      );
    } else {
      return false;
    }
  };

  const isAllByOffer = () => {
    let childItems: PricingSheetRow[] = [];

    if (toggleAll) {
      childItems = filteredRows;
    } else if (toggleField && toggleValue) {
      childItems = filteredRows.filter(
        (row) => row[toggleField] === toggleValue,
      );
    }

    const offerCount = childItems.filter(
      (row) => row.showByOfferText,
    );

    const priceCount = childItems.filter(
      (row) => !row.showByOfferText,
    );

    if (offerCount.length === childItems.length) {
      return true;
    }
    if (priceCount.length === childItems.length) {
      return false;
    }
    return undefined;
  };
  const showByOfferTextValue = isAllByOffer();

  const handleByOfferChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean,
  ) => {
    if (newAlignment === null) {
      return;
    }
    dispatchPricingSheetRows({
      id: toggleAll
        ? 'TOGGLE_ALL'
        : toggleField?.concat('|', toggleValue ?? ''),
      type: 'updateShowByOfferTexts',
      selectedStatus: newAlignment,
    });
  };

  return (
    <>
      {isAtLeastOnePreSelected() ? (
        <ToggleButtonGroup
          sx={{ ml: 2 }}
          style={{ height: 22 }}
          size="small"
          color="primary"
          value={showByOfferTextValue}
          exclusive
          onChange={handleByOfferChange}
        >
          <ToggleButton size="small" value={false}>
            Hinnoitellaan
          </ToggleButton>
          <ToggleButton size="small" value={true}>
            Tarjouksen mukaan
          </ToggleButton>
        </ToggleButtonGroup>
      ) : null}
    </>
  );
};

export default SelectByOfferToggler;
