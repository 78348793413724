import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import SelectIndustry from './SelectIndustry';
import {
  AgGridColumnView,
  IndustryName,
} from '../../../../shared/types';
import { ADMIN_COLUMNS } from '../../../../shared/constants';

interface SelectAdminColumnsProps {
  industry: IndustryName;
  setIndustry: React.Dispatch<React.SetStateAction<IndustryName>>;
  updateGridColumnView: (view: AgGridColumnView) => void;
  gridColumnView: AgGridColumnView;
  refreshCells: any;
}

const SelectAdminColumns: React.FC<SelectAdminColumnsProps> = ({
  industry,
  setIndustry,
  updateGridColumnView,
  gridColumnView,
  refreshCells,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      gap={1}
      sx={{
        flexGrow: 1,
        flexDirection: 'row',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          display: 'inline',
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      }}
    >
      <Box>
        <ButtonGroup
          disableElevation
          orientation={isSmallScreen ? 'vertical' : 'horizontal'}
        >
          {Object.entries(ADMIN_COLUMNS).map(([view, label]) => (
            <AdminColumnButton
              key={view}
              active={gridColumnView === view}
              label={label}
              onClick={() =>
                updateGridColumnView(view as AgGridColumnView)
              }
            />
          ))}
        </ButtonGroup>{' '}
      </Box>
      <Box>
        {(gridColumnView === AgGridColumnView.ADMIN_INDUSTRY_DAY ||
          gridColumnView ===
            AgGridColumnView.ADMIN_INDUSTRY_MONTH) && (
          <SelectIndustry
            industry={industry}
            setIndustry={setIndustry}
            gridColumnView={gridColumnView}
            updateGridColumnView={updateGridColumnView}
          />
        )}
      </Box>
    </Box>
  );
};

type AdminColumnButtonProps = {
  active: boolean;
  label: ReactNode | string;
  onClick: () => void;
};

const AdminColumnButton = ({
  active = false,
  label,
  onClick = () => null,
}: AdminColumnButtonProps) => (
  <Button
    onClick={onClick}
    variant={active ? 'contained' : 'outlined'}
    sx={!active ? { backgroundColor: '#e5e5e5' } : {}}
  >
    {label}
  </Button>
);

export default SelectAdminColumns;
