import { TableFilter } from '../components/Pricing';

import { PricingSheetRow } from '../../../shared/types';

// PLEASE NOTE THAT THIS SHOULD BE USED ONLY IN PRICING SIDE, NOT ADMIN!

// test if the row passes SOME of the active filters
export const combineFilters =
  (...filters: TableFilter[]) =>
  (row: PricingSheetRow) => {
    return filters
      .map((filter) => filter(row))
      .some((x) => x === true);
  };

const getFilteredRows = (
  pricingSheetRows: PricingSheetRow[],
  activeFilters: TableFilter[],
) => {
  return activeFilters.length > 0
    ? pricingSheetRows.filter(combineFilters(...activeFilters))
    : pricingSheetRows;
};

export default getFilteredRows;
