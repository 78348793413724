import { PricingSheetRow } from "./types";

const getFinalValue = (value: number | undefined) => {
  return value ? value / 100 : null;
};

export const getMonthCoefficient = (
  pricingSheetRow: PricingSheetRow,
  coefficients:
    | {
        dayCoefficient: string;
        monthCoefficient: string;
      }
    | undefined
    | null,
) => {
  let value = null;

  switch (coefficients?.monthCoefficient) {
    case "monthPriceCoefficient1":
      value = getFinalValue(pricingSheetRow?.monthPriceCoefficient1);
      break;
    case "monthPriceCoefficient2":
      value = getFinalValue(pricingSheetRow?.monthPriceCoefficient2);
      break;
    case "monthPriceCoefficient3":
      value = getFinalValue(pricingSheetRow?.monthPriceCoefficient3);
      break;
    case "monthPriceCoefficient4":
      value = getFinalValue(pricingSheetRow?.monthPriceCoefficient4);
      break;
    case "monthPriceCoefficient5":
      value = getFinalValue(pricingSheetRow?.monthPriceCoefficient5);
      break;
    case "monthPriceCoefficient6":
      value = getFinalValue(pricingSheetRow?.monthPriceCoefficient6);
      break;
    case "monthPriceCoefficient7":
      value = getFinalValue(pricingSheetRow?.monthPriceCoefficient7);
      break;
    case "monthPriceCoefficientList":
      value = getFinalValue(pricingSheetRow?.monthPriceCoefficientList);
      break;
  }
  return value;
};
