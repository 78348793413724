import {
  Nullable,
  PricingBasis,
  PricingSheetRow,
} from '../../../../../shared/types';

export enum ChangeType {
  ABSOLUTE = 'ABSOLUTE',
  PERCENT = 'PERCENT',
}

export type EditItemsDialogChange = {
  absoluteValue: Nullable<number>;
  percentValue: Nullable<number>;
};

export type EditItemsDialogChangeWithKey<T = string> =
  EditItemsDialogChange & {
    key: T;
  };

export type EditItemsDialogPricingBasis = Exclude<
  PricingBasis,
  'BOTH'
>;

export type TabProps<T extends EditItemsDialogPricingBasis> = {
  changeType: ChangeType;
  changeTypeCommon: ChangeType;
  targetUtilRate: EditItemsDialogChange;
  depreciationPeriod: EditItemsDialogChange;
  rentalReadyPrice: EditItemsDialogChange;
  highlightedItem: Nullable<PricingSheetRow>;
  pricingBasis: T;
};
