import React, { ReactElement } from 'react';

import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SelectGrouping from './SelectGrouping';
import ButtonGroup from '@mui/material/ButtonGroup';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';
import DefaultTooltip from '../Tooltips/DefaultTooltip';
import { ItemGroup } from '../../../../shared/types';
import { GroupingType } from '../../hooks/useColumns';

interface EditViewDialogProps {
  editRoundingBases?: ReactElement;
  groupingStyle: GroupingType;
  groupingValues: ItemGroup[];
  setDefaultGroupingValues: (value: GroupingType) => void;
  updateGroupingValues: (values: ItemGroup[]) => void;
}

const EditViewDialog: React.FC<EditViewDialogProps> = ({
  editRoundingBases,
  groupingStyle,
  groupingValues,
  setDefaultGroupingValues,
  updateGroupingValues,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleGroupingStyleChange = (value: GroupingType) => {
    if (value === 'MIRA') {
      setDefaultGroupingValues('MIRA');
    } else {
      setDefaultGroupingValues('PIM');
    }
  };

  return (
    <>
      <DefaultTooltip title="Muokkaa näkymää">
        <IconButton onClick={handleOpen}>
          <SettingsIcon style={{ fontSize: 20 }} />
        </IconButton>
      </DefaultTooltip>

      <Dialog
        disableScrollLock
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle>Muokkaa näkymää</DialogTitle>

        <Fade in={open}>
          <DialogContent>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              color="primary"
            >
              Valitse ryhmittely
            </Typography>

            <ButtonGroup disableElevation>
              <Button
                onClick={() => handleGroupingStyleChange('MIRA')}
                variant={
                  groupingStyle === 'MIRA' ? 'contained' : 'outlined'
                }
                sx={
                  groupingStyle === 'PIM'
                    ? {
                        backgroundColor: '#e5e5e5',
                      }
                    : {}
                }
              >
                Mira
              </Button>
              <Button
                onClick={() => handleGroupingStyleChange('PIM')}
                variant={
                  groupingStyle === 'PIM' ? 'contained' : 'outlined'
                }
                sx={
                  groupingStyle === 'MIRA'
                    ? {
                        backgroundColor: '#e5e5e5',
                      }
                    : {}
                }
              >
                PIM
              </Button>
            </ButtonGroup>
            <DialogActions sx={{ ml: 2, mr: 2, p: 2 }}>
              {groupingStyle === 'MIRA' ? (
                <SelectGrouping
                  groupingValues={groupingValues}
                  updateGroupingValues={updateGroupingValues}
                  type={'MIRA'}
                />
              ) : (
                <SelectGrouping
                  groupingValues={groupingValues}
                  updateGroupingValues={updateGroupingValues}
                  type={'PIM'}
                />
              )}
            </DialogActions>
            {editRoundingBases ? (
              <>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  color="primary"
                >
                  Valitse pyöristys
                </Typography>
                <DialogActions sx={{ ml: 2, mr: 2, p: 2 }}>
                  {editRoundingBases}
                </DialogActions>
              </>
            ) : null}
          </DialogContent>
        </Fade>
      </Dialog>
    </>
  );
};

export default EditViewDialog;
