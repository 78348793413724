import { getPricingSheetItemsById } from '../services/pricingSheetItems';
import { sortByCatClass } from '../../../shared/sortByCatClass';

import { PricingSheetRow } from '../../../shared/types';

import { getItems } from '../services/items';

// add flag to get only preselected items
const getRowsForPricingSheet = async (
  pricingSheetId: string,
): Promise<PricingSheetRow[]> => {
  const allAvailableItems = await getItems();
  const pricingSheetItems =
    await getPricingSheetItemsById(pricingSheetId);

  const mergedItems = allAvailableItems.map<PricingSheetRow>(
    (item) => {
      const pricingSheetItem = pricingSheetItems.find(
        (row) => item.id === row.item.id,
      );
      if (pricingSheetItem) {
        return {
          ...item,
          dayPrice: Number(pricingSheetItem.dayPrice),
          monthPrice: Number(pricingSheetItem.monthPrice),
          proposalDayPrice: pricingSheetItem.proposalDayPrice
            ? Number(pricingSheetItem.proposalDayPrice)
            : null,
          proposalMonthPrice: pricingSheetItem.proposalMonthPrice
            ? Number(pricingSheetItem.proposalMonthPrice)
            : null,
          showByOfferText: pricingSheetItem.showByOfferText,
          approverComment: pricingSheetItem.approverComment,
          preSelected: true,
          includeInPricing: true,
        };
      } else {
        return {
          ...item,
          preSelected: false,
          showByOfferText: false,
        };
      }
    },
  );
  return sortByCatClass(mergedItems.filter((row) => row.pimPath));
};

export default getRowsForPricingSheet;
