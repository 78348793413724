import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import {
  OfferRejectionReason,
  OfferRejectionReasonKeys,
} from '../../../../../shared/types/offers';
import React, { useEffect, useState } from 'react';
import { DIALOG_TRANSITION_DURATION } from '../../../../../shared/constants';
import TextField from '@mui/material/TextField';
import { ConfirmationDialog } from '../../../components/Common/ConfirmationDialog';

type RejectedOfferDialogProps = {
  loading?: boolean;
  onClose: () => void;
  onConfirm: (
    rejectionReason: OfferRejectionReasonKeys,
    comment: string,
  ) => void;
  open: boolean;
};

const INITIAL_SELECTION = Object.keys(
  OfferRejectionReason,
)[0] as OfferRejectionReasonKeys;

export const RejectedOfferDialog = ({
  loading = false,
  onClose = () => null,
  onConfirm = () => null,
  open,
}: RejectedOfferDialogProps) => {
  const [rejectionReason, setRejectionReason] =
    useState<OfferRejectionReasonKeys>(INITIAL_SELECTION);
  const [comment, setComment] = useState('');

  // reset state when dialog is closed (after transition is complete)
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setComment('');
        setRejectionReason(INITIAL_SELECTION);
      }, DIALOG_TRANSITION_DURATION);
    }
  }, [open]);
  return (
    <ConfirmationDialog
      open={open}
      loading={loading}
      onCancel={onClose}
      onConfirm={() => onConfirm(rejectionReason, comment)}
      title={'Tarjouksen hylkäyksen syy'}
      confirmButtonLabel={'Vahvista'}
    >
      <RadioGroup
        name={'reason'}
        value={rejectionReason}
        onChange={(e) =>
          setRejectionReason(
            e.target.value as OfferRejectionReasonKeys,
          )
        }
      >
        {Object.entries(OfferRejectionReason).map(
          ([value, label]) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio size={'small'} />}
              label={label}
            />
          ),
        )}
      </RadioGroup>
      <TextField
        fullWidth
        multiline
        onChange={(event) => setComment(event.target.value)}
        label={'Kommentti'}
        rows={4}
        size={'small'}
        sx={{ mt: 2 }}
        value={comment}
      />
    </ConfirmationDialog>
  );
};
