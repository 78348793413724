import { MouseEvent, ReactNode, useState } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItemProps,
  MenuItem,
  Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

type ActionMenuProps = {
  toggleIcon: ReactNode;
  renderItems: (closeMenu: () => void) => ReactNode;
};

export const ActionMenu = ({
  renderItems,
  toggleIcon,
}: ActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        onClick={handleClick}
        size={'small'}
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup={'true'}
        aria-expanded={open ? 'true' : undefined}
      >
        {toggleIcon}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id={'action-menu'}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.25))',
            mt: 1.5,
            '.MuiList-root': {
              py: 0.5,
            },
            // arrow
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 11,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {renderItems(handleClose)}
      </Menu>
    </>
  );
};

type ActionMenuItemProps = MenuItemProps & {
  icon?: SvgIconComponent;
  iconProps?: SvgIconProps;
  label: string;
  loading?: boolean;
  tooltipText?: ReactNode;
};

export const ActionMenuItem = ({
  icon: Icon,
  iconProps,
  label,
  loading = false,
  tooltipText,
  ...props
}: ActionMenuItemProps) => {
  const item = (
    <div>
      <MenuItem
        {...props}
        dense
        sx={{
          gap: 1,
        }}
      >
        {loading ? (
          <CircularProgress size={16} thickness={5} />
        ) : Icon ? (
          <Icon
            {...(iconProps || {})}
            sx={{
              ...iconProps?.sx,
              fontSize: '1.125rem',
              opacity: 0.75,
            }}
          />
        ) : null}
        <Typography
          whiteSpace={'nowrap'}
          sx={{
            fontSize: '0.875rem',
          }}
        >
          {label}
        </Typography>
      </MenuItem>
    </div>
  );
  return tooltipText ? (
    <Tooltip
      enterDelay={400}
      enterNextDelay={400}
      title={tooltipText}
    >
      {item}
    </Tooltip>
  ) : (
    item
  );
};
