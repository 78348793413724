import { useContext, useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import BackgroundProvider from '../../../../providers/Background/BackgroundProvider';
import PricingProvider from '../../../../providers/Pricing/PricingProvider';

interface GenericCheckboxProps {
  label: string;
  propName: string;
  changeValuesEnabled?: boolean;
}

const GenericCheckbox: React.FC<GenericCheckboxProps> = ({
  label,
  propName,
  changeValuesEnabled,
}) => {
  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );
  const { pricingSheetId } = useContext(PricingProvider);

  const [checked, setChecked] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setChecked(event.target.checked);

    const newState = { ...backgroundInfo };
    newState[propName] = event.target.checked;

    setBackgroundInfo(newState);
  };

  useEffect(() => {
    if (
      (propName === 'revenueOverMillion' &&
        backgroundInfo.revenue > 200) ||
      (propName === 'revenueOverMillion' &&
        backgroundInfo.revenueOverMillion)
    ) {
      setChecked(true);
    } else if (
      (propName === 'potentialOver100k' &&
        backgroundInfo.potential?.top > 20) ||
      (propName === 'potentialOver100k' &&
        backgroundInfo.potentialOver100k)
    ) {
      setChecked(true);
    } else if (
      propName === 'nationWide' &&
      backgroundInfo.nationWide
    ) {
      setChecked(true);
    } else if (
      propName === 'regionallySignificant' &&
      backgroundInfo.regionallySignificant
    ) {
      setChecked(true);
    } else if (
      propName === 'strategicallySignificant' &&
      backgroundInfo.strategicallySignificant
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundInfo]);

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            disabled={
              pricingSheetId &&
              (propName === 'revenueOverMillion' ||
                propName === 'potentialOver100k') &&
              !changeValuesEnabled
                ? true
                : false
            }
          />
        }
        label={<Typography color="text.primary">{label}</Typography>}
      />
    </div>
  );
};

export default GenericCheckbox;
