import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';

export type SearchComponentProps<T> = Omit<
  AutocompleteProps<T, false, false, false>,
  'renderGroup' | 'renderOption' | 'renderInput' | 'onSelect'
> & {
  clearOnSelect?: boolean;
  label: ReactNode;
  onClear?: () => void;
  onSearch?: (query: string) => void;
  onSelect?: (selectedOption: T | null) => void;
  searchMinLength?: number;
};

export const SearchComponent = <
  T extends { id: unknown; value: unknown },
>({
  clearOnSelect = false,
  label,
  loading,
  loadingText = 'Haetaan...',
  noOptionsText = 'Ei tuloksia',
  onClear = () => null,
  onSearch = () => null,
  onSelect = () => null,
  options,
  placeholder,
  searchMinLength = 2,
  sx,
  ...rest
}: SearchComponentProps<T>) => {
  const [query, setQuery] = React.useState<string>('');
  const [value, setValue] = React.useState<T | null>(null);
  return (
    <Autocomplete
      sx={{
        ...sx,
        'button.MuiAutocomplete-clearIndicator': {
          visibility: 'visible',
        },
      }}
      fullWidth
      clearOnBlur={false}
      clearOnEscape
      clearText={rest.clearText || 'Tyhjennä'}
      size={'small'}
      value={value}
      inputValue={query}
      options={options || []}
      loading={loading}
      noOptionsText={noOptionsText}
      loadingText={loadingText}
      onKeyPress={({ key }) => {
        if (key === 'Enter') {
          onSearch(query);
        }
      }}
      onChange={(_, selectedOption) => {
        onSelect(selectedOption);
        if (clearOnSelect) {
          onClear();
          setQuery('');
          setValue(null);
          return;
        }
        setValue(selectedOption);
      }}
      onInputChange={(_, value) => {
        setQuery(value);
        if (!value) {
          onClear();
        }
      }}
      {...rest}
      // RENDER GROUP
      renderGroup={(params) => (
        <Box key={params.key}>
          {/* GROUP HEADER */}
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: '#3f50b5',
              color: 'white',
              display: 'flex',
              px: 1,
              py: 0.5,
            }}
          >
            {params.group}
          </Box>
          {params.children}
        </Box>
      )}
      // RENDER OPTION
      renderOption={(props, option) => (
        <Box
          {...props}
          key={String(option.id)}
          component={'li'}
          sx={{
            [`&.${props.className}`]: {
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          <Box
            style={{
              lineHeight: 1.2,
            }}
          >
            {option.value}
          </Box>
          <Box style={{ fontSize: '12px' }}>{option.id}</Box>
        </Box>
      )}
      // RENDER INPUT
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                <IconButton
                  onClick={() => onSearch(query)}
                  size={'small'}
                  sx={{
                    p: 0.5,
                  }}
                  disabled={loading || query.length < searchMinLength}
                >
                  {loading ? (
                    <CircularProgress
                      color={'primary'}
                      size={16}
                      thickness={5}
                    />
                  ) : (
                    <SearchIcon fontSize={'small'} />
                  )}
                </IconButton>
              </>
            ),
          }}
        />
      )}
    />
  );
};
