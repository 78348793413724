import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import BlockIcon from '@mui/icons-material/Block';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import MiraDialog from './../PricingSheets/Dialogs/MiraDialog';
import NewVersionDialog from './../PricingSheets/Dialogs/NewVersionDialog';
import GroupIcon from '@mui/icons-material/Group';

import InventoryIcon from '@mui/icons-material/Inventory';

import {
  PricingSheet,
  PricingSheetState,
  PdfQuality,
} from '../../../../shared/types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { formatDateTolocaleDateString } from '../../utils/formatDateTimes';

import NotificationProvider from '../../providers/Notification/NotificationProvider';
import RowsProvider from '../../providers/Rows/RowsProvider';
import useUserContext from '../../providers/User/UserProvider';

import getSheetState from '../../helpers/getSheetState';

import SheetIcon from '../../components/Common/SheetIcon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { createTheme } from '@mui/material/styles';

import CircularProgress from '@mui/material/CircularProgress';
import DefaultTooltip from '../../components/Tooltips/DefaultTooltip';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';

import AdditionalInfo from './AdditionalInfo';
import { downloadPdf } from '../../helpers/downloadPdf';
import { currencyMultiplier } from '../../../../shared/constants';
import PricingProvider from '../../providers/Pricing/PricingProvider';
import BackgroundProvider from '../../providers/Background/BackgroundProvider';
import { isEditingRights } from '../../../../shared/helpers/isEditingRights';
import { isEditingLocked } from '../../../../shared/helpers/isEditingLocked';
import filterOutPricingEmail from '../../helpers/filterOutPricingEmail';
import { isCustomerBlocked } from '../../helpers/isCustomerBlocked';
import { formatCustomerBlockingText } from '../../helpers/formatCustomerBlockingText';
import { GetMiraCustomerByIdResponseBody } from '../../../../shared/types/customer';
import _ from 'lodash';

interface PricingRowProps {
  miraCustomers: GetMiraCustomerByIdResponseBody[];
  pricingSheet: PricingSheet;
  updateSheets: () => Promise<void>;
}

const PricingRow: React.FC<PricingRowProps> = ({
  pricingSheet,
  updateSheets,
  miraCustomers,
}) => {
  const theme = createTheme();

  const { hierarchy } = useContext(RowsProvider);
  const { setNotification } = useContext(NotificationProvider);
  const { isAdmin, userEmail } = useUserContext();
  const { setPricingSheetId } = useContext(PricingProvider);
  const { setBackgroundInfo } = useContext(BackgroundProvider);

  const anchorRefPdfDownload = React.useRef<HTMLInputElement>(null);
  const [openPdfOptions, setOpenPdfOptions] = React.useState(false);

  const handleClosePdfOptions = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRefPdfDownload.current &&
      anchorRefPdfDownload.current.contains(
        event.target as HTMLElement,
      )
    ) {
      return;
    }

    setOpenPdfOptions(false);
  };

  const handleTogglePdfOptions = () => {
    setOpenPdfOptions((prevOpen) => !prevOpen);
  };

  let history = useHistory();

  const [open, setOpen] = useState<boolean>(false);
  const [sheetState, setSheetState] = useState<PricingSheetState>(
    pricingSheet.state,
  );
  const [miraDialogOpen, setMiraDialogOpen] = React.useState(false);
  const [newSheetDialogOpen, setNewSheetDialogOpen] =
    React.useState(false);

  const handleMiraClickOpen = () => {
    setMiraDialogOpen(true);
  };

  const handleMiraDialogClose = () => {
    setMiraDialogOpen(false);
  };

  const handleNewSheetClickOpen = () => {
    setNewSheetDialogOpen(true);
  };

  const handleNewSheetDialogClose = () => {
    setNewSheetDialogOpen(false);
  };

  const isArchived = !!pricingSheet.archivedAt;

  const [pdfLoading, setPdfLoading] = useState(false);

  const getPricingSheetPdf = async (
    id: string,
    name: string,
    quality: PdfQuality,
  ) => {
    try {
      setPdfLoading(true);
      await downloadPdf(id, name, quality);
    } catch (error) {
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'error',
        message: 'Hinnaston lataaminen epäonnistui',
      });
    } finally {
      setPdfLoading(false);
    }
  };

  const goToEditPricingSheet = (pricingSheetId: string) => {
    history.push('/muokkaa-hinnastoa/' + pricingSheetId);
  };

  const onClickInspectPricingSheet = () => {
    const { potentialTop, potentialBottom, ...rest } = pricingSheet;

    const backgroundInfo = {
      ...rest,
      potential: {
        bottom: potentialBottom
          ? potentialBottom / currencyMultiplier
          : null,
        top: potentialTop ? potentialTop / currencyMultiplier : null,
      },
    };
    setPricingSheetId(pricingSheet.id);
    setBackgroundInfo(backgroundInfo);
    history.push(`/tarkastele-hinnastoa/${pricingSheet.id}`, {
      readOnly: true,
    });
  };

  const editButtonTooltipText = () => {
    if (isEditingLocked(pricingSheet, userEmail ?? '')) {
      return `${pricingSheet.editorFullName} on muokkaamassa hinnastoa. Ainoastaan yksi henkilö voi muokata hinnastoa kerrallaan`;
    }
    if (!pricingSheet.isLatestVersion) {
      return 'Hinnastosta on tehty uusi versio. Ainoastaan viimeisin versio on muokattavissa';
    }
    return '';
  };

  const isSheetShared = ({ editingRights }: PricingSheet) =>
    editingRights &&
    editingRights.split(',').filter(filterOutPricingEmail).length > 0;

  const isEditDisabled = () => {
    // Archived sheets can't be edited
    if (!!pricingSheet.archivedAt) {
      return true;
    }

    // User must be the creator or have editing rights to edit sheet
    if (
      pricingSheet.userEmail !== userEmail &&
      !isEditingRights(pricingSheet.editingRights, userEmail)
    ) {
      return true;
    }

    // Sheet edit is disabled after a new version is created.
    // Only latest version can be edited
    if (!pricingSheet.isLatestVersion) {
      return true;
    }

    // Sheet cant't be edited after it's ready or in use
    if (
      sheetState === PricingSheetState.Ready ||
      sheetState === PricingSheetState.InUse
    ) {
      return true;
    }

    // Edit is disabled if another user is editing it
    if (pricingSheet.isSharedSheetLocked) {
      return true;
    }
    return false;
  };

  const isMiraExportDisabled = () => {
    // Archived sheets can't be exported
    if (isArchived) {
      return true;
    }
    // Sheet to export must be Approved or Ready
    if (
      !(sheetState === PricingSheetState.Approved) &&
      !(sheetState === PricingSheetState.Ready)
    ) {
      return true;
    }
    // User must be the creator or have editing rights to export the sheet
    if (
      pricingSheet.userEmail !== userEmail &&
      !isEditingRights(pricingSheet.editingRights, userEmail)
    ) {
      return true;
    }
    return false;
  };

  const miraExportTooltipTitle = () => {
    if (
      pricingSheet.userEmail !== userEmail &&
      pricingSheet.userEmail !== 'admin' &&
      !isAdmin &&
      !isEditingRights(pricingSheet.editingRights, userEmail)
    ) {
      return 'Vain omia tai sinulle jaettuja hinnastoja voi viedä miraan';
    }
    if (
      sheetState !== PricingSheetState.Approved &&
      sheetState !== PricingSheetState.Ready
    ) {
      return 'Hinnaston täytyy olla hyväksytty ennen vientiä';
    }
    return 'Hinnasto on valmis Miraan, voit pyytää sille vientiä';
  };

  const miraCustomer =
    _.find(miraCustomers, {
      clientId: String(pricingSheet.customerId),
    }) ?? {};

  const isBlocked = isCustomerBlocked(miraCustomer);
  const infoTooltipText = formatCustomerBlockingText(miraCustomer);

  return (
    <>
      <TableRow key={pricingSheet.id}>
        {/** PRICING SHEET NAME */}
        <TableCell>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '300px',
              }}
            >
              <Tooltip title={pricingSheet.name}>
                <span>{pricingSheet.name}</span>
              </Tooltip>
            </Box>
            <Box sx={{ marginLeft: 'auto' }}>
              {isBlocked && (
                <DefaultTooltip
                  title={
                    <span style={{ whiteSpace: 'pre-line' }}>
                      {infoTooltipText}
                    </span>
                  }
                >
                  <SvgIcon
                    color="error"
                    sx={{
                      fontSize: 20,
                    }}
                    component={BlockIcon}
                  />
                </DefaultTooltip>
              )}
            </Box>
          </Box>
        </TableCell>
        {/** USER EMAIL */}
        <TableCell sx={{ padding: '6px' }}>
          {pricingSheet?.userEmail}
        </TableCell>
        {/** PRICING SHEET STATE */}
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {pricingSheet.archivedAt ? (
              <>
                <Box sx={{ mt: 1, mr: 1 }}>
                  <InventoryIcon
                    sx={{
                      color: theme.palette.error.main,
                      fontSize: 18,
                    }}
                  />
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Box>{`(${getSheetState(sheetState)})`}</Box>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ mt: 1, mr: 1 }}>
                  <SheetIcon state={sheetState} />
                </Box>
                <Box sx={{ mt: 1 }}>
                  {sheetState === PricingSheetState.InUse ? (
                    <Box sx={{ color: theme.palette.success.main }}>
                      Käytössä
                    </Box>
                  ) : (
                    getSheetState(sheetState)
                  )}
                </Box>
              </>
            )}
          </Box>
        </TableCell>
        {/** EXPIRATION DATE */}
        <TableCell>
          {isArchived ? (
            '-'
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box sx={{ mt: 1, mr: 1 }}>
                {pricingSheet.expired ? (
                  <ErrorOutlineIcon
                    style={{
                      fontSize: 20,
                      color: theme.palette.error.main,
                    }}
                  />
                ) : (
                  <CheckCircleOutlineIcon
                    style={{
                      fontSize: 20,
                      color: theme.palette.success.main,
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  mt: 1,
                  color: pricingSheet.expired
                    ? theme.palette.error.main
                    : theme.palette.success.main,
                }}
              >
                {pricingSheet?.to
                  ? formatDateTolocaleDateString(pricingSheet.to)
                  : null}
              </Box>
            </Box>
          )}
        </TableCell>
        {/** SHARED */}
        <TableCell
          sx={{
            textAlign: 'center',
          }}
        >
          {isSheetShared(pricingSheet) && (
            <Tooltip
              arrow
              placement={'top'}
              title={
                <>
                  <Typography variant="body1">
                    Hinnasto on jaettu käyttäjille:
                  </Typography>
                  {pricingSheet.editingRights
                    ?.split(',')
                    .filter(filterOutPricingEmail)
                    .map((user) => (
                      <Typography key={user} variant="body2">
                        {user}
                      </Typography>
                    ))}
                </>
              }
            >
              <GroupIcon sx={{ mt: 1 }} />
            </Tooltip>
          )}
        </TableCell>
        {/** EXPORT TO MIRA */}
        <TableCell align="right" sx={{ padding: '6px' }}>
          <DefaultTooltip
            title={miraExportTooltipTitle()}
            placement="top"
          >
            <div>
              <Button
                disabled={isMiraExportDisabled()}
                variant="contained"
                size="small"
                sx={{ whiteSpace: 'nowrap' }}
                onClick={() => handleMiraClickOpen()}
              >
                Vie Miraan
              </Button>
            </div>
          </DefaultTooltip>
          <MiraDialog
            pricingSheet={pricingSheet}
            open={miraDialogOpen}
            setSheetState={setSheetState}
            updateSheets={updateSheets}
            title={'Valmis Miraan'}
            onClose={handleMiraDialogClose}
          />
        </TableCell>
        {/** EDIT BUTTON */}
        <TableCell align="right" sx={{ padding: '6px' }}>
          <DefaultTooltip
            title={editButtonTooltipText()}
            placement="top"
          >
            <div>
              <Button
                disabled={isEditDisabled()}
                variant="contained"
                size="small"
                sx={{ my: 0.5 }}
                onClick={() =>
                  sheetState === PricingSheetState.Approved ||
                  sheetState === PricingSheetState.Ready ||
                  sheetState === PricingSheetState.InUse
                    ? handleNewSheetClickOpen()
                    : goToEditPricingSheet(pricingSheet.id)
                }
              >
                Muokkaa
              </Button>
            </div>
          </DefaultTooltip>
          <NewVersionDialog
            pricingSheet={pricingSheet}
            open={newSheetDialogOpen}
            title={'Uusi versio?'}
            onClose={handleNewSheetDialogClose}
            hierarchy={hierarchy}
            goToEditPricingSheet={goToEditPricingSheet}
          />
        </TableCell>
        {/** VIEW BUTTON */}
        <TableCell sx={{ padding: '6px' }}>
          <Button
            disabled={
              pricingSheet?.state !== PricingSheetState.Approved &&
              pricingSheet?.state !== PricingSheetState.Ready &&
              pricingSheet?.state !== PricingSheetState.InUse
            }
            variant="contained"
            size="small"
            onClick={() => {
              onClickInspectPricingSheet();
            }}
          >
            Tarkastele
          </Button>
        </TableCell>
        {/** DOWNLOAD PDF */}
        <TableCell align="center" sx={{ padding: '6px' }}>
          {pdfLoading ? (
            <CircularProgress size={20} />
          ) : (
            <>
              <ButtonGroup
                size="small"
                variant="contained"
                ref={anchorRefPdfDownload}
                disabled={
                  pricingSheet?.state === PricingSheetState.Pending ||
                  pricingSheet?.state ===
                    PricingSheetState.WarningDraft ||
                  pricingSheet?.state === PricingSheetState.OkDraft ||
                  pricingSheet.userEmail === 'admin'
                }
              >
                <Button
                  sx={{ width: 100 }}
                  onClick={() => {
                    getPricingSheetPdf(
                      pricingSheet.id ?? '',
                      pricingSheet.name,
                      PdfQuality.LOW,
                    );
                  }}
                >
                  Lataa PDF
                </Button>
                <Button
                  sx={{ minWidth: 5, padding: 0, margin: 0 }}
                  onClick={handleTogglePdfOptions}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                style={{
                  zIndex: 1,
                }}
                open={openPdfOptions}
                anchorEl={anchorRefPdfDownload.current}
                role={undefined}
                transition
                disablePortal
                onReset={undefined}
                onResetCapture={undefined}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom'
                          ? 'center top'
                          : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={handleClosePdfOptions}
                      >
                        <MenuList>
                          {[
                            {
                              value: PdfQuality.LOW,
                              label: 'Normaali laatu (~5Mt)',
                            },
                            {
                              value: PdfQuality.HIGH,
                              label: 'Korkea laatu (~30Mt)',
                            },
                          ].map((option) => (
                            <MenuItem
                              key={option.value}
                              onClick={(event) => {
                                downloadPdf(
                                  pricingSheet.id ?? '',
                                  pricingSheet.name,
                                  option.value,
                                );
                                setOpenPdfOptions(false);
                              }}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          )}
        </TableCell>
        {/** EXPAND ROW */}
        <TableCell sx={{ padding: '6px' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          sx={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <AdditionalInfo
              pricingSheet={pricingSheet}
              updateSheets={updateSheets}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PricingRow;
