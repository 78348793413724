import { ValueGetterParams } from 'ag-grid-community';
import { PricingSheetRow } from '../../../../../shared/types';

const proposalDayPriceGetter = (
  params: ValueGetterParams<PricingSheetRow>,
) => {
  const proposalDayPrice = params.data?.proposalDayPrice;

  if (!proposalDayPrice) return '';

  const divider = 1000000;
  const dividedProposalDayPrice = proposalDayPrice / divider;

  return dividedProposalDayPrice
    ? Number(dividedProposalDayPrice.toFixed(2))
    : null;
};

export default proposalDayPriceGetter;
