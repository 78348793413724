import React from 'react';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Collapse,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  KeyboardArrowDown,
  WarningRounded,
} from '@mui/icons-material';
import CatClassToolTip from '../../Tooltips/CatClassToolTip';
import {
  AgGridColumnView,
  Language,
  PricingSheetRow,
  TableType,
} from '../../../../../shared/types';
import { countRemovalDayPrice } from '../../../../../shared/countRemovalDayPrice';
import { countRemovalMonthPrice } from '../../../../../shared/countRemovalMonthPrice';
import { countPriceCoefficient } from '../../../utils/pricingFormulas';
import ramigreenLogo from '../../../img/ramigreen-logo.png';
import { PRICE_FACTOR } from '../../../../../shared/constants';
import ProductCard from '../../Common/ProductCard';
import DefaultTooltip from '../../Tooltips/DefaultTooltip';
import { calculateTargetUtilRate } from '../../../../../shared/countOfferPrice';
import { getSurplusRange } from '../../../../../shared/getSurplusRange';

interface CatClassRendererProps {
  row?: PricingSheetRow;
  type?: TableType;
  getFilteredRows?: () => PricingSheetRow[];
  catClassInnerRenderer?: (
    row: any,
    rows: PricingSheetRow[],
  ) => JSX.Element;
  setShowMachineCard?: (value: boolean) => void;
  showMachineCard: boolean;
  gridColumnView?: AgGridColumnView;
  language?: Language;
}
const CatClassRenderer: React.FC<CatClassRendererProps> = ({
  row,
  getFilteredRows,
  catClassInnerRenderer,
  showMachineCard,
  setShowMachineCard,
  gridColumnView,
  language,
}) => {
  const theme = useTheme();
  if (!row) return null;
  const filteredRows = getFilteredRows?.() || [row];

  const isCriticalEquipment = row.minDayPrice || row.minMonthPrice;
  const isSurplusEquipment = row.showSurplusPercentage;
  const rentalRange = {
    start: new Date(row.leasePeriodStart),
    end: new Date(row.leasePeriodEnd),
  };

  const surplusRange = getSurplusRange(row, rentalRange);

  const targetUtilRate = calculateTargetUtilRate(
    rentalRange,
    row.avgRentalPeriod,
    row.targetUtilRate,
    surplusRange,
  );

  const isOfferGrid =
    gridColumnView === AgGridColumnView.OFFER_PRESELECT ||
    gridColumnView === AgGridColumnView.OFFER_ITEMDETAILS ||
    gridColumnView === AgGridColumnView.OFFER_PRICING ||
    gridColumnView === AgGridColumnView.OFFER_INSPECT;

  const rowInSheet = filteredRows.find(
    (providerRow: PricingSheetRow) =>
      providerRow.catClass === row.catClass,
  );

  const removalDayPrice = countRemovalDayPrice(
    Number(rowInSheet?.rentalReadyPrice),
    Number(rowInSheet?.depreciationPeriod),
    Number(rowInSheet?.targetUtilRate) / 100,
  );

  const removalMonthPrice = countRemovalMonthPrice(
    Number(rowInSheet?.rentalReadyPrice),
    Number(rowInSheet?.depreciationPeriod),
    Number(rowInSheet?.targetUtilRate) / 100,
  );
  const isMissingSwedishTranslation =
    language === 'sv' && !Boolean(row.pimDisplayNameSE);
  const isMissingEnglishTranslation =
    language === 'en' && !Boolean(row.pimDisplayNameEN);

  const removalDayPriceWithTargetUtilRate = countRemovalDayPrice(
    Number(rowInSheet?.rentalReadyPrice),
    Number(rowInSheet?.depreciationPeriod),
    Number(targetUtilRate),
  );

  const removalMonthPriceWithTargetUtilRate = countRemovalMonthPrice(
    Number(rowInSheet?.rentalReadyPrice),
    Number(rowInSheet?.depreciationPeriod),
    Number(targetUtilRate),
  );

  return (
    <Box
      component={'span'}
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: 1,
        'span:first-of-type': {
          mr: 'auto',
        },
        width: '100%',
      }}
    >
      <CatClassToolTip
        title={
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <h3>Hinnoittelu</h3>
              <Box
                component="img"
                sx={{
                  visibility: row.isRamigreen ? 'visible' : 'hidden',
                  height: '80px',
                  pointerEvents: 'none',
                  mr: 2,
                  mt: 2,
                }}
                src={ramigreenLogo}
                alt="logo"
              />
            </Grid>
            <Typography color={'text.primary'}>
              Tavoitekäyttöaste:{' '}
              {rowInSheet?.targetUtilRate
                ? `${row.targetUtilRate} %`
                : '-'}
            </Typography>
            <Typography sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>
              Hintakerroin
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                flexDirection: 'row',
              }}
            >
              <Box>
                <Typography color={'text.primary'}>
                  {`Ehdotettu (pv):
          ${
            rowInSheet?.proposalDayPrice && removalDayPrice
              ? countPriceCoefficient(
                  rowInSheet?.proposalDayPrice / PRICE_FACTOR,
                  removalDayPrice,
                )
              : '-'
          }`}
                </Typography>
                <Typography color={'text.primary'}>
                  {`Syötetylla hinnalla (pv):
          ${
            rowInSheet?.dayPrice && removalDayPrice
              ? countPriceCoefficient(
                  rowInSheet?.dayPrice / PRICE_FACTOR,
                  removalDayPrice,
                )
              : '-'
          }`}
                </Typography>
                {isOfferGrid && (
                  <Typography
                    color={'text.primary'}
                    fontWeight={'bold'}
                  >
                    {`Syötetty vuokra-aika huomioitu (pv):
                ${
                  rowInSheet?.dayPrice &&
                  removalDayPriceWithTargetUtilRate
                    ? countPriceCoefficient(
                        rowInSheet?.dayPrice / PRICE_FACTOR,
                        removalDayPriceWithTargetUtilRate,
                      )
                    : '-'
                }`}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography color={'text.primary'}>
                  {`Ehdotettu (kk):
          ${
            rowInSheet?.proposalMonthPrice && removalMonthPrice
              ? countPriceCoefficient(
                  rowInSheet?.proposalMonthPrice / PRICE_FACTOR,
                  removalMonthPrice,
                )
              : '-'
          }`}
                </Typography>
                <Typography color={'text.primary'}>
                  {`Syötetylla hinnalla (kk):
          ${
            rowInSheet?.monthPrice && removalMonthPrice
              ? countPriceCoefficient(
                  rowInSheet?.monthPrice / PRICE_FACTOR,
                  removalMonthPrice,
                )
              : '-'
          }`}
                </Typography>
                {isOfferGrid && (
                  <Typography
                    color={'text.primary'}
                    fontWeight={'bold'}
                  >
                    {`Syötetty vuokra-aika huomioitu (kk):
          ${
            rowInSheet?.monthPrice &&
            removalMonthPriceWithTargetUtilRate
              ? countPriceCoefficient(
                  rowInSheet?.monthPrice / PRICE_FACTOR,
                  removalMonthPriceWithTargetUtilRate,
                )
              : '-'
          }`}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <h3>Konekortti</h3>
              {setShowMachineCard && (
                <IconButton
                  onClick={() => setShowMachineCard(!showMachineCard)}
                >
                  <KeyboardArrowDown
                    style={{
                      cursor: 'pointer',
                      transform: showMachineCard
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                    }}
                  />
                </IconButton>
              )}
            </Box>
            <Collapse in={showMachineCard}>
              <ProductCard catClass={row.catClass} type={row.type} />
            </Collapse>
          </>
        }
      >
        <Typography
          component={'span'}
          fontSize={'inherit'}
          sx={{
            color: row.removedAt
              ? theme.palette.error.main
              : 'inherit',
          }}
        >
          {row.catClass}{' '}
          {row.pimDisplayName ? row.pimDisplayName : row.name}
          {row.removedAt && <span>{' - Poistunut'}</span>}
        </Typography>
      </CatClassToolTip>
      <DefaultTooltip title={'RamiGreen tuote'} placement={'top'}>
        <Box
          component="img"
          sx={{
            display: row.isRamigreen ? 'flex' : 'none',
            height: '20px',
            pointerEvents: 'none',
          }}
          src={ramigreenLogo}
          alt="logo"
        />
      </DefaultTooltip>
      <Box
        sx={{
          display:
            (isCriticalEquipment || isSurplusEquipment) && isOfferGrid
              ? 'flex'
              : 'none',
          '& > span': {
            display: 'flex',
          },
        }}
      >
        <DefaultTooltip
          title={
            isCriticalEquipment
              ? 'Kriittisen kaluston tuote'
              : 'Ylitarjonta tuote'
          }
          placement={'top'}
        >
          <WarningRounded
            fontSize="small"
            color={isCriticalEquipment ? 'error' : 'success'}
          />
        </DefaultTooltip>
      </Box>
      {/** Show warning if there is no translation available for the item on selected language */}
      <Box
        sx={{
          display:
            (gridColumnView === AgGridColumnView.OFFER_ITEMDETAILS &&
              isMissingSwedishTranslation) ||
            isMissingEnglishTranslation
              ? 'flex'
              : 'none',
          '& > span': {
            display: 'flex',
          },
        }}
      >
        <DefaultTooltip
          title={
            isMissingSwedishTranslation
              ? 'Tuotteelta puuttuu ruotsinkielinen käännös. Nimi näytetään tulosteella suomen kielellä'
              : 'Tuotteelta puuttuu englanninkielinen käännös. Nimi näytetään tulosteella suomen kielellä'
          }
          placement={'top'}
        >
          <WarningRounded fontSize="small" color={'warning'} />
        </DefaultTooltip>
      </Box>
      {catClassInnerRenderer &&
        catClassInnerRenderer(row, filteredRows)}
    </Box>
  );
};

export default CatClassRenderer;
