import React, { useContext } from 'react';
import {
  AgGridColumnView,
  SurplusEquipmentRowKeys,
} from '../../../../../shared/types';
import {
  ImportType,
  ImportTableColumn,
  ImportChangesDataMapping,
} from '../../../../../shared/types/import';
import RowsProvider from '../../../providers/Rows/RowsProvider';
import { ExcelImportDialogCommonProps } from './ExcelImportDialog';
import { HeaderMapperTable } from './ExcelImportHeaderMapper';
import { getUpdatedRows } from './helpers';
import ExcelImportDialogBase from './ExcelImportDialogBase';

const ExcelImportSurplusEquipmentDialog = ({
  onClose,
  open,
}: ExcelImportDialogCommonProps) => {
  const { pricingSheetRows, dispatchPricingSheetRows } =
    useContext(RowsProvider);

  const importType = ImportType.SurplusEquipment;

  const tableColumns: ImportTableColumn<`surplusEquipmentItem.${SurplusEquipmentRowKeys}`>[] =
    [
      {
        key: 'surplusEquipmentItem.changePercentage',
        name: 'Muutosprosentti',
      },
      { key: 'surplusEquipmentItem.surplusFrom', name: 'Alkaa' },
      { key: 'surplusEquipmentItem.surplusTo', name: 'Päättyy' },
    ];

  const handleSave = (
    changesDataMapping: ImportChangesDataMapping,
  ) => {
    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: getUpdatedRows(pricingSheetRows, changesDataMapping),
    });
    onClose();
  };

  return (
    <ExcelImportDialogBase<AgGridColumnView.SURPLUS_EQUIPMENT>
      changesTableColumns={tableColumns}
      importType={importType}
      onClose={onClose}
      onSave={handleSave}
      open={open}
      rowData={pricingSheetRows}
      title={'Tuo ylitarjonta kaluston tiedot Excelistä'}
      renderHeaderMapper={(disabled) => (
        <HeaderMapperTable
          columns={tableColumns}
          disabled={disabled}
          importType={importType}
        />
      )}
    />
  );
};

export default ExcelImportSurplusEquipmentDialog;
