import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Button from '@mui/material/Button';
import {
  AgGridColumnView,
  IndustryName,
} from '../../../../../shared/types';
import {
  DAY_PRICE_COEFFICIENTS,
  DIALOG_TRANSITION_DURATION,
  EXPORT_COLUMNS,
  EXPORT_ITEMS_QUERY_KEY,
  MONTH_PRICE_COEFFICIENTS,
  PRICE_PERCENTAGE_COEFFICIENT_KEYS,
} from '../../../../../shared/constants';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import {
  CircularProgress,
  DialogContentText,
  FormControlLabel,
} from '@mui/material';
import { assertObjectKey } from '../../../../../shared/utils/assert';
import { useQuery } from '@tanstack/react-query';
import { exportItems } from '../../../services/export';
import {
  AdminColumnColumnKeys,
  AdminColumnFields,
  DayProposalColumns,
  ExportColumnMapping,
  MonthProposalColumns,
} from '../../../../../shared/types/export';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import IndustrySelector from '../../../components/Steps/Components/Selectors/IndustrySelector';

type ExcelExportDialogProps = {
  activeIndustry: IndustryName;
  gridColumnView: AgGridColumnView;
  open: boolean;
  onClose: () => void;
};

const getProposalColumnKeyLabelPair = <Key extends string>(
  key: Key,
  name: string,
) => {
  // capitalize first letter
  const keyCapitalized = key.charAt(0).toUpperCase() + key.slice(1);
  const formattedKey = `proposal${keyCapitalized.replace(
    'Coefficient',
    '',
  )}`;
  return {
    [formattedKey]: name,
  };
};

const columns: ExportColumnMapping = {
  [AgGridColumnView.ADMIN_COMMON]: {
    name: 'Nimi',
    rentalReadyPrice: 'Rental Ready',
    targetUtilRate: 'Tavoitekäyttöaste',
    depreciationPeriod: 'Poistoaika',
    includeInPricing: 'Hinnoitellaan',
    markedAsRemoved: 'Poistunut',
    avgRentalPeriod: 'Keskimääräinen vuokra-aika',
  },
  [AgGridColumnView.ADMIN_HIERARCHY]: {
    productGroup: 'MIRA - Tuoteryhmä',
    productLine: 'MIRA - Tuotelinja',
    category: 'MIRA - Kategoria',
    pimProductGroup: 'PIM - Tuoteryhmä',
    pimProductLine: 'PIM - Tuotelinja',
    pimCategory: 'PIM - Kategoria',
  },
  [AgGridColumnView.CRITICAL_EQUIPMENT]: {
    'criticalEquipmentItems.from': 'Alkaa',
    'criticalEquipmentItems.to': 'Päättyy',
    'criticalEquipmentItems.minPriceDay': 'Minimihinta pv',
    'criticalEquipmentItems.minPriceMonth': 'Minimihinta kk',
  },
  [AgGridColumnView.SURPLUS_EQUIPMENT]: {
    'surplusEquipmentItem.surplusFrom': 'Alkaa',
    'surplusEquipmentItem.surplusTo': 'Päättyy',
    'surplusEquipmentItem.changePercentage': 'Muutosprosentti',
  },
  [AgGridColumnView.ADMIN_KIT_NAME_LIST]: {
    kitName: 'Nimi',
  },

  PROPOSAL_DAY: DAY_PRICE_COEFFICIENTS.reduce(
    (acc, { key, name }) => {
      return {
        ...acc,
        ...getProposalColumnKeyLabelPair(key, name),
      };
    },
    {} as DayProposalColumns,
  ),
  PROPOSAL_MONTH: MONTH_PRICE_COEFFICIENTS.reduce(
    (acc, { key, name }) => ({
      ...acc,
      ...getProposalColumnKeyLabelPair(key, name),
    }),
    {} as MonthProposalColumns,
  ),
  [AgGridColumnView.ADMIN_DAY]: DAY_PRICE_COEFFICIENTS.reduce(
    (acc, { key, name }) => ({
      ...acc,
      [key]: name,
    }),
    {},
  ),
  [AgGridColumnView.ADMIN_MONTH]: MONTH_PRICE_COEFFICIENTS.reduce(
    (acc, { key, name }) => ({
      ...acc,
      [key]: name,
    }),
    {},
  ),
  [AgGridColumnView.ADMIN_INDUSTRY_DAY]:
    DAY_PRICE_COEFFICIENTS.reduce(
      (acc, { industryPercentKey, name }) => ({
        ...acc,
        [`itemIndustryPercents.${industryPercentKey}`]: name,
      }),
      {},
    ),
  [AgGridColumnView.ADMIN_INDUSTRY_MONTH]:
    MONTH_PRICE_COEFFICIENTS.reduce(
      (acc, { industryPercentKey, name }) => ({
        ...acc,
        [`itemIndustryPercents.${industryPercentKey}`]: name,
      }),
      {},
    ),
};

const getInitialSelectedState = (
  activeGridColumn: AgGridColumnView,
) => {
  return Object.fromEntries(
    Object.entries(columns).flatMap(([gridColumn, values]) => {
      const selected =
        gridColumn === activeGridColumn ||
        (activeGridColumn === AgGridColumnView.ADMIN_BOTH &&
          (gridColumn === AgGridColumnView.ADMIN_DAY ||
            gridColumn === AgGridColumnView.ADMIN_MONTH));
      return Object.keys(values).map((key) => [key, selected]);
    }),
  ) as Record<keyof AdminColumnFields, boolean>;
};

const ExcelExportDialog = ({
  activeIndustry,
  gridColumnView,
  onClose,
  open,
}: ExcelExportDialogProps) => {
  const [fileName, setFileName] = useState('');
  const [industry, setIndustry] =
    useState<IndustryName>(activeIndustry);
  const [selected, setSelected] = useState<
    Record<keyof AdminColumnFields, boolean>
  >(getInitialSelectedState(gridColumnView));

  // update default selection when grid column view changes
  useEffect(() => {
    setSelected(getInitialSelectedState(gridColumnView));
  }, [gridColumnView, setSelected]);

  useEffect(() => {
    // reset file name when dialog is closed (after transition is complete)
    if (!open) {
      setTimeout(() => {
        setFileName('');
      }, DIALOG_TRANSITION_DURATION);
    }
    // use industry selected in parent by default
    if (open && activeIndustry) {
      setIndustry(activeIndustry);
    }
  }, [activeIndustry, open]);

  // a list of the selected columns
  const selectedColumns: Array<keyof AdminColumnFields> =
    useMemo(() => {
      return Object.entries(selected).flatMap(([key, selected]) =>
        selected ? (key as keyof AdminColumnFields) : [],
      );
    }, [selected]);

  // flag for whether any of the industryPercent fields are selected
  const hasIndustryPercentagesSelected = useMemo(() => {
    return selectedColumns.some((item) => {
      const key = item.slice(item.indexOf('.') + 1);
      return (
        PRICE_PERCENTAGE_COEFFICIENT_KEYS as readonly string[]
      ).includes(key);
    });
  }, [selectedColumns]);

  // download pdf query
  const { refetch: downloadXlsx, isFetching } = useQuery({
    queryKey: [...EXPORT_ITEMS_QUERY_KEY, selectedColumns, industry],
    queryFn: () =>
      exportItems({
        columns: selectedColumns,
        fileName,
        industry,
      }),
    enabled: false,
  });

  const handleSelectionChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = event.target;
    setSelected({ ...selected, [name]: checked });
  };

  const handleMultipleSelectionChange = (
    fields: Partial<AdminColumnFields>,
    checked: boolean,
  ) => {
    const updatedSelection = Object.keys(fields).reduce(
      (acc, field) => ({
        ...acc,
        [field]: checked,
      }),
      {},
    );
    setSelected((state) => ({ ...state, ...updatedSelection }));
  };

  const getColumnGroupCheckedState = (
    fields: Partial<AdminColumnFields>,
  ) => {
    const keys = Object.keys(fields) as Array<
      keyof AdminColumnFields
    >;
    const allChecked = keys.every((key) =>
      selectedColumns.includes(key),
    );
    const indeterminate =
      !allChecked &&
      keys.some((key) => selectedColumns.includes(key));
    return [allChecked, indeterminate];
  };

  return (
    <Dialog
      open={open}
      closeAfterTransition
      fullWidth
      maxWidth={'xl'}
      transitionDuration={DIALOG_TRANSITION_DURATION}
      onClose={onClose}
    >
      {/* TITLE */}
      <DialogTitle sx={{ pb: 0 }}>Lataa Excel</DialogTitle>
      <DialogContent sx={{ pt: 0 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            py: 1.5,
          }}
        >
          <DialogContentText
            sx={{
              mr: 'auto',
            }}
          >
            Valitse tiedot, jotka haluat viedä Exceliin.
          </DialogContentText>
          {/* INDUSTRY SELECTOR */}
          <IndustrySelector
            disabled={!hasIndustryPercentagesSelected}
            clearable={false}
            industry={industry}
            changeValuesEnabled
            onIndustryChange={setIndustry}
            width={200}
          />
          {/* FILE NAME */}
          <TextField
            label={'Tiedoston nimi'}
            size={'small'}
            value={fileName}
            sx={{
              ml: 2,
              width: 200,
            }}
            onChange={(e) => setFileName(e.target.value)}
          />
        </Box>
        <GridContainer>
          {Object.entries(columns).map((item) => {
            const [key, fields] = item;
            assertObjectKey<
              AdminColumnColumnKeys,
              typeof EXPORT_COLUMNS
            >(key, EXPORT_COLUMNS);
            const groupLabel = EXPORT_COLUMNS[key];
            const [allChecked, indeterminate] =
              getColumnGroupCheckedState(fields);

            return (
              <FormControl
                key={key}
                sx={{
                  '.MuiFormGroup-root': {
                    alignItems: 'start',
                  },
                }}
                component={'fieldset'}
                variant={'standard'}
              >
                <FormControlLabel
                  label={groupLabel}
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: '0.875rem',
                      fontWeight: 'bold',
                    },
                    '.MuiCheckbox-root': {
                      p: 0.5,
                      mx: 0.5,
                    },
                  }}
                  control={
                    <Checkbox
                      checked={allChecked}
                      indeterminate={indeterminate}
                      onChange={() =>
                        handleMultipleSelectionChange(
                          fields,
                          !allChecked || indeterminate,
                        )
                      }
                    />
                  }
                />
                <FormGroup>
                  {Object.entries(fields).map((field) => {
                    const [key, label] = field;
                    assertObjectKey<
                      keyof AdminColumnFields,
                      typeof selected
                    >(key, selected);
                    return (
                      <FormControlLabel
                        key={key}
                        label={label}
                        sx={{
                          '.MuiFormControlLabel-label': {
                            fontSize: '0.875rem',
                          },
                          '.MuiCheckbox-root': {
                            p: 0.5,
                            mx: 0.5,
                          },
                        }}
                        control={
                          <Checkbox
                            checked={selected[key]}
                            onChange={handleSelectionChange}
                            name={key}
                          />
                        }
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            );
          })}
        </GridContainer>
      </DialogContent>
      <DialogActions
        sx={{
          px: 3,
          pt: 0,
          pb: 2,
        }}
      >
        <Button onClick={onClose}>Peruuta</Button>
        <Button
          variant={'contained'}
          onClick={() => downloadXlsx().then(() => onClose())}
          disabled={isFetching}
          endIcon={
            isFetching && <CircularProgress size={16} thickness={4} />
          }
        >
          Lataa
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const GridContainer = styled.div`
  // allow grid columns to fill available space and wrap, but allow at most 5 columns per row
  --grid-layout-gap: 1rem;
  --grid-column-count: 5;
  --grid-item--min-width: 12.5rem;
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );
  display: grid;
  grid-gap: calc(var(--grid-layout-gap) * 1.5) var(--grid-layout-gap);
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      max(var(--grid-item--min-width), var(--grid-item--max-width)),
      1fr
    )
  );
`;

export default ExcelExportDialog;
