import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getOfferSheet } from '../services/offerSheets';
import { DatabaseOfferWithItems } from '../../../shared/types/offers';
import { OFFER_SHEET_QUERY_KEY } from '../../../shared/constants';
import { AxiosError } from 'axios';

type UseOfferSheetParams = {
  id: string;
  options?: {
    readOnly?: boolean;
  } & Omit<
    UseQueryOptions<
      DatabaseOfferWithItems,
      AxiosError,
      DatabaseOfferWithItems,
      string[]
    >,
    'queryKey' | 'queryFn'
  >;
};

export const useOfferSheet = ({
  id,
  options,
}: UseOfferSheetParams) => {
  const { readOnly = false, ...rest } = options || {};
  return useQuery({
    queryKey: [OFFER_SHEET_QUERY_KEY, id],
    queryFn: () => getOfferSheet({ id, readOnly }),
    staleTime: Infinity,
    retry: false,
    ...rest,
  });
};
