import {
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  CircularProgress,
  Alert,
  Typography,
} from '@mui/material';
import { useApi, LoadingState } from '../../hooks/useApi';
import { formatDateTime } from '../../utils/formatDateTimes';
import { AuditEventType } from '../../../../shared/types';
import { useState } from 'react';
import AutomaticRunDialog from './AutomaticRunDialog';
import {
  AutomaticRunHierarchyDifference,
  AutomaticRunLogItemCreate,
  AutomaticRunLogItemUpdate,
  AutomaticRunLogRemovedItem,
  AutomaticRunLogReturnedItem,
  AutomaticRunMissingHierarchy,
} from '../../../../shared/types/automaticRun';

type AuditLogProps = {
  newState: {
    hierarchyDifference: AutomaticRunHierarchyDifference[];
    missingHierarchies: AutomaticRunMissingHierarchy[];
    removedItems: AutomaticRunLogRemovedItem[];
    returnedItems: AutomaticRunLogReturnedItem[];
    itemUpdates: AutomaticRunLogItemUpdate[];
    newItems: AutomaticRunLogItemCreate[];
  };
};

export type AuditLogEntry = {
  id: number;
  eventType: AuditEventType;
  eventDateTime: Date;
  userEmail: string;
  props?: AuditLogProps;
};

export enum DialogType {
  NEW,
  CHANGES,
  MISSING_HIERACHY,
  DIFFERENCES,
  REMOVAL,
  RETURNED,
}

const getEventTypeLabel = (eventType: AuditEventType) => {
  switch (eventType) {
    case 'automaticPimUpdater':
      return 'Tuotteiden päivitys';
    case 'automaticItemRemoval':
      return 'Tuotteiden poisto';
    default:
      return 'Automaattiajon tyyppi puuttuu!';
  }
};

const AutomaticRuns = () => {
  const [auditLogs, isLoading, error] = useApi<AuditLogEntry[]>(
    '/auditlog/logs?filterByType=automatic',
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [automaticRunId, setAutomaticRunId] = useState<number>();
  const [dialogType, setDialogType] = useState<DialogType>();

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = (id: number, dialogType: DialogType) => {
    setAutomaticRunId(id);
    setDialogOpen(true);
    setDialogType(dialogType);
  };

  const formatCellLabel = (label: string, data: any[]) =>
    `${label}: ${data.length}`;

  return (
    <>
      {error && (
        <Alert severity="error">Virhe ladattaessa muutoslokia</Alert>
      )}
      {isLoading === LoadingState.IsLoading && <CircularProgress />}
      {!error && (
        <>
          <Alert severity="info">
            Näytetään viimeiset 1000 tapahtumaa.
          </Alert>
          <TableContainer>
            <Table
              style={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Tyyppi</TableCell>
                  <TableCell>Aikaleima</TableCell>
                  <TableCell>Muutokset</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auditLogs?.map((row, i) => (
                  <TableRow key={row.eventType + i}>
                    <TableCell>
                      {getEventTypeLabel(row.eventType)}
                    </TableCell>
                    <TableCell component="th">
                      {formatDateTime(row.eventDateTime)}
                    </TableCell>
                    {row.eventType === 'automaticPimUpdater' && (
                      <TableCell>
                        <Typography
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleOpenDialog(row.id, DialogType.NEW);
                          }}
                        >
                          {formatCellLabel(
                            'Uusia tuotteita',
                            row?.props?.newState?.newItems ?? [],
                          )}
                        </Typography>
                        <Typography
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleOpenDialog(
                              row.id,
                              DialogType.CHANGES,
                            );
                          }}
                        >
                          {formatCellLabel(
                            'Muuttuneita tuotteita',
                            row?.props?.newState?.itemUpdates ?? [],
                          )}
                        </Typography>
                        <Typography
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleOpenDialog(
                              row.id,
                              DialogType.MISSING_HIERACHY,
                            );
                          }}
                        >
                          {formatCellLabel(
                            'Puuttuva hierarkia',

                            row?.props?.newState
                              ?.missingHierarchies ?? [],
                          )}
                        </Typography>
                        <Typography
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleOpenDialog(
                              row.id,
                              DialogType.DIFFERENCES,
                            );
                          }}
                        >
                          {formatCellLabel(
                            'Eroavaisuudet',

                            row?.props?.newState
                              ?.hierarchyDifference ?? [],
                          )}
                        </Typography>
                      </TableCell>
                    )}
                    {row.eventType === 'automaticItemRemoval' && (
                      <TableCell>
                        <Typography
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleOpenDialog(
                              row.id,
                              DialogType.REMOVAL,
                            );
                          }}
                        >
                          {formatCellLabel(
                            'Poistetut tuotteet',
                            row?.props?.newState?.removedItems ?? [],
                          )}
                        </Typography>
                        <Typography
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleOpenDialog(
                              row.id,
                              DialogType.RETURNED,
                            );
                          }}
                        >
                          {formatCellLabel(
                            'Palautetut tuotteet',
                            row?.props?.newState?.returnedItems ?? [],
                          )}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <AutomaticRunDialog
            dialogType={dialogType}
            handleClose={handleCloseDialog}
            open={dialogOpen}
            auditLogs={auditLogs}
            automaticRunId={automaticRunId}
          />
        </>
      )}
    </>
  );
};

export default AutomaticRuns;
