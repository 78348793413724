import validateDatePickerValue from './validateDatePickerValue';
import { CellDatePickerValue } from '../components/Cells/common/CellDatePicker';
import {
  CriticalEquipmentItem,
  CriticalEquipmentRowState,
} from '../../../shared/types';
import { CRITICAL_EQUIPMENT_ROW_KEYS } from '../../../shared/constants';

export const validateCriticalEquipmentItem = (
  state: Partial<
    Pick<CriticalEquipmentItem, 'id' | 'itemId'> &
      CriticalEquipmentRowState
  >,
) => {
  // ensure that all fields are included
  const stateKeys = Object.keys(state);
  const hasAllFields = CRITICAL_EQUIPMENT_ROW_KEYS.every((key) =>
    stateKeys.includes(key),
  );
  if (!hasAllFields) {
    return false;
  }
  // validate each field
  return Object.entries(state).every(([key, value]) => {
    if (key === 'minPriceDay' || key === 'minPriceMonth') {
      return Boolean(value && Number(value) > 0);
    }
    if (key === 'from') {
      return (
        validateDatePickerValue({
          maxDate: state.to,
          value: value as CellDatePickerValue,
        }) === null
      );
    }
    if (key === 'to') {
      return (
        validateDatePickerValue({
          minDate: state.from,
          value: value as CellDatePickerValue,
        }) === null
      );
    }
    return true;
  });
};
