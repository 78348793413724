import { Box, Typography } from '@mui/material';

interface BackgroundInfoInputLabelProps {
  label: string;
}

const BackgroundInfoInputLabel: React.FC<
  BackgroundInfoInputLabelProps
> = ({ label }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '3rem',
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
        flexDirection: 'column',
      }}
    >
      <Typography sx={{ mb: 1, mt: 1 }} variant="h6" color="primary">
        {label}
      </Typography>
    </Box>
  );
};

export default BackgroundInfoInputLabel;
