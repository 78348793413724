import { Nullable } from "../types";

export const isEditingRights = (
  editingRights: Nullable<string>,
  userEmail: Nullable<string>,
) => {
  if (!editingRights || editingRights.length === 0 || !userEmail) {
    return false;
  }
  return editingRights.split(",").includes(userEmail);
};
