import { TablePaginationOptions } from '../../../shared/types';

type HasMorePagesFnProps = TablePaginationOptions & {
  count: number;
};

export const hasMorePages = ({
  count,
  limit,
  offset,
}: HasMorePagesFnProps) => count > limit * (offset + 1);
