import { memo } from 'react';
import { PricingSheetRow } from '../../../../../shared/types';
import coefficientValueGetter from '../valueGetters/coefficientValueGetter';
import CoefficientRenderer from '../Renderers/CoefficientRenderer';

import NumericEditorV2 from '../Editors/NumericEditorV2';

import { GridColumnProps } from '../Columns/ColumnsBase';
import { PRICE_COEFFICIENTS } from '../../../../../shared/constants';
import { getCatClassColDef } from '../helpers/getCatClassColDef';

interface OptionalColumnProps {
  handleUpdateRowValue: (row: PricingSheetRow) => void;
}

const AdminColumnsBoth = ({
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps<OptionalColumnProps>) => {
  const { handleUpdateRowValue } = optionalColumnProps;

  const priceCoefficientsColumns = PRICE_COEFFICIENTS.map(
    ({ key, name }) => ({
      field: key,
      headerName: name,
      filter: 'agNumberColumnFilter',
      editable: true,
      valueGetter: (params: { data: PricingSheetRow }) =>
        coefficientValueGetter(params, key),
      cellRenderer: memo(CoefficientRenderer),
      cellRendererParams: {
        coefficient: key,
      },
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: () => {
        if (key === 'monthPriceCoefficientList') {
          return {
            color: '#000',
            borderLeft: '1px solid #ccc',
            borderRight: '1px solid #ccc',
          };
        }
        return { color: '#000', borderLeft: '1px solid #ccc' };
      },
    }),
  );

  const groupingColumns: any = getCatClassColDef(gridGroupingValues);

  const finalColumns = groupingColumns.concat(
    priceCoefficientsColumns,
  );

  return finalColumns;
};

export default AdminColumnsBoth;
