import React from 'react';

import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/icons-material/Undo';

import {
  ItemIndustryPercent,
  PricingSheetRow,
} from '../../../../shared/types';

interface UndoButtonProps {
  disabled: boolean;
  undo: () =>
    | {
        rows: PricingSheetRow[];
        itemIndustryPercents: ItemIndustryPercent[];
      }
    | undefined;
  saveRowsToHistory: (
    rows: PricingSheetRow[],
    itemIndustryPercents: ItemIndustryPercent[],
  ) => void;
}

const UndoButton: React.FC<UndoButtonProps> = ({
  disabled,
  undo,
  saveRowsToHistory,
}) => {
  const onUndoClick = () => {
    const returnedRows = undo();
    if (returnedRows) {
      saveRowsToHistory(
        returnedRows.rows,
        returnedRows.itemIndustryPercents,
      );
    }
  };

  return (
    <>
      <IconButton
        aria-label="undo"
        disabled={disabled}
        color="primary"
        onClick={onUndoClick}
      >
        <UndoIcon />
      </IconButton>
    </>
  );
};

export default UndoButton;
