import { calculateRemainingDays } from "./calculateRemainingDays";
import { DAYS_IN_MONTH } from "./constants";
import { PricingSheetRow, RoundingBasis } from "./types";
import { countDaysInDateRange } from "./countDaysInDateRange";
import { isMonthPricing } from "./helpers/isMonthPricing";
import { getPriceRounded } from "./getPriceRounded";

const calculateTotal = (
  price: number,
  quantity: number,
  isMonth: boolean,
  fullMonths: number,
  remainingDays: number,
  days: number,
) => {
  return isMonth
    ? +(
        (price * fullMonths + (price * remainingDays) / DAYS_IN_MONTH) *
        quantity
      ).toFixed(2)
    : +(price * quantity * days).toFixed(2);
};

export const calculateTotalPrice = <
  Data extends Pick<
    PricingSheetRow,
    | "dayPrice"
    | "monthPrice"
    | "leasePeriodStart"
    | "leasePeriodEnd"
    | "pricingBasis"
    | "quantity"
  >,
>(
  data: Data | undefined,
  roundingBasis: RoundingBasis,
): number => {
  const { leasePeriodStart, leasePeriodEnd, pricingBasis, quantity } =
    data || {};
  const isMonth = isMonthPricing(pricingBasis);
  let days = 1;
  let fullMonths = 0;
  let remainingDays = 0;

  if (leasePeriodStart && leasePeriodEnd) {
    const range = {
      start: new Date(leasePeriodStart),
      end: new Date(leasePeriodEnd),
    };

    ({ fullMonths, remainingDays } = calculateRemainingDays(
      range.start,
      range.end,
    ));

    days = isMonth
      ? remainingDays
      : countDaysInDateRange(range.start, range.end);
  }

  const price = isMonth ? data?.monthPrice : data?.dayPrice;
  const roundedPrice = Number(getPriceRounded(price, roundingBasis));

  if (roundedPrice && quantity) {
    return calculateTotal(
      roundedPrice,
      quantity,
      isMonth,
      fullMonths,
      remainingDays,
      days,
    );
  } else {
    return 0;
  }
};
