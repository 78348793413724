import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import {
  ApprovalRequestState,
  TableColumn,
  TableSortOrder,
} from '../../../../../shared/types';
import { OFFER_APPROVAL_QUERY_KEY } from '../../../../../shared/constants';
import {
  DatabaseOfferApprovalRequestWithOffer,
  GetOfferApprovalRequestsTableFilterOptions,
} from '../../../../../shared/types/offers';
import { getOfferApprovalRequests } from '../../../services/offerSheets';
import Box from '@mui/material/Box';
import GenericCheckBoxFilter from '../../../components/Filters/GenericCheckBoxFilter';
import { AlertColor, alpha, Typography } from '@mui/material';
import { formatDateTime } from '../../../utils/formatDateTimes';
import Button from '@mui/material/Button';
import { RemoveRedEye } from '@mui/icons-material';
import {
  DataTable,
  DataTableProps,
} from '../../../components/Common/DataTable/DataTable';
import { getApprovalRequestStateLabel } from '../../../../../shared/helpers/getApprovalRequestStateLabel';
import { useHistory } from 'react-router-dom';
import useUserContext from '../../../providers/User/UserProvider';
import { StateAlert } from '../../../components/Common/StateAlert';
import { DataTableRowActionsContainer } from '../../../components/Common/DataTable/DataTableRowActions';
import { getUsersAllowedToInspect } from '../../OfferSheets/helpers';
import { useDataTableFiltering } from '../../../hooks/useDataTableFiltering';
import { DataTableRowProps } from '../../../components/Common/DataTable/DataTableRow';
import DefaultTooltip from '../../../components/Tooltips/DefaultTooltip';

const TABLE_COLUMNS: TableColumn<DatabaseOfferApprovalRequestWithOffer>[] =
  [
    {
      key: 'offerSheetId',
      label: 'Tarjousnro',
      width: 95,
    },
    {
      key: 'createdAt',
      label: 'Luotu',
    },
    {
      key: 'offerSheet.name',
      label: 'Tarjouksen nimi',
    },
    {
      key: 'offerSheet.customerName',
      label: 'Asiakas',
    },
    {
      key: 'offerSheet.worksite',
      label: 'Työmaa',
    },
    {
      key: 'creatorEmail',
      label: 'Pyytäjä',
    },
    {
      key: 'approverEmail',
      label: 'Hyväksyntää pyydetty',
    },
    {
      key: 'state',
      label: 'Tila',
      width: 220,
    },
  ];

export const OfferApproval = () => {
  const {
    query,
    filterOptions,
    paginationOptions,
    sortOptions,
    setFilterOptions,
    setPaginationOptions,
    setSortOptions,
  } = useDataTableFiltering<
    DatabaseOfferApprovalRequestWithOffer,
    GetOfferApprovalRequestsTableFilterOptions
  >({
    queryOptions: {
      queryKey: [OFFER_APPROVAL_QUERY_KEY],
      queryFn: getOfferApprovalRequests,
    },
    initFilterOptions: {
      byUser: false,
    },
    initSortOptions: {
      direction: TableSortOrder.Desc,
      orderBy: 'createdAt',
    },
  });

  const { data: { count = 0, items = [] } = {}, isFetching } = query;

  return (
    <>
      <Box sx={{ my: 1 }}>
        <GenericCheckBoxFilter
          disabled={isFetching}
          labelStyle={{ marginLeft: 0 }}
          label={'Näytä minulta hyväksyntää vaativat'}
          checked={filterOptions.byUser || false}
          handleChecked={(checked) =>
            setFilterOptions((state) => ({
              ...state,
              byUser: checked,
            }))
          }
        />
      </Box>
      {/* OFFER APPROVALS TABLE */}
      <DataTable
        count={count}
        filterOptions={filterOptions}
        labelNoResults={'Hyväksymispyyntöjä ei löytynyt!'}
        labelRowsPerPage={'Hyväksymispyyntöjä per sivu'}
        loading={isFetching}
        items={items}
        paginationOptions={paginationOptions}
        renderCellComponent={OfferApprovalTableCellComponent}
        renderColumnFilter={OfferApprovalTableColumnFilter}
        renderRowActions={OfferApprovalTableRowActions}
        renderRowDetails={OfferApprovalTableRowDetails}
        setFilterOptions={setFilterOptions}
        setPaginationOptions={setPaginationOptions}
        setSortOptions={setSortOptions}
        sortOptions={sortOptions}
        tableColumns={TABLE_COLUMNS}
      />
    </>
  );
};

type OfferApprovalTableCellComponentFnParams = (
  renderProps: Parameters<
    DataTableRowProps<DatabaseOfferApprovalRequestWithOffer>['renderCellComponent']
  >[0],
  stateAlertSize?: 'small' | 'normal',
) => ReturnType<
  DataTableRowProps<DatabaseOfferApprovalRequestWithOffer>['renderCellComponent']
>;
const OfferApprovalTableCellComponent: OfferApprovalTableCellComponentFnParams =
  ({ item, column }, stateAlertSize) => {
    const { lockedReason, state, updatedAt } = item;
    const { key } = column;
    switch (key) {
      case 'createdAt':
        return (
          <Typography whiteSpace={'nowrap'} fontSize={'inherit'}>
            {formatDateTime(item[key])}
          </Typography>
        );
      case 'state': {
        const label = getApprovalRequestStateLabel(state);
        let severity: AlertColor = 'info';
        if (state === ApprovalRequestState.Approved) {
          severity = 'success';
        }
        if (state === ApprovalRequestState.Locked) {
          severity = 'warning';
        }
        if (state === ApprovalRequestState.Discarded) {
          severity = 'error';
        }
        const infoTooltipText =
          lockedReason === 'removed'
            ? 'Tarjous on poistettu'
            : lockedReason === 'archived'
              ? 'Tarjous on arkistoitu'
              : null;
        return (
          <StateAlert
            size={stateAlertSize}
            severity={severity}
            label={label}
            infoTooltipText={infoTooltipText}
            subLabel={
              state !== ApprovalRequestState.Waiting
                ? formatDateTime(updatedAt)
                : undefined
            }
          />
        );
      }
      case 'creatorEmail':
      case 'approverEmail':
      case 'offerSheet.name':
      case 'offerSheet.customerName':
      case 'offerSheet.worksite': {
        const width = key.startsWith('offerSheet')
          ? 'max(8.5vw, 105px)'
          : 'max(10vw, 120px)';
        return (
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width,
            }}
          >
            <DefaultTooltip
              placement={'top'}
              enterDelay={400}
              enterNextDelay={400}
              title={item[key]}
            >
              <span>{item[key]}</span>
            </DefaultTooltip>
          </Box>
        );
      }
      default:
        return <span>{item[key]}</span>;
    }
  };

const OfferApprovalTableRowActions: DataTableProps<DatabaseOfferApprovalRequestWithOffer>['renderRowActions'] =
  ({ item, infoToggle }) => {
    const queryClient = useQueryClient();
    const { userEmail } = useUserContext();
    const history = useHistory();
    const { id, offerSheetId, previousRequests = [], state } = item;
    const inspectDisabled =
      !getUsersAllowedToInspect(item).includes(userEmail || '') ||
      state !== ApprovalRequestState.Waiting;
    const hasPreviousRequests = previousRequests.length > 0;
    return (
      <DataTableRowActionsContainer
        sx={{
          justifyContent: 'start',
          px: 1,
        }}
      >
        <Button
          startIcon={<RemoveRedEye />}
          size={'small'}
          variant={'contained'}
          onClick={() => {
            // create cache for offerApproval/:id query
            queryClient.setQueryData(
              [OFFER_APPROVAL_QUERY_KEY, `${id}`],
              item,
            );
            history.push(`/tarjous/${offerSheetId}/tarkastele/${id}`);
          }}
          disabled={inspectDisabled}
        >
          Tarkastele
        </Button>
        {hasPreviousRequests && infoToggle}
      </DataTableRowActionsContainer>
    );
  };

const OfferApprovalTableColumnFilter: DataTableProps<DatabaseOfferApprovalRequestWithOffer>['renderColumnFilter'] =
  ({
    key,
    filterComponentProps,
    InputFilter,
    SelectFilter,
    DateFilter,
  }) => {
    switch (key) {
      // NUMBER INPUT
      case 'offerSheetId':
        return (
          <InputFilter
            {...filterComponentProps}
            inputProps={{ type: 'number', min: '1' }}
          />
        );
      // TEXT INPUT
      case 'creatorEmail':
      case 'approverEmail':
      case 'offerSheet.name':
      case 'offerSheet.customerName':
      case 'offerSheet.worksite':
        return <InputFilter {...filterComponentProps} />;
      // SELECT
      case 'state': {
        const selectFilterOptions = Object.values(
          ApprovalRequestState,
        ).map((state) => ({
          key: state,
          label: getApprovalRequestStateLabel(state),
        }));
        return (
          <SelectFilter
            {...filterComponentProps}
            options={selectFilterOptions}
          />
        );
      }
      // DATE
      case 'createdAt':
        return <DateFilter {...filterComponentProps} />;
    }
  };

const OfferApprovalTableRowDetails: DataTableProps<DatabaseOfferApprovalRequestWithOffer>['renderRowDetails'] =
  ({ previousRequests = [] }) => {
    // return null if the item doesn't have any previous requests
    if (previousRequests.length === 0) {
      return null;
    }
    const detailColumns: Array<
      keyof DatabaseOfferApprovalRequestWithOffer
    > = ['createdAt', 'creatorEmail', 'approverEmail', 'state'];
    const columns = TABLE_COLUMNS.filter(({ key }) =>
      detailColumns.includes(key),
    );
    return (
      <Box
        sx={{
          backgroundColor: alpha('#f2f2f2', 0.25),
          opacity: 0.9,
          p: 1,
          pb: 2,
        }}
      >
        {/* TITLE */}
        <Typography fontSize={14} fontWeight={'bold'} mb={1}>
          Tarjouksen aikaisemmat hyväksyntäpyynnöt
        </Typography>
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            columnGap: 2,
            fontSize: 12,
            rowGap: 0.5,
            gridTemplateColumns: `repeat(${columns.length}, minmax(auto, 200px))`,
          }}
        >
          {/* LABELS */}
          {columns.map((column) => {
            return (
              <Typography
                key={column.key}
                fontSize={12}
                fontWeight={'bold'}
              >
                {column.label}
              </Typography>
            );
          })}
          {/* VALUES */}
          {previousRequests.map((approvalRequest) => {
            return columns.map((column) => (
              <React.Fragment key={column.key}>
                {OfferApprovalTableCellComponent(
                  {
                    item: approvalRequest as DatabaseOfferApprovalRequestWithOffer,
                    column,
                  },
                  'small',
                )}
              </React.Fragment>
            ));
          })}
        </Box>
      </Box>
    );
  };
