import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useContext } from 'react';

import RowsProvider from '../../../providers/Rows/RowsProvider';
import { PricingSheetRow } from '../../../../../shared/types';
import { clone } from '../../../utils/genericUtils';

interface HierarchyRendererProps {
  items: any[] | undefined; // TODO
  value: string | undefined;
  sheetRow: PricingSheetRow | undefined;
  hierarchyLevel: 'group' | 'line' | 'category';
}

const HierarchyRenderer: React.FC<HierarchyRendererProps> = ({
  items,
  value,
  sheetRow,
  hierarchyLevel,
}) => {
  const { updateRow } = useContext(RowsProvider);

  const onChange = (event: SelectChangeEvent<string>) => {
    if (sheetRow) {
      let newRow = clone(sheetRow);
      const [name, path] = event.target.value.split('|');
      newRow.pimPath = path;
      if (hierarchyLevel === 'group') {
        newRow = {
          ...newRow,
          pimProductGroup: name,
          pimProductLine: undefined,
          pimCategory: undefined,
        };
      } else if (hierarchyLevel === 'line') {
        newRow = {
          ...newRow,
          pimProductLine: name,
          pimCategory: undefined,
        };
      } else if (hierarchyLevel === 'category') {
        newRow = { ...newRow, pimCategory: name };
      } else {
        return;
      }
      updateRow(newRow);
    }
  };

  return (
    <FormControl fullWidth variant="standard">
      <Select
        style={{ height: 30 }}
        defaultValue={value}
        size="small"
        onChange={onChange}
        disabled={items === undefined || items.length === 0}
      >
        {items?.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value + '|' + item.path}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default HierarchyRenderer;
