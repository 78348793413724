import { ColDef } from 'ag-grid-community';
import { PricingSheetRow } from '../../../../../shared/types';

export const getCatClassColDef = (
  gridGroupingValues: string[],
): ColDef<PricingSheetRow>[] => [
  {
    field: 'productGroup',
    rowGroup: gridGroupingValues.includes('productGroup'),
    hide: true,
  },
  {
    field: 'productLine',
    rowGroup: gridGroupingValues.includes('productLine'),
    hide: true,
  },
  {
    field: 'category',
    rowGroup: gridGroupingValues.includes('category'),
    hide: true,
  },
  {
    field: 'pimProductGroup',
    rowGroup: gridGroupingValues.includes('pimProductGroup'),
    hide: true,
  },
  {
    field: 'pimProductLine',
    rowGroup: gridGroupingValues.includes('pimProductLine'),
    hide: true,
  },
  {
    field: 'pimCategory',
    rowGroup: gridGroupingValues.includes('pimCategory'),
    hide: true,
  },
];
