import { findPercentsForIndustry } from "./industries";
import {
  Coefficients,
  IndustryName,
  ItemIndustryPercent,
  PricingSheetRow,
} from "./types";

export const getIndustryPercents = (
  pricingSheetRow: PricingSheetRow,
  industry: IndustryName,
  coefficients: Coefficients,
) => {
  const percentsForSelectedIndustry = findPercentsForIndustry(
    pricingSheetRow.itemIndustryPercents,
    industry,
  );

  const dayKey = coefficients?.dayPricePercent as keyof ItemIndustryPercent;
  const monthKey = coefficients?.monthPricePercent as keyof ItemIndustryPercent;

  const dayPercent = percentsForSelectedIndustry
    ? Number(percentsForSelectedIndustry[dayKey])
    : null;
  const monthPercent = percentsForSelectedIndustry
    ? Number(percentsForSelectedIndustry[monthKey])
    : null;

  const changeCoefficientDay = dayPercent ? (dayPercent / 100 + 100) / 100 : 1;
  const changeCoefficientMonth = monthPercent
    ? (monthPercent / 100 + 100) / 100
    : 1;

  return { changeCoefficientDay, changeCoefficientMonth };
};
