import {
  IndustryName,
  ItemIndustryPercent,
  PricingSheetRow,
} from '../../../../../shared/types';
import { ValueGetterParams } from 'ag-grid-community';

const coefficientPercentValueGetter = (
  params: ValueGetterParams<PricingSheetRow>,
  items: PricingSheetRow[],
  key: string,
  industry: IndustryName,
  itemIndustryPercents: ItemIndustryPercent[],
) => {
  const row = params.data;

  if (!row) return 0;

  const percentItem = itemIndustryPercents?.find(
    (percentItem: {
      catClass: string;
      industry: { name: IndustryName };
    }) =>
      percentItem.industry.name === industry &&
      percentItem.catClass === row?.catClass,
  );

  const percent = percentItem
    ? Number(percentItem[key as keyof ItemIndustryPercent])
    : null;

  return typeof percent === 'number' && !isNaN(percent)
    ? percent / 100
    : 0;
};

export default coefficientPercentValueGetter;
