import { createContext, useState } from 'react';
import { useApi } from '../../hooks/useApi';

import {
  PreSelectionType,
  PricingSheet,
  SelectSheetOption,
} from '../../../../shared/types';

const Pricing = createContext<any | null>(null);

const PricingProvider = (props: any) => {
  const [showMachineCard, setShowMachineCard] = useState(true);
  const [itemFilterValue, setItemFilterValue] = useState<
    string | undefined
  >('');
  const [pricingSheetId, setPricingSheetId] = useState<
    string | undefined
  >(undefined);

  const [selectedPreSelection, setSelectedPreSelection] = useState<
    PreSelectionType | undefined
  >();

  const [externalPricingSheetId, setExternalPricingSheetId] =
    useState<number | undefined>();

  const [comparisonPricingSheets] = useApi<
    PricingSheet[] | [] | undefined
  >('/pricingsheets');

  const [comparisonSheetName, setComparisonSheetName] = useState('');

  const [
    externalSheetSelectedOption,
    setExternalSheetSelectedOption,
  ] = useState<SelectSheetOption | undefined>(undefined);

  const clearItemFilterValue = () => {
    setItemFilterValue('');
  };

  const children = props.children;

  return (
    <Pricing.Provider
      value={{
        itemFilterValue,
        setItemFilterValue,
        clearItemFilterValue,
        pricingSheetId,
        setPricingSheetId,
        comparisonPricingSheets,
        externalPricingSheetId,
        setExternalPricingSheetId,
        showMachineCard,
        setShowMachineCard,
        selectedPreSelection,
        setSelectedPreSelection,
        comparisonSheetName,
        setComparisonSheetName,
        externalSheetSelectedOption,
        setExternalSheetSelectedOption,
      }}
    >
      {children}
    </Pricing.Provider>
  );
};

export default Pricing;

export { PricingProvider };
