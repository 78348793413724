import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useOfferSheet } from '../../hooks/useOfferSheet';
import { useHistory, useParams } from 'react-router-dom';
import {
  DatabaseOfferWithItems,
  OfferRouterParams,
} from '../../../../shared/types/offers';
import LoadingRenderer from '../../components/Common/LoadingRenderer';
import { convertDatabaseOfferDatesToDate } from '../../helpers/convertDatabaseOfferDates';
import { useFilters } from '../../hooks/useFilters';
import PricingFilters from '../../components/PricingTable/PricingFilters';
import {
  AgGridColumnView,
  ApprovalRequestState,
  SheetType,
  TableType,
} from '../../../../shared/types';
import AgGrid from '../../components/PricingTable/AgGrid';
import { useItems } from '../../hooks/useItems';
import { ROUNDING_BASIS } from './helpers';
import { useColumns } from '../../hooks/useColumns';
import styled from '@emotion/styled';
import { Check, DoNotDisturb } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OFFER_SHEET_QUERY_KEY } from '../../../../shared/constants';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import { SheetComment } from '../../components/Common/SheetComment';
import useUserContext from '../../providers/User/UserProvider';
import { resolveOfferApprovalRequest } from '../../services/offerSheets';
import { formatDateTolocaleDateString } from '../../utils/formatDateTimes';
import chunk from 'lodash/chunk';
import { updateOfferApprovalRequestCache } from '../../hooks/useUpdateOfferApprovalRequest';
import { calculateItemPrice } from '../../../../shared/helpers/calculateItemPrice';

export const InspectOffer = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { id, approvalId } = useParams<OfferRouterParams>();
  const { isAdmin, userRoles } = useUserContext();
  const { setNotification } = useContext(NotificationProvider);
  const readOnly = !approvalId;

  const [comment, setComment] = useState<string | undefined>('');

  // fetch offer sheet
  const {
    data: offerSheet,
    error: offerSheetError,
    isLoading: isLoadingOffer,
  } = useOfferSheet({ id, options: { readOnly } });
  // get offer sheet item data
  const { data: offerSheetItems = [], isFetching: isLoadingItems } =
    useItems({
      includeHidden: true,
      offerSheetItemsOnly: true,
      offerSheet,
    });
  // resolve offer approval request mutation
  const {
    mutate: resolveApprovalRequest,
    isPending: isUpdatingRequest,
  } = useMutation({
    mutationFn: resolveOfferApprovalRequest,
    onSuccess: (data, variables) => {
      // update offer approval request query cache
      updateOfferApprovalRequestCache(queryClient, data, variables);
      // refetch updated offer sheet
      queryClient.refetchQueries({
        queryKey: [OFFER_SHEET_QUERY_KEY, id],
      });
    },
  });

  const {
    activeFilters,
    addFilter,
    toggleActiveFilter,
    isChecked,
    clearFilters,
    applyActiveFilters,
  } = useFilters();

  const { columnDefs, gridColumnView } = useColumns(
    AgGridColumnView.OFFER_INSPECT,
    {
      roundingBasisSheet: ROUNDING_BASIS,
      hideInspectComment: readOnly,
    },
  );

  // redirect to the listing page if user is not allowed to view the offer request or if the offer sheet has been removed
  useEffect(() => {
    // we have to wait until user roles are available, otherwise the isAdmin value could be wrong
    if (userRoles) {
      const path = isAdmin
        ? `/admin/1/${SheetType.OFFER}`
        : '/tarjoukset';
      // offer sheet has been removed
      if (offerSheet?.removedAt) {
        history.replace(path);
      }
      const offerSheetErrorStatus = offerSheetError?.response?.status;
      if (offerSheetErrorStatus === 403) {
        history.replace(path);
        setNotification({
          type: 'SNACKBAR',
          duration: 4000,
          severity: 'error',
          message:
            'Käyttäjätunnuksellasi ei ole oikeuksia tarkastella tarjousta!',
        });
      }
    }
  }, [
    history,
    isAdmin,
    offerSheet?.removedAt,
    offerSheetError,
    setNotification,
    userRoles,
  ]);

  const handleApproverAction = (
    action:
      | ApprovalRequestState.Approved
      | ApprovalRequestState.Discarded,
  ) => {
    if (!approvalId) {
      console.error(
        `Unable to resolve offer approval request: No ID was passed.`,
      );
      return;
    }
    resolveApprovalRequest(
      {
        id,
        approvalId,
        approvalApproverComment: comment || null,
        action,
      },
      {
        onSuccess: () => {
          // show notification
          const message =
            action === ApprovalRequestState.Approved
              ? 'Hinnasto hyväksytty!'
              : 'Hinnasto palautettu tekijälle!';
          setNotification({
            type: 'SNACKBAR',
            duration: 4000,
            severity: 'success',
            message,
          });
          // redirect to approval request listing
          history.replace(`/admin/1/${SheetType.OFFER}`);
        },
        onError: (error) => {
          console.error(error);
          const message =
            action === ApprovalRequestState.Approved
              ? 'Hinnaston hyväksyminen epäonnistui!'
              : 'Hinnaston palautus epäonnistui!';
          setNotification({
            type: 'SNACKBAR',
            duration: 4000,
            severity: 'error',
            message,
          });
        },
      },
    );
  };

  // NO DATA
  if (!(offerSheet && offerSheetItems.length > 0)) {
    return (
      <BackgroundContainer>
        {isLoadingItems || isLoadingOffer ? (
          <LoadingRenderer
            label={'Ladataan tarjousta'}
            sx={{ my: 2 }}
          />
        ) : (
          <Typography color={'primary'} variant={'h5'}>
            Tarjousta ei löytynyt!
          </Typography>
        )}
      </BackgroundContainer>
    );
  }

  const { approvalCreatorComment, pricingBasis } = offerSheet;

  return (
    <BackgroundContainer maxWidth={'xl'}>
      <Button
        variant={'outlined'}
        onClick={() =>
          history.push(readOnly ? '/tarjoukset' : '/admin/1/offer')
        }
        sx={{ mb: 2 }}
      >
        Takaisin
      </Button>
      <Typography
        variant={'h1'}
        color={'primary'}
        fontSize={28}
        mb={2}
        mr={'auto'}
      >
        {readOnly ? 'Tarjouksen tarkastelu' : 'Tarjouksen hyväksyntä'}
      </Typography>
      <OfferDetails offerSheet={offerSheet} />
      {!readOnly && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <SheetComment
            title={'Tarjouksen tekijän perustelut:'}
            comment={approvalCreatorComment}
          />
          {/* COMMENT */}
          <TextField
            multiline
            required
            rows={2}
            label={'Palaute'}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            sx={{
              flex: 1,
              height: '100%',
            }}
          >
            Tarjouksen hyväksyntä
          </TextField>
          {/* ACTION BUTTONS */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: 200,
            }}
          >
            <Button
              fullWidth
              variant={'contained'}
              startIcon={<Check />}
              disabled={isUpdatingRequest}
              onClick={() =>
                handleApproverAction(ApprovalRequestState.Approved)
              }
            >
              Hyväksy tarjous
            </Button>
            <Tooltip
              title={
                'Palaute on pakollinen tieto, jos tarjous palautetaan myyjälle.'
              }
            >
              <span>
                <Button
                  fullWidth
                  variant={'outlined'}
                  color={'error'}
                  disabled={!comment || isUpdatingRequest}
                  startIcon={<DoNotDisturb />}
                  onClick={() =>
                    handleApproverAction(
                      ApprovalRequestState.Discarded,
                    )
                  }
                >
                  Palauta myyjälle
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
      )}
      {/* FILTERS */}
      <PricingFilters
        addFilter={addFilter}
        pricingBasis={pricingBasis}
        toggleActiveFilter={toggleActiveFilter}
        isChecked={isChecked}
        showZeroPriceFilter
        clearFilters={clearFilters}
        showCriticalPriceFilter
        showDividers={false}
      />
      {/* GRID */}
      <AgGrid
        autoHeight
        fullWidthProductCell
        hideSideBar
        isSelectable={false}
        gridColumnView={gridColumnView}
        columnDefs={columnDefs}
        type={TableType.InspectTable}
        rows={offerSheetItems}
        loading={false}
        externalFilters={activeFilters}
        applyActiveFilters={applyActiveFilters}
      />
    </BackgroundContainer>
  );
};

const OfferDetails = ({
  offerSheet,
}: {
  offerSheet?: DatabaseOfferWithItems;
}) => {
  if (!offerSheet) return null;
  const {
    name,
    customerName,
    worksite,
    offerLeasePeriodStart,
    offerLeasePeriodEnd,
    offerValidityStart,
    offerValidityEnd,
    totalCoefficient,
    totalPrice,
    totalRentalPrice,
    totalSalesPrice,
  } = convertDatabaseOfferDatesToDate(offerSheet);

  const columns = {
    'Tarjouksen nimi': name,
    Asiakas: customerName,
    Työmaa: worksite,
    Voimassaoloaika: `${formatDateTolocaleDateString(
      offerValidityStart,
    )} - ${formatDateTolocaleDateString(offerValidityEnd)}`,
    'Vuokra-aika': `${formatDateTolocaleDateString(
      offerLeasePeriodStart,
    )} - ${formatDateTolocaleDateString(offerLeasePeriodEnd)}`,
    'Vuokratuotteiden hintakerroin':
      calculateItemPrice(totalCoefficient) ?? null,
    'Tarjouksen kokonaishinta': `${calculateItemPrice(totalPrice)} €`,
    'Vuokratuotteiden kokonaishinta': `${calculateItemPrice(totalRentalPrice)} €`,
    'Myyntituotteiden kokonaishinta': `${calculateItemPrice(totalSalesPrice)} €`,
  };
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
        gridAutoFlow: 'column',
        gridTemplateColumns: 'repeat(2, fit-content(50%))',
        mb: 2,
      }}
    >
      {chunk(Object.entries(columns), 3).map((items, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {items.map(
            ([key, value]) =>
              value &&
              value !== 0 &&
              value !== '0 €' && (
                <Box
                  key={key}
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <Typography fontWeight={'bold'}>{key}:</Typography>
                  <Typography>{value}</Typography>
                </Box>
              ),
          )}
        </Box>
      ))}
    </Box>
  );
};

const BackgroundContainer = styled(Container)`
  --spacing: 2rem;
  background-color: #fff;
  border-radius: 0.5rem;
  margin: var(--spacing) auto;
  max-width: 1920px;
  padding: var(--spacing);
  width: calc(100% - var(--spacing) * 2);
`;
