import { UseDataTableQueryKey } from '../hooks/useDataTableFiltering';
import { TableFilterOptions } from '../../../shared/types';

export const convertDataTableFiltersToSearchParams = <
  T,
  F extends TableFilterOptions,
>(
  queryKey: UseDataTableQueryKey<T, F>,
): URLSearchParams => {
  const params = {
    ...queryKey[1],
    ...queryKey[2],
    filter: queryKey[3],
  };
  const filteredParams = Object.entries(params)
    // filter out falsy values
    .filter(([key, value]) => {
      if (key === 'filter') {
        return (
          Object.keys(value).length > 0 &&
          Object.values(value).some(Boolean)
        );
      }
      return Boolean(value);
    })
    // convert value to string
    .map(([key, value]) => {
      if (key === 'filter') {
        const stringifiedFilter = Object.entries(value)
          .map(([filterKey, filterValue]) => {
            // handle 'includeStates' filter
            if (
              filterKey === 'includeStates' &&
              Array.isArray(filterValue)
            ) {
              return `${filterKey}=${filterValue.join('|')}`;
            }
            return `${filterKey}=${filterValue}`;
          })
          .join(',');
        return [key, stringifiedFilter];
      }
      return [key, String(value)];
    });
  return new URLSearchParams(filteredParams);
};
