import { useState } from 'react';
import { PricingSheetRow } from '../../../shared/types';
import { clone } from '../utils/genericUtils';

type SelectedRows<T extends PricingSheetRow> = Record<string, T>;

const setInitialRows = <T extends PricingSheetRow>(
  pricingSheetRows: T[],
) => {
  return pricingSheetRows.reduce((prev, cur) => {
    if (!cur.preSelected) {
      return prev;
    }
    prev[cur.catClass] = cur;
    return prev;
  }, {} as SelectedRows<T>);
};

export const useSelectedRows = <T extends PricingSheetRow>(
  pricingSheetRows: T[],
) => {
  const [selectedRows, setSelectedGridRows] = useState<
    SelectedRows<T>
  >(setInitialRows(pricingSheetRows));

  let current: SelectedRows<T> = clone(selectedRows);

  const addSelectedRow = (row: T) => {
    current[row.catClass] = row;
    setSelectedGridRows(current);
  };

  const removeSelectedRow = (row: T) => {
    delete current[row.catClass];
    setSelectedGridRows(current);
  };

  const isChecked = (row: T) => {
    return !!selectedRows[row.catClass];
  };

  const toggleRow = (row: T) => {
    if (isChecked(row)) {
      removeSelectedRow(row);
    } else {
      addSelectedRow(row);
    }
  };

  const getSelectedRows = () => {
    return Object.values(selectedRows);
  };

  return {
    addSelectedRow,
    removeSelectedRow,
    getSelectedRows,
    toggleRow,
    selectedRows,
    setSelectedGridRows,
  } as const;
};
