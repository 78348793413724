import {
  Input,
  InputProps,
  IconButton,
  IconButtonProps,
  InputAdornment,
  MenuItem,
  TableRow,
  TableCell,
  TextField,
} from '@mui/material';
import { FilterAlt, Start, Clear } from '@mui/icons-material';
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { StickyTableCell } from './DataTable';
import {
  TableColumn,
  TableDateFilterOperators,
  TableFilterOptions,
} from '../../../../../shared/types';
import { produce } from 'immer';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ActionMenu, ActionMenuItem } from '../ActionMenu';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';

export type DataTableColumnFilterProps<T> = {
  filterOptions: TableFilterOptions<T>;
  renderColumnFilter: (props: {
    key: keyof T;
    filterComponentProps: FilterComponentProps<T>;
    InputFilter: typeof InputFilter;
    SelectFilter: typeof SelectFilter;
    DateFilter: typeof DateFilter;
  }) => ReactNode;
  setFilterOptions: (updatedFilters: TableFilterOptions<T>) => void;
  showActionCell?: boolean;
  tableColumns: TableColumn<T>[];
};

type FilterComponentProps<T> = {
  active: boolean;
  filterField: keyof T;
  filterValues: TableFilterOptions<T>;
  handleClear: (key: keyof T) => void;
  handleValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setFilterOptions: DataTableColumnFilterProps<T>['setFilterOptions'];
};

type SelectFilterComponentProps<T> = FilterComponentProps<T> & {
  options: {
    key: string;
    label: string;
  }[];
};

export const DataTableColumnFilter = <T,>({
  filterOptions,
  renderColumnFilter = () => null,
  showActionCell = true,
  setFilterOptions,
  tableColumns = [],
}: DataTableColumnFilterProps<T>) => {
  const [filterValues, setFilterValues] =
    useState<TableFilterOptions<T>>(filterOptions);

  // update filter values if they are updated by the parent
  useEffect(() => {
    setFilterValues(filterOptions);
  }, [filterOptions]);

  const activeFilters = Object.keys(filterValues);

  const handleValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const key = name as keyof T;
      setFilterValues(
        // @ts-ignore
        produce((draft) => {
          !value ? delete draft[key] : (draft[key] = value);
        }),
      );
    },
    [],
  );

  const handleClear = useCallback(
    (key: keyof T) => {
      setFilterOptions(
        // @ts-ignore
        produce((draft) => {
          delete draft[key];
        }),
      );
    },
    [setFilterOptions],
  );

  return (
    <TableRow>
      {tableColumns.map(({ key }) => {
        const filterComponentProps = {
          active: activeFilters.includes(String(key)),
          handleClear,
          handleValueChange,
          filterValues,
          filterField: key,
          setFilterOptions,
        };
        return (
          <TableCell
            key={String(key)}
            padding={'none'}
            sx={{
              '&:not(:nth-last-of-type(2))': {
                borderRight: '1px solid #e0e0e0',
              },
            }}
          >
            {renderColumnFilter({
              key,
              filterComponentProps,
              InputFilter,
              SelectFilter,
              DateFilter,
            })}
          </TableCell>
        );
      })}
      {/* EMPTY CELL FOR ACTIONS COLUMN */}
      {showActionCell && <StickyTableCell border />}
    </TableRow>
  );
};

export const InputFilter = <T,>({
  active,
  filterField,
  filterValues,
  handleClear,
  handleValueChange,
  setFilterOptions,
  ...inputProps
}: FilterComponentProps<T> & InputProps) => (
  <Input
    {...inputProps}
    autoComplete={'off'}
    name={String(filterField)}
    size={'small'}
    endAdornment={
      <FilterAction
        active={active}
        onClick={() => handleClear(filterField)}
      />
    }
    sx={{
      height: '40px',
      px: 1,
      py: 0.5,
      width: '100%',
      '.MuiInput-input': {
        fontSize: '0.875rem',
      },
    }}
    onChange={handleValueChange}
    onBlur={() => setFilterOptions(filterValues)}
    onKeyDown={(event) => {
      const { name: changedKey, value } =
        event.target as HTMLInputElement;
      // clear filter
      if (event.key === 'Backspace' && !value) {
        event.stopPropagation();
        handleClear(changedKey as keyof T);
      }
      // apply filter
      if (event.key === 'Enter') {
        setFilterOptions(filterValues);
      }
    }}
    value={filterValues[filterField] || ''}
  />
);

export const SelectFilter = <T,>({
  active,
  filterField,
  filterValues,
  handleClear,
  options,
  setFilterOptions,
}: SelectFilterComponentProps<T>) => (
  <TextField
    select
    fullWidth
    value={filterValues[filterField] || ''}
    name={String(filterField)}
    variant={'standard'}
    SelectProps={{
      // hide default icon
      IconComponent: () => null,
    }}
    InputProps={{
      endAdornment: (
        <FilterAction
          active={active}
          onClick={() => handleClear(filterField)}
        />
      ),
    }}
    sx={{
      '.MuiInput-root': {
        fontSize: '0.875rem',
        height: '40px',
        pl: 0.5,
        pr: 1,
      },
      '.MuiSelect-select': {
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'inline-flex',
        height: '100%',
        '&.MuiInput-input.MuiInput-input': {
          minWidth: 90,
          px: 1,
        },
        '&:focus': {
          backgroundColor: 'inherit',
        },
      },
      '.MuiInputAdornment-root': {
        ml: 0,
      },
    }}
    onChange={(e) =>
      setFilterOptions(
        // @ts-ignore
        produce((draft) => {
          const value = e.target.value;
          const key = e.target.name as keyof T;
          draft[key] = value;
        }),
      )
    }
  >
    {options.map(({ key, label }) => (
      <MenuItem
        key={key}
        value={key}
        selected={filterValues[filterField] === key}
        sx={{
          '&:hover': {
            backgroundColor: 'primary',
          },
        }}
      >
        {label}
      </MenuItem>
    ))}
  </TextField>
);

export const DateFilter = <T,>({
  active,
  filterField,
  filterValues,
  handleClear,
  setFilterOptions,
}: FilterComponentProps<T>) => {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [operator, setOperator] =
    useState<TableDateFilterOperators>('gte');
  const value = (filterValues[filterField] as string | null) ?? null;
  // extract date from existing filter value, as it's in the form of 'date|operator'
  const date = value?.split('|')[0];

  const handleOperatorChange = useCallback(
    (operator: TableDateFilterOperators, closeMenu: () => void) => {
      setOperator(operator);
      if (active) {
        // update operator for the active date filter
        setFilterOptions(
          // @ts-ignore
          produce((draft) => {
            const currentValue = draft[filterField] as string;
            const currentDateValue = currentValue.split('|')[0];
            draft[filterField] =
              `${currentDateValue}|${operator}` ?? null;
          }),
        );
      }
      closeMenu();
    },
    [active, filterField, setFilterOptions],
  );

  return (
    <DesktopDatePicker
      open={calendarOpen}
      value={date ? new Date(date) : null}
      onClose={() => setCalendarOpen(false)}
      onChange={(date) =>
        setFilterOptions(
          // @ts-ignore
          produce((draft) => {
            if (!date) {
              delete draft[filterField];
            }
            // append operator to selected date, so that the backend can fetch data based on it
            draft[filterField] =
              `${date!.toISOString()}|${operator}` ?? null;
          }),
        )
      }
      inputFormat="dd.MM.yyyy"
      mask="__.__.____"
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          inputProps={{
            ...params.inputProps,
            placeholder: undefined,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position={'end'}
                sx={{
                  display: 'flex',
                }}
              >
                {/* clear button */}
                {active && (
                  <ClearButton
                    onClick={() => handleClear(filterField)}
                  />
                )}
                {/* calendar toggle*/}
                <IconButton
                  size={'small'}
                  onClick={() => setCalendarOpen(true)}
                >
                  <Calendar fontSize={'small'} />
                </IconButton>
                {/* filter operator menu */}
                <ActionMenu
                  toggleIcon={<FilterIcon active={active} />}
                  // todo: to reduce repetition, use mapping function if the number of operators increases
                  renderItems={(closeMenu) => [
                    <ActionMenuItem
                      key={'gte'}
                      onClick={() =>
                        handleOperatorChange('gte', closeMenu)
                      }
                      icon={Start}
                      label={'Alkaen'}
                      selected={operator === 'gte'}
                    />,
                    <ActionMenuItem
                      key={'lte'}
                      onClick={() =>
                        handleOperatorChange('lte', closeMenu)
                      }
                      icon={Start}
                      iconProps={{
                        sx: {
                          transform: 'rotate(180deg)',
                        },
                      }}
                      label={'Päättyen'}
                      selected={operator === 'lte'}
                    />,
                  ]}
                />
              </InputAdornment>
            ),
          }}
          placeholder={''}
          variant={'standard'}
          sx={{
            cursor: 'default',
            minWidth: 140,
            '.MuiInput-root': {
              cursor: 'default',
              fontSize: '0.875rem',
              height: '40px',
              justifyContent: 'space-between',
              pl: 1,
              pr: 0.5,
            },
            '.MuiInput-input': {
              width: 75,
            },
            '.MuiInputAdornment-root': {
              ml: 0,
            },
            '.MuiIconButton-root': {
              p: 0.5,
            },
          }}
        />
      )}
    />
  );
};

type FilterActionProps = {
  active: boolean;
  onClick: () => void;
};

const FilterAction = ({
  active = false,
  onClick = () => null,
}: FilterActionProps) => {
  return (
    <InputAdornment position={'end'}>
      {active && <ClearButton onClick={onClick} />}
      <FilterIcon active={active} />
    </InputAdornment>
  );
};

const ClearButton = (props: IconButtonProps) => (
  <IconButton size={'small'} color={'error'} {...props}>
    <Clear fontSize={'small'} />
  </IconButton>
);

const FilterIcon = ({
  active,
}: Pick<FilterActionProps, 'active'>) => (
  <FilterAlt
    color={active ? 'primary' : 'disabled'}
    sx={{
      fontSize: '1rem',
    }}
  />
);
