import { roundToTwoDecimal } from "./roundToTwoDecimal";

export const countRemovalMonthPrice = (
  rentalReadyPrice: number,
  depreciationPeriod: number,
  targetUtilRate: number,
) =>
  rentalReadyPrice && depreciationPeriod && targetUtilRate
    ? roundToTwoDecimal(
        Number(rentalReadyPrice) /
          Number(depreciationPeriod) /
          Number(targetUtilRate),
      )
    : null;
