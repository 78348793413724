import { DataTableColumnFilterProps } from '../../../components/Common/DataTable/DataTableColumnFilter';
import {
  DatabaseOfferWithApprovalRequest,
  OffersTableTabState,
  OfferState,
} from '../../../../../shared/types/offers';
import { getOfferStateLabel } from '../helpers';
import { RenderComponentProps } from './OfferSheetsTable';

type OffersTableColumnFilterProps = RenderComponentProps<
  DataTableColumnFilterProps<DatabaseOfferWithApprovalRequest>['renderColumnFilter']
>;

export const OffersTableColumnFilter: OffersTableColumnFilterProps = (
  {
    key,
    filterComponentProps,
    InputFilter,
    SelectFilter,
    DateFilter,
  },
  { activeTab },
) => {
  switch (key) {
    // NUMBER INPUT
    case 'id':
      return (
        <InputFilter
          {...filterComponentProps}
          inputProps={{ type: 'number', min: '1' }}
        />
      );
    case 'totalPrice':
    case 'totalCoefficient':
      return (
        <InputFilter
          {...filterComponentProps}
          inputProps={{ type: 'number' }}
        />
      );
    // TEXT INPUT
    case 'customerName':
    case 'name':
    case 'userEmail':
    case 'worksite':
      return <InputFilter {...filterComponentProps} />;
    // SELECT
    case 'state': {
      const getOptions = (array: OfferState[]) =>
        array.map((state) => ({
          key: state,
          label: getOfferStateLabel(state),
        }));
      let selectFilterOptions = getOptions([
        OfferState.Draft,
        OfferState.InternalApproval,
        OfferState.InternalRejection,
        OfferState.ReadyForSending,
      ]);
      if (activeTab === OffersTableTabState.Sent) {
        selectFilterOptions = getOptions([
          OfferState.Sent,
          OfferState.Approved,
          OfferState.Rejected,
        ]);
      }
      if (activeTab === OffersTableTabState.Archived) {
        selectFilterOptions = getOptions(Object.values(OfferState));
      }
      return (
        <SelectFilter
          {...filterComponentProps}
          options={selectFilterOptions}
        />
      );
    }
    // DATE
    case 'createdAt':
    case 'offerValidityEnd':
      return <DateFilter {...filterComponentProps} />;
  }
};
