import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Dialog,
} from '@mui/material';
import { useContext } from 'react';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import { removePricingSheetById } from '../../../services/pricingSheets';
import { PricingSheet } from '../../../../../shared/types';

const RemoveDialog = ({
  pricingSheet,
  updateSheets,
  isOpenRemoveDialog,
  handleCloseRemoveDialog,
}: {
  pricingSheet: PricingSheet;
  updateSheets: () => Promise<void>;
  isOpenRemoveDialog: boolean;
  handleCloseRemoveDialog: () => void;
}) => {
  const { setNotification } = useContext(NotificationProvider);
  const removeSheet = async (id: string) => {
    try {
      await removePricingSheetById(id);
      await updateSheets();
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: `Hinnasto ${pricingSheet.name} poistettu onnistuneesti`,
      });
    } catch (error) {
      setNotification({
        type: 'SNACKBAR',
        duration: 6000,
        severity: 'error',
        message: `Hinnastoa ${pricingSheet.name} poistaessa tapahtui virhe!`,
      });
    }
  };
  return (
    <Dialog
      disableScrollLock
      open={isOpenRemoveDialog}
      onClose={handleCloseRemoveDialog}
    >
      <DialogTitle>{'Haluatko poistaa hinnaston?'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography color="text.primary">
            Olet poistamassa hinnastoa {pricingSheet.name}
            <br />
            Haluatko jatkaa?
            <br />
            <br />
            <b>
              Poistettua hinnastoa ei ole enää mahdollista palauttaa!
            </b>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          m: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button color="inherit" onClick={handleCloseRemoveDialog}>
          Peruuta
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            removeSheet(pricingSheet.id);
            handleCloseRemoveDialog();
          }}
          autoFocus
        >
          Poista
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
