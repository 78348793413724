import React from 'react';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import { ControlledSelector } from '../../InitialInformationStep';

import useUserContext from '../../../../providers/User/UserProvider';

const PriceSheetTypeSelector: React.FC<
  ControlledSelector<string>
> = ({ value, onChange }) => {
  const { isAdmin } = useUserContext();

  const adminOptions = [
    {
      name: 'Vuosisopimus',
      description: 'kiinnitetään asiakkaan taakse',
    },
    {
      name: 'Erillinen sopimus',
      description:
        'toimii ainoastaan vaihtamalla se myyntisopimuksen otsikolle',
    },
    {
      name: 'Projekti',
      description:
        'toimii ainoastaan projektihierarkiassa projektin taakse kytkettynä',
    },
    {
      name: 'Jäsenhinnasto',
      description: '',
    },
    {
      name: 'Referenssi',
      description: '',
    },
  ];

  const basicOptions = [
    {
      name: 'Vuosisopimus',
      description: 'kiinnitetään asiakkaan taakse',
    },
    {
      name: 'Erillinen sopimus',
      description:
        'toimii ainoastaan vaihtamalla se myyntisopimuksen otsikolle',
    },
    {
      name: 'Projekti',
      description:
        'toimii ainoastaan projektihierarkiassa projektin taakse kytkettynä',
    },
  ];

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="revenue"
        name="radio-buttons-group"
        value={value ?? null}
        onChange={(_, value) => {
          onChange(value);
        }}
      >
        {(isAdmin ? adminOptions : basicOptions).map(
          (agreementType, i) => {
            return (
              <FormControlLabel
                key={agreementType.name}
                value={agreementType.name}
                control={<Radio />}
                label={
                  <Box paddingBottom={1} paddingTop={1}>
                    <Typography color="primary">
                      {agreementType.name}
                    </Typography>
                    <Typography color="text.primary">
                      {agreementType.description}
                    </Typography>
                  </Box>
                }
              />
            );
          },
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default PriceSheetTypeSelector;
