import { ItemType, OfferItem } from "../types";

export const hasRamiturva = (item: OfferItem) => {
  const { type, includeRamiturva } = item;
  if (type === ItemType.Sales || type === ItemType.Service) {
    return false;
  }

  if (!includeRamiturva) {
    return false;
  }

  return true;
};
