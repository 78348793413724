import { Dispatch, SetStateAction, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { industries } from '../../../../shared/industries';
import {
  AgGridColumnView,
  IndustryName,
} from '../../../../shared/types';

export interface SelectIndustryProps {
  industry: string;
  setIndustry: Dispatch<SetStateAction<IndustryName>>;
  gridColumnView: AgGridColumnView;
  updateGridColumnView: (view: AgGridColumnView) => void;
}

const SelectIndustry = ({
  industry,
  setIndustry,
  gridColumnView,
  updateGridColumnView,
}: SelectIndustryProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    setIndustry(event.target.value as IndustryName);
    updateGridColumnView(gridColumnView);
  };

  // if we update gridColumnView inside change handler, columns get old industry state value
  useEffect(() => {
    updateGridColumnView(gridColumnView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industry]);

  return (
    <FormControl sx={{ minWidth: 180 }} size={'small'}>
      <InputLabel id="select-industry">Toimiala</InputLabel>
      <Select
        labelId="select-industry"
        id="select-industry"
        value={industry}
        label="Toimiala"
        onChange={(e) => handleChange(e)}
      >
        {[...industries].map((industry, idx) => {
          return (
            <MenuItem key={idx} value={industry}>
              {industry}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectIndustry;
