import React from 'react';

import { Divider } from '@mui/material';

import { SubtleText } from '../InitialInformationStep';

import CustomerSiteSelector from './Selectors/CustomerSiteSelector';

interface SiteProps {}
const Site: React.FC<SiteProps> = () => {
  return (
    <>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <SubtleText>Työmaa*</SubtleText>
      <CustomerSiteSelector />
    </>
  );
};

export default Site;
