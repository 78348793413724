import { calculatePrices } from "./calculatePrices";
import { TARGET_UTIL_OFFER_MARGIN } from "./constants";
import getCoefficientKeysByCategory from "./getCoefficientKeysByCategory";
import { getIndustryPercents } from "./getIndustyPercents";
import {
  Coefficients,
  DateRange,
  IndustryName,
  OfferItem,
  PriceCategory,
} from "./types";
import { countDaysInDateRange } from "./countDaysInDateRange";
import { countDaysInBothRanges } from "./countDaysInBothRanges";
import { getSurplusRange } from "./getSurplusRange";

const getFinalValue = (value: number | undefined) => {
  return value ? value / 100 : null;
};

export const calculateTargetUtilRate = (
  rentalRange: DateRange,
  avgRentalPeriod: number = 0,
  itemTargetUtilRate?: number,
  surplusRange?: DateRange,
) => {
  if (!itemTargetUtilRate) {
    return;
  }
  if (avgRentalPeriod === null) {
    avgRentalPeriod = 0;
  }

  let targetUtilResult;
  const targetUtilDecimal = Number(itemTargetUtilRate) / 100;
  // If target util rate is more than 70% then we need to increase it
  const multiplier = targetUtilDecimal > 0.7 ? 0.95 : 0.9;
  let targetUtilOfferCap = targetUtilDecimal + TARGET_UTIL_OFFER_MARGIN;
  let surplusDays = 0;
  if (targetUtilOfferCap > 1) {
    targetUtilOfferCap = 1;
  }

  const rentalDays = Math.floor(
    countDaysInDateRange(rentalRange.start, rentalRange.end),
  );

  surplusDays = surplusRange
    ? Math.max(
        Math.round(countDaysInBothRanges(rentalRange, surplusRange) - 7),
        0,
      )
    : 0;

  let discountDays =
    avgRentalPeriod === 0
      ? 0
      : rentalDays - avgRentalPeriod <= 0
        ? 0
        : rentalDays - avgRentalPeriod;

  discountDays =
    discountDays + surplusDays <= 0 ? 0 : discountDays + surplusDays;

  targetUtilResult =
    (multiplier * discountDays + targetUtilDecimal * (365 - discountDays)) /
    365;

  return targetUtilResult > targetUtilOfferCap
    ? targetUtilOfferCap
    : targetUtilResult;
};

export const countOfferPrice = (
  item: OfferItem,
  priceCategory: PriceCategory,
  priceType: "DAY" | "MONTH",
  rentalRange: DateRange,
  industry: IndustryName,
) => {
  const coefficientKeys = getCoefficientKeysByCategory(
    priceCategory,
  ) as Coefficients;

  const dayCoefficient = getFinalValue(item[coefficientKeys.dayCoefficient]);
  const monthCoefficient = getFinalValue(
    item[coefficientKeys.monthCoefficient],
  );

  const { changeCoefficientDay, changeCoefficientMonth } = getIndustryPercents(
    item,
    industry,
    coefficientKeys,
  );

  const finalDayCoefficient = dayCoefficient
    ? dayCoefficient * changeCoefficientDay
    : dayCoefficient;
  const finalMonthCoefficient = monthCoefficient
    ? monthCoefficient * changeCoefficientMonth
    : monthCoefficient;

  const surplusRange = getSurplusRange(item, rentalRange);

  const targetUtilRate = calculateTargetUtilRate(
    rentalRange,
    item.avgRentalPeriod,
    item.targetUtilRate,
    surplusRange,
  );
  const rentalReadyPrice = Number(item.rentalReadyPrice);
  const depreciationPeriod = Number(item.depreciationPeriod);

  if (
    !finalDayCoefficient ||
    !finalMonthCoefficient ||
    !rentalReadyPrice ||
    !rentalReadyPrice ||
    !depreciationPeriod ||
    !targetUtilRate
  ) {
    return 0;
  }

  const prices = calculatePrices(
    rentalReadyPrice,
    depreciationPeriod,
    targetUtilRate,
    finalDayCoefficient,
    finalMonthCoefficient,
  );
  return priceType === "DAY"
    ? prices.proposalDayPrice
    : prices.proposalMonthPrice;
};
