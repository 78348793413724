import {
  PricingSheetRow,
  RoundingBasis,
} from '../../../../../shared/types';

import { ValueGetterParams } from 'ag-grid-community';
import { getPriceRounded } from '../../../../../shared/getPriceRounded';

export const monthPriceGetter = (
  { data }: ValueGetterParams<PricingSheetRow>,
  roundingBasis: RoundingBasis,
) => {
  const monthPrice = data?.monthPrice;
  const showByOfferText = data?.showByOfferText;

  if (showByOfferText) {
    return showByOfferText
      ? 'Tarjouksen mukaan'
      : monthPrice
        ? monthPrice
        : null;
  }
  return getPriceRounded(monthPrice, roundingBasis);
};
