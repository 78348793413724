import { api } from '../index';

export const getApprovalRequestById = (id: string) => {
  const createApprovalRequestResponse = api
    .get('/approvalrequests/' + id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return createApprovalRequestResponse;
};

export const createApprovalRequest = (
  pricingSheetId: string,
  email: string,
  comment?: string,
  approvalReasons?: any,
) => {
  const createApprovalRequestResponse = api
    .post('/approvalrequests', {
      pricingSheetId: pricingSheetId,
      email: email,
      comment: comment,
      approvalReasons: approvalReasons,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return createApprovalRequestResponse;
};

export const updateApprovalRequest = (
  id: string,
  newValues: { state?: string; comment?: string },
) => {
  const updateApprovalRequestResponse = api
    .put(`/approvalrequests/${id}`, newValues)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });

  return updateApprovalRequestResponse;
};
