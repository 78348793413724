import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { updateOfferSheet } from '../services/offerSheets';
import { UpdateOfferResponseBody } from '../../../shared/types/offers';
import { OFFER_SHEET_QUERY_KEY } from '../../../shared/constants';
import { AxiosError } from 'axios';

type DataResponseType = UpdateOfferResponseBody;
type ErrorType = AxiosError;
type VariablesType = Parameters<typeof updateOfferSheet>[0];

type UseUpdateOfferParams = Omit<
  UseMutationOptions<DataResponseType, ErrorType, VariablesType>,
  'mutationFn'
>;

export const useUpdateOffer = (
  options: UseUpdateOfferParams = {},
) => {
  const queryClient = useQueryClient();
  return useMutation<DataResponseType, ErrorType, VariablesType>({
    ...options,
    mutationFn: updateOfferSheet,
    onSuccess: (data, variables, context) => {
      // update item to offer query cache
      queryClient.setQueryData(
        [OFFER_SHEET_QUERY_KEY, `${data.id}`],
        data,
      );
      options?.onSuccess?.(data, variables, context);
    },
  });
};
