import { useTheme } from '@mui/system';
import { ReactNode, useState } from 'react';
import { Collapse, TableCell, TableRow } from '@mui/material';
import {
  DataTableRowActions,
  DataTableRowActionsProps,
} from './DataTableRowActions';
import { TableColumn } from '../../../../../shared/types';

export type DataTableRowProps<T> = {
  detailsOpenByDefault?: boolean;
  item: T;
  renderCellComponent: (props: {
    column: TableColumn<T>;
    item: T;
  }) => ReactNode;
  renderRowActions?: DataTableRowActionsProps<T>['renderRowActions'];
  renderRowDetails?: (item: T) => ReactNode;
  tableColumns: TableColumn<T>[];
};
export const DataTableRow = <T extends { id: number }>({
  detailsOpenByDefault = false,
  item,
  renderCellComponent = () => null,
  renderRowActions,
  renderRowDetails,
  tableColumns = [],
}: DataTableRowProps<T>) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(detailsOpenByDefault);
  const colSpan = tableColumns.length + 1;

  return (
    <>
      <TableRow
        key={item.id}
        sx={{
          '&:hover > td': {
            backgroundColor: '#fbfbfb',
          },
        }}
      >
        {/* ROW FIELD VALUES */}
        {tableColumns.map((column) => (
          <TableCell
            key={String(column.key)}
            sx={{
              boxSizing: 'border-box',
              height: 40,
              px: 1,
              py: 0.5,
              [theme.breakpoints.down('xl')]: {
                fontSize: '0.75rem',
              },
            }}
          >
            {renderCellComponent({ column, item })}
          </TableCell>
        ))}
        {/* ACTIONS */}
        {renderRowActions && (
          <DataTableRowActions
            item={item}
            open={open}
            renderRowActions={renderRowActions}
            setOpen={setOpen}
          />
        )}
      </TableRow>
      {renderRowDetails && renderRowDetails(item) !== null && (
        <TableRow>
          <TableCell
            // show border only when collapsed
            sx={{ p: 0, ...(!open && { border: 0 }) }}
            colSpan={colSpan}
          >
            <Collapse in={open} timeout={'auto'} unmountOnExit>
              {renderRowDetails(item)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
