import currencyFormatter from '../formatters/priceFormatter';
import percentFormatter from '../formatters/percentFormatter';

import { getClassForAgGridCell } from '../../../helpers/cellHelpers';

import {
  AgGridColumnView,
  OfferItem,
  RoundingBasis,
} from '../../../../../shared/types';

import {
  CellClassParams,
  CellStyle,
  ICellEditorParams,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import proposalDayPriceGetter from '../valueGetters/proposalDayPriceGetter';
import { GridColumnProps } from './ColumnsBase';
import { ProposalPriceRenderer } from '../Renderers/ProposalPriceRenderer';
import { memo } from 'react';
import { OfferCommentRenderer } from '../Renderers/OfferCommentRenderer';
import catClassValueGetter from '../valueGetters/catClassValueGetter';
import proposalMonthPriceGetter from '../valueGetters/proposalMonthPriceGetter';
import { isMonthPricing } from '../../../pages/OfferSheets/helpers';
import { priceGetter } from '../valueGetters/priceGetter';
import PriceRenderer from '../Renderers/PriceRenderer';
import discountGetter from '../valueGetters/discountGetter';
import CatClassRenderer from '../Renderers/CatClassRenderer';
import { calculateTotalPrice } from '../../../../../shared/calculateTotalPrice';
import { formatDateTolocaleDateString } from '../../../utils/formatDateTimes';
import { isSalesItem } from '../../../../../shared/helpers/isSalesItem';
import { sortByItemType } from '../../../../../shared/sortByItemType';
import MultiLineHeader from '../Components/MultiLineHeader';
import { ramiturvaPriceGetter } from '../valueGetters/RamiturvaPricegetter';
import ramiturvaPriceFormatter from '../formatters/ramiturvaPriceFormatter';

interface OptionalColumnProps {
  hideInspectComment: boolean;
  roundingBasisSheet: RoundingBasis;
}

const defaultCellStyle: CellStyle = {
  borderLeft: '1px solid #ccc',
  color: '#000',
  display: 'flex',
};

export const OfferColumnsInspect = ({
  classes,
  optionalColumnProps,
}: GridColumnProps<OptionalColumnProps>) => {
  const { hideInspectComment } = optionalColumnProps;
  /*
   * for some reason right border does not apply if we pass it from cellClass
   * therefore we define borders here in cellStyles and only get colors from cellClass
   * */
  return [
    {
      field: 'catClass',
      headerName: 'Nimi',
      filter: 'agTextColumnFilter',
      valueGetter: catClassValueGetter,
      cellRenderer: (params: ICellRendererParams<OfferItem>) => (
        <CatClassRenderer
          showMachineCard={true}
          row={params.data}
          gridColumnView={AgGridColumnView.OFFER_PRICING}
        />
      ),
      sort: 'desc',
      comparator: (
        _: string,
        __: string,
        nodeA: IRowNode<OfferItem>,
        nodeB: IRowNode<OfferItem>,
      ) => sortByItemType(nodeA.data, nodeB.data),
      cellStyle: defaultCellStyle,
      minWidth: 460,
    },
    {
      field: 'rentalPeriod',
      headerName: 'Vuokra-aika',
      minWidth: 150,
      filter: false,
      sortable: false,
      valueGetter: ({ data }: ValueGetterParams<OfferItem>) => {
        const rentalPeriod = data
          ? `${formatDateTolocaleDateString(
              data.leasePeriodStart,
            )} - ${formatDateTolocaleDateString(data.leasePeriodEnd)}`
          : '';
        return !isSalesItem(data?.type) ? rentalPeriod : null;
      },
      cellStyle: {
        ...defaultCellStyle,
      },
    },
    {
      field: 'pricingBasis',
      headerName: 'Hinnoitteluperuste',
      filter: false,
      valueGetter: ({ data }: ValueGetterParams<OfferItem>) =>
        !isSalesItem(data?.type) ? data?.pricingBasis : null,
      valueFormatter: (params: ValueFormatterParams<OfferItem>) => {
        if (isSalesItem(params.data?.type)) return null;
        if (
          !params.data ||
          typeof params.data.pricingBasis === 'undefined'
        ) {
          return '';
        } else {
          return isMonthPricing(params)
            ? 'Kuukausihinta'
            : 'Päivähinta';
        }
      },
      headerTooltip: 'Onko tuotteella PV vai KK hinta',
      cellStyle: {
        ...defaultCellStyle,
        color: '#000',
      },
      maxWidth: 150,
    },
    {
      field: 'quantity',
      headerName: 'Lkm',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        return params.data?.quantity;
      },
      maxWidth: 80,
      headerTooltip: 'Tuotteiden lukumäärä',
      cellStyle: {
        ...defaultCellStyle,
        color: '#000',
      },
    },
    {
      field: 'proposalPrice',
      headerName: 'Ehdotushinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        if (isMonthPricing(params)) {
          return proposalMonthPriceGetter(params);
        } else {
          return proposalDayPriceGetter(params);
        }
      },
      valueFormatter: currencyFormatter,
      cellRenderer: ProposalPriceRenderer,
      headerTooltip: 'Työkalun ehdottama hinta',
      cellStyle: defaultCellStyle,
    },
    {
      field: 'price',
      headerName: 'Tarjouksen hinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) =>
        priceGetter(params, optionalColumnProps.roundingBasisSheet),
      valueFormatter: currencyFormatter,
      headerTooltip: 'Tuotteen hinta',
      cellClass: (params: CellClassParams) => {
        const priceType = isMonthPricing(params)
          ? 'monthPrice'
          : 'dayPrice';
        return getClassForAgGridCell(params.data, classes, priceType);
      },
      cellRenderer: PriceRenderer,
      cellRendererParams: (params: ICellEditorParams<OfferItem>) => {
        return {
          minPrice: isMonthPricing(params)
            ? params.data.minMonthPrice
            : params.data.minDayPrice,
        };
      },
      cellStyle: {
        ...defaultCellStyle,
        color: '#000',
      },
    },
    {
      field: 'totalPrice',
      headerName: 'Kokonaishinta',
      filter: 'agNumberColumnFilter',
      editable: false,
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        return (
          (priceGetter(
            params,
            optionalColumnProps.roundingBasisSheet,
          ) as number) * (params.data?.quantity ?? 1)
        );
      },
      valueFormatter: currencyFormatter,
      headerTooltip:
        'Tuotteen yhteenlaskettu hinta vuokra-aika huomioiden',
      cellStyle: defaultCellStyle,
    },
    {
      field: 'rentPeriodTotalPrice',
      headerName: 'Vuokra-ajan kokonaishinta',
      filter: 'agNumberColumnFilter',
      editable: false,
      headerComponent: MultiLineHeader,
      headerComponentParams: {
        header1: 'Vuokra-ajan',
        header2: 'kokonaishinta',
      },
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        return !isSalesItem(params.data?.type)
          ? calculateTotalPrice(
              params.data,
              optionalColumnProps.roundingBasisSheet,
            )
          : null;
      },
      cellStyle: defaultCellStyle,
    },
    {
      field: 'discount',
      headerName: 'Alennus%',
      filter: 'agNumberColumnFilter',
      valueGetter: discountGetter,
      valueFormatter: percentFormatter,
      headerTooltip: 'Hinta verrattuna ehdotushintaan',
      cellClass: (params: CellClassParams) => {
        const discountType = isMonthPricing(params)
          ? 'discountMonth'
          : 'discountDay';
        const className = getClassForAgGridCell(
          params.data,
          classes,
          discountType,
        );
        return !className?.startsWith('editable') && className;
      },
      cellStyle: {
        ...defaultCellStyle,
        color: '#000',
      },
    },
    {
      field: 'ramiturvaPrice',
      headerName: 'RamiTurvan hinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) =>
        ramiturvaPriceGetter(
          params,
          optionalColumnProps.roundingBasisSheet,
        ),
      valueFormatter: ramiturvaPriceFormatter,
      headerTooltip: 'RamiTurvan hinta',
      cellClass: (params: CellClassParams) => {
        return getClassForAgGridCell(
          params.data,
          classes,
          'ramiturvaPrice',
        );
      },
      cellStyle: {
        defaultCellStyle,
      },
    },
    {
      field: 'approvalApproverComment',
      headerName: 'Kommentti',
      hide: hideInspectComment,
      cellRenderer: memo(OfferCommentRenderer),
      cellStyle: {
        ...defaultCellStyle,
        borderRight: '1px solid #ccc',
        padding: 0,
      } as CellStyle,
    },
  ];
};
