import { GridColumnProps } from './ColumnsBase';
import { getCatClassColDef } from '../helpers/getCatClassColDef';

const OfferColumnsPreSelect = ({
  gridGroupingValues,
}: GridColumnProps) => {
  return getCatClassColDef(gridGroupingValues);
};

export default OfferColumnsPreSelect;
