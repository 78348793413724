import React, { useState } from 'react';
import { LoadingState, useApi } from '../../../../hooks/useApi';
import SearchIcon from '@mui/icons-material/Search';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  Autocomplete,
  Box,
  Typography,
  CircularProgress,
  TextField,
} from '@mui/material';
import {
  CustomerType,
  Nullable,
} from '../../../../../../shared/types';
import { MIRA_CUSTOMER_BY_ID_QUERY_KEY } from '../../../../../../shared/constants';
import { useQuery } from '@tanstack/react-query';
import { getMiraCustomerById } from '../../../../services/customers';
import {
  MiraCustomerBlocking,
  MiraCustomerData,
} from '../../../../../../shared/types/customer';
import { CustomerBlockingInfo } from '../CustomerBlockingInfo';
import { getBlockedText } from '../../../../helpers/getCustomerBlockedText';
import { isCustomerBlocked } from '../../../../helpers/isCustomerBlocked';
import { sortCustomersByType } from '../../../../helpers/sortByCustomerType';
import _ from 'lodash';

export interface ClientOption extends MiraCustomerBlocking {
  readonly value: string | undefined;
  readonly label: string | undefined;
  readonly id: string | undefined;
  readonly type: CustomerType.MIRA | CustomerType.PROSPECT;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const DEFAULT_BLOCKS = {
  blockedAll: false,
  blockedConfirmation: false,
  blockedInvoice: false,
  blockedPacking: false,
  blockedPicking: false,
  blockingReason: '',
  blockingComment: '',
};

type CustomerSearchSelectorProps = {
  customerName: string;
  customerId: string;
  onCustomerChange: (clientOption: Nullable<ClientOption>) => void;
};

const CustomerSearchSelector: React.FC<
  CustomerSearchSelectorProps
> = ({ onCustomerChange, customerName, customerId }) => {
  const [customerSearchName, setCustomerSearchName] = useState<
    string | undefined
  >(undefined);
  const [clientName, setClientName] = useState<string | undefined>();
  const [clientNameAlias, setClientNameAlias] = useState<
    undefined | string
  >(undefined);

  const [clients, isLoadingClients, clientsError] = useApi<
    MiraCustomerData[]
  >('/clients/mira/' + clientName, () => clientName !== undefined);

  // Fetch customer blocking data after customer is selected
  const { data: customerBlockData, isError: isErrorBlocking } =
    useQuery({
      queryKey: [MIRA_CUSTOMER_BY_ID_QUERY_KEY, customerId],
      enabled: Boolean(customerId),
      queryFn: async () =>
        _.pick(
          await getMiraCustomerById(customerId),
          Object.keys(DEFAULT_BLOCKS),
        ),
    });

  const customerBlocks = customerBlockData ?? DEFAULT_BLOCKS;

  const handleClientFetchMira = () => {
    if (customerSearchName !== clientName) {
      setClientName(customerSearchName);
    }
  };

  const clientOptions = clientsError
    ? null
    : clients?.sort(sortCustomersByType).map((client) => ({
        type: client.type,
        value: client.clientName,
        label: client.clientNameAlias,
        id: client.clientId,
        isFixed: true,
        blockedAll: !!client.blockedAll,
        blockedConfirmation: !!client.blockedConfirmation,
        blockedInvoice: !!client.blockedInvoice,
        blockedPacking: !!client.blockedPacking,
        blockedPicking: !!client.blockedPicking,
      }));

  const handleAutoCompleteChange = (
    newValue: ClientOption | null,
  ) => {
    onCustomerChange(newValue);
    setClientNameAlias(newValue?.label);
  };

  const handleInputChange = (value: string) => {
    setCustomerSearchName(value);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >
        <Autocomplete
          fullWidth
          clearOnBlur={false}
          size="small"
          clearOnEscape
          options={clientOptions ? clientOptions : []}
          loading={isLoadingClients === LoadingState.IsLoading}
          noOptionsText={'Ei tuloksia'}
          loadingText={'Haetaan...'}
          getOptionLabel={(option) => option.value || ''}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              handleClientFetchMira();
            }
          }}
          isOptionEqualToValue={(option, value) =>
            option.id === value.id
          }
          onChange={(event, newValue) => {
            handleAutoCompleteChange(newValue as ClientOption);
          }}
          onInputChange={(event, newInputValue) => {
            handleInputChange(newInputValue);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Box
                    style={{
                      lineHeight: 1.2,
                    }}
                  >
                    {option.value}
                  </Box>
                  <Box style={{ fontSize: '12px' }}>{option.id}</Box>
                  <Box
                    style={{
                      fontSize: '12px',
                      color: 'red',
                    }}
                  >
                    {getBlockedText(option)}
                  </Box>
                </Box>
              </li>
            );
          }}
          groupBy={(option) => {
            if (isCustomerBlocked(option)) {
              return 'Lukitut';
            }

            return option.type === CustomerType.MIRA
              ? 'Asiakkaat'
              : 'Prospektit';
          }}
          renderGroup={(params) => (
            <Box key={params.group}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#3f50b5',
                  color: 'white',
                  padding: '4px 8px',
                }}
              >
                {params.group}
              </Box>
              {params.children}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Hae asiakkaita"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    <button
                      onClick={handleClientFetchMira}
                      style={{
                        display: 'flex',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      {isLoadingClients === LoadingState.IsLoading ? (
                        <CircularProgress
                          color={'primary'}
                          size={20}
                        />
                      ) : (
                        <SearchIcon />
                      )}
                    </button>
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
      <Card variant="outlined" sx={{ minWidth: 275, my: 2 }}>
        <CardContent>
          <Typography variant="body1" color="text.primary">
            {customerName ? customerName : 'Asiakasta ei valittu!'}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {clientNameAlias && clientNameAlias}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {customerId ? customerId : ''}
          </Typography>
          <CustomerBlockingInfo
            customerBlocks={customerBlocks}
            isErrorBlocking={isErrorBlocking}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default CustomerSearchSelector;
