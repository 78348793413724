import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';

import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ChangeType, EditItemsDialogChange } from './types';

interface CommonTabProps {
  changeType: ChangeType;
  targetUtilRate: EditItemsDialogChange;
  setTargetUtilRate: (change: EditItemsDialogChange) => void;
  depreciationPeriod: EditItemsDialogChange;
  setDepreciationPeriod: (change: EditItemsDialogChange) => void;
  rentalReadyPrice: EditItemsDialogChange;
  setRentalReadyPrice: (change: EditItemsDialogChange) => void;
}

const CommonTab: React.FC<CommonTabProps> = ({
  changeType,
  targetUtilRate,
  setTargetUtilRate,
  depreciationPeriod,
  setDepreciationPeriod,
  rentalReadyPrice,
  setRentalReadyPrice,
}) => {
  return (
    <>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">
          {changeType === 'ABSOLUTE' ? (
            <>Syötä uudet arvot:</>
          ) : (
            <>Syötä prosentuaalinen (%) muutos:</>
          )}
        </FormLabel>
        <Box sx={{ mt: 2 }}>
          <List>
            <ListItem
              key={`list-item-rental-ready-price-${changeType}`}
            >
              <TextField
                key={`text-field-rental-ready-price`}
                label={
                  changeType === 'ABSOLUTE'
                    ? 'Rental Ready'
                    : `Rental Ready muutos`
                }
                id={`input-rental-ready-price`}
                size="small"
                type="number"
                value={
                  changeType === 'ABSOLUTE'
                    ? rentalReadyPrice?.absoluteValue || ''
                    : rentalReadyPrice?.percentValue || ''
                }
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) =>
                  setRentalReadyPrice(
                    event.target.value
                      ? changeType === 'ABSOLUTE'
                        ? {
                            ...rentalReadyPrice,
                            absoluteValue: Number(event.target.value),
                          }
                        : {
                            ...rentalReadyPrice,
                            percentValue: Number(event.target.value),
                          }
                      : {
                          absoluteValue: undefined,
                          percentValue: undefined,
                        },
                  )
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[+0-9]*',
                  min: changeType === 'ABSOLUTE' ? 0 : undefined,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {changeType === 'PERCENT' ? '%' : '€'}
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
            <ListItem
              key={`list-item-target-util-rate-${changeType}`}
            >
              <TextField
                key={`text-field-target-util-rate`}
                label={
                  changeType === 'ABSOLUTE'
                    ? 'Tavoitekäyttöaste'
                    : `Tavoitekäyttöaste muutos`
                }
                id={`input-target-util-rate`}
                size="small"
                type="number"
                value={
                  changeType === 'ABSOLUTE'
                    ? targetUtilRate?.absoluteValue || ''
                    : targetUtilRate?.percentValue || ''
                }
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) =>
                  setTargetUtilRate(
                    event.target.value
                      ? changeType === 'ABSOLUTE'
                        ? {
                            ...targetUtilRate,
                            absoluteValue: Number(event.target.value),
                          }
                        : {
                            ...targetUtilRate,
                            percentValue: Number(event.target.value),
                          }
                      : {
                          absoluteValue: undefined,
                          percentValue: undefined,
                        },
                  )
                }
                sx={{ minWidth: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[+0-9]*',
                  min: changeType === 'ABSOLUTE' ? 0 : undefined,
                  max: 100,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </ListItem>
            <ListItem key={`list-item-depreciation-period`}>
              <TextField
                key={`text-field-depreciation-period-${changeType}`}
                label={
                  changeType === 'ABSOLUTE'
                    ? 'Poistoaika'
                    : `Poistoaika muutos`
                }
                id={`input-depreciation-period`}
                size="small"
                type="number"
                value={
                  changeType === 'ABSOLUTE'
                    ? depreciationPeriod?.absoluteValue || ''
                    : depreciationPeriod?.percentValue || ''
                }
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) =>
                  setDepreciationPeriod(
                    event.target.value
                      ? changeType === 'ABSOLUTE'
                        ? {
                            ...depreciationPeriod,
                            absoluteValue: Number(event.target.value),
                          }
                        : {
                            ...depreciationPeriod,
                            percentValue: Number(event.target.value),
                          }
                      : {
                          absoluteValue: undefined,
                          percentValue: undefined,
                        },
                  )
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[+0-9]*',
                  min: changeType === 'ABSOLUTE' ? 0 : undefined,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {changeType === 'PERCENT' ? '%' : 'kk'}
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
          </List>
        </Box>
      </FormControl>
    </>
  );
};

export default CommonTab;
