import { IRowNode, CellMouseDownEvent } from 'ag-grid-community';
import { AgGridRefType } from '../../../types';
import { MutableRefObject } from 'react';

/**
 * inspiration from
 * https://stackoverflow.com/questions/57770409/how-to-expand-all-child-nodes-inside-one-parent-node-group-element-on-custom-get
 **/
let timeoutId: number | undefined;

const getChildNodes = (
  clickedNode: IRowNode,
  gridRef: any,
): IRowNode[] => {
  let haveEnteredChildZone: boolean = false;
  let haveLeftChildZone: boolean = false;
  let childNodes: IRowNode[] = [];

  gridRef?.current?.api?.forEachNode((node: IRowNode) => {
    if (node.id === clickedNode.id) {
      haveEnteredChildZone = true;
      return;
    }

    if (haveEnteredChildZone && node.level === clickedNode.level) {
      haveLeftChildZone = true;
      return;
    }
    if (haveEnteredChildZone && !haveLeftChildZone) {
      childNodes.push(node);
    }
  });

  return childNodes;
};

const getParentNodes = (
  clickedNode: IRowNode,
  gridRef: any,
): IRowNode[] => {
  let haveEnteredParentZone: boolean = false;
  let haveLeftParentZone: boolean = false;
  let parentNodes: IRowNode[] = [];

  gridRef?.current?.api?.forEachNode((node: IRowNode) => {
    if (node.id === clickedNode.id) {
      haveEnteredParentZone = true;
      return;
    }

    if (haveEnteredParentZone && node.level === clickedNode.level) {
      haveLeftParentZone = true;
      return;
    }
    if (haveEnteredParentZone && !haveLeftParentZone) {
      parentNodes.push(node);
    }
  });

  return parentNodes;
};

const drillRowToLevel = (
  params: { node: IRowNode },
  level: number,
  uiLevel: number,
  gridRef: MutableRefObject<AgGridRefType>,
) => {
  let childNodes: IRowNode[] = getChildNodes(params.node, gridRef); // get child nodes
  childNodes.forEach((n) => {
    gridRef?.current?.api?.setRowNodeExpanded(
      n,
      n.level < level + uiLevel,
    ); // set child nodes expanded
  });

  gridRef?.current?.api?.setRowNodeExpanded(
    params.node,
    params.node.level < level,
  ); // expand self
  gridRef?.current?.api?.onGroupExpandedOrCollapsed(); // enact the expansion changes
};

const closeRowToLevel = (
  params: { node: IRowNode },
  level: number,
  uiLevel: number,
  gridRef: MutableRefObject<AgGridRefType>,
) => {
  let parentNodes: any[] = getParentNodes(params.node, gridRef); // get parent nodes
  parentNodes.forEach((n) => {
    gridRef?.current?.api?.setRowNodeExpanded(
      n,
      n.level > level + uiLevel,
    ); // set parent nodes closed
  });
  gridRef?.current?.api?.setRowNodeExpanded(
    params.node,
    params.node.level > level,
  ); // close self
  gridRef?.current?.api?.onGroupExpandedOrCollapsed(); // enact the expansion changes
};

export const expandAllChildren = (
  gridRef: MutableRefObject<AgGridRefType>,
  event: CellMouseDownEvent,
) => {
  const uiLevel = event.node.uiLevel;
  drillRowToLevel(event, 3, uiLevel, gridRef);
};

export const collapseAllChildren = (
  gridRef: MutableRefObject<AgGridRefType>,
  event: CellMouseDownEvent,
) => {
  const uiLevel = event.node.uiLevel;
  closeRowToLevel(event, 3, uiLevel, gridRef);
};

export const setExpandCollapseAllTimer =
  (gridRef: MutableRefObject<AgGridRefType>) =>
  (event: CellMouseDownEvent) => {
    const mTimeout = () => {
      if (event.node.expanded) {
        collapseAllChildren(gridRef, event);
      } else {
        expandAllChildren(gridRef, event);
      }
    };
    const onMouseDown = () => {
      timeoutId = setTimeout(mTimeout, 500);
    };

    onMouseDown();
  };

export const clearExpandCollapseAllTimer = () => {
  clearTimeout(timeoutId);
};
