import { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/Edit';

import DialogActions from '@mui/material/DialogActions';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import FormControl from '@mui/material/FormControl';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';

import { createTheme, Divider } from '@mui/material';
import DefaultTooltip from '../Tooltips/DefaultTooltip';

import {
  OfferItem,
  PricingBasis,
  PricingSheetRow,
} from '../../../../shared/types';

import EditPricesByPercent from './EditPricesDialogContents/EditPricesByPercent';
import EditPrices from './EditPricesDialogContents/EditPrices';
import EditDiscountPercent from './EditPricesDialogContents/EditDiscountPercent';
import EditCoefficient from './EditPricesDialogContents/EditCoefficient';

import SelectPriceType from './EditPricesDialogContents/SelectPriceType';

import { percentualNumberChange } from '../../../../shared/percentualNumberChange';

import { PRICE_FACTOR } from '../../../../shared/constants';

enum EditState {
  PRICE = 0,
  PRICE_BY_PERCENT = 1,
  PERCENT = 2,
  COEFFICIENT = 3,
}

export type CoefficientState = {
  basis: Exclude<PricingBasis, 'BOTH'>;
  value: number | null;
};

interface EditPricesDialogProps {
  onChangePrices: (selectedRows: OfferItem[]) => void;
  backgroundInfo: any;
  selectedRows: PricingSheetRow[];
}

const INIT_COEFFICIENT_STATE: CoefficientState = {
  basis: 'DAY',
  value: null,
};

const EditPricesDialog: React.FC<EditPricesDialogProps> = ({
  onChangePrices,
  backgroundInfo,
  selectedRows,
}) => {
  const theme = createTheme();

  const [activeTab, setActiveTab] = useState<EditState>(
    EditState.PRICE,
  );

  const handleTabChange = (newValue: EditState) => {
    setActiveTab(newValue);
    setEditType(newValue);
    setPriceValue(null);
    setPercentValue(null);
    setAbsolutePercentValue(null);
    setCoefficientState(INIT_COEFFICIENT_STATE);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    resetState();
  };

  const [priceValue, setPriceValue] = useState<number | null>();
  const isAbsolutePriceValid =
    priceValue !== undefined &&
    priceValue !== null &&
    !(typeof priceValue === 'number' ? priceValue <= 0 : false);

  const [percentValue, setPercentValue] = useState<number | null>();
  const isPercentValueValid =
    percentValue !== undefined && percentValue !== null;

  const [absolutePercentValue, setAbsolutePercentValue] = useState<
    number | null
  >();
  const isAbsolutePercentValid =
    absolutePercentValue !== undefined &&
    absolutePercentValue !== null;

  const [coefficientState, setCoefficientState] =
    useState<CoefficientState>(INIT_COEFFICIENT_STATE);
  const isCoefficientStateValid = Boolean(coefficientState.value);

  const checkValidity = () => {
    switch (activeTab) {
      case EditState.PRICE:
        return !isAbsolutePriceValid;
      case EditState.PRICE_BY_PERCENT:
        return !isPercentValueValid;
      case EditState.PERCENT:
        return !isAbsolutePercentValid;
      case EditState.COEFFICIENT:
        return !isCoefficientStateValid;
      default:
        return false;
    }
  };

  const [editType, setEditType] = useState<EditState>(
    EditState.PRICE,
  );

  const [priceType, setPriceType] = useState('DAY');

  useEffect(() => {
    setPriceType(
      backgroundInfo.pricingBasis === 'MONTH' ? 'MONTH' : 'DAY',
    );
  }, [backgroundInfo.pricingBasis]);

  const handlePriceTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPriceType((event.target as HTMLInputElement).value);
  };

  const resetState = () => {
    setPriceValue(null);
    setPercentValue(null);
    setAbsolutePercentValue(null);
    setPriceType('DAY');
    setCoefficientState(INIT_COEFFICIENT_STATE);
    setEditType(EditState.PRICE);
    handleTabChange(EditState.PRICE);
  };

  const changePrices = (rows: PricingSheetRow[]) => {
    const newRows = selectedRows.map(
      (pricingSheetRow: PricingSheetRow) => {
        if (
          rows.some(
            (r: PricingSheetRow) =>
              r.catClass === pricingSheetRow.catClass,
          ) &&
          priceValue
        ) {
          if (priceType === 'DAY') {
            return {
              ...pricingSheetRow,
              dayPrice: priceValue * PRICE_FACTOR,
              selected: false,
            };
          } else if (priceType === 'MONTH') {
            return {
              ...pricingSheetRow,
              monthPrice: priceValue * PRICE_FACTOR,
              selected: false,
            };
          } else {
            return {
              ...pricingSheetRow,
              dayPrice: priceValue * PRICE_FACTOR,
              monthPrice: priceValue * PRICE_FACTOR,
              selected: false,
            };
          }
        } else {
          if (priceType === 'DAY') {
            return {
              ...pricingSheetRow,
              dayPrice: pricingSheetRow.dayPrice,
              selected: false,
            };
          } else if (priceType === 'MONTH') {
            return {
              ...pricingSheetRow,
              monthPrice: pricingSheetRow.monthPrice,
              selected: false,
            };
          } else {
            return {
              ...pricingSheetRow,
              dayPrice: pricingSheetRow.dayPrice,
              monthPrice: pricingSheetRow.monthPrice,
              selected: false,
            };
          }
        }
      },
    );
    onChangePrices([...newRows]);
    handleClose();
  };

  const changePricesByPercent = (
    rows: PricingSheetRow[],
    type: 'PRICE_BY_PERCENT' | 'PERCENT',
  ) => {
    const newRows = selectedRows.map(
      (pricingSheetRow: PricingSheetRow) => {
        if (
          rows.some((r) =>
            r.offerItemId
              ? r.offerItemId === pricingSheetRow.offerItemId
              : r.catClass === pricingSheetRow.catClass,
          ) &&
          (type === 'PRICE_BY_PERCENT'
            ? percentValue
            : absolutePercentValue !== undefined)
        ) {
          const newDayPriceValue =
            type === 'PRICE_BY_PERCENT'
              ? percentualNumberChange(
                  pricingSheetRow.dayPrice,
                  percentValue,
                )
              : percentualNumberChange(
                  pricingSheetRow.proposalDayPrice,
                  absolutePercentValue,
                );
          const newMonthPriceValue =
            type === 'PRICE_BY_PERCENT'
              ? percentualNumberChange(
                  pricingSheetRow.monthPrice,
                  percentValue,
                )
              : percentualNumberChange(
                  pricingSheetRow.proposalMonthPrice,
                  absolutePercentValue,
                );

          if (priceType === 'DAY') {
            return {
              ...pricingSheetRow,
              dayPrice: newDayPriceValue,
              selected: false,
            };
          } else if (priceType === 'MONTH') {
            return {
              ...pricingSheetRow,
              monthPrice: newMonthPriceValue,
              selected: false,
            };
          } else {
            return {
              ...pricingSheetRow,
              dayPrice: newDayPriceValue,
              monthPrice: newMonthPriceValue,
              selected: false,
            };
          }
        } else {
          if (priceType === 'DAY') {
            return {
              ...pricingSheetRow,
              dayPrice: pricingSheetRow.dayPrice,
              selected: false,
            };
          } else if (priceType === 'MONTH') {
            return {
              ...pricingSheetRow,
              monthPrice: pricingSheetRow.monthPrice,
              selected: false,
            };
          } else {
            return {
              ...pricingSheetRow,
              dayPrice: pricingSheetRow.dayPrice,
              monthPrice: pricingSheetRow.monthPrice,
              selected: false,
            };
          }
        }
      },
    );

    onChangePrices([...newRows]);
    handleClose();
  };

  const changeItemsCoefficient = (rows: PricingSheetRow[]) => {
    const { basis, value: coefficientValue } = coefficientState;
    if (coefficientValue) {
      const newRows = selectedRows.map(
        (pricingSheetRow: PricingSheetRow) => {
          if (
            rows.some((r) =>
              r.offerItemId
                ? r.offerItemId === pricingSheetRow.offerItemId
                : r.catClass === pricingSheetRow.catClass,
            )
          ) {
            let dayPrice = pricingSheetRow.dayPrice;
            let monthPrice = pricingSheetRow.monthPrice;

            if (basis === 'DAY') {
              monthPrice = dayPrice
                ? dayPrice * coefficientValue
                : monthPrice;
            }
            if (basis === 'MONTH') {
              dayPrice = monthPrice
                ? monthPrice / coefficientValue
                : dayPrice;
            }

            return {
              ...pricingSheetRow,
              dayPrice,
              monthPrice,
              selected: false,
            };
          } else {
            return { ...pricingSheetRow, selected: false };
          }
        },
      );

      onChangePrices([...newRows]);
    }
    handleClose();
  };

  const formatInputValue = (val: any) => (val === null ? '' : val);

  return (
    <>
      <DefaultTooltip title="Muokkaa valittujen tuoterivien hintoja">
        <span>
          <Button
            disabled={selectedRows.length === 0}
            sx={{ height: 26 }}
            size="small"
            variant="outlined"
            onClick={handleOpen}
          >
            <EditIcon style={{ fontSize: 16 }} />
            <Box
              sx={{
                [theme.breakpoints.down('xl')]: {
                  display: 'none',
                },
                [theme.breakpoints.up('xl')]: {
                  display: 'inline',
                },
              }}
            >
              Muokkaa valittuja
            </Box>
          </Button>
        </span>
      </DefaultTooltip>
      <Dialog
        disableScrollLock
        maxWidth={'md'}
        fullWidth={true}
        open={open}
        onClose={() => {
          handleClose();
          resetState();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle>Muokkaa valittuja</DialogTitle>
        <Fade in={open}>
          <DialogContent>
            {selectedRows.length > 0 ? (
              <>
                <Typography id="edit-prices-header">
                  Olet muokkaamassa seuraavia tuotteita, (yht.{' '}
                  <b>{selectedRows.length}</b> kpl)
                </Typography>
                <Box
                  sx={{
                    flexGrow: 1,
                    height: selectedRows.length * 20,
                    minHeight: 80,
                    maxHeight: 160,
                    mb: 4,
                  }}
                >
                  <List
                    dense={true}
                    style={{
                      maxHeight: '100%',
                      overflow: 'auto',
                      border: '1px solid grey',
                    }}
                  >
                    {selectedRows.map((rowItem: PricingSheetRow) => {
                      return (
                        <ListItem
                          key={
                            rowItem.offerItemId
                              ? rowItem.offerItemId
                              : rowItem.catClass
                          }
                        >
                          <ListItemText
                            primary={rowItem.catClass}
                            secondary={rowItem.name}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
                <Box sx={{ mb: 1 }}>
                  <Typography color="primary">
                    Muokkaa tuotteiden:
                  </Typography>

                  <Tabs
                    value={activeTab}
                    onChange={(_, newValue) => {
                      handleTabChange(newValue);
                    }}
                  >
                    <Tab
                      label={
                        <DefaultTooltip
                          title={
                            'Muokkaa valittujen tuotteiden hintaa absoluuttisella arvolla'
                          }
                          placement={'top'}
                        >
                          <>Hintaa</>
                        </DefaultTooltip>
                      }
                    />
                    <Tab
                      label={
                        <DefaultTooltip
                          title={
                            'Muokkaa valittujen tuotteiden hintaa prosentuaalisesti nykyisestä hinnasta'
                          }
                          placement={'top'}
                        >
                          <>Hintaa prosenteissa</>
                        </DefaultTooltip>
                      }
                    />
                    <Tab
                      label={
                        <DefaultTooltip
                          title={
                            ' Muokkaa valittujen tuotteiden hintaa prosentuaalisesti ehdotushinnasta'
                          }
                          placement={'top'}
                        >
                          <>Alennusprosenttia</>
                        </DefaultTooltip>
                      }
                    />
                    <Tab
                      label={
                        <DefaultTooltip
                          title={
                            'Muokkaa valittujen tuotteiden PV->KK kerrointa absoluuttisella arvolla'
                          }
                          placement={'top'}
                        >
                          <>{`PV->KK kerrointa`}</>
                        </DefaultTooltip>
                      }
                    />
                  </Tabs>
                </Box>
                <Divider />
                <FormControl component="fieldset">
                  {editType !== EditState.COEFFICIENT && (
                    <SelectPriceType
                      activeTab={activeTab}
                      pricingBasis={backgroundInfo.pricingBasis}
                      priceType={priceType}
                      handlePriceTypeChange={handlePriceTypeChange}
                    />
                  )}

                  {editType === EditState.PRICE && (
                    <EditPrices
                      priceValue={priceValue}
                      setPriceValue={setPriceValue}
                      formatInputValue={formatInputValue}
                      isAbsolutePriceValid={isAbsolutePriceValid}
                    />
                  )}

                  {editType === EditState.PRICE_BY_PERCENT && (
                    <EditPricesByPercent
                      percentValue={percentValue}
                      setPercentValue={setPercentValue}
                      formatInputValue={formatInputValue}
                    />
                  )}
                  {editType === EditState.PERCENT && (
                    <EditDiscountPercent
                      absolutePercentValue={absolutePercentValue}
                      setAbsolutePercentValue={
                        setAbsolutePercentValue
                      }
                      formatInputValue={formatInputValue}
                    />
                  )}
                  {editType === EditState.COEFFICIENT && (
                    <EditCoefficient
                      coefficientState={coefficientState}
                      setCoefficientState={setCoefficientState}
                    />
                  )}
                </FormControl>

                <DialogActions
                  sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Button
                      color="inherit"
                      onClick={() => {
                        handleClose();
                        resetState();
                      }}
                    >
                      Peruuta
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      disabled={checkValidity()}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (editType === EditState.PRICE) {
                          changePrices(selectedRows);
                        }
                        if (editType === EditState.PRICE_BY_PERCENT) {
                          changePricesByPercent(
                            selectedRows,
                            'PRICE_BY_PERCENT',
                          );
                        }
                        if (editType === EditState.PERCENT) {
                          changePricesByPercent(
                            selectedRows,
                            'PERCENT',
                          );
                        }
                        if (editType === EditState.COEFFICIENT) {
                          changeItemsCoefficient(selectedRows);
                        }
                        resetState();
                      }}
                    >
                      Muokkaa
                    </Button>
                  </Box>
                </DialogActions>
              </>
            ) : (
              <>
                <Typography
                  id="not-selected-items-warning"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Et ole valinnut hinnoiteltavia tuotteita!
                </Typography>
              </>
            )}
          </DialogContent>
        </Fade>
      </Dialog>
    </>
  );
};

export default EditPricesDialog;
