import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { updateOfferSheetItems } from '../services/offerSheets';
import {
  DatabaseOfferWithItems,
  UpdateOfferItemsResponseBody,
} from '../../../shared/types/offers';
import { OFFER_SHEET_QUERY_KEY } from '../../../shared/constants';
import { produce } from 'immer';
import { AxiosError } from 'axios';

type DataResponseType = UpdateOfferItemsResponseBody;
type ErrorType = AxiosError;
type VariablesType = Parameters<typeof updateOfferSheetItems>[0];

type UseUpdateOfferItemsParams = Omit<
  UseMutationOptions<DataResponseType, ErrorType, VariablesType>,
  'mutationFn'
>;

export const useUpdateOfferItems = (
  options: UseUpdateOfferItemsParams = {},
) => {
  const queryClient = useQueryClient();
  return useMutation<DataResponseType, ErrorType, VariablesType>({
    ...options,
    mutationFn: updateOfferSheetItems,
    onSuccess: (data, variables, context) => {
      // update items to offer query cache
      queryClient.setQueryData<DatabaseOfferWithItems>(
        [OFFER_SHEET_QUERY_KEY, `${variables.id}`],
        (oldData) =>
          produce(oldData, (updatedData) => {
            if (updatedData) {
              updatedData.items = data;
            }
          }),
      );
      options?.onSuccess?.(data, variables, context);
    },
  });
};
