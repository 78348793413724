export const processEnvs = {
  BACKEND_URI: import.meta.env.VITE_BACKEND_URI ?? '',
  AUTH_URL: import.meta.env.VITE_AUTH_URL ?? '',
  REQUIRE_LOGIN: import.meta.env.VITE_REQUIRE_LOGIN ?? '',
  NODE_ENV: import.meta.env.VITE_NODE_ENV ?? '',
  MAINTENANCE: import.meta.env.VITE_MAINTENANCE ?? '',
  USE_STRICT_MODE: import.meta.env.VITE_USE_STRICT_MODE ?? '',
  OFFER_TOOL_ALLOWED_USERS:
    import.meta.env.VITE_OFFER_TOOL_ALLOWED_USERS ?? '',
  HEROKU_PR_NUMBER: import.meta.env.HEROKU_PR_NUMBER ?? '',
  ENABLE_SENTRY: import.meta.env.VITE_ENABLE_SENTRY ?? '',
};
