import {
  CircularProgress,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Typography,
  Box,
} from '@mui/material';
import { ReactNode } from 'react';
import { DataTableRow, DataTableRowProps } from './DataTableRow';
import { TABLE_SIZE_OPTIONS } from '../../../../../shared/constants';
import {
  TableColumn,
  TableFilterOptions,
  TablePaginationOptions,
  TableSizeOption,
  TableSortOptions,
  TableSortOrder,
} from '../../../../../shared/types';
import { hasMorePages } from '../../../utils/hasMorePages';
import { useTheme } from '@mui/system';
import {
  DataTableColumnFilter,
  DataTableColumnFilterProps,
} from './DataTableColumnFilter';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { WarningAmber } from '@mui/icons-material';
import { DataTableRowActionsProps } from './DataTableRowActions';
import { GetMiraCustomersByIdsResponseBody } from '../../../../../shared/types/customer';
import _ from 'lodash';

export type DataTableProps<T> = {
  count: number;
  detailsOpenByDefault?: boolean;
  filterOptions: TableFilterOptions<T>;
  labelNoResults?: ReactNode;
  labelRowsPerPage?: ReactNode;
  loading: boolean;
  items: T[];
  miraCustomers?: GetMiraCustomersByIdsResponseBody;
  paginationOptions: TablePaginationOptions;
  renderCellComponent: DataTableRowProps<T>['renderCellComponent'];
  renderColumnFilter?: DataTableColumnFilterProps<T>['renderColumnFilter'];
  renderRowActions?: DataTableRowActionsProps<T>['renderRowActions'];
  renderRowDetails?: DataTableRowProps<T>['renderRowDetails'];
  setFilterOptions: DataTableColumnFilterProps<T>['setFilterOptions'];
  setPaginationOptions: (
    updatedPagination: TablePaginationOptions,
  ) => void;
  setSortOptions: (updatedSort: TableSortOptions<T>) => void;
  sortOptions: TableSortOptions<T>;
  tableColumns: TableColumn<T>[];
  tableHeaderColor?: string;
};

export const ACTION_COLUMN_WIDTH = 50;

export const DataTable = <
  T extends { id: number; customerId?: string },
>({
  count = 0,
  detailsOpenByDefault = false,
  filterOptions,
  labelNoResults = 'Tuloksia ei löytynyt!',
  labelRowsPerPage = 'Tuloksia per sivu',
  loading = false,
  items = [],
  miraCustomers = [],
  paginationOptions,
  renderCellComponent = () => null,
  renderColumnFilter,
  renderRowActions,
  renderRowDetails,
  setFilterOptions = () => null,
  setPaginationOptions = () => null,
  setSortOptions = () => null,
  sortOptions,
  tableColumns = [],
  tableHeaderColor,
}: DataTableProps<T>) => {
  const theme = useTheme();
  const { limit, offset } = paginationOptions;
  const { direction, orderBy } = sortOptions;
  const colSpan = tableColumns.length + 1;

  const handleSort = (key: keyof T) => {
    setSortOptions({
      orderBy: key,
      direction:
        orderBy === key
          ? direction === TableSortOrder.Asc
            ? TableSortOrder.Desc
            : TableSortOrder.Asc
          : TableSortOrder.Asc,
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '1px solid #e0e0e0',
          borderBottomWidth: 0,
        }}
      >
        <Table
          size={'small'}
          sx={{
            minWidth: 650,
            opacity: loading ? 0.7 : 1,
            transition: 'opacity 75ms linear',
          }}
        >
          {/* TABLE HEADER */}
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: tableHeaderColor || '#f2f2f2',
                transition: 'background-color 200ms linear',
              }}
            >
              {tableColumns.map(({ key, label, width }) => (
                <TableCell
                  width={width}
                  key={String(key)}
                  sx={{
                    fontWeight: 'bold',
                    pl: 1,
                    pr: 0.5,
                    py: 0.5,
                    whiteSpace: 'nowrap',
                    [theme.breakpoints.down('xl')]: {
                      fontSize: '0.75rem',
                    },
                  }}
                  sortDirection={direction}
                >
                  <TableSortLabel
                    active={orderBy === key}
                    direction={
                      orderBy === key
                        ? direction
                        : TableSortOrder.Desc
                    }
                    sx={{
                      '.MuiSvgIcon-root': {
                        mr: 0,
                      },
                    }}
                    onClick={() => handleSort(key)}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
              {/* HEADER CELL FOR ACTIONS COLUMN */}
              {renderRowActions && (
                <StickyTableCell border type={'th'}>
                  {loading && (
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'end',
                        px: 2,
                      }}
                    >
                      <CircularProgress size={18} thickness={5} />
                    </Box>
                  )}
                </StickyTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* COLUMN FILTERING  */}
            {renderColumnFilter && (
              <DataTableColumnFilter
                filterOptions={filterOptions}
                renderColumnFilter={renderColumnFilter}
                setFilterOptions={setFilterOptions}
                showActionCell={Boolean(renderRowActions)}
                tableColumns={tableColumns}
              />
            )}
            {/* OFFER SHEETS */}
            {items.length === 0 && (
              <TableRow>
                <TableCell colSpan={colSpan} padding={'none'}>
                  <Typography
                    sx={{
                      alignItems: 'center',
                      backgroundColor: '#fbfbfb',
                      display: 'flex',
                      fontWeight: 'bold',
                      gap: 1,
                      justifyContent: 'center',
                      p: 2,
                    }}
                  >
                    <WarningAmber />
                    {labelNoResults}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {items.length > 0 &&
              items.map((item) => {
                // Merge mira customers into items by id
                const mergedItems = _.merge(
                  item,
                  _.find(miraCustomers, {
                    clientId: item.customerId,
                  }),
                );

                return (
                  <DataTableRow
                    key={mergedItems.id}
                    detailsOpenByDefault={detailsOpenByDefault}
                    item={mergedItems}
                    renderCellComponent={renderCellComponent}
                    renderRowActions={renderRowActions}
                    renderRowDetails={renderRowDetails}
                    tableColumns={tableColumns}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={labelRowsPerPage}
        rowsPerPageOptions={TABLE_SIZE_OPTIONS}
        component="div"
        count={count}
        rowsPerPage={limit}
        page={limit >= count ? 0 : offset}
        nextIconButtonProps={{
          disabled:
            loading || !hasMorePages({ count, ...paginationOptions }),
        }}
        onPageChange={(_, page) => {
          setPaginationOptions({
            ...paginationOptions,
            offset: page,
          });
        }}
        onRowsPerPageChange={(event) =>
          setPaginationOptions({
            limit: +event.target.value as TableSizeOption,
            offset: 0,
          })
        }
      />
    </>
  );
};

type StickyTableCellProps = {
  border?: boolean;
  type?: 'th' | 'td';
};

export const StickyTableCell = styled(TableCell, {
  shouldForwardProp: isPropValid,
})<StickyTableCellProps>(
  {
    minWidth: ACTION_COLUMN_WIDTH,
    padding: 0,
    position: 'sticky',
    right: 0,
    width: ACTION_COLUMN_WIDTH,
  },
  ({ border = false, type = 'td' }) => ({
    background: type === 'td' ? '#fff' : 'inherit',
    boxShadow: border ? 'inset 2px 0 0 -1px #e0e0e0' : 'none',
  }),
);
