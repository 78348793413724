import React from 'react';
import Box from '@mui/material/Box';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { CellMouseDownEvent } from 'ag-grid-community';

interface GroupHeaderRendererProps {
  row: any;
  collapseAllNodeChildren: (event: CellMouseDownEvent) => void;
  expandAllNodeChildren: (event: CellMouseDownEvent) => void;
  groupHeaderInnerRenderer?: (row: any, rows: any) => JSX.Element;
  getFilteredRows: any;
}
const GroupHeaderRenderer: React.FC<GroupHeaderRendererProps> = ({
  row,
  collapseAllNodeChildren,
  expandAllNodeChildren,
  groupHeaderInnerRenderer,
  getFilteredRows,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <span
          onClick={() => {
            if (row.node.expanded) {
              collapseAllNodeChildren(row);
            } else {
              expandAllNodeChildren(row);
            }
          }}
        >
          {row.node.expanded ? (
            <KeyboardDoubleArrowDownIcon
              style={{ fontSize: 18, display: 'inherit' }}
            />
          ) : (
            <KeyboardDoubleArrowRightIcon
              style={{ fontSize: 18, display: 'inherit' }}
            />
          )}
        </span>
        <span style={{ marginLeft: 12 }}> {row?.value}</span>
        {groupHeaderInnerRenderer &&
          groupHeaderInnerRenderer(row, getFilteredRows())}
      </Box>
    </>
  );
};

export default GroupHeaderRenderer;
