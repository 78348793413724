import { PriceCategory } from "./types";

const getCoefficientKeysByCategory = (category: PriceCategory) => {
  switch (category) {
    case PriceCategory.PriceCategory7:
      return {
        dayCoefficient: "dayPriceCoefficient7",
        monthCoefficient: "monthPriceCoefficient7",
        dayPricePercent: "dayPricePercent7",
        monthPricePercent: "monthPricePercent7",
      };
    case PriceCategory.PriceCategory6:
      return {
        dayCoefficient: "dayPriceCoefficient6",
        monthCoefficient: "monthPriceCoefficient6",
        dayPricePercent: "dayPricePercent6",
        monthPricePercent: "monthPricePercent6",
      };
    case PriceCategory.PriceCategory5:
      return {
        dayCoefficient: "dayPriceCoefficient5",
        monthCoefficient: "monthPriceCoefficient5",
        dayPricePercent: "dayPricePercent5",
        monthPricePercent: "monthPricePercent5",
      };
    case PriceCategory.PriceCategory4:
      return {
        dayCoefficient: "dayPriceCoefficient4",
        monthCoefficient: "monthPriceCoefficient4",
        dayPricePercent: "dayPricePercent4",
        monthPricePercent: "monthPricePercent4",
      };
    case PriceCategory.PriceCategory3:
      return {
        dayCoefficient: "dayPriceCoefficient3",
        monthCoefficient: "monthPriceCoefficient3",
        dayPricePercent: "dayPricePercent3",
        monthPricePercent: "monthPricePercent3",
      };
    case PriceCategory.PriceCategory2:
      return {
        dayCoefficient: "dayPriceCoefficient2",
        monthCoefficient: "monthPriceCoefficient2",
        dayPricePercent: "dayPricePercent2",
        monthPricePercent: "monthPricePercent2",
      };
    case PriceCategory.PriceCategory1:
      return {
        dayCoefficient: "dayPriceCoefficient1",
        monthCoefficient: "monthPriceCoefficient1",
        dayPricePercent: "dayPricePercent1",
        monthPricePercent: "monthPricePercent1",
      };
    default:
      alert(
        "Jokin meni pieleen ehdotushinnaston laskennassa! Ota yhteyttä järjestelmän ylläpitäjään.",
      );
  }
};

export default getCoefficientKeysByCategory;
