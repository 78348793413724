import { PricingSheetRow } from '../../../shared/types';

import {
  isDayWarningPrice,
  isMonthWarningPrice,
  isBelow70PercentPriceRow,
} from '../utils/pricingSheetRowFilters';

const getUnderWarningPriceCount = (rows: PricingSheetRow[]) => {
  const count = rows.reduce(
    (acc: number, current: PricingSheetRow) => {
      let c = 0;

      const dayPriceNotDefined =
        !current?.dayPrice && !current.showByOfferText;
      const monthPriceNotDefined =
        !current?.monthPrice && !current.showByOfferText;

      if (dayPriceNotDefined || monthPriceNotDefined) {
        c += 1;
      } else if (
        isDayWarningPrice(current) &&
        !current.showByOfferText
      ) {
        c += 1;
      } else if (
        isMonthWarningPrice(current) &&
        !current.showByOfferText
      ) {
        c += 1;
      } else if (
        isBelow70PercentPriceRow(current) &&
        !current.showByOfferText
      ) {
        c += 1;
      }

      return acc + c;
    },
    0,
  );
  return count;
};

export default getUnderWarningPriceCount;
