import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AgGridColumnView,
  IndustryName,
  PricingSheetRow,
  ItemIndustryPercent,
} from '../../../../../shared/types';
import {
  ImportType,
  ImportChangesDataMapping,
} from '../../../../../shared/types/import';
import { HeaderMapperTable } from './ExcelImportHeaderMapper';
import { ExcelImportDialogCommonProps } from './ExcelImportDialog';
import {
  DAY_PRICE_COEFFICIENTS,
  MONTH_PRICE_COEFFICIENTS,
  PRICE_COEFFICIENTS,
  PRICE_PERCENTAGE_COEFFICIENT_KEYS,
} from '../../../../../shared/constants';
import useItemIndustryPercentsContext from '../../../providers/Rows/ItemIndustryPercents/ItemIndustryPercentsProvider';
import Box from '@mui/material/Box';
import IndustrySelector from '../../../components/Steps/Components/Selectors/IndustrySelector';
import { getUpdatedRows } from './helpers';
import RowsProvider from '../../../providers/Rows/RowsProvider';
import { industries } from '../../../../../shared/industries';
import { ItemIndustryPercentActions } from '../../../providers/Rows/ItemIndustryPercents/itemIndustryPercentsReducer';
import ExcelImportDialogBase, {
  DialogLabel,
  DialogSubLabel,
} from './ExcelImportDialogBase';

type IndustryPercentColumnView =
  | AgGridColumnView.ADMIN_INDUSTRY_DAY
  | AgGridColumnView.ADMIN_INDUSTRY_MONTH;

type ExcelImportIndustryPercentDialogProps =
  ExcelImportDialogCommonProps & {
    activeIndustry: IndustryName;
  };

const ExcelImportIndustryPercentDialog = ({
  activeIndustry,
  onClose,
  open,
}: ExcelImportIndustryPercentDialogProps) => {
  const [industry, setIndustry] =
    useState<IndustryName>(activeIndustry);
  const { itemIndustryPercents = [], dispatchItemIndustryPercents } =
    useItemIndustryPercentsContext();
  const { pricingSheetRows } = useContext(RowsProvider);

  useEffect(() => {
    // use industry selected in parent by default
    if (open && activeIndustry) {
      setIndustry(activeIndustry);
    }
  }, [activeIndustry, open]);

  const filteredItemIndustryPercents = useMemo(
    () =>
      itemIndustryPercents.filter(
        (item) => item.industry.name === industry,
      ) || [],
    [industry, itemIndustryPercents],
  );

  const importType = ImportType.IndustryPercent;

  const changesTableColumns = PRICE_COEFFICIENTS.map(
    ({ industryPercentKey: key, name }) => ({
      key,
      name,
    }),
  );

  const handleSave = (
    changesDataMapping: ImportChangesDataMapping,
  ) => {
    const updatedRows = getUpdatedRows(
      itemIndustryPercents,
      changesDataMapping,
      industry,
    );
    // update existing rows
    if (updatedRows.length > 0) {
      dispatchItemIndustryPercents({
        type: ItemIndustryPercentActions.UpdateItems,
        itemIndustryPercents: updatedRows,
      });
    }
    const industryIndex = industries.indexOf(industry) + 1;
    const newRows: ItemIndustryPercent[] = Object.entries(
      changesDataMapping,
    )
      .filter(([, { newRow }]) => newRow)
      .map(([catClass, { changes }]) => {
        // remove id and set it as itemId
        const { id, ...item } =
          pricingSheetRows.find(
            (i: PricingSheetRow) => i.catClass === catClass,
          ) || {};

        const industryPercentages =
          PRICE_PERCENTAGE_COEFFICIENT_KEYS.reduce((acc, key) => {
            return {
              ...acc,
              [key]: changes[key]?.newValue ?? 0,
            };
          }, {});

        return {
          ...item,
          itemId: id,
          industryId: industryIndex,
          industry: { id: industryIndex, name: industry },
          ...industryPercentages,
        };
      });
    // add new rows
    if (newRows.length > 0) {
      dispatchItemIndustryPercents({
        type: ItemIndustryPercentActions.AddItems,
        newItems: newRows,
      });
    }
    onClose();
  };

  return (
    <ExcelImportDialogBase<IndustryPercentColumnView>
      changesTableColumns={changesTableColumns}
      importType={importType}
      onClose={onClose}
      onSave={handleSave}
      open={open}
      rowData={filteredItemIndustryPercents}
      title={'Tuo toimialakohtaiset muutosprosentit Excelistä'}
      renderContent={(fileName, loading) =>
        // INDUSTRY SELECTOR
        fileName && !loading ? (
          <Box
            sx={{
              my: 2,
            }}
          >
            <DialogLabel variant={'body1'}>
              Valitse toimiala, jonka muutosprosentit päivitetään
            </DialogLabel>
            <IndustrySelector
              clearable={false}
              industry={industry}
              changeValuesEnabled
              showLabel={false}
              onIndustryChange={setIndustry}
            />
          </Box>
        ) : null
      }
      renderHeaderMapper={(disabled) =>
        [
          {
            label: 'Päiväkertoimet',
            columns: DAY_PRICE_COEFFICIENTS,
          },
          {
            label: 'Kuukausikertoimet',
            columns: MONTH_PRICE_COEFFICIENTS,
          },
        ].map(({ label, columns }) => {
          return (
            <React.Fragment key={label}>
              <DialogSubLabel variant={'body1'}>
                {label}
              </DialogSubLabel>
              <HeaderMapperTable
                columns={columns.map(
                  ({ industryPercentKey: key, name }) => ({
                    key,
                    name,
                  }),
                )}
                disabled={disabled}
                importType={importType}
              />
            </React.Fragment>
          );
        })
      }
    />
  );
};

export default ExcelImportIndustryPercentDialog;
