import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { PricingBasis } from '../../../../../shared/types';

interface SelectPriceTypeProps {
  pricingBasis: PricingBasis;
  priceType: string;
  activeTab: number;
  handlePriceTypeChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

const SelectPriceType: React.FC<SelectPriceTypeProps> = ({
  pricingBasis,
  priceType,
  activeTab,
  handlePriceTypeChange,
}) => {
  return (
    <>
      <RadioGroup
        aria-label="priceType"
        defaultValue="BOTH"
        name="radio-buttons-group"
      >
        <RadioGroup
          aria-label="priceType"
          name="controlled-radio-buttons-group"
          value={priceType}
          onChange={handlePriceTypeChange}
        >
          <FormControlLabel
            disabled={pricingBasis === 'MONTH'}
            value="DAY"
            control={<Radio />}
            label="Päivähinta"
          />
          <FormControlLabel
            disabled={pricingBasis === 'DAY'}
            value="MONTH"
            control={<Radio />}
            label="Kuukausihinta"
          />
          {activeTab !== 0 ? (
            <FormControlLabel
              disabled={pricingBasis === 'DAY'}
              value="BOTH"
              control={<Radio />}
              label="Molemmat"
            />
          ) : null}
        </RadioGroup>
      </RadioGroup>
    </>
  );
};

export default SelectPriceType;
