import { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  PricingSheet,
  PricingSheetRow,
} from '../../../../../shared/types';

import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import { createNewVersionById } from '../../../services/pricingSheets';

export interface NewVersionDialogProps {
  pricingSheet: PricingSheet;
  open: boolean;
  title: string;
  hierarchy: PricingSheetRow[];
  onClose: () => void;
  goToEditPricingSheet: (pricingSheetId: string) => void;
}
const NewVersionDialog = (props: NewVersionDialogProps) => {
  const { pricingSheet, open, title, onClose, goToEditPricingSheet } =
    props;
  const { setNotification } = useContext(NotificationProvider);

  const onClickCreateNewSheetVersion = async (
    pricingSheet: PricingSheet,
  ) => {
    try {
      const newPricingSheet: any = await createNewVersionById(
        pricingSheet.id,
      );
      goToEditPricingSheet(newPricingSheet.id);
      setNotification({
        type: 'SNACKBAR',
        duration: 3000,
        severity: 'success',
        message: `Hinnasto ${newPricingSheet.name} luotu luonnostilassa!`,
      });
    } catch (error) {
      setNotification({
        type: 'SNACKBAR',
        duration: 3000,
        severity: 'error',
        message: `Palvelimella tapahtui virhe! Uuden version luonti hinnastosta epäonnistui!`,
      });
    }
  };

  return (
    <Dialog disableScrollLock open={open} onClose={onClose}>
      <DialogTitle id="new-version-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          {pricingSheet.name}
        </DialogContentText>
        <DialogContentText>
          Hyväksyttyä hinnastoa ei voi muokata. Haluatko luoda uuden
          version hyväksytystä hinnastosta?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>En</Button>
        <Button
          onClick={() => onClickCreateNewSheetVersion(pricingSheet)}
          autoFocus
        >
          Kyllä
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewVersionDialog;
