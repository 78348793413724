import {
  ExternalPricingSheetItem,
  PostPricingSheetItems,
  PricingSheetItemResponse,
} from '../../../shared/types';
import { api } from '../index';

export const postPricingSheetItems = (
  id: string,
  pricingSheetItems: PostPricingSheetItems[],
) => {
  const postPricingSheetItemsResponse = api
    .post(`/pricingsheetitems/${id}`, {
      pricingSheetItems: pricingSheetItems,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return postPricingSheetItemsResponse;
};

export const getPricingSheetItems = () => {
  const getPricingSheetItemsResponse = api
    .get('/pricingsheetitems')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return getPricingSheetItemsResponse;
};

export const getPricingSheetItemsById = async (id: string) => {
  const getPricingSheetItemsByIdResponse = await api.get<
    PricingSheetItemResponse[]
  >(`/pricingsheetitems/${id}`);
  return getPricingSheetItemsByIdResponse.data;
};

export const getPricingSheetItemsByName = (name: string) => {
  const getPricingSheetItemsByIdResponse = api
    .get(`/pricingsheetitems`, {
      params: {
        name: name,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return getPricingSheetItemsByIdResponse;
};

export const addCommentToPricingSheetItemById = (
  id: string,
  pricingSheetItem: { approverComment?: string; catClass?: string },
) => {
  const commentToPricingSheetResponse = api
    .put(`/pricingsheetitems/${id}`, pricingSheetItem)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return commentToPricingSheetResponse;
};

export const getPricingSheetItemsMira = async (
  id: string,
  onlyRamiturvas = false,
) => {
  const getItemsResponse = await api.get<ExternalPricingSheetItem[]>(
    `/pricingsheetitems/mira/${id}?onlyRamiturvas=${onlyRamiturvas}`,
  );
  return getItemsResponse.data;
};
