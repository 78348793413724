import React from 'react';

import { itemStatusCellClasses } from '../../..';

import { PricingSheetRow } from '../../../../../shared/types';

import { formatDateTolocaleDateString } from '../../../utils/formatDateTimes';

import { clone } from '../../../utils/genericUtils';

import Cancel from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from '@mui/material';

import Dialog from '@mui/material/Dialog';

import DefaultTooltip from '../../Tooltips/DefaultTooltip';
import { useTheme } from '@mui/system';

enum ItemStatus {
  ItemNotInPim = 'itemnotinpim',
  MissingPimHierarchy = 'missingpimhierarchy',
  MissingCoefficient = 'missingcoefficient',
  OK = 'ok',
}

const StatusIcon = ({ itemStatus }: { itemStatus: ItemStatus }) => {
  const classes = itemStatusCellClasses();
  const theme = useTheme();
  switch (itemStatus) {
    case ItemStatus.MissingPimHierarchy:
      return (
        <DefaultTooltip
          title={`Tuotteelta puuttuu vaadittavia arvoja ja sitä ei voida näyttää hinnastossa`}
        >
          <Cancel
            className={classes.itemCellIconStyle}
            style={{
              color: theme.palette.error.main,
            }}
          />
        </DefaultTooltip>
      );
    case ItemStatus.MissingCoefficient:
      return (
        <DefaultTooltip
          title={
            'Tuotteelta puuttuu arvoja, mikä voi aiheuttaa virheitä ehdotushintoihin tai tuotehierarkiaan '
          }
        >
          <WarningIcon
            className={classes.itemCellIconStyle}
            style={{
              color: 'rgb(243 181 4)',
            }}
          />
        </DefaultTooltip>
      );
    case ItemStatus.ItemNotInPim:
      return (
        <DefaultTooltip
          title={
            'Tuotteelta puuttuu arvoja, mikä voi aiheuttaa virheitä ehdotushintoihin tai tuotehierarkiaan '
          }
        >
          <WarningIcon
            className={classes.itemCellIconStyle}
            style={{
              color: 'rgb(243 181 4)',
            }}
          />
        </DefaultTooltip>
      );
    default:
      return (
        <DefaultTooltip title={`Tuote valmis hinnastoon`}>
          <CheckCircleIcon
            className={classes.itemCellIconStyle}
            style={{
              color: theme.palette.success.main,
            }}
          />
        </DefaultTooltip>
      );
  }
};

interface ItemStatusRendererProps {
  data: PricingSheetRow;
}

const ItemStatusRenderer: React.FC<ItemStatusRendererProps> = ({
  data,
}) => {
  const row = data ? clone(data) : undefined;

  const classes = itemStatusCellClasses();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const {
    pimUpdatedAt,
    pimCategory,
    pimProductGroup,
    pimProductLine,
    catClass,
    name,
    pimDisplayName,
    rentalReadyPrice,
    depreciationPeriod,
    targetUtilRate,
    dayPriceCoefficient1,
    dayPriceCoefficient2,
    dayPriceCoefficient3,
    dayPriceCoefficient4,
    dayPriceCoefficient5,
    dayPriceCoefficient6,
    dayPriceCoefficient7,
    monthPriceCoefficient1,
    monthPriceCoefficient2,
    monthPriceCoefficient3,
    monthPriceCoefficient4,
    monthPriceCoefficient5,
    monthPriceCoefficient6,
    monthPriceCoefficient7,
  } = { ...row };

  const handleClose = () => {
    setOpen(false);
  };

  const isPimHierarchyDefined = () =>
    pimCategory || pimProductGroup || pimProductLine;

  const isCoefficientsDefined = () =>
    dayPriceCoefficient1 &&
    dayPriceCoefficient2 &&
    dayPriceCoefficient3 &&
    dayPriceCoefficient4 &&
    dayPriceCoefficient5 &&
    dayPriceCoefficient6 &&
    dayPriceCoefficient7 &&
    monthPriceCoefficient1 &&
    monthPriceCoefficient2 &&
    monthPriceCoefficient3 &&
    monthPriceCoefficient4 &&
    monthPriceCoefficient5 &&
    monthPriceCoefficient6 &&
    monthPriceCoefficient7;

  const isPricingParametersDefined = () =>
    targetUtilRate && depreciationPeriod && rentalReadyPrice;

  const getItemStatus = () => {
    if (!isPimHierarchyDefined()) {
      return ItemStatus.MissingPimHierarchy;
    }
    if (!isCoefficientsDefined() || !isPricingParametersDefined()) {
      return ItemStatus.MissingCoefficient;
    }
    if (pimUpdatedAt === null) {
      return ItemStatus.ItemNotInPim;
    }
    return ItemStatus.OK;
  };

  const cellValue = (value?: string | number) =>
    value || value === 0 ? value : '-';

  const coefficientCellValue = (value?: number) =>
    value ? value / 100 : '-';

  return (
    <>
      <Dialog
        disableScrollLock
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle>
          <StatusIcon itemStatus={getItemStatus()} />
          {`${catClass} - ${pimDisplayName || name || ' - '}`}
          {row?.removedAt && (
            <Typography
              color={theme.palette.error.main}
            >{`Tuote poistunut käytöstä ${formatDateTolocaleDateString(
              row?.removedAt,
            )}`}</Typography>
          )}
        </DialogTitle>
        <DialogContent style={{ padding: 24, minWidth: 1200 }}>
          <h3>
            {pimUpdatedAt ? (
              <DefaultTooltip
                title={`Tuotteen tiedot haettu PIM:stä
            ${pimUpdatedAt && formatDateTolocaleDateString(pimUpdatedAt)}`}
              >
                <CheckCircleIcon
                  className={classes.statusDialogIconStyle}
                  style={{
                    color: theme.palette.success.main,
                  }}
                />
              </DefaultTooltip>
            ) : isPimHierarchyDefined() ? (
              <DefaultTooltip title={`Tuotetta ei ole PIM:ssä`}>
                <WarningIcon
                  className={classes.statusDialogIconStyle}
                  style={{
                    color: 'rgb(243 181 4)',
                  }}
                />
              </DefaultTooltip>
            ) : (
              <DefaultTooltip
                title={`Tuotteelta puuttuu PIM-tuotehierarkia`}
              >
                <Cancel
                  className={classes.statusDialogIconStyle}
                  style={{
                    color: theme.palette.error.main,
                  }}
                />
              </DefaultTooltip>
            )}
            PIM
            {pimUpdatedAt && (
              <Typography color="text.secondary">{`Synkronoitu ${formatDateTolocaleDateString(
                pimUpdatedAt,
              )}`}</Typography>
            )}
          </h3>
          <Table style={{ maxWidth: 800 }}>
            <TableHead>
              <MuiTableRow>
                {['Tuoteryhmä', 'Tuotelinja', 'Kategoria'].map(
                  (headerValue, index) => (
                    <TableCell
                      key={index}
                      style={{ fontWeight: 'bold' }}
                    >
                      {headerValue}
                    </TableCell>
                  ),
                )}
              </MuiTableRow>
            </TableHead>
            <TableBody>
              <MuiTableRow>
                <TableCell>{cellValue(pimProductGroup)}</TableCell>
                <TableCell>{cellValue(pimProductLine)}</TableCell>
                <TableCell>{cellValue(pimCategory)}</TableCell>
              </MuiTableRow>
            </TableBody>
          </Table>
          <h3>
            {isPricingParametersDefined() ? (
              <CheckCircleIcon
                className={classes.statusDialogIconStyle}
                style={{
                  color: theme.palette.success.main,
                }}
              />
            ) : (
              <WarningIcon
                className={classes.statusDialogIconStyle}
                style={{
                  color: 'rgb(243 181 4)',
                }}
              />
            )}
            Hinnoitteluparametrit
          </h3>

          <Table style={{ maxWidth: 800 }}>
            <TableHead>
              <MuiTableRow>
                {[
                  'Rental ready',
                  'Tavoitekäyttöaste',
                  'Poistohinta',
                ].map((cellValue, index) => (
                  <TableCell
                    key={index}
                    style={{ fontWeight: 'bold' }}
                  >
                    {cellValue}
                  </TableCell>
                ))}
              </MuiTableRow>
            </TableHead>
            <TableBody>
              <MuiTableRow>
                <TableCell>
                  {cellValue(rentalReadyPrice)}
                  {rentalReadyPrice && '€'}
                </TableCell>
                <TableCell>
                  {cellValue(targetUtilRate)}
                  {!!targetUtilRate && '%'}
                </TableCell>
                <TableCell>
                  {cellValue(depreciationPeriod)}{' '}
                  {depreciationPeriod && 'kk'}
                </TableCell>
              </MuiTableRow>
            </TableBody>
          </Table>
          <h3>
            {isCoefficientsDefined() ? (
              <CheckCircleIcon
                className={classes.statusDialogIconStyle}
                style={{
                  color: theme.palette.success.main,
                }}
              />
            ) : (
              <WarningIcon
                className={classes.statusDialogIconStyle}
                style={{
                  color: 'rgb(243 181 4)',
                }}
              />
            )}
            Hintakertoimet
          </h3>
          <Table>
            <TableHead>
              <MuiTableRow>
                {[
                  '',
                  'Hintakategoria 1',
                  'Hintakategoria 2',
                  'Hintakategoria 3',
                  'Hintakategoria 4',
                  'Hintakategoria 5',
                  'Hintakategoria 6',
                  'Hintakategoria 7',
                ].map((cellValue, index) => (
                  <TableCell
                    key={index}
                    style={{ fontWeight: 'bold' }}
                  >
                    {cellValue}
                  </TableCell>
                ))}
              </MuiTableRow>
            </TableHead>
            <TableBody>
              <MuiTableRow>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {'Päivä'}
                </TableCell>
                {[
                  coefficientCellValue(dayPriceCoefficient1),
                  coefficientCellValue(dayPriceCoefficient2),
                  coefficientCellValue(dayPriceCoefficient3),
                  coefficientCellValue(dayPriceCoefficient4),
                  coefficientCellValue(dayPriceCoefficient5),
                  coefficientCellValue(dayPriceCoefficient6),
                  coefficientCellValue(dayPriceCoefficient7),
                ].map((cellValue, index) => (
                  <TableCell key={index}>{cellValue}</TableCell>
                ))}
              </MuiTableRow>
              <MuiTableRow>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {'Kuukausi'}
                </TableCell>
                {[
                  coefficientCellValue(monthPriceCoefficient1),
                  coefficientCellValue(monthPriceCoefficient2),
                  coefficientCellValue(monthPriceCoefficient3),
                  coefficientCellValue(monthPriceCoefficient4),
                  coefficientCellValue(monthPriceCoefficient5),
                  coefficientCellValue(monthPriceCoefficient6),
                  coefficientCellValue(monthPriceCoefficient7),
                ].map((cellValue, index) => (
                  <TableCell key={index}>{cellValue}</TableCell>
                ))}
              </MuiTableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Sulje</Button>
        </DialogActions>
      </Dialog>
      <div>
        <div
          style={{ display: 'inline' }}
          onClick={() => setOpen(true)}
        >
          <StatusIcon itemStatus={getItemStatus()} />
        </div>
      </div>
    </>
  );
};

export default ItemStatusRenderer;
