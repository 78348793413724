import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const GenericContainer: React.FC = (props) => {
  const theme = useTheme();

  return (
    <Container
      maxWidth={false}
      sx={{
        [theme.breakpoints.down('xl')]: {
          width: '100%',
        },
        width: '92.5%',
        backgroundColor: 'white',
        borderRadius: 2,
        paddingTop: 4,
        paddingBottom: 2,
        marginTop: 4,
      }}
    >
      {props.children}
    </Container>
  );
};

export default GenericContainer;
