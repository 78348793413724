import styled from '@emotion/styled';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { PricingSheetRow } from '../../../../../shared/types';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useContext } from 'react';
import RowsProvider from '../../../providers/Rows/RowsProvider';

export type CriticalEquipmentActionRendererParams =
  ICellRendererParams<PricingSheetRow> & {
    showClearAction?: boolean;
  };

const CriticalEquipmentActionRenderer = ({
  api,
  data,
  node,
  showClearAction = true,
}: CriticalEquipmentActionRendererParams) => {
  const { dispatchPricingSheetRows } = useContext(RowsProvider);

  const handleClear = () => {
    dispatchPricingSheetRows({
      id: data?.catClass,
      type: 'updateRow',
      row: {
        ...data,
        criticalEquipmentItems: [],
      },
    });
    // we have to redraw the row that was edited, so that colSpan is updated in colDefs
    setTimeout(() => {
      api.redrawRows({ rowNodes: [node] });
    });
  };

  return (
    <ActionCell>
      {showClearAction && (
        <ActionButton size={'small'} onClick={handleClear}>
          <ClearIcon color={'error'} />
        </ActionButton>
      )}
    </ActionCell>
  );
};

const ActionCell = styled.div`
  align-items: center;
  display: grid;
  flex: 0;
  gap: 0.5rem;
  grid-auto-flow: column;
  justify-content: center;
`;

const ActionButton = styled(IconButton)`
  svg {
    height: 20px;
    width: 20px;
  }
`;
export default CriticalEquipmentActionRenderer;
