import { unionBy } from 'lodash';
import React, { ReactNode, useContext, useRef } from 'react';
import { countOfferProposalPrice } from '../../../../shared/countOfferProposalPrice';
import {
  AgGridColumnView,
  OfferItem,
  TableType,
} from '../../../../shared/types';
import {
  DatabaseOfferItem,
  DatabaseOfferWithItems,
} from '../../../../shared/types/offers';
import AgGrid from '../../components/PricingTable/AgGrid';
import { useColumns } from '../../hooks/useColumns';
import { useItems } from '../../hooks/useItems';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import {
  getSessionStorageArray,
  removeFromSessionStorageArray,
  StorageKeys,
} from '../../stores/sessionStorage';
import { getUpdatedOfferItems, NotificationText } from './helpers';

import OfferStepActionContainer from './OfferStepActionContainer';
import { OfferStepTitle } from './OfferStepTitle';
import { useUpdateOfferItem } from '../../hooks/useUpdateOfferItem';
import { useUpdateOfferItems } from '../../hooks/useUpdateOfferItems';
import { useDeleteOfferItems } from '../../hooks/useDeleteOfferItems';
import { WarningAmber } from '@mui/icons-material';
import { sortByItemType } from '../../../../shared/sortByItemType';

type OfferItemDetailsProps = {
  offerSheet: DatabaseOfferWithItems;
  renderStepActions: () => ReactNode;
};

export const OfferItemDetails = ({
  offerSheet,
  renderStepActions = () => null,
}: OfferItemDetailsProps) => {
  const { id } = offerSheet;
  const { setNotification } = useContext(NotificationProvider);

  const offerSheetItemsRef = useRef<OfferItem[]>([]);
  const offerSheetItemsDatabaseRef = useRef<DatabaseOfferItem[]>([]);

  const { data: offerSheetItems = [], isFetching: isLoadingItems } =
    useItems({
      includeHidden: true,
      offerSheetItemsOnly: true,
      offerSheet,
    });

  offerSheetItemsRef.current = offerSheetItems;
  offerSheetItemsRef.current.sort(sortByItemType).reverse();
  offerSheetItemsDatabaseRef.current = offerSheet.items;

  // delete offer sheet item(s) mutation
  const { mutate: deleteOfferItems } = useDeleteOfferItems({
    offerSheetId: id,
  });
  // update offer sheet -ITEM- mutation
  const { mutate: updateOfferItem } = useUpdateOfferItem();
  // update offer sheet -ITEMS- mutation
  const { mutate: updateOfferItems } = useUpdateOfferItems();

  const handleLeaseChange = (newRow: OfferItem) => {
    const { leasePeriodStart, leasePeriodEnd } = newRow;
    const updatedItem = offerSheetItemsDatabaseRef.current.find(
      (item) => item.id === newRow.offerItemId,
    );

    const proposalPrices = countOfferProposalPrice({
      item: newRow,
      priceCategory: offerSheet.priceCategory,
      industry: offerSheet.industry,
      offerLeasePeriodStart: new Date(leasePeriodStart),
      offerLeasePeriodEnd: new Date(leasePeriodEnd),
    });

    if (
      leasePeriodStart !== updatedItem?.leasePeriodStart ||
      leasePeriodEnd !== updatedItem?.leasePeriodEnd
    ) {
      handleUpdateRowValue({
        ...proposalPrices,
        dayPrice:
          updatedItem?.proposalDayPrice === updatedItem?.dayPrice
            ? proposalPrices.proposalDayPrice
            : updatedItem?.dayPrice,
        monthPrice:
          updatedItem?.proposalMonthPrice === updatedItem?.monthPrice
            ? proposalPrices.proposalMonthPrice
            : updatedItem?.monthPrice,
        proposalDayPrice: proposalPrices.proposalDayPrice,
        proposalMonthPrice: proposalPrices.proposalMonthPrice,
      });
    } else {
      handleUpdateRowValue(newRow);
    }
  };

  const handleUpdateRowValue = ({
    comment,
    dayPrice,
    leasePeriodEnd,
    leasePeriodStart,
    monthPrice,
    offerItemId,
    pricingBasis,
    proposalDayPrice,
    proposalMonthPrice,
    quantity,
    unit,
    includeRamiturva,
    ramiturvaDayPrice,
    ramiturvaMonthPrice,
    ramiturvaProposalDayPrice,
    ramiturvaProposalMonthPrice,
  }: OfferItem) => {
    updateOfferItem({
      id: String(offerItemId),
      item: {
        comment,
        dayPrice,
        leasePeriodEnd,
        leasePeriodStart,
        monthPrice,
        pricingBasis,
        proposalDayPrice,
        proposalMonthPrice,
        quantity,
        unit,
        includeRamiturva,
        ramiturvaDayPrice,
        ramiturvaMonthPrice,
        ramiturvaProposalDayPrice,
        ramiturvaProposalMonthPrice,
      },
    });
  };

  const handleMultipleRowValueUpdates = (
    updatedRows: OfferItem[],
  ) => {
    const updatedItems = unionBy(
      updatedRows,
      offerSheetItems,
      'offerItemId',
    );
    const updatedItemIds = updatedRows.map(({ id }) => id);
    const updatedDatabaseOfferItems = offerSheet.items.filter(
      ({ itemId }) => itemId && updatedItemIds.includes(itemId),
    );
    updateOfferItems({
      id: String(id),
      items: getUpdatedOfferItems(
        updatedDatabaseOfferItems,
        updatedItems,
      ),
    });
    setNotification({
      type: 'SNACKBAR',
      duration: 3000,
      severity: 'success',
      message: 'Tuotteiden arvot päivitetty!',
    });
  };

  const handleCopyRow = (newRowCopy: OfferItem) => {
    const existingItem = offerSheetItemsDatabaseRef.current.find(
      (item) => item.id === newRowCopy.offerItemId,
    );

    if (existingItem) {
      const { id, ...itemWithoutId } = existingItem;
      const updatedOfferItems = [
        ...offerSheetItemsDatabaseRef.current,
        { ...itemWithoutId },
      ];
      updateOfferItems({
        id: String(itemWithoutId.offerSheetId),
        items: updatedOfferItems,
      });
    }
  };

  const handleRemoveRow = (removedRow: OfferItem) => {
    const removedId = removedRow.offerItemId;
    if (!removedId) return;
    const length = offerSheetItemsDatabaseRef.current.reduce(
      (count, item) =>
        item.itemId === removedRow.id ? count + 1 : count,
      0,
    );
    const name = removedRow.name || removedRow.pimDisplayName;

    // Show a modal if the last item of itemId X is being removed
    if (length === 1) {
      setNotification({
        type: 'MODAL',
        severity: 'info',
        message: `Olet poistamassa viimeistä tuotetta: ${removedRow.catClass} - ${name}`,
        title: 'Haluatko jatkaa?',
        onAccept: () => {
          deleteOfferItems({
            id: [removedId],
          });
          setNotification({
            type: 'SNACKBAR',
            severity: 'success',
            message: 'Tuote poistettu onnistuneesti',
            duration: 3000,
          });
        },
      });
    } else {
      deleteOfferItems({
        id: [removedId],
      });
      setNotification({
        type: 'SNACKBAR',
        severity: 'success',
        message: 'Tuote poistettu onnistuneesti',
        duration: 3000,
      });
    }
  };

  const { columnDefs, gridColumnView } = useColumns(
    AgGridColumnView.OFFER_ITEMDETAILS,
    {
      handleUpdateRowValue,
      handleRemoveRow,
      handleCopyRow,
      handleLeaseChange,
      language: offerSheet.language,
      includeRamiturvaOffer: offerSheet.includeRamiturva,
    },
  );

  // If user changes leasePeriod from offerDetails, show modal where user
  // can update the values to match the leasePeriod
  if (
    getSessionStorageArray(
      StorageKeys.ShowLeaseDateChangedDialog,
    ).includes(offerSheet.id)
  ) {
    removeFromSessionStorageArray(
      StorageKeys.ShowLeaseDateChangedDialog,
      offerSheet.id,
    );
    if (
      offerSheetItemsRef.current.some(
        (item) =>
          String(item.leasePeriodStart) !==
            offerSheet.offerLeasePeriodStart ||
          String(item.leasePeriodEnd) !==
            offerSheet.offerLeasePeriodEnd,
      )
    ) {
      setNotification({
        type: 'MODAL',
        severity: 'info',
        message: `Tarjouksen vuokra-aika on muuttunut. Muutetaanko tuotteiden alkamis- ja päättymisaika vastaamaan tarjouksen vuokra-aikaa?`,
        cancelText: 'Älä vaihda',
        acceptText: 'Vaihda',
        title: 'Muutos vuokra-ajassa',
        onAccept: () => {
          const updatedRows = offerSheetItemsRef.current.map(
            (item) => ({
              ...item,
              leasePeriodStart: new Date(
                offerSheet.offerLeasePeriodStart,
              ),
              leasePeriodEnd: new Date(
                offerSheet.offerLeasePeriodEnd,
              ),
            }),
          );
          handleMultipleRowValueUpdates(updatedRows);
        },
      });
    }
  }

  return (
    <>
      <OfferStepActionContainer>
        {renderStepActions()}
      </OfferStepActionContainer>
      <OfferStepTitle
        title={'Muokkaa tuotteiden tietoja'}
        offerSheet={offerSheet}
      />
      {!isLoadingItems && offerSheetItems.length === 0 && (
        <NotificationText>
          <WarningAmber />
          Tuotteita ei löytynyt!
        </NotificationText>
      )}
      {!isLoadingItems && offerSheetItems.length > 0 && (
        <AgGrid
          autoHeight
          fullWidthProductCell
          hideSideBar
          gridColumnView={gridColumnView}
          columnDefs={columnDefs}
          type={TableType.OfferItemDetails}
          rows={offerSheetItems}
          loading={false}
          externalFilters={[]}
          isSelectable={true}
        />
      )}
    </>
  );
};
