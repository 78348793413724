import React, { useContext, useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';

import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import RowsProvider from '../../providers/Rows/RowsProvider';

import {
  AgGridColumnView,
  ItemIndustryPercent,
  NewItemIndustryPercent,
  PricingSheetRow,
} from '../../../../shared/types';

import { RowsHistoryType } from '../../hooks/useRowsHistory';

import CommonTab from './AdminDialogTabs/CommonTab';

import ItemsEditSummaryTable from './AdminDialogTabs/ItemsEditSummaryTable';

import SelectChangeType from './SelectChangeType';

import { Nullable, IndustryName } from '../../../../shared/types';

import {
  createIndustryPercentsRow,
  updateIndustryPercentsRow,
  updateRow,
} from './AdminDialogTabs/helpers/constructors';

import SelectIndustry from '../../pages/Admin/SelectIndustry';

import useItemIndustryPercentsContext from '../../providers/Rows/ItemIndustryPercents/ItemIndustryPercentsProvider';

import {
  initialDayPriceCoefficients,
  initialMonthPriceCoefficients,
  initialDayIndustryPercents,
  initialMonthIndustryPercents,
} from './AdminDialogTabs/initials';
import { ChangeType } from './AdminDialogTabs/types';
import CoefficientsTab from './AdminDialogTabs/CoefficientsTab';
import IndustryPercentagesTab from './AdminDialogTabs/IndustryPercentagesTab';
import SelectedItemsList from '../Common/SelectedRowsList';
import CircularProgress from '@mui/material/CircularProgress';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`edit-items-tabpanel-${index}`}
      aria-labelledby={`edit-items-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2, pb: 2 }}>{children}</Box>}
    </div>
  );
};

const tabProps = (index: number) => {
  return {
    id: `edit-items-tab-${index}`,
    'aria-controls': `edit-items-tabpanel-${index}`,
  };
};

interface EditItemsDialogProps {
  saveItems: (
    rows: PricingSheetRow[],
    itemIndustryPercents: {
      new: NewItemIndustryPercent[] | undefined;
      changed: ItemIndustryPercent[] | undefined;
    },
    moveInHistory?: boolean,
  ) => Promise<void>;
  setCurrentRowsState: React.Dispatch<
    React.SetStateAction<[] | PricingSheetRow[]>
  >;
  getRowsSortedByCatClass: (
    items: PricingSheetRow[],
  ) => PricingSheetRow[];
  rowsHistory: RowsHistoryType[];

  isUnsavedChanges: boolean;
  updateTimeStampToNewestChange: () => void;

  clearAllSelections: () => void;
  gridColumnView: AgGridColumnView;
  industry: IndustryName;
  industryIndex: number;
  setIndustry: React.Dispatch<React.SetStateAction<IndustryName>>;
  updateGridColumnView: (view: AgGridColumnView) => void;
}

const EditItemsDialog: React.FC<EditItemsDialogProps> = ({
  saveItems,
  setCurrentRowsState,
  getRowsSortedByCatClass,
  rowsHistory,
  isUnsavedChanges,
  updateTimeStampToNewestChange,
  clearAllSelections,
  industry,
  industryIndex,
  setIndustry,
  gridColumnView,
  updateGridColumnView,
}) => {
  const { pricingSheetRows, selectedRows, setSelectedRows } =
    useContext(RowsProvider);
  const { itemIndustryPercents } = useItemIndustryPercentsContext();

  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    clearAllInputs();
    setOpen(false);
  };

  const clearAllInputs = () => {
    setRentalReadyPrice({
      absoluteValue: undefined,
      percentValue: undefined,
    });
    setTargetUtilRate({
      absoluteValue: undefined,
      percentValue: undefined,
    });
    setDepreciationPeriod({
      absoluteValue: undefined,
      percentValue: undefined,
    });

    setDayPriceCoefficients(initialDayPriceCoefficients);
    setMonthPriceCoefficients(initialMonthPriceCoefficients);
    setDayPriceIndustryPercents(initialDayIndustryPercents);
    setMonthPriceIndustryPercents(initialMonthIndustryPercents);
  };

  useEffect(() => {
    if (rowsHistory.length > 0 && !isUnsavedChanges) {
      updateTimeStampToNewestChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsHistory]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setTabIndex(newValue);
  };

  const [rentalReadyPrice, setRentalReadyPrice] = useState<{
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  }>({ absoluteValue: undefined, percentValue: undefined });

  const [targetUtilRate, setTargetUtilRate] = useState<{
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  }>({ absoluteValue: undefined, percentValue: undefined });

  const [depreciationPeriod, setDepreciationPeriod] = useState<{
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  }>({ absoluteValue: undefined, percentValue: undefined });

  const [dayPriceCoefficients, setDayPriceCoefficients] = useState(
    initialDayPriceCoefficients,
  );
  const [monthPriceCoefficients, setMonthPriceCoefficients] =
    useState(initialMonthPriceCoefficients);
  const [dayPriceIndustryPercents, setDayPriceIndustryPercents] =
    useState(initialDayIndustryPercents);
  const [monthPriceIndustryPercents, setMonthPriceIndustryPercents] =
    useState(initialMonthIndustryPercents);

  const [changeTypeCommon, setChangeTypeCommon] =
    useState<ChangeType>(ChangeType.ABSOLUTE);
  const [changeTypeCoefficients, setChangeTypeCoefficients] =
    useState<ChangeType>(ChangeType.ABSOLUTE);
  const [changeTypeIndustryPercents, setChangeTypeIndustryPercents] =
    useState<ChangeType>(ChangeType.ABSOLUTE);

  const changeValues = async (rows: PricingSheetRow[]) => {
    setSaving(true);
    try {
      const selectedCatClasses = rows.map(({ catClass }) => catClass);
      // updated pricingSheetRows without itemIndustryPercents
      const updatedPricingSheetRows = pricingSheetRows.map(
        (pricingSheetRow: PricingSheetRow) => {
          return selectedCatClasses.includes(pricingSheetRow.catClass)
            ? updateRow({
                changeTypeCommon,
                changeTypeCoefficients,
                dayPriceCoefficients,
                monthPriceCoefficients,
                rentalReadyPrice,
                targetUtilRate,
                depreciationPeriod,
                pricingSheetRow,
              })
            : pricingSheetRow;
        },
      );
      // group industry percent items by catClass, so that it's easier to update the changed rows
      const selectedIndustryPercentItemsGroupedByCatClass =
        itemIndustryPercents
          // filter out rows that aren't selected
          ?.filter((item) =>
            selectedCatClasses.includes(item.catClass),
          )
          // group selected rows by cat class
          .reduce(
            (acc, item) => {
              const existingCatClassItems = acc[item.catClass];
              return {
                ...acc,
                [item.catClass]: existingCatClassItems
                  ? [...existingCatClassItems, item]
                  : [item],
              };
            },
            {} as Record<string, ItemIndustryPercent[]>,
          );
      // holds new industry percent items that should be created
      const createdItemIndustryPercents: NewItemIndustryPercent[] =
        [];
      // get changed industry percent items
      const changedItemIndustryPercents = Object.values(
        selectedIndustryPercentItemsGroupedByCatClass || {},
      ).flatMap((items) => {
        const existingIndustryPercentRowIndex = items.findIndex(
          ({ industryId }) => industryId === industryIndex,
        );
        // update existing industry values
        if (existingIndustryPercentRowIndex >= 0) {
          return updateIndustryPercentsRow({
            changeType: changeTypeIndustryPercents,
            dayPriceIndustryPercents,
            monthPriceIndustryPercents,
            itemIndustryPercentRow:
              items[existingIndustryPercentRowIndex],
          });
        }
        // newly created item row for selected industry
        const createdRow = createIndustryPercentsRow({
          dayPriceIndustryPercents,
          monthPriceIndustryPercents,
          industryName: industry,
          industryId: industryIndex,
          row: items[0],
        });
        createdItemIndustryPercents.push(createdRow);
        // created items are handled separately, so we return an empty array
        return [];
      });

      // handle catClasses that don't have existing industry percents
      (pricingSheetRows as PricingSheetRow[])
        // filter out rows that aren't selected or ones that have existing industry percent items
        .filter(
          ({ catClass }) =>
            selectedCatClasses.includes(catClass) &&
            !selectedIndustryPercentItemsGroupedByCatClass?.[
              catClass
            ],
        )
        .forEach((item) => {
          // newly created item row for selected industry
          const createdRow = createIndustryPercentsRow({
            dayPriceIndustryPercents,
            monthPriceIndustryPercents,
            industryName: industry,
            industryId: industryIndex,
            row: item,
          });
          createdItemIndustryPercents.push(createdRow);
        });

      await saveItems(
        updatedPricingSheetRows,
        {
          changed: changedItemIndustryPercents,
          new: createdItemIndustryPercents,
        },
        true,
      );
      setCurrentRowsState([
        ...getRowsSortedByCatClass(updatedPricingSheetRows),
      ]);
      setSelectedRows([]); // provider
      clearAllSelections(); // grid
      setHighlightedItem(undefined);
      handleClose();
    } finally {
      setSaving(false);
    }
  };

  const handleChangeTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'COMMON' | 'COEFFICIENTS' | 'ITEMINDUSTRYPERCENTS',
  ) => {
    if (type === 'COMMON') {
      setChangeTypeCommon(
        (event.target as HTMLInputElement).value as ChangeType,
      );
    } else if (type === 'COEFFICIENTS') {
      setChangeTypeCoefficients(
        (event.target as HTMLInputElement).value as ChangeType,
      );
    } else {
      setChangeTypeIndustryPercents(
        (event.target as HTMLInputElement).value as ChangeType,
      );
    }
  };

  const [highlightedItem, setHighlightedItem] =
    useState<PricingSheetRow>();

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        Muokkaa valittuja
      </Button>
      <Dialog
        disableScrollLock
        maxWidth={'xl'}
        fullWidth
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle>Muokkaa valittuja</DialogTitle>

        <Fade in={open}>
          <DialogContent>
            {selectedRows.length > 0 ? (
              <>
                <Typography id="edit-prices-header">
                  Olet muokkaamassa seuraavien tuotteiden arvoja,
                  (yht. <b>{selectedRows.length}</b> kpl)
                </Typography>
                <SelectedItemsList
                  selectedItems={selectedRows}
                  primaryText={'catClass'}
                  secondaryText={'name'}
                  selected={(item) =>
                    highlightedItem?.catClass === item.catClass
                  }
                  onClick={(item) =>
                    highlightedItem?.catClass === item.catClass
                      ? setHighlightedItem(undefined)
                      : setHighlightedItem(item)
                  }
                />
                <>
                  <Box sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                      }}
                    >
                      <Tabs
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label="admin-dialog-tabs"
                      >
                        <Tab label="Yleinen" {...tabProps(0)} />
                        <Tab label="Kertoimet" {...tabProps(1)} />
                        <Tab
                          label="Toimialamuutos%"
                          {...tabProps(2)}
                        />
                      </Tabs>
                    </Box>
                    {/* COMMON */}
                    <TabPanel value={tabIndex} index={0}>
                      <Box sx={{ mb: 2 }}>
                        <SelectChangeType
                          changeType={changeTypeCommon}
                          handleChangeTypeChange={(e) =>
                            handleChangeTypeChange(e, 'COMMON')
                          }
                        />
                      </Box>
                      <CommonTab
                        changeType={changeTypeCommon}
                        targetUtilRate={targetUtilRate}
                        setTargetUtilRate={setTargetUtilRate}
                        depreciationPeriod={depreciationPeriod}
                        setDepreciationPeriod={setDepreciationPeriod}
                        rentalReadyPrice={rentalReadyPrice}
                        setRentalReadyPrice={setRentalReadyPrice}
                      />
                    </TabPanel>
                    {/* PRICE COEFFICIENTS */}
                    <TabPanel value={tabIndex} index={1}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Box sx={{ mb: 2 }}>
                          <SelectChangeType
                            changeType={changeTypeCoefficients}
                            handleChangeTypeChange={(e) =>
                              handleChangeTypeChange(
                                e,
                                'COEFFICIENTS',
                              )
                            }
                          />
                        </Box>
                        <Box>
                          {/* DAY COEFFICIENTS */}
                          <CoefficientsTab
                            pricingBasis={'DAY'}
                            changeType={changeTypeCoefficients}
                            changeTypeCommon={changeTypeCommon}
                            targetUtilRate={targetUtilRate}
                            depreciationPeriod={depreciationPeriod}
                            rentalReadyPrice={rentalReadyPrice}
                            highlightedItem={highlightedItem}
                            priceCoefficients={dayPriceCoefficients}
                            setPriceCoefficients={
                              setDayPriceCoefficients
                            }
                          />
                          {/* MONTH COEFFICIENTS */}
                          <CoefficientsTab
                            pricingBasis={'MONTH'}
                            changeType={changeTypeCoefficients}
                            changeTypeCommon={changeTypeCommon}
                            targetUtilRate={targetUtilRate}
                            depreciationPeriod={depreciationPeriod}
                            rentalReadyPrice={rentalReadyPrice}
                            highlightedItem={highlightedItem}
                            priceCoefficients={monthPriceCoefficients}
                            setPriceCoefficients={
                              setMonthPriceCoefficients
                            }
                          />
                        </Box>
                      </Box>
                    </TabPanel>
                    {/* INDUSTRY PERCENTS */}
                    <TabPanel value={tabIndex} index={2}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            mb: 2,
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {/* uncomment selector below to allow modifying industry percent values using percentage values  */}
                          {/*<SelectChangeType
                            changeType={changeTypeIndustryPercents}
                            handleChangeTypeChange={(e) =>
                              handleChangeTypeChange(
                                e,
                                'ITEMINDUSTRYPERCENTS',
                              )
                            }
                          />*/}
                          <SelectIndustry
                            industry={industry}
                            setIndustry={setIndustry}
                            gridColumnView={gridColumnView}
                            updateGridColumnView={
                              updateGridColumnView
                            }
                          />
                        </Box>
                        <Box>
                          {/* DAY PERCENTAGES */}
                          <IndustryPercentagesTab
                            pricingBasis={'DAY'}
                            changeType={changeTypeCoefficients}
                            changeTypeCommon={changeTypeCommon}
                            changeTypeIndustryPercents={
                              changeTypeIndustryPercents
                            }
                            targetUtilRate={targetUtilRate}
                            depreciationPeriod={depreciationPeriod}
                            rentalReadyPrice={rentalReadyPrice}
                            highlightedItem={highlightedItem}
                            industry={industry}
                            itemIndustryPercents={
                              itemIndustryPercents
                            }
                            priceCoefficients={dayPriceCoefficients}
                            priceIndustryPercents={
                              dayPriceIndustryPercents
                            }
                            setPriceIndustryPercents={
                              setDayPriceIndustryPercents
                            }
                          />
                          {/* MONTH PERCENTAGES */}
                          <IndustryPercentagesTab
                            pricingBasis={'MONTH'}
                            changeType={changeTypeCoefficients}
                            changeTypeCommon={changeTypeCommon}
                            changeTypeIndustryPercents={
                              changeTypeIndustryPercents
                            }
                            targetUtilRate={targetUtilRate}
                            depreciationPeriod={depreciationPeriod}
                            rentalReadyPrice={rentalReadyPrice}
                            highlightedItem={highlightedItem}
                            industry={industry}
                            itemIndustryPercents={
                              itemIndustryPercents
                            }
                            priceCoefficients={monthPriceCoefficients}
                            priceIndustryPercents={
                              monthPriceIndustryPercents
                            }
                            setPriceIndustryPercents={(value) =>
                              setMonthPriceIndustryPercents(value)
                            }
                          />
                        </Box>
                      </Box>
                    </TabPanel>
                  </Box>

                  <Alert icon={false} severity="info">
                    <Typography fontWeight="bold">
                      {highlightedItem ? (
                        <>
                          Muokataan arvoja seuraavasti, (
                          {highlightedItem?.name}
                          {', '}
                          {highlightedItem?.catClass})
                        </>
                      ) : (
                        'Valitse tuote klikkaamalla listasta'
                      )}
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <Box sx={{ ml: 2 }}>
                        <ItemsEditSummaryTable
                          changeTypeCommon={changeTypeCommon}
                          changeTypeCoefficients={
                            changeTypeCoefficients
                          }
                          changeTypeIndustryPercents={
                            changeTypeIndustryPercents
                          }
                          highlightedItem={highlightedItem}
                          targetUtilRate={targetUtilRate}
                          depreciationPeriod={depreciationPeriod}
                          rentalReadyPrice={rentalReadyPrice}
                          dayPriceCoefficients={dayPriceCoefficients}
                          monthPriceCoefficients={
                            monthPriceCoefficients
                          }
                          dayPriceIndustryPercents={
                            dayPriceIndustryPercents
                          }
                          monthPriceIndustryPercents={
                            monthPriceIndustryPercents
                          }
                          itemIndustryPercents={itemIndustryPercents}
                          industry={industry}
                        />
                      </Box>
                    </Box>
                  </Alert>
                </>

                <DialogActions>
                  <Button color="inherit" onClick={handleClose}>
                    Peruuta
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => changeValues(selectedRows)}
                    disabled={saving}
                    endIcon={
                      saving ? <CircularProgress size={16} /> : null
                    }
                  >
                    Tallenna muutokset
                  </Button>
                </DialogActions>
              </>
            ) : (
              <>
                <Typography
                  id="not-selected-items-warning"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Et ole valinnut muokattavia tuotteita!
                </Typography>
              </>
            )}
          </DialogContent>
        </Fade>
      </Dialog>
    </div>
  );
};

export default EditItemsDialog;
