import { addMonths, differenceInDays, differenceInMonths } from "date-fns";

export const calculateRemainingDays = (startDate: Date, endDate: Date) => {
  const endDateWithExtraDay = new Date(endDate).setDate(
    new Date(endDate).getDate() + 1,
  );

  const fullMonths = differenceInMonths(endDateWithExtraDay, startDate);

  const remainingDays = differenceInDays(
    endDateWithExtraDay,
    addMonths(startDate, fullMonths),
  );

  if (remainingDays < 0) {
    return { fullMonths, remainingDays: 0 };
  } else {
    return { fullMonths, remainingDays };
  }
};
