import { useContext } from 'react';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/material';
import { PricingSheetRow } from '../../../../../shared/types';

import RowsProvider from '../../../providers/Rows/RowsProvider';

import { clone } from '../../../utils/genericUtils';

import { PreSelectionType } from '../../../../../shared/types';

interface BelongsToPreSelectionRendererProps {
  data: PricingSheetRow;
  type: PreSelectionType;
  value: boolean;
}

const BelongsToPreSelectionRenderer: React.FC<
  BelongsToPreSelectionRendererProps
> = ({ data, type, value }) => {
  const row = data ? clone(data) : undefined;

  const { updateRow } = useContext(RowsProvider);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (type === PreSelectionType.COMPACT) {
      updateRow({
        ...row,
        belongsToCompactPreSelection: event.target.checked,
      } as PricingSheetRow);
    } else if (type === PreSelectionType.LARGE) {
      updateRow({
        ...row,
        belongsToLargePreSelection: event.target.checked,
      } as PricingSheetRow);
    }
  };

  return (
    <Box id={`${row?.catClass}`}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={value}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              size="small"
            />
          }
          label={value ? 'Kyllä' : 'Ei'}
        />
      </FormGroup>
    </Box>
  );
};

export default BelongsToPreSelectionRenderer;
