import { useContext } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import BackgroundProvider from '../../providers/Background/BackgroundProvider';

import {
  AgGridColumnView,
  PricingSheetRow,
  TableType,
} from '../../../../shared/types';
import RowsProvider from '../../providers/Rows/RowsProvider';
import SelectPreSelection from '../PricingTable/SelectPreSelection';
import { Divider, Grid } from '@mui/material';
import AgGrid from '../PricingTable/AgGrid';
import { useRef } from 'react';
import { useSelectedRows } from '../../hooks/useSelectedRows';
import { useEffect } from 'react';
import { useColumns } from '../../hooks/useColumns';
import EditViewDialog from '../PricingTable/EditViewDialog';
import SelectByOfferToggler from '../AgGrid/Components/SelectByOfferToggler';

const PreSelectStep: React.FC = () => {
  const { pricingSheetRows, dispatchPricingSheetRows } =
    useContext(RowsProvider);
  const { backgroundInfo } = useContext(BackgroundProvider);

  const {
    addSelectedRow,
    removeSelectedRow,
    getSelectedRows,
    selectedRows,
  } = useSelectedRows(pricingSheetRows);

  const {
    columnDefs,
    gridColumnView,
    groupingStyle,
    setDefaultGroupingValues,
    updateGroupingValues,
    groupingValues,
  } = useColumns(AgGridColumnView.PRESELECT);

  const agGridRef = useRef<any>();

  const selectedGridRows = getSelectedRows();

  const preSelectedItemsForPricing = pricingSheetRows.filter(
    (pricingSheetRow: PricingSheetRow) =>
      pricingSheetRow.preSelected && !pricingSheetRow.showByOfferText,
  );

  const preSelectedItemsByOffer = pricingSheetRows.filter(
    (pricingSheetRow: PricingSheetRow) =>
      pricingSheetRow.preSelected && pricingSheetRow.showByOfferText,
  );

  useEffect(() => {
    const selectedCC = Object.keys(selectedRows);
    const newRows = pricingSheetRows.map((row: any) => {
      return {
        ...row,
        preSelected: selectedCC.includes(row.catClass),
      };
    });
    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: newRows,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  return (
    <>
      <Box
        sx={{
          mt: 3,
        }}
      >
        <Typography
          sx={{ ml: 1, mt: 1, mb: 0 }}
          variant="h4"
          component="div"
          gutterBottom
          color="primary"
        >
          Hinnaston tuotteet
        </Typography>
        <Typography
          sx={{ ml: 1, mb: 2 }}
          variant="body1"
          color={'text.secondary'}
        >
          {backgroundInfo?.name}
        </Typography>

        <div style={{ margin: 'auto', width: '70%' }}>
          <SelectPreSelection
            selectedRows={selectedGridRows}
            rows={pricingSheetRows}
            handlePreSelectionByCatClass={
              agGridRef.current?.selectRowsByCatClass
            }
          />
          <Divider />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mt: 1,
              mb: 1,
            }}
          ></Box>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <p>
              Olet valinnut <b>{selectedGridRows.length}</b> tuotetta,{' '}
              {preSelectedItemsForPricing.length} hinnoiteltaltavaksi,{' '}
              {preSelectedItemsByOffer.length} tarjouksen mukaan.
            </p>
            <EditViewDialog
              groupingStyle={groupingStyle}
              setDefaultGroupingValues={setDefaultGroupingValues}
              updateGroupingValues={updateGroupingValues}
              groupingValues={groupingValues}
            />
          </Grid>
          <AgGrid
            ref={agGridRef}
            gridColumnView={gridColumnView}
            disableProviderUpdate={true}
            columnDefs={columnDefs}
            type={TableType.PreSelectTable}
            rows={pricingSheetRows}
            loading={false}
            externalFilters={[]}
            addSelectedRow={addSelectedRow}
            removeSelectedRow={removeSelectedRow}
            isSelectable={true}
            groupHeaderInnerRenderer={(
              row: any,
              filteredRows: PricingSheetRow[],
            ) => {
              return (
                <SelectByOfferToggler
                  toggleField={row.node.field}
                  toggleValue={row.node.key}
                  filteredRows={filteredRows}
                />
              );
            }}
            catClassInnerRenderer={(
              row: any,
              filteredRows: PricingSheetRow[],
            ) => {
              return (
                <SelectByOfferToggler
                  toggleField="catClass"
                  toggleValue={row.catClass}
                  filteredRows={filteredRows}
                />
              );
            }}
            customHeaderInnerRenderer={(
              filteredRows: PricingSheetRow[],
            ) => {
              const isAtLeastOnePreSelected =
                filteredRows.filter(
                  (row: PricingSheetRow) => row.preSelected,
                ).length > 0;
              return (
                <>
                  {isAtLeastOnePreSelected && (
                    <SelectByOfferToggler
                      toggleAll={true}
                      filteredRows={pricingSheetRows}
                    />
                  )}
                </>
              );
            }}
          />
        </div>
      </Box>
    </>
  );
};

export default PreSelectStep;
