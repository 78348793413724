import axios from 'axios';
import { processEnvs } from '../config/processEnvs';
import {
  ExportItemsQueryParams,
  ExportItemsResponseBody,
} from '../../../shared/types/export';

const api = axios.create({
  baseURL: processEnvs.BACKEND_URI,
  withCredentials: true,
});

type ExportItemsFnParams = Omit<ExportItemsQueryParams, 'columns'> & {
  columns: string[];
  fileName?: string;
};

export const exportItems = async ({
  columns = [],
  fileName = '',
  industry,
}: ExportItemsFnParams): Promise<boolean> => {
  try {
    const response = await api.get<ExportItemsResponseBody>(
      `/export/items`,
      {
        params: {
          columns: columns.join(','),
          industry,
        },
        responseType: 'arraybuffer',
      },
    );
    const fileURL = window.URL.createObjectURL(
      new Blob([response.data]),
    );
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute(
      'download',
      `${fileName || 'hinnastotyökalu-tuotteet'}.xlsx`,
    );
    link.click();
    return true;
  } catch (e) {
    console.error(`Failed to download items XLSX: ${e}`);
    return false;
  }
};
