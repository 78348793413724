interface MultiLineHeaderProps {
  header1: string;
  header2: string;
}

const MultiLineHeader: React.FC<MultiLineHeaderProps> = ({
  header1,
  header2,
}) => (
  <div>
    <div>{header1}</div>
    <div>{header2}</div>
  </div>
);

export default MultiLineHeader;
