import { MiraCustomerBlocking } from '../../../shared/types/customer';
import { getBlockedText } from './getCustomerBlockedText';

export const formatCustomerBlockingText = (
  blocking: MiraCustomerBlocking,
) => {
  const blocksList = getBlockedText(blocking);
  const { blockingReason, blockingComment } = blocking;
  return `Esto: 
    ${blocksList}

    Syy: 
    ${blockingReason}

    Kommentti: 
    ${blockingComment}
    `;
};
