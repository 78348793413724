type Options = {
  removeTime: boolean;
};

type DateAsIsoStringFnParams = (
  date: Date,
  options?: Partial<Options>,
) => string;

export const dateAsIsoString: DateAsIsoStringFnParams = (
  date: Date,
  options = { removeTime: false },
) =>
  options?.removeTime
    ? new Date(date.toDateString()).toISOString()
    : date.toISOString();
