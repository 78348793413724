import { PRICE_FACTOR } from "./constants";
import { countRemovalDayPrice } from "./countRemovalDayPrice";
import { countRemovalMonthPrice } from "./countRemovalMonthPrice";
import { roundToTwoDecimal } from "./roundToTwoDecimal";

export const calculateDayPrice = (
  rentalReadyPrice: number,
  depreciationPeriod: number,
  targetUtilRate: number,
  dayCoefficient: number,
) => {
  const removalDayPrice = countRemovalDayPrice(
    rentalReadyPrice,
    depreciationPeriod,
    targetUtilRate,
  );

  const calculatedProposalDayPrice = removalDayPrice
    ? roundToTwoDecimal(removalDayPrice * Number(dayCoefficient))
    : null;

  const proposalDayPrice =
    calculatedProposalDayPrice && removalDayPrice
      ? calculatedProposalDayPrice > removalDayPrice
        ? roundToTwoDecimal(calculatedProposalDayPrice)
        : roundToTwoDecimal(removalDayPrice)
      : null;

  return proposalDayPrice
    ? Math.round(proposalDayPrice * PRICE_FACTOR)
    : proposalDayPrice;
};

export const calculateMonthPrice = (
  rentalReadyPrice: number,
  depreciationPeriod: number,
  targetUtilRate: number,
  monthCoefficient: number,
) => {
  const removalMonthPrice = countRemovalMonthPrice(
    rentalReadyPrice,
    depreciationPeriod,
    targetUtilRate,
  );

  const calculatedProposalMonthPrice = removalMonthPrice
    ? roundToTwoDecimal(removalMonthPrice * Number(monthCoefficient))
    : null;

  const proposalMonthPrice =
    calculatedProposalMonthPrice && removalMonthPrice
      ? calculatedProposalMonthPrice > removalMonthPrice
        ? roundToTwoDecimal(calculatedProposalMonthPrice)
        : roundToTwoDecimal(removalMonthPrice)
      : null;

  return proposalMonthPrice
    ? Math.round(proposalMonthPrice * PRICE_FACTOR)
    : proposalMonthPrice;
};

export const calculatePrices = (
  rentalReadyPrice: number,
  depreciationPeriod: number,
  targetUtilRate: number,
  dayCoefficient: number,
  monthCoefficient: number,
) => {
  return {
    proposalDayPrice:
      calculateDayPrice(
        rentalReadyPrice,
        depreciationPeriod,
        targetUtilRate,
        dayCoefficient,
      ) ?? null,
    proposalMonthPrice:
      calculateMonthPrice(
        rentalReadyPrice,
        depreciationPeriod,
        targetUtilRate,
        monthCoefficient,
      ) ?? null,
  };
};
