import { Box, Icon, IconButton } from '@mui/material';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { StickyTableCell } from './DataTable';
import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { BoxProps } from '@mui/system';

export type DataTableRowActionsProps<T> = {
  item: T;
  open: boolean;
  renderRowActions: (props: {
    item: T;
    infoToggle: ReactNode;
  }) => ReactNode;
  setOpen: (open: boolean) => void;
};
export const DataTableRowActions = <T,>({
  item,
  open = false,
  renderRowActions = () => null,
  setOpen = () => null,
}: DataTableRowActionsProps<T>) => {
  // info toggle component
  const infoToggle = useMemo(
    () => (
      <IconButton size={'small'} onClick={() => setOpen(!open)}>
        <Icon
          component={open ? KeyboardArrowUp : KeyboardArrowDown}
        />
      </IconButton>
    ),
    [open, setOpen],
  );

  return (
    <StickyTableCell
      sx={{
        textAlign: 'center',
      }}
    >
      {renderRowActions({ item, infoToggle })}
    </StickyTableCell>
  );
};

export const DataTableRowActionsContainer = ({
  children,
  ...props
}: PropsWithChildren<BoxProps>) => (
  <Box
    {...props}
    sx={{
      alignItems: 'center',
      borderLeft: '1px solid #e0e0e0',
      display: 'flex',
      gap: 0.5,
      justifyContent: 'center',
      py: 0.5,
      '& > button': {
        // don't allow text wrapping for buttons
        flexShrink: 0,
      },
      ...props.sx,
    }}
  >
    {children}
  </Box>
);
