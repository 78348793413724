import { Box, Typography } from '@mui/material';
import { DatabaseOffer } from '../../../../shared/types/offers';
import { BoxProps } from '@mui/system';

type OfferStepTitleProps<T extends DatabaseOffer = DatabaseOffer> =
  BoxProps & {
    offerSheet?: T;
    title: string;
  };
export const OfferStepTitle = ({
  offerSheet,
  title,
  ...props
}: OfferStepTitleProps) => (
  <Box
    {...props}
    sx={{
      my: 2,
      ...props.sx,
    }}
  >
    <Typography variant={'h4'} fontSize={26} color={'primary'}>
      {title}
    </Typography>
    {offerSheet && (
      <Typography
        color={'text.secondary'}
        mt={0.5}
        textTransform={'uppercase'}
      >
        {offerSheet.name}
      </Typography>
    )}
  </Box>
);
