import discountDayGetter from '../valueGetters/discountDayGetter';

import currencyFormatter from '../formatters/priceFormatter';
import percentFormatter from '../formatters/percentFormatter';

import { getClassForAgGridCell } from '../../../helpers/cellHelpers';

import proposalDayPriceGetter from '../valueGetters/proposalDayPriceGetter';

import {
  PricingSheetRow,
  RoundingBasis,
} from '../../../../../shared/types';

import { dayPriceGetter } from '../valueGetters/dayPriceGetter';

import {
  CellClassParams,
  ICellRendererParams,
  CellStyle,
  ValueGetterParams,
} from 'ag-grid-community';
import CommentRenderer from '../Renderers/CommentRenderer';
import { GridColumnProps } from './ColumnsBase';
import { getCatClassColDef } from '../helpers/getCatClassColDef';

interface OptionalColumnProps {
  hideInspectComment: boolean;
  roundingBasisSheet: RoundingBasis;
}

const InspectColumnsDay = ({
  classes,
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps<OptionalColumnProps>) => {
  const columns = [
    ...getCatClassColDef(gridGroupingValues),
    {
      field: 'proposalDayPrice',
      headerName: 'EhdotusPVHinta',
      filter: 'agNumberColumnFilter',
      valueGetter: proposalDayPriceGetter,
      valueFormatter: currencyFormatter,
      headerTooltip: 'Työkalun ehdottama hinta päivähinnaksi',
      cellStyle: {
        backgroundColor: '#fafafa',
        borderLeft: '1px solid #ccc',
      } as CellStyle,
    },
    {
      field: 'discountDay',
      headerName: 'Alennus% (PV)',
      filter: 'agNumberColumnFilter',
      valueGetter: discountDayGetter,
      valueFormatter: percentFormatter,
      headerTooltip: 'Päivähinta verrattuna ehdotushintaan',
      cellClass: (params: CellClassParams) => {
        return getClassForAgGridCell(
          params.data,
          classes,
          'discountDay',
        );
      },
      cellStyle: {
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'dayPrice',
      headerName: 'PVHinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<PricingSheetRow>) =>
        dayPriceGetter(
          params,
          optionalColumnProps.roundingBasisSheet,
        ),
      valueFormatter: currencyFormatter,
      headerTooltip: 'Tuotteen päivähinta',
      cellClass: (params: CellClassParams) => {
        return getClassForAgGridCell(
          params.data,
          classes,
          'dayPrice',
        );
      },
      cellStyle: () => {
        return {
          color: '#000',
          borderLeft: '1px solid #ccc',
          borderRight: '1px solid #ccc',
        } as CellStyle;
      },
    },
    {
      field: 'comment',
      headerName: 'Kommentti',
      minWidth: 200,
      hide: optionalColumnProps.hideInspectComment,
      cellRenderer: (params: ICellRendererParams) => {
        return <CommentRenderer row={params?.data} />;
      },
    },
  ];
  return columns;
};

export default InspectColumnsDay;
