import { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/system';
import styled from '@emotion/styled';

const OfferStepActionContainer = ({
  children,
  ...props
}: PropsWithChildren<BoxProps>) => (
  <Container {...props}>{children}</Container>
);

const Container = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

export default OfferStepActionContainer;
