import {
  InitialGroupOrderComparatorParams,
  IRowNode,
} from 'ag-grid-community';
import { PricingSheetRow } from '../../../shared/types';
import { MIRA_PRODUCT_GROUP } from '../../../shared/constants';

const customGroupOrder = {
  'Ryhmittelemättömät tuotteet': 0,
  Tarvikemyynti: 1,
  [MIRA_PRODUCT_GROUP]: 2,
} as const;

const isCustomGroup = (node: IRowNode<PricingSheetRow>) =>
  Object.keys(customGroupOrder).includes(node.key || '');

export const sortGridGroups =
  (hierarchySortIndexMapping: Record<string, number>) =>
  (
    params: InitialGroupOrderComparatorParams<PricingSheetRow>,
  ): number => {
    const a = params.nodeA;
    const b = params.nodeB;
    // items without hierarchy and sales items are always last
    if (isCustomGroup(b)) {
      return isCustomGroup(a)
        ? customGroupOrder[a.key as keyof typeof customGroupOrder] -
            customGroupOrder[b.key as keyof typeof customGroupOrder]
        : -1;
    }
    // sort by node sort index if both belong to groups
    if (a.group && b.group) {
      return (
        hierarchySortIndexMapping[a.key || ''] -
        hierarchySortIndexMapping[b.key || '']
      );
    }
    // if a group contains both groups and items, groups are listed first
    if ((b.group && !a.group) || (!b.group && a.group)) {
      return a.group ? -1 : 1;
    }
    return 0;
  };
