import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { SubtleText } from '../InitialInformationStep';

import GenericCheckbox from './Checkboxes/GenericCheckbox';
import RentalPotentialForm from './Forms/RentalPotentialForm';

import PotentialSlider from './Sliders/PotentialSlider';
import { initPotentialTop } from '../../../providers/Background/BackgroundProvider';

import DefaultTooltip from '../../Tooltips/DefaultTooltip';

const potentialToolTipText =
  'Arvio asiakkaan koko vuokrapotentiaalista kaikilta toimijoilta';

interface PotentialProps {
  changeValuesEnabled: boolean;
}
const Potential: React.FC<PotentialProps> = ({
  changeValuesEnabled,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <DefaultTooltip title={potentialToolTipText} placement="top">
          <div>
            <SubtleText variant="subtitle2">
              Arvio koko vuokrapotentiaalista vuodessa*
            </SubtleText>
          </div>
        </DefaultTooltip>
        <Box marginLeft={1}>
          <GenericCheckbox
            label={'Vuokrapotentiaali yli 100 000€'}
            propName={'potentialOver100k'}
            changeValuesEnabled={changeValuesEnabled}
          />
        </Box>
      </Box>
      <Box sx={{ ml: 2, mr: 2 }} minWidth={380}>
        <Typography color="text.primary">
          Vuokrapotentiaali
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          ml: 2,
        }}
      >
        <Box sx={{ width: '20%' }}>
          <RentalPotentialForm
            changeValuesEnabled={changeValuesEnabled}
          />
        </Box>
        <Box sx={{ width: '80%' }}>
          <PotentialSlider
            initial={initPotentialTop}
            changeValuesEnabled={changeValuesEnabled}
          />
        </Box>
      </Box>
    </>
  );
};

export default Potential;
