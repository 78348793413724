import { roundToTwoDecimal } from '../../../../../shared/roundToTwoDecimal';
import { PricingSheetRow } from '../../../../../shared/types';

const priceThresholdGetter = (params: { data: PricingSheetRow }) => {
  const dayPrice = params.data?.dayPrice;
  const monthPrice = params.data?.monthPrice;

  const showByOfferText = params.data?.showByOfferText;

  if (showByOfferText) return '';

  return dayPrice && monthPrice
    ? monthPrice
      ? roundToTwoDecimal(monthPrice / (dayPrice ? dayPrice : 1))
      : roundToTwoDecimal(0 / (dayPrice ? dayPrice : 1))
    : null;
};

export default priceThresholdGetter;
