import React, {
  ReactElement,
  ReactChildren,
  ReactChild,
} from 'react';

import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

type CatClassToolTipProps = {
  title: ReactElement;
  children: ReactChild | ReactChildren;
};

const CatClassToolTip = ({
  title,
  children,
}: CatClassToolTipProps) => (
  <Tooltip
    leaveDelay={100}
    enterDelay={1000}
    title={title}
    placement="right-start"
    componentsProps={{
      tooltip: {
        sx: {
          minWidth: 650,
          mr: 'auto',
          fontSize: '1.125rem',
          backgroundColor: 'white',
          color: '#003287',
          border: '1px solid',
          borderColor: 'text.primary',
        },
      },
    }}
  >
    <Typography component={'span'} noWrap fontSize={'inherit'}>
      {children}
    </Typography>
  </Tooltip>
);
export default CatClassToolTip;
