import {
  AgGridColumnView,
  CriticalEquipmentItem,
  CriticalEquipmentRowKeys,
  IndustryName,
  ItemIndustryPercent,
  Nullable,
  PricingSheetRow,
  SurplusEquipmentItem,
  SurplusEquipmentRowKeys,
} from '../../../../../shared/types';
import {
  ImportChangedRow,
  ImportChangedRowDetails,
  ImportChangesDataMapping,
  ImportRowData,
  ImportType,
} from '../../../../../shared/types/import';
import { assert } from '../../../../../shared/utils/assert';
import { validateCriticalEquipmentItem } from '../../../utils/validateCriticalEquipmentItem';
import { dateAsIsoString } from '../../../../../shared/utils/dateAsIsoString';

export const getCriticalEquipmentRowKey = (
  _key: string | number | symbol,
): CriticalEquipmentRowKeys | null => {
  const key = String(_key);
  if (!isCriticalEquipmentKey(key)) {
    return null;
  }
  const result = key.substring(key.indexOf('.') + 1);
  assert(
    result === 'minPriceDay' ||
      result === 'minPriceMonth' ||
      result === 'from' ||
      result === 'to',
    'Given key is not a valid critical equipment row key',
  );
  return result;
};

export const getSurplusEquipmentRowKey = (
  _key: string | number | symbol,
): SurplusEquipmentRowKeys | null => {
  const key = String(_key);
  if (!isSurplusEquipmentKey(key)) {
    return null;
  }
  const result = key.substring(key.indexOf('.') + 1);
  assert(
    result === 'changePercentage' ||
      result === 'surplusFrom' ||
      result === 'surplusTo',
    'Given key is not a valid surplus equipment row key',
  );
  return result;
};

export const isCriticalEquipmentKey = (key: string) =>
  key.startsWith('criticalEquipmentItems');

export const isSurplusEquipmentKey = (key: string) =>
  key.startsWith('surplusEquipmentItem');

// updates the changes to all the rows
export const getUpdatedRows = <
  T extends PricingSheetRow | ItemIndustryPercent,
>(
  data: T[],
  changesDataMapping: ImportChangesDataMapping,
  ...industry: T extends ItemIndustryPercent ? [IndustryName] : []
): T[] =>
  data.map((row) => {
    // ensure that only industry percent items for the selected industry are updated
    if ('industry' in row && row.industry.name !== industry[0]) {
      return row;
    }
    const { changes, newRow } =
      changesDataMapping[row.catClass] || {};
    if (!changes) {
      return row;
    }
    // handle critical equipment changes
    const hasCriticalEquipmentChanges = Object.keys(changes).some(
      isCriticalEquipmentKey,
    );
    if (hasCriticalEquipmentChanges) {
      const existingCriticalEquipmentValue =
        row.criticalEquipmentItems[0] || {};
      const updatedValues = Object.entries(changes).reduce(
        (acc, [_key, { newValue }]) => {
          const key = getCriticalEquipmentRowKey(_key);
          if (!key) {
            return acc;
          }
          return {
            ...acc,
            [key]: newValue,
          };
        },
        {} as Partial<CriticalEquipmentItem>,
      );
      const updatedState = newRow
        ? { itemId: row.id, ...updatedValues }
        : { ...existingCriticalEquipmentValue, ...updatedValues };
      // don't update row if the updated state is invalid
      return validateCriticalEquipmentItem(updatedState)
        ? {
            ...row,
            criticalEquipmentItems: [{ ...updatedState }],
          }
        : row;
    }
    // handle surplus equipment changes
    const hasSurplusEquipmentChanges = Object.keys(changes).some(
      isSurplusEquipmentKey,
    );
    if (hasSurplusEquipmentChanges) {
      const existingSurplusEquipmentValue =
        row.surplusEquipmentItem || {};
      const updatedValues = Object.entries(changes).reduce(
        (acc, [_key, { newValue }]) => {
          const key = getSurplusEquipmentRowKey(_key);
          if (!key) {
            return acc;
          }
          return {
            ...acc,
            [key]: newValue,
          };
        },
        {} as Partial<SurplusEquipmentItem>,
      );
      const updatedState = newRow
        ? // add new row
          {
            itemId: row.id,
            surplusTo: null,
            changePercentage: 0,
            ...updatedValues,
          }
        : // update existing
          { ...existingSurplusEquipmentValue, ...updatedValues };
      // don't update row if the updated state is invalid
      const isValidState = Boolean(updatedState.surplusFrom);
      return isValidState
        ? {
            ...row,
            surplusEquipmentItem: updatedState,
          }
        : row;
    }
    const updatedValues = Object.entries(changes).reduce(
      (acc, [key, { newValue }]) => ({ ...acc, [key]: newValue }),
      {},
    );
    return {
      ...row,
      ...updatedValues,
    };
  });

// converts the given data array to an object with catClass ids as keys
export const getCatClassWithDataMapping = <
  T extends Record<'catClass', string | number>,
>(
  array: T[] | null,
): Record<string, Omit<T, 'catClass'>> =>
  array?.reduce(
    (acc, { catClass, ...data }) => ({
      ...acc,
      [`${catClass}`]: data,
    }),
    {},
  ) || {};

// compares the import data to the current grid data and returns the changes
export const getChangedRows = <
  ColumnView extends AgGridColumnView,
  RowType extends PricingSheetRow = PricingSheetRow,
>(
  initRows: RowType[],
  importData: ImportRowData<ColumnView>[] | null,
  importType: ImportType,
): ImportChangedRow[] => {
  if (!initRows || !importData) {
    return [];
  }
  const initRowDataMapping =
    getCatClassWithDataMapping<RowType>(initRows);
  return importData.flatMap((importRow) => {
    const { catClass } = importRow;
    const rowData: Omit<RowType, 'catClass'> | undefined =
      initRowDataMapping[catClass];
    // it is a new row if there is:
    const isNewRow =
      // no existing row value
      !rowData
        ? true
        : // no existing critical equipment value
          importType === ImportType.CriticalEquipment
          ? rowData.criticalEquipmentItems.length === 0
          : // no existing surplus equipment value
            importType === ImportType.SurplusEquipment
            ? !rowData.surplusEquipmentItem
            : false;
    // only industry percents can contain new rows.
    // we don't do anything for other import types, as there is no item to update.
    if (!rowData && importType !== ImportType.IndustryPercent) {
      return [];
    }
    // check for changes if the import data contained values for catClass
    const changes = Object.entries(importRow).reduce(
      (acc, item) => {
        const [key, newValue] = item as [
          keyof Omit<RowType, 'catClass'>,
          number | string,
        ];
        // skip catClass
        if (key === 'catClass') {
          return acc;
        }
        // get initial old value
        let oldValue: Nullable<string | number> = rowData?.[key]
          ? Number(rowData[key])
          : 0;
        // handle critical equipment item values
        if (String(key).startsWith('criticalEquipmentItems')) {
          const field = getCriticalEquipmentRowKey(key);
          if (!field) {
            return acc;
          }
          const fieldValue =
            rowData?.criticalEquipmentItems[0]?.[field];
          switch (field) {
            case 'minPriceDay':
            case 'minPriceMonth':
              oldValue = fieldValue ? Number(fieldValue) : null;
              break;
            case 'from':
            case 'to': {
              oldValue =
                typeof fieldValue === 'string'
                  ? dateAsIsoString(new Date(fieldValue), {
                      removeTime: true,
                    })
                  : null;
              break;
            }
          }
        }
        // handle surplus equipment item values
        if (String(key).startsWith('surplusEquipmentItem')) {
          const field = getSurplusEquipmentRowKey(key);
          if (!field) {
            return acc;
          }
          const fieldValue = rowData?.surplusEquipmentItem?.[field];
          switch (field) {
            case 'changePercentage':
              oldValue = fieldValue ? Number(fieldValue) : null;
              break;
            case 'surplusFrom':
            case 'surplusTo':
              oldValue =
                typeof fieldValue === 'string'
                  ? dateAsIsoString(new Date(fieldValue), {
                      removeTime: true,
                    })
                  : null;
              break;
          }
        }
        const valueChanged =
          oldValue === null || oldValue !== newValue;
        return valueChanged
          ? {
              ...acc,
              [key]: {
                newValue,
                oldValue,
              },
            }
          : acc;
      },
      {} as ImportChangedRowDetails<keyof RowType>,
    );
    return Object.keys(changes).length > 0
      ? {
          catClass: String(catClass),
          changes,
          newRow: isNewRow,
        }
      : [];
  });
};
