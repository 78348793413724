import { PricingSheetRow } from '../../../../../shared/types';

import { GridColumnProps } from '../Columns/ColumnsBase';
import TextEditor from '../Editors/TextEditor';

interface OptionalColumnProps {
  handleUpdateRowValue: (row: PricingSheetRow) => void;
}

const AdminColumnsKitNameList = ({
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps<OptionalColumnProps>) => {
  const { handleUpdateRowValue } = optionalColumnProps;
  const columns = [
    {
      field: 'productGroup',
      rowGroup: gridGroupingValues.includes('productGroup'),
      hide: true,
    },
    {
      field: 'productLine',
      rowGroup: gridGroupingValues.includes('productLine'),
      hide: true,
    },
    {
      field: 'category',
      rowGroup: gridGroupingValues.includes('category'),
      hide: true,
    },
    {
      field: 'pimProductGroup',
      rowGroup: gridGroupingValues.includes('pimProductGroup'),
      hide: true,
    },
    {
      field: 'pimProductLine',
      rowGroup: gridGroupingValues.includes('pimProductLine'),
      hide: true,
    },
    {
      field: 'pimCategory',
      rowGroup: gridGroupingValues.includes('pimCategory'),
      hide: true,
    },
    {
      field: 'kitName',
      headerName: 'Duplikaatin nimi vientitiedostossa',
      cellEditor: TextEditor,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      editable: true,
      filter: 'agTextColumnFilter',
      cellStyle: {
        borderLeft: '1px solid #ccc',
      },
    },
  ];

  return columns;
};

export default AdminColumnsKitNameList;
