import {
  PricingSheetRow,
  CoefficientType,
} from '../../../../../shared/types';
import {
  calculateDayPrice,
  calculateMonthPrice,
} from '../../../../../shared/calculatePrices';

import { PRICE_FACTOR } from '../../../../../shared/constants';

import { formatPriceWithEuroMark } from '../../../helpers/formatPriceWithEuroMark';

import { clone } from '../../../utils/genericUtils';
import { ICellRendererParams } from 'ag-grid-community';

export const getPrice = (
  row: PricingSheetRow,
  coefficient: CoefficientType,
  industryPercent?: number | null,
) => {
  if (!row) {
    return;
  }

  const changeCoefficient = industryPercent
    ? (100 + industryPercent) / 100
    : 1;

  const price = coefficient.startsWith('day')
    ? calculateDayPrice(
        Number(row.rentalReadyPrice),
        Number(row.depreciationPeriod),
        Number(row.targetUtilRate) / 100,
        Number(row[coefficient]) * changeCoefficient,
      )
    : calculateMonthPrice(
        Number(row.rentalReadyPrice),
        Number(row.depreciationPeriod),
        Number(row.targetUtilRate) / 100,
        Number(row[coefficient]) * changeCoefficient,
      );

  const dividedPrice = price ? price / PRICE_FACTOR : price;

  return formatPriceWithEuroMark(dividedPrice ?? undefined);
};

export type CoefficientPercentRendererProps = {
  coefficient: CoefficientType;
};

export const CoefficientPercentRenderer = ({
  value,
  coefficient,
  data,
}: CoefficientPercentRendererProps &
  ICellRendererParams<PricingSheetRow>) => {
  if (!data) return <></>;
  const row = clone(data);
  return (
    <>
      {value ?? 'Kerrointa ei löydy'}
      {row.catClass && value && typeof value === 'number'
        ? ' %'
        : null}
      {row.catClass && value ? (
        <span style={{ color: 'gray' }}>
          {row && getPrice(row, coefficient, value)}
        </span>
      ) : (
        <>
          <>%</>
          <span style={{ color: 'gray' }}>
            {getPrice(row, coefficient, value)}
          </span>
        </>
      )}
    </>
  );
};
