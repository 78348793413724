import { useContext, useState, useEffect, useRef } from 'react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import SaveIcon from '@mui/icons-material/Save';

import PricingFilters from './PricingTable/PricingFilters';
import Saving from './PricingTable/Saving';

import useUserContext from '../providers/User/UserProvider';

import RowsProvider from '../providers/Rows/RowsProvider';
import PricingProvider from '../providers/Pricing/PricingProvider';
import BackgroundProvider from '../providers/Background/BackgroundProvider';
import NotificationProvider from '../providers/Notification/NotificationProvider';

import CommentPresenter from './Common/CommentPresenter';
import NoInternetConnectionAlert from './Common/NoInternetConnectionAlert';

import {
  AgGridColumnView,
  ExternalPricingSheetItem,
  PdfQuality,
  PricingBasis,
  PricingSheet,
  PricingSheetItemResponse,
  PricingSheetRow,
  PricingSheetState,
  RoundingBasis,
  SelectSheetOption,
  TableType,
} from '../../../shared/types';

import { WARNING_THRESHOLD } from '../utils/pricingFormulas';

import postSheetRows from '../helpers/postSheetRows';
import getUnderWarningPriceCount from '../helpers/getUnderWarningPriceCount';
import getUnderCoefficientCount from '../helpers/getUnderCoefficientCount';

import useOnlineStatus from '../hooks/useOnlineStatus';
import useRowsHistory from '../hooks/useRowsHistory';

import { usePreventWindowUnload } from '../utils/commonUtils';

import { updatePricingSheetById } from '../services/pricingSheets';
import {
  CircularProgress,
  Grid,
  SelectChangeEvent,
} from '@mui/material';
import EditPricesDialog from './PricingTable/EditPricesDialog';
import EditViewDialog from './PricingTable/EditViewDialog';
import RemoveItemsDialog from './PricingTable/RemoveItemsDialog';
import SelectPricingColumns from './PricingTable/SelectPricingColumns';
import EditPricingBasis from './PricingTable/EditPricingBasis';
import EditRoundingBases from './PricingTable/EditRoundingBases';

import { ResetFiltersButton } from './PricingTable/ResetFiltersButton';

import SearchExternalSheet, {
  ExternalPricingSheet,
} from './PricingTable/SearchExternalSheet';
import CopyPricesDialog, {
  CopyColumns,
} from './PricingTable/CopyPricesDialog';
import { downloadPdf } from '../helpers/downloadPdf';

import History from './History/History';
import { isHistoryChangedInPricingSide } from './History/changeChecks';
import { useFilters } from '../hooks/useFilters';
import AgGrid from './PricingTable/AgGrid';

import { useColumns } from '../hooks/useColumns';
import WarningCounts from './Cells/common/WarningCounts';
import { getGroupFilterForGroup } from '../helpers/groupFilter';
import CoefficientChips from './Cells/common/CoefficientChips';
import CommentToolTip from './Tooltips/CommentToolTip';
import CommentIndicator from './Common/CommentIndicator';
import calculateMeansByGroup from '../utils/calculateMeansByGroup';
import { clone } from '../utils/genericUtils';

interface PricingProps {
  currentPricingSheetRows: PricingSheetRow[];
  setCurrentPricingSheetRows: React.Dispatch<
    React.SetStateAction<[] | PricingSheetRow[]>
  >;
}

const getInitialColumns = (pricingBasis: PricingBasis) => {
  if (pricingBasis === 'DAY') {
    return AgGridColumnView.PRICING_DAY;
  }
  return AgGridColumnView.PRICING_BOTH;
};

export type TableFilter = (row: PricingSheetRow) => boolean;

const Pricing: React.FC<PricingProps> = ({
  currentPricingSheetRows,
  setCurrentPricingSheetRows,
}) => {
  const theme = useTheme();

  const { setHasUnsavedChanges } = useUserContext();
  const {
    pricingSheetRows,
    selectedRows,
    setSelectedRows,
    preSelectedRows,
    updateRow,
    warningCounts,
    dispatchPricingSheetRows,
  } = useContext(RowsProvider);

  const {
    dayWarnings,
    dayLightWarnings,
    dayBelow70Warnings,
    dayZeroPriceWarnings,
    monthWarnings,
    monthLightWarnings,
    monthBelow70Warnings,
    monthZeroPriceWarnings,
    invalidPriceThresholdRows,
  } = warningCounts;

  const {
    pricingSheetId,
    setItemFilterValue,
    externalPricingSheetId,
    isMiraSheet,
    miraSuccess,
    miraSheetId,
    comparisonPricingSheets,
    setExternalPricingSheetId,
    comparisonSheetName,
    setComparisonSheetName,
    externalSheetSelectedOption,
    setExternalSheetSelectedOption,
  } = useContext(PricingProvider);
  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );
  const { pricingBasis, roundingBasisSheet } = backgroundInfo;
  const { setNotification } = useContext(NotificationProvider);
  const {
    activeFilters,
    addFilter,
    toggleActiveFilter,
    isChecked,
    clearFilters,
    applyActiveFilters,
  } = useFilters();

  const {
    columnDefs,
    groupingStyle,
    gridColumnView,
    groupingValues,
    setDefaultGroupingValues,
    updateGridColumnView,
    updateGroupingValues,
    getMainMenuItems,
  } = useColumns(getInitialColumns(pricingBasis), {
    roundingBasisSheet,
    handleUpdateRowValue: updateRow,
  });

  const isOnline = useOnlineStatus();
  const [
    rowsHistory,
    currentRowsInHistory,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentItemIndustryPercentsInHistory,
    setRowsHistory,
    undo,
    undoToIndex,
    redo,
    redoToIndex,
    currentIndex,
    setCurrentIndex,
  ] = useRowsHistory();

  const agGridRef = useRef<any>();

  const [savingState, setSavingState] = useState<boolean>(false);
  const [savingTimeStamp, setSavingTimeStamp] = useState<Date>();
  const [error, setError] = useState<boolean>(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  const updateTimeStampToNewestChange = () => {
    setSavingTimeStamp(
      new Date(rowsHistory[rowsHistory.length - 1]?.timeStamp),
    );
  };

  const getPricingSheetPdf = async (
    id: string,
    name: string,
    quality: PdfQuality,
  ) => {
    try {
      setPdfLoading(true);
      await downloadPdf(id, name, quality);
    } catch (error) {
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'error',
        message: 'Hinnaston lataaminen epäonnistui',
      });
    } finally {
      setPdfLoading(false);
    }
  };

  // change values when component unmount
  useEffect(() => {
    return () => {
      setHasUnsavedChanges(false);
      setItemFilterValue('');
      setSelectedRows([]); // provider
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preSelectedPricingSheetRows = preSelectedRows;

  const isUnsavedChanges = !(
    JSON.stringify(
      currentPricingSheetRows.map((row) => {
        return {
          id: row.id,
          catClass: row.catClass,
          dayPrice: row.dayPrice,
          monthPrice: row.monthPrice,
        };
      }),
    ) ===
    JSON.stringify(
      preSelectedPricingSheetRows.map((row: PricingSheetRow) => {
        return {
          id: row.id,
          catClass: row.catClass,
          dayPrice: row.dayPrice,
          monthPrice: row.monthPrice,
        };
      }),
    )
  );

  const isStateAndSaveSame =
    currentIndex === 0 && !savingTimeStamp
      ? true
      : rowsHistory.length > 1
        ? savingTimeStamp?.getTime() ===
          new Date(rowsHistory[currentIndex]?.timeStamp).getTime()
        : true;

  const updatePricingSheetState = async (
    state: PricingSheetState,
    pricingBasis: PricingBasis,
  ) => {
    try {
      await updatePricingSheetById(pricingSheetId, {
        state: state,
        pricingBasis: pricingBasis,
      });
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'success',
        message: 'Hinnaston tila päivitetty palvelimelle!',
      });
    } catch (error) {
      console.error(error);
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'error',
        message: 'Hinnaston tilan päivitys palvelimelle epäonnistui!',
      });
    }
  };

  const handleColumnViewChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateGridColumnView(event.target.value as AgGridColumnView);
  };

  const handlePricingBasisChange = (
    event: SelectChangeEvent<PricingBasis>,
  ) => {
    if (event.target.value === 'DAY') {
      updateGridColumnView(AgGridColumnView.PRICING_DAY);
      setBackgroundInfo({
        ...backgroundInfo,
        pricingBasis: 'DAY',
      });
    } else {
      updateGridColumnView(AgGridColumnView.PRICING_BOTH);
      setBackgroundInfo({
        ...backgroundInfo,
        pricingBasis: 'BOTH',
      });
    }
    clearFilters();
    updatePricingSheetState(
      backgroundInfo.state,
      event.target.value as PricingBasis,
    );
  };

  const handleComparisonSheetChange = (
    newValue: SelectSheetOption,
  ) => {
    setExternalSheetSelectedOption(newValue);
    if (newValue.value !== 'isMiraSheet') {
      const comparisonSheet = comparisonPricingSheets.find(
        (sheet: PricingSheet) => Number(sheet.id) === newValue.id,
      );
      setComparisonSheetName(comparisonSheet.name);
    }
  };

  const handlePricingSheetItemsResponse = (
    pricingSheetItems: PricingSheetItemResponse[],
  ) => {
    const copy = clone<PricingSheetRow[]>(pricingSheetRows);
    for (const pricingSheetRow of copy) {
      if (pricingSheetRow) {
        const item = pricingSheetItems.find(
          (row: { item: PricingSheetRow }) =>
            pricingSheetRow?.catClass === row.item?.catClass,
        );

        pricingSheetRow.comparisonDayPrice = item?.dayPrice
          ? Number(item.dayPrice)
          : undefined;

        pricingSheetRow.comparisonMonthPrice = item?.monthPrice
          ? Number(item.monthPrice)
          : undefined;
      }
    }

    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: copy,
    });
  };

  const handleExternalItemsResponse = (
    externalPricingSheetItems: ExternalPricingSheetItem[],
  ) => {
    const copy = clone<PricingSheetRow[]>(pricingSheetRows);
    for (const pricingSheetRow of copy) {
      if (pricingSheetRow) {
        const item = externalPricingSheetItems.find(
          (row) => pricingSheetRow?.catClass === row.item?.catClass,
        );

        // if sheet is mira sheet we have to multiply prices by 10000
        pricingSheetRow.comparisonDayPrice = item?.dayPrice
          ? Number(item.dayPrice) * 10000
          : undefined;

        pricingSheetRow.comparisonMonthPrice = item?.monthPrice
          ? Number(item.monthPrice) * 10000
          : undefined;
      }
    }

    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: copy,
    });
  };

  const handleComparisonSheetResponse = (
    externalPricingSheet: ExternalPricingSheet,
  ) => {
    setComparisonSheetName(externalPricingSheet.sheetName);
  };

  const handlePriceChanges = (newRows: PricingSheetRow[]) => {
    const copy = clone<PricingSheetRow[]>(pricingSheetRows);
    for (const pricingSheetRow of copy) {
      if (
        pricingSheetRow?.preSelected &&
        newRows.some(
          (row: PricingSheetRow) =>
            row.catClass === pricingSheetRow.catClass,
        )
      ) {
        const matchingNewRow = newRows.find(
          (row: PricingSheetRow) =>
            row.catClass === pricingSheetRow.catClass,
        );

        if (matchingNewRow) {
          Object.assign(pricingSheetRow, matchingNewRow);
        }
      }
    }

    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: copy,
    });
    const preSelectedNewRows = copy.filter(
      (row: PricingSheetRow) => row.preSelected,
    );

    saveRows([...preSelectedNewRows]);
    setCurrentPricingSheetRows([...preSelectedNewRows]);
    setSelectedRows([]); //provider
    agGridRef.current?.clearAllSelections(); // grid
  };

  const handleCopyPricesComparison = (
    selectedRows: PricingSheetRow[],
    copyColumns: CopyColumns,
  ) => {
    const copy = clone<PricingSheetRow[]>(pricingSheetRows);
    for (const pricingSheetRow of copy) {
      if (
        pricingSheetRow?.preSelected &&
        selectedRows.some(
          (row: PricingSheetRow) =>
            row.catClass === pricingSheetRow.catClass,
        )
      ) {
        const dayPrice = pricingSheetRow?.comparisonDayPrice;
        const monthPrice = pricingSheetRow?.comparisonMonthPrice;

        if (
          dayPrice &&
          (copyColumns === 'DAY' || copyColumns === 'BOTH')
        ) {
          pricingSheetRow.dayPrice = Number(dayPrice);
        }
        if (
          monthPrice &&
          (copyColumns === 'MONTH' || copyColumns === 'BOTH')
        ) {
          pricingSheetRow.monthPrice = Number(monthPrice);
        }
        pricingSheetRow.selected = false;
      }
    }

    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: copy,
    });
    const preSelectedNewRows = copy.filter(
      (row: PricingSheetRow) => row.preSelected,
    );

    saveRows([...preSelectedNewRows]);
    setCurrentPricingSheetRows([...preSelectedNewRows]);
    setSelectedRows([]); // provider
    agGridRef.current?.clearAllSelections(); // grid
  };

  const handleCopyPricesProposal = (
    selectedRows: PricingSheetRow[],
    copyColumns: CopyColumns,
  ) => {
    const copy = clone<PricingSheetRow[]>(pricingSheetRows);

    for (const copyRow of copy) {
      if (
        copyRow.preSelected &&
        selectedRows.some(
          (row: PricingSheetRow) => row.catClass === copyRow.catClass,
        )
      ) {
        const { proposalDayPrice, proposalMonthPrice } = copyRow;

        if (
          proposalDayPrice &&
          (copyColumns === 'DAY' || copyColumns === 'BOTH')
        ) {
          copyRow.dayPrice = proposalDayPrice;
        }
        if (
          proposalMonthPrice &&
          (copyColumns === 'MONTH' || copyColumns === 'BOTH')
        ) {
          copyRow.monthPrice = proposalMonthPrice;
        }
        copyRow.selected = false;
      }
    }

    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: copy,
    });

    const preSelectedNewRows = copy.filter(
      (row: PricingSheetRow) => row.preSelected,
    );

    saveRows([...preSelectedNewRows]);
    setCurrentPricingSheetRows([...preSelectedNewRows]);
    setSelectedRows([]); // provider
    agGridRef.current?.clearAllSelections(); // grid
  };

  const updateSheetStateLocally = () => {
    try {
      const underWarningPriceCount = getUnderWarningPriceCount(
        preSelectedPricingSheetRows,
      );
      const underCoefficientCount = getUnderCoefficientCount(
        preSelectedPricingSheetRows,
      );

      if (
        underWarningPriceCount > 0 ||
        underCoefficientCount > WARNING_THRESHOLD
      ) {
        setBackgroundInfo({
          ...backgroundInfo,
          state: PricingSheetState.WarningDraft,
        });
      } else {
        setBackgroundInfo({
          ...backgroundInfo,
          state: PricingSheetState.OkDraft,
        });
      }
    } catch (error) {
      setNotification({
        severity: 'error',
        message: 'Hinnaston tilaa päivittäessä tapahtui virhe!',
      });
    }
  };

  useEffect(() => {
    updateSheetStateLocally();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingSheetRows]);

  usePreventWindowUnload(isUnsavedChanges);

  const shouldSaveRowsState = isHistoryChangedInPricingSide(
    currentRowsInHistory,
    preSelectedPricingSheetRows,
  );

  const saveRowsState = async () => {
    if (pricingSheetRows.length > 0 && shouldSaveRowsState) {
      setRowsHistory([
        ...rowsHistory,
        {
          timeStamp: Date.now(),
          rows: pricingSheetRows,
          roundingBasisSheet: backgroundInfo.roundingBasisSheet,
          roundingBasisPdf: backgroundInfo.roundingBasisPdf,
        },
      ]);
      setCurrentIndex(rowsHistory.length);
    }
  };

  const handleClearComparisonPrices = () => {
    const copy = clone<PricingSheetRow[]>(pricingSheetRows);
    for (const pricingSheetRow of copy) {
      if (pricingSheetRow) {
        pricingSheetRow.comparisonDayPrice = undefined;
        pricingSheetRow.comparisonMonthPrice = undefined;
      }
    }
    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: copy,
    });
    setComparisonSheetName('');
  };

  useEffect(() => {
    setHasUnsavedChanges(isUnsavedChanges);
    updateSheetStateLocally();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnsavedChanges, pricingBasis]);

  useEffect(() => {
    saveRowsState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnsavedChanges, pricingSheetRows]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isUnsavedChanges && !error && isOnline) {
        await saveRows(preSelectedPricingSheetRows);
        updatePricingSheetState(
          backgroundInfo.state,
          backgroundInfo.pricingBasis,
        );
        saveRows(preSelectedPricingSheetRows);
      }
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnsavedChanges, error, pricingSheetRows, currentIndex]);

  const save = async (
    rows: PricingSheetRow[],
    moveInHistory: boolean = false,
  ) => {
    if (!moveInHistory) {
      await saveRows([...rows]);
    }
  };

  const currentRoundingBasis = backgroundInfo.roundingBasisSheet;

  const onSaveClick = () => {
    updatePricingSheetState(
      backgroundInfo.state,
      backgroundInfo.pricingBasis,
    );
    save(preSelectedPricingSheetRows, false);
  };

  const saveRows = async (
    rows: PricingSheetRow[],
    roundingBasisSheet?: RoundingBasis,
  ) => {
    let successfully = false;
    setError(false);
    setSavingState(true);
    try {
      const response = await postSheetRows(
        pricingSheetId,
        roundingBasisSheet
          ? roundingBasisSheet
          : currentRoundingBasis,
        rows,
      );
      if (response?.status === 204) {
        setCurrentPricingSheetRows([...rows]);

        setSavingTimeStamp(
          new Date(rowsHistory[currentIndex]?.timeStamp),
        );
        setSavingState(false);

        successfully = true;
      }
    } catch (error) {
      setNotification({
        severity: 'error',
        message: 'Hinnastoa tallentaessa tapahtui virhe!',
      });
      setError(true);
    } finally {
      setSavingState(false);
      return successfully;
    }
  };

  const onClickDownloadDraftPdf = async () => {
    try {
      await saveRows(preSelectedPricingSheetRows);
      updatePricingSheetState(
        backgroundInfo.state,
        backgroundInfo.pricingBasis,
      );

      getPricingSheetPdf(pricingSheetId, 'draft', PdfQuality.LOW);
    } catch (error) {
      setNotification({
        severity: 'error',
        message: 'Hinnastoa ladatessa tapahtui virhe!',
      });
      setError(true);
    }
  };

  const removeRows = () => {
    selectedRows.forEach((row: PricingSheetRow) => {
      dispatchPricingSheetRows({
        id: row.catClass,
        type: 'updateSelectedStatuses',
        selectedStatus: true,
      });
    });
    selectedRows.forEach((row: PricingSheetRow) => {
      dispatchPricingSheetRows({
        id: row.catClass,
        type: 'updatePreSelectedStatuses',
        selectedStatus: true,
      });
    });

    setNotification({
      type: 'SNACKBAR',
      severity: 'success',
      message: `Poistettu ${selectedRows.length} tuotetta hinnoittelusta`,
      duration: 3000,
    });
  };

  const shouldShowComparisonFilter = isMiraSheet
    ? externalPricingSheetId && miraSuccess && miraSheetId
    : externalPricingSheetId;

  const shouldShowInvalidThresholdFilter = pricingBasis === 'BOTH';

  const groupHeaderInnerRenderer = (
    row: any,
    filteredRows: PricingSheetRow[],
  ) => {
    const groupFilter = getGroupFilterForGroup(
      row.node.field,
      row.value,
      groupingStyle,
    );

    const {
      meanFilledCoefficientOfAllDayRows,
      meanProposalCoefficientOfAllDayRows,
      meanFilledCoefficientOfAllMonthRows,
      meanProposalCoefficientOfAllMonthRows,
    } = calculateMeansByGroup({
      rows: filteredRows,
      groupingParams: {
        field: row.node.field,
        value: row.value,
        groupingStyle: groupingStyle,
      },
    });

    const sheetContainsCommentRows =
      filteredRows
        .filter(groupFilter)
        .filter(
          (sheetRow: PricingSheetRow) => sheetRow.approverComment,
        ).length > 0;

    return (
      <>
        <WarningCounts
          countDayPriceWarnings={
            dayWarnings.filter(groupFilter).length
          }
          countMonthPriceWarnings={
            monthWarnings.filter(groupFilter).length
          }
          countDayPriceLightWarnings={
            dayLightWarnings.filter(groupFilter).length
          }
          countMonthPriceLightWarnings={
            monthLightWarnings.filter(groupFilter).length
          }
          countBelow70PercentDayPriceRows={
            dayBelow70Warnings.filter(groupFilter).length
          }
          countBelow70PercentMonthPriceRows={
            monthBelow70Warnings.filter(groupFilter).length
          }
          countZeroDayPriceRows={
            dayZeroPriceWarnings.filter(groupFilter).length
          }
          countZeroMonthPriceRows={
            monthZeroPriceWarnings.filter(groupFilter).length
          }
          countPriceThresholdWarnings={
            invalidPriceThresholdRows.filter(groupFilter).length
          }
          pricingBasis={backgroundInfo.pricingBasis}
        />
        <CoefficientChips
          means={{
            meanFilledDay: meanFilledCoefficientOfAllDayRows,
            meanProposalDay: meanProposalCoefficientOfAllDayRows,
            meanFilledMonth: meanFilledCoefficientOfAllMonthRows,
            meanProposalMonth: meanProposalCoefficientOfAllMonthRows,
          }}
          pricingBasis={backgroundInfo.pricingBasis}
          gridColumnView={gridColumnView}
        />
        {sheetContainsCommentRows && (
          <Box sx={{ ml: 1, mr: 1 }}>
            {showCommentTooltip(
              'Tuoteriville on jätetty kommentti',
              true,
              {
                x: 18,
                y: 12,
              },
            )}
          </Box>
        )}
      </>
    );
  };

  const customHeaderInnerRenderer = (
    filteredRows: PricingSheetRow[],
  ) => {
    const rowsForMeanCalculations = filteredRows
      ? filteredRows?.filter(
          (row: PricingSheetRow) => !row.showByOfferText,
        )
      : [];

    const {
      meanFilledCoefficientOfAllDayRows,
      meanProposalCoefficientOfAllDayRows,
      meanFilledCoefficientOfAllMonthRows,
      meanProposalCoefficientOfAllMonthRows,
    } = calculateMeansByGroup({
      rows: rowsForMeanCalculations,
    });

    const sheetContainsCommentRows =
      filteredRows.filter(
        (sheetRow: PricingSheetRow) => sheetRow.approverComment,
      ).length > 0;
    return (
      <>
        <WarningCounts
          countDayPriceWarnings={dayWarnings.length}
          countMonthPriceWarnings={monthWarnings.length}
          countDayPriceLightWarnings={dayLightWarnings.length}
          countMonthPriceLightWarnings={monthLightWarnings.length}
          countBelow70PercentDayPriceRows={dayBelow70Warnings.length}
          countBelow70PercentMonthPriceRows={
            monthBelow70Warnings.length
          }
          countZeroDayPriceRows={dayZeroPriceWarnings.length}
          countZeroMonthPriceRows={monthZeroPriceWarnings.length}
          countPriceThresholdWarnings={
            invalidPriceThresholdRows.length
          }
          pricingBasis={backgroundInfo.pricingBasis}
        />
        <CoefficientChips
          means={{
            meanFilledDay: meanFilledCoefficientOfAllDayRows,
            meanProposalDay: meanProposalCoefficientOfAllDayRows,
            meanFilledMonth: meanFilledCoefficientOfAllMonthRows,
            meanProposalMonth: meanProposalCoefficientOfAllMonthRows,
          }}
          pricingBasis={backgroundInfo.pricingBasis}
          gridColumnView={gridColumnView}
        />
        {sheetContainsCommentRows && (
          <Box sx={{ ml: 1, mr: 1 }}>
            {showCommentTooltip(
              'Tuoteriville on jätetty kommentti',
              true,
              {
                x: 18,
                y: 12,
              },
            )}
          </Box>
        )}
      </>
    );
  };

  const catClassInnerRenderer = (row: any) => (
    <>
      {row.approverComment && showCommentTooltip(row.approverComment)}
    </>
  );

  const showCommentTooltip = (
    comment: string,
    outlined: boolean = false,
    offsetProps?: { x: number; y: number },
  ) => (
    <CommentToolTip
      offsetProps={offsetProps}
      title={
        <Typography color={theme.palette.text.primary}>
          {comment}
        </Typography>
      }
    >
      <CommentIndicator outlined={outlined} />
    </CommentToolTip>
  );

  const itemCountText = (
    <>
      Hinnoiteltavia tuotteita yhteensä{' '}
      <b>{preSelectedPricingSheetRows.length}</b>, valittu{' '}
      <b>{selectedRows.length}</b>
    </>
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          mb: 1,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{ ml: 1, mt: 1, mb: 0 }}
            variant="h4"
            component="div"
            gutterBottom
            color="primary"
          >
            Hinnoittelu
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            }}
          >
            <Typography
              sx={{ ml: 1, mb: 2 }}
              variant="body1"
              color={'text.secondary'}
            >
              {backgroundInfo?.name
                ? `${backgroundInfo?.name}`
                : null}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: 2,
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                },
              }}
            >
              {!isOnline ? (
                <Box
                  sx={{
                    height: 20,
                    [theme.breakpoints.down('md')]: {
                      mb: 2,
                    },
                  }}
                >
                  <NoInternetConnectionAlert />
                </Box>
              ) : null}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <History
                  rowsHistory={rowsHistory}
                  currentIndex={currentIndex}
                  undo={undo}
                  undoToIndex={undoToIndex}
                  redo={redo}
                  redoToIndex={redoToIndex}
                  save={save}
                  tableType={TableType.EditTable}
                />
                <Box sx={{ ml: 2 }}>
                  <Saving
                    saveButton={
                      <Button
                        endIcon={<SaveIcon />}
                        disabled={
                          savingState ||
                          (!isUnsavedChanges && isStateAndSaveSame)
                        }
                        size="small"
                        variant="contained"
                        onClick={onSaveClick}
                        sx={{
                          height: 40,
                          minWidth: 120,
                        }}
                      >
                        {savingState
                          ? 'Tallennetaan...'
                          : !isUnsavedChanges && isStateAndSaveSame
                            ? 'Tallennettu'
                            : 'Tallenna'}
                      </Button>
                    }
                    savingTimeStamp={savingTimeStamp}
                    rowsHistory={rowsHistory}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, mb: 1 }}>
        {backgroundInfo?.approverComment && (
          <CommentPresenter
            header={'Palauttajan perustelut'}
            comment={backgroundInfo.approverComment}
          />
        )}
      </Box>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box>
          <EditPricingBasis
            pricingBasis={pricingBasis}
            handlePricingBasisChange={handlePricingBasisChange}
            showOptions={['DAY', 'BOTH']}
          />
        </Box>
        <Box>
          <SearchExternalSheet
            pricingSheetId={pricingSheetId}
            comparisonPricingSheets={comparisonPricingSheets}
            externalPricingSheetId={externalPricingSheetId}
            setExternalPricingSheetId={setExternalPricingSheetId}
            handleClearComparison={handleClearComparisonPrices}
            handleClear={handleClearComparisonPrices}
            tooltipTitle="Lataa hinnasto vertailuun hinnastotyökalusta tai MIRA:sta. Vertailuhinnat latautuvat taulukon VRT-sarakkeisiin"
            selectPlaceholder="Valitse vertailuhinnasto"
            handleExternalItemsResponse={handleExternalItemsResponse}
            handleExternalPricingSheetResponse={
              handleComparisonSheetResponse
            }
            onSelectionChange={handleComparisonSheetChange}
            handlePricingSheetItemsResponse={
              handlePricingSheetItemsResponse
            }
            externalSheetNameDefault={comparisonSheetName}
            selectedOptionDefault={externalSheetSelectedOption}
            miraSheetIdDefault={externalPricingSheetId}
          />
        </Box>
        <Box
          sx={{
            justifySelf: 'flex-end',
            marginLeft: 'auto',
            alignSelf: 'center',
          }}
        >
          {pdfLoading ? (
            <CircularProgress size={20} sx={{ mr: 5 }} />
          ) : (
            <Button
              size="small"
              variant="outlined"
              onClick={async () => onClickDownloadDraftPdf()}
            >
              Lataa luonnos
            </Button>
          )}
        </Box>
      </Grid>

      <PricingFilters
        addFilter={addFilter}
        pricingBasis={pricingBasis}
        toggleActiveFilter={toggleActiveFilter}
        isChecked={isChecked}
        clearFilters={clearFilters}
        showZeroPriceFilter={true}
        shouldShowComparisonFilter={shouldShowComparisonFilter}
        showPriceThresholdPriceFilter={
          shouldShowInvalidThresholdFilter
        }
      />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={2}
      >
        <Typography sx={{ mt: 1, mb: 1 }} color="text.primary">
          {itemCountText}
        </Typography>
        <Grid>
          <Grid sx={{ display: 'inline' }}>
            <EditPricesDialog
              onChangePrices={handlePriceChanges}
              backgroundInfo={backgroundInfo}
              selectedRows={selectedRows}
            />
          </Grid>
          <Grid sx={{ display: 'inline', ml: 0.5 }}>
            <CopyPricesDialog
              enabledSources={['PROPOSAL', 'COMPARISON']}
              onCopyPricesComparison={handleCopyPricesComparison}
              onCopyPricesProposal={handleCopyPricesProposal}
              selectedRows={selectedRows}
              comparisonSheetName={comparisonSheetName}
            />
          </Grid>
          <Grid sx={{ display: 'inline', ml: 0.5 }}>
            <RemoveItemsDialog
              selectedRows={selectedRows}
              onRemoveRows={removeRows}
              dialogText={'Hinnoiteltavat tuotteet'}
            />
          </Grid>
          <Grid sx={{ display: 'inline', ml: 0.5 }}>
            <ResetFiltersButton
              gridApi={agGridRef.current?.gridRef?.api}
            />
          </Grid>
        </Grid>
        <Grid justifySelf="flex-end" sx={{ marginLeft: 'auto' }}>
          <Box sx={{ display: 'inline', mr: 0.5 }}>
            <SelectPricingColumns
              handleColumnViewChange={handleColumnViewChange}
              selectableColumns={{
                DAY: AgGridColumnView.PRICING_DAY,
                MONTH: AgGridColumnView.PRICING_MONTH,
                BOTH: AgGridColumnView.PRICING_BOTH,
              }}
              gridColumnView={gridColumnView}
              pricingBasis={pricingBasis}
            />
          </Box>
          <EditViewDialog
            editRoundingBases={
              <EditRoundingBases
                rowsHistory={rowsHistory}
                isUnsavedChanges={isUnsavedChanges}
                updateTimeStampToNewestChange={
                  updateTimeStampToNewestChange
                }
                gridColumnView={gridColumnView}
                updateGridColumnView={updateGridColumnView}
              />
            }
            groupingStyle={groupingStyle}
            groupingValues={groupingValues}
            setDefaultGroupingValues={setDefaultGroupingValues}
            updateGroupingValues={updateGroupingValues}
          />
        </Grid>
      </Grid>
      <AgGrid
        ref={agGridRef}
        pricingBasis={pricingBasis}
        isSelectable={true}
        gridColumnView={gridColumnView}
        type={TableType.EditTable}
        rows={preSelectedRows}
        loading={false}
        columnDefs={columnDefs ?? []}
        externalFilters={activeFilters}
        applyActiveFilters={applyActiveFilters}
        setSelectedRows={setSelectedRows}
        catClassInnerRenderer={catClassInnerRenderer}
        groupHeaderInnerRenderer={groupHeaderInnerRenderer}
        customHeaderInnerRenderer={customHeaderInnerRenderer}
        getMainMenuItems={getMainMenuItems}
      />
    </>
  );
};

export default Pricing;
