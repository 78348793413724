import { Link } from 'react-router-dom';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const NotFound = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        backgroundColor: 'white',
        borderRadius: 2,
        paddingTop: 4,
        paddingBottom: 2,
        marginTop: 4,
      }}
    >
      <Box>
        <Typography variant="h6" gutterBottom color="primary">
          Täällä ei ole mitään! :(
        </Typography>
        <Typography component={Link} to={'/'}>
          Etusivulle
        </Typography>
      </Box>
    </Container>
  );
};

export default NotFound;
