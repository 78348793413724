import { useTheme } from '@mui/material';
import { Feedback, FeedbackOutlined } from '@mui/icons-material';

const CommentIndicator = ({
  outlined = false,
}: {
  outlined?: boolean;
}) => {
  const theme = useTheme();
  const sx = {
    float: 'right',
    color: theme.palette.error.main,
    fontWeight: 'bold',
    fontSize: 18,
    p: '1px',
  };
  return (
    <>
      {outlined ? <FeedbackOutlined sx={sx} /> : <Feedback sx={sx} />}
    </>
  );
};

export default CommentIndicator;
