import { useQuery } from '@tanstack/react-query';
import { MIRA_ITEM_SEARCH_QUERY_KEY } from '../../../../shared/constants';
import { api } from '../../index';
import {
  SearchComponent,
  SearchComponentProps,
} from './SearchComponent';
import { ConvertedMiraSearchItem } from '../../../../shared/types/mira';
import { ItemType } from '../../../../shared/types';
import { useMemo, useState } from 'react';
import { createFilterOptions } from '@mui/material';

export type MiraItemSearchOption = {
  id: string;
  value: string;
  type: ItemType;
};

type MiraItemSearchProps = {
  onSelect?: SearchComponentProps<MiraItemSearchOption>['onSelect'];
};

const itemType = 1; // sales/service items only

export const MiraItemSearch = ({
  onSelect = () => null,
}: MiraItemSearchProps) => {
  const [query, setQuery] = useState<string>('');
  const { data, isFetching } = useQuery({
    queryKey: [MIRA_ITEM_SEARCH_QUERY_KEY, query],
    queryFn: async () =>
      (
        await api.get<ConvertedMiraSearchItem[]>(
          `/items/mira/search?itemType=${itemType}&query=${encodeURIComponent(query)}`,
        )
      ).data,
    staleTime: Infinity,
    retry: false,
    enabled: Boolean(query),
  });
  // update options when data changes
  const options = useMemo(
    () =>
      (data || []).map((item) => ({
        id: item.catClass,
        value: item.name,
        type: item.type,
      })),
    [data],
  );

  const { salesItemCount, serviceItemCount } = useMemo(
    () =>
      options.reduce(
        (acc, { type }) => {
          switch (type) {
            case ItemType.Sales:
              return {
                ...acc,
                salesItemCount: acc.salesItemCount + 1,
              };
            case ItemType.Service:
              return {
                ...acc,
                serviceItemCount: acc.serviceItemCount + 1,
              };
            default:
              return acc;
          }
        },
        { salesItemCount: 0, serviceItemCount: 0 },
      ),
    [options],
  );

  return (
    <SearchComponent
      sx={{
        my: 1,
        width: 'min(500px, 100%)',
      }}
      clearOnSelect
      label={'Hae myynti- ja palvelutuotteita MIRA:sta'}
      placeholder={'Tuotteen nimi tai numero'}
      options={options}
      loading={isFetching}
      searchMinLength={3}
      filterOptions={(options, state) => {
        const { inputValue } = state;
        if (!inputValue) return [];
        const inputIsNumber = !isNaN(Number(inputValue));
        return inputIsNumber
          ? options.filter(({ id }) => id.includes(inputValue))
          : createFilterOptions<MiraItemSearchOption>()(
              options,
              state,
            );
      }}
      getOptionLabel={({ value }) => value}
      onSearch={setQuery}
      onSelect={onSelect}
      groupBy={({ type }) => {
        switch (type) {
          case ItemType.Sales:
            return `Myyntituotteet (${salesItemCount})`;
          case ItemType.Service:
            return `Palvelutuotteet (${serviceItemCount})`;
          default:
            return 'Muut';
        }
      }}
    />
  );
};
