import {
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { isValidEmail } from '../../../utils/isValidEmail';
import { updatePricingSheetById } from '../../../services/pricingSheets';
import { Nullable } from '../../../../../shared/types';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import { difference } from 'lodash';
import filterOutPricingEmail from '../../../helpers/filterOutPricingEmail';

interface EditingRightsProps {
  pricingSheetId: string;
  editingRights: Nullable<string>;
  userAllowedToDelete: boolean;
}

const initEditingRights = (editingRights: Nullable<string>) => {
  if (editingRights && editingRights.length > 0) {
    return editingRights.split(',');
  } else {
    return [];
  }
};

export const EditingRights: React.FC<EditingRightsProps> = ({
  pricingSheetId,
  editingRights,
  userAllowedToDelete = true,
}) => {
  const [emails, setEmails] = useState<string[]>(
    initEditingRights(editingRights),
  );
  const [emailInput, setEmailInput] = useState('');
  const [isError, setIsError] = useState(false);

  const { setNotification } = useContext(NotificationProvider);
  // emails added by user that can be deleted
  const userAddedEmails = difference(
    emails,
    initEditingRights(editingRights),
  );

  const deleteEmail = async (emailToDelete: string) => {
    try {
      const updatedEmails = emails.filter(
        (email) => email !== emailToDelete,
      );
      await updatePricingSheetById(pricingSheetId, {
        editingRights: updatedEmails.join(','),
      });
      setNotification({
        type: 'SNACKBAR',
        duration: 3000,
        severity: 'success',
        message:
          'Muokkausoikeudet poistettu käyttäjältä ' + emailToDelete,
      });
      setEmails(updatedEmails);
    } catch (error) {
      console.error(error);
      setNotification({
        type: 'SNACKBAR',
        duration: 3000,
        severity: 'error',
        message: `Muokkausoikeuksien poisto käyttäjältä ${emailToDelete} epöonnistui`,
      });
    }
  };

  const addEmail = async () => {
    if (isValidEmail(emailInput)) {
      try {
        const updatedEmails = emails.concat(emailInput);
        await updatePricingSheetById(pricingSheetId, {
          editingRights: updatedEmails.join(','),
        });
        setNotification({
          type: 'SNACKBAR',
          duration: 3000,
          severity: 'success',
          message:
            'Muokkausoikeudet lisätty käyttäjälle ' + emailInput,
        });
        setIsError(false);
        setEmails(updatedEmails);
        setEmailInput('');
      } catch (error) {
        console.error(error);
        setNotification({
          type: 'SNACKBAR',
          duration: 3000,
          severity: 'error',
          message: `Muokkausoikeuksien lisääminen käyttäjälle ${emailInput} epöonnistui`,
        });
      }
    } else {
      setIsError(true);
    }
  };

  return (
    <Box sx={{ ml: 5, mr: 5 }}>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        color="primary"
      >
        Muokkausoikeudet
      </Typography>
      <Typography variant="body1" color={'text.secondary'}>
        Voit antaa muille käyttäjille muokkausoikeuden hinnastoon.
        <br />
        Muut käyttäjät saavat samat oikeudet hinnaston muokkaukseen
        kuin hinnaston luojakin.
        <br />
        Ainoastaan yksi käyttäjä voi muokata hinnastoa samanaikaisesti
      </Typography>
      <TextField
        error={isError}
        helperText={isError ? 'Virheellinen sähköpostiosoite' : ''}
        sx={{ mt: 2, minWidth: 300 }}
        label="Käyttäjän sähköposti"
        variant="outlined"
        size="small"
        value={emailInput}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter' && emailInput) {
            addEmail();
          }
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setIsError(false);
          setEmailInput(event.target.value);
        }}
      />
      <Button
        variant="contained"
        disabled={!emailInput}
        onClick={() => {
          addEmail();
        }}
        sx={{ mt: 2, ml: 2 }}
      >
        Lisää
      </Button>
      <Grid sx={{ mt: 1 }}>
        {emails.filter(filterOutPricingEmail).map((email) => {
          return (
            <Chip
              key={email}
              label={email}
              {...(userAllowedToDelete ||
              userAddedEmails.includes(email)
                ? {
                    onDelete: () => {
                      deleteEmail(email);
                    },
                  }
                : {})}
              sx={{
                mt: 1,
                '&:not(:last-of-type)': {
                  mr: 1,
                },
              }}
            />
          );
        })}
      </Grid>
    </Box>
  );
};
