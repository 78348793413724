import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import {
  ExternalFilter,
  ExternalFilterName,
  ExternalFilterType,
} from '../../hooks/useFilters';
import PricingRowCheckBoxFilter from '../Filters/PricingRowCheckBoxFilter';
import { PricingBasis } from '../../../../shared/types';

interface PricingFiltersProps {
  addFilter: (filter: ExternalFilter | ExternalFilter[]) => void;
  pricingBasis: PricingBasis;
  toggleActiveFilter: (filter: ExternalFilter) => void;
  isChecked: (filter: ExternalFilter) => boolean;
  clearFilters: () => void;
  showZeroPriceFilter?: boolean;
  shouldShowComparisonFilter?: boolean;
  showCriticalPriceFilter?: boolean;
  showPriceThresholdPriceFilter?: boolean;
  showCoefficientFilters?: boolean;
  showDividers?: boolean;
}

const PricingFilters: React.FC<PricingFiltersProps> = ({
  addFilter,
  toggleActiveFilter,
  isChecked,
  pricingBasis,
  clearFilters,
  showZeroPriceFilter,
  shouldShowComparisonFilter = false,
  showCriticalPriceFilter = false,
  showPriceThresholdPriceFilter = false,
  showCoefficientFilters = true,
  showDividers = true,
}) => {
  const dayFilters = [
    {
      name: ExternalFilterName.ShowWarningRowsDay,
      type: ExternalFilterType.OR,
    },
    {
      name: ExternalFilterName.ShowRedRowsDay,
      type: ExternalFilterType.OR,
    },
    {
      name: ExternalFilterName.ShowOrangeRowsDay,
      type: ExternalFilterType.OR,
    },
    {
      name: ExternalFilterName.ShowPinkRowsDay,
      type: ExternalFilterType.OR,
    },
    {
      name: ExternalFilterName.ShowZeroPriceRowsDay,
      type: ExternalFilterType.OR,
    },
    {
      name: ExternalFilterName.ShowCriticalPriceRowsDay,
      type: ExternalFilterType.OR,
    },
  ];

  const bothFilters = [
    {
      name: ExternalFilterName.ShowWarningRows,
      type: ExternalFilterType.OR,
    },
    {
      name: ExternalFilterName.ShowRedRows,
      type: ExternalFilterType.OR,
    },
    {
      name: ExternalFilterName.ShowOrangeRows,
      type: ExternalFilterType.OR,
    },
    {
      name: ExternalFilterName.ShowPinkRows,
      type: ExternalFilterType.OR,
    },
    {
      name: ExternalFilterName.ShowZeroPriceRows,
      type: ExternalFilterType.OR,
    },
    {
      name: ExternalFilterName.ShowCriticalPriceRows,
      type: ExternalFilterType.OR,
    },
    {
      name: ExternalFilterName.ShowInvalidPriceThresholdRows,
      type: ExternalFilterType.OR,
    },
  ];

  const handleAllWarningFilters = (filter: ExternalFilter) => {
    // in case all warning rows checkbox is checked, clicking it resets all filters
    if (isChecked(filter)) {
      clearFilters();
    } else {
      if (pricingBasis === 'DAY') {
        addFilter(dayFilters);
      } else {
        addFilter(bothFilters);
      }
    }
  };

  return (
    <>
      {showDividers && <Divider sx={{ my: 1 }} />}
      <Grid container direction="row" sx={{ mb: 1, mt: 1 }}>
        <Grid container direction="row">
          <Grid item>
            <PricingRowCheckBoxFilter
              label="Kaikki varoitusrivit"
              checked={isChecked}
              handleChecked={(filter) =>
                handleAllWarningFilters(filter)
              }
              filterType={
                pricingBasis === 'DAY'
                  ? {
                      name: ExternalFilterName.ShowWarningRowsDay,
                      type: ExternalFilterType.OR,
                    }
                  : {
                      name: ExternalFilterName.ShowWarningRows,
                      type: ExternalFilterType.OR,
                    }
              }
            />
          </Grid>
          {showCoefficientFilters && (
            <Grid item>
              <PricingRowCheckBoxFilter
                label="Hintakerroin alle 1"
                checked={isChecked}
                handleChecked={toggleActiveFilter}
                filterType={
                  pricingBasis === 'DAY'
                    ? {
                        name: ExternalFilterName.ShowRedRowsDay,
                        type: ExternalFilterType.OR,
                      }
                    : {
                        name: ExternalFilterName.ShowRedRows,
                        type: ExternalFilterType.OR,
                      }
                }
              />
            </Grid>
          )}
          {showCoefficientFilters && (
            <Grid item>
              <PricingRowCheckBoxFilter
                label="Hintakerroin 1-1,2"
                checked={isChecked}
                handleChecked={toggleActiveFilter}
                filterType={
                  pricingBasis === 'DAY'
                    ? {
                        name: ExternalFilterName.ShowOrangeRowsDay,
                        type: ExternalFilterType.OR,
                      }
                    : {
                        name: ExternalFilterName.ShowOrangeRows,
                        type: ExternalFilterType.OR,
                      }
                }
              />
            </Grid>
          )}
          <Grid item>
            <PricingRowCheckBoxFilter
              label="Hinta alle 70% ehdotushinnasta"
              checked={isChecked}
              handleChecked={toggleActiveFilter}
              filterType={
                pricingBasis === 'DAY'
                  ? {
                      name: ExternalFilterName.ShowPinkRowsDay,
                      type: ExternalFilterType.OR,
                    }
                  : {
                      name: ExternalFilterName.ShowPinkRows,
                      type: ExternalFilterType.OR,
                    }
              }
            />
          </Grid>
          {showZeroPriceFilter && (
            <Grid item>
              <PricingRowCheckBoxFilter
                label="Nollahintaiset rivit"
                checked={isChecked}
                handleChecked={toggleActiveFilter}
                filterType={
                  pricingBasis === 'DAY'
                    ? {
                        name: ExternalFilterName.ShowZeroPriceRowsDay,
                        type: ExternalFilterType.OR,
                      }
                    : {
                        name: ExternalFilterName.ShowZeroPriceRows,
                        type: ExternalFilterType.OR,
                      }
                }
              />
            </Grid>
          )}

          {showPriceThresholdPriceFilter && (
            <Grid item>
              <PricingRowCheckBoxFilter
                label="Virheellinen PV->KK kerroin"
                checked={isChecked}
                handleChecked={toggleActiveFilter}
                filterType={{
                  name: ExternalFilterName.ShowInvalidPriceThresholdRows,
                  type: ExternalFilterType.OR,
                }}
              />
            </Grid>
          )}

          {showCriticalPriceFilter && (
            <Grid item>
              <PricingRowCheckBoxFilter
                label="Kriittisen hinnan alitukset"
                checked={isChecked}
                handleChecked={toggleActiveFilter}
                filterType={
                  pricingBasis === 'DAY'
                    ? {
                        name: ExternalFilterName.ShowCriticalPriceRowsDay,
                        type: ExternalFilterType.OR,
                      }
                    : {
                        name: ExternalFilterName.ShowCriticalPriceRows,
                        type: ExternalFilterType.OR,
                      }
                }
              />
            </Grid>
          )}

          {shouldShowComparisonFilter && (
            <Grid item>
              <PricingRowCheckBoxFilter
                label="Ei vertailuhintaa"
                checked={isChecked}
                handleChecked={toggleActiveFilter}
                filterType={
                  pricingBasis === 'DAY'
                    ? {
                        name: ExternalFilterName.ShowComparisonPriceEmptyDay,
                        type: ExternalFilterType.OR,
                      }
                    : {
                        name: ExternalFilterName.ShowComparisonPriceEmpty,
                        type: ExternalFilterType.OR,
                      }
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {showDividers && <Divider sx={{ mt: 1 }} />}
    </>
  );
};

export default PricingFilters;
