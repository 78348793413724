import { isBefore } from 'date-fns';
import { getDateWithoutTime } from '../../../shared/utils/getDateWithoutTime';

type DatePickerValue = Date | string | null;

export const validateDatePickerValue = ({
  maxDate,
  minDate,
  required = true,
  value,
}: {
  minDate?: DatePickerValue;
  maxDate?: DatePickerValue;
  required?: boolean;
  value: DatePickerValue;
}): string | null => {
  if (required && !value) {
    return 'Kenttä on pakollinen';
  }
  if (value instanceof Date && value.toString() === 'Invalid Date') {
    return 'Tarkista päivämäärä';
  }
  if (
    value &&
    minDate &&
    isBefore(getDateWithoutTime(value), getDateWithoutTime(minDate))
  ) {
    return 'Päättymispäivä ei voi olla ennen alkamispäivää';
  }
  if (
    value &&
    maxDate &&
    isBefore(getDateWithoutTime(maxDate), getDateWithoutTime(value))
  ) {
    return 'Alkamispäivä ei voi olla päättymispäivän jälkeen';
  }
  return null;
};

export default validateDatePickerValue;
