import React, { useContext, useState, useEffect } from 'react';

import Slider from '@mui/material/Slider';
import { Box } from '@mui/system';

import BackgroundProvider from '../../../../providers/Background/BackgroundProvider';
import PricingProvider from '../../../../providers/Pricing/PricingProvider';

import { formatRevenue } from '../../InitialInformationStep';
import { currencyMultiplier } from '../../../../../../shared/constants';

interface PotentialSliderProps {
  initial: number;
  changeValuesEnabled: boolean;
}
const PotentialSlider: React.FC<PotentialSliderProps> = ({
  initial,
  changeValuesEnabled,
}) => {
  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );
  const { pricingSheetId } = useContext(PricingProvider);
  const [value, setValue] = useState(
    backgroundInfo?.potential?.top
      ? backgroundInfo?.potential?.top
      : initial,
  );

  const minMarks = [
    {
      value: 2 * currencyMultiplier,
      label: '10 000 €',
    },
    {
      value: 5 * currencyMultiplier,
      label: '25 000 €',
    },
    {
      value: 10 * currencyMultiplier,
      label: '50 000 €',
    },
    {
      value: 15 * currencyMultiplier,
      label: '75 000 €',
    },
    {
      value: 20 * currencyMultiplier,
      label: '100 000 €',
    },
  ];

  const largeMarks = [
    {
      value: 20 * currencyMultiplier,
      label: '100 000 €',
    },
    {
      value: 100 * currencyMultiplier,
      label: '500 000 €',
    },
    {
      value: 200 * currencyMultiplier,
      label: '1 000 000 €',
    },
  ];

  useEffect(() => {
    const newBottom =
      (backgroundInfo?.commitment / 100) * value
        ? (backgroundInfo?.commitment / 100) * value
        : backgroundInfo?.bottom;
    const newCommitment =
      (backgroundInfo?.potential?.bottom /
        backgroundInfo?.potential?.top) *
      100
        ? (backgroundInfo?.potential?.bottom /
            backgroundInfo?.potential?.top) *
          100
        : backgroundInfo?.commitment;
    setBackgroundInfo({
      ...backgroundInfo,
      potential: {
        bottom: isNaN(newBottom) ? 0 : Number(newBottom),
        top: Number(value),
        commitment: newCommitment,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (_event: any, newValue: any) => {
    setValue((newValue / 5000).toPrecision(2));
  };

  useEffect(() => {
    if (!pricingSheetId) {
      if (
        backgroundInfo?.potentialOver100k &&
        backgroundInfo?.potential?.top <= 20
      ) {
        setValue(20);
      }
      if (
        !backgroundInfo?.potentialOver100k &&
        backgroundInfo?.potential?.top > 20
      ) {
        setValue(20);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundInfo]);

  return (
    <Box display="flex">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {backgroundInfo?.potentialOver100k ||
        backgroundInfo?.potential?.top > 20 ? (
          <Slider
            valueLabelDisplay={'auto'}
            valueLabelFormat={`${formatRevenue(
              backgroundInfo?.potential?.top,
            )}`}
            sx={{ ml: 4, mr: 4 }}
            value={
              backgroundInfo?.potential?.top * currencyMultiplier
            }
            onChange={handleChange}
            aria-label="potential"
            step={20 * currencyMultiplier}
            marks={largeMarks}
            min={20 * currencyMultiplier}
            max={200 * currencyMultiplier}
            disabled={
              pricingSheetId && !changeValuesEnabled ? true : false
            }
          />
        ) : (
          <Slider
            valueLabelDisplay={'auto'}
            valueLabelFormat={`${formatRevenue(
              backgroundInfo?.potential?.top,
            )}`}
            sx={{ ml: 2, mr: 2 }}
            value={
              backgroundInfo?.potential?.top * currencyMultiplier
            }
            onChange={handleChange}
            aria-label="potential"
            step={0.5 * currencyMultiplier}
            marks={minMarks}
            min={0}
            max={20 * currencyMultiplier}
            disabled={
              pricingSheetId && !changeValuesEnabled ? true : false
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default PotentialSlider;
