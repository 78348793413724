import { PricingSheetRow } from '../../../shared/types';

export const itemFilter = (
  row: PricingSheetRow,
  filterValue: string | undefined,
) => {
  const itemFilterValueLowerCase = filterValue
    ? filterValue?.toLowerCase()
    : '';

  return `${row.catClass} ${row.name}`
    ?.toLowerCase()
    .includes(itemFilterValueLowerCase);
};
