import {
  DataTableRowActionsContainer,
  DataTableRowActionsProps,
} from '../../../components/Common/DataTable/DataTableRowActions';
import {
  DatabaseOfferWithApprovalRequest,
  OffersTableTabState,
  OfferState,
  OfferStep,
} from '../../../../../shared/types/offers';
import { useHistory } from 'react-router-dom';
import useUserContext from '../../../providers/User/UserProvider';
import { CUSTOMER_RESPONDED_OFFER_STATES } from '../../../../../shared/constants';
import React, { useCallback } from 'react';
import {
  ActionMenu,
  ActionMenuItem,
} from '../../../components/Common/ActionMenu';
import { IconButton } from '@mui/material';
import {
  Edit,
  MoreVert,
  PictureAsPdf,
  RemoveRedEye,
} from '@mui/icons-material';
import { RenderComponentProps } from './OfferSheetsTable';
import DefaultTooltip from '../../../components/Tooltips/DefaultTooltip';
import {
  useOfferItemsPdf,
  useOfferPdf,
} from '../../../hooks/useOfferPdf';

type OffersTableRowActionsProps = RenderComponentProps<
  DataTableRowActionsProps<DatabaseOfferWithApprovalRequest>['renderRowActions']
>;

export const OffersTableRowActions: OffersTableRowActionsProps = (
  { item, infoToggle },
  { activeTab, loading },
) => {
  const history = useHistory();
  const { userEmail: currentUserEmail } = useUserContext();
  const {
    id,
    name,
    previousState,
    state,
    userEmail: offerUserEmail,
  } = item;
  // determines the label of the download pdf button and whether the pdf will include a draft watermark
  const isDraft = ![
    ...CUSTOMER_RESPONDED_OFFER_STATES,
    OfferState.Sent,
    OfferState.ReadyForSending,
  ].includes(state);

  // download offer pdf query
  const { refetch: downloadPdf, isFetching: isFetchingPdf } =
    useOfferPdf({
      id: String(id),
      draft: isDraft,
      pdfName: name,
    });
  // download offer items pdf query
  const {
    refetch: downloadItemsPdf,
    isFetching: isFetchingItemsPdf,
  } = useOfferItemsPdf({
    id: String(id),
    draft: isDraft,
    pdfName: name,
  });

  const editDisabled =
    offerUserEmail !== currentUserEmail ||
    state === OfferState.ReadyForSending;

  const handleEdit = useCallback(() => {
    const editPath =
      state === OfferState.InternalApproval
        ? `/tarjous/${id}?t=${OfferStep.Summary}`
        : `/tarjous/${id}?t=${OfferStep.Pricing}`;
    history.push(editPath);
  }, [history, id, state]);

  // download pdf action
  const downloadPdfAction = useCallback(
    (closeMenu: () => void, itemsOnly = false) => {
      const label = isDraft
        ? 'Lataa luonnos'
        : `Lataa PDF ${itemsOnly ? '(tuotteet)' : ''}`;
      const downloadFn = itemsOnly ? downloadItemsPdf : downloadPdf;
      return (
        <ActionMenuItem
          key={itemsOnly ? 'downloadItemsPdf' : 'downloadPdf'}
          disabled={isFetchingItemsPdf || isFetchingPdf || loading}
          icon={PictureAsPdf}
          label={label}
          loading={itemsOnly ? isFetchingItemsPdf : isFetchingPdf}
          onClick={() => downloadFn().then(() => closeMenu())}
        />
      );
    },
    [
      isDraft,
      downloadItemsPdf,
      downloadPdf,
      isFetchingPdf,
      isFetchingItemsPdf,
      loading,
    ],
  );

  // inspect action
  const inspectAction = useCallback(() => {
    const offerCanBeInspected = (state: OfferState) => {
      return (
        state === OfferState.ReadyForSending ||
        state === OfferState.Sent ||
        CUSTOMER_RESPONDED_OFFER_STATES.includes(state)
      );
    };
    const canBeInspected =
      state === OfferState.Expired && previousState
        ? offerCanBeInspected(previousState)
        : offerCanBeInspected(state);
    return (
      <ActionMenuItem
        key={'inspect'}
        disabled={!canBeInspected || loading}
        icon={RemoveRedEye}
        label={'Tarkastele'}
        onClick={() => {
          history.push(`/tarjous/${id}/tarkastele`);
        }}
      />
    );
  }, [history, id, loading, previousState, state]);

  const offerActions = useCallback(() => {
    switch (activeTab) {
      case OffersTableTabState.Archived:
        return null;
      case OffersTableTabState.Sent:
        return (
          <>
            <ActionMenu
              toggleIcon={<MoreVert />}
              renderItems={(closeMenu) => {
                return [
                  // INSPECT
                  inspectAction(),
                  // DOWNLOAD PDF
                  downloadPdfAction(closeMenu),
                  // DOWNLOAD PDF (items only)
                  downloadPdfAction(closeMenu, true),
                ];
              }}
            />
          </>
        );
      case OffersTableTabState.Incomplete:
        return (
          <>
            {/* EDIT */}
            {!editDisabled && (
              <DefaultTooltip title={'Muokkaa'} placement={'top'}>
                <IconButton
                  size={'small'}
                  color={'primary'}
                  disabled={editDisabled || loading}
                  onClick={handleEdit}
                  sx={{ fontSize: '1.25rem', p: '7px' }}
                >
                  <Edit fontSize={'inherit'} />
                </IconButton>
              </DefaultTooltip>
            )}
            <ActionMenu
              toggleIcon={<MoreVert />}
              renderItems={(closeMenu) => {
                return [
                  // INSPECT
                  inspectAction(),
                  // DOWNLOAD PDF
                  downloadPdfAction(closeMenu),
                  // DOWNLOAD PDF (items only)
                  ...(state === OfferState.ReadyForSending
                    ? [downloadPdfAction(closeMenu, true)]
                    : []),
                ];
              }}
            />
          </>
        );
    }
  }, [
    activeTab,
    downloadPdfAction,
    editDisabled,
    handleEdit,
    inspectAction,
    loading,
    state,
  ]);

  return (
    <DataTableRowActionsContainer
      sx={{
        justifyContent: 'end',
        px: 1,
      }}
    >
      {!loading && offerActions()}
      {infoToggle}
    </DataTableRowActionsContainer>
  );
};
