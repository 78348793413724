import {
  CreateOfferApprovalRequestBody,
  CreateOfferApprovalRequestParams,
  CreateOfferApprovalResponseBody,
  CreateOfferRequestBody,
  CreateOfferResponseBody,
  CreateRevisionRequestBody,
  CreateRevisionRequestParams,
  CreateRevisionResponseBody,
  DatabaseOfferApprovalRequestWithOffer,
  DatabaseOfferWithApprovalRequest,
  GetOfferApprovalRequestRequestParams,
  GetOfferApprovalRequestResponseBody,
  GetOfferApprovalRequestsResponseBody,
  GetOfferApprovalRequestsTableFilterOptions,
  GetOfferPdfRequestParams,
  GetOfferPdfResponseBody,
  GetOfferRequestParams,
  GetOfferRequestQueryParams,
  GetOfferResponseBody,
  GetOffersResponseBody,
  GetOffersTableFilterOptions,
  ResolveOfferApprovalRequestBody,
  ResolveOfferApprovalRequestParams,
  ResolveOfferApprovalResponseBody,
  UpdatedDatabaseOfferItem,
  UpdateOfferApprovalRequestBody,
  UpdateOfferApprovalRequestParams,
  UpdateOfferApprovalResponseBody,
  UpdateOfferItemRequestBody,
  UpdateOfferItemRequestParams,
  UpdateOfferItemResponseBody,
  UpdateOfferItemsRequestParams,
  UpdateOfferItemsResponseBody,
  UpdateOfferRequestBody,
  UpdateOfferRequestParams,
  UpdateOfferResponseBody,
  DeleteOfferItemsResponseBody,
  GetOfferPdfRequestQueryParams,
} from '../../../shared/types/offers';
import { getBlobFromBuffer } from '../utils/getBlobFromBuffer';
import { downloadFile } from '../utils/downloadFile';
import { api } from '../index';
import { QueryFunctionContext } from '@tanstack/react-query';
import { convertDataTableFiltersToSearchParams } from '../helpers/convertDataTableFiltersToSearchParams';
import { UseDataTableQueryKey } from '../hooks/useDataTableFiltering';

/**
 * CREATE OFFER SHEET
 */
export const createOfferSheet = async (
  // filter out items from payload
  { items, ...offer }: CreateOfferRequestBody,
): Promise<CreateOfferResponseBody> => {
  const { data } = await api.post<CreateOfferResponseBody>(
    '/offers',
    offer,
  );
  return data;
};

/**
 * GET ALL OFFER SHEETS
 */
export const getOfferSheets = async ({
  queryKey,
}: QueryFunctionContext<
  UseDataTableQueryKey<
    DatabaseOfferWithApprovalRequest,
    GetOffersTableFilterOptions
  >
>): Promise<GetOffersResponseBody> => {
  const { data } = await api.get<GetOffersResponseBody>(`/offers`, {
    params: convertDataTableFiltersToSearchParams(queryKey),
  });
  return data;
};

/**
 * GET OFFER SHEET
 */
export const getOfferSheet = async ({
  id,
  readOnly,
}: GetOfferRequestParams &
  GetOfferRequestQueryParams): Promise<GetOfferResponseBody> => {
  const { data } = await api.get<GetOfferResponseBody>(
    `/offers/${id}${readOnly ? '?readOnly=true' : ''}`,
  );
  return data;
};

/**
 * UPDATE OFFER SHEET
 */
export type UpdateOfferSheetFnProps = UpdateOfferRequestParams & {
  item: UpdateOfferRequestBody;
};
export const updateOfferSheet = async ({
  id,
  item: {
    // filter out items from payload
    items,
    ...item
  },
}: UpdateOfferSheetFnProps): Promise<UpdateOfferResponseBody> => {
  const { data } = await api.patch<UpdateOfferResponseBody>(
    `/offers/${id}`,
    item,
  );
  return data;
};

/**
 * CREATE NEW OFFER SHEET REVISION
 */
type CreateOfferRevisionFnProps = CreateRevisionRequestParams &
  CreateRevisionRequestBody;
export const createOfferRevision = async ({
  id,
  ...dates
}: CreateOfferRevisionFnProps): Promise<CreateRevisionResponseBody> => {
  const { data } = await api.post<CreateRevisionResponseBody>(
    `/offers/${id}/new-revision`,
    dates,
  );
  return data;
};

/**
 * CREATE / UPDATE OFFER SHEET ITEMS
 */
type UpdateOfferSheetItemsFnProps = UpdateOfferItemsRequestParams & {
  items: UpdatedDatabaseOfferItem[];
};
export const updateOfferSheetItems = async ({
  id: offerSheetId,
  items,
}: UpdateOfferSheetItemsFnProps): Promise<UpdateOfferItemsResponseBody> => {
  const { data } = await api.put<UpdateOfferItemsResponseBody>(
    `/offers/${offerSheetId}/items`,
    items,
  );
  return data;
};

/**
 * DELETE OFFER SHEET ITEMS
 */
type DeleteOfferSheetItemsFnProps = {
  id: number[];
};
export const deleteOfferSheetItems = async ({
  id,
}: DeleteOfferSheetItemsFnProps): Promise<DeleteOfferItemsResponseBody> => {
  const params = new URLSearchParams(
    id.map((_id) => `id=${_id}`).join('&'),
  );
  await api.delete<DeleteOfferItemsResponseBody>('/offers/item', {
    params,
  });
};

/**
 * UPDATE *SINGLE* OFFER SHEET ITEM
 */
type UpdateOfferSheetItemFnProps = {
  id?: UpdateOfferItemRequestParams['id'];
  item: UpdateOfferItemRequestBody;
};
export const updateOfferSheetItem = async ({
  id,
  item,
}: UpdateOfferSheetItemFnProps): Promise<UpdateOfferItemResponseBody> => {
  if (!id) {
    throw new Error(
      `Unable to update offer sheet item: No ID was passed.`,
    );
  }
  const { data } = await api.patch<UpdateOfferItemResponseBody>(
    `/offers/item/${id}`,
    item,
  );
  return data;
};

/**
 * DOWNLOAD OFFER PDF
 */
export type GetOfferPdfFnProps = GetOfferPdfRequestParams &
  GetOfferPdfRequestQueryParams & {
    pdfName?: string;
  };
export const downloadOfferPdf = async ({
  id,
  draft = false,
  itemsOnly = false,
  pdfName = 'tarjous',
}: GetOfferPdfFnProps): Promise<boolean> => {
  try {
    const response = await api.get<GetOfferPdfResponseBody>(
      `/offers/${id}/pdf`,
      {
        params: {
          draft,
          itemsOnly,
        },
        responseType: 'blob',
      },
    );
    const pdfBlob = getBlobFromBuffer(response.data);
    downloadFile(pdfBlob, pdfName);
    return true;
  } catch (e) {
    console.error(`Failed to download PDF: ${e}`);
    return false;
  }
};

/**
 * GET OFFER APPROVAL REQUEST
 */
export const getOfferApprovalRequest = async (
  id?: GetOfferApprovalRequestRequestParams['id'],
): Promise<GetOfferApprovalRequestResponseBody> => {
  if (!id) {
    throw new Error(
      `Unable to fetch offer approval request: No ID was passed.`,
    );
  }
  const { data } = await api.get<GetOfferApprovalRequestResponseBody>(
    `/offers/approval/${id}`,
  );
  return data;
};

/**
 * CREATE OFFER APPROVAL REQUEST
 */
export const createOfferApprovalRequest = async ({
  id,
  ...params
}: CreateOfferApprovalRequestParams &
  CreateOfferApprovalRequestBody): Promise<CreateOfferApprovalResponseBody> => {
  const { data } = await api.post<CreateOfferApprovalResponseBody>(
    `/offers/${id}/approval`,
    params,
  );
  return data;
};

/**
 * GET ALL OFFER APPROVAL REQUESTS
 */
export const getOfferApprovalRequests = async ({
  queryKey,
}: QueryFunctionContext<
  UseDataTableQueryKey<
    DatabaseOfferApprovalRequestWithOffer,
    GetOfferApprovalRequestsTableFilterOptions
  >
>): Promise<GetOfferApprovalRequestsResponseBody> => {
  const { data } =
    await api.get<GetOfferApprovalRequestsResponseBody>(
      `/offers/approvals`,
      {
        params: convertDataTableFiltersToSearchParams(queryKey),
      },
    );
  return data;
};

/**
 * UPDATE OFFER APPROVAL REQUEST
 */
type UpdateOfferApprovalRequestFnProps =
  UpdateOfferApprovalRequestParams & {
    item: UpdateOfferApprovalRequestBody;
  };
export const updateOfferApprovalRequest = async ({
  id,
  approvalId,
  item,
}: UpdateOfferApprovalRequestFnProps): Promise<UpdateOfferApprovalResponseBody> => {
  const { data } = await api.patch<UpdateOfferApprovalResponseBody>(
    `/offers/${id}/approval/${approvalId}`,
    item,
  );
  return data;
};

/**
 * RESOLVE OFFER APPROVAL REQUEST
 */
type ResolveOfferApprovalRequestFnProps =
  ResolveOfferApprovalRequestParams & ResolveOfferApprovalRequestBody;
export const resolveOfferApprovalRequest = async ({
  id,
  approvalId,
  ...payload
}: ResolveOfferApprovalRequestFnProps): Promise<ResolveOfferApprovalResponseBody> => {
  const { data } = await api.put<ResolveOfferApprovalResponseBody>(
    `/offers/${id}/approval/${approvalId}/resolve`,
    payload,
  );
  return data;
};
