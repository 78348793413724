import {
  DatabaseOffer,
  OfferDateFieldKeys,
  OfferDateFields,
} from '../../../shared/types/offers';

export const convertDatabaseOfferDatesToDate = <
  T extends DatabaseOffer,
>({
  offerLeasePeriodStart,
  offerLeasePeriodEnd,
  offerValidityStart,
  offerValidityEnd,
  ...offer
}: T): Omit<T, OfferDateFieldKeys> & OfferDateFields<Date> => ({
  ...offer,
  offerLeasePeriodStart: new Date(offerLeasePeriodStart),
  offerLeasePeriodEnd: new Date(offerLeasePeriodEnd),
  offerValidityStart: new Date(offerValidityStart),
  offerValidityEnd: new Date(offerValidityEnd),
});
