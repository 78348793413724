import React from 'react';

import TextField from '@mui/material/TextField';

interface GenericTextFieldFilterProps {
  filterValue: string;
  handleFilter: React.Dispatch<React.SetStateAction<string>>;
  label: string;
}
const GenericTextFieldFilter: React.FC<
  GenericTextFieldFilterProps
> = ({ filterValue, handleFilter, label }) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handleFilter(event.target.value);
  };

  return (
    <>
      <TextField
        id="search-bar"
        label={label}
        variant="standard"
        value={filterValue}
        onChange={handleChange}
      />
    </>
  );
};

export default GenericTextFieldFilter;
