import {
  formatPimNode,
  formatPimPath,
  formatPimCellValue,
} from '../../../../../shared/helpers/pimFormatters';

import HierarchyRenderer from '../Renderers/HierarchyRenderer';
import { ICellEditorParams } from 'ag-grid-community';

import { GridColumnProps } from '../Columns/ColumnsBase';
import { getCatClassColDef } from '../helpers/getCatClassColDef';

const AdminColumnsHierarchy = ({
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps) => {
  const { hierarchyTree } = optionalColumnProps;

  const columns: any = [
    ...getCatClassColDef(gridGroupingValues),
    {
      field: 'pimProductGroup',
      headerName: 'PIM - Tuoteryhmä',
      editable: true,
      filter: false,

      cellEditor: (params: ICellEditorParams) => {
        const pimProductGroup = params?.data?.pimProductGroup;
        const pimPath = formatPimPath(params?.data?.pimPath ?? '', 1);
        const productGroupList =
          Object.values(hierarchyTree).map(formatPimNode);
        return (
          <HierarchyRenderer
            value={formatPimCellValue(pimPath, pimProductGroup)}
            items={productGroupList}
            sheetRow={params?.data}
            hierarchyLevel="group"
          />
        );
      },
      cellStyle: {
        color: '#000',
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'pimProductLine',
      headerName: 'PIM - Tuotelinja',
      editable: true,
      filter: false,
      cellEditor: (params: ICellEditorParams) => {
        const pimProductLine = params?.data?.pimProductLine;
        const pimPath = formatPimPath(params?.data?.pimPath ?? '', 2);
        const path =
          Number(params?.data?.pimPath?.split('/')[0]) ?? 0;
        let productLineList;
        if (path && path !== 0) {
          if (hierarchyTree[path] && hierarchyTree[path].nodes) {
            productLineList = Object.values(
              hierarchyTree[path].nodes,
            ).map(formatPimNode);
          }
        }
        return (
          <HierarchyRenderer
            value={formatPimCellValue(pimPath, pimProductLine)}
            items={productLineList}
            sheetRow={params?.data}
            hierarchyLevel="line"
          />
        );
      },
      cellStyle: {
        color: '#000',
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'pimCategory',
      headerName: 'PIM - Kategoria',
      editable: true,
      filter: false,
      cellEditor: (params: ICellEditorParams) => {
        const pimCategory = params?.data?.pimCategory;
        const pimPath = formatPimPath(params?.data?.pimPath ?? '', 3);
        const pathArray = params?.data?.pimPath?.split('/') ?? [];
        let categoryList;
        if (pathArray?.length >= 2) {
          const productGroup = Number(pathArray[0]);
          const productLine = Number(pathArray[1]);
          if (
            hierarchyTree[productGroup]?.nodes[productLine]?.nodes
          ) {
            categoryList = Object.values(
              hierarchyTree[productGroup].nodes[productLine].nodes,
            ).map(formatPimNode);
          }
        }
        return (
          <HierarchyRenderer
            value={formatPimCellValue(pimPath, pimCategory)}
            items={categoryList}
            sheetRow={params?.data}
            hierarchyLevel="category"
          />
        );
      },
      cellStyle: {
        color: '#000',
        borderLeft: '1px solid #ccc',
        borderRight: '1px solid #ccc',
      },
    },
  ];

  return columns;
};

export default AdminColumnsHierarchy;
