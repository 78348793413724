import { ReactNode, useCallback, useContext, useState } from 'react';
import { Box } from '@mui/material';
import BackgroundInfoInputLabel from '../../components/Steps/Components/BackgroundInfoInputLabel';
import Dates from '../../components/Steps/Components/Dates';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import {
  DatabaseOfferWithItems,
  OfferDateFields,
} from '../../../../shared/types/offers';
import isSameDay from 'date-fns/isSameDay';
import {
  addToSessionStorageArray,
  removeFromSessionStorageArray,
  StorageKeys,
} from '../../stores/sessionStorage';
import { setDatetoStartOfUTCDay } from '../../utils/formatDateTimes';

type OfferDatesSelectorProps = {
  offerLeasePeriodLabel?: ReactNode;
  offerSheet?: DatabaseOfferWithItems;
  offerValidityLabel?: ReactNode;
  initialValues?: OfferDateFields<Date>;
  onChange: (dates: OfferDateFields<Date>) => void;
};

export const OfferDatesSelector = ({
  offerLeasePeriodLabel,
  offerSheet,
  offerValidityLabel,
  initialValues,
  onChange = () => null,
}: OfferDatesSelectorProps) => {
  const { setNotification } = useContext(NotificationProvider);
  const [state, setState] = useState(
    initialValues || {
      offerLeasePeriodStart: setDatetoStartOfUTCDay(new Date()),
      offerLeasePeriodEnd: setDatetoStartOfUTCDay(new Date()),
      offerValidityStart: setDatetoStartOfUTCDay(new Date()),
      offerValidityEnd: setDatetoStartOfUTCDay(new Date()),
    },
  );
  const [startDateHasChanged, setStartDateHasChanged] =
    useState(false);
  const [endDateHasChanged, setEndDateHasChanged] = useState(false);

  const {
    offerLeasePeriodStart,
    offerLeasePeriodEnd,
    offerValidityStart,
    offerValidityEnd,
  } = state;

  const handleStartDateChange = useCallback(
    (field: 'offerLeasePeriodStart' | 'offerValidityStart') =>
      (value: Date | null) => {
        if (!value) {
          return;
        }
        value = setDatetoStartOfUTCDay(value);
        const date = offerSheet
          ? setDatetoStartOfUTCDay(
              new Date(offerSheet.offerLeasePeriodStart),
            )
          : setDatetoStartOfUTCDay(new Date());
        if (isSameDay(date, value)) {
          setStartDateHasChanged(() => false);
          if (offerSheet && !endDateHasChanged) {
            removeFromSessionStorageArray(
              StorageKeys.ShowLeaseDateChangedDialog,
              offerSheet.id,
            );
          }
        } else {
          setStartDateHasChanged(() => true);
          if (offerSheet)
            addToSessionStorageArray(
              StorageKeys.ShowLeaseDateChangedDialog,
              offerSheet.id,
            );
        }
        let updatedState: OfferDateFields<Date> = {
          ...state,
          [field]: value,
        };
        if (
          (field === 'offerLeasePeriodStart' &&
            offerLeasePeriodEnd < value) ||
          (field === 'offerValidityStart' && offerValidityEnd < value)
        ) {
          setNotification({
            type: 'SNACKBAR',
            duration: 3000,
            severity: 'warning',
            message: 'Päättymispäivä ei voi olla ennen alkamispäivää',
          });
          const fieldToUpdate =
            field === 'offerLeasePeriodStart'
              ? 'offerLeasePeriodEnd'
              : 'offerValidityEnd';
          updatedState = {
            ...updatedState,
            [fieldToUpdate]: value,
          };
        }
        setState(updatedState);
        onChange(updatedState);
      },
    [
      endDateHasChanged,
      offerLeasePeriodEnd,
      offerSheet,
      offerValidityEnd,
      onChange,
      setNotification,
      state,
    ],
  );

  const handleEndDateChange = useCallback(
    (field: 'offerLeasePeriodEnd' | 'offerValidityEnd') =>
      (value: Date | null) => {
        if (!value) {
          return;
        }
        value = setDatetoStartOfUTCDay(value);
        const date = offerSheet
          ? setDatetoStartOfUTCDay(
              new Date(offerSheet.offerLeasePeriodEnd),
            )
          : setDatetoStartOfUTCDay(new Date());
        if (isSameDay(date, value)) {
          setEndDateHasChanged(() => false);
          if (offerSheet && !startDateHasChanged) {
            removeFromSessionStorageArray(
              StorageKeys.ShowLeaseDateChangedDialog,
              offerSheet.id,
            );
          }
        } else {
          setEndDateHasChanged(() => true);
          if (offerSheet)
            addToSessionStorageArray(
              StorageKeys.ShowLeaseDateChangedDialog,
              offerSheet.id,
            );
        }
        const updatedState: OfferDateFields<Date> = {
          ...state,
          [field]: value,
        };
        onChange(updatedState);
        setState(updatedState);
      },
    [offerSheet, onChange, startDateHasChanged, state],
  );

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '1.5rem',
        gridAutoFlow: 'column',
      }}
    >
      <div>
        {offerLeasePeriodLabel || (
          <BackgroundInfoInputLabel label="Vuokra-aika*" />
        )}
        <Dates
          onStartDateChange={handleStartDateChange(
            'offerLeasePeriodStart',
          )}
          onEndDateChange={handleEndDateChange('offerLeasePeriodEnd')}
          startDate={offerLeasePeriodStart}
          endDate={offerLeasePeriodEnd}
          minStartDate={new Date()}
          startDateLabel={'Vuokra-aika alkaa'}
          endDateLabel={'Vuokra-aika loppuu'}
        />
      </div>
      <div>
        {offerValidityLabel || (
          <BackgroundInfoInputLabel label="Tarjouksen voimassaoloaika*" />
        )}
        <Dates
          onStartDateChange={handleStartDateChange(
            'offerValidityStart',
          )}
          onEndDateChange={handleEndDateChange('offerValidityEnd')}
          startDate={offerValidityStart}
          endDate={offerValidityEnd}
          minStartDate={new Date()}
          startDateLabel={'Voimassaoloaika alkaa'}
          endDateLabel={'Voimassaoloaika loppuu'}
        />
      </div>
    </Box>
  );
};
