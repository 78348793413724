const getPriceSheetTypeAbbreviation = (priceSheetType: string) => {
  if (priceSheetType === 'Projekti') {
    return 'PR';
  }
  if (priceSheetType === 'Vuosisopimus') {
    return 'VS';
  }
  if (priceSheetType === 'Erillinen sopimus') {
    return 'ER';
  }
  if (priceSheetType === 'Jäsenhinnasto') {
    return 'JH';
  }
  if (priceSheetType === 'Referenssi') {
    return 'RF';
  }
  return 'Unknown';
};

export default getPriceSheetTypeAbbreviation;
