import React, { ChangeEvent } from 'react';

import { TextField } from '@mui/material';

interface MultiLineCommentProps {
  label: string;
  placeholder: string;
  handleCommentChange: (event: ChangeEvent<HTMLInputElement>) => void;
  required: boolean;
}
const MultiLineComment: React.FC<MultiLineCommentProps> = ({
  label,
  placeholder,
  handleCommentChange,
  required,
}) => {
  return (
    <>
      <TextField
        required={required}
        id="comment-cell"
        label={label}
        multiline
        rows={4}
        placeholder={placeholder}
        onChange={handleCommentChange}
        fullWidth
        sx={{ pr: 2 }}
      />
    </>
  );
};

export default MultiLineComment;
