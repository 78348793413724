import { useContext, useState, ChangeEvent } from 'react';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import PricingProvider from '../../providers/Pricing/PricingProvider';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import BackgroundProvider from '../../providers/Background/BackgroundProvider';
import RowsProvider from '../../providers/Rows/RowsProvider';

import { updatePricingSheetById } from '../../services/pricingSheets';
import { createApprovalRequest } from '../../services/approvalRequests';

import getSheetState from '../../helpers/getSheetState';

import { useHistory } from 'react-router-dom';

import MultiLineComment from '../Common/MultiLineComment';

import AcceptanceMatrix from './Components/AcceptanceMatrix';

import {
  differenceInDays,
  formatRentalPotential,
  formatRevenue,
} from './InitialInformationStep';

import { WARNING_THRESHOLD } from '../../utils/pricingFormulas';
import {
  ApprovalReasonsType,
  PricingSheetState,
} from '../../../../shared/types';
import {
  currencyMultiplier,
  SUMMARY_COLORS,
} from '../../../../shared/constants';
import { EditingRights } from './Components/EditingRights';
import { isValidEmail } from '../../utils/isValidEmail';
import useUserContext from '../../providers/User/UserProvider';
import { formatPricingBasis } from '../../helpers/formatPricingBasis';
import SummaryList from './Components/SummaryList';
import {
  AccountBox,
  Euro,
  Event,
  House,
  Money,
  Today,
} from '@mui/icons-material';
import { formatDateTolocaleDateString } from '../../utils/formatDateTimes';

const SummaryStep: React.FC = () => {
  const { pricingSheetId } = useContext(PricingProvider);
  const { setNotification } = useContext(NotificationProvider);
  const { backgroundInfo } = useContext(BackgroundProvider);

  const { preSelectedRows, warningCounts } = useContext(RowsProvider);
  const { userId } = useUserContext();

  const {
    dayWarnings,
    dayLightWarnings,
    dayBelow70Warnings,
    monthWarnings,
    monthLightWarnings,
    monthBelow70Warnings,
    allBelow70PercentRows,
  } = warningCounts;

  // red rows
  const underWarningPriceCount =
    preSelectedRows && dayWarnings && monthWarnings
      ? dayWarnings.length + monthWarnings.length
      : undefined;

  // orange rows
  const underCoefficientCount =
    preSelectedRows && dayLightWarnings && monthLightWarnings
      ? dayLightWarnings.length + monthLightWarnings.length
      : undefined;

  // pink rows
  const below70PercentRowsCount = allBelow70PercentRows.length;

  let history = useHistory();

  const [sheetStateInFinnish] = useState<string | undefined>(
    getSheetState(backgroundInfo?.state),
  );

  const approvePricingSheet = async () => {
    try {
      await updatePricingSheetById(pricingSheetId, {
        state: PricingSheetState.Approved,
      });
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: 'Hinnasto hyväksytty!',
      });
      history.push('/hinnastot');
    } catch (error) {
      console.error(error);
      setNotification({
        severity: 'error',
        message: 'Hinnaston hyväksyminen epäonnistui!',
      });
    }
  };

  const warningText = `
  Hintakertoimen 1 alituksia ei saa olla yhtään kappaletta. Muokkaa joko hintoja tai pyydä
  hyväksyntää esihenkilöltä!
`;

  const coefficientText = `
Hintakertoimen 1,2 alituksia saa olla maksimissaan 5 kappaletta. Muokkaa joko hintoja tai pyydä
hyväksyntää esihenkilöltä!
`;

  const below70PercentText = `
Yksikään hinta ei saa olla alle 70 % ehdotushinnasta. Muokkaa joko hintoja tai pyydä
hyväksyntää esihenkilöltä!
`;

  const warningAlertText = () => {
    const day = `Hintakertoimen 1 alituksia: ${dayWarnings.length} kpl päivätasolla`;
    const month = `, ${monthWarnings.length} kpl kuukausitasolla`;

    return backgroundInfo.pricingBasis === 'DAY'
      ? day
      : day.concat(month);
  };

  const lightWarningAlertText = () => {
    const day = `Hintakertoimen 1,2 alituksia:
    ${dayLightWarnings.length} kpl päivätasolla`;

    const month = `, ${monthLightWarnings.length} kpl kuukausitasolla`;

    return backgroundInfo.pricingBasis === 'DAY'
      ? day
      : day.concat(month);
  };

  const below70PercentAlertText = () => {
    const day = `Hinta alle 70 % ehdotushinnasta :
    ${dayBelow70Warnings.length} kpl päivätasolla`;

    const month = `, ${monthBelow70Warnings.length} kpl kuukausitasolla`;

    return backgroundInfo.pricingBasis === 'DAY'
      ? day
      : day.concat(month);
  };

  const CountView = () => {
    return (
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}
      >
        <Typography
          sx={{ ml: 1 }}
          variant="h6"
          component="div"
          gutterBottom
          color="primary"
        >
          Hinnaston tila: {sheetStateInFinnish}
        </Typography>
        <Typography
          sx={{ ml: 1 }}
          variant="h6"
          component="div"
          gutterBottom
          color="primary"
        >
          Hinnaston tyyppi:{' '}
          {formatPricingBasis(backgroundInfo.pricingBasis)}
        </Typography>

        <Stack spacing={2}>
          <Alert
            variant="outlined"
            severity={
              underWarningPriceCount && underWarningPriceCount > 0
                ? 'error'
                : 'success'
            }
            sx={{
              backgroundColor:
                underWarningPriceCount && underWarningPriceCount > 0
                  ? SUMMARY_COLORS['warningBorder']
                  : SUMMARY_COLORS['success'],
            }}
          >
            {warningAlertText()}
          </Alert>
          <Alert
            variant="outlined"
            severity={
              underCoefficientCount && underCoefficientCount > 0
                ? underCoefficientCount > WARNING_THRESHOLD
                  ? 'error'
                  : 'warning'
                : 'success'
            }
            sx={{
              backgroundColor:
                underCoefficientCount && underCoefficientCount > 0
                  ? SUMMARY_COLORS['lightWarning']
                  : SUMMARY_COLORS['success'],
              borderColor:
                underCoefficientCount && underCoefficientCount > 0
                  ? SUMMARY_COLORS['lightWarningBorder']
                  : undefined,
            }}
          >
            {lightWarningAlertText()}
          </Alert>
          <Alert
            variant="outlined"
            severity={
              below70PercentRowsCount && below70PercentRowsCount > 0
                ? 'error'
                : 'success'
            }
            sx={{
              backgroundColor:
                below70PercentRowsCount && below70PercentRowsCount > 0
                  ? SUMMARY_COLORS['below70Border']
                  : SUMMARY_COLORS['success'],
            }}
          >
            {below70PercentAlertText()}
          </Alert>
        </Stack>
      </Box>
    );
  };

  const RequireApprovalView = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      setEmail(event.target.value);
    };

    const [comment, setComment] = useState<string | undefined>(
      undefined,
    );

    const handleCommentChange = (
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      setComment(event.target.value);
    };

    const approvalReasons: ApprovalReasonsType = {
      // red rows
      belowOneCoefficient: {
        count: underWarningPriceCount,
        requireApproval: warningCountCondition,
      },
      // orange rows
      below1Point2Coefficient: {
        count: underCoefficientCount,
        requireApproval: coefficientCountCondition,
      },
      // pink rows
      below70PercentOfPropositionPrice: {
        count: below70PercentRowsCount,
        requireApproval: below70PercentRowsCondition,
      },
      paymentTerms: {
        requireApproval: paymentTermDaysCondition,
      },
      sheetPeriod: {
        requireApproval: sheetPeriodCondition,
      },
      valueAbove500: {
        requireApproval: sheetValueNationWideCondition,
      },
      valueAbove200: {
        requireApproval: sheetValueNotNationWideCondition,
      },
    };

    const askApproval = async () => {
      if (email && comment && approvalReasons) {
        const createApprovalRequestResponse =
          await createApprovalRequest(
            pricingSheetId,
            email,
            comment,
            approvalReasons,
          );

        if (createApprovalRequestResponse?.status === 200) {
          try {
            await updatePricingSheetById(pricingSheetId, {
              state: PricingSheetState.Pending,
              creatorComment: comment,
            });
            setNotification({
              type: 'SNACKBAR',
              duration: 4000,
              severity: 'success',
              message: 'Hinnaston hyväksymispyyntö lähetetty!',
            });
            history.push('/hinnastot');
          } catch (error) {
            console.error(error);
            setNotification({
              severity: 'error',
              message:
                'Hinnaston hyväksymispyynnön lähetys epäonnistui!',
            });
          }
        } else {
          setNotification({
            severity: 'error',
            message: 'Virhe!',
          });
        }
      } else {
        setNotification({
          type: 'SNACKBAR',
          duration: 4000,
          severity: 'error',
          message: 'Kirjoita sähköpostiosoite sekä perustelut!',
        });
      }
    };

    const validEmail = isValidEmail(email);

    return (
      <>
        <Box sx={{ mt: 3, mb: 3 }}>
          {warningCountCondition ? (
            <Typography
              sx={{ m: 1 }}
              variant="body1"
              component="div"
              gutterBottom
              color="primary"
            >
              {warningText}
            </Typography>
          ) : null}
          {coefficientCountCondition ? (
            <Typography
              sx={{ m: 1 }}
              variant="body1"
              component="div"
              gutterBottom
              color="primary"
            >
              {coefficientText}
            </Typography>
          ) : null}
          {below70PercentRowsCondition ? (
            <Typography
              sx={{ m: 1 }}
              variant="body1"
              component="div"
              gutterBottom
              color="primary"
            >
              {below70PercentText}
            </Typography>
          ) : null}

          {paymentTermDaysCondition ? (
            <Typography
              sx={{ m: 1 }}
              variant="body1"
              component="div"
              gutterBottom
              color="primary"
            >
              Maksuaika yli 21 päivää, vaatii hyväksynnän
            </Typography>
          ) : null}
          {sheetPeriodCondition ? (
            <Typography
              sx={{ m: 1 }}
              variant="body1"
              component="div"
              gutterBottom
              color="primary"
            >
              Voimassaoloaika yli 700 päivää, vaatii hyväksynnän
            </Typography>
          ) : null}

          {sheetValueNationWideCondition ||
          sheetValueNotNationWideCondition ? (
            <Typography
              sx={{ m: 1 }}
              variant="body1"
              component="div"
              gutterBottom
              color="primary"
            >
              Sopimuksen arvo on niin suuri, että se vaatii
              hyväksynnän!
            </Typography>
          ) : null}
        </Box>
        <FormControl
          fullWidth
          sx={{
            mt: 1,
          }}
        >
          <TextField
            sx={{ width: '100%', mb: 1 }}
            id="approval-email"
            label="Sähköpostiosoite"
            variant="outlined"
            type="email"
            value={email}
            error={
              !email ? false : !validEmail || !email ? true : false
            }
            helperText={
              !email
                ? ''
                : !validEmail || !email
                  ? 'Varmista että sähköpostiosoite on oikein'
                  : null
            }
            onChange={handleEmailChange}
          />

          <MultiLineComment
            label="Perustelut"
            placeholder="Lisää perustelut"
            handleCommentChange={handleCommentChange}
            required={true}
          />
        </FormControl>
        <Box>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              disabled={
                !validEmail || !email || !comment ? true : false
              }
              variant="contained"
              onClick={askApproval}
              sx={{ height: 40 }}
            >
              Pyydä hyväksyntää
            </Button>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <AcceptanceMatrix />
          </Box>
        </Box>
      </>
    );
  };

  const OkView = () => {
    return (
      <>
        <>
          <Box sx={{ mt: 5, mb: 5 }}>
            <Typography
              sx={{ m: 1 }}
              variant="body1"
              component="div"
              gutterBottom
              color="text.primary"
            >
              {underWarningPriceCount === 0 &&
              underCoefficientCount === 0
                ? 'Kaikki hinnat ok! Hinnasto on valmis hyväksyttäväksi!'
                : `Hintakertoimien alitukset ovat sallituissa rajoissa! Hinnasto on valmis hyväksyttäväksi!`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              size="small"
              variant="contained"
              onClick={approvePricingSheet}
              sx={{ height: 40 }}
            >
              Hyväksy
            </Button>
          </Box>
        </>
      </>
    );
  };

  const differenceInDaysValue = differenceInDays(
    backgroundInfo.priceSheetStartDate,
    backgroundInfo.priceSheetEndDate,
  );

  // below 1 coefficient
  const warningCountCondition = underWarningPriceCount
    ? underWarningPriceCount > 0
    : false;

  // below 1.2 coefficient
  const coefficientCountCondition = underCoefficientCount
    ? underCoefficientCount > WARNING_THRESHOLD
    : false;

  // below 70% proposal price
  const below70PercentRowsCondition = below70PercentRowsCount
    ? below70PercentRowsCount > 0
    : false;

  const paymentTermDaysCondition =
    backgroundInfo.paymentTermDays > 21;

  const sheetPeriodCondition = differenceInDaysValue > 700;

  const potentialBottomValue = Math.floor(
    backgroundInfo.potential?.bottom *
      currencyMultiplier *
      (differenceInDaysValue / 365),
  );

  const sheetValueNationWideCondition =
    potentialBottomValue > 500000 && backgroundInfo.nationWide;

  const sheetValueNotNationWideCondition =
    potentialBottomValue > 200000 && !backgroundInfo.nationWide;

  const shouldShowRequireApprovalView =
    warningCountCondition ||
    below70PercentRowsCondition ||
    coefficientCountCondition ||
    paymentTermDaysCondition ||
    sheetPeriodCondition ||
    sheetValueNationWideCondition ||
    sheetValueNotNationWideCondition;

  const summaryItems = [
    {
      value: backgroundInfo.customerName || 'Asiakasta ei valittuna!',
      label: 'Asiakas',
      icon: <AccountBox />,
    },
    {
      value:
        backgroundInfo.priceSheetType ||
        'Hinnaston taso ei valittuna!',
      label: 'Hinnaston taso',
      icon: <Money />,
    },
    {
      value: backgroundInfo.revenue
        ? formatRevenue(backgroundInfo.revenue)
        : 'Liikevaihtoa ei valittuna!',
      label: 'Liikevaihto',
      icon: <Euro />,
    },
    {
      value: backgroundInfo.potential
        ? formatRentalPotential(
            backgroundInfo.potential.bottom,
            backgroundInfo.potential.top,
          )
        : 'Vuokrapotentiaali ei valittuna!',
      label: 'Potentiaali',
      icon: <Euro />,
    },
    {
      value: backgroundInfo.industry || 'Segmenttiä ei valittuna!',
      label: 'Asiakkaan toimiala',
      icon: <House />,
    },
    {
      value: backgroundInfo.priceSheetStartDate
        ? formatDateTolocaleDateString(
            backgroundInfo.priceSheetStartDate,
          )
        : 'Ei aloituspäivämäärää',
      label: 'Hinnasto voimassa alkaen',
      icon: <Today />,
    },
    {
      value: backgroundInfo.priceSheetEndDate
        ? formatDateTolocaleDateString(
            backgroundInfo.priceSheetEndDate,
          )
        : 'Ei lopetuspäivämäärää',
      label: 'Hinnaston voimassaolo päättyy',
      icon: <Event />,
    },
  ];

  return (
    <Box
      sx={{
        mt: 3,
      }}
    >
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        color="primary"
        sx={{ ml: 1, mt: 1, mb: 0 }}
      >
        Yhteenveto
      </Typography>
      <Typography
        sx={{ ml: 1, mb: 2 }}
        variant="body1"
        color={'text.secondary'}
      >
        {backgroundInfo?.name ? backgroundInfo?.name : null}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box>
          <SummaryList
            items={summaryItems}
            sx={{
              maxWidth: 360,
            }}
          />
        </Box>
        <Box>
          <EditingRights
            pricingSheetId={pricingSheetId}
            editingRights={backgroundInfo.editingRights}
            userAllowedToDelete={
              Number(backgroundInfo.userId) === userId
            }
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 'auto',
            width: '35%',
          }}
        >
          <Box>
            <>
              {underWarningPriceCount === undefined ||
              underCoefficientCount === undefined ? (
                <CircularProgress />
              ) : (
                <>
                  {preSelectedRows.length === 0 &&
                  underWarningPriceCount === 0 &&
                  underCoefficientCount === 0 ? (
                    <Typography
                      variant="body1"
                      component="div"
                      gutterBottom
                      color="primary"
                    >
                      Hinnastossa ei ole hinnoiteltavia tuotteita!
                    </Typography>
                  ) : (
                    <>
                      {shouldShowRequireApprovalView ? (
                        <>
                          {typeof underWarningPriceCount ===
                            'number' &&
                          typeof underCoefficientCount ===
                            'number' ? (
                            <>
                              <CountView />
                              <RequireApprovalView />
                            </>
                          ) : (
                            <CircularProgress />
                          )}
                        </>
                      ) : (
                        <>
                          {typeof underWarningPriceCount ===
                            'number' &&
                          typeof underCoefficientCount ===
                            'number' ? (
                            <>
                              <CountView />
                              <OkView />
                            </>
                          ) : (
                            <CircularProgress />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryStep;
