import { PricingSheetRow } from '../../../../../shared/types';
import { ValueFormatterParams } from 'ag-grid-community';
import { formatDateTolocaleDateString } from '../../../utils/formatDateTimes';

const dateFormatter = ({
  value,
}: ValueFormatterParams<PricingSheetRow, string>): string => {
  if (!value) {
    return '';
  }
  return formatDateTolocaleDateString(value);
};

export default dateFormatter;
