import { createContext, useCallback, useState } from 'react';

import {
  BackgroundInfo,
  PricingBasis,
  RoundingBasis,
  PricingSheetState,
} from '../../../../shared/types';

const Background = createContext<any | null>(null);

export const initPotentialBottom = 4;
export const initPotentialTop = 8;
export const initRevenue = 30;
export const initCommitment =
  (initPotentialBottom / initPotentialTop) * 100;

const defaultBackgroundData = {
  customerName: undefined,
  pdfCustomerName: undefined,
  industry: undefined,
  region: undefined,
  priceSheetStartDate: new Date(),
  priceSheetEndDate: new Date(),
  pricingBasis: 'BOTH' as PricingBasis,
  roundingBasisSheet: 'TWODECIMALS' as RoundingBasis,
  roundingBasisPdf: 'TWODECIMALS' as RoundingBasis,
  potential: { bottom: initPotentialBottom, top: initPotentialTop },
  revenue: initRevenue,
  commitment: initCommitment,
  paymentTermDays: 14,
  nationWide: false,
  regionallySignificant: false,
  strategicallySignificant: false,
  priceSheetType: undefined,
  revenueOverMillion: false,
  potentialOver100k: false,
  state: PricingSheetState.OkDraft,
};

const BackgroundProvider = (props: any) => {
  const [currentBackgroundInfo, setCurrentBackgroundInfo] =
    useState<BackgroundInfo>(defaultBackgroundData);
  const [backgroundInfo, setBackgroundInfo] =
    useState<BackgroundInfo>(defaultBackgroundData);
  const [updateInitialInfoEnabled, setUpdateInitialInfoEnabled] =
    useState(false);

  const resetBackgroundInfo = useCallback(() => {
    setBackgroundInfo(defaultBackgroundData);
    setCurrentBackgroundInfo(defaultBackgroundData);
  }, []);

  const children = props.children;

  return (
    <Background.Provider
      value={{
        currentBackgroundInfo,
        setCurrentBackgroundInfo,
        backgroundInfo,
        setBackgroundInfo,
        updateInitialInfoEnabled,
        setUpdateInitialInfoEnabled,
        resetBackgroundInfo,
      }}
    >
      {children}
    </Background.Provider>
  );
};

export default Background;

export { BackgroundProvider };
