import styled from '@emotion/styled';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { PricingSheetRow } from '../../../../../shared/types';
import IconButton from '@mui/material/IconButton';
import { Add, Remove } from '@mui/icons-material';
import { forwardRef, memo } from 'react';

export type OfferItemDetailsActionsRendererParams =
  ICellRendererParams<PricingSheetRow> & {
    showClearAction?: boolean;
    handleRemoveRow: (row: PricingSheetRow) => void;
    handleCopyRow: (row: PricingSheetRow) => void;
  };

const OfferItemDetailsActionsRenderer = memo(
  forwardRef((props: OfferItemDetailsActionsRendererParams, ref) => {
    const {
      showClearAction = true,
      data,
      handleRemoveRow,
      handleCopyRow,
    } = props;

    const handleClear = () => {
      if (data) {
        handleRemoveRow(data);
      }
    };

    const handleCopy = () => {
      if (data) {
        handleCopyRow(data);
      }
    };

    return (
      <ActionCell>
        <ActionButton size={'small'} onClick={handleCopy}>
          <Add color={'primary'} />
        </ActionButton>
        {showClearAction && (
          <ActionButton size={'small'} onClick={handleClear}>
            <Remove color={'error'} />
          </ActionButton>
        )}
      </ActionCell>
    );
  }),
);

const ActionCell = styled.div`
  align-items: center;
  display: grid;
  flex: 0;
  gap: 0.5rem;
  grid-auto-flow: column;
  justify-content: center;
`;

const ActionButton = styled(IconButton)`
  svg {
    height: 20px;
    width: 20px;
  }
`;
export default OfferItemDetailsActionsRenderer;
