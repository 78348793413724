import { PricingSheetRow } from '../../../../../shared/types';
import { countRemovalDayPrice } from '../../../../../shared/countRemovalDayPrice';

const removalDayPriceFormatter = (props: {
  data: PricingSheetRow;
}) => {
  if (!props.data) {
    return '';
  }
  const { rentalReadyPrice, depreciationPeriod, targetUtilRate } =
    props?.data;

  const removalDayPrice = countRemovalDayPrice(
    Number(rentalReadyPrice),
    Number(depreciationPeriod),
    Number(targetUtilRate) / 100,
  );

  return removalDayPrice ? removalDayPrice + ' €' : '';
};

export default removalDayPriceFormatter;
