import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, {
  ReactNode,
  Reducer,
  useCallback,
  // fixme: update '@types/react' to 18+
  // @ts-ignore
  useDeferredValue,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import GenericCheckBoxFilter from '../../../components/Filters/GenericCheckBoxFilter';
import {
  INCOMPLETE_OFFER_STATES,
  MIRA_CUSTOMER_BY_ID_QUERY_KEY,
  OFFER_SHEET_QUERY_KEY,
  TABLE_SIZE_OPTIONS,
} from '../../../../../shared/constants';
import { getOfferSheets } from '../../../services/offerSheets';
import {
  TableColumn,
  TablePaginationOptions,
  TableSortOptions,
  TableSortOrder,
} from '../../../../../shared/types';
import {
  DatabaseOfferWithApprovalRequest,
  GetOffersTableFilterOptions,
  OffersTableTabState,
  OfferState,
} from '../../../../../shared/types/offers';
import { DataTable } from '../../../components/Common/DataTable/DataTable';
import { Tab, Tabs } from '@mui/material';
import { useDataTableFiltering } from '../../../hooks/useDataTableFiltering';
import { produce } from 'immer';
import { OffersTableCellComponent } from './OffersTableCellComponent';
import { OffersTableRowActions } from './OffersTableRowActions';
import { OffersTableColumnFilter } from './OfferTableColumnFilter';
import { OffersTableRowDetails } from './OffersTableRowDetails';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getMiraCustomerByIds } from '../../../services/customers';
import { CustomerLoadError } from '../../../components/Common/CustomerLoadError';

export type RenderComponentProps<
  T extends (...args: any) => ReactNode = (...args: any) => ReactNode,
> = (
  renderProps: Parameters<T>[0],
  parentProps: {
    activeTab: OffersTableTabState;
    setActiveTab: (state: OffersTableTabState) => void;
    loading: boolean;
  },
) => ReturnType<T>;

type TableState = {
  activeTab: OffersTableTabState;
  filterState: {
    [Tab in OffersTableTabState]: GetOffersTableFilterOptions;
  };
  paginationState: {
    [Tab in OffersTableTabState]: TablePaginationOptions;
  };
  sortState: {
    [Tab in OffersTableTabState]: TableSortOptions<DatabaseOfferWithApprovalRequest>;
  };
};

type TableAction =
  | {
      type: 'SET_ACTIVE_TAB';
      payload: OffersTableTabState;
    }
  | {
      type: 'SET_FILTER';
      payload: GetOffersTableFilterOptions;
    }
  | {
      type: 'SET_PAGINATION';
      payload: TablePaginationOptions;
    }
  | {
      type: 'SET_SORT';
      payload: TableSortOptions<DatabaseOfferWithApprovalRequest>;
    };

const DEFAULT_FILTER: GetOffersTableFilterOptions = {
  byUser: false,
};

const DEFAULT_PAGINATION: TablePaginationOptions = {
  limit: TABLE_SIZE_OPTIONS[1],
  offset: 0,
};

const DEFAULT_SORT: TableSortOptions<DatabaseOfferWithApprovalRequest> =
  {
    direction: TableSortOrder.Desc,
    orderBy: 'updatedAt',
  };

const INITIAL_STATE: TableState = {
  activeTab: OffersTableTabState.Incomplete,
  filterState: {
    [OffersTableTabState.Sent]: {
      ...DEFAULT_FILTER,
      archived: false,
      removed: false,
      includeStates: [
        OfferState.Sent,
        OfferState.Approved,
        OfferState.Rejected,
        OfferState.RequiresChanges,
      ],
    },
    [OffersTableTabState.Incomplete]: {
      ...DEFAULT_FILTER,
      archived: false,
      removed: false,
      includeStates: INCOMPLETE_OFFER_STATES,
    },
    [OffersTableTabState.Expired]: {
      ...DEFAULT_FILTER,
      archived: false,
      removed: false,
      includeStates: [OfferState.Expired],
    },
    [OffersTableTabState.Archived]: {
      ...DEFAULT_FILTER,
      archived: true,
    },
  },
  paginationState: {
    [OffersTableTabState.Sent]: DEFAULT_PAGINATION,
    [OffersTableTabState.Incomplete]: DEFAULT_PAGINATION,
    [OffersTableTabState.Expired]: DEFAULT_PAGINATION,
    [OffersTableTabState.Archived]: DEFAULT_PAGINATION,
  },
  sortState: {
    [OffersTableTabState.Sent]: DEFAULT_SORT,
    [OffersTableTabState.Incomplete]: DEFAULT_SORT,
    [OffersTableTabState.Expired]: DEFAULT_SORT,
    [OffersTableTabState.Archived]: DEFAULT_SORT,
  },
};

const TABLE_COLUMNS: TableColumn<DatabaseOfferWithApprovalRequest>[] =
  [
    {
      key: 'id',
      label: 'Tarjousnro',
      width: 80,
    },
    {
      key: 'name',
      label: 'Nimi',
    },
    {
      key: 'customerName',
      label: 'Asiakas',
    },
    {
      key: 'worksite',
      label: 'Työmaa',
    },
    {
      key: 'userEmail',
      label: 'Tekijä',
    },
    {
      key: 'state',
      label: 'Tila',
    },
    {
      key: 'createdAt',
      label: 'Luotu',
    },
    {
      key: 'offerValidityEnd',
      label: 'Voimassa asti',
    },
    {
      key: 'totalCoefficient',
      label: 'Hintakerroin',
    },
    {
      key: 'totalPrice',
      label: 'Yht.',
    },
  ];

const TABS: Record<string, OffersTableTabState> = {
  Lähetetyt: OffersTableTabState.Sent,
  Keskeneräiset: OffersTableTabState.Incomplete,
  Vanhentuneet: OffersTableTabState.Expired,
  Arkistoidut: OffersTableTabState.Archived,
};

const reducer: Reducer<TableState, TableAction> = produce(
  (state, { type, payload }) => {
    switch (type) {
      case 'SET_ACTIVE_TAB':
        state.activeTab = payload;
        break;
      case 'SET_FILTER':
        state.filterState[state.activeTab] = payload;
        break;
      case 'SET_PAGINATION':
        state.paginationState[state.activeTab] = payload;
        break;
      case 'SET_SORT':
        state.sortState[state.activeTab] = payload;
        break;
      default:
        return state;
    }
  },
);

export const OfferSheetsTable = () => {
  const { tab } = useParams<{ tab?: OffersTableTabState }>();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    activeTab: tab || INITIAL_STATE.activeTab,
  });
  const { activeTab, filterState, paginationState, sortState } =
    state;

  // set active tab from path params
  useEffect(() => {
    if (tab && tab !== activeTab) {
      dispatch({ type: 'SET_ACTIVE_TAB', payload: tab });
    }
  }, [activeTab, tab]);

  const deferredActiveTab = useDeferredValue(activeTab);

  const { query } = useDataTableFiltering<
    DatabaseOfferWithApprovalRequest,
    GetOffersTableFilterOptions
  >({
    queryOptions: {
      queryKey: [OFFER_SHEET_QUERY_KEY],
      queryFn: getOfferSheets,
    },
    filterOptions: filterState[activeTab],
    paginationOptions: paginationState[activeTab],
    sortOptions: sortState[activeTab],
  });

  const {
    data: { count: itemCount = 0, items = [] } = {},
    isFetching,
  } = query;

  // fetch customer blocking info for each customer by Id
  const customerIds = items.map((item) => item.customerId).join(',');
  const {
    data: miraCustomers = [],
    isError: isErrorCustomers,
    isFetching: isFetchingBlocking,
  } = useQuery({
    queryKey: [MIRA_CUSTOMER_BY_ID_QUERY_KEY, customerIds],
    enabled: itemCount > 0,
    queryFn: async () => getMiraCustomerByIds(customerIds),
  });

  // sets the table header color based on the active tab
  const tableHeaderColor =
    activeTab === OffersTableTabState.Sent
      ? '#edf7ed'
      : activeTab === OffersTableTabState.Expired ||
          activeTab === OffersTableTabState.Archived
        ? '#fff4e5'
        : undefined;

  const setActiveTab = useCallback(
    (payload: OffersTableTabState) => {
      dispatch({ type: 'SET_ACTIVE_TAB', payload });
      history.push(`/tarjoukset/${payload}`);
    },
    [history],
  );

  const parentProps: Parameters<RenderComponentProps>[1] = useMemo(
    () => ({
      activeTab: deferredActiveTab,
      setActiveTab,
      loading: isFetching,
    }),
    [deferredActiveTab, isFetching, setActiveTab],
  );

  return (
    <Container
      maxWidth={false}
      sx={{
        '--spacing': '2rem',
        backgroundColor: 'white',
        borderRadius: 2,
        maxWidth: '1920px',
        my: 'var(--spacing)',
        p: 'var(--spacing)',
        width: 'calc(100% - var(--spacing) * 2)',
      }}
    >
      <Typography variant={'h4'} color={'primary'}>
        Tarjoukset
      </Typography>
      <Tabs
        value={activeTab}
        onChange={(_, payload) => {
          setActiveTab(payload);
        }}
        sx={{ mt: 2 }}
      >
        {Object.entries(TABS).map(([label, value]) => (
          <Tab key={value} label={label} value={value} />
        ))}
      </Tabs>
      <Divider sx={{ mb: 1 }} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ my: 1 }}>
          <GenericCheckBoxFilter
            disabled={isFetching}
            labelStyle={{ marginLeft: 0 }}
            label={'Näytä vain omat'}
            checked={filterState[activeTab].byUser || false}
            handleChecked={(checked) =>
              dispatch({
                type: 'SET_FILTER',
                payload: {
                  ...filterState[activeTab],
                  byUser: checked,
                },
              })
            }
          />
        </Box>
        {isErrorCustomers && <CustomerLoadError />}
      </Box>
      {/* OFFERS TABLE */}
      <DataTable
        count={itemCount}
        filterOptions={filterState[activeTab]}
        labelNoResults={'Tarjouksia ei löytynyt!'}
        labelRowsPerPage={'Tarjouksia per sivu'}
        loading={isFetching || isFetchingBlocking}
        miraCustomers={miraCustomers}
        items={items}
        paginationOptions={paginationState[activeTab]}
        renderCellComponent={(renderProps) =>
          OffersTableCellComponent(renderProps, parentProps)
        }
        renderRowActions={(renderProps) =>
          OffersTableRowActions(renderProps, parentProps)
        }
        renderColumnFilter={(renderProps) =>
          OffersTableColumnFilter(renderProps, parentProps)
        }
        renderRowDetails={(renderProps) =>
          OffersTableRowDetails(renderProps, parentProps)
        }
        setFilterOptions={(updatedFilter) => {
          dispatch({ type: 'SET_FILTER', payload: updatedFilter });
        }}
        setPaginationOptions={(updatedPagination) => {
          dispatch({
            type: 'SET_PAGINATION',
            payload: updatedPagination,
          });
        }}
        setSortOptions={(updatedSort) => {
          dispatch({ type: 'SET_SORT', payload: updatedSort });
        }}
        sortOptions={sortState[activeTab]}
        tableColumns={TABLE_COLUMNS}
        tableHeaderColor={tableHeaderColor}
      />
    </Container>
  );
};
