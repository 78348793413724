import Alert from '@mui/material/Alert';
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';

const NoInternetConnectionAlert = () => {
  return (
    <Alert
      icon={<SignalWifiConnectedNoInternet4Icon fontSize="inherit" />}
      severity="error"
    >
      Tarkista internetyhteys
    </Alert>
  );
};

export default NoInternetConnectionAlert;
