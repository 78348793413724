import Tooltip from '@mui/material/Tooltip';

const CommentToolTip = ({
  title,
  children,
  offsetProps = { x: 0, y: 0 },
}: any) => {
  const { x, y } = offsetProps;
  return (
    <Tooltip
      title={title}
      placement={'right'}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [y, x],
              },
            },
          ],
        },
      }}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            py: 0.5,
            px: 1,
            fontSize: '1.125rem',
            backgroundColor: 'white',
            color: 'text.primary',
            border: '2px solid',
            borderColor: 'error.main',
            '.MuiTooltip-arrow': {
              color: 'error.main',
            },
          },
        },
      }}
    >
      <span> {children}</span>
    </Tooltip>
  );
};
export default CommentToolTip;
