import currencyFormatter from '../formatters/priceFormatter';
import ramiturvaPriceFormatter from '../formatters/ramiturvaPriceFormatter';
import percentFormatter from '../formatters/percentFormatter';
import {
  getClassForAgGridCell,
  getOfferExternalComparisonPriceValue,
} from '../../../helpers/cellHelpers';
import {
  AgGridColumnView,
  Nullable,
  OfferItem,
  PricingSheetRow,
  RoundingBasis,
} from '../../../../../shared/types';
import NumericEditorV2 from '../Editors/NumericEditorV2';
import {
  CellClassParams,
  ICellEditorParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import proposalMonthPriceGetter from '../valueGetters/proposalMonthPriceGetter';
import { GridColumnProps } from './ColumnsBase';
import contractPriceValueGetter from '../valueGetters/contractPriceValueGetter';
import PriceRenderer from '../Renderers/PriceRenderer';
import comparisonPriceValueGetter from '../valueGetters/comparisonPriceValueGetter';
import { ProposalPriceRenderer } from '../Renderers/ProposalPriceRenderer';
import { OfferPricingBasisRenderer } from '../Renderers/OfferPricingBasisRenderer';
import { priceGetter } from '../valueGetters/priceGetter';
import discountGetter from '../valueGetters/discountGetter';
import proposalDayPriceGetter from '../valueGetters/proposalDayPriceGetter';
import dayPricePercentGetter from '../valueGetters/dayPricePercentGetter';
import monthPricePercentGetter from '../valueGetters/monthPricePercentGetter';
import catClassValueGetter from '../valueGetters/catClassValueGetter';
import {
  catClassInnerRenderer,
  isMonthPricing,
} from '../../../pages/OfferSheets/helpers';
import CatClassRenderer from '../Renderers/CatClassRenderer';
import { calculateTotalPrice } from '../../../../../shared/calculateTotalPrice';
import { formatDateTolocaleDateString } from '../../../utils/formatDateTimes';
import MultiLineHeader from '../Components/MultiLineHeader';
import { isSalesItem } from '../../../../../shared/helpers/isSalesItem';
import { roundToTwoDecimal } from '../../../../../shared/roundToTwoDecimal';
import { ramiturvaPriceGetter } from '../valueGetters/RamiturvaPricegetter';
import { hasRamiturva } from '../../../../../shared/helpers/hasRamiturva';

interface OptionalColumnProps {
  roundingBasisSheet: RoundingBasis;
  handleUpdateRowValue: (row: PricingSheetRow) => void;
}

const defaultCellStyle = {
  borderLeft: '1px solid #ccc',
  display: 'flex',
};

const editableCellStyle = {
  color: '#000',
};

const ColumnsOffer = ({
  classes,
  optionalColumnProps,
}: GridColumnProps<OptionalColumnProps>) => {
  const { handleUpdateRowValue } = optionalColumnProps;

  return [
    {
      field: 'catClass',
      headerName: 'Nimi',
      lockPosition: 'left',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      filter: 'agTextColumnFilter',
      filterValueGetter: catClassValueGetter,
      cellRenderer: (params: ICellRendererParams<OfferItem>) => {
        return (
          <CatClassRenderer
            showMachineCard={true}
            row={params.data}
            catClassInnerRenderer={catClassInnerRenderer}
            gridColumnView={AgGridColumnView.OFFER_PRICING}
          />
        );
      },
      cellClass: 'full-width',
      cellStyle: defaultCellStyle,
      minWidth: 540,
    },
    {
      field: 'rentalPeriod',
      headerName: 'Vuokra-aika',
      minWidth: 160,
      filter: false,
      sortable: false,
      valueGetter: ({ data }: ValueGetterParams<OfferItem>) => {
        const rentalPeriod = data
          ? `${formatDateTolocaleDateString(
              data.leasePeriodStart,
            )} - ${formatDateTolocaleDateString(data.leasePeriodEnd)}`
          : '';
        return !isSalesItem(data?.type) ? rentalPeriod : null;
      },
      cellStyle: {
        ...defaultCellStyle,
      },
    },
    {
      field: 'pricingBasis',
      headerName: 'Hinnoitteluperuste',
      filter: 'agTextColumnFilter',
      cellRenderer: OfferPricingBasisRenderer,
      valueGetter: ({ data }: ValueGetterParams<OfferItem>) =>
        !isSalesItem(data?.type) ? data?.pricingBasis : null,
      valueFormatter: (params: ValueFormatterParams<OfferItem>) => {
        if (isSalesItem(params.data?.type)) return null;
        if (
          !params.data ||
          typeof params.data.pricingBasis === 'undefined'
        ) {
          return '';
        } else {
          return isMonthPricing(params)
            ? 'Kuukausihinta'
            : 'Päivähinta';
        }
      },
      cellClass: (params: CellClassParams) =>
        getClassForAgGridCell(params.data, classes, 'pricingBasis'),
      cellStyle: { ...defaultCellStyle, padding: 0 },
      headerTooltip: 'Onko tuotteella PV vai KK hinta',
      maxWidth: 150,
    },
    {
      field: 'quantity',
      headerName: 'Lkm',
      editable: true,
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        return params.data?.quantity;
      },
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
        allowNegative: false,
        required: false,
      },
      maxWidth: 80,
      headerTooltip: 'Tuotteiden lukumäärä',
      cellClass: (params: CellClassParams) =>
        getClassForAgGridCell(params.data, classes, 'quantity'),
      cellStyle: {
        ...defaultCellStyle,
        ...editableCellStyle,
      },
    },
    {
      field: 'proposalPrice',
      headerName: 'Ehdotushinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        if (isMonthPricing(params)) {
          return proposalMonthPriceGetter(params);
        } else {
          return proposalDayPriceGetter(params);
        }
      },
      valueFormatter: currencyFormatter,
      cellRenderer: ProposalPriceRenderer,
      headerTooltip: 'Työkalun ehdottama hinta',
      cellStyle: defaultCellStyle,
    },
    {
      field: 'price',
      headerName: 'Tarjouksen hinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) =>
        priceGetter(params, optionalColumnProps.roundingBasisSheet),
      valueFormatter: currencyFormatter,
      editable: true,
      headerTooltip: 'Tuotteen hinta',
      cellEditor: NumericEditorV2,
      cellEditorParams: (params: ICellEditorParams) => {
        const {
          data: { pricingBasis },
          column,
        } = params;
        const columnId = column.getColId();

        let defaultField;
        if (columnId === 'price') {
          if (pricingBasis === 'MONTH') {
            defaultField = 'monthPrice';
          } else {
            defaultField = 'dayPrice';
          }
        }
        return { handleUpdateRowValue, defaultField };
      },
      cellClass: (params: CellClassParams) => {
        const priceType = isMonthPricing(params)
          ? 'monthPrice'
          : 'dayPrice';
        return getClassForAgGridCell(params.data, classes, priceType);
      },
      cellRenderer: PriceRenderer,
      cellRendererParams: (params: ICellEditorParams<OfferItem>) => {
        return {
          minPrice: isMonthPricing(params)
            ? params.data.minMonthPrice
            : params.data.minDayPrice,
        };
      },
      cellStyle: {
        ...editableCellStyle,
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'totalPrice',
      headerName: 'Kokonaishinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        return roundToTwoDecimal(
          (priceGetter(
            params,
            optionalColumnProps.roundingBasisSheet,
          ) as number) * (params.data?.quantity ?? 1),
        );
      },
      valueFormatter: currencyFormatter,
      headerTooltip:
        'Tuotteen yhteenlaskettu hinta vuokra-aika huomioiden',
      cellStyle: defaultCellStyle,
    },
    {
      field: 'rentPeriodTotalPrice',
      headerName: 'Vuokra-ajan kokonaishinta',
      filter: 'agNumberColumnFilter',
      editable: false,
      headerComponent: MultiLineHeader,
      headerComponentParams: {
        header1: 'Vuokra-ajan',
        header2: 'kokonaishinta',
      },
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        return !isSalesItem(params.data?.type)
          ? calculateTotalPrice(
              params.data,
              optionalColumnProps.roundingBasisSheet,
            )
          : null;
      },
      valueFormatter: currencyFormatter,
      cellStyle: defaultCellStyle,
    },
    {
      field: 'discount',
      headerName: 'Alennus%',
      filter: 'agNumberColumnFilter',
      valueGetter: discountGetter,
      valueFormatter: percentFormatter,
      editable: true,
      headerTooltip: 'Hinta verrattuna ehdotushintaan',
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellClass: (params: CellClassParams) => {
        const discountType = isMonthPricing(params)
          ? 'discountMonth'
          : 'discountDay';
        return getClassForAgGridCell(
          params.data,
          classes,
          discountType,
        );
      },
      cellStyle: {
        ...defaultCellStyle,
        ...editableCellStyle,
      },
    },
    {
      field: 'ramiturvaPrice',
      headerName: 'RamiTurvan hinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) =>
        ramiturvaPriceGetter(
          params,
          optionalColumnProps.roundingBasisSheet,
        ),
      valueFormatter: ramiturvaPriceFormatter,
      editable: (params: CellClassParams) =>
        hasRamiturva(params.data),
      headerTooltip: 'RamiTurvan hinta',
      cellEditor: NumericEditorV2,
      cellEditorParams: (params: ICellEditorParams) => {
        const {
          data: { pricingBasis },
          column,
        } = params;
        const columnId = column.getColId();

        let defaultField;
        if (columnId === 'ramiturvaPrice') {
          if (pricingBasis === 'DAY') {
            defaultField = 'ramiturvaDayPrice';
          } else if (pricingBasis === 'MONTH') {
            defaultField = 'ramiturvaMonthPrice';
          } else {
            defaultField = '';
          }
        }
        return { handleUpdateRowValue, defaultField };
      },
      cellClass: (params: CellClassParams) => {
        return getClassForAgGridCell(
          params.data,
          classes,
          'ramiturvaPrice',
        );
      },
      cellStyle: {
        ...editableCellStyle,
        borderLeft: '1px solid #ccc',
      },
    },
    {
      field: 'contractPrice',
      headerName: 'Sopimushinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        const priceType = isMonthPricing(params) ? 'MONTH' : 'DAY';
        return contractPriceValueGetter(
          params,
          optionalColumnProps.roundingBasisSheet,
          getOfferExternalComparisonPriceValue(
            params.data,
            'contract',
            priceType,
          ),
        );
      },
      valueFormatter: currencyFormatter,
      cellStyle: defaultCellStyle,
      suppressColumnsToolPanel: false,
    },
    {
      field: 'comparisonPrice',
      headerName: 'VRThinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        const priceType = isMonthPricing(params) ? 'MONTH' : 'DAY';
        return comparisonPriceValueGetter(
          params,
          optionalColumnProps.roundingBasisSheet,
          getOfferExternalComparisonPriceValue(
            params.data,
            'comparison',
            priceType,
          ),
        );
      },
      valueFormatter: currencyFormatter,
      headerTooltip: 'Tuotteen hinta valitussa vertailuhinnastossa',
      cellStyle: {
        ...defaultCellStyle,
        borderRight: '1px solid #ccc',
      },
    },
    {
      field: 'comparisonPricePercent',
      headerName: 'VRTero%',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<OfferItem>) => {
        if (!params.data) return '';
        let pricePercent;
        const priceType = isMonthPricing(params) ? 'MONTH' : 'DAY';
        priceType === 'MONTH'
          ? (pricePercent = monthPricePercentGetter(
              params,
              getOfferExternalComparisonPriceValue(
                params.data,
                'comparison',
                priceType,
              ),
            ))
          : (pricePercent = dayPricePercentGetter(
              params,
              getOfferExternalComparisonPriceValue(
                params.data,
                'comparison',
                priceType,
              ),
            ));
        return pricePercent;
      },
      valueFormatter: (params: {
        value: Nullable<number>;
        data: PricingSheetRow | undefined;
      }) => {
        if (!params.value) return '';
        return `${params.value} %`;
      },
      cellStyle: {
        ...defaultCellStyle,
        borderRight: '1px solid #ccc',
      },
      suppressColumnsToolPanel: false,
      hide:
        localStorage.getItem(
          'columnVisibility_comparisonPricePercent',
        ) !== 'true',
    },
  ];
};

export default ColumnsOffer;
