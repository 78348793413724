import { useState, useEffect, useContext } from 'react';

import FormControl from '@mui/material/FormControl';
import { InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';

import DefaultTooltip from '../Tooltips/DefaultTooltip';

import BackgroundProvider from '../../providers/Background/BackgroundProvider';
import PricingProvider from '../../providers/Pricing/PricingProvider';
import NotificationProvider from '../../providers/Notification/NotificationProvider';

import {
  AgGridColumnView,
  RoundingBasis,
} from '../../../../shared/types';

import { RowsHistoryType } from '../../hooks/useRowsHistory';

import { formatRoundingBasis } from '../../pages/PricingSheets/AdditionalInfo';
import { updatePricingSheetById } from '../../services/pricingSheets';

interface EditRoundingBasesProps {
  rowsHistory: RowsHistoryType[];
  isUnsavedChanges: boolean;
  updateTimeStampToNewestChange: () => void;
  updateGridColumnView: (newTableView: AgGridColumnView) => void;
  gridColumnView: AgGridColumnView;
}

const EditRoundingBases: React.FC<EditRoundingBasesProps> = ({
  rowsHistory,
  isUnsavedChanges,
  updateTimeStampToNewestChange,
  updateGridColumnView,
  gridColumnView,
}) => {
  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );

  const { pricingSheetId } = useContext(PricingProvider);
  const { setNotification } = useContext(NotificationProvider);

  useEffect(() => {
    if (rowsHistory.length > 0 && !isUnsavedChanges) {
      updateTimeStampToNewestChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsHistory]);

  const [roundingBasisPdfValue, setRoundingBasisPdfValue] =
    useState<RoundingBasis>(
      backgroundInfo.roundingBasisSheet === 'THREESIGNIFICANT'
        ? backgroundInfo.roundingBasisSheet
        : backgroundInfo.roundingBasisPdf,
    );

  const [roundingBasisSheetValue, setRoundingBasisSheetValue] =
    useState<RoundingBasis>(
      backgroundInfo.roundingBasisPdf === 'THREESIGNIFICANT'
        ? backgroundInfo.roundingBasisPdf
        : backgroundInfo.roundingBasisSheet,
    );

  const handleRoundingBasisChangePdf = (event: any) => {
    const newRoundingBasisSheetValue =
      event.target.value === 'THREESIGNIFICANT' ||
      backgroundInfo.roundingBasisSheet === 'THREESIGNIFICANT'
        ? event.target.value
        : backgroundInfo.roundingBasisSheet;

    setBackgroundInfo({
      ...backgroundInfo,
      roundingBasisPdf: event.target.value,
      roundingBasisSheet: newRoundingBasisSheetValue,
    });
    setRoundingBasisPdfValue(event.target.value);

    // update prices by THREESIGNIFICANT if THREESIGNIFICANT is selected in pdf or THREESIGNIFICANT is used
    if (
      event.target.value === 'THREESIGNIFICANT' ||
      backgroundInfo.roundingBasisSheet === 'THREESIGNIFICANT'
    ) {
      // changePrices(newRoundingBasisSheetValue);
      setRoundingBasisSheetValue(newRoundingBasisSheetValue);
    }
    updatePricingSheetPdfRounding(pricingSheetId, event.target.value);
  };

  const updatePricingSheetRounding = async (
    sheetId: string,
    roundingBasisSheet: RoundingBasis,
  ) => {
    try {
      await updatePricingSheetById(sheetId, {
        roundingBasisSheet: roundingBasisSheet,
      });
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: 'Hinnasto tallennettu!',
      });
    } catch (error) {
      console.error(error);
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'error',
        message:
          'Pyöristysvalinnan päivitys palvelimelle epäonnistui!',
      });
    }
  };

  const updatePricingSheetPdfRounding = async (
    sheetId: string,
    roundingBasisPdf: RoundingBasis,
  ) => {
    try {
      await updatePricingSheetById(sheetId, {
        roundingBasisPdf: roundingBasisPdf,
      });
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: 'Hinnasto tallennettu!',
      });
    } catch (error) {
      console.error(error);
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'error',
        message:
          'Pdf-pyöristysvalinnan päivitys palvelimelle epäonnistui!',
      });
    }
  };

  const handleRoundingBasisSheetChange = (event: any) => {
    const newRoundingBasisPdfValue =
      event.target.value === 'THREESIGNIFICANT' ||
      backgroundInfo.roundingBasisSheet === 'THREESIGNIFICANT'
        ? event.target.value
        : backgroundInfo.roundingBasisPdf;

    setBackgroundInfo({
      ...backgroundInfo,
      roundingBasisSheet: event.target.value,
      roundingBasisPdf: newRoundingBasisPdfValue,
    });
    setRoundingBasisSheetValue(event.target.value);

    // update pdf to use THREESIGNIFICANT if THREESIGNIFICANT is selected in sheet or THREESIGNIFICANT is used
    if (
      event.target.value === 'THREESIGNIFICANT' ||
      backgroundInfo.roundingBasisSheet === 'THREESIGNIFICANT'
    ) {
      setRoundingBasisPdfValue(newRoundingBasisPdfValue);
    }
    updatePricingSheetRounding(pricingSheetId, event.target.value);
  };

  // force columns to update if rounding changes
  // have to update inside useEffect since useColumns hook gets old state value if update directly in update functions
  useEffect(() => {
    updateGridColumnView(gridColumnView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundingBasisSheetValue, roundingBasisPdfValue]);

  return (
    <Box sx={{ m: 1, minWidth: 220 }}>
      <FormControl sx={{ minWidth: 220 }} size="small">
        <InputLabel>Pyöristys viennissä</InputLabel>
        <DefaultTooltip
          placement="top"
          title={`Valitse miten hinnat pyöristetään tulosteella. Jos valitset kolme merkitsevää, se täytyy olla molemmissa pyöristysvalinnoissa.`}
        >
          <Select
            sx={{ minWidth: 220 }}
            label="Pyöristys viennissä"
            value={roundingBasisSheetValue}
            onChange={handleRoundingBasisSheetChange}
          >
            <MenuItem value={'TWODECIMALS'}>
              {formatRoundingBasis('TWODECIMALS')}
            </MenuItem>
            <MenuItem value={'SIXDECIMALS'}>
              {formatRoundingBasis('SIXDECIMALS')}
            </MenuItem>

            <MenuItem value={'THREESIGNIFICANT'}>
              {formatRoundingBasis('THREESIGNIFICANT')}
            </MenuItem>
          </Select>
        </DefaultTooltip>
      </FormControl>
      <>
        <FormControl sx={{ minWidth: 220 }} size="small">
          <InputLabel>Pyöristys tulosteella</InputLabel>
          <DefaultTooltip
            placement="top"
            title={`Valitse miten hinnat pyöristetään tulosteella. Jos valitset kolme merkitsevää, se täytyy olla molemmissa pyöristysvalinnoissa.`}
          >
            <Select
              sx={{ minWidth: 220 }}
              label="Pyöristys tulosteella"
              value={roundingBasisPdfValue}
              onChange={handleRoundingBasisChangePdf}
            >
              <MenuItem value={'TWODECIMALS'}>
                {formatRoundingBasis('TWODECIMALS')}
              </MenuItem>
              <MenuItem value={'SIXDECIMALS'}>
                {formatRoundingBasis('SIXDECIMALS')}
              </MenuItem>
              <MenuItem value={'THREESIGNIFICANT'}>
                {formatRoundingBasis('THREESIGNIFICANT')}
              </MenuItem>
            </Select>
          </DefaultTooltip>
        </FormControl>
      </>
    </Box>
  );
};

export default EditRoundingBases;
