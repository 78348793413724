import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AuditLogEntry, DialogType } from './AutomaticRuns';
import { Nullable } from '../../../../shared/types';

const InfoTable = ({
  data,
  columnHeaders,
  columnDataKeys,
}: {
  data: Record<string, Nullable<string>>[];
  columnHeaders: string[];
  columnDataKeys: string[];
}) => {
  return (
    <>
      {data ? (
        <Table
          sx={{
            border: '1px solid #e0e0e0',
            borderRadius: '2px',
            overflow: 'hidden',
            '& tr': {
              '& td': {
                borderBottom: 0,
              },
              '&:nth-of-type(even)': {
                backgroundColor: '#fafafa',
              },
            },
            '& th': {
              backgroundColor: '#fafafa',
              color: 'primary.main',
              fontWeight: 'bold',
            },
            '& th, & td': {
              fontSize: '0.75rem',
              p: '4px 8px',
              '&:not(:first-of-type)': {
                borderLeft: '1px solid #e0e0e0',
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              {columnHeaders.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((dataRow) => (
              <TableRow key={dataRow.catClass}>
                {columnDataKeys.map((dataKey) => (
                  <TableCell key={dataKey}>
                    {dataRow[dataKey]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography>Ei muutoksia</Typography>
      )}
    </>
  );
};

const getTableData = (
  dialogType?: DialogType,
  auditLog?: AuditLogEntry,
) => {
  switch (dialogType) {
    case DialogType.NEW:
      return {
        data: auditLog?.props?.newState?.newItems,
        columnHeaders: [
          'CatClass',
          'Nimi',
          'Tyyppi',
          'PIM tuoteryhmä',
          'PIM tuotelinja',
          'PIM kategoria',
        ],
        columnDataKeys: [
          'catClass',
          'pimDisplayName',
          'type',
          'pimProductGroup',
          'pimProductLine',
          'pimCategory',
        ],
      };
    case DialogType.DIFFERENCES:
      return {
        data: auditLog?.props?.newState?.hierarchyDifference,
        columnHeaders: [
          'CatClass',
          'Nimi',
          'Nykyinen tuoteryhmä',
          'Nykyinen tuotelinja',
          'Nykyinen kategoria',
          'PIM tuoteryhmä',
          'PIM tuotelinja',
          'PIM kategoria',
        ],
        columnDataKeys: [
          'catClass',
          'pimDisplayName',
          'currentProductGroup',
          'currentProductLine',
          'currentCategory',
          'pimProductGroup',
          'pimProductLine',
          'pimProductCategory',
        ],
      };
    case DialogType.MISSING_HIERACHY:
      return {
        data: auditLog?.props?.newState?.missingHierarchies,
        columnHeaders: ['CatClass', 'Nimi'],
        columnDataKeys: ['catClass', 'pimDisplayName'],
      };
    case DialogType.CHANGES:
      return {
        data: auditLog?.props?.newState?.itemUpdates,
        columnHeaders: [
          'CatClass',
          'Tyyppi',
          'Vanha nimi',
          'Uusi nimi',
        ],
        columnDataKeys: [
          'catClass',
          'type',
          'currentPimDisplayName',
          'newPimDisplayName',
        ],
      };
    case DialogType.REMOVAL:
    case DialogType.RETURNED: {
      const dataKey =
        dialogType === DialogType.REMOVAL
          ? 'removedItems'
          : 'returnedItems';
      return {
        data: auditLog?.props?.newState?.[dataKey],
        columnHeaders: ['CatClass', 'Nimi'],
        columnDataKeys: ['catClass', 'name'],
      };
    }
    default:
      return undefined;
  }
};

const getDialogTitle = (dialogType?: DialogType) => {
  switch (dialogType) {
    case DialogType.NEW:
      return 'Uudet tuotteet';
    case DialogType.DIFFERENCES:
      return 'Hierarkian eroavaisuudet';
    case DialogType.MISSING_HIERACHY:
      return 'Ei hierarkiaa PIM:ssä';
    case DialogType.CHANGES:
      return 'Muuttuneet tuotteet';
    case DialogType.REMOVAL:
      return 'Poistetut tuotteet';
    default:
      return '';
  }
};

interface AutomaticRunDialogProps {
  dialogType?: DialogType;
  handleClose: () => void;
  open: boolean;
  automaticRunId?: number;
  auditLogs: Nullable<AuditLogEntry[]>;
}

const AutomaticRunDialog: React.FC<AutomaticRunDialogProps> = ({
  dialogType,
  handleClose,
  open,
  automaticRunId,
  auditLogs,
}) => {
  const auditLog = auditLogs?.find(
    (auditLog) => auditLog.id === automaticRunId,
  );

  const tableData = getTableData(dialogType, auditLog);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle>{getDialogTitle(dialogType)}</DialogTitle>
      <DialogContent>
        {tableData?.data && tableData?.data.length > 0 ? (
          <InfoTable
            data={tableData.data}
            columnHeaders={tableData.columnHeaders}
            columnDataKeys={tableData.columnDataKeys}
          />
        ) : (
          <Typography>Ei muutoksia</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AutomaticRunDialog;
