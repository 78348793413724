import discountDayGetter from '../valueGetters/discountDayGetter';

import currencyFormatter from '../formatters/priceFormatter';
import percentFormatter from '../formatters/percentFormatter';

import { getClassForAgGridCell } from '../../../helpers/cellHelpers';

import {
  Nullable,
  PricingSheetRow,
  RoundingBasis,
} from '../../../../../shared/types';
import { dayPriceGetter } from '../valueGetters/dayPriceGetter';

import NumericEditorV2 from '../Editors/NumericEditorV2';

import {
  CellClassParams,
  CellStyle,
  ValueGetterParams,
} from 'ag-grid-community';
import proposalDayPriceGetter from '../valueGetters/proposalDayPriceGetter';
import { GridColumnProps } from './ColumnsBase';
import comparisonPriceValueGetter from '../valueGetters/comparisonPriceValueGetter';
import dayPricePercentGetter from '../valueGetters/dayPricePercentGetter';
import { getCatClassColDef } from '../helpers/getCatClassColDef';

interface OptionalColumnProps {
  roundingBasisSheet: RoundingBasis;
  handleUpdateRowValue: (row: PricingSheetRow) => void;
}

const ColumnsDay = ({
  classes,
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps<OptionalColumnProps>) => {
  const { handleUpdateRowValue } = optionalColumnProps;
  /*
   * for some reason right border does not apply if we pass it from cellClass
   * therefore we define borders here in cellStyles and only get colors from cellClass
   * */
  const columns = [
    ...getCatClassColDef(gridGroupingValues),
    {
      field: 'proposalDayPrice',
      headerName: 'EhdotusPVHinta',
      filter: 'agNumberColumnFilter',
      valueGetter: proposalDayPriceGetter,
      valueFormatter: currencyFormatter,
      headerTooltip: 'Työkalun ehdottama hinta päivähinnaksi',
      cellStyle: {
        backgroundColor: '#fafafa',
        borderLeft: '1px solid #ccc',
      } as CellStyle,
    },
    {
      field: 'discountDay',
      headerName: 'Alennus% (PV)',
      filter: 'agNumberColumnFilter',
      valueGetter: discountDayGetter,
      valueFormatter: percentFormatter,
      editable: (params: { data: PricingSheetRow }) =>
        !params.data.showByOfferText,
      cellEditor: NumericEditorV2,
      headerTooltip: 'Päivähinta verrattuna ehdotushintaan',
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellClass: (params: CellClassParams) => {
        return getClassForAgGridCell(
          params.data,
          classes,
          'discountDay',
        );
      },
      cellStyle: () => {
        return {
          color: '#000',
          borderLeft: '1px solid #ccc',
        } as CellStyle;
      },
    },
    {
      field: 'dayPrice',
      headerName: 'PVHinta',
      filter: 'agNumberColumnFilter',
      valueFormatter: currencyFormatter,
      valueGetter: (params: ValueGetterParams<PricingSheetRow>) =>
        dayPriceGetter(
          params,
          optionalColumnProps.roundingBasisSheet,
        ),
      editable: (params: { data: PricingSheetRow }) =>
        !params.data.showByOfferText,
      headerTooltip: 'Tuotteen päivähinta',
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellClass: (params: CellClassParams) => {
        return getClassForAgGridCell(
          params.data,
          classes,
          'dayPrice',
        );
      },
      cellStyle: () => {
        return {
          color: '#000',
          borderRight: '1px solid #ccc',
        } as CellStyle;
      },
    },
    {
      field: 'comparisonDayPrice',
      headerName: 'VRThintaPV',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<PricingSheetRow>) =>
        comparisonPriceValueGetter(
          params,
          optionalColumnProps.roundingBasisSheet,
          params.data?.comparisonDayPrice,
        ),
      valueFormatter: currencyFormatter,
      headerTooltip:
        'Tuotteen päivähinta valitussa vertailuhinnastossa',
      cellStyle: {
        backgroundColor: '#fafafa',
      },
    },
    {
      field: 'comparisonDayPricePercent',
      headerName: 'VRTero%PV',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<PricingSheetRow>) =>
        dayPricePercentGetter(
          params,
          params.data?.comparisonDayPrice,
        ),
      valueFormatter: (params: {
        value: Nullable<number>;
        data: PricingSheetRow | undefined;
      }) => {
        if (!params.value) return '';
        return `${params.value} %`;
      },
      cellStyle: {
        backgroundColor: '#fafafa',
        borderLeft: '1px solid #ccc',
      } as CellStyle,
      suppressColumnsToolPanel: false,
      hide:
        localStorage.getItem(
          'columnVisibility_comparisonDayPricePercent',
        ) !== 'true',
    },
  ];
  return columns;
};

export default ColumnsDay;
