/*
Collection of functions to calculate values in mass edit dialog
*/

import {
  Nullable,
  PricingSheetRow,
  PricingSheetRowKey,
} from '../../../../../../shared/types';
import { percentualNumberChange } from '../../../../../../shared/percentualNumberChange';
import {
  calculateDayPrice,
  calculateMonthPrice,
} from '../../../../../../shared/calculatePrices';
import {
  ChangeType,
  EditItemsDialogChange,
  EditItemsDialogChangeWithKey,
} from '../types';

/**
 * Returns new coefficient value from input field, can be absolute or percentual
 *
 * @param coefficients coefficients day or month, absolute or percentual
 * @param key category key
 * @param changeType absolute or percentual
 * @return input value as number or null
 */
export const getNewInputValue = (
  coefficients: {
    key: string;
    absoluteValue: Nullable<number>;
    percentValue: Nullable<number>;
  }[],
  key: string,
  changeType: ChangeType,
) => {
  const value =
    changeType === 'ABSOLUTE'
      ? coefficients.find((c) => c.key === key)?.absoluteValue ??
        undefined
      : coefficients.find((c) => c.key === key)?.percentValue ??
        undefined;
  return value ? Number(value) : null;
};

// Returns new calculation value from input field, can be absolute or percentual
export const getCalculationValue = (
  change: EditItemsDialogChange,
  changeType: ChangeType,
  highlightedItem: Nullable<PricingSheetRow>,
  field: 'rentalReadyPrice' | 'depreciationPeriod' | 'targetUtilRate',
) => {
  if (!highlightedItem) return null;
  return changeType === ChangeType.ABSOLUTE
    ? change.absoluteValue
    : percentualNumberChange(
        Number(highlightedItem[field]),
        change.percentValue,
      );
};

/**
 * Returns new price if change is percentual
 * @param coefficients
 * @param key category key
 * @param highlightedItem selected item from list
 * @return new calculated price as number
 */
export const getCalculatedNewPriceByPercentChange = (
  coefficients: EditItemsDialogChangeWithKey[],
  key: PricingSheetRowKey,
  highlightedItem: Nullable<PricingSheetRow>,
) =>
  highlightedItem
    ? percentualNumberChange(
        Number(highlightedItem[key]),
        coefficients &&
          Number(
            coefficients.find((c) => c.key === key)?.percentValue,
          ),
      )
    : null;

/**
 * Returns new price from absolute values
 * @param fn function used for calculation
 * @param highlightedItem selected item from list
 * @param rentalReadyPriceForCalculation absolute rental ready
 * @param depreciationPeriodForCalculation absolute depreciationPeriod
 * @param targetUtilRateForCalculation: absolute target util rate
 * @param newCoefficient absolute coefficient
 * @return new calculated price as number
 */
export const getNewPrice = (
  fn:
    | typeof calculateDayPrice
    | typeof calculateMonthPrice = calculateDayPrice,
  highlightedItem: Nullable<PricingSheetRow>,
  rentalReadyPriceForCalculation: Nullable<number>,
  depreciationPeriodForCalculation: Nullable<number>,
  targetUtilRateForCalculation: Nullable<number>,
  newCoefficient: number,
) =>
  fn(
    Number(
      percentualNumberChange(
        Number(highlightedItem?.rentalReadyPrice),
        rentalReadyPriceForCalculation,
      ) ?? highlightedItem?.rentalReadyPrice,
    ),
    Number(
      percentualNumberChange(
        Number(highlightedItem?.depreciationPeriod),
        depreciationPeriodForCalculation,
      ) ?? highlightedItem?.depreciationPeriod,
    ),
    Number(
      percentualNumberChange(
        Number(highlightedItem?.targetUtilRate),
        targetUtilRateForCalculation,
      ) ?? highlightedItem?.targetUtilRate,
    ) / 100,
    newCoefficient / 100,
  );
