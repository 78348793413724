import { UTCDate } from '@date-fns/utc';
import { format } from 'date-fns';

export const formatDateTime = (date: Date) =>
  format(new Date(date), 'dd.MM.yyyy HH:mm');

export const formatDateTimeWithSeconds = (date: Date) =>
  format(new Date(date), 'dd.MM.yyyy HH:mm:ss');

export const formatDateTolocaleDateString = (
  date: Date | string | number,
) => {
  return new Date(date).toLocaleDateString('fi', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const setDatetoStartOfUTCDay = (date: Date) => {
  return new UTCDate(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  );
};
