import React, { SetStateAction, useContext, useState } from 'react';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import Backdrop from '@mui/material/Backdrop';

import Box from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import PricingProvider from '../../../providers/Pricing/PricingProvider';
import RowsProvider from '../../../providers/Rows/RowsProvider';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';

import { addCommentToPricingSheetItemById } from '../../../services/pricingSheetItems';
import { PricingSheetRow } from '../../../../../shared/types';

interface CommentDialogProps {
  open: boolean;
  onClose: () => void;
  row: PricingSheetRow;
  comment: string | undefined;
  setComment: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

const CommentDialog: React.FC<CommentDialogProps> = ({
  onClose,
  open,
  row,
  comment,
  setComment,
}) => {
  const { pricingSheetId } = useContext(PricingProvider);
  const { dispatchPricingSheetRows } = useContext(RowsProvider);
  const { setNotification } = useContext(NotificationProvider);

  const [commentInPlace, setCommentInPlace] = useState<boolean>(
    comment ? true : false,
  );

  const handleCommentChange = (event: {
    target: { value: SetStateAction<string | undefined> };
  }) => {
    setComment(event.target.value);
  };

  const postComment = async () => {
    if (row.catClass && comment) {
      const commentResponse = await addCommentToPricingSheetItemById(
        pricingSheetId,
        { approverComment: comment, catClass: row.catClass },
      );
      if (commentResponse.status === 204) {
        dispatchPricingSheetRows({
          id: row.catClass,
          type: 'updateComment',
          comment: comment,
        });
        setCommentInPlace(true);
        setNotification({
          type: 'SNACKBAR',
          duration: 4000,
          severity: 'success',
          message: commentInPlace
            ? 'Kommentti päivitetty!'
            : 'Kommentti lisätty!',
        });
        return true;
      } else {
        setNotification({
          severity: 'error',
          message: commentInPlace
            ? 'Virhe päivittäessä kommenttia!'
            : 'Virhe lisätessä kommenttia!',
        });
        return false;
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleOkClick = async () => {
    const commentPosted = await postComment();
    if (commentPosted) {
      handleClose();
    }
  };

  return (
    <Dialog
      disableScrollLock
      onClose={handleClose}
      open={open}
      fullWidth
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>Kommentoi riviä</DialogTitle>
      <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
        <Box>
          <TextField
            id="comment-cell"
            multiline
            fullWidth
            rows={4}
            value={comment ? comment : row?.approverComment}
            onChange={handleCommentChange}
            placeholder="Lisää kommentti"
          />
        </Box>
        <DialogActions>
          <Button
            sx={{ mt: 2, float: 'right' }}
            onClick={handleOkClick}
            size="small"
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

interface CommentRendererProps {
  row: PricingSheetRow;
}
const CommentRenderer: React.FC<CommentRendererProps> = ({ row }) => {
  const { pricingSheetRows } = useContext(RowsProvider);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // need to find updated comment from provider in order to update UI in cell
  const pricingSheetRow = pricingSheetRows.find(
    (sourceRowItem: PricingSheetRow) =>
      sourceRowItem.catClass === row?.catClass,
  );

  const [comment, setComment] = useState<string | undefined>(
    pricingSheetRow?.approverComment ?? undefined,
  );

  return (
    <>
      <Button onClick={handleClickOpen}>
        {pricingSheetRow?.approverComment
          ? 'Muokkaa kommenttia'
          : 'Lisää kommentti'}
      </Button>
      <CommentDialog
        open={open}
        onClose={handleClose}
        row={row}
        comment={comment}
        setComment={setComment}
      />
    </>
  );
};

export default CommentRenderer;
