import { useContext, useEffect } from 'react';
import useUserContext from '../../providers/User/UserProvider';
import NotificationProvider from '../../providers/Notification/NotificationProvider';

import { Redirect } from 'react-router-dom';

const NotificationRedirect = () => {
  const { isAdmin } = useUserContext();
  const { setNotification } = useContext(NotificationProvider);

  useEffect(() => {
    return () => {
      if (!isAdmin) {
        setNotification({
          severity: 'error',
          message: 'Sinulla ei ole oikeuksia tarkastella tätä sivua!',
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
};

export default NotificationRedirect;
