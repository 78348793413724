import {
  isDayWarningPrice,
  isWarningPrice,
  isDayLightWarningPrice,
  isLightWarningPrice,
  isBelow70PercentDayPriceRow,
  isBelow70PercentPriceRow,
  isDayWarningRow,
  isWarningRow,
  isZeroDayPriceRow,
  isZeroPriceRow,
  isComparisonDayPriceEmpty,
  isComparisonPriceEmpty,
  inPricingFilter,
  missingPricingParameter,
  itemNotInPimFilter,
  itemInPimFilter,
  removedItemFilter,
  criticalEquipmentFilter,
  ramigreenFilter,
  isCriticalPrice,
  isBelowCriticalPriceDay,
  isInvalidPriceThreshold,
  showKitTypeOnly,
} from '../utils/pricingSheetRowFilters';
import { useState } from 'react';
import { PricingSheetRow } from '../../../shared/types';

export enum ExternalFilterName {
  ShowWarningRows = 'SHOW_WARNING_ROWS',
  ShowWarningRowsDay = 'SHOW_WARNING_ROWS_DAY',
  ShowRedRows = 'SHOW_RED_ROWS',
  ShowRedRowsDay = 'SHOW_RED_ROWS_DAY',
  ShowOrangeRows = 'SHOW_ORANGE_ROWS',
  ShowOrangeRowsDay = 'SHOW_ORANGE_ROWS_DAY',
  ShowPinkRows = 'SHOW_PINK_ROWS',
  ShowPinkRowsDay = 'SHOW_PINK_ROWS_DAY',
  ShowZeroPriceRows = 'SHOW_ZERO_PRICE_ROWS',
  ShowZeroPriceRowsDay = 'SHOW_ZERO_PRICE_ROWS_DAY',
  ShowComparisonPriceEmpty = 'SHOW_COMPARISON_PRICE_EMPTY',
  ShowComparisonPriceEmptyDay = 'SHOW_COMPARISON_PRICE_EMPTY_DAY',
  ShowOnlyInPricingRows = 'SHOW_ONLY_IN_PRICING_ROWS',
  ShowOnlyPricingParameterMissingRows = 'SHOW_ONLY_PRICING_PARAMETER_MISSING_ROWS',
  ShowItemIsNotInPim = 'SHOW_ITEM_IS_NOT_IN_PIM',
  ShowItemIsInPim = 'SHOW_ITEM_IS_IN_PIM',
  ShowRemovedItems = 'SHOW_REMOVED_ITEMS',
  ShowCriticalEquipment = 'SHOW_CRITICAL_EQUIPMENT',
  ShowRamiGreen = 'SHOW_RAMI_GREEN',
  ShowCriticalPriceRows = 'SHOW_CRITICAL_PRICE_ROWS',
  ShowCriticalPriceRowsDay = 'SHOW_CRITICAL_PRICE_ROWS_DAY',
  ShowInvalidPriceThresholdRows = 'SHOW_INVALID_PRICE_THRESHOLD_ROWS',
  showKitTypeOnly = 'SHOW_KIT_TYPE_ONLY',
}

export enum ExternalFilterType {
  OR = 'OR',
  AND = 'AND',
}

export type ExternalFilter = {
  name: ExternalFilterName;
  type: ExternalFilterType;
};

const filterToFunction = (filter: ExternalFilterName) => {
  return {
    [ExternalFilterName.ShowWarningRows]: isWarningRow,
    [ExternalFilterName.ShowWarningRowsDay]: isDayWarningRow,
    [ExternalFilterName.ShowRedRows]: isWarningPrice,
    [ExternalFilterName.ShowRedRowsDay]: isDayWarningPrice,
    [ExternalFilterName.ShowOrangeRows]: isLightWarningPrice,
    [ExternalFilterName.ShowOrangeRowsDay]: isDayLightWarningPrice,
    [ExternalFilterName.ShowPinkRows]: isBelow70PercentPriceRow,
    [ExternalFilterName.ShowPinkRowsDay]: isBelow70PercentDayPriceRow,
    [ExternalFilterName.ShowZeroPriceRows]: isZeroPriceRow,
    [ExternalFilterName.ShowZeroPriceRowsDay]: isZeroDayPriceRow,
    [ExternalFilterName.ShowComparisonPriceEmpty]:
      isComparisonPriceEmpty,
    [ExternalFilterName.ShowComparisonPriceEmptyDay]:
      isComparisonDayPriceEmpty,
    [ExternalFilterName.ShowOnlyInPricingRows]: inPricingFilter,
    [ExternalFilterName.ShowOnlyPricingParameterMissingRows]:
      missingPricingParameter,
    [ExternalFilterName.ShowItemIsNotInPim]: itemNotInPimFilter,
    [ExternalFilterName.ShowItemIsInPim]: itemInPimFilter,
    [ExternalFilterName.ShowRemovedItems]: removedItemFilter,
    [ExternalFilterName.ShowCriticalEquipment]:
      criticalEquipmentFilter,
    [ExternalFilterName.ShowRamiGreen]: ramigreenFilter,
    [ExternalFilterName.ShowCriticalPriceRowsDay]:
      isBelowCriticalPriceDay,
    [ExternalFilterName.ShowCriticalPriceRows]: isCriticalPrice,
    [ExternalFilterName.showKitTypeOnly]: showKitTypeOnly,
    [ExternalFilterName.ShowInvalidPriceThresholdRows]:
      isInvalidPriceThreshold,
  }[filter];
};

export const useFilters = () => {
  const [activeFilters, setActiveFilter] = useState<ExternalFilter[]>(
    [],
  );

  const clearFilters = () => {
    setActiveFilter([]);
  };

  const addFilter = (filter: ExternalFilter | ExternalFilter[]) => {
    setActiveFilter(activeFilters.concat(filter));
  };

  const removeFilter = (filter: ExternalFilter) => {
    setActiveFilter(
      activeFilters.filter(
        (activeFilter) => activeFilter.name !== filter.name,
      ),
    );
  };

  const toggleActiveFilter = (filter: ExternalFilter) => {
    if (isChecked(filter)) {
      removeFilter(filter);
    } else {
      addFilter(filter);
    }
  };

  const isChecked = (filter: ExternalFilter) =>
    activeFilters
      .map((activeFilter) => activeFilter.name)
      .includes(filter.name);

  const getFilters = () => {
    return activeFilters
      .map((activeFilter) => activeFilter.name)
      .map(filterToFunction);
  };

  const applyActiveFilters = (row: PricingSheetRow) => {
    const getFuncsByType = (type: ExternalFilterType) => {
      return activeFilters
        .filter((activeFilter) => activeFilter.type === type)
        .map((activeFilter) => filterToFunction(activeFilter.name));
    };

    const andFuncs = getFuncsByType(ExternalFilterType.AND);
    const orFuncs = getFuncsByType(ExternalFilterType.OR);

    // Apply "AND" functions
    let andFuncsPass = false;
    if (andFuncs.length !== 0) {
      andFuncsPass = andFuncs.every((andFunc) => andFunc(row));
    }

    // Apply "OR" functions
    const orFuncsPass = orFuncs.some((orFunc) => orFunc(row));

    // Combine result
    return andFuncsPass || orFuncsPass;
  };

  return {
    activeFilters,
    addFilter,
    removeFilter,
    getFilters,
    toggleActiveFilter,
    isChecked,
    clearFilters,
    applyActiveFilters,
  } as const;
};
