import {
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import Button from '@mui/material/Button';
import { DIALOG_TRANSITION_DURATION } from '../../../../shared/constants';

type ConfirmationDialogProps = PropsWithChildren<
  DialogProps & {
    cancelButtonLabel?: string;
    cancelButtonProps?: ButtonProps;
    confirmButtonLabel?: string;
    confirmButtonProps?: ButtonProps;
    onCancel: () => void;
    onConfirm: () => void;
    loading?: boolean;
    open: boolean;
    title: ReactNode;
  }
>;

export const ConfirmationDialog = ({
  children,
  cancelButtonLabel = 'Peruuta',
  cancelButtonProps,
  confirmButtonLabel = 'Hyväksy',
  confirmButtonProps,
  onCancel = () => null,
  onConfirm = () => null,
  loading = false,
  open,
  title,
  ...props
}: ConfirmationDialogProps) => {
  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      onClose={onCancel}
      open={open}
      transitionDuration={DIALOG_TRANSITION_DURATION}
      {...props}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          pb: 2,
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'end',
          px: 3,
          pt: 0,
          pb: 2,
        }}
      >
        <Button
          disabled={loading}
          color={'inherit'}
          {...cancelButtonProps}
          onClick={onCancel}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          variant={'contained'}
          disabled={loading}
          endIcon={
            loading && <CircularProgress thickness={4} size={16} />
          }
          {...confirmButtonProps}
          onClick={onConfirm}
        >
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
