import { getWarningCounts } from './helpers';
import { SUMMARY_COLORS } from '../../../../shared/constants';
import {
  Alert,
  AlertProps,
  alpha,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';
import { HorizontalRule } from '@mui/icons-material';

type OfferSummaryWarningsProps = {
  dense?: boolean;
  loading: boolean;
  warningCounts: ReturnType<typeof getWarningCounts>;
  width?: number;
};

export const OfferSummaryWarnings = ({
  dense = false,
  loading,
  warningCounts,
  width,
}: OfferSummaryWarningsProps) => {
  const getAlertProps = (
    hasWarnings: boolean,
    requiresApproval = false,
  ) => {
    const warningColor = requiresApproval
      ? SUMMARY_COLORS['below70Border']
      : SUMMARY_COLORS['lightWarning'];
    return {
      variant: 'outlined',
      severity: hasWarnings
        ? requiresApproval
          ? 'error'
          : 'warning'
        : 'success',
      sx: {
        alignItems: 'center',
        backgroundColor: hasWarnings
          ? warningColor
          : SUMMARY_COLORS['success'],
      },
    } as Partial<AlertProps>;
  };

  const hasBelowCoefficientWarnings =
    warningCounts.belowOneCoefficient > 0;
  const hasBelow1Point2Coefficient =
    warningCounts.below1Point2Coefficient > 0;
  const hasBelow70Warnings = warningCounts.below70 > 0;
  const hasZeroPriceWarnings = warningCounts.zeroPrice > 0;
  const hasBelowCriticalWarnings = warningCounts.belowCritical > 0;

  // loading skeletons
  if (loading) {
    return (
      <Stack
        spacing={dense ? 1 : 2}
        sx={{
          width: width || 'auto',
        }}
      >
        {Object.keys(warningCounts).map((key) => (
          <Skeleton
            key={key}
            variant={'rounded'}
            height={dense ? 40 : 50}
            sx={{
              backgroundColor: alpha('#000', 0.06),
            }}
          />
        ))}
      </Stack>
    );
  }

  const getWarningText = (text: string) => {
    const askApprovalText =
      ' Muokkaa joko hintoja tai pyydä hyväksyntää esihenkilöltä!';
    return text.concat(dense ? '' : askApprovalText);
  };

  return (
    <Stack
      spacing={dense ? 1 : 2}
      sx={{
        width: width || 'auto',
      }}
    >
      {/* BELOW ONE COEFFICIENT */}
      <SummaryAlert
        dense={dense}
        alertProps={getAlertProps(hasBelowCoefficientWarnings, true)}
        count={warningCounts.belowOneCoefficient}
        label={'Hintakertoimen 1 alituksia'}
        warningText={
          hasBelowCoefficientWarnings
            ? getWarningText(
                'Hintakertoimen 1 alituksia ei saa olla yhtään kappaletta.',
              )
            : null
        }
      />
      {/* COEFFICIENT BETWEEN 1 AND 1.2 */}
      <SummaryAlert
        dense={dense}
        alertProps={getAlertProps(hasBelow1Point2Coefficient)}
        count={warningCounts.below1Point2Coefficient}
        label={'Hintakerroin 1-1,2'}
      />
      {/* BELOW 70 */}
      <SummaryAlert
        dense={dense}
        alertProps={getAlertProps(hasBelow70Warnings, true)}
        count={warningCounts.below70}
        label={'Tuotteita, joilla hinta on alle 70 % ehdotushinnasta'}
        warningText={
          hasBelow70Warnings
            ? getWarningText(
                'Yksikään hinta ei saa olla alle 70 % ehdotushinnasta.',
              )
            : null
        }
      />
      {/* BELOW CRITICAL */}
      <SummaryAlert
        dense={dense}
        alertProps={getAlertProps(hasBelowCriticalWarnings, true)}
        count={warningCounts.belowCritical}
        label={'Kriittisen hinnan alituksia'}
        warningText={
          hasBelowCriticalWarnings
            ? getWarningText(
                'Yksikään hinta ei saa alittaa kriittiseksi kalustoksi merkatun tuotteen minimihintaa.',
              )
            : null
        }
      />
      {/* ZERO PRICE */}
      <SummaryAlert
        dense={dense}
        alertProps={getAlertProps(hasZeroPriceWarnings)}
        count={warningCounts.zeroPrice}
        label={'Nollahintoja'}
      />
    </Stack>
  );
};

type SummaryAlertProps = {
  alertProps: AlertProps;
  count: number;
  dense?: boolean;
  label: string;
  warningText?: string | null;
};

const SummaryAlert = ({
  alertProps,
  count,
  dense = false,
  label,
  warningText,
}: SummaryAlertProps) => {
  const denseStyles = {
    ...alertProps.sx,
    px: 2,
    py: 1,
    '.MuiAlert-message, .MuiAlert-icon': {
      p: 0,
    },
  };
  return (
    <Alert {...alertProps} {...(dense && { sx: denseStyles })}>
      <Typography
        fontSize={14}
        fontWeight={'bold'}
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 0.5,
        }}
      >
        <span>{label}</span>
        <HorizontalRule fontSize={'inherit'} />
        <Typography
          component={'span'}
          fontSize={'inherit'}
          fontWeight={'inherit'}
        >
          {count}
        </Typography>
      </Typography>
      {warningText && (
        <>
          <Divider sx={{ my: 0.5 }} />
          {warningText}
        </>
      )}
    </Alert>
  );
};
