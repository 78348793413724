import { Grid, Typography } from '@mui/material';
import { formatDateTolocaleDateString } from '../../utils/formatDateTimes';
import getSheetState from '../../helpers/getSheetState';

const ExternalSheetOption = ({
  value,
  label,
  state,
  customerName,
  from,
  to,
}: any) => {
  if (value === 'isMiraSheet') {
    return <Typography color={'text.primary'}>{label}</Typography>;
  }
  return (
    <div>
      <Typography>{customerName}</Typography>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontSize={14}>{getSheetState(state)}</Typography>
        <Typography fontSize={14}>
          {`${formatDateTolocaleDateString(from)} -
        ${formatDateTolocaleDateString(to)}`}
        </Typography>
      </Grid>

      <Typography fontSize={14} color={'text.secondary'}>
        {label}
      </Typography>
    </div>
  );
};

export default ExternalSheetOption;
