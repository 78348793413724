import { countRemovalDayPrice } from '../../../shared/countRemovalDayPrice';
import { countRemovalMonthPrice } from '../../../shared/countRemovalMonthPrice';
import { PricingSheetRow } from '../../../shared/types';

import {
  countPriceCoefficient,
  COEFFICIENT_LIMIT,
  COEFFICIENT_LIMIT_LIGHT,
} from '../utils/pricingFormulas';

const getUnderCoefficientCount = (rows: PricingSheetRow[]) => {
  const count = rows.reduce(
    (acc: number, current: PricingSheetRow) => {
      let c = 0;

      if (
        typeof current.dayPrice === 'number' &&
        !current.showByOfferText
      ) {
        const removalDayPrice = countRemovalDayPrice(
          Number(current.rentalReadyPrice),
          Number(current.depreciationPeriod),
          Number(current.targetUtilRate) / 100,
        );

        const coefficientDayPrice = removalDayPrice
          ? countPriceCoefficient(current.dayPrice, removalDayPrice)
          : null;

        if (
          coefficientDayPrice &&
          coefficientDayPrice < COEFFICIENT_LIMIT_LIGHT &&
          coefficientDayPrice > COEFFICIENT_LIMIT
        ) {
          c += 1;
        }
      }

      if (
        typeof current.monthPrice === 'number' &&
        !current.showByOfferText
      ) {
        const removalMonthPrice = countRemovalMonthPrice(
          Number(current.rentalReadyPrice),
          Number(current.depreciationPeriod),
          Number(current.targetUtilRate) / 100,
        );

        const coefficientMonthPrice = removalMonthPrice
          ? countPriceCoefficient(
              current.monthPrice,
              removalMonthPrice,
            )
          : null;

        if (
          coefficientMonthPrice &&
          coefficientMonthPrice < COEFFICIENT_LIMIT_LIGHT &&
          coefficientMonthPrice > COEFFICIENT_LIMIT
        ) {
          c += 1;
        }
      }

      return acc + c;
    },
    0,
  );
  return count;
};

export default getUnderCoefficientCount;
