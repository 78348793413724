import discountDayGetter from '../valueGetters/discountDayGetter';
import discountMonthGetter from '../valueGetters/discountMonthGetter';
import priceThresholdGetter from '../valueGetters/priceThresholdGetter';

import currencyFormatter from '../formatters/priceFormatter';
import percentFormatter from '../formatters/percentFormatter';

import { getClassForAgGridCell } from '../../../helpers/cellHelpers';

import {
  Nullable,
  PricingSheetRow,
  RoundingBasis,
} from '../../../../../shared/types';
import NumericEditorV2 from '../Editors/NumericEditorV2';

import {
  CellClassParams,
  CellStyle,
  ValueGetterParams,
} from 'ag-grid-community';
import { dayPriceGetter } from '../valueGetters/dayPriceGetter';
import { monthPriceGetter } from '../valueGetters/monthPriceGetter';
import proposalDayPriceGetter from '../valueGetters/proposalDayPriceGetter';
import proposalMonthPriceGetter from '../valueGetters/proposalMonthPriceGetter';
import { GridColumnProps } from './ColumnsBase';
import comparisonPriceValueGetter from '../valueGetters/comparisonPriceValueGetter';
import dayPricePercentGetter from '../valueGetters/dayPricePercentGetter';
import monthPricePercentGetter from '../valueGetters/monthPricePercentGetter';
import { getCatClassColDef } from '../helpers/getCatClassColDef';

interface OptionalColumnProps {
  roundingBasisSheet: RoundingBasis;
  handleUpdateRowValue: (row: PricingSheetRow) => void;
}

const ColumnsBoth = ({
  classes,
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps<OptionalColumnProps>) => {
  const { handleUpdateRowValue } = optionalColumnProps;
  /*
   * for some reason right border does not apply if we pass it from cellClass
   * therefore we define borders here in cellStyles and only get colors from cellClass
   * */
  const columns = [
    ...getCatClassColDef(gridGroupingValues),
    {
      headerName: 'EhdotusPVHinta',
      filter: 'agNumberColumnFilter',
      valueGetter: proposalDayPriceGetter,
      valueFormatter: currencyFormatter,
      headerTooltip: 'Työkalun ehdottama hinta päivähinnaksi',
      cellStyle: {
        backgroundColor: '#fafafa',
        borderLeft: '1px solid #ccc',
      } as CellStyle,
    },
    {
      field: 'discountDay',
      headerName: 'Alennus% (PV)',
      filter: 'agNumberColumnFilter',
      valueGetter: discountDayGetter,
      valueFormatter: percentFormatter,
      editable: (params: { data: PricingSheetRow }) =>
        !params.data.showByOfferText,
      headerTooltip: 'Päivähinta verrattuna ehdotushintaan',
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellClass: (params: CellClassParams) => {
        return getClassForAgGridCell(
          params.data,
          classes,
          'discountDay',
        );
      },
      cellStyle: () => {
        return {
          color: '#000',
          borderLeft: '1px solid #ccc',
        } as CellStyle;
      },
    },
    {
      field: 'dayPrice',
      headerName: 'PVHinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<PricingSheetRow>) =>
        dayPriceGetter(
          params,
          optionalColumnProps.roundingBasisSheet,
        ),
      valueFormatter: currencyFormatter,
      editable: (params: { data: PricingSheetRow }) =>
        !params.data.showByOfferText,
      headerTooltip: 'Tuotteen päivähinta',
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellClass: (params: CellClassParams) => {
        return getClassForAgGridCell(
          params.data,
          classes,
          'dayPrice',
        );
      },
      cellStyle: () => {
        return {
          color: '#000',
          borderLeft: '1px solid #ccc',
          borderRight: '1px solid #ccc',
        } as CellStyle;
      },
    },
    {
      field: 'priceThreshold',
      headerName: 'PV->KK kerroin',
      filter: 'agNumberColumnFilter',
      valueGetter: priceThresholdGetter,
      editable: (params: { data: PricingSheetRow }) =>
        !params.data.showByOfferText,
      headerTooltip:
        'Kuinka moninkertainen kuukausihinta on verrattuna päivähintaan?',
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: () => {
        return {
          color: '#000',
          borderRight: '1px solid #ccc',
        } as CellStyle;
      },
      cellClass: (params: CellClassParams) => {
        return getClassForAgGridCell(
          params.data,
          classes,
          'priceThreshold',
        );
      },
    },
    {
      field: 'comparisonDayPrice',
      headerName: 'VRThintaPV',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<PricingSheetRow>) =>
        comparisonPriceValueGetter(
          params,
          optionalColumnProps.roundingBasisSheet,
          params.data?.comparisonDayPrice,
        ),
      valueFormatter: currencyFormatter,
      headerTooltip:
        'Tuotteen päivähinta valitussa vertailuhinnastossa',
      cellStyle: {
        backgroundColor: '#fafafa',
      },
    },
    {
      field: 'comparisonDayPricePercent',
      headerName: 'VRTero%PV',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<PricingSheetRow>) =>
        dayPricePercentGetter(
          params,
          params.data?.comparisonDayPrice,
        ),
      valueFormatter: (params: {
        value: Nullable<number>;
        data: PricingSheetRow | undefined;
      }) => {
        if (!params.value) return '';
        return `${params.value} %`;
      },
      cellStyle: {
        backgroundColor: '#fafafa',
        borderLeft: '1px solid #ccc',
      } as CellStyle,
      suppressColumnsToolPanel: false,
      hide:
        localStorage.getItem(
          'columnVisibility_comparisonDayPricePercent',
        ) !== 'true',
    },
    {
      field: 'proposalMonthPrice',
      headerName: 'EhdotusKKHinta',
      filter: 'agNumberColumnFilter',
      valueGetter: proposalMonthPriceGetter,
      valueFormatter: currencyFormatter,
      headerTooltip: 'Työkalun ehdottama hinta kuukausihinnaksi',
      cellStyle: {
        backgroundColor: '#fafafa',
        borderLeft: '1px solid #ccc',
      } as CellStyle,
    },
    {
      field: 'discountMonth',
      headerName: 'Alennus% (KK)',
      filter: 'agNumberColumnFilter',
      valueGetter: discountMonthGetter,
      valueFormatter: percentFormatter,
      editable: (params: { data: PricingSheetRow }) =>
        !params.data.showByOfferText,
      headerTooltip: 'Kuukausihinta verrattuna ehdotushintaan',
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellClass: (params: CellClassParams) => {
        return getClassForAgGridCell(
          params.data,
          classes,
          'discountMonth',
        );
      },
      cellStyle: () => {
        return {
          color: '#000',
          borderLeft: '1px solid #ccc',
          borderRight: '1px solid #ccc',
        } as CellStyle;
      },
    },
    {
      field: 'monthPrice',
      headerName: 'KKHinta',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<PricingSheetRow>) =>
        monthPriceGetter(
          params,
          optionalColumnProps.roundingBasisSheet,
        ),
      valueFormatter: currencyFormatter,
      editable: (params: { data: PricingSheetRow }) =>
        !params.data.showByOfferText,
      headerTooltip: 'Tuotteen kuukausihinta',
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellClass: (params: CellClassParams) => {
        return getClassForAgGridCell(
          params.data,
          classes,
          'monthPrice',
        );
      },
      cellStyle: () => {
        return {
          color: '#000',
          borderRight: '1px solid #ccc',
        } as CellStyle;
      },
    },
    {
      field: 'comparisonMonthPrice',
      headerName: 'VRThintaKK',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<PricingSheetRow>) =>
        comparisonPriceValueGetter(
          params,
          optionalColumnProps.roundingBasisSheet,
          params.data?.comparisonMonthPrice,
        ),
      valueFormatter: currencyFormatter,
      headerTooltip:
        'Tuotteen kuukausihinta valitussa vertailuhinnastossa',
      cellStyle: {
        backgroundColor: '#fafafa',
      },
    },
    {
      field: 'comparisonMonthPricePercent',
      headerName: 'VRTero%KK',
      filter: 'agNumberColumnFilter',
      valueGetter: (params: ValueGetterParams<PricingSheetRow>) =>
        monthPricePercentGetter(
          params,
          params.data?.comparisonMonthPrice,
        ),
      valueFormatter: (params: {
        value: Nullable<number>;
        data: PricingSheetRow | undefined;
      }) => {
        if (!params.value) return '';
        return `${params.value} %`;
      },
      cellStyle: {
        backgroundColor: '#fafafa',
        borderLeft: '1px solid #ccc',
      } as CellStyle,
      suppressColumnsToolPanel: false,
      hide:
        localStorage.getItem(
          'columnVisibility_comparisonMonthPricePercent',
        ) !== 'true',
    },
  ];
  return columns;
};

export default ColumnsBoth;
