import {
  ItemIndustryPercent,
  PricingSheetRow,
} from '../../../../shared/types';
import isEqual from 'lodash/isEqual';

export const isHistoryChangedInPricingSide = (
  rowsInHistory: PricingSheetRow[],
  preSelectedPricingSheetRows: PricingSheetRow[],
) => {
  return rowsInHistory
    ? !(
        JSON.stringify(
          rowsInHistory
            .filter(
              (pricingSheetRow: PricingSheetRow) =>
                pricingSheetRow.preSelected,
            )
            .map((row) => {
              return {
                id: row.id,
                catClass: row.catClass,
                dayPrice: row.dayPrice,
                monthPrice: row.monthPrice,
              };
            }),
        ) ===
        JSON.stringify(
          preSelectedPricingSheetRows.map((row: PricingSheetRow) => {
            return {
              id: row.id,
              catClass: row.catClass,
              dayPrice: row.dayPrice,
              monthPrice: row.monthPrice,
            };
          }),
        )
      )
    : true;
};

const normalizePricingSheetRows = (
  pricingSheetsRows: PricingSheetRow[] | undefined,
): PricingSheetRow[] => {
  if (!pricingSheetsRows) {
    return [];
  }
  return pricingSheetsRows.map((row) => ({
    ...row,
    dayPriceCoefficient1: Number(row.dayPriceCoefficient1),
    dayPriceCoefficient2: Number(row.dayPriceCoefficient2),
    dayPriceCoefficient3: Number(row.dayPriceCoefficient3),
    dayPriceCoefficient4: Number(row.dayPriceCoefficient4),
    dayPriceCoefficient5: Number(row.dayPriceCoefficient5),
    dayPriceCoefficient6: Number(row.dayPriceCoefficient6),
    dayPriceCoefficient7: Number(row.dayPriceCoefficient7),
    dayPriceCoefficientList: Number(row.dayPriceCoefficientList),
    monthPriceCoefficient1: Number(row.monthPriceCoefficient1),
    monthPriceCoefficient2: Number(row.monthPriceCoefficient2),
    monthPriceCoefficient3: Number(row.monthPriceCoefficient3),
    monthPriceCoefficient4: Number(row.monthPriceCoefficient4),
    monthPriceCoefficient5: Number(row.monthPriceCoefficient5),
    monthPriceCoefficient6: Number(row.monthPriceCoefficient6),
    monthPriceCoefficient7: Number(row.monthPriceCoefficient7),
    monthPriceCoefficientList: Number(row.monthPriceCoefficientList),
    criticalEquipmentItems: row.criticalEquipmentItems.map(
      (item) => ({
        ...item,
        minPriceDay: Number(item.minPriceDay),
        minPriceMonth: Number(item.minPriceMonth),
      }),
    ),
    surplusEquipmentItem: row.surplusEquipmentItem || null,
  }));
};

const normalizeItemIndustryPercents = (
  itemIndustryPercents: ItemIndustryPercent[] | undefined,
) => {
  if (!itemIndustryPercents) {
    return [];
  }
  return itemIndustryPercents.map(
    (percentRow: ItemIndustryPercent) => ({
      ...percentRow,
      dayPricePercent1: Number(percentRow.dayPricePercent1),
      dayPricePercent2: Number(percentRow.dayPricePercent2),
      dayPricePercent3: Number(percentRow.dayPricePercent3),
      dayPricePercent4: Number(percentRow.dayPricePercent4),
      dayPricePercent5: Number(percentRow.dayPricePercent5),
      dayPricePercent6: Number(percentRow.dayPricePercent6),
      dayPricePercent7: Number(percentRow.dayPricePercent7),
      dayPricePercentList: Number(percentRow.dayPricePercentList),
      monthPricePercent1: Number(percentRow.monthPricePercent1),
      monthPricePercent2: Number(percentRow.monthPricePercent2),
      monthPricePercent3: Number(percentRow.monthPricePercent3),
      monthPricePercent4: Number(percentRow.monthPricePercent4),
      monthPricePercent5: Number(percentRow.monthPricePercent5),
      monthPricePercent6: Number(percentRow.monthPricePercent6),
      monthPricePercent7: Number(percentRow.monthPricePercent7),
      monthPricePercentList: Number(percentRow.monthPricePercentList),
    }),
  );
};

export const isHistoryChangedInAdminSide = (
  currentRowsInDb: PricingSheetRow[] | undefined,
  pricingSheetRows: PricingSheetRow[],
  currentItemIndustryPercentsState: any,
  itemIndustryPercents: ItemIndustryPercent[] | undefined,
) => {
  const rowsChanged = !isEqual(
    normalizePricingSheetRows(currentRowsInDb),
    normalizePricingSheetRows(pricingSheetRows),
  );

  const percentsChanged = !isEqual(
    normalizeItemIndustryPercents(currentItemIndustryPercentsState),
    normalizeItemIndustryPercents(itemIndustryPercents),
  );

  return rowsChanged || percentsChanged;
};
