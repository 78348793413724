import { PricingBasis, SheetType } from '../../../shared/types';

export const formatPricingBasis = (
  pricingBasis: PricingBasis,
  sheetType: SheetType = SheetType.PRICING,
): string => {
  if (pricingBasis === 'DAY') {
    return sheetType === SheetType.PRICING
      ? 'Päivähinnasto'
      : 'Päivätarjous';
  }
  if (pricingBasis === 'MONTH') {
    return sheetType === SheetType.PRICING
      ? 'Kuukausihinnasto'
      : 'Kuukausitarjous';
  }
  if (pricingBasis === 'BOTH') {
    return sheetType === SheetType.PRICING
      ? 'Päivä- ja kuukausihinnasto'
      : 'Päivä- ja kuukausitarjous';
  }
  return 'Tuntematon';
};
