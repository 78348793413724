import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  SubtleText,
  differenceInDaysString,
} from '../InitialInformationStep';

import { BackgroundInfo } from '../../../../../shared/types';

interface DurationProps {
  backgroundInfo: BackgroundInfo;
}
const Duration: React.FC<DurationProps> = ({ backgroundInfo }) => {
  return (
    <Box>
      <SubtleText>Sopimuksen kesto</SubtleText>
      <Box sx={{ ml: 2 }}>
        <Typography
          sx={{ m: 1 }}
          variant="h4"
          component="div"
          gutterBottom
          color="text.primary"
        >
          {differenceInDaysString(
            backgroundInfo?.priceSheetStartDate,
            backgroundInfo?.priceSheetEndDate,
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default Duration;
