import {
  Nullable,
  PricingSheetRow,
  RoundingBasis,
} from '../../../../../shared/types';

import { PRICE_FACTOR } from '../../../../../shared/constants';
import { ValueGetterParams } from 'ag-grid-community';

const comparisonPriceValueGetter = <T extends PricingSheetRow>(
  params: ValueGetterParams<T>,
  roundingBasis: RoundingBasis,
  value: Nullable<number>,
) => {
  const showByOfferText = params.data?.showByOfferText;

  if (showByOfferText) {
    return showByOfferText ? 'Tarjouksen mukaan' : value ?? null;
  }

  const dividedPrice = value ? value / PRICE_FACTOR : value;

  if (roundingBasis === 'THREESIGNIFICANT') {
    return dividedPrice ? Number(dividedPrice.toPrecision(3)) : null;
  }

  const digits = roundingBasis === 'TWODECIMALS' ? 2 : 6;

  return dividedPrice ? Number(dividedPrice.toFixed(digits)) : null;
};

export default comparisonPriceValueGetter;
