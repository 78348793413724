import { ItemType, PricingSheetRow } from "./types";

const isRental = (item: PricingSheetRow) => item.type === ItemType.Rental;

export const sortByItemType = (
  a: PricingSheetRow | undefined,
  b: PricingSheetRow | undefined,
) => {
  if (a && b) {
    // rental items come first
    if (isRental(b) && !isRental(a)) return -1;
    if (!isRental(b) && isRental(a)) return 1;
    if (b.type === a.type) {
      if (a.id === b.id) {
        return b.pricingBasis.localeCompare(a.pricingBasis);
      }
      // additionally sort rental and sales items by catClass
      return b.catClass.localeCompare(a.catClass);
    }
  }
  return 0;
};
