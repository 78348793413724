import { Dispatch, SetStateAction } from 'react';

import TextField from '@mui/material/TextField';

interface EditPricesProps {
  priceValue: number | null | undefined;
  setPriceValue: Dispatch<SetStateAction<number | null | undefined>>;
  formatInputValue: any;
  isAbsolutePriceValid: boolean;
}

const EditPrices: React.FC<EditPricesProps> = ({
  priceValue,
  setPriceValue,
  formatInputValue,
  isAbsolutePriceValid,
}) => {
  return (
    <>
      <TextField
        sx={{ mt: 1 }}
        size="small"
        id="percent-change"
        label="Hinta €"
        type="number"
        value={formatInputValue(priceValue)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setPriceValue(Number(event.target.value))
        }
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          min: 0,
        }}
        error={!isAbsolutePriceValid}
      />
    </>
  );
};

export default EditPrices;
