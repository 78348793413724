import { CustomerType } from '../../../shared/types';
import { MiraCustomerData } from '../../../shared/types/customer';
import { isCustomerBlocked } from './isCustomerBlocked';

/*
 * order: Customers, Prospects, Blocked customers
 */
export const sortCustomersByType = (
  a: MiraCustomerData,
  b: MiraCustomerData,
) => {
  // blocked customers last
  if (isCustomerBlocked(a) && isCustomerBlocked(b)) return 0;
  if (!isCustomerBlocked(a) && isCustomerBlocked(b)) return -1;

  // customers before prospects
  if (
    a.type === CustomerType.MIRA &&
    b.type === CustomerType.PROSPECT
  ) {
    return -1;
  }
  if (
    a.type === CustomerType.PROSPECT &&
    b.type === CustomerType.MIRA
  ) {
    return 1;
  }

  return 0;
};
