import { NodeHierarchy } from '../../../shared/types/automaticRun';

export const getHierarchySortIndexMapping = (
  hierarchyTree: NodeHierarchy | undefined,
) =>
  Object.values(hierarchyTree || {}).reduce(
    (acc, { displayName, sortIndex }) => ({
      ...acc,
      [displayName]: sortIndex,
    }),
    {} as Record<string, number>,
  );
