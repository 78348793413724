import {
  PricingSheetRow,
  CellType,
  OfferItem,
  Nullable,
} from '../../../shared/types';

import {
  isBelow70PercentDayPriceRow,
  isBelow70PercentMonthPriceRow,
  isDayWarningPrice,
  isDayLightWarningPrice,
  isMonthWarningPrice,
  isMonthLightWarningPrice,
  isZeroDayPriceRow,
  isZeroMonthPriceRow,
  isBelowCriticalPriceDay,
  isBelowCriticalPriceMonth,
  isInvalidPriceThreshold,
} from '../utils/pricingSheetRowFilters';
import { CompareType } from '../../../shared/types/offers';
import { useOfferStore } from '../stores/offerStore';

export const getClassForAgGridCell = (
  row: PricingSheetRow | undefined,
  classes: {
    headerCell: string;
    childHeaderCell: string;
    orangePriceCell?: string;
    redPriceCell?: string;
    pinkPercentCell?: string;
    zeroPriceCell?: string;
    editableCell?: string;
    priceCell?: string;
    criticalPriceCell?: string;
    invalidPriceThresholdCell?: string;
  },
  type: CellType,
) => {
  if (!row) {
    return 'initial';
  }
  const showDayPriceWarning = isDayWarningPrice(row);
  const showDayPriceLightWarning = isDayLightWarningPrice(row);
  const showDayPriceBelow70Percent = isBelow70PercentDayPriceRow(row);

  const showMonthPriceWarning = isMonthWarningPrice(row);
  const showMonthPriceLightWarning = isMonthLightWarningPrice(row);
  const showMonthPriceBelow70Percent =
    isBelow70PercentMonthPriceRow(row);

  const showDayZeroPriceWarning = isZeroDayPriceRow(row);
  const showMonthZeroPriceWarning = isZeroMonthPriceRow(row);

  const showCriticalDayPriceWarning = isBelowCriticalPriceDay(row);
  const showCriticalMonthPriceWarning =
    isBelowCriticalPriceMonth(row);

  const showInvalidPriceThresholdWarning =
    isInvalidPriceThreshold(row);
  switch (type) {
    case 'dayPrice':
      return showDayZeroPriceWarning
        ? classes.zeroPriceCell
        : showCriticalDayPriceWarning && !row.showByOfferText
          ? classes.criticalPriceCell
          : showDayPriceWarning && !row.showByOfferText
            ? classes.redPriceCell
            : showDayPriceLightWarning && !row.showByOfferText
              ? classes.orangePriceCell
              : !row.showByOfferText
                ? classes.priceCell
                : 'initial';
    case 'monthPrice':
      return showMonthZeroPriceWarning
        ? classes.zeroPriceCell
        : showCriticalMonthPriceWarning && !row.showByOfferText
          ? classes.criticalPriceCell
          : showMonthPriceWarning && !row.showByOfferText
            ? classes.redPriceCell
            : showMonthPriceLightWarning && !row.showByOfferText
              ? classes.orangePriceCell
              : !row.showByOfferText
                ? classes.priceCell
                : 'initial';
    case 'discountDay':
      return showDayPriceBelow70Percent && !row.showByOfferText
        ? classes.pinkPercentCell
        : !row.showByOfferText
          ? classes.editableCell
          : 'initial';
    case 'discountMonth':
      return showMonthPriceBelow70Percent && !row.showByOfferText
        ? classes.pinkPercentCell
        : !row.showByOfferText
          ? classes.editableCell
          : 'initial';
    case 'priceThreshold':
      return showInvalidPriceThresholdWarning
        ? classes.invalidPriceThresholdCell
        : classes.editableCell;
    case 'ramiturvaPrice':
      return classes.priceCell;
    case 'quantity':
    case 'pricingBasis':
    case 'comment':
    case 'approvalApproverComment':
    case 'leasePeriodStart':
    case 'leasePeriodEnd':
    case 'unit':
      return classes.editableCell;
    default:
      console.error(`Tuntematon tyyppi ${type}.`);
  }
};

export const getOfferExternalComparisonPriceValue = (
  data: OfferItem | undefined,
  compareType: CompareType,
  priceType: 'DAY' | 'MONTH',
): Nullable<number> => {
  if (!data?.catClass) {
    return undefined;
  }
  const contractItems =
    useOfferStore.getState().compare[`${compareType}Sheet`].items;
  const { dayPrice, monthPrice } =
    contractItems?.[data.catClass] || {};
  return priceType === 'DAY' ? dayPrice : monthPrice;
};
