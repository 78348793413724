import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  SubtleText,
  pricingValue,
  differenceInDays,
} from '../InitialInformationStep';

import { BackgroundInfo } from '../../../../../shared/types';

interface ValueProps {
  backgroundInfo: BackgroundInfo;
}
const Value: React.FC<ValueProps> = ({ backgroundInfo }) => {
  return (
    <Box>
      <SubtleText>Sopimuksen arvo</SubtleText>

      <Box sx={{ ml: 2 }}>
        <Typography
          sx={{ m: 1 }}
          variant="h4"
          component="div"
          gutterBottom
          color="text.primary"
        >
          {pricingValue(
            backgroundInfo?.potential,
            differenceInDays(
              backgroundInfo?.priceSheetStartDate,
              backgroundInfo?.priceSheetEndDate,
            ),
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default Value;
