export const getUniquesByKey = (arr: any[], key: string) => {
  const keys = new Set();
  return arr?.filter(
    (el: { [x: string]: unknown }) =>
      !keys.has(el[key]) && keys.add(el[key]),
  );
};

export const getUniques = (arr: any[]) =>
  arr.filter((x, i, a) => a.indexOf(x) === i);
