import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { getItems } from '../services/items';
import { ITEMS_QUERY_KEY } from '../../../shared/constants';
import {
  DatabaseItem,
  OfferItem,
  RamiturvaNames,
  RamiturvaPricesMira,
} from '../../../shared/types';
import { DatabaseOfferWithItems } from '../../../shared/types/offers';
import { countOfferProposalPrice } from '../../../shared/countOfferProposalPrice';
import { sortByCatClass } from '../../../shared/sortByCatClass';
import { AxiosError } from 'axios';
import { composeMiraOfferItem } from '../pages/OfferSheets/helpers';
import { isSalesItem } from '../../../shared/helpers/isSalesItem';
import _ from 'lodash';

type QueryOptions<T extends DatabaseItem = DatabaseItem> = Omit<
  UseQueryOptions<T[], AxiosError, T[], string[]>,
  'queryKey' | 'queryFn'
>;

type ItemsBaseParams = {
  includeHidden?: boolean;
  // Returns only the items that have been selected for the offer when true
  offerSheetItemsOnly?: boolean;
  includeRamiturvaFetch?: boolean;
  ramiturvaPricesMira?: RamiturvaPricesMira;
  ramiturvaNames?: RamiturvaNames;
};

type DatabaseItemsParams = ItemsBaseParams & {
  offerSheet?: undefined;
  options?: QueryOptions;
};

type OfferItemsParams = ItemsBaseParams & {
  offerSheet?: DatabaseOfferWithItems;
  options?: QueryOptions<OfferItem>;
};
// todo: update hook to allow only to prefetch? > change enabled flag on useQuery depending on prefetch variable value
export function useItems(
  params: DatabaseItemsParams,
): UseQueryResult<DatabaseItem[]>;

export function useItems(
  params: OfferItemsParams,
): UseQueryResult<OfferItem[]>;

export function useItems(
  params: DatabaseItemsParams | OfferItemsParams,
): UseQueryResult<DatabaseItem[] | OfferItem[]> {
  const {
    includeHidden = false,
    offerSheet,
    offerSheetItemsOnly,
    options,
    ramiturvaPricesMira = {},
    ramiturvaNames = {},
  } = params;
  const queryKey = [ITEMS_QUERY_KEY];
  if (includeHidden) {
    queryKey.push('includeHidden');
  }
  const queryResult = useQuery({
    queryKey,
    queryFn: () => getItems(includeHidden),
    staleTime: Infinity,
    ...options,
  });

  if (!queryResult.data || !offerSheet) {
    return queryResult;
  }

  // calculate proposal prices for items if offerSheet is given
  const {
    priceCategory,
    industry,
    items,
    offerLeasePeriodStart,
    offerLeasePeriodEnd,
  } = offerSheet;

  let offerItems: OfferItem[] = [];
  if (offerSheetItemsOnly) {
    offerItems = items.flatMap((databaseItem) => {
      const {
        approvalApproverComment = null,
        id,
        quantity,
        leasePeriodStart,
        leasePeriodEnd,
        pricingBasis,
        comment,
        details,
        unit,
        includeRamiturva,
      } = databaseItem;

      let item: DatabaseItem | undefined;
      if (!databaseItem.itemId && details) {
        item = composeMiraOfferItem({
          catClass: details.miraItemNumber || '',
          name: details.miraName,
          type: details.miraItemType,
          leasePeriodStart,
          leasePeriodEnd,
        });
      } else {
        item = queryResult.data.find(
          (item) => item.id === databaseItem.itemId,
        );
      }
      if (!item) {
        return [];
      }
      let pricing;
      if (isSalesItem(item.type)) {
        const {
          dayPrice,
          monthPrice,
          proposalDayPrice,
          proposalMonthPrice,
        } = databaseItem;
        pricing = {
          dayPrice: dayPrice || proposalDayPrice,
          monthPrice: monthPrice || proposalMonthPrice,
          proposalDayPrice,
          proposalMonthPrice,
        };
      } else {
        const {
          proposalDayPrice,
          proposalMonthPrice,
          minDayPrice,
          minMonthPrice,
          showSurplusPercentage,
        } = countOfferProposalPrice({
          item,
          priceCategory,
          industry,
          offerLeasePeriodStart: leasePeriodStart
            ? new Date(leasePeriodStart)
            : new Date(offerLeasePeriodStart),
          offerLeasePeriodEnd: leasePeriodEnd
            ? new Date(leasePeriodEnd)
            : new Date(offerLeasePeriodEnd),
        });
        const ramiturvaProposalDayPrice = _.get(ramiturvaPricesMira, [
          `XR${item.catClass}`,
          'ramiturvaDayPrice',
        ]);
        const ramiturvaProposalMonthPrice = _.get(
          ramiturvaPricesMira,
          [`XR${item.catClass}`, 'ramiturvaMonthPrice'],
        );

        // use the day and month price of pre-selected offer sheet items instead of the calculated one
        pricing = {
          dayPrice: databaseItem.dayPrice,
          monthPrice: databaseItem.monthPrice,
          proposalDayPrice,
          proposalMonthPrice,
          minDayPrice,
          minMonthPrice,
          showSurplusPercentage,
          ramiturvaProposalDayPrice,
          ramiturvaProposalMonthPrice,
          ramiturvaDayPrice:
            databaseItem.ramiturvaDayPrice ??
            ramiturvaProposalDayPrice,
          ramiturvaMonthPrice:
            databaseItem.ramiturvaMonthPrice ??
            ramiturvaProposalMonthPrice,
        };
      }

      const ramiturvaName = _.get(ramiturvaNames, [
        `XR${item.catClass}`,
        'ramiturvaName',
      ]);

      // final result
      return {
        ...item,
        ...pricing,
        offerItemId: id,
        pimProductGroup:
          item.pimProductGroup ?? 'Ryhmittelemättömät tuotteet',
        preSelected: true,
        approvalApproverComment,
        quantity,
        leasePeriodStart,
        leasePeriodEnd,
        comment,
        pricingBasis,
        unit,
        includeRamiturva,
        ramiturvaName,
      };
    });
  } else {
    // unique array containing all the selected itemIds
    const preSelectedItemIds = [
      ...new Set(items.map(({ itemId }) => String(itemId))),
    ];
    offerItems = queryResult.data.map((item) => {
      let ramiturvaName = _.get(ramiturvaNames, [
        `XR${item.catClass}`,
        'ramiturvaName',
      ]);
      const ramiturvaProposalDayPrice = _.get(ramiturvaPricesMira, [
        `XR${item.catClass}`,
        'ramiturvaDayPrice',
      ]);
      const ramiturvaProposalMonthPrice = _.get(ramiturvaPricesMira, [
        `XR${item.catClass}`,
        'ramiturvaMonthPrice',
      ]);
      const preSelected = preSelectedItemIds.includes(`${item.id}`);
      return {
        // common item data and proposal prices
        ...countOfferProposalPrice({
          item,
          priceCategory,
          industry,
          offerLeasePeriodStart: new Date(offerLeasePeriodStart),
          offerLeasePeriodEnd: new Date(offerLeasePeriodEnd),
        }),
        pimProductGroup:
          item.pimProductGroup ?? 'Ryhmittelemättömät tuotteet',
        preSelected,
        ramiturvaName: ramiturvaName,
        ramiturvaProposalDayPrice,
        ramiturvaProposalMonthPrice,
        ramiturvaDayPrice: ramiturvaProposalDayPrice,
        ramiturvaMonthPrice: ramiturvaProposalMonthPrice,
      };
    });
  }

  return {
    ...queryResult,
    data: sortByCatClass(offerItems),
  };
}
