import React from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { industries } from '../../../../../../shared/industries';
import {
  IndustryName,
  Nullable,
} from '../../../../../../shared/types';

interface IndustrySelectorBaseProps {
  changeValuesEnabled: boolean;
  disabled?: boolean;
  pricingSheetId?: Nullable<number>;
  onIndustryChange: (newValue: IndustryName) => void;
  showLabel?: boolean;
  width?: number;
}

interface ClearableIndustrySelectorProps
  extends IndustrySelectorBaseProps {
  clearable?: true;
  industry: Nullable<IndustryName>;
}
interface NonClearableIndustrySelectorProps
  extends IndustrySelectorBaseProps {
  clearable?: false;
  industry: IndustryName;
}

type IndustrySelectorProps =
  | ClearableIndustrySelectorProps
  | NonClearableIndustrySelectorProps;

const IndustrySelector = ({
  clearable = true,
  disabled = false,
  changeValuesEnabled,
  onIndustryChange,
  pricingSheetId,
  industry,
  showLabel = true,
  width = 300,
}: IndustrySelectorProps) => {
  return (
    <Autocomplete
      disableClearable={!clearable}
      size="small"
      value={industry ?? null}
      onChange={(
        _: React.SyntheticEvent<Element, Event>,
        newValue: string | null,
      ) => {
        onIndustryChange(newValue as IndustryName);
      }}
      id="controllable-industry"
      options={industries}
      sx={{ width }}
      disabled={
        disabled || !!(pricingSheetId && !changeValuesEnabled)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          {...(showLabel && { label: 'Valitse  toimiala...' })}
        />
      )}
    />
  );
};

export default IndustrySelector;
