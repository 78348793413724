import { useContext, useState, useEffect } from 'react';

import RowsProvider from '../providers/Rows/RowsProvider';
import NotificationProvider from '../providers/Notification/NotificationProvider';
import BackgroundProvider from '../providers/Background/BackgroundProvider';

import useItemIndustryPercentsContext from '../providers/Rows/ItemIndustryPercents/ItemIndustryPercentsProvider';

import {
  ItemIndustryPercent,
  PricingSheetRow,
  RoundingBasis,
} from '../../../shared/types';

import { sortByCatClass } from '../../../shared/sortByCatClass';
import { ItemIndustryPercentActions } from '../providers/Rows/ItemIndustryPercents/itemIndustryPercentsReducer';

export type RowsHistoryType = {
  timeStamp: number;
  rows: PricingSheetRow[];
  itemIndustryPercents?: any; // used on in admin side
  roundingBasisSheet: RoundingBasis;
  roundingBasisPdf: RoundingBasis;
};

const useRowsHistory = () => {
  const [rowsHistory, setRowsHistory] = useState<
    Array<RowsHistoryType>
  >([]);

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const currentRowsInHistory = rowsHistory[currentIndex]?.rows;
  const currentItemIndustryPercentsInHistory =
    rowsHistory[currentIndex]?.itemIndustryPercents;

  const { dispatchPricingSheetRows } = useContext(RowsProvider);
  const { setNotification } = useContext(NotificationProvider);
  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );

  const { dispatchItemIndustryPercents } =
    useItemIndustryPercentsContext();

  const updateRows = (
    rows: PricingSheetRow[],
    itemIndustryPercents: ItemIndustryPercent[],
  ) => {
    try {
      dispatchPricingSheetRows({
        type: 'updatePricingSheetRows',
        newRows: sortByCatClass([...rows]),
      });

      if (itemIndustryPercents) {
        dispatchItemIndustryPercents({
          type: ItemIndustryPercentActions.UpdateItems,
          itemIndustryPercents: [...itemIndustryPercents],
        });
      }

      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: 'Muutokset palautettu!',
      });

      setRowsHistory(rowsHistory);

      return {
        rows,
        itemIndustryPercents,
      };
    } catch (error) {
      setNotification({
        severity: 'error',
        message: 'Virhe palauttaessa muutoksia!',
      });
    }
  };

  // every time rowsHistory is changed we must update roundingBases
  useEffect(() => {
    if (rowsHistory[currentIndex]) {
      setBackgroundInfo({
        ...backgroundInfo,
        roundingBasisSheet:
          rowsHistory[currentIndex].roundingBasisSheet,
        roundingBasisPdf: rowsHistory[currentIndex].roundingBasisPdf,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsHistory, currentIndex]);

  const undo = () => {
    const newIndex = currentIndex - 1;
    const undoRows = rowsHistory[newIndex]?.rows;
    const undoItemIndustryPercents =
      rowsHistory[newIndex]?.itemIndustryPercents;

    setCurrentIndex(newIndex);

    if (undoRows) {
      const event = new CustomEvent('rowsHistory', {
        detail: 'undo',
      });
      document.dispatchEvent(event);
      return updateRows(undoRows, undoItemIndustryPercents);
    }
  };

  const undoToIndex = (index: number) => {
    const undoRows = rowsHistory[index]?.rows;
    const undoItemIndustryPercents =
      rowsHistory[index]?.itemIndustryPercents;

    setCurrentIndex(index);

    if (undoRows) {
      return updateRows(undoRows, undoItemIndustryPercents);
    }
  };

  const redo = () => {
    const newIndex = currentIndex + 1;
    const redoRows = rowsHistory[newIndex]?.rows;
    const redoItemIndustryPercents =
      rowsHistory[newIndex]?.itemIndustryPercents;

    setCurrentIndex(newIndex);

    if (redoRows) {
      const event = new CustomEvent('rowsHistory', {
        detail: 'redo',
      });
      document.dispatchEvent(event);
      return updateRows(redoRows, redoItemIndustryPercents);
    }
  };

  const redoToIndex = (index: number) => {
    const redoRows = rowsHistory[index]?.rows;
    const redoItemIndustryPercents =
      rowsHistory[index]?.itemIndustryPercents;

    setCurrentIndex(index);

    if (redoRows) {
      return updateRows(redoRows, redoItemIndustryPercents);
    }
  };

  return [
    rowsHistory,
    currentRowsInHistory,
    currentItemIndustryPercentsInHistory,
    setRowsHistory,
    undo,
    undoToIndex,
    redo,
    redoToIndex,
    currentIndex,
    setCurrentIndex,
  ] as const;
};

export default useRowsHistory;
