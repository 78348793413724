import React, { useEffect, useState } from 'react';

import { useApi, LoadingState } from '../hooks/useApi';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import Paper from '@mui/material/Paper';

import GenericCheckBoxFilter from '../components/Filters/GenericCheckBoxFilter';
import GenericTextFieldFilter from '../components/Filters/GenericTextFieldFilter';

import useUserContext from '../providers/User/UserProvider';

import { getPricingSheets } from '../services/pricingSheets';

import {
  PricingSheet,
  PricingSheetState,
} from '../../../shared/types';

import PricingRow from './PricingSheets/PricingRow';
import { Divider, Tab, Tabs } from '@mui/material';
import { isEditingRights } from '../../../shared/helpers/isEditingRights';
import { TABLE_SIZE_OPTIONS } from '../../../shared/constants';
import { CustomerLoadError } from '../components/Common/CustomerLoadError';

enum TabState {
  InUse = 0,
  InComplete = 1,
  Archived = 2,
}

const usePricingSheets = (url: string) => {
  const [pricingSheetsResponse, isLoading, isError] =
    useApi<PricingSheet[]>(url);
  const [pricingSheets, setPricingSheets] = React.useState<
    PricingSheet[]
  >([]);

  useEffect(() => {
    if (pricingSheetsResponse) {
      setPricingSheets(pricingSheetsResponse);
    }
  }, [pricingSheetsResponse]);
  return [
    pricingSheets,
    setPricingSheets,
    isLoading,
    isError,
  ] as const;
};

const Pricings = () => {
  const { userEmail, isAdmin } = useUserContext();

  const [error, setError] = useState<string | undefined>(undefined);
  const [activeTab, setActiveTab] = useState(TabState.InComplete);

  const isArchived = activeTab === TabState.Archived ? true : false;

  const [
    pricingSheets,
    setPricingSheets,
    isLoadingPricingSheets,
    pricingSheetsError,
  ] = usePricingSheets(`/pricingsheets?isArchived=${isArchived}`);

  // ***
  // Mira customer's blocking info fetch is temporary disabled due to bug

  // const customerIds = pricingSheets
  //   .map((pricingSheet) => pricingSheet.customerId)
  //   .join(',');
  // const {
  //   data: miraCustomers = [],
  //   isError: isErrorCustomers,
  //   isFetching: isFetchingMiraCustomer,
  // } = useQuery({
  //   queryKey: [MIRA_CUSTOMER_BY_ID_QUERY_KEY, customerIds],
  //   enabled: pricingSheets.length > 0,
  //   queryFn: async () => getMiraCustomerByIds(customerIds),
  // });

  const isErrorCustomers = false;
  const isFetchingMiraCustomer = false;
  const miraCustomers: any = [];

  // ****
  useEffect(() => {
    if (pricingSheetsError?.status === 403) {
      setError('Sinulla ei ole oikeuksia tarkastella hinnastoja!');
    } else if (pricingSheetsError?.status === 404) {
      setError('Hinnastoja ei löytynyt!');
    }
  }, [pricingSheetsError]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [nameFilterValue, setNameFilterValue] = useState<string>('');
  const [creatorFilterValue, setCreatorFilterValue] =
    useState<string>('');

  const [showOnlyOwns, setShowOnlyOwns] = useState<boolean>(false);
  const [showOnlyNeedExporting, setShowOnlyNeedExporting] =
    useState<boolean>(false);
  const [showOnlyShared, setShowOnlyShared] =
    useState<boolean>(false);

  const resetFilters = () => {
    setNameFilterValue('');
    setCreatorFilterValue('');
    setShowOnlyOwns(false);
    setShowOnlyNeedExporting(false);
  };

  const updateSheets = async () => {
    const newSheets = await getPricingSheets(isArchived);
    if (newSheets?.status === 200) {
      setPricingSheets(newSheets.data);
    }
  };

  const sharedToUserCount = pricingSheets.filter((pricingSheet) =>
    isEditingRights(pricingSheet.editingRights, userEmail),
  ).length;

  const filteredSheets = pricingSheets
    .filter((row) =>
      row.name.toLowerCase().includes(nameFilterValue.toLowerCase()),
    )
    .filter((row) =>
      row.userEmail
        .toLowerCase()
        .includes(creatorFilterValue.toLowerCase()),
    )
    .filter((row) =>
      showOnlyOwns ? row.userEmail === userEmail : true,
    )
    .filter((row) =>
      showOnlyShared
        ? isEditingRights(row.editingRights, userEmail)
        : true,
    )
    .filter((row) =>
      showOnlyNeedExporting
        ? row.state === PricingSheetState.Ready
        : true,
    )
    .filter((row) =>
      activeTab === TabState.InUse
        ? row.state === PricingSheetState.InUse
        : true,
    )
    .filter((row) =>
      activeTab === TabState.InComplete
        ? row.state !== PricingSheetState.InUse
        : true,
    )
    .sort((a, b) => (a.id > b.id ? 1 : -1))
    .reverse();

  const filteredAndSlicedSheets = filteredSheets?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  useEffect(() => {
    setPage(0);
  }, [
    nameFilterValue,
    creatorFilterValue,
    showOnlyOwns,
    showOnlyNeedExporting,
    activeTab,
  ]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        backgroundColor: 'white',
        borderRadius: 2,
        paddingTop: 4,
        paddingBottom: 2,
        marginTop: 4,
      }}
    >
      <Box marginBottom={3}>
        <Typography
          variant="h4"
          component="div"
          gutterBottom
          color="primary"
        >
          Hinnastot
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {error ? (
          <>{error}</>
        ) : (
          <>
            <Tabs
              value={activeTab}
              onChange={(_, newValue) => {
                resetFilters();
                setActiveTab(newValue);
              }}
            >
              <Tab label="Käytössä" />
              <Tab label="Keskeneräiset" />
              <Tab label="Arkistoidut" />
            </Tabs>
            <Divider />
            <>
              {isLoadingPricingSheets === LoadingState.IsLoading ||
              isFetchingMiraCustomer ? (
                <Box
                  sx={{
                    marginTop: 8,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <GenericCheckBoxFilter
                      labelStyle={{ marginLeft: 0 }}
                      label="Näytä vain omat"
                      checked={showOnlyOwns}
                      handleChecked={setShowOnlyOwns}
                    />
                    {isAdmin && activeTab === TabState.InComplete && (
                      <>
                        <GenericCheckBoxFilter
                          label="Näytä vain vientiä odottavat"
                          checked={showOnlyNeedExporting}
                          handleChecked={setShowOnlyNeedExporting}
                        />
                      </>
                    )}
                    <GenericCheckBoxFilter
                      labelStyle={{ marginLeft: 0 }}
                      label={`Minulle jaetut hinnastot (${sharedToUserCount})`}
                      checked={showOnlyShared}
                      handleChecked={setShowOnlyShared}
                    />
                  </Box>

                  <>
                    <Box sx={{ display: 'flex' }}>
                      <Typography marginBottom={1}>
                        {filteredSheets?.length
                          ? `Hinnastoja yhteensä ${filteredSheets?.length} kappaletta`
                          : null}
                      </Typography>
                      {isErrorCustomers && <CustomerLoadError />}
                    </Box>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead
                          sx={{
                            background:
                              activeTab === TabState.Archived
                                ? 'rgb(253, 237, 237)'
                                : activeTab === TabState.InUse
                                  ? 'rgb(237, 247, 237)'
                                  : '#e5e5e5',
                          }}
                        >
                          <TableRow
                            sx={{
                              '& > *': { borderBottom: 'unset' },
                            }}
                          >
                            <TableCell>
                              <GenericTextFieldFilter
                                label="Suodata nimellä"
                                filterValue={nameFilterValue}
                                handleFilter={setNameFilterValue}
                              />
                            </TableCell>
                            <TableCell>
                              <GenericTextFieldFilter
                                label="Suodata tekijällä"
                                filterValue={creatorFilterValue}
                                handleFilter={setCreatorFilterValue}
                              />
                            </TableCell>
                            <TableCell>Tila</TableCell>
                            <TableCell>Voimassa asti</TableCell>
                            <TableCell>Jaettu</TableCell>
                            <TableCell align="right"></TableCell>

                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>

                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        {filteredSheets?.length === 0 ? (
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ml: 70,
                                    mb: 2,
                                    mt: 2,
                                  }}
                                >
                                  Hakuehdoilla ei löytynyt yhtään
                                  hinnastoa
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <TableBody>
                            {filteredAndSlicedSheets.map(
                              (pricingSheet: PricingSheet) => (
                                <PricingRow
                                  miraCustomers={miraCustomers}
                                  pricingSheet={pricingSheet}
                                  key={pricingSheet?.id}
                                  updateSheets={updateSheets}
                                />
                              ),
                            )}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                    <TablePagination
                      labelRowsPerPage="Hinnastoja per sivu"
                      rowsPerPageOptions={TABLE_SIZE_OPTIONS}
                      component="div"
                      count={
                        filteredSheets ? filteredSheets.length : 0
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                </>
              )}
            </>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Pricings;
