import React, { useContext } from 'react';

import { Autocomplete, TextField } from '@mui/material';

import BackgroundProvider from '../../../../providers/Background/BackgroundProvider';

const regions = [
  'Etelä-Suomi',
  'Väli-Suomi',
  'Pohjois- ja Itä-Suomi',
];

const RegionSelector: React.FC = () => {
  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );

  return (
    <Autocomplete
      size="small"
      value={backgroundInfo?.region ?? null}
      onChange={(
        _: React.SyntheticEvent<Element, Event>,
        newValue: string | null,
      ) => {
        setBackgroundInfo({
          ...backgroundInfo,
          region: newValue ?? '',
        });
      }}
      id="controllable-region"
      options={regions}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Valitse alue..." />
      )}
    />
  );
};

export default RegionSelector;
