import { MiraCustomerBlocking } from '../../../shared/types/customer';

export type ClientBlockedTexts = Record<
  'All' | 'Invoice' | 'Confirmation' | 'Picking' | 'Packing',
  string
>;

export const clientBlockedTexts: ClientBlockedTexts = {
  All: 'Lukittu kaikelta',
  Invoice: 'Lukittu laskutukselta',
  Confirmation: 'Lukittu vahvistamiselta',
  Picking: 'Lukittu pakkausluettelolta',
  Packing: 'Lukittu keräysluettelolta',
};

export const getBlockedText = (block: MiraCustomerBlocking) => {
  const {
    blockedAll,
    blockedConfirmation,
    blockedInvoice,
    blockedPacking,
    blockedPicking,
  } = block;
  const blocked = [
    blockedAll && clientBlockedTexts.All,
    !blockedAll && blockedInvoice && clientBlockedTexts.Invoice,
    !blockedAll &&
      blockedConfirmation &&
      clientBlockedTexts.Confirmation,
    !blockedAll && blockedPicking && clientBlockedTexts.Picking,
    !blockedAll && blockedPacking && clientBlockedTexts.Packing,
  ]
    .filter((blockedField) => Boolean(blockedField))
    .join(', ');

  return blocked ? `${'Esto'}: ${blocked}` : '';
};
