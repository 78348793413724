import Box from '@mui/material/Box';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { EnvContext } from '../../App';
import CircularProgress from '@mui/material/CircularProgress';
import { ItemType } from '../../../../shared/types';
import { isSalesItem } from '../../../../shared/helpers/isSalesItem';

type ProductCardProps = {
  catClass: string;
  type?: ItemType;
};
const ProductCard = ({ catClass, type }: ProductCardProps) => {
  const envs = useContext(EnvContext);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const iframeEl = iframeRef.current;

    const handleLoad = () => setLoading(false);
    if (iframeEl) {
      iframeEl.addEventListener('load', handleLoad);
    }
    return () => {
      if (iframeEl) {
        iframeEl.removeEventListener('load', handleLoad);
        iframeEl.src = '';
      }
    };
  }, [iframeRef, setLoading]);

  const param = isSalesItem(type) ? 'salesItemNumber' : 'catClassId';

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: '#fff',
        color: '#3e4041',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {loading && (
        <CircularProgress
          color={'inherit'}
          size={42}
          thickness={4}
          sx={{
            position: 'absolute',
          }}
        />
      )}
      <iframe
        src={`${envs?.VITE_RAMISMART_PRODUCT_URL}/?${param}=${catClass}&backgroundColor=${encodeURIComponent(
          '#fff',
        )}`}
        width={'100%'}
        height={500}
        style={{ border: 'none' }}
        title="Konekortti"
        ref={iframeRef}
      />
    </Box>
  );
};

export default ProductCard;
