import Chip from '@mui/material/Chip';
import DefaultTooltip from '../../Tooltips/DefaultTooltip';

import {
  AgGridColumnView,
  PricingBasis,
} from '../../../../../shared/types';
import { roundToTwoDecimal } from '../../../../../shared/roundToTwoDecimal';

const toolTipText =
  'Hintakertoimien keskiarvo: syötetyt hinnat (ehdotetut hinnat)';

type SingleChipProps = {
  meanFilledCoefficient: number;
  meanProposalCoefficient: number;
};

const SingleChip = ({
  meanFilledCoefficient,
  meanProposalCoefficient,
}: SingleChipProps) => {
  return (
    <Chip
      size="small"
      sx={{ ml: 1, backgroundColor: 'white' }}
      label={`${roundToTwoDecimal(
        meanFilledCoefficient,
      )} (${roundToTwoDecimal(meanProposalCoefficient)})
`}
      variant="outlined"
    />
  );
};

type CoefficientChipsProps = {
  means: {
    meanFilledDay: number | null;
    meanProposalDay: number | null;
    meanFilledMonth: number | null;
    meanProposalMonth: number | null;
  };
  pricingBasis: PricingBasis;
  gridColumnView: AgGridColumnView;
};

const CoefficientChips = ({
  means,
  pricingBasis,
  gridColumnView,
}: CoefficientChipsProps) => {
  return (
    <>
      <DefaultTooltip placement="top" title={toolTipText}>
        <span>
          {means.meanFilledDay &&
          means.meanProposalDay &&
          (pricingBasis === 'DAY' || pricingBasis === 'BOTH') &&
          (gridColumnView === AgGridColumnView.PRICING_DAY ||
            gridColumnView === AgGridColumnView.PRICING_BOTH ||
            gridColumnView === AgGridColumnView.INSPECT_DAY ||
            gridColumnView === AgGridColumnView.INSPECT_BOTH ||
            gridColumnView === AgGridColumnView.OFFER_PRICING) ? (
            <SingleChip
              meanFilledCoefficient={means.meanFilledDay}
              meanProposalCoefficient={means.meanProposalDay}
            />
          ) : null}
          {means.meanFilledMonth &&
          means.meanProposalMonth &&
          (pricingBasis === 'BOTH' || pricingBasis === 'MONTH') &&
          (gridColumnView === AgGridColumnView.PRICING_MONTH ||
            gridColumnView === AgGridColumnView.PRICING_BOTH ||
            gridColumnView === AgGridColumnView.INSPECT_BOTH ||
            gridColumnView === AgGridColumnView.OFFER_PRICING) ? (
            <SingleChip
              meanFilledCoefficient={means.meanFilledMonth}
              meanProposalCoefficient={means.meanProposalMonth}
            />
          ) : null}
        </span>
      </DefaultTooltip>
    </>
  );
};

export default CoefficientChips;
