import { ValueGetterParams } from 'ag-grid-community';

const monthPricePercentGetter = (
  params: ValueGetterParams,
  comparisonMonthPrice: number | null | undefined,
) => {
  const monthPrice = params.data?.monthPrice;

  const comparisonMonthPricePercent =
    comparisonMonthPrice && monthPrice
      ? (
          ((comparisonMonthPrice - monthPrice) / monthPrice) *
          100
        ).toFixed(2)
      : null;

  return comparisonMonthPricePercent;
};

export default monthPricePercentGetter;
