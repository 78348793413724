import { ReactNode, useContext, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import OfferStepActionContainer from './OfferStepActionContainer';
import {
  DatabaseOfferWithItems,
  OfferState,
} from '../../../../shared/types/offers';
import SummaryList from '../../components/Steps/Components/SummaryList';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EuroIcon from '@mui/icons-material/Euro';
import HouseIcon from '@mui/icons-material/House';
import { formatDateTolocaleDateString } from '../../utils/formatDateTimes';
import EventIcon from '@mui/icons-material/Event';
import { convertDatabaseOfferDatesToDate } from '../../helpers/convertDatabaseOfferDates';
import { getCustomerTypeLabel } from '../../helpers/getCustomerTypeLabel';
import {
  OFFER_SHEET_QUERY_KEY,
  PRICE_CATEGORIES,
} from '../../../../shared/constants';
import LocalOffer from '@mui/icons-material/LocalOffer';
import { formatPricingBasis } from '../../helpers/formatPricingBasis';
import { SheetType } from '../../../../shared/types';
import { getOfferStateLabel, getWarningCounts } from './helpers';
import { useItems } from '../../hooks/useItems';
import { HelpOutline } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import { isValidEmail } from '../../utils/isValidEmail';
import Button from '@mui/material/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createOfferApprovalRequest } from '../../services/offerSheets';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import { useHistory } from 'react-router-dom';
import { OfferStepTitle } from './OfferStepTitle';
import { OfferSummaryWarnings } from './OfferSummaryWarnings';
import { useUpdateOffer } from '../../hooks/useUpdateOffer';
import { calculateItemPrice } from '../../../../shared/helpers/calculateItemPrice';

type OfferSummaryProps = {
  offerSheet: DatabaseOfferWithItems;
  renderStepActions: () => ReactNode;
};

export const OfferSummary = ({
  offerSheet,
  renderStepActions = () => null,
}: OfferSummaryProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { setNotification } = useContext(NotificationProvider);
  const {
    id,
    customerName,
    customerType,
    industry,
    offerLeasePeriodEnd,
    offerLeasePeriodStart,
    offerValidityEnd,
    offerValidityStart,
    priceCategory,
    pricingBasis,
    state,
    totalCoefficient,
    totalPrice,
    totalRentalPrice,
    totalSalesPrice,
  } = convertDatabaseOfferDatesToDate(offerSheet);

  const { data: offerSheetItems = [], isFetching: isLoadingItems } =
    useItems({
      includeHidden: true,
      offerSheetItemsOnly: true,
      offerSheet,
    });
  // update offer sheet mutation
  const { mutateAsync: updateOffer, isPending: isUpdatingOffer } =
    useUpdateOffer();
  // create offer approval request mutation
  const {
    isPending: isCreatingApprovalRequest,
    mutate: createApprovalRequest,
  } = useMutation({
    mutationFn: createOfferApprovalRequest,
    onSuccess: () => {
      // refetch offer data
      queryClient.refetchQueries({
        queryKey: [OFFER_SHEET_QUERY_KEY, `${id}`],
        exact: true,
      });
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'success',
        message: 'Tarjouksen hyväksymispyyntö lähetetty!',
      });
      history.push('/tarjoukset');
    },
    onError: () => {
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'error',
        message: 'Hyväksymispyynnön lähettäminen epäonnistui!',
      });
      history.push('/tarjoukset');
    },
  });

  const totalOfferPrice = calculateItemPrice(totalPrice);
  const totalRentalItemPrice = calculateItemPrice(totalRentalPrice);
  const totalSalesItemPrice = calculateItemPrice(totalSalesPrice);
  const averagePriceFactor = calculateItemPrice(totalCoefficient);

  const approveOfferSheet = async () => {
    try {
      await updateOffer(
        {
          id: String(id),
          item: { state: OfferState.ReadyForSending },
        },
        {
          onSuccess: () => {
            setTimeout(() => {
              history.push('/tarjoukset');
              setNotification({
                type: 'SNACKBAR',
                duration: 3000,
                severity: 'success',
                message: 'Hinnasto hyväksytty!',
              });
            });
          },
        },
      );
    } catch (error) {
      console.error(error);
      setNotification({
        severity: 'error',
        message: 'Hinnaston hyväksyminen epäonnistui!',
      });
    }
  };

  const warningCounts = useMemo(
    () => getWarningCounts(offerSheetItems),
    [offerSheetItems],
  );

  const priceCategoryLabel = PRICE_CATEGORIES[priceCategory];
  const approvalRequired =
    warningCounts.belowOneCoefficient > 0 ||
    warningCounts.below70 > 0 ||
    warningCounts.belowCritical > 0;

  const summaryItems = [
    {
      value: getOfferStateLabel(state),
      label: 'Tarjouksen tila',
      icon: <HelpOutline />,
    },
    {
      value: formatPricingBasis(pricingBasis, SheetType.OFFER),
      label: 'Tarjouksen tyyppi',
      icon: <LocalOffer />,
    },
    {
      value: customerName,
      label: 'Asiakas',
      icon: <AccountBoxIcon />,
    },
    {
      value: getCustomerTypeLabel(customerType),
      label: 'Asiakkaan tyyppi',
      icon: <AccountBoxIcon />,
    },
    {
      value: industry,
      label: 'Asiakkaan toimiala',
      icon: <HouseIcon />,
    },
    {
      value: priceCategoryLabel.substring(
        priceCategoryLabel.indexOf(String(priceCategory + 1)),
      ),
      label: 'Hintakategoria',
      icon: <EuroIcon />,
    },
    {
      value: `${formatDateTolocaleDateString(
        offerValidityStart,
      )} - ${formatDateTolocaleDateString(offerValidityEnd)}`,
      label: 'Tarjouksen voimassaoloaika',
      icon: <EventIcon />,
    },
    {
      value: `${formatDateTolocaleDateString(
        offerLeasePeriodStart,
      )} - ${formatDateTolocaleDateString(offerLeasePeriodEnd)}`,
      label: 'Tarjouksen vuokra-aika',
      icon: <EventIcon />,
    },
  ];

  return (
    <>
      <OfferStepActionContainer>
        {renderStepActions()}
      </OfferStepActionContainer>
      <OfferStepTitle title={'Yhteenveto'} offerSheet={offerSheet} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 4,
        }}
      >
        <Paper
          elevation={2}
          sx={{
            display: 'grid',
            height: '100%',
          }}
        >
          <Box>
            <SummaryList grid items={summaryItems} />
            <Divider variant="middle" sx={{ mt: 2 }} />
          </Box>
          <Box sx={{ placeItems: 'center', margin: '30px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <Typography color="textSecondary">
                  Vuokratuotteiden hintakerroin
                </Typography>
                <Typography variant="h4">
                  {averagePriceFactor ? (
                    averagePriceFactor
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </Typography>
                <Typography color="textSecondary" sx={{ mt: 2 }}>
                  Tarjouksen kokonaisarvo
                </Typography>
                <Typography variant="h4">
                  {totalOfferPrice ? (
                    `${totalOfferPrice} €`
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mx: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <Typography color="textSecondary">
                  Vuokratuotteet
                </Typography>
                <Typography variant="h6">
                  {totalRentalItemPrice !== null &&
                  totalRentalItemPrice !== undefined ? (
                    `${totalRentalItemPrice} €`
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mx: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <Typography color="textSecondary">
                  Myyntituotteet
                </Typography>
                <Typography variant="h6">
                  {totalSalesItemPrice !== null &&
                  totalSalesItemPrice !== undefined ? (
                    `${totalSalesItemPrice} €`
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
        <Paper
          elevation={2}
          sx={{
            p: 2,
          }}
        >
          <OfferSummaryWarnings
            loading={isLoadingItems}
            warningCounts={warningCounts}
          />
          {/* APPROVER FORM */}
          {approvalRequired && (
            <RequireApproval
              loading={isCreatingApprovalRequest}
              onSubmit={(approverEmail, approvalCreatorComment) =>
                createApprovalRequest({
                  id: String(id),
                  approverEmail,
                  approvalCreatorComment,
                  warningCounts: {
                    belowOneCoefficient:
                      warningCounts.belowOneCoefficient,
                    below1Point2Coefficient:
                      warningCounts.below1Point2Coefficient,
                    below70: warningCounts.below70,
                    belowCritical: warningCounts.belowCritical,
                    zeroPrice: warningCounts.zeroPrice,
                  },
                })
              }
            />
          )}
          {/* APPROVE */}
          {!approvalRequired &&
            (state === OfferState.Draft ||
              state === OfferState.InternalRejection) && (
              <Button
                sx={{
                  display: 'flex',
                  mt: 2,
                  ml: 'auto',
                }}
                variant={'contained'}
                disabled={isUpdatingOffer}
                endIcon={
                  isUpdatingOffer && (
                    <CircularProgress size={16} thickness={4} />
                  )
                }
                onClick={approveOfferSheet}
              >
                Hyväksy
              </Button>
            )}
        </Paper>
      </Box>
    </>
  );
};

type RequireApprovalProps = {
  loading: boolean;
  onSubmit: (email: string, comment: string) => void;
};

const RequireApproval = ({
  loading,
  onSubmit = () => null,
}: RequireApprovalProps) => {
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');

  const validEmail = isValidEmail(email);

  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Typography
        variant={'subtitle1'}
        fontWeight={'bold'}
        color={'primary'}
        sx={{ mb: 1 }}
      >
        Pyydä hyväksyntää
      </Typography>
      {/* EMAIL */}
      <TextField
        required
        sx={{ width: '100%', mb: 1 }}
        label={'Sähköpostiosoite'}
        variant={'outlined'}
        type={'email'}
        value={email}
        size={'small'}
        error={email?.length > 0 && !validEmail}
        helperText={
          email?.length > 0 && !validEmail
            ? 'Varmista että sähköpostiosoite on oikein'
            : null
        }
        onChange={(e) => setEmail(e.target.value)}
      />
      {/* COMMENT */}
      <TextField
        required
        fullWidth
        size={'small'}
        multiline
        rows={4}
        label={'Perustelut'}
        placeholder={'Lisää perustelut'}
        onChange={(e) => setComment(e.target.value)}
      />
      {/* SUBMIT */}
      <Button
        disabled={loading || !validEmail || !comment}
        variant={'contained'}
        onClick={() => onSubmit(email.toLowerCase(), comment)}
        sx={{ alignSelf: 'end', mt: 1 }}
        endIcon={
          loading && <CircularProgress size={16} thickness={4} />
        }
      >
        Lähetä hyväksymispyyntö
      </Button>
    </Box>
  );
};
