import { PricingSheetState } from '../../../shared/types';

const getSheetState = (state: PricingSheetState) => {
  if (state === PricingSheetState.Draft) {
    return 'Luonnos';
  }
  if (state === PricingSheetState.OkDraft) {
    return 'Luonnos';
  }
  if (state === PricingSheetState.WarningDraft) {
    return 'Luonnos';
  }
  if (state === PricingSheetState.Pending) {
    return 'Odottaa hyväksyntää';
  }
  if (state === PricingSheetState.Approved) {
    return 'Hyväksytty';
  }
  if (state === PricingSheetState.Ready) {
    return 'Odottaa vientiä';
  }
  if (state === PricingSheetState.InUse) {
    return 'Käytössä';
  }

  return 'Tuntematon';
};

export default getSheetState;
