import { ValueGetterParams } from 'ag-grid-community';
import { PricingSheetRow } from '../../../../../shared/types';

const discountDayGetter = (
  params: ValueGetterParams<PricingSheetRow>,
) => {
  const dayPrice = params.data?.dayPrice;
  const proposalDayPrice = params.data?.proposalDayPrice;

  return dayPrice && proposalDayPrice
    ? Number(
        (
          ((dayPrice - proposalDayPrice) / proposalDayPrice) *
          100
        ).toFixed(2),
      )
    : null;
};

export default discountDayGetter;
