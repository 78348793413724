import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DeleteIcon from '@mui/icons-material/Delete';
import { PricingSheetRow } from '../../../../shared/types';

import DialogActions from '@mui/material/DialogActions';

import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { createTheme } from '@mui/material';
import DefaultTooltip from '../Tooltips/DefaultTooltip';

interface RemoveItemsDialogProps {
  selectedRows: PricingSheetRow[];
  onRemoveRows: () => void;
  dialogText: string;
}

const RemoveItemsDialog: React.FC<RemoveItemsDialogProps> = ({
  selectedRows,
  onRemoveRows,
  dialogText,
}) => {
  const theme = createTheme();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const removeRows = () => {
    onRemoveRows();
    handleClose();
  };

  return (
    <>
      <DefaultTooltip title="Poista rivejä hinnoittelusta">
        <span>
          <Button
            disabled={selectedRows.length === 0}
            sx={{ height: 26 }}
            size="small"
            variant="outlined"
            onClick={handleOpen}
          >
            <DeleteIcon style={{ fontSize: 16 }} />
            <Box
              sx={{
                [theme.breakpoints.down('xl')]: {
                  display: 'none',
                },
                [theme.breakpoints.up('xl')]: {
                  display: 'inline',
                },
              }}
            >
              Poista valitut
            </Box>
          </Button>
        </span>
      </DefaultTooltip>

      <Dialog
        disableScrollLock
        fullWidth={true}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop} // ???
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle>Poista valitut</DialogTitle>

        <Fade in={open}>
          <DialogContent>
            <Typography>
              Olet poistamassa <b>{selectedRows.length}</b> tuotetta
              hinnoittelusta. Haluatko jatkaa?
            </Typography>

            <Typography
              color="text.secondary"
              style={{ marginTop: 16 }}
            >
              {`Tuotteet saadaan palautettua hinnastoon "${dialogText}" -välilehden alta.`}
            </Typography>

            <DialogActions
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Button
                  color="inherit"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Peruuta
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="error"
                  onClick={removeRows}
                >
                  Poista
                </Button>
              </Box>
            </DialogActions>
          </DialogContent>
        </Fade>
      </Dialog>
    </>
  );
};

export default RemoveItemsDialog;
