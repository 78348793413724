import {
  CoefficientType,
  IndustryName,
  ItemIndustryPercent,
  NewItemIndustryPercent,
  PercentCoefficientType,
  PricingSheetRow,
} from '../../../../../../shared/types';

import { percentualNumberChange } from '../../../../../../shared/percentualNumberChange';
import {
  ChangeType,
  EditItemsDialogChange,
  EditItemsDialogChangeWithKey,
} from '../types';

type UpdateRowParams = {
  changeTypeCommon: ChangeType;
  changeTypeCoefficients: ChangeType;
  dayPriceCoefficients: EditItemsDialogChangeWithKey<CoefficientType>[];
  monthPriceCoefficients: EditItemsDialogChangeWithKey<CoefficientType>[];
  rentalReadyPrice: EditItemsDialogChange;
  targetUtilRate: EditItemsDialogChange;
  depreciationPeriod: EditItemsDialogChange;
  pricingSheetRow: PricingSheetRow;
};

type UpdateIndustryPercentsRowParams = {
  changeType: ChangeType;
  dayPriceIndustryPercents: EditItemsDialogChangeWithKey<PercentCoefficientType>[];
  monthPriceIndustryPercents: EditItemsDialogChangeWithKey<PercentCoefficientType>[];
  itemIndustryPercentRow: ItemIndustryPercent;
};

type CreateIndustryPercentsRowParams = Omit<
  UpdateIndustryPercentsRowParams,
  'changeType' | 'itemIndustryPercentRow'
> & {
  industryId: number;
  industryName: IndustryName;
  row: PricingSheetRow | ItemIndustryPercent;
};

type UpdatedPriceCoefficients<T extends PricingSheetRow> = Record<
  CoefficientKeys<T>,
  number
>;

type CoefficientKeys<T> = T extends ItemIndustryPercent
  ? PercentCoefficientType
  : T extends PricingSheetRow
    ? CoefficientType
    : never;

type GetUpdatedPriceCoefficientsParams<T> = {
  changeType: ChangeType;
  dayPriceCoefficients: EditItemsDialogChangeWithKey<
    CoefficientKeys<T>
  >[];
  monthPriceCoefficients: EditItemsDialogChangeWithKey<
    CoefficientKeys<T>
  >[];
  row: T;
  useRowValueAsDefault?: boolean;
};

const getUpdatedPriceCoefficients = <T extends PricingSheetRow>({
  changeType,
  dayPriceCoefficients,
  monthPriceCoefficients,
  row,
  useRowValueAsDefault = true,
}: GetUpdatedPriceCoefficientsParams<T>): UpdatedPriceCoefficients<T> => {
  return [...dayPriceCoefficients, ...monthPriceCoefficients].reduce(
    (acc, item) => {
      const key = item.key;
      let value = useRowValueAsDefault ? row[key] : null;
      if (changeType === ChangeType.ABSOLUTE && item.absoluteValue) {
        value = item.absoluteValue * 100;
      }
      if (changeType === ChangeType.PERCENT && item.percentValue) {
        value = percentualNumberChange(
          Number(row[key]),
          item.percentValue,
        )!;
      }
      return {
        ...acc,
        [key]: value || null,
      };
    },
    {} as UpdatedPriceCoefficients<T>,
  );
};

export const updateRow = ({
  changeTypeCommon,
  changeTypeCoefficients,
  dayPriceCoefficients,
  monthPriceCoefficients,
  rentalReadyPrice,
  targetUtilRate,
  depreciationPeriod,
  pricingSheetRow,
}: UpdateRowParams): PricingSheetRow => {
  const updatedPriceCoefficients = getUpdatedPriceCoefficients({
    changeType: changeTypeCoefficients,
    dayPriceCoefficients,
    monthPriceCoefficients,
    row: pricingSheetRow,
  });
  const commonCoefficients = {
    rentalReadyPrice,
    targetUtilRate,
    depreciationPeriod,
  };
  const updatedCommonCoefficients = Object.entries(
    commonCoefficients,
  ).reduce(
    (acc, [_key, value]) => {
      const key = _key as keyof typeof commonCoefficients;
      const updatedValue =
        changeTypeCommon === ChangeType.ABSOLUTE
          ? value.absoluteValue
          : percentualNumberChange(
              Number(pricingSheetRow[key]),
              value.percentValue,
            );
      return {
        ...acc,
        [key]: updatedValue ?? pricingSheetRow[key],
      };
    },
    {} as Partial<{
      rentalReadyPrice: string;
      targetUtilRate: number;
      depreciationPeriod: number;
    }>,
  );

  return {
    ...pricingSheetRow,
    ...updatedCommonCoefficients,
    ...updatedPriceCoefficients,
  };
};

export const updateIndustryPercentsRow = ({
  changeType,
  dayPriceIndustryPercents,
  monthPriceIndustryPercents,
  itemIndustryPercentRow,
}: UpdateIndustryPercentsRowParams): ItemIndustryPercent => {
  const updatedPriceCoefficients =
    getUpdatedPriceCoefficients<ItemIndustryPercent>({
      changeType,
      dayPriceCoefficients: dayPriceIndustryPercents,
      monthPriceCoefficients: monthPriceIndustryPercents,
      row: itemIndustryPercentRow,
    });

  return {
    ...itemIndustryPercentRow,
    ...updatedPriceCoefficients,
  };
};

export const createIndustryPercentsRow = ({
  dayPriceIndustryPercents,
  monthPriceIndustryPercents,
  industryName,
  industryId,
  row,
}: CreateIndustryPercentsRowParams): NewItemIndustryPercent => {
  const updatedPriceCoefficients = [
    ...dayPriceIndustryPercents,
    ...monthPriceIndustryPercents,
  ].reduce(
    (acc, { absoluteValue, key }) => ({
      ...acc,
      [key]: absoluteValue ? absoluteValue * 100 : null,
    }),
    {} as UpdatedPriceCoefficients<ItemIndustryPercent>,
  );
  // remove id
  const { id, ...item } = row;
  return {
    ...item,
    ...updatedPriceCoefficients,
    industryId,
    industry: { id: industryId, name: industryName },
    itemId: 'itemId' in row ? row.itemId : row.id,
  };
};
