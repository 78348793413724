import { PRICE_FACTOR } from '../../../../../shared/constants';
import { Nullable } from '../../../../../shared/types';

interface PriceRendererProps {
  value: string;
  minPrice: Nullable<number>;
}
const PriceRenderer: React.FC<PriceRendererProps> = ({
  value,
  minPrice,
}) => {
  return (
    <>
      {value ? value + ' €' : 0 + ' €'}
      <span style={{ color: 'gray' }}>
        {minPrice ? ' (min. ' + minPrice / PRICE_FACTOR + '€)' : ''}
      </span>
    </>
  );
};

export default PriceRenderer;
