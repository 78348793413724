import getCoefficients from "./getCoefficients";

import { Coefficients, DatabaseItem, IndustryName } from "./types";
import { calculatePrices } from "./calculatePrices";
import { getDayCoefficient } from "./getDayCoefficient";
import { getMonthCoefficient } from "./getMonthCoefficient";
import { getIndustryPercents } from "./getIndustyPercents";

export const countProposalPrice = (
  proposalType: "day" | "month",
  pricingSheetRow: DatabaseItem,
  coefficientFactors: { potentialTop: number },
  industry: IndustryName,
) => {
  const coefficients = getCoefficients(
    coefficientFactors.potentialTop,
  ) as Coefficients;

  const dayCoefficient = Number(
    getDayCoefficient(pricingSheetRow, coefficients),
  );
  const monthCoefficient = Number(
    getMonthCoefficient(pricingSheetRow, coefficients),
  );

  const { changeCoefficientDay, changeCoefficientMonth } = getIndustryPercents(
    pricingSheetRow,
    industry,
    coefficients,
  );
  const finalDayCoefficient = dayCoefficient
    ? dayCoefficient * changeCoefficientDay
    : dayCoefficient;
  const finalMonthCoefficient = monthCoefficient
    ? monthCoefficient * changeCoefficientMonth
    : monthCoefficient;

  const rentalReadyPrice = Number(pricingSheetRow.rentalReadyPrice);
  const depreciationPeriod = Number(pricingSheetRow.depreciationPeriod);
  const targetUtilRate = Number(pricingSheetRow.targetUtilRate) / 100; // percent

  const prices = calculatePrices(
    rentalReadyPrice,
    depreciationPeriod,
    targetUtilRate,
    finalDayCoefficient,
    finalMonthCoefficient,
  );

  return proposalType === "day"
    ? prices.proposalDayPrice
    : prices.proposalMonthPrice;
};
