import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { useContext, useState } from 'react';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import { updatePricingSheetById } from '../../../services/pricingSheets';
import {
  PricingSheet,
  PricingSheetState,
} from '../../../../../shared/types';
import { getUniques } from '../../../utils/getUniques';
import CustomerSelector, {
  ClientIdType,
  createOption,
  getClientIdValues,
} from './CustomerSelector';

const MiraStatusDialog = ({
  pricingSheet,
  updateSheets,
  isOpenMiraStatusDialog,
  handleCloseMiraStatusDialog,
}: {
  pricingSheet: PricingSheet;
  updateSheets: () => Promise<void>;
  handleCloseMiraStatusDialog: () => void;
  isOpenMiraStatusDialog: boolean;
}) => {
  const { setNotification } = useContext(NotificationProvider);

  const pricingSheetMiraInfo = pricingSheet?.miraInfo;

  const initMiraIdState = pricingSheetMiraInfo?.miraId
    ? pricingSheetMiraInfo.miraId
    : undefined;

  const initMiraStatusState =
    pricingSheet.state === PricingSheetState.InUse ? true : false;

  const [miraId, setMiraId] = useState<string | undefined>(
    initMiraIdState,
  );

  const [miraStatus, setMiraStatus] = useState(initMiraStatusState);

  const defaultClientIds = pricingSheetMiraInfo?.customerIds
    ? pricingSheetMiraInfo.customerIds?.map((id) => createOption(id))
    : [];

  const [clientIds, setClientIds] = useState<
    readonly ClientIdType[] | []
  >(defaultClientIds);

  const resetState = () => {
    setMiraId(initMiraIdState);
    setMiraStatus(initMiraStatusState);
    setClientIds(defaultClientIds);
  };

  const handleMiraSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setMiraStatus(event.target.checked);
  };

  const handleMiraIdChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setMiraId(event.target.value);
  };

  const saveMiraInfo = async () => {
    try {
      await updatePricingSheetById(
        pricingSheet.id,
        {
          state: miraStatus
            ? PricingSheetState.InUse
            : PricingSheetState.Ready,
        },
        {
          oldSheetId: miraId,
          clientIds: getUniques(getClientIdValues(clientIds)),
        },
      );
      updateSheets();
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: 'Mira-tiedot tallennettu!',
      });
    } catch (error) {
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'error',
        message: 'Mira-tietoja tallentaessa tapahtui virhe!',
      });
    }
  };

  const onCloseDialog = () => {
    handleCloseMiraStatusDialog();
    resetState();
  };

  return (
    <Dialog
      disableScrollLock
      open={isOpenMiraStatusDialog}
      onClose={onCloseDialog}
      fullWidth
    >
      <DialogTitle>{'Mira-status'}</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ mt: 2, mr: 2 }}
        >
          <FormControlLabel
            disabled={
              pricingSheet.state !== PricingSheetState.InUse &&
              pricingSheet.state !== PricingSheetState.Ready
            }
            control={
              <Switch
                checked={miraStatus}
                onChange={handleMiraSwitchChange}
              />
            }
            label="Viety Miraan"
          />

          <TextField
            sx={{ mt: 2, mb: 2 }}
            disabled={!miraStatus}
            size="small"
            id="mira-id"
            label="MiraID"
            variant="outlined"
            value={miraId}
            onChange={handleMiraIdChange}
          />
          <CustomerSelector
            disabled={!miraStatus}
            clientIds={clientIds}
            setClientIds={setClientIds}
          />
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          m: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button color="inherit" onClick={onCloseDialog}>
          Sulje
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            saveMiraInfo();
            handleCloseMiraStatusDialog();
          }}
        >
          Tallenna
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MiraStatusDialog;
