import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackgroundProvider from '../providers/Background/BackgroundProvider';
import PricingProvider from '../providers/Pricing/PricingProvider';
import { getPricingSheetById } from '../services/pricingSheets';
import HorizontalNonLinearStepper from './Stepper';
import StepperContainer from './StepperContainer';
import LoadingRenderer from '../components/Common/LoadingRenderer';
import { Box } from '@mui/material';
import { PricingSheet } from '../../../shared/types';
import { currencyMultiplier } from '../../../shared/constants';

const formatInitialBackgroundEditState = (
  pricingSheet: PricingSheet,
) => {
  return {
    name: pricingSheet.name,
    customerName: pricingSheet.customerName,
    customerId: pricingSheet.customerId,
    pdfCustomerName: pricingSheet.pdfCustomerName,
    priceSheetStartDate:
      pricingSheet.from && new Date(pricingSheet.from),
    priceSheetEndDate: pricingSheet.to && new Date(pricingSheet.to),
    priceSheetType: pricingSheet.level,
    state: pricingSheet.state,
    industry: pricingSheet.industry,
    revenue:
      pricingSheet.revenue &&
      pricingSheet.revenue / currencyMultiplier,
    potential: {
      bottom:
        pricingSheet.potentialBottom &&
        pricingSheet.potentialBottom / currencyMultiplier,
      top:
        pricingSheet.potentialTop &&
        pricingSheet.potentialTop / currencyMultiplier,
    },
    pricingBasis: pricingSheet?.pricingBasis ?? 'BOTH',
    region: pricingSheet.region,
    site: pricingSheet.site,
    nationWide: pricingSheet.nationWide,
    regionallySignificant: pricingSheet.regionallySignificant,
    strategicallySignificant: pricingSheet.strategicallySignificant,
    commitment: pricingSheet.commitment,
    paymentTermDays: pricingSheet.paymentTermDays,
    creatorComment: pricingSheet.creatorComment,
    approverComment: pricingSheet.approverComment,
    roundingBasisSheet: pricingSheet.roundingBasisSheet
      ? pricingSheet.roundingBasisSheet
      : 'TWODECIMALS',
    roundingBasisPdf: pricingSheet.roundingBasisPdf
      ? pricingSheet.roundingBasisPdf
      : 'TWODECIMALS',
    editingRights: pricingSheet.editingRights,
    userId: pricingSheet.userId,
  };
};

/**
 * Initialize state when editing an existing pricing sheet
 */
const EditPricingSheet = () => {
  const { pricingSheetIdUrl } = useParams<{
    pricingSheetIdUrl: string;
  }>();
  const { setBackgroundInfo, setCurrentBackgroundInfo } = useContext(
    BackgroundProvider,
  );
  const { setPricingSheetId } = useContext(PricingProvider);

  const [isLoadingBackgroundState, setIsLoadingBackgroundState] =
    useState(true);

  useEffect(() => {
    if (pricingSheetIdUrl) {
      (async () => {
        try {
          setIsLoadingBackgroundState(true);
          const pricingSheet =
            await getPricingSheetById(pricingSheetIdUrl);
          setBackgroundInfo(
            formatInitialBackgroundEditState(pricingSheet),
          );
          setCurrentBackgroundInfo(
            formatInitialBackgroundEditState(pricingSheet),
          );
          setPricingSheetId(pricingSheetIdUrl);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingBackgroundState(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoadingBackgroundState ? (
        <StepperContainer>
          <Box sx={{ mt: 20, mb: 70 }}>
            <LoadingRenderer label="Alustetaan hinnastoa" />
          </Box>
        </StepperContainer>
      ) : (
        <HorizontalNonLinearStepper />
      )}
    </>
  );
};

export default EditPricingSheet;
