// potential should be same as in db, i.e. multiplyed by currencyMultiplier
const getCoefficientsByPotential = (potential: number) => {
  switch (true) {
    case potential < 20000:
      return {
        dayCoefficient: "dayPriceCoefficient7",
        monthCoefficient: "monthPriceCoefficient7",
        dayPricePercent: "dayPricePercent7",
        monthPricePercent: "monthPricePercent7",
      };
    case potential <= 50000:
      return {
        dayCoefficient: "dayPriceCoefficient6",
        monthCoefficient: "monthPriceCoefficient6",
        dayPricePercent: "dayPricePercent6",
        monthPricePercent: "monthPricePercent6",
      };
    case potential <= 100000:
      return {
        dayCoefficient: "dayPriceCoefficient5",
        monthCoefficient: "monthPriceCoefficient5",
        dayPricePercent: "dayPricePercent5",
        monthPricePercent: "monthPricePercent5",
      };
    case potential <= 300000:
      return {
        dayCoefficient: "dayPriceCoefficient4",
        monthCoefficient: "monthPriceCoefficient4",
        dayPricePercent: "dayPricePercent4",
        monthPricePercent: "monthPricePercent4",
      };
    case potential <= 600000:
      return {
        dayCoefficient: "dayPriceCoefficient3",
        monthCoefficient: "monthPriceCoefficient3",
        dayPricePercent: "dayPricePercent3",
        monthPricePercent: "monthPricePercent3",
      };
    case potential <= 1000000:
      return {
        dayCoefficient: "dayPriceCoefficient2",
        monthCoefficient: "monthPriceCoefficient2",
        dayPricePercent: "dayPricePercent2",
        monthPricePercent: "monthPricePercent2",
      };
    case potential > 1000000:
      return {
        dayCoefficient: "dayPriceCoefficient1",
        monthCoefficient: "monthPriceCoefficient1",
        dayPricePercent: "dayPricePercent1",
        monthPricePercent: "monthPricePercent1",
      };
    default:
      alert(
        "Jokin meni pieleen ehdotushinnaston laskennassa! Ota yhteyttä järjestelmän ylläpitäjään.",
      );
  }
};

export default getCoefficientsByPotential;
