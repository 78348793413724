import {
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useApi, LoadingState } from '../../hooks/useApi';
import { formatDateTime } from '../../utils/formatDateTimes';

import getSheetState from '../../helpers/getSheetState';

import { AuditEventType } from '../../../../shared/types';

type AuditLogEntry = {
  id: number;
  eventType: AuditEventType;
  eventDateTime: Date;
  userEmail: string;
  props?: any;
};

const mapEventTypeToText = (eventType: AuditEventType) => {
  switch (eventType) {
    case 'dayPriceChange':
      return 'Päivähintamuutos';
    case 'monthPriceChange':
      return 'Kuukausihintamuutos';
    case 'rentalReadyChange':
      return 'Vuokrausvalmiushinnan muutos';
    case 'depreciationPeriodChange':
      return 'Poistoajan muutos';
    case 'priceSheetCreate':
      return 'Hinnasto luotu';
    case 'priceSheetParamsChange':
      return 'Hinnaston parametrien muutos';
    case 'priceSheetStateChange':
      return 'Hinnaston tilan muutos';
    case 'itemNameChange':
      return 'Tuotteen nimenmuutos';
    case 'targetUtilRateChange':
      return 'Tavoitekäyttöasteen muutos';
    case 'includeInPricingChange':
      return 'Näytä tuote hinnastossa';
    case 'markedAsRemovedChange':
      return 'Merkattu poistuneeksi';
    case 'isRamigreenChange':
      return 'Ramigreen-muutos';
    case 'dayPriceCoefficient1Change':
      return 'Hintakategoria 1 (pv) muutos';
    case 'dayPriceCoefficient2Change':
      return 'Hintakategoria 2 (pv) muutos';
    case 'dayPriceCoefficient3Change':
      return 'Hintakategoria 3 (pv) muutos';
    case 'dayPriceCoefficient4Change':
      return 'Hintakategoria 4 (pv) muutos';
    case 'dayPriceCoefficient5Change':
      return 'Hintakategoria 5 (pv) muutos';
    case 'dayPriceCoefficient6Change':
      return 'Hintakategoria 6 (pv) muutos';
    case 'dayPriceCoefficient7Change':
      return 'Hintakategoria 7 (pv) muutos';
    case 'dayPriceCoefficientListChange':
      return 'Listahinta (pv) muutos';
    case 'monthPriceCoefficient1Change':
      return 'Hintakategoria 1 (kk) muutos';
    case 'monthPriceCoefficient2Change':
      return 'Hintakategoria 2 (kk) muutos';
    case 'monthPriceCoefficient3Change':
      return 'Hintakategoria 3 (kk) muutos';
    case 'monthPriceCoefficient4Change':
      return 'Hintakategoria 4 (kk) muutos';
    case 'monthPriceCoefficient5Change':
      return 'Hintakategoria 5 (kk) muutos';
    case 'monthPriceCoefficient6Change':
      return 'Hintakategoria 6 (kk) muutos';
    case 'monthPriceCoefficient7Change':
      return 'Hintakategoria 7 (kk) muutos';
    case 'monthPriceCoefficientListChange':
      return 'Listahinta (kk) muutos';

    case 'dayPricePercent1Change':
      return 'Toimialaprosentti (pv) muutos - kategoria 1';
    case 'dayPricePercent2Change':
      return 'Toimialaprosentti (pv) muutos - kategoria 2';
    case 'dayPricePercent3Change':
      return 'Toimialaprosentti (pv) muutos - kategoria 3';
    case 'dayPricePercent4Change':
      return 'Toimialaprosentti (pv) muutos - kategoria 4';
    case 'dayPricePercent5Change':
      return 'Toimialaprosentti (pv) muutos - kategoria 5';
    case 'dayPricePercent6Change':
      return 'Toimialaprosentti (pv) muutos - kategoria 6';
    case 'dayPricePercent7Change':
      return 'Toimialaprosentti (pv) muutos - kategoria 7';

    case 'monthPricePercent1Change':
      return 'Toimialaprosentti (kk) muutos - kategoria 1';
    case 'monthPricePercent2Change':
      return 'Toimialaprosentti (kk) muutos - kategoria 2';
    case 'monthPricePercent3Change':
      return 'Toimialaprosentti (kk) muutos - kategoria 3';
    case 'monthPricePercent4Change':
      return 'Toimialaprosentti (kk) muutos - kategoria 4';
    case 'monthPricePercent5Change':
      return 'Toimialaprosentti (kk) muutos - kategoria 5';
    case 'monthPricePercent6Change':
      return 'Toimialaprosentti (kk) muutos - kategoria 6';
    case 'monthPricePercent7Change':
      return 'Toimialaprosentti (kk) muutos - kategoria 7';

    case 'pimProductGroupChange':
      return 'PIM - Tuoteryhmä muutos';
    case 'pimProductLineChange':
      return 'PIM - Tuotelinja muutos';
    case 'pimCategoryChange':
      return 'PIM - Kategoria muutos';
    case 'belongsToCompactPreSelectionChange':
      return 'Tuote kuuluu suppeaan esivalintaan';
    case 'belongsToLargePreSelectionChange':
      return 'Tuote kuuluu laajaan esivalintaan';
    case 'pricingSheetArchived':
      return 'Hinnasto arkistoitu';
    case 'pricingSheetRestored':
      return 'Hinnasto palautettu arkistosta';
    case 'pricingSheetRemoved':
      return 'Hinnasto poistettu';
    default:
      return 'Määrittelemätön tapahtuma: ' + eventType;
  }
};

const AuditLogAdditionalProps: React.FC<{
  eventType: AuditEventType;
  eventProps?: { [key: string]: any };
}> = ({ eventProps, eventType }) => {
  switch (eventType) {
    case 'dayPriceChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha hinta: {eventProps && eventProps.prevDayPrice}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi hinta: {eventProps && eventProps.nextDayPrice}
            </div>
          )}
        </>
      );
    case 'monthPriceChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha hinta: {eventProps && eventProps.prevMonthPrice}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi hinta: {eventProps && eventProps.nextMonthPrice}
            </div>
          )}
        </>
      );
    case 'rentalReadyChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha vuokrausvalmiushinta:{' '}
              {eventProps && eventProps.prevRentalReadyPrice}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi vuokrausvalmiushinta:{' '}
              {eventProps && eventProps.nextRentalReadyPrice}
            </div>
          )}
        </>
      );
    case 'depreciationPeriodChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha poistoaika:{' '}
              {eventProps &&
                eventProps.prevDepreciationPeriod + ' kk'}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi poistoaika:{' '}
              {eventProps &&
                eventProps.nextDepreciationPeriod + ' kk'}
            </div>
          )}
        </>
      );
    case 'priceSheetStateChange':
      return (
        <table>
          {eventProps && (
            <tr>
              <td>Vanha tila</td>
              <td style={{ paddingLeft: 18 }}>
                {eventProps && getSheetState(eventProps.prevState)}
              </td>
            </tr>
          )}
          {eventProps && (
            <tr>
              <td>Uusi tila</td>
              <td style={{ paddingLeft: 18 }}>
                {eventProps && getSheetState(eventProps.newState)}
              </td>
            </tr>
          )}
          {eventProps && (
            <tr>
              <td>Vanhat perustelut</td>
              <td style={{ paddingLeft: 18 }}>
                <i>{eventProps && eventProps.prevCreatorComment}</i>
              </td>
            </tr>
          )}
          {eventProps && (
            <tr>
              <td>Uudet perustelut</td>
              <td style={{ paddingLeft: 18 }}>
                <i>{eventProps && eventProps.newCreatorComment}</i>
              </td>
            </tr>
          )}
          {eventProps && (
            <tr>
              <td>Vanha palaute</td>
              <td style={{ paddingLeft: 18 }}>
                <i>{eventProps && eventProps.prevApproverComment}</i>
              </td>
            </tr>
          )}
          {eventProps && (
            <tr>
              <td>Uusi palaute</td>
              <td style={{ paddingLeft: 18 }}>
                <i>{eventProps && eventProps.newApproverComment}</i>
              </td>
            </tr>
          )}
        </table>
      );

    case 'itemNameChange':
      return (
        <>
          {eventProps && (
            <div>Vanha nimi: {eventProps && eventProps.prevName}</div>
          )}
          {eventProps && (
            <div>Uusi nimi: {eventProps && eventProps.nextName}</div>
          )}
        </>
      );
    case 'targetUtilRateChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha tavoitekäyttöaste:{' '}
              {eventProps && eventProps.prevTargetUtilRate + ' %'}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi tavoitekäyttöaste:{' '}
              {eventProps && eventProps.nextTargetUtilRate + ' %'}
            </div>
          )}
        </>
      );
    case 'includeInPricingChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha näyttö:{' '}
              {eventProps && eventProps.prevIncludeInPricing
                ? 'Kyllä'
                : 'Ei'}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi näyttö:{' '}
              {eventProps && eventProps.nextIncludeInPricing
                ? 'Kyllä'
                : 'Ei'}
            </div>
          )}
        </>
      );
    case 'markedAsRemovedChange':
      return (
        <>
          {eventProps && (
            <div>
              Oli merkattu poistuneeksi:{' '}
              {eventProps && eventProps.prevMarkedAsRemoved
                ? 'Kyllä'
                : 'Ei'}
            </div>
          )}
          {eventProps && (
            <div>
              Merkittiin poistuneeksi:{' '}
              {eventProps && eventProps.nextMarkedAsRemoved
                ? 'Kyllä'
                : 'Ei'}
            </div>
          )}
        </>
      );
    case 'isRamigreenChange':
      return (
        <>
          {eventProps && (
            <div>
              Oli Ramigreen-tuote:{' '}
              {eventProps && eventProps.prevIsRamigreen
                ? 'Kyllä'
                : 'Ei'}
            </div>
          )}
          {eventProps && (
            <div>
              Merkittiin Ramigreen-tuotteeksi:{' '}
              {eventProps && eventProps.nextIsRamigreen
                ? 'Kyllä'
                : 'Ei'}
            </div>
          )}
        </>
      );
    case 'dayPriceCoefficient1Change':
    case 'dayPriceCoefficient2Change':
    case 'dayPriceCoefficient3Change':
    case 'dayPriceCoefficient4Change':
    case 'dayPriceCoefficient5Change':
    case 'dayPriceCoefficient6Change':
    case 'dayPriceCoefficient7Change':
    case 'dayPriceCoefficientListChange':
    case 'monthPriceCoefficient1Change':
    case 'monthPriceCoefficient2Change':
    case 'monthPriceCoefficient3Change':
    case 'monthPriceCoefficient4Change':
    case 'monthPriceCoefficient5Change':
    case 'monthPriceCoefficient6Change':
    case 'monthPriceCoefficient7Change':
    case 'monthPriceCoefficientListChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha kerroin:{' '}
              {eventProps && eventProps.prevCoefficient}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi kerroin: {eventProps && eventProps.nextCoefficient}
            </div>
          )}
        </>
      );
    case 'pimProductGroupChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha tuoteryhmä:{' '}
              {eventProps && eventProps.prevProductGroup}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi tuoteryhmä:{' '}
              {eventProps && eventProps.nextProductGroup}
            </div>
          )}
        </>
      );
    case 'pimProductLineChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha tuotelinja:{' '}
              {eventProps && eventProps.prevProductLine}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi tuotelinja:{' '}
              {eventProps && eventProps.nextProductLine}
            </div>
          )}
        </>
      );
    case 'pimCategoryChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha kategoria: {eventProps && eventProps.prevCategory}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi kategoria: {eventProps && eventProps.nextCategory}
            </div>
          )}
        </>
      );
    case 'belongsToCompactPreSelectionChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha arvo:{' '}
              {eventProps &&
              eventProps.prevbelongsToCompactPreSelection
                ? 'Kyllä'
                : 'Ei'}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi arvo:{' '}
              {eventProps &&
              eventProps.nextbelongsToCompactPreSelection
                ? 'Kyllä'
                : 'Ei'}
            </div>
          )}
        </>
      );
    case 'belongsToLargePreSelectionChange':
      return (
        <>
          {eventProps && (
            <div>
              Vanha arvo:{' '}
              {eventProps && eventProps.prevbelongsToLargePreSelection
                ? 'Kyllä'
                : 'Ei'}
            </div>
          )}
          {eventProps && (
            <div>
              Uusi arvo:{' '}
              {eventProps && eventProps.nextbelongsToLargePreSelection
                ? 'Kyllä'
                : 'Ei'}
            </div>
          )}
        </>
      );
    case 'pricingSheetArchived':
    case 'pricingSheetRestored':
    case 'pricingSheetRemoved':
      return (
        <>
          {eventProps && (
            <>
              <tr>
                <td>Id</td>
                <td style={{ paddingLeft: 18 }}>
                  {eventProps && eventProps.id}
                </td>
              </tr>
              <tr>
                <td>Nimi</td>
                <td style={{ paddingLeft: 18 }}>
                  {eventProps && eventProps.name}
                </td>
              </tr>
            </>
          )}
        </>
      );
  }

  return <span />;
};

const AuditLogs = () => {
  const [auditLogs, isLoading, error] = useApi<AuditLogEntry[]>(
    '/auditlog/logs?filterByType=user',
  );
  return (
    <>
      {error && (
        <Alert severity="error">Virhe ladattaessa muutoslokia</Alert>
      )}
      {isLoading === LoadingState.IsLoading && <CircularProgress />}
      {!error && (
        <>
          <Alert severity="info">
            Näytetään viimeiset 1000 tapahtumaa.
          </Alert>
          <TableContainer>
            <Table
              style={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Tyyppi</TableCell>
                  <TableCell>Aikaleima</TableCell>
                  <TableCell>Sähköposti</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auditLogs?.map((row, i) => (
                  <TableRow key={row.eventType + i}>
                    <TableCell>
                      {mapEventTypeToText(row.eventType)}
                    </TableCell>
                    <TableCell component="th">
                      {formatDateTime(row.eventDateTime)}
                    </TableCell>
                    <TableCell>{row.userEmail}</TableCell>
                    <TableCell>
                      {row.props?.catClass && (
                        <div>CatClass: {row.props?.catClass}</div>
                      )}
                      <AuditLogAdditionalProps
                        eventProps={row.props}
                        eventType={row.eventType}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default AuditLogs;
