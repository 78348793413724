import { ValueGetterParams } from 'ag-grid-community';
import { PricingSheetRow } from '../../../../../shared/types';

const proposalMonthPriceGetter = (
  params: ValueGetterParams<PricingSheetRow>,
) => {
  const proposalMonthPrice = params.data?.proposalMonthPrice;

  if (!proposalMonthPrice) return '';

  const divider = 1000000;
  const dividedProposalMonthPrice = proposalMonthPrice / divider;

  return dividedProposalMonthPrice
    ? Number(dividedProposalMonthPrice.toFixed(2))
    : null;
};

export default proposalMonthPriceGetter;
