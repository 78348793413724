import {
  DatabaseItem,
  ItemIndustryPercent,
  PricingSheetRow,
} from '../../../shared/types';
import { api } from '../index';

export const getItems = async (includeHidden: boolean = false) => {
  const getItemsResponse = await api.get<DatabaseItem[]>(
    `/items?includeHidden=${includeHidden}`,
  );
  return getItemsResponse.data;
};

interface postItemsVariables {
  items: (
    | {
        value: PricingSheetRow;
        changedProperties: string[];
      }
    | undefined
  )[];
  itemIndustryPercents: (
    | {
        value: ItemIndustryPercent;
        changedProperties: string[];
      }
    | undefined
  )[];
}

export const postItems = ({
  items,
  itemIndustryPercents,
}: postItemsVariables) => {
  const postItemsResponse = api
    .post('/items', {
      items: items,
      itemIndustryPercents: itemIndustryPercents,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return postItemsResponse;
};
