import { PricingBasis, PricingSheetRow } from '../../../shared/types';
import { clone } from './genericUtils';
import {
  isBelow70PercentDayPriceRow,
  isBelow70PercentMonthPriceRow,
  isBelowCriticalPriceDay,
  isBelowCriticalPriceMonth,
  isDayLightWarningPrice,
  isDayWarningPrice,
  isInvalidPriceThreshold,
  isMonthLightWarningPrice,
  isMonthWarningPrice,
  isWarningRow,
  isZeroDayPriceRow,
  isZeroMonthPriceRow,
} from './pricingSheetRowFilters';
import { isSalesItem } from '../../../shared/helpers/isSalesItem';

export type WarningCountFields =
  | 'dayWarnings'
  | 'dayLightWarnings'
  | 'dayBelow70Warnings'
  | 'dayZeroPriceWarnings'
  | 'dayBelowCriticalWarnings'
  | 'monthWarnings'
  | 'monthLightWarnings'
  | 'monthBelow70Warnings'
  | 'monthZeroPriceWarnings'
  | 'monthBelowCriticalWarnings'
  | 'allWarningsCount'
  | 'allBelow70PercentRows'
  | 'invalidPriceThresholdRows';

export type WarningsCounts<T> = {
  [key in WarningCountFields]: T[];
};

const calculateWarningCounts = <
  T extends PricingSheetRow = PricingSheetRow,
>(
  rows: T[],
  pricingBasis: PricingBasis,
): WarningsCounts<T> => {
  const dayPriceWarningsRows = rows.filter((row: PricingSheetRow) => {
    return isDayWarningPrice(row) && !isSalesItem(row.type);
  });

  // orange day rows
  const dayPriceLightWarningsRows = rows.filter(
    (row: PricingSheetRow) =>
      isDayLightWarningPrice(row) && !isSalesItem(row.type),
  );

  const zeroDayPriceRows = rows.filter(
    (row) => isZeroDayPriceRow(row) && !isSalesItem(row.type),
  );

  const zeroMonthPriceRows =
    pricingBasis === 'DAY'
      ? []
      : rows.filter(
          (row) => isZeroMonthPriceRow(row) && !isSalesItem(row.type),
        );

  // red month rows
  const monthPriceWarningsRows =
    pricingBasis === 'DAY'
      ? []
      : rows.filter(
          (row) => isMonthWarningPrice(row) && !isSalesItem(row.type),
        );

  // orange month rows
  const monthPriceLightWarningsRows =
    pricingBasis === 'DAY'
      ? []
      : rows.filter(
          (row) =>
            isMonthLightWarningPrice(row) && !isSalesItem(row.type),
        );

  const allWarningRowsCombined = rows.filter(isWarningRow);

  const below70PercentDayPriceRows = rows?.filter(
    isBelow70PercentDayPriceRow,
  );

  // pink month rows
  const below70PercentMonthPriceRows =
    pricingBasis === 'DAY'
      ? []
      : rows?.filter(isBelow70PercentMonthPriceRow);

  const below70PercentRows = clone<T[]>(
    below70PercentDayPriceRows.concat(below70PercentMonthPriceRows),
  );

  // critical day price rows
  const belowCriticalDayPriceRows =
    pricingBasis === 'MONTH'
      ? []
      : rows?.filter(
          (row) =>
            isBelowCriticalPriceDay(row) && !isSalesItem(row.type),
        );

  // critical month price rows
  const belowCriticalMonthPriceRows =
    pricingBasis === 'DAY'
      ? []
      : rows?.filter(
          (row) =>
            isBelowCriticalPriceMonth(row) && !isSalesItem(row.type),
        );

  // PV->KK -kerroin under 5 or above 30
  const invalidPriceThresholdRows =
    pricingBasis === 'DAY'
      ? []
      : rows?.filter(
          (row) =>
            isInvalidPriceThreshold(row) && !isSalesItem(row.type),
        );

  return {
    dayWarnings: dayPriceWarningsRows,
    dayLightWarnings: dayPriceLightWarningsRows,
    dayBelow70Warnings: below70PercentDayPriceRows,
    dayZeroPriceWarnings: zeroDayPriceRows,
    dayBelowCriticalWarnings: belowCriticalDayPriceRows,
    monthWarnings: monthPriceWarningsRows,
    monthLightWarnings: monthPriceLightWarningsRows,
    monthBelow70Warnings: below70PercentMonthPriceRows,
    monthZeroPriceWarnings: zeroMonthPriceRows,
    monthBelowCriticalWarnings: belowCriticalMonthPriceRows,
    allWarningsCount: allWarningRowsCombined,
    allBelow70PercentRows: below70PercentRows,
    invalidPriceThresholdRows: invalidPriceThresholdRows,
  };
};

export default calculateWarningCounts;
