import React, {
  ReactNode,
  createContext,
  useContext,
  useReducer,
} from 'react';

import itemIndustryPercentsReducer, {
  ItemIndustryPercentReducerAction,
} from './itemIndustryPercentsReducer';
import { ItemIndustryPercent } from '../../../../../shared/types';

export type ItemIndustryPercentsProviderType = {
  itemIndustryPercents: ItemIndustryPercent[] | undefined;
  dispatchItemIndustryPercents: React.Dispatch<ItemIndustryPercentReducerAction>;
};

const ItemIndustryPercentsContext =
  createContext<ItemIndustryPercentsProviderType>(null!);

const ItemIndustryPercentsProvider = (props: {
  children: ReactNode;
}) => {
  const [itemIndustryPercents, dispatchItemIndustryPercents] =
    useReducer(itemIndustryPercentsReducer, undefined);

  const children = props.children;

  return (
    <ItemIndustryPercentsContext.Provider
      value={{ itemIndustryPercents, dispatchItemIndustryPercents }}
    >
      {children}
    </ItemIndustryPercentsContext.Provider>
  );
};

const useItemIndustryPercentsContext = () => {
  const context = useContext(ItemIndustryPercentsContext);

  if (context === undefined) {
    throw new Error(
      'useItemIndustryPercentsProvider was used outside of its Provider',
    );
  }

  return context;
};

export default useItemIndustryPercentsContext;

export { ItemIndustryPercentsContext, ItemIndustryPercentsProvider };
