import React, { useContext, useState, ChangeEvent } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import MultiLineComment from '../../../components/Common/MultiLineComment';

import NotificationProvider from '../../../providers/Notification/NotificationProvider';

import {
  PricingSheet,
  PricingSheetState,
} from '../../../../../shared/types';

import {
  AdditionalInfo,
  updatePricingSheetById,
} from '../../../services/pricingSheets';

import CustomerSelector from './CustomerSelector';
import {
  ClientIdType,
  createOption,
  getClientIdValues,
} from './CustomerSelector';

import { getUniques } from '../../../utils/getUniques';

type MiraDialogFormState = Omit<AdditionalInfo, 'clientIds'>;
export interface MiraDialogProps {
  pricingSheet: PricingSheet;
  open: boolean;
  title: string;
  setSheetState: React.Dispatch<
    React.SetStateAction<PricingSheetState>
  >;
  updateSheets: () => Promise<void>;
  onClose: () => void;
}
const MiraDialog = (props: MiraDialogProps) => {
  const {
    pricingSheet,
    open,
    setSheetState,
    updateSheets,
    title,
    onClose,
  } = props;

  const { setNotification } = useContext(NotificationProvider);
  const pricingSheetMiraInfo = pricingSheet?.miraInfo;

  const defaultClientIds = pricingSheetMiraInfo
    ? pricingSheetMiraInfo.customerIds?.map((id) => createOption(id))
    : [];

  const defaultClientIdsFromInitialStep = pricingSheet?.customerId
    ? [createOption(pricingSheet?.customerId)]
    : [];

  const [clientIds, setClientIds] = useState<readonly ClientIdType[]>(
    defaultClientIds?.length > 0
      ? defaultClientIds
      : defaultClientIdsFromInitialStep,
  );

  const initialFormState = {
    oldSheetId: pricingSheetMiraInfo?.miraId || '',
    projectId: '',
    comment: '',
  };

  const [formState, setFormState] =
    useState<MiraDialogFormState>(initialFormState);

  const handleInputChange =
    (name: keyof MiraDialogFormState) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setFormState({ ...formState, [name]: value });
    };

  const sendExportRequest = async () => {
    if (clientIds.length === 0) {
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'error',
        message: 'Lisää asiakasnumero(t) ja vanhan hinnaston id!',
      });
    } else {
      const additionalInfo: AdditionalInfo = {
        oldSheetId: formState.oldSheetId || undefined,
        projectId: formState.projectId || undefined,
        comment: formState.comment || undefined,
        clientIds: getUniques(getClientIdValues(clientIds)),
      };

      try {
        await updatePricingSheetById(
          pricingSheet.id,
          {
            state: PricingSheetState.Ready,
          },
          additionalInfo,
        );
        setSheetState(PricingSheetState.Ready);
        updateSheets();
        setNotification({
          type: 'SNACKBAR',
          duration: 2000,
          severity: 'success',
          message: 'Vientipyyntö hinnastotiimille lähetetty!',
        });
      } catch (error) {
        console.error(error);
        setNotification({
          type: 'SNACKBAR',
          duration: 2000,
          severity: 'error',
          message: 'Vientipyyntöä lähettäessä tapahtui virhe!',
        });
      }
      clearAndClose();
    }
  };

  const clearAndClose = () => {
    setFormState(initialFormState);
    onClose();
  };

  return (
    <Dialog
      disableScrollLock
      fullWidth
      open={open}
      onClose={clearAndClose}
    >
      <DialogTitle id="mira-dialog-title">{title}</DialogTitle>

      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <DialogContentText color={'text.primary'}>
            {pricingSheet.name}
          </DialogContentText>
          <DialogContentText color={'text.secondary'}>
            {pricingSheet?.customerId}
          </DialogContentText>
        </Box>
        {
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <DialogContentText>
                Asiakasnumerot, joihin hinnasto liitetään
              </DialogContentText>
              <CustomerSelector
                disabled={false}
                clientIds={clientIds}
                setClientIds={setClientIds}
              />

              <DialogContentText>
                Päivitettävän hinnaston Id (numero)
              </DialogContentText>
              <TextField
                value={formState.oldSheetId}
                onChange={handleInputChange('oldSheetId')}
                id="outlined-basic"
                label="Hinnaston ID"
                variant="outlined"
                size="small"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
              />
              <DialogContentText>
                Projektinumero, mikäli projektihinnasto
              </DialogContentText>
              <TextField
                value={formState.projectId}
                onChange={handleInputChange('projectId')}
                id="outlined-basic"
                label="Projektinumero"
                variant="outlined"
                size="small"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
              />
              <DialogContentText>
                Kommentti hinnastotiimille
              </DialogContentText>
              <MultiLineComment
                label="Esim. hinnastoketjutus"
                placeholder="Lisää kommentti"
                handleCommentChange={handleInputChange('comment')}
                required={false}
              />
            </Box>

            <DialogActions sx={{ mt: 2 }}>
              <Button onClick={clearAndClose}>Peru</Button>
              <Button
                variant="contained"
                onClick={sendExportRequest}
                autoFocus
              >
                Lähetä vientipyyntö
              </Button>
            </DialogActions>
          </>
        }
      </DialogContent>
    </Dialog>
  );
};

export default MiraDialog;
