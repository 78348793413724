import { useQuery } from '@tanstack/react-query';
import {
  ALL_ITEMS_PRICING_SHEET_ID,
  MIRA_RAMITURVA_PRICES_QUERY_KEY,
  PRICE_FACTOR,
} from '../../../shared/constants';
import { getPricingSheetItemsMira } from '../services/pricingSheetItems';
import { produce } from 'immer';
import _ from 'lodash';
import { RamiturvaPricesMira } from '../../../shared/types';

const useRamiturvaPrices = () => {
  // fetch ramiturva prices from mira
  return useQuery({
    staleTime: Infinity,
    queryKey: [
      MIRA_RAMITURVA_PRICES_QUERY_KEY,
      ALL_ITEMS_PRICING_SHEET_ID,
    ],
    queryFn: async () => {
      const result = await getPricingSheetItemsMira(
        String(ALL_ITEMS_PRICING_SHEET_ID),
        true,
      );
      return result.reduce<RamiturvaPricesMira>((acc, cur) => {
        const { catClass } = cur.item;
        const { dayPrice, monthPrice } = cur;
        if (catClass.startsWith('XR')) {
          // reduce array to {catclass: prices} object to make finding catclass more efficient
          return produce(acc, (draft: RamiturvaPricesMira) => {
            _.set(
              draft,
              `${catClass}.ramiturvaDayPrice`,
              dayPrice ? (dayPrice / 100) * PRICE_FACTOR : dayPrice,
            );
            _.set(
              draft,
              `${catClass}.ramiturvaMonthPrice`,
              monthPrice
                ? (monthPrice / 100) * PRICE_FACTOR
                : monthPrice,
            );
          });
        }
        return acc;
      }, {});
    },
  });
};

export default useRamiturvaPrices;
