import { memo } from 'react';

import { PricingSheetRow } from '../../../../../shared/types';
import coefficientValueGetter from '../valueGetters/coefficientValueGetter';
import CoefficientRenderer from '../Renderers/CoefficientRenderer';

import NumericEditorV2 from '../Editors/NumericEditorV2';

import { GridColumnProps } from '../Columns/ColumnsBase';
import { DAY_PRICE_COEFFICIENTS } from '../../../../../shared/constants';
import { getCatClassColDef } from '../helpers/getCatClassColDef';

interface OptionalColumnProps {
  handleUpdateRowValue: (row: PricingSheetRow) => void;
}

const AdminColumnsDay = ({
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps<OptionalColumnProps>) => {
  const { handleUpdateRowValue } = optionalColumnProps;

  const dayPriceCoefficientsColumns = DAY_PRICE_COEFFICIENTS.map(
    ({ key, name }) => ({
      field: key,
      headerName: name,
      editable: (params: { data: PricingSheetRow }) =>
        !params.data.showByOfferText,
      filter: 'agNumberColumnFilter',
      valueGetter: (params: { data: PricingSheetRow }) =>
        coefficientValueGetter(params, key),
      cellRenderer: memo(CoefficientRenderer),
      cellRendererParams: {
        coefficient: key,
      },
      cellEditor: NumericEditorV2,
      cellEditorParams: {
        handleUpdateRowValue,
      },
      cellStyle: () => {
        if (key === 'dayPriceCoefficientList') {
          return {
            color: '#000',
            borderLeft: '1px solid #ccc',
            borderRight: '1px solid #ccc',
          };
        }
        return { color: '#000', borderLeft: '1px solid #ccc' };
      },
    }),
  );

  const groupingColumns: any = getCatClassColDef(gridGroupingValues);

  const finalColumns = groupingColumns.concat(
    dayPriceCoefficientsColumns,
  );

  return finalColumns;
};

export default AdminColumnsDay;
