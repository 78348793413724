import { createTheme } from '@mui/material';
import DefaultTooltip from '../../Tooltips/DefaultTooltip';

const WarningCountBoth = ({
  dayWarnings,
  monthWarnings,
  color,
}: {
  dayWarnings: number;
  monthWarnings: number;
  color: string;
}) => {
  return (
    <>
      {dayWarnings > 0 || monthWarnings > 0 ? (
        <>
          <span
            style={{
              color: color,
              marginLeft: 4,
            }}
          >
            {`( ${dayWarnings}  | `}
          </span>
          <span
            style={{
              color: color,
            }}
          >
            {` ${monthWarnings} ) `}
          </span>
        </>
      ) : null}
    </>
  );
};

const SingleWarningCount = ({
  warnings,
  color,
}: {
  warnings: number;
  color: string;
}) => {
  return (
    <>
      {warnings > 0 ? (
        <>
          <span
            style={{
              marginLeft: 8,
              color: color,
            }}
          >
            {`( ${warnings} )`}
          </span>
        </>
      ) : null}
    </>
  );
};

type WarningCountsProps = {
  countDayPriceWarnings: number;
  countCriticalDayPriceWarnings?: number;
  countMonthPriceWarnings: number;
  countDayPriceLightWarnings: number;
  countMonthPriceLightWarnings: number;
  countBelow70PercentDayPriceRows: number;
  countBelow70PercentMonthPriceRows: number;
  countZeroDayPriceRows: number;
  countZeroMonthPriceRows: number;
  countCriticalMonthPriceWarnings?: number;
  countPriceThresholdWarnings?: number;
  pricingBasis: string;
};

const WarningCounts = ({
  countDayPriceWarnings,
  countCriticalDayPriceWarnings,
  countMonthPriceWarnings,
  countDayPriceLightWarnings,
  countMonthPriceLightWarnings,
  countBelow70PercentDayPriceRows,
  countBelow70PercentMonthPriceRows,
  countZeroDayPriceRows,
  countZeroMonthPriceRows,
  countCriticalMonthPriceWarnings,
  countPriceThresholdWarnings,
  pricingBasis,
}: WarningCountsProps) => {
  const theme = createTheme();
  return (
    <>
      {pricingBasis === 'DAY' && (
        <>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle 1 hintakertoimen"
          >
            <SingleWarningCount
              warnings={countDayPriceWarnings}
              color={theme.palette.error.light}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle 1,2 hintakertoimen"
          >
            <SingleWarningCount
              warnings={countDayPriceLightWarnings}
              color={theme.palette.warning.light}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle 70% ehdotushinnasta"
          >
            <SingleWarningCount
              warnings={countBelow70PercentDayPriceRows}
              color={'#f48fb1'}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä nollahintoina"
          >
            <SingleWarningCount
              warnings={countZeroDayPriceRows}
              color={theme.palette.warning.dark}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle kriittisen hinnan"
          >
            <SingleWarningCount
              warnings={countCriticalDayPriceWarnings ?? 0}
              color={theme.palette.error.light}
            />
          </DefaultTooltip>
        </>
      )}
      {pricingBasis === 'MONTH' && (
        <>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle 1 hintakertoimen (pv | kk)"
          >
            <SingleWarningCount
              warnings={countMonthPriceWarnings}
              color={theme.palette.error.light}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle 1,2 hintakertoimen (pv | kk)"
          >
            <SingleWarningCount
              warnings={countMonthPriceLightWarnings}
              color={theme.palette.warning.light}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle 70% ehdotushinnasta (pv | kk)"
          >
            <SingleWarningCount
              warnings={countBelow70PercentMonthPriceRows}
              color={'#f48fb1'}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä nollahintoina (pv | kk)"
          >
            <SingleWarningCount
              warnings={countZeroMonthPriceRows}
              color={theme.palette.warning.dark}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle kriittisen hinnan"
          >
            <SingleWarningCount
              warnings={countCriticalMonthPriceWarnings ?? 0}
              color={theme.palette.error.light}
            />
          </DefaultTooltip>
        </>
      )}{' '}
      {pricingBasis === 'BOTH' && (
        <>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle 1 hintakertoimen (pv | kk)"
          >
            <WarningCountBoth
              dayWarnings={countDayPriceWarnings}
              monthWarnings={countMonthPriceWarnings}
              color={theme.palette.error.light}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle 1,2 hintakertoimen (pv | kk)"
          >
            <WarningCountBoth
              dayWarnings={countDayPriceLightWarnings}
              monthWarnings={countMonthPriceLightWarnings}
              color={theme.palette.warning.light}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle 70% ehdotushinnasta (pv | kk)"
          >
            <WarningCountBoth
              dayWarnings={countBelow70PercentDayPriceRows}
              monthWarnings={countBelow70PercentMonthPriceRows}
              color={'#f48fb1'}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä nollahintoina (pv | kk)"
          >
            <WarningCountBoth
              dayWarnings={countZeroDayPriceRows}
              monthWarnings={countZeroMonthPriceRows}
              color={theme.palette.warning.dark}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä joilla PV->KK-kerroin alle 5 tai yli 30"
          >
            <SingleWarningCount
              warnings={countPriceThresholdWarnings ?? 0}
              color={theme.palette.warning.dark}
            />
          </DefaultTooltip>
          <DefaultTooltip
            placement="top"
            title="Tuoterivejä alle kriittisen hinnan"
          >
            <WarningCountBoth
              dayWarnings={countCriticalDayPriceWarnings ?? 0}
              monthWarnings={countCriticalMonthPriceWarnings ?? 0}
              color={theme.palette.error.light}
            />
          </DefaultTooltip>
        </>
      )}
    </>
  );
};

export default WarningCounts;
