import { Step, StepLabel, Stepper } from '@mui/material';
import { useOfferStore } from '../../stores/offerStore';
import StepButton from '@mui/material/StepButton';
import { OfferStep } from '../../../../shared/types/offers';

type OfferStepperProps = {
  onStepClick?: (step: number) => void;
  stepDisabled?: (step: number) => boolean | undefined;
};

const stepLabels = [
  'Asiakkaan perustiedot',
  'Tarjouksen tuotteet',
  'Tuotteiden tiedot',
  'Hinnoittelu',
  'Yhteenveto',
] as const;

const OfferStepper = ({
  onStepClick,
  stepDisabled = () => false,
}: OfferStepperProps) => {
  const activeStep = useOfferStore((state) => state.activeStep);
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {stepLabels.map((label, index) => (
        <Step key={label}>
          {onStepClick &&
          index < Object.keys(OfferStep).length / 2 - 1 ? (
            <StepButton
              disabled={stepDisabled(index)}
              onClick={() => {
                onStepClick(index);
              }}
              sx={{
                '&.Mui-disabled .MuiStepIcon-root': {
                  color: 'text.primary',
                  opacity: 0.38,
                },
              }}
            >
              <StepLabel>{label}</StepLabel>
            </StepButton>
          ) : (
            <StepLabel>{label}</StepLabel>
          )}
        </Step>
      ))}
    </Stepper>
  );
};

export default OfferStepper;
