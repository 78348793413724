import useUserContext from '../../providers/User/UserProvider';

import { Route } from 'react-router-dom';

import NotificationRedirect from './NotificationRedirect';

const AdminRoute = (props: any) => {
  const { userRoles, userEmail, isAdmin } = useUserContext();

  // check isAdmin after provider has set roles
  if (userRoles) {
    return userEmail && isAdmin ? (
      <Route {...props} />
    ) : (
      <NotificationRedirect />
    );
  }
  return null;
};

export default AdminRoute;
