import axios from 'axios';
import { processEnvs } from '../config/processEnvs';
import { AgGridColumnView } from '../../../shared/types';
import {
  ImportHeaderMapping,
  ImportRowData,
} from '../../../shared/types/import';

const api = axios.create({
  baseURL: processEnvs.BACKEND_URI,
  withCredentials: true,
});

type ImportXlXFnParams = {
  data: string;
  headerMapping: ImportHeaderMapping[keyof ImportHeaderMapping];
};

export const importXlx = async <ColumnView extends AgGridColumnView>({
  data,
  headerMapping,
}: ImportXlXFnParams): Promise<ImportRowData<ColumnView>[]> => {
  const { data: importData } = await api.post<
    ImportRowData<ColumnView>[]
  >(`/import`, { data, headerMapping });
  return importData;
};
