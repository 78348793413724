import React from 'react';

import { Divider } from '@mui/material';

import { SubtleText } from '../InitialInformationStep';

import RegionSelector from './Selectors/RegionSelector';
import GenericCheckbox from './Checkboxes/GenericCheckbox';

interface RegionProps {}
const Region: React.FC<RegionProps> = () => {
  return (
    <>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <SubtleText>Alue*</SubtleText>

      <RegionSelector />
      <GenericCheckbox
        label={'Toimii valtakunnallisesti'}
        propName={'nationWide'}
      />
    </>
  );
};

export default Region;
