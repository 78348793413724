import { Dispatch, SetStateAction } from 'react';

import TextField from '@mui/material/TextField';

interface EditPricesByPercentProps {
  percentValue: number | null | undefined;
  setPercentValue: Dispatch<
    SetStateAction<number | null | undefined>
  >;
  formatInputValue: any;
}

const EditPricesByPercent: React.FC<EditPricesByPercentProps> = ({
  percentValue,
  setPercentValue,
  formatInputValue,
}) => {
  const handlePercentChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPercentValue(
      event.target.value ? Number(event.target.value) : null,
    );
  };

  return (
    <>
      <TextField
        sx={{ mt: 1 }}
        size="small"
        id="percent-change"
        label="Prosenttia %"
        type="number"
        value={formatInputValue(percentValue)}
        onChange={handlePercentChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
      />
    </>
  );
};

export default EditPricesByPercent;
