import { Box, Button, Grid, Typography } from '@mui/material';
import GenericContainer from './GenericContainer';

export const ErrorFallback = () => {
  return (
    <GenericContainer>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item sx={{ mb: 2 }}>
          <Box>
            <img width={150} src="/rami-error.svg" alt="Error" />
          </Box>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5" style={{ marginLeft: 50 }}>
              Järjestelmässä tapahtui virhe. Päivitä sivu tai yritä
              myöhemmin uudelleen.
            </Typography>
            <Box sx={{ marginTop: 3 }}>
              <Button
                onClick={() => window.location.reload()}
                variant="outlined"
              >
                Päivitä sivu
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </GenericContainer>
  );
};
