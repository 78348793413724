import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  AgGridColumnView,
  PricingSheetRow,
} from '../../../../../shared/types';
import {
  ImportChangedRow,
  ImportRowData,
  ImportTableColumn,
  ImportType,
} from '../../../../../shared/types/import';
import CircularProgress from '@mui/material/CircularProgress';
import ExcelImportChangesTable from './ExcelImportChangesTable';
import Box from '@mui/material/Box';
import {
  InsertDriveFileOutlined,
  UploadFile,
  WarningAmber,
} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styled from '@emotion/styled';
import ExcelImportHeaderMapper from './ExcelImportHeaderMapper';
import { convertFileToBase64 } from '../../../../../shared/utils/convertFileToBase64';
import { useMutation } from '@tanstack/react-query';
import { importXlx } from '../../../services/import';
import { useImportStore } from '../../../stores/importStore';
import { Divider } from '@mui/material';
import {
  getCatClassWithDataMapping,
  getChangedRows,
} from './helpers';
import { DIALOG_TRANSITION_DURATION } from '../../../../../shared/constants';

export type ExcelImportDialogCommonProps = {
  open: boolean;
  onClose: () => void;
};

type ExcelImportDialogBaseProps<RowData> =
  ExcelImportDialogCommonProps & {
    changesTableColumns: ImportTableColumn[];
    importType: ImportType;
    onSave: (
      changes: Record<string, Omit<ImportChangedRow, 'catClass'>>,
    ) => void;
    renderContent?: (
      fileName: string | null,
      loading: boolean,
    ) => ReactNode;
    renderHeaderMapper: (disabled: boolean) => ReactNode;
    rowData: RowData[];
    title: string;
  };
const ExcelImportDialogBase = <
  ColumnView extends AgGridColumnView,
  RowData extends PricingSheetRow = PricingSheetRow,
>({
  changesTableColumns = [],
  importType,
  onClose,
  onSave,
  open,
  renderContent = () => null,
  renderHeaderMapper,
  rowData,
  title,
}: ExcelImportDialogBaseProps<RowData>) => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [importData, setImportData] = useState<
    ImportRowData<ColumnView>[] | null
  >(null);

  // file import mutation
  const { mutate: importFile, isPending } = useMutation({
    mutationFn: importXlx<ColumnView>,
  });

  const getHeaderMapping = useImportStore(
    (state) => state.getHeaderMapping,
  );

  const [changedRows, changedRowsCount] = useMemo(() => {
    const changes = getChangedRows<ColumnView>(
      rowData,
      importData,
      importType,
    );
    const changesSortedByCatClass = changes.sort((a, b) =>
      a.catClass.localeCompare(b.catClass),
    );
    const count = changes.reduce(
      (acc, item) => (acc += Object.keys(item.changes).length),
      0,
    );
    return [changesSortedByCatClass, count];
  }, [importData, importType, rowData]);

  const hasChanges = changedRowsCount > 0;

  useEffect(() => {
    // reset data on close (after transition is complete)
    if (!open) {
      setTimeout(() => {
        handleClear();
      }, DIALOG_TRANSITION_DURATION);
    }
  }, [open]);

  const handleUpload = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      handleClear();
      importFile(
        {
          data: await convertFileToBase64(file),
          headerMapping: getHeaderMapping(importType),
        },
        {
          onSuccess: (data) => {
            setFileName(file.name);
            setImportData(data);
          },
          onError: (e) => {
            console.error(`Failed to import file: ${e}`);
            // todo: show notification
          },
        },
      );
      // reset the input field
      event.target.value = '';
    }
  };

  const handleClear = () => {
    setImportData(null);
    setFileName(null);
  };

  return (
    <Dialog
      open={open}
      closeAfterTransition
      fullWidth
      maxWidth={'xl'}
      transitionDuration={DIALOG_TRANSITION_DURATION}
      onClose={onClose}
    >
      {/* TITLE */}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          pb: 2,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            color: 'primary.main',
            display: 'flex',
            mb: 2,
          }}
        >
          {/* FILE INPUT */}
          <FileInput loading={isPending} onUpload={handleUpload} />
          {fileName && !isPending && (
            <>
              <InsertDriveFileOutlined
                sx={{
                  ml: 2,
                  mr: 0.5,
                }}
              />
              {fileName}
              <IconButton onClick={handleClear}>
                <Close color={'error'} />
              </IconButton>
            </>
          )}
        </Box>
        {/* HEADER MAPPING */}
        <ExcelImportHeaderMapper
          disabled={Boolean(fileName) || isPending}
          renderHeaderMapper={renderHeaderMapper}
        />
        {/* CUSTOM CONTENT */}
        {renderContent(fileName, isPending)}
        {/* LOADING */}
        {isPending && (
          <NotificationText>
            <CircularProgress size={18} thickness={5} />
            Ladataan tietoja...
          </NotificationText>
        )}
        {/* NO CHANGES */}
        {!hasChanges && fileName && !isPending && (
          <NotificationText>
            <WarningAmber />
            Muutoksia ei löytynyt!
          </NotificationText>
        )}
        {hasChanges && (
          <>
            <Divider sx={{ my: 1.5 }} />
            <DialogLabel variant={'body1'}>Muutokset</DialogLabel>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                mb: 1,
              }}
            >
              <Typography
                variant={'body1'}
                sx={{
                  fontSize: '0.75rem',
                }}
              >
                Muuttuneet tuotteet: <b>{changedRows.length}</b> -
                Muuttuneet arvot: <b>{changedRowsCount}</b>
              </Typography>
            </Box>
            {/* CHANGES TABLE */}
            <ExcelImportChangesTable
              changedRows={changedRows}
              columns={changesTableColumns}
              importType={importType}
            />
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          px: 3,
          pt: 0,
          pb: 2,
        }}
      >
        <Button onClick={onClose}>Peruuta</Button>
        <Button
          disabled={!hasChanges}
          variant={'contained'}
          onClick={() =>
            onSave(getCatClassWithDataMapping(changedRows))
          }
        >
          Vahvista muutokset
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type FileInputProps = {
  loading?: boolean;
  onUpload: (event: ChangeEvent<HTMLInputElement>) => void;
};

const FileInput = ({
  loading = false,
  onUpload = () => null,
}: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <Button
        sx={{
          flexShrink: 0,
        }}
        variant={'outlined'}
        startIcon={<UploadFile />}
        disabled={loading}
        endIcon={
          loading && <CircularProgress thickness={4} size={16} />
        }
        onClick={() => inputRef.current?.click()}
      >
        Valitse tiedosto
      </Button>
      <input
        type={'file'}
        accept={
          // only accept excel files
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
        ref={inputRef}
        onChange={onUpload}
        style={{ display: 'none' }}
      />
    </>
  );
};

export const NotificationText = styled(Typography)`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  font-weight: bold;
  justify-content: center;
  margin: 2rem 0;
`;

export const DialogSubLabel = styled(Typography)`
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
`;

export const DialogLabel = styled(DialogSubLabel)`
  font-weight: bold;
`;

export default ExcelImportDialogBase;
