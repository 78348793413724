import { PricingSheetRow, IndustryName, ItemIndustryPercent } from "./types";

export const industries = [
  "Rakentaminen",
  "Teollisuus",
  "Talotekniikka",
  "Infra",
  "Tapahtuma",
  "Muu",
];

export const findItemIndustryPercents = (
  items: PricingSheetRow[],
  catClass: string,
): Array<ItemIndustryPercent> => {
  const itemIndustryPercents = items.find(
    (item) => item.catClass === catClass,
  )?.itemIndustryPercents;
  if (itemIndustryPercents) {
    return itemIndustryPercents;
  }
  return [];
};

export const findPercentsForIndustry = (
  itemIndustryPercents: ItemIndustryPercent[],
  industry: IndustryName,
) => {
  const percentsForSelectedIndustry = itemIndustryPercents?.find(
    (percents: { industry: { name: IndustryName } }) =>
      percents.industry.name === industry,
  );

  if (percentsForSelectedIndustry) {
    return percentsForSelectedIndustry;
  }
};

// update percents for industry and return all ItemIndustryPercents
export const updatePercentsForIndustry = (
  itemIndustryPercents: ItemIndustryPercent[],
  industry: IndustryName,
  newValues: ItemIndustryPercent,
) => {
  const industryIndex = itemIndustryPercents.findIndex(
    (percents: { industry: { name: IndustryName } }) =>
      percents.industry.name === industry,
  );
  itemIndustryPercents[industryIndex] = newValues;
  return itemIndustryPercents;
};
