import useUserContext from '../../providers/User/UserProvider';
import {
  Redirect,
  Route,
  RouteProps,
  useLocation,
} from 'react-router-dom';

import AdminRoute from '../../components/Routes/AdminRoute';

const PrivateRoute = (props: RouteProps) => {
  const { userEmail, loggedIn } = useUserContext();

  const { pathname } = useLocation();

  if (loggedIn && pathname.startsWith('/admin')) {
    return <AdminRoute {...props} />;
  }

  return userEmail ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
};

export default PrivateRoute;
