import { useContext } from 'react';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/material';

import { PricingSheetRow } from '../../../../../shared/types';

import RowsProvider from '../../../providers/Rows/RowsProvider';

import { clone } from '../../../utils/genericUtils';

interface BooleanRendererProps {
  data: PricingSheetRow;
  column: string;
  value: boolean;
}

const BooleanRenderer: React.FC<BooleanRendererProps> = ({
  data,
  column,
  value,
}) => {
  const row = data ? (clone(data) as PricingSheetRow) : undefined;
  const { pricingSheetRows, updateRow } = useContext(RowsProvider);

  const currentRow = pricingSheetRows.find(
    (r: PricingSheetRow) => r.catClass === row?.catClass,
  );

  const currentIncludeInPricing = currentRow?.includeInPricing;
  const currentMarkedAsRemoved = currentRow?.markedAsRemoved;
  const currentIsRamigreen = currentRow?.isRamigreen;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    switch (column) {
      case 'includeInPricing':
        updateRow({
          ...currentRow,
          includeInPricing: event.target.checked,
        } as PricingSheetRow);
        break;
      case 'markedAsRemoved':
        updateRow({
          ...currentRow,
          markedAsRemoved: event.target.checked,
        } as PricingSheetRow);
        break;
      case 'isRamigreen':
        updateRow({
          ...currentRow,
          isRamigreen: event.target.checked,
        } as PricingSheetRow);
        break;
    }
  };

  const getLabel = (column: string) => {
    switch (column) {
      case 'includeInPricing':
        return currentIncludeInPricing ? 'Kyllä' : 'Ei';
      case 'markedAsRemoved':
        return currentMarkedAsRemoved ? 'Kyllä' : 'Ei';
      case 'isRamigreen':
        return currentIsRamigreen ? 'Kyllä' : 'Ei';
    }
  };

  return (
    <Box id={`${row?.catClass}`}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={value || false}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              size="small"
            />
          }
          label={getLabel(column)}
        />
      </FormGroup>
    </Box>
  );
};

export default BooleanRenderer;
