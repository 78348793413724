import { useHierarchy } from './useHierarchy';
import { useCallback, useMemo } from 'react';
import { getHierarchySortIndexMapping } from '../helpers/getHierarchySortIndexMapping';
import { sortGridGroups } from '../helpers/sortGridGroups';
import { InitialGroupOrderComparatorParams } from 'ag-grid-community';
import { PricingSheetRow } from '../../../shared/types';

export const useSortedGroupHierarchy = () => {
  const { data, isPending } = useHierarchy({ flat: true });
  const nodeHierarchySortIndexMapping = useMemo(
    () => getHierarchySortIndexMapping(data),
    [data],
  );
  const sortGroups = useCallback(
    (params: InitialGroupOrderComparatorParams<PricingSheetRow>) =>
      nodeHierarchySortIndexMapping
        ? sortGridGroups(nodeHierarchySortIndexMapping)(params)
        : 0,
    [nodeHierarchySortIndexMapping],
  );
  return { isPending, sortGroups };
};
