import { ItemGroup } from '../../../../shared/types';
import {
  GroupingType,
  miraGroupingOptions,
  pimGroupingOptions,
} from '../../hooks/useColumns';

const SelectGrouping: React.FC<{
  type: GroupingType;
  groupingValues: ItemGroup[];
  updateGroupingValues: (values: ItemGroup[]) => void;
}> = ({ type, groupingValues, updateGroupingValues }) => {
  const toggleOption = (option: ItemGroup, enabled: boolean) => {
    const index = groupingValues
      .map((v: ItemGroup) => v.name)
      .indexOf(option.name);

    const removeOption = (opts: ItemGroup[], index: number) => {
      const newOptions = [...opts];
      newOptions.splice(index, 1);
      return newOptions;
    };

    const addOption = (opts: ItemGroup[], value: ItemGroup) => {
      return opts.concat([value]);
    };

    if (enabled) {
      updateGroupingValues(addOption(groupingValues, option));
    } else {
      updateGroupingValues(removeOption(groupingValues, index));
    }
  };

  return (
    <>
      {type === 'MIRA' ? (
        <>
          {miraGroupingOptions.map((option: ItemGroup) => (
            <label key={option.value}>
              <input
                type="checkbox"
                checked={groupingValues
                  .map((v: ItemGroup) => v.value)
                  .includes(option.value)}
                onChange={(event) =>
                  toggleOption(option, event.target.checked)
                }
              />
              {option.name}
            </label>
          ))}
        </>
      ) : (
        <>
          {pimGroupingOptions.map((option: ItemGroup) => (
            <label key={option.value}>
              <input
                type="checkbox"
                checked={groupingValues
                  .map((v: ItemGroup) => v.name)
                  .includes(option.name)}
                onChange={(event) =>
                  toggleOption(option, event.target.checked)
                }
              />
              {option.name}
            </label>
          ))}
        </>
      )}
    </>
  );
};

export default SelectGrouping;
