import React from 'react';

import { Divider } from '@mui/material';

import { SubtleText } from '../InitialInformationStep';

import IndustrySelector from './Selectors/IndustrySelector';
import { IndustryName, Nullable } from '../../../../../shared/types';

interface SegmentProps {
  changeValuesEnabled: boolean;
  pricingSheetId: number;
  industry: IndustryName;
  handleIndustryChange: (newValue: Nullable<string>) => void;
}
const Segment: React.FC<SegmentProps> = ({
  changeValuesEnabled,
  pricingSheetId,
  industry,
  handleIndustryChange,
}) => {
  return (
    <>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <SubtleText>Asiakkaan toimiala*</SubtleText>
      <IndustrySelector
        changeValuesEnabled={changeValuesEnabled}
        pricingSheetId={pricingSheetId}
        industry={industry}
        onIndustryChange={handleIndustryChange}
      />
    </>
  );
};

export default Segment;
