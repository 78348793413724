import { PricingSheetRow } from '../../../../../../shared/types';

const updateComment = (
  id: string,
  pricingSheetRows: PricingSheetRow[],
  comment: string,
) => {
  let filtered: PricingSheetRow[];

  filtered = pricingSheetRows.filter(
    (pricingSheetRow) =>
      pricingSheetRow['prefixProductGroup'] === id ||
      pricingSheetRow['prefixProductLine'] === id ||
      pricingSheetRow['prefixCategory'] === id ||
      pricingSheetRow['catClass'] === id ||
      pricingSheetRow['prefixPimProductGroup'] === id ||
      pricingSheetRow['prefixPimProductLine'] === id ||
      pricingSheetRow['prefixPimCategory'] === id,
  );

  if (id === 'allRows') {
    filtered = pricingSheetRows.filter(
      (row: PricingSheetRow) => row.preSelected,
    );
  }

  const newHierarchy = pricingSheetRows.map((obj) => {
    if (filtered.includes(obj)) {
      return {
        ...obj,
        approverComment: comment,
      };
    } else {
      return obj;
    }
  });
  return newHierarchy;
};

export default updateComment;
