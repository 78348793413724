import { PricingSheetRow, RowFieldType } from '../../../shared/types';
import { getGroupFilterForGroup } from '../helpers/groupFilter';
import { GroupingType } from '../hooks/useColumns';
import {
  getCoefficientsMeanValue,
  getDayCoefficients,
  getMonthCoefficients,
  getProposalDayCoefficients,
  getProposalMonthCoefficients,
} from './pricingFormulas';

export type GroupingParams = {
  field: RowFieldType;
  value: string;
  groupingStyle: GroupingType;
};

type CalculateMeansByGroupParams = {
  rows: PricingSheetRow[];
  groupingParams?: GroupingParams;
};

const calculateMeansByGroup = ({
  rows,
  groupingParams,
}: CalculateMeansByGroupParams) => {
  let rowsForMeanCalculations = rows
    ? rows?.filter((row: PricingSheetRow) => !row.showByOfferText)
    : [];

  if (groupingParams) {
    const groupFilter = getGroupFilterForGroup(
      groupingParams.field,
      groupingParams.value,
      groupingParams.groupingStyle,
    );
    rowsForMeanCalculations =
      rowsForMeanCalculations.filter(groupFilter);
  }

  const meanFilledCoefficientOfAllDayRows = getCoefficientsMeanValue(
    getDayCoefficients(rowsForMeanCalculations),
  );

  const meanProposalCoefficientOfAllDayRows =
    getCoefficientsMeanValue(
      getProposalDayCoefficients(rowsForMeanCalculations),
    );

  const meanFilledCoefficientOfAllMonthRows =
    getCoefficientsMeanValue(
      getMonthCoefficients(rowsForMeanCalculations),
    );

  const meanProposalCoefficientOfAllMonthRows =
    getCoefficientsMeanValue(
      getProposalMonthCoefficients(rowsForMeanCalculations),
    );

  return {
    meanFilledCoefficientOfAllDayRows,
    meanProposalCoefficientOfAllDayRows,
    meanFilledCoefficientOfAllMonthRows,
    meanProposalCoefficientOfAllMonthRows,
  };
};

export default calculateMeansByGroup;
