import React from 'react';

import IconButton from '@mui/material/IconButton';
import RedoIcon from '@mui/icons-material/Redo';

import {
  ItemIndustryPercent,
  PricingSheetRow,
} from '../../../../shared/types';

interface RedoButtonProps {
  disabled: boolean;
  redo: () =>
    | {
        rows: PricingSheetRow[];
        itemIndustryPercents: ItemIndustryPercent[];
      }
    | undefined;
  saveRowsToHistory: (
    rows: PricingSheetRow[],
    itemIndustryPercents: ItemIndustryPercent[],
  ) => void;
}

const RedoButton: React.FC<RedoButtonProps> = ({
  disabled,
  redo,
  saveRowsToHistory,
}) => {
  const onUndoClick = () => {
    const returnedRows = redo();
    if (returnedRows) {
      saveRowsToHistory(
        returnedRows.rows,
        returnedRows.itemIndustryPercents,
      );
    }
  };

  return (
    <>
      <IconButton
        aria-label="redo"
        disabled={disabled}
        color="primary"
        onClick={onUndoClick}
      >
        <RedoIcon />
      </IconButton>
    </>
  );
};

export default RedoButton;
