import { IndustryName, Nullable, OfferItem, PriceCategory } from "./types";
import { PRICE_FACTOR } from "./constants";
import { countOfferPrice } from "./countOfferPrice";
import {
  isCriticalPriceActive,
  isSurplusPriceActive,
} from "./helpers/isEquipmentActive";

type CountOfferProposalPriceFnParams = {
  item: OfferItem;
  priceCategory: PriceCategory;
  offerLeasePeriodStart: Date;
  offerLeasePeriodEnd: Date;
  industry: IndustryName;
};

const setPrice = (
  criticalPrice: Nullable<number>,
  newProposalPrice: Nullable<number>,
) => {
  if (criticalPrice && newProposalPrice) {
    return criticalPrice >= newProposalPrice ? criticalPrice : newProposalPrice;
  }
  if (newProposalPrice || newProposalPrice === 0) {
    return newProposalPrice;
  }
  if (criticalPrice) {
    return criticalPrice;
  }
  return undefined;
};

export const countOfferProposalPrice = ({
  item,
  priceCategory,
  industry,
  offerLeasePeriodStart,
  offerLeasePeriodEnd,
}: CountOfferProposalPriceFnParams): OfferItem => {
  let criticalDayPrice: number | undefined = undefined;
  let criticalMonthPrice: number | undefined = undefined;
  let isCritical: boolean = false;

  const newProposalDayPrice = countOfferPrice(
    item,
    priceCategory,
    "DAY",
    {
      start: offerLeasePeriodStart,
      end: offerLeasePeriodEnd,
    },
    industry,
  );
  const newProposalMonthPrice = countOfferPrice(
    item,
    priceCategory,
    "MONTH",
    {
      start: offerLeasePeriodStart,
      end: offerLeasePeriodEnd,
    },
    industry,
  );

  // Item has critical pricing ranges
  if (item.criticalEquipmentItems.length > 0) {
    const { minPriceDay, minPriceMonth, from, to } =
      item.criticalEquipmentItems[0];
    isCritical = isCriticalPriceActive(
      {
        start: offerLeasePeriodStart,
        end: offerLeasePeriodEnd,
      },
      { start: new Date(from), end: new Date(to) },
    );

    if (isCritical) {
      criticalDayPrice = Number(minPriceDay) * PRICE_FACTOR;
      criticalMonthPrice = Number(minPriceMonth) * PRICE_FACTOR;
    }
  }
  if (item.surplusEquipmentItem && !isCritical) {
    const {
      surplusFrom: from,
      surplusTo: to,
      changePercentage,
    } = item.surplusEquipmentItem;

    const isSurplus = isSurplusPriceActive(
      {
        start: offerLeasePeriodStart,
        end: offerLeasePeriodEnd,
      },
      { start: new Date(from), end: to ? new Date(to) : offerLeasePeriodEnd },
    );

    if (
      isSurplus &&
      newProposalDayPrice &&
      changePercentage &&
      newProposalMonthPrice
    ) {
      const discountedPriceDay = Math.round(
        newProposalDayPrice * (1 + Number(changePercentage) / 100),
      );
      const discountedPriceMonth = Math.round(
        newProposalMonthPrice * (1 + Number(changePercentage) / 100),
      );

      return {
        ...item,
        dayPrice: discountedPriceDay,
        monthPrice: discountedPriceMonth,
        proposalDayPrice: discountedPriceDay,
        proposalMonthPrice: discountedPriceMonth,
        showSurplusPercentage: true,
      };
    }
  }

  return {
    ...item,
    dayPrice: setPrice(criticalDayPrice, newProposalDayPrice),
    monthPrice: setPrice(criticalMonthPrice, newProposalMonthPrice),
    proposalDayPrice: setPrice(criticalDayPrice, newProposalDayPrice),
    proposalMonthPrice: setPrice(criticalMonthPrice, newProposalMonthPrice),
    minDayPrice: criticalDayPrice,
    minMonthPrice: criticalMonthPrice,
  };
};
