import { ReactNode, useState } from 'react';
import { OfferItem } from '../../../../../shared/types';
import { ICellRendererParams } from 'ag-grid-community';
import { Add, Clear, Edit, SmsOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  InputAdornment,
  Popover,
  TextField,
} from '@mui/material';
import { OfferItemCommentFieldKeys } from '../../../../../shared/types/offers';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DefaultTooltip from '../../Tooltips/DefaultTooltip';
import { useUpdateOfferItem } from '../../../hooks/useUpdateOfferItem';

const ANIMATION_DURATION = 200;

export type OfferCommentRendererProps = {
  buttonLabel?: ReactNode;
};

export const OfferCommentRenderer = ({
  buttonLabel,
  column,
  data,
  eGridCell,
}: ICellRendererParams<OfferItem> & OfferCommentRendererProps) => {
  const { offerItemId } = data || {};
  const field = column?.getColId() as OfferItemCommentFieldKeys;
  const currentFieldValue = field ? data?.[field] : null;
  const hasCustomButtonLabel = typeof buttonLabel !== 'undefined';

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [comment, setComment] = useState(currentFieldValue ?? '');

  // update offer sheet item mutation
  const { mutate: updateOfferItem, isPending: isLoading } =
    useUpdateOfferItem();

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setComment((comment) => comment ?? '');
    }, ANIMATION_DURATION);
  };

  const handleUpdate = () => {
    if (comment !== currentFieldValue) {
      updateOfferItem({
        id: String(offerItemId),
        item: {
          [field]: comment || null,
        },
      });
    }
    handleClose();
  };

  const handleClear = () => {
    setComment('');
    updateOfferItem({
      id: String(offerItemId),
      item: {
        [field]: null,
      },
    });
  };

  // show loading indicator when loading
  if (isLoading) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          bgcolor: '#fafafa',
          boxSizing: 'border-box',
          display: 'flex',
          height: 'inherit',
          justifyContent: 'center',
        }}
      >
        <CircularProgress thickness={4} size={16} />
      </Box>
    );
  }

  return (
    <>
      {/* EDIT / REMOVE APPROVER COMMENT */}
      {currentFieldValue && (
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: '#fff',
            boxSizing: 'border-box',
            display: 'flex',
            gap: 0.25,
            pl: 1,
            pr: 0.5,
            width: '100%',
          }}
        >
          <DefaultTooltip
            enterDelay={ANIMATION_DURATION}
            enterNextDelay={ANIMATION_DURATION}
            placement={'top'}
            title={currentFieldValue}
            sx={{
              color: 'text.primary',
              fontSize: 12,
              mr: 'auto',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <>{currentFieldValue}</>
          </DefaultTooltip>
          <DefaultTooltip
            enterDelay={ANIMATION_DURATION}
            enterNextDelay={ANIMATION_DURATION}
            title={'Muokkaa'}
            placement={'top'}
          >
            <IconButton
              size={'small'}
              sx={{
                fontSize: '1rem',
                p: 0.5,
                '.MuiSvgIcon-root': {
                  fontSize: 'inherit',
                },
              }}
              onClick={() => setAnchorEl(eGridCell)}
            >
              <Edit />
            </IconButton>
          </DefaultTooltip>
          <DefaultTooltip
            enterDelay={ANIMATION_DURATION}
            enterNextDelay={ANIMATION_DURATION}
            title={'Poista'}
            placement={'top'}
          >
            <IconButton
              color={'error'}
              size={'small'}
              sx={{
                fontSize: '1rem',
                p: 0.5,
                '.MuiSvgIcon-root': {
                  fontSize: 'inherit',
                },
              }}
              onClick={handleClear}
            >
              <Clear />
            </IconButton>
          </DefaultTooltip>
        </Box>
      )}
      {/* ADD APPROVER COMMENT*/}
      {!currentFieldValue && (
        <Button
          fullWidth
          size={'small'}
          {...(!hasCustomButtonLabel && {
            startIcon: <Add />,
          })}
          sx={{
            backgroundColor: '#fff',
            borderRadius: 0,
            height: 'inherit',
          }}
          onClick={() => setAnchorEl(eGridCell)}
        >
          {hasCustomButtonLabel ? buttonLabel : 'Lisää'}
        </Button>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transitionDuration={ANIMATION_DURATION}
        sx={{
          '.MuiPopover-paper': {
            width: 300,
          },
        }}
        onClose={handleClose}
      >
        <TextField
          autoFocus={!comment}
          fullWidth
          multiline
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          size={'small'}
          rows={3}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <SmsOutlined color={'primary'} />
              </InputAdornment>
            ),
          }}
          sx={{
            '.MuiInputBase-root': {
              borderRadius: '0.25rem 0.25rem 0 0',
            },
          }}
        />
        <Box
          component={'div'}
          sx={{
            display: 'flex',
            '& > button': {
              borderRadius: 0,
              flex: 1,
            },
          }}
        >
          <Button size={'small'} onClick={handleClose}>
            Peruuta
          </Button>
          <Button
            size={'small'}
            variant={'contained'}
            onClick={handleUpdate}
            disabled={(!currentFieldValue && !comment) || isLoading}
          >
            OK
          </Button>
        </Box>
      </Popover>
    </>
  );
};
