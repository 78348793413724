import { Dispatch, SetStateAction } from 'react';

import TextField from '@mui/material/TextField';

interface EditDiscountPercentProps {
  absolutePercentValue: number | null | undefined;
  setAbsolutePercentValue: Dispatch<
    SetStateAction<number | null | undefined>
  >;
  formatInputValue: any;
}

const EditDiscountPercent: React.FC<EditDiscountPercentProps> = ({
  absolutePercentValue,
  setAbsolutePercentValue,
  formatInputValue,
}) => {
  return (
    <>
      <TextField
        sx={{ mt: 1 }}
        size="small"
        id="percent-change"
        label="Alennus %"
        type="number"
        value={formatInputValue(absolutePercentValue)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setAbsolutePercentValue(Number(event.target.value))
        }
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
      />
    </>
  );
};

export default EditDiscountPercent;
