import {
  PricingSheetRow,
  RoundingBasis,
} from '../../../shared/types';

import { postPricingSheetItems } from '../services/pricingSheetItems';

const postSheetRows = async (
  pricingSheetId: string,
  roundingBasis: RoundingBasis,
  pricingSheetRows: any,
) => {
  const response = await postPricingSheetItems(
    pricingSheetId,
    pricingSheetRows.map((pricingSheetRow: PricingSheetRow) => ({
      pricingSheetId: pricingSheetId,
      pricingItemId: `${pricingSheetId}-${pricingSheetRow.id}`,
      dayPrice: pricingSheetRow.dayPrice
        ? Math.round(pricingSheetRow.dayPrice)
        : undefined,
      proposalDayPrice: pricingSheetRow.proposalDayPrice
        ? Math.round(pricingSheetRow.proposalDayPrice)
        : undefined,

      monthPrice: pricingSheetRow.monthPrice
        ? Math.round(pricingSheetRow.monthPrice)
        : undefined,
      proposalMonthPrice: pricingSheetRow.proposalMonthPrice
        ? Math.round(pricingSheetRow.proposalMonthPrice)
        : undefined,

      itemId: pricingSheetRow.id,
      showByOfferText: pricingSheetRow.showByOfferText,
    })),
  );
  return response;
};

export default postSheetRows;
