import React from 'react';
import Box from '@mui/material/Box';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  CoefficientColumn,
  CoefficientType,
} from '../../../../../shared/types';
import {
  calculateDayPrice,
  calculateMonthPrice,
} from '../../../../../shared/calculatePrices';
import {
  DAY_PRICE_COEFFICIENTS,
  MONTH_PRICE_COEFFICIENTS,
  PRICE_FACTOR,
} from '../../../../../shared/constants';

import {
  getCalculatedNewPriceByPercentChange,
  getCalculationValue,
  getNewInputValue,
  getNewPrice,
} from './helpers/valueHelpers';
import {
  ChangeType,
  EditItemsDialogChangeWithKey,
  EditItemsDialogPricingBasis,
  TabProps,
} from './types';

type CoefficientTabProps<Basis extends EditItemsDialogPricingBasis> =
  TabProps<Basis> & {
    priceCoefficients: EditItemsDialogChangeWithKey<
      CoefficientType<Basis>
    >[];
    setPriceCoefficients: (
      change: EditItemsDialogChangeWithKey<CoefficientType<Basis>>[],
    ) => void;
  };

const CoefficientsTab = <Basis extends EditItemsDialogPricingBasis>(
  props: CoefficientTabProps<Basis>,
) => {
  const { changeType, pricingBasis = 'DAY' } = props;
  const unit = pricingBasis === 'DAY' ? 'pv' : 'kk';
  const tabLabel =
    changeType === ChangeType.ABSOLUTE
      ? `Syötä uudet kertoimet (${unit}):`
      : `Syötä prosentuaalinen (%) muutos (${unit}):`;
  const coefficients =
    pricingBasis === 'DAY'
      ? DAY_PRICE_COEFFICIENTS
      : MONTH_PRICE_COEFFICIENTS;

  return (
    <FormControl
      component={'fieldset'}
      variant={'standard'}
      fullWidth
    >
      <FormLabel component={'legend'}>{tabLabel}</FormLabel>
      <Box sx={{ mt: 2 }}>
        <List sx={{ display: 'flex', alignItems: 'flex-start' }}>
          {coefficients.map((coefficient) => (
            <ListItem
              key={coefficient.key}
              disableGutters
              sx={{
                pl: 1,
                pr: 1,
                '&:first-of-type': {
                  pl: 0,
                },
              }}
            >
              <CoefficientListItem
                coefficient={coefficient}
                {...props}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </FormControl>
  );
};

type CoefficientListItemProps<
  Basis extends EditItemsDialogPricingBasis,
> = CoefficientTabProps<Basis> & {
  coefficient: CoefficientColumn<EditItemsDialogPricingBasis>;
};

const CoefficientListItem = <
  Basis extends EditItemsDialogPricingBasis,
>({
  changeType,
  changeTypeCommon,
  coefficient,
  depreciationPeriod,
  highlightedItem,
  priceCoefficients,
  pricingBasis,
  rentalReadyPrice,
  setPriceCoefficients,
  targetUtilRate,
}: CoefficientListItemProps<Basis>) => {
  const value = getNewInputValue(
    priceCoefficients,
    coefficient.key,
    changeType,
  );

  const rentalReadyPriceForCalculation = getCalculationValue(
    rentalReadyPrice,
    changeTypeCommon,
    highlightedItem,
    'rentalReadyPrice',
  );

  const depreciationPeriodForCalculation = getCalculationValue(
    depreciationPeriod,
    changeTypeCommon,
    highlightedItem,
    'depreciationPeriod',
  );

  const targetUtilRateForCalculation = getCalculationValue(
    targetUtilRate,
    changeTypeCommon,
    highlightedItem,
    'targetUtilRate',
  );

  const calculatePriceFn =
    pricingBasis === 'DAY' ? calculateDayPrice : calculateMonthPrice;

  const calculatedOldPrice = highlightedItem
    ? calculatePriceFn(
        Number(highlightedItem.rentalReadyPrice),
        Number(highlightedItem.depreciationPeriod),
        Number(highlightedItem.targetUtilRate) / 100,
        Number(highlightedItem[coefficient.key]) / 100,
      )
    : null;

  const calculatedNewPriceAbsolute = calculatePriceFn(
    Number(
      depreciationPeriodForCalculation ??
        highlightedItem?.rentalReadyPrice,
    ),
    Number(
      targetUtilRateForCalculation ??
        highlightedItem?.depreciationPeriod,
    ),
    Number(
      targetUtilRateForCalculation ?? highlightedItem?.targetUtilRate,
    ) / 100,
    Number(
      priceCoefficients.find(({ key }) => key === coefficient.key)
        ?.absoluteValue,
    ),
  );

  const getCalculatedNewPricePercent = () => {
    const newCoefficient = getCalculatedNewPriceByPercentChange(
      priceCoefficients,
      coefficient.key,
      highlightedItem,
    );

    return newCoefficient
      ? getNewPrice(
          calculatePriceFn,
          highlightedItem,
          rentalReadyPriceForCalculation,
          depreciationPeriodForCalculation,
          targetUtilRateForCalculation,
          newCoefficient,
        )
      : null;
  };

  const calculatedNewPricePercent = value
    ? getCalculatedNewPricePercent()
    : null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TextField
        sx={{ minWidth: 140 }}
        label={
          changeType === ChangeType.ABSOLUTE
            ? coefficient.name
            : `${coefficient.name} %-muutos`
        }
        id={`${pricingBasis.toLowerCase()}-coefficient-input-${
          coefficient.key
        }`}
        size={'small'}
        type={'number'}
        value={value ?? ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const updatedValue = event.target.value
            ? Number(event.target.value)
            : null;
          setPriceCoefficients(
            priceCoefficients.map((x) => {
              const key =
                changeType === ChangeType.ABSOLUTE
                  ? 'absoluteValue'
                  : 'percentValue';
              return x.key === coefficient.key
                ? {
                    ...x,
                    [key]: updatedValue,
                  }
                : x;
            }),
          );
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[+0-9]*',
          min: changeType === ChangeType.ABSOLUTE ? 0 : undefined,
        }}
        InputProps={{
          endAdornment:
            changeType === ChangeType.PERCENT ? (
              <InputAdornment position={'end'}>%</InputAdornment>
            ) : undefined,
        }}
      />
      <Typography sx={{ mt: 1, fontSize: '0.75rem' }}>
        Vanha hinta:{' '}
        {calculatedOldPrice
          ? `${calculatedOldPrice / PRICE_FACTOR} €`
          : '-'}
      </Typography>
      <Typography sx={{ mt: 0, fontSize: '0.75rem' }}>
        Uusi hinta:{' '}
        {changeType === ChangeType.ABSOLUTE &&
        calculatedNewPriceAbsolute
          ? `${calculatedNewPriceAbsolute / PRICE_FACTOR} €`
          : changeType === ChangeType.PERCENT &&
              calculatedNewPricePercent
            ? `${calculatedNewPricePercent / PRICE_FACTOR} €`
            : '-'}
      </Typography>
    </Box>
  );
};

export default CoefficientsTab;
