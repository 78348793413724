import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
  QueryFunction,
} from '@tanstack/react-query';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TableFilterOptions,
  TablePaginationOptions,
  TableSortOptions,
  TableSortOrder,
  TableWithPaginationDataResponse,
} from '../../../shared/types';
import { TABLE_SIZE_OPTIONS } from '../../../shared/constants';
import { hasMorePages } from '../utils/hasMorePages';
import { AxiosError } from 'axios';

export type UseDataTableQueryKey<Type, FilterOptions> = [
  string,
  TablePaginationOptions,
  TableSortOptions<Type>,
  FilterOptions,
];

export type UseDataTableQueryOptions<
  Type,
  FilterOptions extends TableFilterOptions,
> = UseQueryOptions<
  TableWithPaginationDataResponse<Type>,
  AxiosError,
  TableWithPaginationDataResponse<Type>,
  UseDataTableQueryKey<Type, FilterOptions>
>;

type UseDataTableFilteringParams<
  Type,
  FilterOptions extends TableFilterOptions,
> = {
  queryOptions: Omit<
    UseDataTableQueryOptions<Type, FilterOptions>,
    'queryKey' | 'queryFn'
  > & {
    queryKey: [string];
    queryFn: QueryFunction<
      TableWithPaginationDataResponse<Type>,
      UseDataTableQueryKey<Type, FilterOptions>
    >;
  };
  initFilterOptions?: FilterOptions;
  initPaginationOptions?: TablePaginationOptions;
  initSortOptions?: TableSortOptions<Type>;
  filterOptions?: FilterOptions;
  paginationOptions?: TablePaginationOptions;
  sortOptions?: TableSortOptions<Type>;
};

type UseDataTableFilteringReturnType<Type, FilterOptions> = {
  query: UseQueryResult<TableWithPaginationDataResponse<Type>>;
  filterOptions: FilterOptions;
  paginationOptions: TablePaginationOptions;
  sortOptions: TableSortOptions<Type>;
  // NOTE: setter functions should not be used when filter state is controlled
  setFilterOptions: Dispatch<SetStateAction<FilterOptions>>;
  setPaginationOptions: Dispatch<
    SetStateAction<TablePaginationOptions>
  >;
  setSortOptions: Dispatch<SetStateAction<TableSortOptions<Type>>>;
};

export const useDataTableFiltering = <
  Type extends { createdAt: Date },
  FilterOptions extends TableFilterOptions,
>({
  filterOptions: controlledFilterOptions,
  initFilterOptions,
  initPaginationOptions,
  initSortOptions,
  paginationOptions: controlledPaginationOptions,
  sortOptions: controlledSortOptions,
  queryOptions,
}: UseDataTableFilteringParams<
  Type,
  FilterOptions
>): UseDataTableFilteringReturnType<Type, FilterOptions> => {
  const queryClient = useQueryClient();
  // filtering
  const [filterOptions, setFilterOptions] = useState<FilterOptions>(
    // @ts-ignore
    controlledFilterOptions || initFilterOptions || {},
  );
  // pagination
  const [paginationOptions, setPaginationOptions] =
    useState<TablePaginationOptions>(
      controlledPaginationOptions ||
        initPaginationOptions || {
          limit: TABLE_SIZE_OPTIONS[1],
          offset: 0,
        },
    );
  // sorting
  const [sortOptions, setSortOptions] = useState<
    TableSortOptions<Type>
  >(
    controlledSortOptions ||
      initSortOptions || {
        direction: TableSortOrder.Desc,
        orderBy: 'createdAt',
      },
  );

  // update filter, pagination and sort options if they've been changed
  useEffect(() => {
    if (controlledFilterOptions) {
      setFilterOptions(controlledFilterOptions);
    }
  }, [controlledFilterOptions]);
  useEffect(() => {
    if (controlledPaginationOptions) {
      setPaginationOptions(controlledPaginationOptions);
    }
  }, [controlledPaginationOptions]);
  useEffect(() => {
    if (controlledSortOptions) {
      setSortOptions(controlledSortOptions);
    }
  }, [controlledSortOptions]);

  const queryOptionsWithFilters: UseDataTableQueryOptions<
    Type,
    FilterOptions
  > = useMemo(
    () => ({
      ...queryOptions,
      queryKey: [
        ...queryOptions.queryKey,
        paginationOptions,
        sortOptions,
        filterOptions,
      ],
    }),
    [paginationOptions, sortOptions, filterOptions, queryOptions],
  );

  const query = useQuery({
    placeholderData: keepPreviousData,
    staleTime: 6000,
    ...queryOptionsWithFilters,
  });

  const { data: { count = 0 } = {}, isPlaceholderData } = query;
  const { limit, offset } = paginationOptions;

  // prefetch the next page
  useEffect(() => {
    if (
      queryOptionsWithFilters.queryKey &&
      !isPlaceholderData &&
      hasMorePages({ count, limit, offset })
    ) {
      const nextPageOptions: TablePaginationOptions = {
        limit,
        offset: offset + 1,
      };
      const updatedQueryKey: UseDataTableQueryKey<
        Type,
        FilterOptions
      > = [...queryOptionsWithFilters.queryKey];
      updatedQueryKey[1] = nextPageOptions;
      queryClient.prefetchQuery({
        ...queryOptionsWithFilters,
        queryKey: updatedQueryKey,
      });
    }
  }, [
    count,
    isPlaceholderData,
    limit,
    offset,
    queryClient,
    queryOptionsWithFilters,
  ]);

  return {
    query,
    filterOptions,
    paginationOptions,
    sortOptions,
    setFilterOptions,
    setPaginationOptions,
    setSortOptions,
  };
};
