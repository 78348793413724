import { Box, Divider, Typography } from '@mui/material';
import { MiraCustomerBlocking } from '../../../../../shared/types/customer';
import { clientBlockedTexts } from '../../../helpers/getCustomerBlockedText';
import { isCustomerBlocked } from '../../../helpers/isCustomerBlocked';

type CustomerBlockingInfoProps = {
  customerBlocks: MiraCustomerBlocking;
  isErrorBlocking: boolean;
};
export const CustomerBlockingInfo: React.FC<
  CustomerBlockingInfoProps
> = ({ customerBlocks, isErrorBlocking }) => {
  const {
    blockedAll,
    blockedConfirmation,
    blockedPicking,
    blockedPacking,
    blockedInvoice,
    blockingReason,
    blockingComment,
  } = customerBlocks;

  // Failed to fetch
  if (isErrorBlocking) {
    return (
      <Typography variant="body2" color="error">
        Virhe ladattaessa asiakkaan lukituksia
      </Typography>
    );
  }

  // No blocks on customer
  if (
    !isCustomerBlocked(customerBlocks) ||
    !Boolean(blockingReason) ||
    !Boolean(blockingComment)
  ) {
    return <></>;
  }

  return (
    <>
      <Divider sx={{ mt: 1, mb: 1 }} />
      {isCustomerBlocked(customerBlocks) && (
        <>
          <Typography variant="body2" color="text.secondary">
            Esto:
          </Typography>
          {blockedAll ? (
            <Typography>{clientBlockedTexts.All}</Typography>
          ) : (
            <>
              {blockedConfirmation && (
                <Typography>
                  {clientBlockedTexts.Confirmation}
                </Typography>
              )}
              {blockedPicking && (
                <Typography>{clientBlockedTexts.Picking}</Typography>
              )}
              {blockedPacking && (
                <Typography>{clientBlockedTexts.Packing}</Typography>
              )}
              {blockedInvoice && (
                <Typography>{clientBlockedTexts.Invoice}</Typography>
              )}
            </>
          )}
        </>
      )}
      {!!blockingReason && (
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2" color="text.secondary">
            Lukitsemisen syy:
          </Typography>
          <Typography variant="body1" color="text.error">
            {blockingReason ? blockingReason : ''}
          </Typography>
        </Box>
      )}
      {!!blockingComment && (
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2" color="text.secondary">
            Lukitsemisen kommentti:
          </Typography>
          <Typography variant="body1" color="text.error">
            {blockingComment ? blockingComment : ''}
          </Typography>
        </Box>
      )}
    </>
  );
};
