import { useState, useEffect } from 'react';

const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  const setToFalse = () => {
    setIsOnline(false);
  };

  const setToTrue = () => {
    setIsOnline(true);
  };

  useEffect(() => {
    window.addEventListener('offline', setToFalse);
    window.addEventListener('online', setToTrue);
    return () => {
      window.removeEventListener('offline', setToFalse);
      window.removeEventListener('online', setToTrue);
    };
  }, []);
  return isOnline;
};

export default useOnlineStatus;
