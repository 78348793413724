import React, { useContext } from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';

import { useHistory, useParams } from 'react-router-dom';
import GenericContainer from '../../GenericContainer';

import AuditLogs from './AuditLogs';

import AdminTableContainer from './AdminTableContainer';

import RowsProvider from '../../providers/Rows/RowsProvider';
import AutomaticRuns from './AutomaticRuns';
import { Approval } from './Approval/Approval';

const Admin: React.FC = () => {
  const history = useHistory();
  const params = useParams<any>();

  const { dispatchPricingSheetRows } = useContext(RowsProvider);

  const handleChange = (_event: any, newValue: any) => {
    // clean pricingsheetrows because of undo/redo functionality
    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: [],
    });

    history.push(`/admin/${newValue}`);
  };

  return (
    <GenericContainer>
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        color="primary"
      >
        Hallintapaneeli
      </Typography>
      <TabContext value={params.tabId}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Hyväksynnät" value="1" />
            <Tab label="Hinnoiteltavat tuotteet" value="2" />
            <Tab label="Muutoslokit" value="3" />
            <Tab label="Automaattiajot" value="4" />
          </TabList>
        </Box>
        <TabPanel sx={{ p: 0 }} value="1">
          <Approval />
        </TabPanel>
        <TabPanel value="2">
          <AdminTableContainer />
        </TabPanel>
        <TabPanel value="3">
          <AuditLogs />
        </TabPanel>
        <TabPanel value="4">
          <AutomaticRuns />
        </TabPanel>
      </TabContext>
    </GenericContainer>
  );
};

export default Admin;
