import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  memo,
} from 'react';
import { Input } from '@mui/material';
import { clone } from 'lodash';
import constructNewRow from '../helpers/constructNewRow';
import { ICellEditorParams } from 'ag-grid-community';
import { PricingSheetRow } from '../../../../../shared/types';

export type TextEditorParams = ICellEditorParams<PricingSheetRow> & {
  handleUpdateRowValue?: (row: PricingSheetRow) => void;
};

const TextEditor = memo(
  forwardRef((params: TextEditorParams, ref) => {
    const {
      column,
      data,
      handleUpdateRowValue = () => null,
    } = params;
    const [value, setValue] = useState(params.value);
    const row = data ? clone(data) : undefined;
    const field = column.getColId();

    useEffect(() => {
      setValue(params.value);
    }, [params.value]);

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const newValue = event.target.value;
      setValue(newValue);
    };

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          if (!row) return;
          const newRow = constructNewRow(field, row, value);
          handleUpdateRowValue(newRow);
        },
      };
    });

    return (
      <Input
        autoFocus={true}
        value={value}
        onChange={handleChange}
        sx={{
          backgroundColor: 'transparent',
          border: 0,
          paddingLeft: '8px',
          flex: 1,
          outline: 'none',
          height: '100%',
          width: '100%',
          fontSize: 'inherit',
        }}
      />
    );
  }),
);

export default TextEditor;
