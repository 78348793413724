import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';

import PreSelectStep from '../components/Steps/PreSelectStep';
import SummaryStep from '../components/Steps/SummaryStep';
import Pricing from '../components/Pricing';
import InitialInformationStep from '../components/Steps/InitialInformationStep';

import {
  createPricingSheet,
  editLock,
  updatePricingSheetById,
} from '../services/pricingSheets';

import postSheetRows from '../helpers/postSheetRows';

import { useLocation } from 'react-router-dom';

import BackgroundProvider from '../providers/Background/BackgroundProvider';
import PricingProvider from '../providers/Pricing/PricingProvider';
import NotificationProvider from '../providers/Notification/NotificationProvider';
import RowsProvider from '../providers/Rows/RowsProvider';
import useUserContext from '../providers/User/UserProvider';

import {
  PricingSheetRow,
  PricingSheetState,
} from '../../../shared/types';

import StepperContainer from './StepperContainer';
import LoadingRenderer from '../components/Common/LoadingRenderer';
import { countProposalPrice } from '../../../shared/countProposalPrice';
import getRowsForPricingSheet from '../helpers/getRowsForPricingSheet';
import {
  currencyMultiplier,
  PRICING_TEAM_EDITING_RIGHTS_EMAIL,
} from '../../../shared/constants';
import { isInvalidPriceThreshold } from '../utils/pricingSheetRowFilters';

enum Steps {
  BackgroundInfo = 0,
  PreSelect = 1,
  Pricing = 2,
  Summary = 3,
}

const stepLabels = [
  'Asiakkaan perustiedot',
  'Hinnoiteltavat tuotteet',
  'Hinnoittelu',
  'Hyväksyntä',
];

type StepContentProps = {
  stepNumber: number;
  currentPricingSheetRows: PricingSheetRow[];
  setCurrentPricingSheetRows: React.Dispatch<
    React.SetStateAction<[] | PricingSheetRow[]>
  >;
};

let intervalId: ReturnType<typeof setInterval>;

const StepContent: React.FC<StepContentProps> = ({
  stepNumber,
  currentPricingSheetRows,
  setCurrentPricingSheetRows,
}) => {
  const steps = [
    <InitialInformationStep />,
    <PreSelectStep />,
    <Pricing
      currentPricingSheetRows={currentPricingSheetRows}
      setCurrentPricingSheetRows={setCurrentPricingSheetRows}
    />,
    <SummaryStep />,
  ];

  if (stepNumber === Steps.BackgroundInfo) {
    return steps[0];
  } else if (stepNumber === Steps.PreSelect) {
    return steps[1];
  } else if (stepNumber === Steps.Pricing) {
    return steps[2];
  } else if (stepNumber === Steps.Summary) {
    return steps[3];
  }
  return <></>;
};

const HorizontalNonLinearStepper = () => {
  const { preSelectedRows, dispatchPricingSheetRows } =
    useContext(RowsProvider);
  const { setNotification } = useContext(NotificationProvider);
  const { pricingSheetRows } = useContext(RowsProvider);
  const { hasUnsavedChanges } = useUserContext();
  const { pricingSheetId, setPricingSheetId, clearItemFilterValue } =
    useContext(PricingProvider);
  const {
    backgroundInfo,
    updateInitialInfoEnabled,
    currentBackgroundInfo,
    resetBackgroundInfo,
    setCurrentBackgroundInfo,
  } = useContext(BackgroundProvider);

  const [isLoadingCombinedRows, setIsLoadingCombinedRows] =
    useState(false);

  const [sheetState] = useState<PricingSheetState | undefined>(
    backgroundInfo?.state,
  );
  const { pathname } = useLocation();

  const initStepperState = () => {
    if (pathname.startsWith('/muokkaa-hinnastoa')) {
      if (
        pricingSheetId &&
        sheetState !== PricingSheetState.Pending
      ) {
        return Steps.Pricing;
      } else if (pricingSheetId) {
        return Steps.Summary;
      }
    }
    return Steps.BackgroundInfo;
  };

  const [activeStep, setActiveStep] = React.useState(
    initStepperState(),
  );

  // Reset background data on stepper unmount.
  useEffect(() => {
    return () => {
      resetBackgroundInfo();
    };
  }, [resetBackgroundInfo]);

  // Initial row data fetch
  useEffect(() => {
    if (!pricingSheetId) {
      return;
    }

    const setMissingPrices = (
      combinedRows: PricingSheetRow[],
    ): PricingSheetRow[] => {
      return combinedRows.map((row) => {
        const newProposalDayPrice = countProposalPrice(
          'day',
          row,
          {
            potentialTop:
              backgroundInfo.potential.top * currencyMultiplier,
          },
          backgroundInfo.industry,
        );
        const newProposalMonthPrice = countProposalPrice(
          'month',
          row,
          {
            potentialTop:
              backgroundInfo.potential.top * currencyMultiplier,
          },
          backgroundInfo.industry,
        );

        return {
          ...row,
          dayPrice: row.dayPrice || newProposalDayPrice,
          monthPrice: row.monthPrice || newProposalMonthPrice,
          proposalDayPrice:
            row.proposalDayPrice || newProposalDayPrice,
          proposalMonthPrice:
            row.proposalMonthPrice || newProposalMonthPrice,
        };
      });
    };

    (async () => {
      try {
        setIsLoadingCombinedRows(true);

        const pricingSheetRows =
          await getRowsForPricingSheet(pricingSheetId);
        const rowsWithPrices = setMissingPrices(pricingSheetRows);

        activeStep === Steps.PreSelect
          ? setPreSelectStepRows(rowsWithPrices)
          : setPricingStepRows(rowsWithPrices);
      } catch (error: any) {
        console.error(error);
        setNotification({
          type: 'SNACKBAR',
          duration: 4000,
          severity: 'error',
          message: `Hinnaston rivejä ladatessa tapahtui virhe!`,
        });
      } finally {
        setIsLoadingCombinedRows(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingSheetId]);

  const [stepButtonsDisabled, setStepButtonsDisabled] =
    useState(false);

  const [currentPricingSheetRows, setCurrentPricingSheetRows] =
    useState<PricingSheetRow[]>(preSelectedRows);

  const setPreSelectStepRows = (rows: PricingSheetRow[]) => {
    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: rows,
    });
  };

  const setPricingStepRows = (rows: PricingSheetRow[]) => {
    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: rows,
    });

    setCurrentPricingSheetRows(
      rows.filter((item) => item.preSelected),
    );
  };

  const backgroundInfoChanged =
    JSON.stringify(currentBackgroundInfo) !==
    JSON.stringify(backgroundInfo);

  const preSelectedChanged = !(
    JSON.stringify(
      currentPricingSheetRows.map((row: PricingSheetRow) => {
        return {
          id: row.id,
          showByOfferText: row.showByOfferText,
        };
      }),
    ) ===
    JSON.stringify(
      preSelectedRows.map((row: PricingSheetRow) => {
        return {
          id: row.id,
          showByOfferText: row.showByOfferText,
        };
      }),
    )
  );

  const showError = (msg?: string) => {
    setNotification({
      type: 'SNACKBAR',
      duration: 4000,
      severity: 'error',
      message: msg ? `${msg}` : `Virhe!`,
    });
  };

  const isValidValue = (
    value: string | Date | number | null | undefined,
  ) => {
    if (value !== undefined && value !== null && value !== '') {
      return true;
    }
    return false;
  };

  const requiredValuesAreValid =
    isValidValue(backgroundInfo?.customerName) &&
    isValidValue(backgroundInfo?.region) &&
    isValidValue(backgroundInfo?.industry) &&
    isValidValue(backgroundInfo?.priceSheetStartDate) &&
    isValidValue(backgroundInfo?.priceSheetEndDate) &&
    isValidValue(backgroundInfo?.priceSheetType) &&
    isValidValue(backgroundInfo?.revenue) &&
    isValidValue(backgroundInfo?.potential) &&
    isValidValue(backgroundInfo?.commitment) &&
    isValidValue(backgroundInfo?.paymentTermDays) &&
    (backgroundInfo?.priceSheetType === 'Projekti'
      ? isValidValue(backgroundInfo?.site)
      : true);

  const createOrUpdatePricingSheet = async () => {
    if (pricingSheetId) {
      try {
        await updatePricingSheetById(pricingSheetId, {
          name: backgroundInfo?.name,
          customerName: backgroundInfo?.customerName,
          pdfCustomerName: backgroundInfo?.pdfCustomerName || null,
          state: backgroundInfo?.state,
          level: backgroundInfo?.priceSheetType,
          industry: backgroundInfo?.industry,
          region: backgroundInfo?.region,
          from: backgroundInfo?.priceSheetStartDate,
          to: backgroundInfo?.priceSheetEndDate,
          revenue: Math.floor(
            backgroundInfo?.revenue * currencyMultiplier,
          ),
          potentialBottom: Math.floor(
            backgroundInfo?.potential?.bottom * currencyMultiplier,
          ),
          potentialTop: Math.floor(
            backgroundInfo?.potential?.top * currencyMultiplier,
          ),
          site: backgroundInfo?.site,
          nationWide: backgroundInfo?.nationWide,
          regionallySignificant:
            backgroundInfo?.regionallySignificant,
          strategicallySignificant:
            backgroundInfo?.strategicallySignificant,
          commitment: backgroundInfo?.commitment,
          paymentTermDays: backgroundInfo?.paymentTermDays,
          approverComment: backgroundInfo?.approverComment,
        });
        setNotification({
          type: 'SNACKBAR',
          duration: 3000,
          severity: 'success',
          message: `Hinnaston taustatiedot päivitetty!`,
        });
      } catch (error) {
        console.error(error);
        throw new Error('Hinnaston päivitys epäonnistui!');
      }
    } else {
      try {
        const newPricingSheet = await createPricingSheet({
          name: backgroundInfo?.name,
          customerName: backgroundInfo?.customerName,
          pdfCustomerName: backgroundInfo?.pdfCustomerName,
          customerId: backgroundInfo?.customerId,
          state: PricingSheetState.OkDraft,
          level: backgroundInfo?.priceSheetType,
          industry: backgroundInfo?.industry,
          region: backgroundInfo?.region,
          from: backgroundInfo?.priceSheetStartDate,
          to: backgroundInfo?.priceSheetEndDate,
          revenue: Math.floor(
            backgroundInfo?.revenue * currencyMultiplier,
          ),
          potentialBottom: Math.floor(
            backgroundInfo?.potential?.bottom * currencyMultiplier,
          ),
          potentialTop: Math.floor(
            backgroundInfo?.potential?.top * currencyMultiplier,
          ),
          site: backgroundInfo?.site,
          nationWide: backgroundInfo?.nationWide,
          regionallySignificant:
            backgroundInfo?.regionallySignificant,
          strategicallySignificant:
            backgroundInfo?.strategicallySignificant,
          commitment: backgroundInfo?.commitment,
          paymentTermDays: backgroundInfo?.paymentTermDays,
          roundingBasisSheet: backgroundInfo?.roundingBasisSheet,
          roundingBasisPdf: backgroundInfo?.roundingBasisPdf,
          pricingBasis: backgroundInfo?.pricingBasis,
          editingRights: PRICING_TEAM_EDITING_RIGHTS_EMAIL,
        });

        setPricingSheetId(newPricingSheet.id);
        setNotification({
          type: 'SNACKBAR',
          duration: 3000,
          severity: 'success',
          message: `Hinnasto ${backgroundInfo?.name} luotu luonnostilassa!`,
        });
      } catch (error) {
        console.error(error);
        throw new Error('Hinnaston luominen epäonnistui :(');
      }
    }
  };

  const showUnsavedChangesWarning = () => {
    setNotification({
      severity: 'warning',
      message: `Hinnastossa on tallentamattomia muutoksia!`,
    });
  };

  const showZeroPricesError = () => {
    setNotification({
      severity: 'error',
      message:
        'Hinnaston kaikilla tuotteita täytyy olla nollaa suurempi hinta!',
    });
  };

  const showPriceThresholdError = () => {
    setNotification({
      severity: 'error',
      message:
        'Hinnaston kaikilla tuotteilla täytyy PV->KK kertoimen olla 5-30 välillä',
    });
  };

  const showErrorNotification = () => {
    if (backgroundInfo?.potential?.bottom === 0) {
      showError(
        'Hinnaston päivitys epäonnistui. Vuokrapotentiaali ei voi olla nolla!',
      );
    }
    if (pricingSheetId && !requiredValuesAreValid) {
      showError(
        'Hinnaston päivitys epäonnistui. Vaadittuja arvoja puuttuu!',
      );
    } else if (!pricingSheetId && !requiredValuesAreValid) {
      showError(
        'Hinnaston luominen epäonnistui. Vaadittuja arvoja puuttuu!',
      );
    }
  };

  const emptyDayPricesCount = (pricingSheetRow: PricingSheetRow) => {
    return (
      !pricingSheetRow.dayPrice && !pricingSheetRow.showByOfferText
    );
  };

  const emptyDayOrMonthPricesCount = (
    pricingSheetRow: PricingSheetRow,
  ) => {
    return (
      (!pricingSheetRow.dayPrice || !pricingSheetRow.monthPrice) &&
      !pricingSheetRow.showByOfferText
    );
  };

  const emptyPriceRowsCount = preSelectedRows.filter(
    (pricingSheetRow: PricingSheetRow) =>
      backgroundInfo.pricingBasis === 'DAY'
        ? emptyDayPricesCount(pricingSheetRow)
        : emptyDayOrMonthPricesCount(pricingSheetRow),
  ).length;

  const invalidPriceThresholdRowsCount = () => {
    if (backgroundInfo.pricingBasis !== 'BOTH') {
      return 0;
    }
    return preSelectedRows.filter(isInvalidPriceThreshold).length;
  };

  const recountProposalPricesOnServer = async () => {
    const combinedRows = await getRowsForPricingSheet(pricingSheetId);

    const newRows: PricingSheetRow[] = combinedRows.map(
      (row: PricingSheetRow) => {
        const newProposalDayPrice = countProposalPrice(
          'day',
          row,
          {
            potentialTop:
              backgroundInfo.potential.top * currencyMultiplier,
          },
          backgroundInfo.industry,
        );
        const newProposalMonthPrice = countProposalPrice(
          'month',
          row,
          {
            potentialTop:
              backgroundInfo.potential.top * currencyMultiplier,
          },
          backgroundInfo.industry,
        );

        return {
          ...row,
          dayPrice:
            row.dayPrice === row.proposalDayPrice
              ? newProposalDayPrice
              : row.dayPrice,
          monthPrice:
            row.monthPrice === row.proposalMonthPrice
              ? newProposalMonthPrice
              : row.monthPrice,
          proposalDayPrice: newProposalDayPrice,
          proposalMonthPrice: newProposalMonthPrice,
        };
      },
    );

    await postSheetRows(
      pricingSheetId,
      backgroundInfo.roundingBasisSheet,
      newRows.filter(
        (pricingSheetRow: PricingSheetRow) =>
          pricingSheetRow.preSelected,
      ),
    );

    return newRows;
  };

  // Handler for linear movement. Moving one step forward
  const handleNext = async () => {
    // Prevent multiple clicks
    if (stepButtonsDisabled) {
      return;
    }
    if (hasUnsavedChanges) {
      showUnsavedChangesWarning();
    } else {
      setStepButtonsDisabled(true);
      const newActiveStep = activeStep + 1;
      switch (activeStep) {
        case Steps.BackgroundInfo:
          if (!requiredValuesAreValid) {
            showErrorNotification();
            break;
          }
          if (backgroundInfoChanged) {
            try {
              let rows = pricingSheetRows;
              if (updateInitialInfoEnabled) {
                // Recount proposal prices when parameters affecting the price is changed
                rows = await recountProposalPricesOnServer();
              }
              await createOrUpdatePricingSheet();
              setPreSelectStepRows(rows);
              setCurrentBackgroundInfo(backgroundInfo);
              setActiveStep(newActiveStep);
            } catch (error) {
              if (typeof error === 'string') {
                showError(error);
              }
            }
          } else {
            setActiveStep(newActiveStep);
          }
          break;
        case Steps.PreSelect:
          if (preSelectedChanged) {
            await saveRows();
          }
          clearItemFilterValue();
          setActiveStep(newActiveStep);
          break;
        case Steps.Pricing:
          if (emptyPriceRowsCount > 0) {
            showZeroPricesError();
          } else if (invalidPriceThresholdRowsCount() > 0) {
            showPriceThresholdError();
          } else {
            clearItemFilterValue();
            setActiveStep(newActiveStep);
          }
          break;
      }
      setStepButtonsDisabled(false);
    }
  };

  // Handler for linear movement. One step back
  const handleBack = () => {
    // Prevent multiple clicks
    if (stepButtonsDisabled) {
      return;
    }
    setStepButtonsDisabled(true);
    if (hasUnsavedChanges) {
      showUnsavedChangesWarning();
    } else {
      clearItemFilterValue();
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    setStepButtonsDisabled(false);
  };

  const saveRows = async () => {
    const response = await postSheetRows(
      pricingSheetId,
      backgroundInfo.roundingBasisSheet,
      preSelectedRows,
    );

    if (response?.status === 204) {
      setCurrentPricingSheetRows([...preSelectedRows]);
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: 'Hinnaston tuotteet päivitetty!',
      });
    } else {
      setNotification({
        severity: 'error',
        message: 'Hinnastoa tallentaessa tapahtui virhe!',
      });
    }
  };

  // Handler for nonlinear movement. When clicking step number instead of back/next -buttons
  const handleStep = (step: number) => async () => {
    // is same step
    if (activeStep === step) {
      return null;
    }

    // Prevent multiple clicks
    if (stepButtonsDisabled) {
      return;
    }
    if (hasUnsavedChanges) {
      showUnsavedChangesWarning();
    } else {
      setStepButtonsDisabled(true);

      switch (activeStep) {
        case Steps.BackgroundInfo:
          if (!requiredValuesAreValid) {
            showErrorNotification();
            break;
          }
          if (backgroundInfoChanged) {
            try {
              let rows = pricingSheetRows;
              if (updateInitialInfoEnabled) {
                // Recount proposal prices when parameters affecting the price is changed
                rows = await recountProposalPricesOnServer();
              }
              await createOrUpdatePricingSheet();
              step === Steps.PreSelect
                ? setPreSelectStepRows(rows)
                : setPricingStepRows(rows);

              setCurrentBackgroundInfo(backgroundInfo);
              setActiveStep(step);
            } catch (error) {
              if (typeof error === 'string') {
                showError(error);
              }
            }
          } else {
            setActiveStep(step);
          }
          break;
        case Steps.PreSelect:
          if (preSelectedChanged) {
            await saveRows();
          }
          clearItemFilterValue();
          setActiveStep(step);
          break;
        case Steps.Pricing:
          if (emptyPriceRowsCount > 0 && step >= 3) {
            showZeroPricesError();
          } else if (
            invalidPriceThresholdRowsCount() > 0 &&
            step >= 3
          ) {
            showPriceThresholdError();
          } else {
            clearItemFilterValue();
            setActiveStep(step);
          }
          break;
        case Steps.Summary:
          setActiveStep(step);
          break;
      }
      setStepButtonsDisabled(false);
    }
  };

  const loadStepContent = !isLoadingCombinedRows || !pricingSheetId;

  // ensure that table is empty after unmount
  useEffect(() => {
    return () => {
      dispatchPricingSheetRows({
        type: 'updatePricingSheetRows',
        newRows: [],
      });

      setPricingSheetId(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Only poll if sheet is shared
    if (
      backgroundInfo.editingRights &&
      backgroundInfo.editingRights.length > 0
    ) {
      const lockEditing = async () => {
        await editLock(pricingSheetId);
      };

      clearInterval(intervalId);
      lockEditing();
      intervalId = setInterval(async () => {
        await lockEditing();
      }, 1000 * 45);
      return () => clearInterval(intervalId);
    }
  }, [pricingSheetId, backgroundInfo.editingRights]);

  return (
    <StepperContainer>
      <Stepper nonLinear activeStep={activeStep} sx={{ pt: 1 }}>
        {stepLabels.map((label, index) => (
          <Step key={label}>
            <StepButton
              disabled={sheetState === PricingSheetState.Pending}
              color="inherit"
              onClick={handleStep(index)}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
        }}
      >
        {/* Hide back button on first step or when waiting for approval and only summary page is allowed */}
        {activeStep === Steps.BackgroundInfo ||
        sheetState === PricingSheetState.Pending ? null : (
          <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
            Edellinen
          </Button>
        )}
        <Box sx={{ flex: '1 1 auto' }} />
        {activeStep < Steps.Summary ? (
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            Seuraava
          </Button>
        ) : null}
      </Box>
      <Box sx={{ mt: 2, mb: 1 }}>
        {loadStepContent ? (
          <StepContent
            stepNumber={activeStep}
            currentPricingSheetRows={currentPricingSheetRows}
            setCurrentPricingSheetRows={setCurrentPricingSheetRows}
          />
        ) : (
          <Box sx={{ mt: 20, mb: 70 }}>
            <LoadingRenderer label="Ladataan tuotteita" />
          </Box>
        )}
      </Box>
    </StepperContainer>
  );
};

export default HorizontalNonLinearStepper;
