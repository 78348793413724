import {
  Nullable,
  PricingSheetRow,
} from '../../../../../shared/types';

import { removeTrailingZeros } from '../../../../../shared/utils/removeTrailingZeros';

const coefficientValueGetter = (
  params: { data: PricingSheetRow },
  key: string,
) => {
  const coefficient = params?.data
    ? (params?.data[key as keyof PricingSheetRow] as Nullable<number>)
    : undefined;
  return coefficient
    ? removeTrailingZeros((coefficient / 100).toFixed(2))
    : '';
};

export default coefficientValueGetter;
