import {
  Nullable,
  PricingSheetRow,
} from '../../../../../shared/types';

import { ColDef } from 'ag-grid-community';

const priceFormatter = (params: {
  value: Nullable<number>;
  data: PricingSheetRow | undefined;
  colDef: ColDef;
}) => {
  const showByOfferText = params.data?.showByOfferText;

  const field = params.colDef.field;

  if (
    showByOfferText &&
    field !== 'dayPrice' &&
    field !== 'monthPrice'
  ) {
    return '';
  }

  if (
    (field === 'proposalDayPrice' &&
      !params.data?.proposalDayPrice) ||
    (field === 'proposalMonthPrice' &&
      !params.data?.proposalMonthPrice)
  ) {
    return 'Ei voida laskea';
  }

  return showByOfferText && params.value
    ? 'Tarjouksen mukaan'
    : params.value
      ? params.value + ' €'
      : '';
};

export default priceFormatter;
