import {
  AgGridColumnView,
  CoefficientType,
  DayPriceCoefficientType,
  DayPricePercentCoefficientType,
  MonthPriceCoefficientType,
  MonthPricePercentCoefficientType,
  Nullable,
} from "./index";

export type ImportHeaderMapping = Record<ImportType, Record<string, string>>;

export enum ImportType {
  Common = "common",
  Coefficient = "coefficient",
  CriticalEquipment = "criticalEquipment",
  IndustryPercent = "industryPercent",
  SurplusEquipment = "surplusEquipment",
}

export type ImportTableColumn<T extends string = string> = {
  key: T;
  name: string;
};

export type ImportRowDataKeys<C> = C extends AgGridColumnView.ADMIN_DAY
  ? DayPriceCoefficientType
  : C extends AgGridColumnView.ADMIN_MONTH
    ? MonthPriceCoefficientType
    : C extends AgGridColumnView.ADMIN_BOTH
      ? CoefficientType
      : C extends AgGridColumnView.ADMIN_INDUSTRY_DAY
        ? DayPricePercentCoefficientType
        : C extends AgGridColumnView.ADMIN_INDUSTRY_MONTH
          ? MonthPricePercentCoefficientType
          : never;

export type ImportRowData<
  ColumnView extends AgGridColumnView = AgGridColumnView,
> = Record<"catClass", number> &
  Partial<Record<ImportRowDataKeys<ColumnView>, number | string>>;

export type ImportChangedRowValues<T = number | string> = {
  newValue: T;
  oldValue: Nullable<T>;
};

export type ImportChangedRowDetails<
  T extends string | number | symbol = string,
> = Record<T, ImportChangedRowValues>;

export type ImportChangedRow = {
  catClass: string;
  changes: ImportChangedRowDetails;
  newRow?: boolean;
};

export type ImportChangesDataMapping = Record<
  string,
  Omit<ImportChangedRow, "catClass">
>;
