import React from 'react';
import { createRoot } from 'react-dom/client';
import { processEnvs } from './config/processEnvs';

import { createUseStyles } from 'react-jss';
import './index.css';
import App from './App';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { BrowserRouter as Router } from 'react-router-dom';

import { UserProvider } from './providers/User/UserProvider';
import { NotificationProvider } from './providers/Notification/NotificationProvider';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import axios from 'axios';
import { DEFAULT_TIMEOUT } from '../../shared/constants';
import releaseTime from '../../shared/releaseTime.json';
import { formatReleaseTime } from '../../shared/formatReleaseTime';

const queryClient = new QueryClient();

// axios instance for backend API calls
export const api = axios.create({
  baseURL: processEnvs.BACKEND_URI,
  timeout: DEFAULT_TIMEOUT,
  withCredentials: true,
});

if (processEnvs.ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://6e32f9183f2a439dbea39acca0b54a1c@o318763.ingest.sentry.io/5956861',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: formatReleaseTime(releaseTime.latestAppVersion),
  });
}

const theme = createTheme({
  typography: {
    fontFamily: 'Boing',
  },
  palette: {
    primary: {
      light: '#194693',
      main: '#003287',
      dark: '#002D79',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffdf19',
      main: '#ffdc00',
      dark: '#e5c600',
      contrastText: '#fff',
    },
    text: {
      primary: '#000000de',
      secondary: '#0000008a',
    },
  },
});

export const adminTableClasses = createUseStyles({
  headerCell: {
    backgroundColor: '#e0e0e0',
  },
  childHeaderCell: {
    backgroundColor: '#fafafa',
  },

  editableCell: {
    color: '#000',
    borderRadius: '1px',
    fontWeight: 'bold',
  },
});

export const itemStatusCellClasses = createUseStyles({
  itemCellIconStyle: {
    cursor: 'pointer',
    marginTop: 6,
    fontWeight: 'bold',
    fontSize: 16,
    marginRight: 8,
    textAlign: 'center',
  },

  itemCellDeleteIconStyle: {
    marginTop: 6,
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },

  statusDialogIconStyle: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 16,
    marginRight: 8,
  },
});

export const groupedTableClasses = createUseStyles({
  headerCell: {
    backgroundColor: '#e0e0e0',
  },
  childHeaderCell: {
    backgroundColor: '#fafafa',
    fontWeight: 'bold',
  },
  priceCell: {
    fontWeight: 'bold',
    backgroundColor: '#ECF5EB',
  },
  orangePriceCell: {
    backgroundColor: theme.palette.warning.light,
    fontWeight: 'bold',
  },
  redPriceCell: {
    backgroundColor: theme.palette.error.light,
    fontWeight: 'bold',
  },
  pinkPercentCell: {
    backgroundColor: '#f48fb1',
    fontWeight: 'bold',
  },
  zeroPriceCell: {
    backgroundColor: theme.palette.warning.dark,
    fontWeight: 'bold',
  },
  criticalPriceCell: {
    backgroundColor: theme.palette.error.light,
    fontWeight: 'bold',
  },
  invalidPriceThresholdCell: {
    backgroundColor: theme.palette.warning.dark,
    fontWeight: 'bold',
  },
  editableCell: {
    backgroundColor: '#fff',
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <>
    {processEnvs.USE_STRICT_MODE ? (
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <UserProvider>
              <Router>
                <App />
              </Router>
            </UserProvider>
          </NotificationProvider>
        </ThemeProvider>
      </React.StrictMode>
    ) : (
      <>
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <UserProvider>
              <Router>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </Router>
            </UserProvider>
          </NotificationProvider>
        </ThemeProvider>
      </>
    )}
  </>,
);
