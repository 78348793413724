import { PdfQuality } from '../../../shared/types';
import { getBlobFromBuffer } from '../utils/getBlobFromBuffer';
import { downloadFile } from '../utils/downloadFile';
import { api } from '../index';

export const downloadPdf = async (
  id: string,
  pdfName: string,
  quality: PdfQuality = PdfQuality.LOW,
) => {
  const response = await api.get<BufferSource>(
    `/pricingsheets/pdf/${id}/${quality}`,
    {
      responseType: 'blob',
    },
  );
  const pdfBlob = getBlobFromBuffer(response.data);
  downloadFile(pdfBlob, pdfName);
};
