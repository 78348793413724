import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Dialog,
} from '@mui/material';
import { useContext } from 'react';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import { archivePricingSheetById } from '../../../services/pricingSheets';
import { PricingSheet } from '../../../../../shared/types';

const ArchiveDialog = ({
  pricingSheet,
  updateSheets,
  isOpenArchiveDialog,
  handleCloseArchiveDialog,
}: {
  pricingSheet: PricingSheet;
  updateSheets: () => Promise<void>;
  isOpenArchiveDialog: boolean;
  handleCloseArchiveDialog: () => void;
}) => {
  const { setNotification } = useContext(NotificationProvider);
  const archiveSheet = async (id: string) => {
    try {
      await archivePricingSheetById(id);
      await updateSheets();
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'success',
        message: `Hinnasto ${pricingSheet.name} arkistoitu onnistuneesti`,
      });
    } catch (error) {
      setNotification({
        type: 'SNACKBAR',
        duration: 6000,
        severity: 'error',
        message: `Hinnastoa ${pricingSheet.name} arkistoitaessa tapahtui virhe!`,
      });
    }
  };
  return (
    <Dialog
      disableScrollLock
      open={isOpenArchiveDialog}
      onClose={handleCloseArchiveDialog}
    >
      <DialogTitle>{'Haluatko arkistoida hinnaston?'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography color="text.primary">
            Olet arkistoimassa hinnastoa {pricingSheet.name}
            <br />
            Haluatko jatkaa?
            <br />
            <br />
          </Typography>
          Hinnaston arkistointi ei poista hinnastoa kokonaan
          järjestelmästä ja se on yhä mahdollista palauttaa.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          m: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button color="inherit" onClick={handleCloseArchiveDialog}>
          Peruuta
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            archiveSheet(pricingSheet.id);
            handleCloseArchiveDialog();
          }}
          autoFocus
        >
          Arkistoi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveDialog;
