import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { BoxProps } from '@mui/system';

const LoadingRenderer = ({
  label,
  sx,
  ...props
}: BoxProps & { label?: string }) => {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <CircularProgress />
      {label && (
        <Typography color="primary" variant="h5" sx={{ ml: 5 }}>
          {label}
        </Typography>
      )}
    </Box>
  );
};

export default LoadingRenderer;
