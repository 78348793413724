import React from 'react';

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  // Chrome requires returnValue to be set
  event.returnValue = '';
};
export const usePreventWindowUnload = (
  shouldDisplayMessage: boolean,
) => {
  React.useEffect(() => {
    if (!shouldDisplayMessage) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      return;
    }

    //if (document.location.hostname !== 'localhost') {
    window.addEventListener('beforeunload', handleBeforeUnload);
    //}
    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [shouldDisplayMessage]);
  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.location.reload();
  };
};
