import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HIERARCHY_QUERY_KEY } from '../../../shared/constants';
import { AxiosError } from 'axios';
import { api } from '../index';
import { NodeHierarchy } from '../../../shared/types/automaticRun';

type UseHierarchyParams = { flat?: boolean } & Omit<
  UseQueryOptions<
    NodeHierarchy,
    AxiosError,
    NodeHierarchy,
    (string | boolean)[]
  >,
  'queryKey' | 'queryFn'
>;

export const useHierarchy = ({
  flat = false,
  ...options
}: UseHierarchyParams = {}) =>
  useQuery({
    queryKey: [HIERARCHY_QUERY_KEY, flat],
    queryFn: async () => {
      const { data } = await api.get<NodeHierarchy>(
        `/items/hierarchy${flat ? '?flat=true' : ''}`,
      );
      return data;
    },
    staleTime: Infinity,
    retry: false,
    ...options,
  });
