import { PricingSheetRow } from '../../../../../../shared/types';

const updateShowByOfferTexts = (
  id: string,
  pricingSheetRows: PricingSheetRow[],
  showByOfferText: boolean,
) => {
  const [field, key] = id.split('|');

  let filtered: PricingSheetRow[];
  filtered = pricingSheetRows.filter((pricingSheetRow: any) => {
    if (field === 'TOGGLE_ALL') {
      return pricingSheetRow;
    } else {
      return pricingSheetRow[field] === key;
    }
  });

  const newHierarchy = pricingSheetRows.map((obj) => {
    if (filtered.includes(obj)) {
      return {
        ...obj,
        showByOfferText: showByOfferText,
      };
    } else {
      return obj;
    }
  });
  return newHierarchy;
};

export default updateShowByOfferTexts;
