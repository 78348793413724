import updateSelectedStatuses from './pricingSheetRowsActions/updateSelectedStatuses';
import updatePreSelectedStatuses from './pricingSheetRowsActions/updatePreSelectedStatuses';
import updateShowByOfferTexts from './pricingSheetRowsActions/updateShowByOfferTexts';

import updatePricingSheetRows from './pricingSheetRowsActions/updateRows';
import updateComment from './pricingSheetRowsActions/updateComment';
import updateRow from './pricingSheetRowsActions/updateRow';

import { PricingSheetRow } from '../../../../../shared/types';
import { TableFilter } from '../../../components/Pricing';

export interface PricingSheetRowReducerAction {
  type:
    | 'updateRows'
    | 'updateSelectedStatuses'
    | 'updatePreSelectedStatuses'
    | 'updatePricingSheetRows'
    | 'updateComment'
    | 'updateShowByOfferTexts'
    | 'updateRow';

  id?: string;
  newRows?: PricingSheetRow[];
  selectedStatus?: boolean;
  newPrice?: number | null;
  priceType?: string;
  comment?: string;
  row?: PricingSheetRow;
  tableType?: string;
  itemFilterValue?: string;
  activeFilters?: TableFilter[] | undefined;
}

const pricingSheetRowReducer = (
  pricingSheetRows: PricingSheetRow[],
  {
    type,
    id,
    newRows,
    selectedStatus,
    comment,
    row,
    tableType,
    itemFilterValue,
    activeFilters,
  }: PricingSheetRowReducerAction,
): PricingSheetRow[] => {
  switch (type) {
    case 'updatePricingSheetRows':
      if (newRows) {
        return updatePricingSheetRows(newRows);
      }
      return pricingSheetRows;
    case 'updateSelectedStatuses':
      if (selectedStatus === undefined) {
        selectedStatus = false;
      }
      if (id && selectedStatus !== undefined) {
        return updateSelectedStatuses(
          id,
          pricingSheetRows,
          selectedStatus,
          tableType,
          itemFilterValue,
          activeFilters,
        );
      }
      return pricingSheetRows;
    case 'updatePreSelectedStatuses':
      if (selectedStatus === undefined) {
        selectedStatus = false;
      }
      if (id && selectedStatus !== undefined) {
        return updatePreSelectedStatuses(
          id,
          pricingSheetRows,
          selectedStatus,
          itemFilterValue,
        );
      }
      return pricingSheetRows;
    case 'updateShowByOfferTexts':
      if (id && selectedStatus !== undefined) {
        return updateShowByOfferTexts(
          id,
          pricingSheetRows,
          selectedStatus ?? false,
        );
      }
      return pricingSheetRows;
    case 'updateComment':
      if (id && comment) {
        return updateComment(id, pricingSheetRows, comment);
      }
      return pricingSheetRows;
    case 'updateRow':
      if (id && row) {
        return updateRow(id, pricingSheetRows, row);
      }
      return pricingSheetRows;

    default:
      return pricingSheetRows;
  }
};

export default pricingSheetRowReducer;
