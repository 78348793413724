import React, { useContext } from 'react';
import { AgGridColumnView } from '../../../../../shared/types';
import {
  ImportType,
  ImportChangesDataMapping,
} from '../../../../../shared/types/import';
import RowsProvider from '../../../providers/Rows/RowsProvider';
import { HeaderMapperTable } from './ExcelImportHeaderMapper';
import { ExcelImportDialogCommonProps } from './ExcelImportDialog';
import {
  DAY_PRICE_COEFFICIENTS,
  MONTH_PRICE_COEFFICIENTS,
  PRICE_COEFFICIENTS,
} from '../../../../../shared/constants';
import { getUpdatedRows } from './helpers';
import ExcelImportDialogBase, {
  DialogSubLabel,
} from './ExcelImportDialogBase';

type CoefficientColumnView =
  | AgGridColumnView.ADMIN_DAY
  | AgGridColumnView.ADMIN_MONTH
  | AgGridColumnView.ADMIN_BOTH;

const ExcelImportCoefficientDialog = ({
  onClose,
  open,
}: ExcelImportDialogCommonProps) => {
  const { pricingSheetRows, dispatchPricingSheetRows } =
    useContext(RowsProvider);

  const importType = ImportType.Coefficient;

  const changesTableColumns = PRICE_COEFFICIENTS.map(
    ({ key, name }) => ({
      key,
      name,
    }),
  );

  const handleSave = (
    changesDataMapping: ImportChangesDataMapping,
  ) => {
    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: getUpdatedRows(pricingSheetRows, changesDataMapping),
    });
    onClose();
  };

  return (
    <ExcelImportDialogBase<CoefficientColumnView>
      changesTableColumns={changesTableColumns}
      importType={importType}
      onClose={onClose}
      onSave={handleSave}
      open={open}
      rowData={pricingSheetRows}
      title={'Tuo hintakertoimet Excelistä'}
      renderHeaderMapper={(disabled) =>
        [
          {
            label: 'Päiväkertoimet',
            columns: DAY_PRICE_COEFFICIENTS,
          },
          {
            label: 'Kuukausikertoimet',
            columns: MONTH_PRICE_COEFFICIENTS,
          },
        ].map(({ label, columns }) => {
          return (
            <React.Fragment key={label}>
              <DialogSubLabel variant={'body1'}>
                {label}
              </DialogSubLabel>
              <HeaderMapperTable
                columns={columns.map(({ key, name }) => ({
                  key,
                  name,
                }))}
                disabled={disabled}
                importType={importType}
              />
            </React.Fragment>
          );
        })
      }
    />
  );
};

export default ExcelImportCoefficientDialog;
