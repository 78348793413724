import {
  Alert,
  AlertColor,
  AlertProps,
  alpha,
  Typography,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import DefaultTooltip from '../Tooltips/DefaultTooltip';

type StateAlertProps = AlertProps & {
  disabled?: boolean;
  label: string;
  infoTooltipColor?: AlertColor;
  infoTooltipText?: string | null;
  size?: 'small' | 'normal';
  subLabel?: string;
};

export const StateAlert = ({
  disabled,
  label,
  infoTooltipColor,
  infoTooltipText,
  subLabel,
  severity,
  size = 'normal',
  ...props
}: StateAlertProps) => (
  <Alert
    severity={severity}
    {...props}
    sx={{
      alignItems: 'center',
      px: size === 'normal' ? 1.5 : 0.5,
      py: size === 'normal' ? 0.75 : 0.25,
      ...props.sx,
      ...(disabled && {
        backgroundColor: alpha('#000', 0.12),
        color: 'text.secondary',
        opacity: 0.4,
        transition:
          'opacity 300ms ease-in-out, background-color 300ms ease-in-out',
      }),
      '.MuiAlert-icon': {
        mr: 1,
        p: 0,
        ...(disabled && {
          color: 'text.secondary',
          transition: 'color 300ms ease-in-out',
        }),
      },
      '.MuiSvgIcon-root': {
        fontSize: size === 'normal' ? 18 : 15,
      },
      '.MuiAlert-message': {
        // @ts-ignore
        ...(props.sx?.['.MuiAlert-message'] || {}),
        alignItems: 'center',
        columnGap: 0.5,
        display: 'flex',
        flex: 1,
        fontSize: size === 'normal' ? 14 : 11,
        m: 0,
        p: 0,
        '& span': {
          display: 'inline-flex',
          whiteSpace: 'nowrap',
        },
      },
    }}
  >
    <Typography
      component={'span'}
      fontSize={'inherit'}
      sx={{ mr: 'auto' }}
    >
      {label}
    </Typography>
    {subLabel && (
      <Typography
        component={'span'}
        sx={{
          fontSize: size === 'normal' ? 11 : 10,
        }}
      >
        ({subLabel})
      </Typography>
    )}
    {infoTooltipText && (
      <DefaultTooltip title={infoTooltipText} placement={'top'}>
        <InfoOutlined
          color={infoTooltipColor || severity}
          sx={{
            cursor: 'help',
            ml: 0.5,
          }}
        />
      </DefaultTooltip>
    )}
  </Alert>
);
