import { DataTableRowProps } from '../../../components/Common/DataTable/DataTableRow';
import {
  DatabaseOfferWithApprovalRequest,
  OfferDateFields,
  OfferRejectionReason,
  OfferRevision,
  OffersTableTabState,
  OfferState,
  OfferStep,
} from '../../../../../shared/types/offers';
import { convertDatabaseOfferDatesToDate } from '../../../helpers/convertDatabaseOfferDates';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import useUserContext from '../../../providers/User/UserProvider';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  createOfferRevision,
  updateOfferSheet,
} from '../../../services/offerSheets';
import {
  INCOMPLETE_OFFER_STATES,
  OFFER_SHEET_QUERY_KEY,
  PRICE_CATEGORIES,
} from '../../../../../shared/constants';
import { getCustomerTypeLabel } from '../../../helpers/getCustomerTypeLabel';
import { formatDateTolocaleDateString } from '../../../utils/formatDateTimes';
import { formatPricingBasis } from '../../../helpers/formatPricingBasis';
import {
  ApprovalRequestState,
  SheetType,
} from '../../../../../shared/types';
import {
  alpha,
  Button,
  ButtonProps,
  CircularProgress,
  DialogContentText,
  SvgIcon,
} from '@mui/material';
import {
  AccessTime,
  AltRouteOutlined,
  CopyAll,
  DeleteOutlined,
  DoNotDisturb,
  Edit,
  ForwardToInboxOutlined,
  Inventory2Outlined,
  InventoryOutlined,
  SettingsBackupRestore,
  SmsOutlined,
  TaskAltOutlined,
  UnarchiveOutlined,
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { RenderComponentProps } from './OfferSheetsTable';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import { RejectedOfferDialog } from './RejectedOfferDialog';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { ConfirmationDialog } from '../../../components/Common/ConfirmationDialog';
import { Link, useHistory } from 'react-router-dom';
import { useOfferStore } from '../../../stores/offerStore';
import { StateAlert } from '../../../components/Common/StateAlert';
import {
  getOfferStateIcon,
  getOfferStateLabel,
  getOfferStateSeverity,
} from '../helpers';
import { OfferDatesSelector } from '../OfferDatesSelector';
import { useUpdateOffer } from '../../../hooks/useUpdateOffer';
import { useUpdateOfferApprovalRequest } from '../../../hooks/useUpdateOfferApprovalRequest';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';

export type RowDetailActionsProps = {
  actionButton: (label: string, props: ButtonProps) => ReactNode;
  archiveAction: (props?: ButtonProps) => ReactNode;
  createdByUser: boolean;
  item: DatabaseOfferWithApprovalRequest;
  loading: boolean;
  removeAction: (props?: ButtonProps) => ReactNode;
  createRevisionAction: (
    label: string,
    props?: ButtonProps,
  ) => ReactNode;
  setActiveTab: (state: OffersTableTabState) => void;
  updateOffer: UseMutateAsyncFunction<
    Awaited<ReturnType<typeof updateOfferSheet>>,
    unknown,
    Parameters<typeof updateOfferSheet>[0]
  >;
};

type OffersTableRowDetailsProps = RenderComponentProps<
  NonNullable<
    DataTableRowProps<DatabaseOfferWithApprovalRequest>['renderRowDetails']
  >
>;

export const OffersTableRowDetails: OffersTableRowDetailsProps = (
  item,
  { activeTab, loading, setActiveTab },
) => {
  const {
    approvalRequests,
    archivedAt,
    customerRejectionComment,
    customerRejectionReason,
    id,
    name,
    offerLeasePeriodEnd,
    original,
    revision,
    state,
    updatedAt,
    userEmail: offerCreatedBy,
  } = convertDatabaseOfferDatesToDate(item);

  const queryClient = useQueryClient();
  const { isAdmin, userEmail } = useUserContext();
  const history = useHistory();
  const { setNotification } = useContext(NotificationProvider);
  const setActiveStep = useOfferStore((state) => state.setActiveStep);

  const [newRevisionDates, setNewRevisionDates] = useState<
    OfferDateFields<Date>
  >({
    offerLeasePeriodStart: new Date(),
    offerLeasePeriodEnd: isAfter(offerLeasePeriodEnd, new Date())
      ? offerLeasePeriodEnd
      : new Date(),
    offerValidityStart: new Date(),
    offerValidityEnd: addDays(new Date(), 14),
  });
  // dialogs
  const [revisionDialogOpen, setRevisionDialogOpen] = useState(false);
  const [expiredRevisionDialogOpen, setExpiredRevisionDialogOpen] =
    useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  // update offer sheet mutation
  const { mutateAsync: updateOffer, isPending: isUpdatingOffer } =
    useUpdateOffer({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [OFFER_SHEET_QUERY_KEY],
          refetchType: 'all',
        });
      },
    });
  // update offer approval request mutation
  const {
    mutate: updateApprovalRequest,
    isPending: isUpdatingRequest,
  } = useUpdateOfferApprovalRequest();
  // create new offer sheet revision mutation
  const { mutate: createRevision, isPending: isCreatingRevision } =
    useMutation({
      mutationFn: createOfferRevision,
      onSuccess: (data) => {
        // create cache for created revision
        queryClient.setQueryData(
          [OFFER_SHEET_QUERY_KEY, `${data.id}`],
          data,
        );
        setNotification({
          type: 'SNACKBAR',
          duration: 3000,
          severity: 'success',
          message: `Tarjous ${data.name} (#${data.id}) luotu luonnostilassa!`,
        });
        // navigate to offer pricing page
        setActiveStep(OfferStep.Pricing);
        history.push({
          pathname: `/tarjous/${data.id}`,
        });
      },
      onError: (error) => {
        console.error(error);
        setNotification({
          type: 'SNACKBAR',
          duration: 3000,
          severity: 'error',
          message: `Palvelimella tapahtui virhe! Uuden revision luonti tarjouksesta epäonnistui!`,
        });
      },
    });

  const createdByUser = offerCreatedBy === userEmail;
  const allowedToArchiveAndRemove = createdByUser || isAdmin;
  const expiredOffer = OfferState.Expired === state;

  const isLoading =
    loading ||
    isUpdatingOffer ||
    isUpdatingRequest ||
    isCreatingRevision;

  // archives the offer
  const handleArchive = () => {
    updateOffer(
      {
        id: String(id),
        item: { archivedAt: new Date() },
      },
      {
        onSuccess: () => {
          setNotification({
            type: 'SNACKBAR',
            duration: 3000,
            severity: 'success',
            message: 'Tarjous arkistoitu!',
          });
        },
      },
    );
    // lock related approval request if it exists
    const approvalRequestId = approvalRequests[0]?.id;
    if (approvalRequestId) {
      updateApprovalRequest({
        id: String(id),
        approvalId: String(approvalRequestId),
        item: {
          lockedBy: userEmail,
          lockedReason: 'archived',
          state: ApprovalRequestState.Locked,
        },
      });
    }
  };

  // removes the offer
  const handleRemove = () => {
    updateOffer(
      {
        id: String(id),
        item: { removedAt: new Date() },
      },
      {
        onSuccess: () => {
          setNotification({
            type: 'SNACKBAR',
            duration: 3000,
            severity: 'warning',
            message: 'Tarjous poistettu!',
          });
        },
      },
    );
    // lock related approval request if it exists
    const approvalRequestId = approvalRequests[0]?.id;
    if (approvalRequestId) {
      updateApprovalRequest({
        id: String(id),
        approvalId: String(approvalRequestId),
        item: {
          lockedBy: userEmail,
          lockedReason: 'removed',
          state: ApprovalRequestState.Locked,
        },
      });
    }
  };

  const actionButton: RowDetailActionsProps['actionButton'] =
    useCallback(
      (label: string, props: ButtonProps) => (
        <Button
          fullWidth
          size={'small'}
          variant={'contained'}
          disabled={isLoading}
          endIcon={
            isLoading && <CircularProgress thickness={4} size={16} />
          }
          {...props}
        >
          {label}
        </Button>
      ),
      [isLoading],
    );

  const removeAction: RowDetailActionsProps['removeAction'] =
    useCallback(
      (props = {}) =>
        actionButton('Poista', {
          color: 'error',
          disabled: !allowedToArchiveAndRemove,
          startIcon: <DeleteOutlined />,
          ...props,
          onClick: () => setRemoveDialogOpen(true),
        }),
      [actionButton, allowedToArchiveAndRemove],
    );

  const archiveAction: RowDetailActionsProps['archiveAction'] =
    useCallback(
      (props = {}) =>
        actionButton('Arkistoi', {
          color: 'warning',
          disabled: !allowedToArchiveAndRemove,
          variant: 'outlined',
          startIcon: <UnarchiveOutlined />,
          ...props,
          onClick: () => setArchiveDialogOpen(true),
        }),
      [actionButton, allowedToArchiveAndRemove],
    );

  const createRevisionAction: RowDetailActionsProps['createRevisionAction'] =
    useCallback(
      (label, props = {}) =>
        actionButton(label, {
          ...props,
          onClick: () => setRevisionDialogOpen(true),
        }),
      [actionButton],
    );

  const offerDetailActions = useCallback(() => {
    const props: RowDetailActionsProps = {
      actionButton,
      archiveAction,
      createdByUser,
      createRevisionAction,
      item,
      loading: isUpdatingOffer,
      setActiveTab,
      removeAction,
      updateOffer,
    };
    switch (activeTab) {
      // SENT TAB ACTIONS
      case OffersTableTabState.Sent:
        return <SentTabRowDetailActions {...props} />;
      // INCOMPLETE TAB ACTIONS
      case OffersTableTabState.Incomplete:
        return <IncompleteTabRowDetailActions {...props} />;
      // EXPIRED TAB ACTIONS
      case OffersTableTabState.Expired:
        return <ExpiredTabRowDetailActions {...props} />;
      // ARCHIVED TAB ACTIONS
      case OffersTableTabState.Archived:
        return <ArchivedTabRowDetailActions {...props} />;
    }
  }, [
    actionButton,
    activeTab,
    archiveAction,
    createdByUser,
    createRevisionAction,
    isUpdatingOffer,
    item,
    removeAction,
    setActiveTab,
    updateOffer,
  ]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: alpha('#f2f2f2', 0.12),
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
        }}
      >
        {/* INFO */}
        <OfferDetailInformation item={item} />
        {/* ACTIONS */}
        <Box
          sx={{
            backgroundColor: '#fff',
            borderLeft: `1px solid ${alpha('#000', 0.12)}`,
            boxShadow: '-1px 0 4px -4px #333',
            p: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              mb: 2,
            }}
          >
            {!archivedAt && (
              <DetailLabelItem
                icon={AccessTime}
                label={'Tarjous päivitetty:'}
                value={formatDateTolocaleDateString(updatedAt)}
              />
            )}
            {archivedAt && (
              <DetailLabelItem
                icon={Inventory2Outlined}
                label={'Tarjous arkistoitu:'}
                value={formatDateTolocaleDateString(archivedAt)}
              />
            )}
            {revision && (
              <RevisionDetailLabelItem
                label={'Tarjouksesta tehty uusi versio:'}
                item={revision}
              />
            )}
            {original && (
              <RevisionDetailLabelItem
                label={'Tarjouksen aikaisempi versio:'}
                item={original}
              />
            )}
            {state === OfferState.Rejected && (
              <>
                {customerRejectionReason && (
                  <DetailLabelItem
                    icon={DoNotDisturb}
                    iconColor={'error'}
                    label={'Hylkäyksen syy:'}
                    value={
                      OfferRejectionReason[customerRejectionReason]
                    }
                  />
                )}
                {customerRejectionComment && (
                  <DetailLabelItem
                    icon={SmsOutlined}
                    iconColor={'error'}
                    label={'Hylkäyksen kommentti:'}
                    value={customerRejectionComment}
                  />
                )}
              </>
            )}
          </Box>
          <Divider sx={{ my: 2 }} />
          {offerDetailActions()}
        </Box>
      </Box>
      {/* ARCHIVE DIALOG */}
      <ConfirmationDialog
        open={archiveDialogOpen}
        confirmButtonLabel={'Arkistoi'}
        confirmButtonProps={{
          color: 'warning',
          startIcon: <Inventory2Outlined />,
        }}
        onCancel={() => setArchiveDialogOpen(false)}
        onConfirm={handleArchive}
        title={'Tarjouksen arkistointi'}
      >
        <Typography variant={'body1'} mb={2}>
          Olet arkistoimassa tarjouksen <i>"{name}"</i>
          <br />
          Haluatko jatkaa?
        </Typography>
        <DialogContentText>
          Tarjouksen arkistointi ei poista tarjousta kokonaan
          järjestelmästä ja se on yhä mahdollista palauttaa.
        </DialogContentText>
      </ConfirmationDialog>
      {/* REMOVE DIALOG */}
      <ConfirmationDialog
        open={removeDialogOpen}
        confirmButtonLabel={'Poista'}
        confirmButtonProps={{
          color: 'error',
          startIcon: <DeleteOutlined />,
        }}
        onCancel={() => setRemoveDialogOpen(false)}
        onConfirm={handleRemove}
        title={'Tarjouksen poistaminen'}
      >
        <Typography variant={'body1'} mb={2}>
          Olet poistamassa tarjouksen <i>"{name}"</i>
          <br />
          Haluatko jatkaa?
        </Typography>
        <Typography variant={'body1'} fontWeight={'bold'}>
          Poistettua tarjousta ei ole enää mahdollista palauttaa!
        </Typography>
      </ConfirmationDialog>
      {/* CREATE REVISION DIALOG */}
      <ConfirmationDialog
        open={revisionDialogOpen}
        confirmButtonLabel={'Kyllä'}
        loading={loading || isCreatingRevision}
        onCancel={() => setRevisionDialogOpen(false)}
        onConfirm={() =>
          expiredOffer
            ? setExpiredRevisionDialogOpen(true)
            : createRevision({ id: String(id) })
        }
        title={'Uusi revisio'}
      >
        <DialogContentText>
          {state === OfferState.Rejected && (
            <>
              Tarjousta <i>"{name}"</i> ei voi enää muokata.{' '}
            </>
          )}
          Haluatko luoda uuden revision tarjouksesta? Vanha tarjous
          arkistoidaan.
        </DialogContentText>
      </ConfirmationDialog>
      {/* CREATE REVISION DIALOG (OFFER EXPIRED) */}
      <ConfirmationDialog
        open={expiredRevisionDialogOpen}
        loading={loading || isCreatingRevision}
        onCancel={() => {
          setRevisionDialogOpen(false);
          setExpiredRevisionDialogOpen(false);
        }}
        onConfirm={() => {
          createRevision({ id: String(id), ...newRevisionDates });
        }}
        title={'Vanhentunut tarjous'}
      >
        <DialogContentText>
          Tarjous <i>"{name}"</i> on vanhentunut. Anna uudelle
          revisiolle uudet alkamis ja päättymis päivämäärät.
        </DialogContentText>
        <OfferDatesSelector
          offerLeasePeriodLabel={
            <Typography mt={2}>Vuokra-aika *</Typography>
          }
          offerValidityLabel={
            <Typography mt={2}>
              Tarjouksen voimassaoloaika *
            </Typography>
          }
          initialValues={newRevisionDates}
          onChange={setNewRevisionDates}
        />
      </ConfirmationDialog>
    </>
  );
};

type DetailLabelItemProps = {
  icon: typeof SvgIcon;
  iconColor?: SvgIconProps['color'];
  label: string;
  value: ReactNode;
};
const DetailLabelItem = ({
  icon,
  iconColor = 'primary',
  label,
  value,
}: DetailLabelItemProps) => {
  return (
    <Typography
      component={'div'}
      display={'flex'}
      fontSize={'inherit'}
      gap={1}
      lineHeight={1.2}
      whiteSpace={'nowrap'}
    >
      <SvgIcon
        component={icon}
        fontSize={'small'}
        color={iconColor}
        sx={{
          transform: 'translateY(-1px)',
        }}
      />
      <b>{label}</b>
      <Typography
        component={'span'}
        fontSize={'inherit'}
        lineHeight={1.25}
        whiteSpace={'initial'}
      >
        {value}
      </Typography>
    </Typography>
  );
};

type RevisionDetailLabelItemProps = {
  item: OfferRevision;
  label: string;
};
const RevisionDetailLabelItem = ({
  item,
  label,
}: RevisionDetailLabelItemProps) => {
  const { id, removedAt, state } = item;
  const severity = removedAt ? 'error' : getOfferStateSeverity(state);
  const stateLabel = removedAt
    ? 'Poistettu'
    : getOfferStateLabel(state);
  const icon = removedAt ? DeleteOutlined : getOfferStateIcon(state);
  const value = !removedAt ? (
    <Link to={`/tarjous/${id}/tarkastele`}>#{id}</Link>
  ) : (
    `#${id}`
  );
  return (
    <DetailLabelItem
      icon={AltRouteOutlined}
      label={label}
      value={
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
            transform: 'translateY(-2px)',
          }}
        >
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 'bold',
              lineHeight: 1.25,
              a: {
                color: 'primary.main',
                '&:hover': {
                  color: 'primary.light',
                },
              },
            }}
          >
            {value}
          </Typography>
          <StateAlert
            size={'small'}
            severity={severity}
            label={`(${stateLabel})`}
            icon={
              <SvgIcon
                sx={{ fontSize: 'inherit' }}
                component={icon}
              />
            }
          />
        </Box>
      }
    />
  );
};

/* OFFER DETAIL INFORMATION */

const OfferDetailInformation = ({
  item,
}: {
  item: DatabaseOfferWithApprovalRequest;
}) => {
  const {
    customerId,
    customerType,
    industry,
    offerLeasePeriodEnd,
    offerLeasePeriodStart,
    offerValidityEnd,
    offerValidityStart,
    pricingBasis,
    priceCategory,
  } = convertDatabaseOfferDatesToDate(item);
  const priceCategoryLabel = PRICE_CATEGORIES[priceCategory];

  const infoColumns: Record<string, string>[] = [
    {
      'Asiakkaan ID': customerId || '-',
      'Asiakkaan tyyppi': getCustomerTypeLabel(customerType),
      Toimiala: industry,
      Hintakategoria: priceCategoryLabel.substring(
        priceCategoryLabel.indexOf(String(priceCategory + 1)),
      ),
    },
    {
      Voimassaoloaika: `${formatDateTolocaleDateString(
        offerValidityStart,
      )} - ${formatDateTolocaleDateString(offerValidityEnd)}`,
      'Vuokra-aika': `${formatDateTolocaleDateString(
        offerLeasePeriodStart,
      )} - ${formatDateTolocaleDateString(offerLeasePeriodEnd)}`,
      'Tarjouksen tyyppi': formatPricingBasis(
        pricingBasis,
        SheetType.OFFER,
      ),
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        p: 2,
        '& > *': {
          flex: 1,
        },
      }}
    >
      {/* MAP COLUMNS */}
      {infoColumns.map((column, index) => {
        return (
          <Box
            key={index}
            sx={{
              alignContent: 'start',
              columnGap: 2,
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              rowGap: 0.5,
            }}
          >
            {/* MAP INFO FIELDS */}
            {Object.entries(column).map(([label, value]) => {
              return (
                <React.Fragment key={label}>
                  <Typography variant={'body2'} fontWeight={'bold'}>
                    {label}
                  </Typography>
                  <Typography variant={'body2'}>{value}</Typography>
                </React.Fragment>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

/* 'SENT' TAB ACTIONS */

const SentTabRowDetailActions = ({
  actionButton,
  archiveAction,
  createdByUser,
  createRevisionAction,
  item,
  loading,
  updateOffer,
}: RowDetailActionsProps) => {
  const { setNotification } = useContext(NotificationProvider);
  const [rejectedOfferDialogOpen, setRejectedOfferDialogOpen] =
    useState(false);
  const { id, state } = item;

  const renderActions = useCallback(() => {
    switch (state) {
      // 'SENT' STATE ACTIONS
      case OfferState.Sent:
        return (
          <>
            <Typography variant={'body1'} fontSize={14}>
              Aseta tarjouksen tila asiakkaan palautteen mukaan
            </Typography>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
              }}
            >
              {/* 'CUSTOMER APPROVED' ACTION */}
              {actionButton('Hyväksytty', {
                startIcon: <TaskAltOutlined />,
                disabled: !createdByUser,
                onClick: () =>
                  updateOffer(
                    {
                      id: String(id),
                      item: { state: OfferState.Approved },
                    },
                    {
                      onSuccess: () => {
                        setNotification({
                          type: 'SNACKBAR',
                          duration: 3000,
                          severity: 'success',
                          message: 'Tarjouksen tila päivitetty!',
                        });
                      },
                    },
                  ),
              })}
              {/* 'CUSTOMER DECLINED' ACTION */}
              {actionButton('Hylätty', {
                color: 'error',
                disabled: !createdByUser,
                startIcon: <DoNotDisturb />,
                onClick: () => setRejectedOfferDialogOpen(true),
              })}
            </Box>
            <Divider />
            {/* ARCHIVE ACTION */}
            {archiveAction({
              sx: {
                ml: 'auto',
                width: 'calc(50% - 0.5rem)',
              },
            })}
            <RejectedOfferDialog
              loading={loading}
              open={rejectedOfferDialogOpen}
              onClose={() => setRejectedOfferDialogOpen(false)}
              onConfirm={(rejectionReason, comment) => {
                updateOffer(
                  {
                    id: String(id),
                    item: {
                      state: OfferState.Rejected,
                      customerRejectionComment: comment || null,
                      customerRejectionReason: rejectionReason,
                    },
                  },
                  {
                    onSuccess: () =>
                      setRejectedOfferDialogOpen(false),
                  },
                );
              }}
            />
          </>
        );
      // 'APPROVED' STATE ACTIONS
      case OfferState.Approved:
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 2,
            }}
          >
            {/* ORDER ACTION */}
            {actionButton('Tilaa', {
              disabled: true,
              startIcon: <InventoryOutlined />,
              onClick: () => null,
            })}
            {/* ARCHIVE ACTION */}
            {archiveAction()}
          </Box>
        );
      // 'REJECTED' STATE ACTIONS
      case OfferState.Rejected:
        return (
          <>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
              }}
            >
              {/* CREATE REVISION ACTION */}
              {createRevisionAction('Muokkaa', {
                disabled: !createdByUser,
                startIcon: <Edit />,
              })}
              {/* ARCHIVE ACTION */}
              {archiveAction()}
            </Box>
          </>
        );
      default:
        return null;
    }
  }, [
    state,
    actionButton,
    createdByUser,
    archiveAction,
    loading,
    rejectedOfferDialogOpen,
    createRevisionAction,
    updateOffer,
    id,
    setNotification,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {renderActions()}
    </Box>
  );
};

/* 'INCOMPLETE' TAB ACTIONS */

const IncompleteTabRowDetailActions = ({
  actionButton,
  archiveAction,
  createdByUser,
  item,
  removeAction,
  setActiveTab,
  updateOffer,
}: RowDetailActionsProps) => {
  const { setNotification } = useContext(NotificationProvider);
  const [returnToDraftDialogOpen, setReturnToDraftDialogOpen] =
    useState(false);
  const { id, name, state } = item;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {/* 'MARK AS SENT' ACTION */}
      {state === OfferState.ReadyForSending && (
        <>
          {actionButton('Aseta lähetetyksi', {
            startIcon: <ForwardToInboxOutlined />,
            disabled: !createdByUser,
            onClick: () =>
              updateOffer(
                {
                  id: String(id),
                  item: { state: OfferState.Sent },
                },
                {
                  onSuccess: () => {
                    setActiveTab(OffersTableTabState.Sent);
                    setNotification({
                      type: 'SNACKBAR',
                      duration: 3000,
                      severity: 'success',
                      message: 'Tarjouksen tila päivitetty!',
                    });
                  },
                },
              ),
          })}
          {actionButton('Palauta luonnokseksi', {
            startIcon: <SettingsBackupRestore />,
            disabled: !createdByUser,
            variant: 'outlined',
            onClick: () => setReturnToDraftDialogOpen(true),
          })}
          <Divider />
          {/* RETURN TO DRAFT DIALOG */}
          <ConfirmationDialog
            open={returnToDraftDialogOpen}
            confirmButtonLabel={'Palauta'}
            confirmButtonProps={{
              startIcon: <SettingsBackupRestore />,
            }}
            onCancel={() => setReturnToDraftDialogOpen(false)}
            onConfirm={() => {
              updateOffer(
                {
                  id: String(id),
                  item: {
                    state: OfferState.Draft,
                    approvalApproverComment: null,
                  },
                },
                {
                  onSuccess: () => {
                    setReturnToDraftDialogOpen(false);
                    setNotification({
                      type: 'SNACKBAR',
                      duration: 3000,
                      severity: 'success',
                      message: 'Tarjouksen tila päivitetty!',
                    });
                  },
                },
              );
            }}
            title={'Palauta luonnokseksi'}
          >
            <Typography variant={'body1'} mb={2}>
              Olet palauttamassa tarjouksen <i>"{name}"</i>{' '}
              luonnostilaan.
              <br />
              Haluatko jatkaa?
            </Typography>
            <DialogContentText>
              Tarjouksen hyväksymisprosessi pitää mahdollisesti
              läpikäydä uudelleen.
            </DialogContentText>
          </ConfirmationDialog>
        </>
      )}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 2,
        }}
      >
        {/* REMOVE ACTION */}
        {removeAction()}
        {/* ARCHIVE ACTION */}
        {archiveAction()}
      </Box>
    </Box>
  );
};

/* 'ARCHIVED' TAB ACTIONS */

const ArchivedTabRowDetailActions = ({
  actionButton,
  item,
  removeAction,
  updateOffer,
}: RowDetailActionsProps) => {
  const { setNotification } = useContext(NotificationProvider);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const { id, name, state } = item;
  const isRemovable = INCOMPLETE_OFFER_STATES.includes(state);
  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 2,
          'button:only-of-type': {
            ml: 'auto',
            width: 'calc(50% - 0.5rem)',
          },
        }}
      >
        {/* UNARCHIVE ACTION */}
        {actionButton('Palauta tarjous', {
          variant: 'outlined',
          startIcon: <UnarchiveOutlined />,
          onClick: () => setArchiveDialogOpen(true),
        })}
        {/* REMOVE ACTION */}
        {isRemovable && removeAction()}
      </Box>
      {/* UNARCHIVE DIALOG */}
      <ConfirmationDialog
        open={archiveDialogOpen}
        confirmButtonLabel={'Palauta'}
        onCancel={() => setArchiveDialogOpen(false)}
        onConfirm={() => {
          updateOffer(
            {
              id: String(id),
              item: { archivedAt: null },
            },
            {
              onSuccess: () => {
                setNotification({
                  type: 'SNACKBAR',
                  duration: 3000,
                  severity: 'success',
                  message: 'Tarjous palautettu!',
                });
              },
            },
          );
        }}
        title={'Tarjouksen palautus'}
      >
        <Typography variant={'body1'} mb={2}>
          Olet palauttamassa tarjouksen <i>"{name}"</i>
          <br />
          Haluatko jatkaa?
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

/* 'EXPIRED' TAB ACTIONS */

const ExpiredTabRowDetailActions = ({
  archiveAction,
  createRevisionAction,
  item,
  removeAction,
}: RowDetailActionsProps) => {
  const { previousState } = item;
  const isRemovable =
    previousState && INCOMPLETE_OFFER_STATES.includes(previousState);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {/* CREATE REVISION ACTION */}
      {createRevisionAction('Kopioi uudeksi', {
        startIcon: <CopyAll />,
      })}
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 2,
          'button:only-of-type': {
            ml: 'auto',
            width: 'calc(50% - 0.5rem)',
          },
        }}
      >
        {/* REMOVE ACTION */}
        {isRemovable && removeAction()}
        {/* ARCHIVE ACTION */}
        {archiveAction()}
      </Box>
    </Box>
  );
};
