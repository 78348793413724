import React, { ChangeEvent, useContext } from 'react';

import { TextField } from '@mui/material';

import BackgroundProvider from '../../../../providers/Background/BackgroundProvider';
import { TextFieldProps } from '@mui/material/TextField/TextField';

type CustomerWriteSelectorProps = Omit<
  TextFieldProps,
  'onChange' | 'value'
> & {
  field?: 'customerName' | 'pdfCustomerName';
};

const CustomerWriteSelector = ({
  field = 'customerName',
  fullWidth = true,
  placeholder = 'Kirjoita asiakkaan nimi...',
  size = 'small',
  ...textFieldProps
}: CustomerWriteSelectorProps) => {
  const { backgroundInfo, setBackgroundInfo } = useContext(
    BackgroundProvider,
  );
  const value = backgroundInfo[field] || '';

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBackgroundInfo({
      ...backgroundInfo,
      [field]: event.target.value,
    });
  };

  return (
    <TextField
      fullWidth={fullWidth}
      size={size}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      {...textFieldProps}
    />
  );
};

export default CustomerWriteSelector;
