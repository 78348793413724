import {
  forwardRef,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community';
import 'ag-grid-enterprise';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import '../../components/AgGrid/styles/styles.scss';

import {
  CellClassParams,
  CellMouseDownEvent,
  ColDef,
  IHeaderParams,
  InitialGroupOrderComparatorParams,
  IRowNode,
} from 'ag-grid-community';

import {
  AgGridColumnView,
  IndustryName,
  ItemIndustryPercent,
  PricingSheetRow,
  TableType,
} from '../../../../shared/types';

import RowsProvider from '../../providers/Rows/RowsProvider';

import catClassValueGetter from '../../components/AgGrid/valueGetters/catClassValueGetter';
import CustomHeader from '../../components/AgGrid/Components/CustomHeader';
import CatClassRenderer from '../../components/AgGrid/Renderers/CatClassRenderer';
import GroupHeaderRenderer from '../../components/AgGrid/Renderers/GroupHeaderRenderer';

import {
  collapseAllChildren,
  expandAllChildren,
} from '../../components/AgGrid/Listeners/expandCollapseAllChildren';
import { ExternalFilter } from '../../hooks/useFilters';

import AG_GRID_LOCALE_FI from '../../components/AgGrid/locales/locale.fi';
import { AgGridRefType } from '../../types';
import { useLocalStorage } from '../../hooks/useLocalStorage';

interface AgGridAdminProps {
  type: TableType;
  rows: PricingSheetRow[];
  industry: IndustryName;
  itemIndustryPercents: ItemIndustryPercent[] | undefined;
  loading: boolean;
  externalFilters: ExternalFilter[];
  applyActiveFilters?: (row: PricingSheetRow) => boolean;
  columnsDefs: ColDef[];
  gridColumnView: AgGridColumnView;
  groupingStyle: any;
  initialGroupOrderComparator?: (
    params: InitialGroupOrderComparatorParams<PricingSheetRow>,
  ) => number;
}

const AgGridAdmin = forwardRef<any, AgGridAdminProps>(
  (
    {
      type,
      rows,
      industry,
      itemIndustryPercents,
      loading,
      externalFilters,
      applyActiveFilters,
      columnsDefs,
      gridColumnView,
      groupingStyle,
      initialGroupOrderComparator,
    },
    ref,
  ) => {
    const gridRef = useRef<AgGridRefType>(null);

    const {
      pricingSheetRows,
      setSelectedRows,
      gridGroupingValues,
      dispatchPricingSheetRows,
    } = useContext(RowsProvider);

    useEffect(() => {
      const listener = () => {
        // we have to redraw all the rows after a undo/redo event to ensure that colSpan is updated in colDefs
        setTimeout(() => {
          gridRef?.current?.api.redrawRows();
        }); // add timeout in order to ensure that redrawing uses correct nodes
      };
      // only redraw rows for the critical equipment grid
      gridColumnView === AgGridColumnView.CRITICAL_EQUIPMENT
        ? document.addEventListener('rowsHistory', listener)
        : document.removeEventListener('rowsHistory', listener);
      return () => {
        document.removeEventListener('rowsHistory', listener);
      };
    }, [gridColumnView, gridRef]);

    const [showMachineCardStorage, setShowMachineCardStorage] =
      useLocalStorage('showMachineCard', 'true');

    const showMachineCard = showMachineCardStorage === 'true';
    const setShowMachineCard = (value: boolean) => {
      setShowMachineCardStorage(String(value));
    };

    const [filteredRows, setFilteredRows] =
      useState<PricingSheetRow[]>(pricingSheetRows); // default there are not filters applied

    const [isAllExpanded, setIsAllExpanded] =
      useState<boolean>(false);

    const [suppressCellFocus, setSuppressCellFocus] = useState(false);

    const expandAllListener = useCallback(
      (_event: SyntheticEvent) => {
        gridRef?.current?.api?.expandAll();
        setIsAllExpanded(true);
        gridRef?.current?.api?.onGroupExpandedOrCollapsed(); // enact the expansion changes
      },
      [],
    );

    const collapseAllListener = useCallback(
      (_event: SyntheticEvent) => {
        gridRef?.current?.api?.collapseAll();
        setIsAllExpanded(false);
        gridRef?.current?.api?.onGroupExpandedOrCollapsed(); // enact the expansion changes
      },
      [],
    );

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(
      () => ({
        flex: 1,
        initialWidth: 100,
        minWidth: 90,
        filter: true,
        floatingFilter: true,
        resizable: true,
        sortable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      }),
      [],
    );

    // group rows without a product group together
    const groupedRows = useMemo(() => {
      const groupName = 'Ryhmittelemättömät tuotteet';
      return rows.map((row) => ({
        ...row,
        pimProductGroup: row.pimProductGroup ?? groupName,
        productGroup: row.productGroup ?? groupName,
      }));
    }, [rows]);

    const autoGroupColumnDef = useMemo(() => {
      return {
        colSpan: (params: { data: PricingSheetRow }) => {
          if (params?.data?.catClass) {
            return 0;
          }
          return 20;
        },
        cellStyle: ({ data }: CellClassParams<PricingSheetRow>) =>
          data ? { backgroundColor: '#fafafa' } : null,
        lockPosition: 'left' as ColDef['lockPosition'],
        headerName: 'CatClass',
        field: 'name',
        minWidth: 415,
        filter: 'agTextColumnFilter',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        valueGetter: catClassValueGetter,
        headerComponent: (params: IHeaderParams<PricingSheetRow>) => (
          <CustomHeader
            gridColumnView={gridColumnView}
            displayName={params.displayName}
            expanded={isAllExpanded}
            onCellExpand={
              isAllExpanded ? collapseAllListener : expandAllListener
            }
            type={type}
            getFilteredRows={getFilteredRows}
          />
        ),
        cellRendererParams: {
          checkbox: true,
          tooltipField: 'catClass',
          innerRenderer: (params: { data: PricingSheetRow }) => {
            if (params?.data?.catClass) {
              return (
                <CatClassRenderer
                  setShowMachineCard={setShowMachineCard}
                  showMachineCard={showMachineCard}
                  row={params.data}
                  type={type}
                  getFilteredRows={getFilteredRows}
                />
              );
            }
            return (
              <GroupHeaderRenderer
                expandAllNodeChildren={expandAllNodeChildren}
                collapseAllNodeChildren={collapseAllNodeChildren}
                row={params}
                getFilteredRows={getFilteredRows}
              />
            );
          },
        },
        cellClass: 'child-header-cell',
        cellRenderer: 'agGroupCellRenderer',
      };
      /* eslint-disable */
    }, [
      groupingStyle,
      JSON.stringify(gridColumnView),
      JSON.stringify(gridGroupingValues),
      JSON.stringify(filteredRows),
      isAllExpanded,
      showMachineCard,
    ]);
    /* eslint-enable */

    const getSelectedRows = () => {
      return gridRef?.current?.api?.getSelectedRows() || [];
    };

    const getRowId = useMemo(() => {
      return (params: { data: PricingSheetRow }) => {
        return params.data.id.toString();
      };
    }, []);

    const getRowHeight = useCallback((params) => {
      return params.node.group ? 35 : 30;
    }, []);

    const onSelectionChanged = () => {
      const selectedRows = getSelectedRows();

      if (type !== TableType.PreSelectTable) {
        setSelectedRows([...selectedRows]);
      } else {
        // might change
        const copy = [...pricingSheetRows];

        dispatchPricingSheetRows({
          type: 'updatePricingSheetRows',
          newRows: copy,
        });
      }
    };

    const externalFilterChanged = useCallback(() => {
      gridRef?.current?.api?.onFilterChanged();
    }, []);

    const isExternalFilterPresent = useCallback(() => {
      return externalFilters.length > 0;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(externalFilters)]);

    useEffect(() => {
      externalFilterChanged();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(externalFilters)]);

    const doesExternalFilterPass = useCallback(
      (node) => {
        if (!applyActiveFilters) {
          return false;
        }
        return applyActiveFilters(node.data);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify(externalFilters)],
    );

    const getFilteredRows = () => {
      let filteredRows: PricingSheetRow[] = [];
      gridRef?.current?.api?.forEachNodeAfterFilter(
        (rowNode: IRowNode) => {
          if (rowNode.data) {
            filteredRows.push(rowNode.data);
          }
        },
      );
      return filteredRows;
    };

    const onFilterChanged = useCallback(
      (_event) => {
        setFilteredRows(getFilteredRows());
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify(externalFilters)],
    );

    const selectAllPreSelected = useCallback(() => {
      gridRef?.current?.api?.forEachNode((node: IRowNode) => {
        if (node?.data?.preSelected) {
          node.setSelected(true);
        }
      });
    }, [gridRef]);

    const clearAllSelections = useCallback(() => {
      gridRef?.current?.api?.deselectAll();
    }, [gridRef]);

    const refreshCells = useCallback(() => {
      gridRef?.current?.api?.refreshCells();
    }, [gridRef]);

    const onGridReady = useCallback(() => {
      if (type === TableType.PreSelectTable) {
        selectAllPreSelected();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const expandAllNodeChildren = useCallback(
      (event: CellMouseDownEvent) => {
        expandAllChildren(gridRef, event);
      },
      [],
    );

    const collapseAllNodeChildren = useCallback(
      (event: CellMouseDownEvent) => {
        collapseAllChildren(gridRef, event);
      },
      [],
    );

    const localeText = useMemo(() => {
      return AG_GRID_LOCALE_FI;
    }, []);

    // These methods are available by parent component trough ref
    useImperativeHandle(ref, () => ({
      clearAllSelections() {
        clearAllSelections();
      },
      refreshCells() {
        refreshCells();
      },
    }));

    return (
      <div>
        <div className="ag-theme-material" style={{ height: '80vh' }}>
          <AgGridReact
            animateRows={false}
            className={'admin'}
            ref={gridRef} // Ref for accessing Grid's API
            initialGroupOrderComparator={initialGroupOrderComparator}
            rowData={!loading ? groupedRows : undefined} // Row Data for Rows
            columnDefs={columnsDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            localeText={localeText}
            autoGroupColumnDef={autoGroupColumnDef as any}
            rowSelection={'multiple'}
            groupSelectsChildren={true}
            groupSelectsFiltered={true}
            suppressRowClickSelection={true}
            suppressAggFuncInHeader={true}
            getRowHeight={getRowHeight}
            getRowId={getRowId} // returns a unique id for each row. https://www.ag-grid.com/react-data-grid/react-hooks/#immutable-data
            onSelectionChanged={onSelectionChanged}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            onFilterChanged={onFilterChanged}
            suppressDragLeaveHidesColumns={true}
            suppressMakeColumnVisibleAfterUnGroup={true}
            suppressRowGroupHidesColumns={true}
            tooltipShowDelay={500}
            onGridReady={onGridReady}
            groupAllowUnbalanced={true}
            readOnlyEdit={true}
            suppressModelUpdateAfterUpdateTransaction={true}
            suppressCellFocus={suppressCellFocus}
            context={{
              suppressCellFocus: setSuppressCellFocus,
            }}
          />
        </div>
      </div>
    );
  },
);

export default AgGridAdmin;
