import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@mui/material';

export const ApprovalLockedDialog = ({
  open,
  handleButtonClick,
  lockedBy,
  lockedReason,
}: {
  open: boolean;
  handleButtonClick: () => void;
  lockedBy: string | null;
  lockedReason: string | null;
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>Hyväksyntäpyyntö käsitelty</DialogTitle>
      <Box sx={{ ml: 3, mr: 2, mb: 2 }}>
        <Typography>
          {lockedBy} {lockedReason} hinnaston. Tämä hyväksyntäpyyntö
          on nyt lukittu.
        </Typography>
        <DialogActions>
          <Button
            sx={{ mt: 2, float: 'right' }}
            onClick={handleButtonClick}
            size="small"
            variant="contained"
          >
            Palaa etusivulle
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
