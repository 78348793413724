import DefaultTooltip from '../Tooltips/DefaultTooltip';
import { Box, SvgIcon, Typography } from '@mui/material';
import WarningAmber from '@mui/icons-material/WarningAmber';

export const CustomerLoadError = () => {
  return (
    <Box
      sx={{
        marginLeft: 'auto',
      }}
    >
      <DefaultTooltip
        title={
          'Asiakastietojen lataus MIRA:sta epäonnistui. Asiakkaan lukitustiedot eivät näy tarjoustaulukossa'
        }
        placement={'top'}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SvgIcon
            color="warning"
            sx={{
              fontSize: 16,
            }}
            component={WarningAmber}
          />
          <Typography>
            Virhe ladattaessa asiakkaan lukitustietoja
          </Typography>
        </Box>
      </DefaultTooltip>
    </Box>
  );
};
