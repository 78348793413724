import { ApprovalRequestState } from "../types";

export const getApprovalRequestStateLabel = (state: ApprovalRequestState) => {
  switch (state) {
    case ApprovalRequestState.Waiting:
      return "Odottaa hyväksyntää";
    case ApprovalRequestState.Discarded:
      return "Hylätty";
    case ApprovalRequestState.Approved:
      return "Hyväksytty";
    case ApprovalRequestState.Locked:
      return "Lukittu";
  }
};
