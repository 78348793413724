import StepperContainer from '../StepperContainer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createOfferSheet } from '../../services/offerSheets';
import { useHistory } from 'react-router-dom';
import { OfferDetails } from './OfferDetails';
import { useEffect } from 'react';
import OfferStepper from './OfferStepper';
import {
  OfferStep,
  ValidatedClientOffer,
} from '../../../../shared/types/offers';
import { useOfferStore } from '../../stores/offerStore';
import { useItems } from '../../hooks/useItems';
import { OFFER_SHEET_QUERY_KEY } from '../../../../shared/constants';
import OfferStepActionButton from './OfferStepActionButton';

const CreateOffer = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  // prefetch items if not already in cache, so that the Items step will load instantly
  useItems({ includeHidden: true });
  const setActiveStep = useOfferStore((state) => state.setActiveStep);
  // create offer sheet mutation
  const { isPending, mutate: createOffer } = useMutation({
    mutationFn: createOfferSheet,
    onSuccess: (data) => {
      const { id } = data;
      // create cache for offerSheet/:id query
      queryClient.setQueryData(
        [OFFER_SHEET_QUERY_KEY, `${id}`],
        data,
      );
      setActiveStep(OfferStep.Items);
      history.push({
        pathname: `/tarjous/${id}`,
      });
    },
  });

  // set active step on init
  useEffect(() => {
    setActiveStep(OfferStep.Details);
  }, [setActiveStep]);

  return (
    <StepperContainer>
      <OfferStepper />
      <OfferDetails
        renderStepActions={(state) => {
          const { customerName, industry, name } = state;
          const validState = !!(customerName && industry && name);
          return (
            <OfferStepActionButton
              type={'NEXT'}
              loading={isPending}
              disabled={!validState}
              onClick={
                validState
                  ? () => createOffer(state as ValidatedClientOffer)
                  : undefined
              }
            />
          );
        }}
      />
    </StepperContainer>
  );
};

export default CreateOffer;
