import React, { useContext, useState, useEffect } from 'react';

import { createTheme } from '@mui/material/styles';

import { Typography, TypographyProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';

import BackgroundProvider from '../../providers/Background/BackgroundProvider';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import PricingProvider from '../../providers/Pricing/PricingProvider';

import Divider from '@mui/material/Divider';

import getPriceSheetTypeAbbreviation from '../../helpers/getPriceSheetTypeAbbrevation';

import PaymentTermSelector from './Components/Selectors/PaymentTermSelector';

import AcceptanceMatrix from './Components/AcceptanceMatrix';

import GenericCheckbox from './Components/Checkboxes/GenericCheckbox';
import ChangeValuesButton from './Components/Buttons/ChangeValuesButton';

import Header from './Components/Header';
import Revenue from './Components/Revenue';
import Potential from './Components/Potential';
import Commitment from './Components/Commitment';
import Level from './Components/Level';

import Site from './Components/Site';
import Segment from './Components/Segment';
import Region from './Components/Region';

import Dates from './Components/Dates';
import Value from './Components/Value';
import Duration from './Components/Duration';

import { useHistory } from 'react-router-dom';
import { currencyMultiplier } from '../../../../shared/constants';
import CustomerSearchSelector from './Components/Selectors/CustomerSearchSelector';
import CustomerWriteSelector from './Components/Selectors/CustomerWriteSelector';
import { Nullable } from '../../../../shared/types';
import { setDatetoStartOfUTCDay } from '../../utils/formatDateTimes';

interface InitialInformationStepProps {}

export type HandleDateChange = (date: Date | null) => void;

export const asCurrency = new Intl.NumberFormat('fi-FI', {
  style: 'currency',
  currency: 'EUR',
  maximumSignificantDigits: 3,
});

export type ControlledSelector<T> = {
  value: T;
  onChange: (newValue: T) => void;
};

export const formatRevenue = (revenue: number) => {
  return `${asCurrency.format(revenue * currencyMultiplier)}`;
};

export const formatRentalPotential = (start: number, end: number) => {
  return `${asCurrency.format(
    start * currencyMultiplier,
  )} - ${asCurrency.format(end * currencyMultiplier)}`;
};

export const differenceInDays = (start: Date, end: Date) => {
  const differenceInTime = end?.getTime() - start?.getTime();
  return Math.floor(differenceInTime / (1000 * 3600 * 24) + 1);
};

export const differenceInDaysString = (start: Date, end: Date) => {
  return `${differenceInDays(start, end).toString()} päivää`;
};

export const pricingValue = (
  potential: { bottom: number; top: number },
  durationInDays: number,
) => {
  const bottomValue = Math.floor(
    potential?.bottom * currencyMultiplier * (durationInDays / 365),
  );
  const topValue = Math.floor(
    potential?.top * currencyMultiplier * (durationInDays / 365),
  );

  return `${asCurrency.format(bottomValue)} - ${asCurrency.format(
    topValue,
  )}`;
};

export const SubtleText: typeof Typography = (
  props: TypographyProps,
) => (
  <Typography
    sx={{ m: 1 }}
    variant="h6"
    component="div"
    gutterBottom
    color="primary"
  >
    {props.children}
  </Typography>
);

const InitialInformationStep: React.FC<
  InitialInformationStepProps
> = () => {
  const theme = createTheme();
  let history = useHistory();

  const { backgroundInfo, setBackgroundInfo, resetBackgroundInfo } =
    useContext(BackgroundProvider);
  const { setNotification } = useContext(NotificationProvider);
  const { pricingSheetId } = useContext(PricingProvider);

  const { updateInitialInfoEnabled, setUpdateInitialInfoEnabled } =
    useContext(BackgroundProvider);
  const [name, setName] = useState<string | undefined>(
    backgroundInfo?.name,
  );

  const handlePropChange = <T,>(propName: string) => {
    return (value: T | string) => {
      const newState = { ...backgroundInfo };
      newState[propName] = value;
      setBackgroundInfo(newState);

      // ensure that end date is not before start date
      const startDate = newState['priceSheetStartDate'];
      const endDate = newState['priceSheetEndDate'];
      if (endDate < startDate) {
        setNotification({
          type: 'SNACKBAR',
          duration: 2000,
          severity: 'warning',
          message: 'Päättymispäivä ei voi olla ennen alkamispäivää',
        });
        newState['priceSheetEndDate'] =
          newState['priceSheetStartDate'];
      }
      // clear pdfCustomerName if changing pricing sheet type to a type with free input
      if (
        propName === 'priceSheetType' &&
        (value === 'Jäsenhinnasto' || value === 'Referenssi')
      ) {
        newState.pdfCustomerName = null;
      }
      setBackgroundInfo(newState);
    };
  };

  useEffect(() => {
    if (
      window.location.pathname === '/muokkaa-hinnastoa' &&
      !backgroundInfo?.customerName
    ) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      backgroundInfo?.customerName &&
      backgroundInfo?.priceSheetStartDate &&
      backgroundInfo?.priceSheetEndDate &&
      backgroundInfo?.priceSheetType
    ) {
      const site =
        backgroundInfo?.site &&
        backgroundInfo?.priceSheetType === 'Projekti'
          ? `_${backgroundInfo?.site?.replace(/\s/g, '')}`
          : '';
      const customerName = backgroundInfo?.customerName?.replace(
        /\s/g,
        '',
      );
      const name = `${customerName}${site}_${backgroundInfo?.priceSheetStartDate
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, '')}-${backgroundInfo?.priceSheetEndDate
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, '')}_${getPriceSheetTypeAbbreviation(
        backgroundInfo?.priceSheetType,
      )}`;

      setName(name);
    } else {
      setName(undefined);
    }
  }, [backgroundInfo]);

  useEffect(() => {
    setBackgroundInfo({
      ...backgroundInfo,
      name: name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handlers = <T,>(propName: string) => {
    return {
      value: backgroundInfo[propName],
      onChange: handlePropChange<T>(propName),
    };
  };

  useEffect(() => {
    if (!pricingSheetId) {
      resetBackgroundInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingSheetId]);

  useEffect(() => {
    return () => {
      setUpdateInitialInfoEnabled(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartDateChange: HandleDateChange = (value) => {
    const newState = { ...backgroundInfo };
    if (value) {
      value = setDatetoStartOfUTCDay(value);
    }
    newState['priceSheetStartDate'] = value;

    // ensure that end date is not before start date
    const startDate = newState['priceSheetStartDate'];
    const endDate = newState['priceSheetEndDate'];

    if (endDate < startDate) {
      setNotification({
        type: 'SNACKBAR',
        duration: 2000,
        severity: 'warning',
        message: 'Päättymispäivä ei voi olla ennen alkamispäivää',
      });
      newState['priceSheetEndDate'] = newState['priceSheetStartDate'];
    }

    setBackgroundInfo(newState);
  };

  const handleEndDateChange: HandleDateChange = (value) => {
    const newState = { ...backgroundInfo };

    newState['priceSheetEndDate'] =
      value && setDatetoStartOfUTCDay(value);
    setBackgroundInfo(newState);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Box
          sx={{
            mt: 2,
            ml: 2,
            mr: 2,
          }}
        >
          <Header backgroundInfo={backgroundInfo} />
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              [theme.breakpoints.down('lg')]: {
                flexDirection: 'column',
              },
            }}
          >
            <Grid>
              <Box sx={{ width: 320 }}>
                <SubtleText>Asiakas*</SubtleText>
                <CustomerSearchSelector
                  customerName={backgroundInfo.customerName}
                  customerId={backgroundInfo.customerId}
                  onCustomerChange={(clientOption) => {
                    setBackgroundInfo({
                      ...backgroundInfo,
                      customerName: clientOption?.value,
                      pdfCustomerName: clientOption?.value,
                      customerId: clientOption?.id,
                    });
                  }}
                />
                {backgroundInfo?.priceSheetType === 'Referenssi' ||
                backgroundInfo?.priceSheetType === 'Jäsenhinnasto' ? (
                  <CustomerWriteSelector />
                ) : (
                  <>
                    <CustomerWriteSelector
                      field={'pdfCustomerName'}
                      label={'Asiakkaan nimi hinnaston kannessa'}
                      sx={{
                        mb: 1,
                      }}
                    />
                  </>
                )}
                {backgroundInfo?.priceSheetType === 'Projekti' && (
                  <Site />
                )}

                <Segment
                  handleIndustryChange={(
                    newValue: Nullable<string>,
                  ) => {
                    setBackgroundInfo({
                      ...backgroundInfo,
                      industry: newValue ?? undefined,
                    });
                  }}
                  pricingSheetId={pricingSheetId}
                  industry={backgroundInfo?.industry}
                  changeValuesEnabled={updateInitialInfoEnabled}
                />
                <Region />

                <Divider sx={{ mt: 1, mb: 1 }} />
                <GenericCheckbox
                  label={'Alueellisesti merkittävä kohde'}
                  propName={'regionallySignificant'}
                />
                <GenericCheckbox
                  label={'Strategisesti merkittävä kohde'}
                  propName={'strategicallySignificant'}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  ml: 4,
                  [theme.breakpoints.down('lg')]: {
                    m: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    [theme.breakpoints.down('lg')]: {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Level handlers={handlers} />
                  <Box
                    sx={{
                      [theme.breakpoints.down('lg')]: {
                        maxWidth: '380px',
                      },
                    }}
                  >
                    <Revenue
                      changeValuesEnabled={updateInitialInfoEnabled}
                    />

                    <Box
                      sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Potential
                        changeValuesEnabled={updateInitialInfoEnabled}
                      />

                      <Commitment
                        backgroundInfo={backgroundInfo}
                        changeValuesEnabled={updateInitialInfoEnabled}
                      />
                      <Box
                        sx={{
                          p: 1,
                          gap: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        {pricingSheetId && (
                          <ChangeValuesButton
                            changeValuesEnabled={
                              updateInitialInfoEnabled
                            }
                            setChangeValuesEnabled={
                              setUpdateInitialInfoEnabled
                            }
                          />
                        )}
                        <AcceptanceMatrix />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Box
            sx={{
              ml: 4,
              mt: 2,
              display: 'flex',
              flexDirection: 'row',
              [theme.breakpoints.down('lg')]: {
                flexDirection: 'column',
              },
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <SubtleText>Hinnaston voimassaolo*</SubtleText>
              <Dates
                onStartDateChange={handleStartDateChange}
                onEndDateChange={handleEndDateChange}
                minStartDate={new Date()}
                startDate={backgroundInfo.priceSheetStartDate}
                endDate={backgroundInfo.priceSheetEndDate}
                startDateLabel={'Hinnasto voimassa alkaen'}
                endDateLabel={'Hinnaston päättymispäivä'}
              />
            </Box>
            <Duration backgroundInfo={backgroundInfo} />
            <Value backgroundInfo={backgroundInfo} />
            <PaymentTermSelector />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default InitialInformationStep;
