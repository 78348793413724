import {
  ITEM_ROW_KEYS,
  CRITICAL_EQUIPMENT_ROW_KEYS,
  PRICE_PERCENTAGE_COEFFICIENT_KEYS,
} from '../../../../../shared/constants';

import {
  PricingSheetRow,
  CriticalEquipmentRowKeys,
  ItemIndustryPercent,
  PricingSheetRowKey,
  SurplusEquipmentRowKeys,
} from '../../../../../shared/types';

type ChangedRow<T> = {
  changedProperties: string[];
  value: T;
};

export const getChangedRows = <
  T extends PricingSheetRow | ItemIndustryPercent,
>(
  previousRows: T[],
  changedRows: T[],
) => {
  const previousRowsMappedByKey: Record<string, T> = {};
  let a = 0;
  const previousRowsLength = previousRows.length;
  // create a key - value mapping of the previous rows, so that we can easily compare changes against it
  while (a < previousRowsLength) {
    const item = previousRows[a];
    const key =
      'industryId' in item
        ? // add industryId to key for industry percent items to make it unique
          `${item.catClass}${item.industryId}`
        : item.catClass;
    previousRowsMappedByKey[key] = item;
    a++;
  }
  const changes: ChangedRow<T>[] = [];
  let b = 0;
  const changedRowsLength = changedRows?.length || 0;
  // get changed rows
  while (b < changedRowsLength) {
    const item = changedRows[b];
    // handle industry percent items
    if ('industryId' in item) {
      const key = `${item.catClass}${item.industryId}`;
      const previousRow = previousRowsMappedByKey[
        key
      ] as ItemIndustryPercent;
      if (previousRow) {
        const changedProperties =
          PRICE_PERCENTAGE_COEFFICIENT_KEYS.flatMap((key) =>
            previousRow[key] !== item[key] ? key : [],
          );
        if (changedProperties.length > 0) {
          changes.push({
            changedProperties,
            value: item,
          });
        }
      }
      // handle price sheet items
    } else {
      const previousRow = previousRowsMappedByKey[
        item.catClass
      ] as PricingSheetRow;

      // Check for differences in surplusEquipmentItem properties
      if (
        previousRow.surplusEquipmentItem ||
        item.surplusEquipmentItem
      ) {
      }
      const surplusEquipmentChanges = [
        'surplusTo',
        'surplusFrom',
        'changePercentage',
      ].filter((key) => {
        const previousValue =
          previousRow.surplusEquipmentItem?.[
            key as SurplusEquipmentRowKeys
          ];
        const currentValue =
          item.surplusEquipmentItem?.[key as SurplusEquipmentRowKeys];

        return previousValue !== currentValue;
      });

      // Check if any property in surplusEquipmentItem changed
      const surplusEquipmentItemChanged =
        surplusEquipmentChanges.length > 0 ||
        (previousRow.surplusEquipmentItem &&
          item.surplusEquipmentItem === null);

      // Merge surplus equipment changes into changedProperties
      const changedProperties = [
        ...ITEM_ROW_KEYS.flatMap((key) => {
          const criticalEquipmentKey =
            CRITICAL_EQUIPMENT_ROW_KEYS.some((i) => i === key);
          let initialValue = criticalEquipmentKey
            ? previousRow.criticalEquipmentItems[0]?.[
                key as CriticalEquipmentRowKeys
              ]
            : previousRow[key as PricingSheetRowKey];
          let currentValue = criticalEquipmentKey
            ? item.criticalEquipmentItems[0]?.[
                key as CriticalEquipmentRowKeys
              ]
            : item[key as PricingSheetRowKey];
          return initialValue !== currentValue ? key : [];
        }),
        ...surplusEquipmentChanges, // Merge surplus equipment changes
      ];

      if (
        changedProperties.length > 0 ||
        surplusEquipmentItemChanged
      ) {
        changes.push({
          changedProperties,
          value: item,
        });
      }
    }
    b++;
  }
  return changes;
};
