import { PricingSheetRow } from "./types";

const getFinalValue = (value: number | undefined) => {
  return value ? value / 100 : null;
};

export const getDayCoefficient = (
  pricingSheetRow: PricingSheetRow,
  coefficients:
    | {
        dayCoefficient: string;
        monthCoefficient: string;
      }
    | undefined
    | null,
) => {
  let value = null;

  switch (coefficients?.dayCoefficient) {
    case "dayPriceCoefficient1":
      value = getFinalValue(pricingSheetRow?.dayPriceCoefficient1);
      break;
    case "dayPriceCoefficient2":
      value = getFinalValue(pricingSheetRow?.dayPriceCoefficient2);
      break;
    case "dayPriceCoefficient3":
      value = getFinalValue(pricingSheetRow?.dayPriceCoefficient3);
      break;
    case "dayPriceCoefficient4":
      value = getFinalValue(pricingSheetRow?.dayPriceCoefficient4);
      break;
    case "dayPriceCoefficient5":
      value = getFinalValue(pricingSheetRow?.dayPriceCoefficient5);
      break;
    case "dayPriceCoefficient6":
      value = getFinalValue(pricingSheetRow?.dayPriceCoefficient6);
      break;
    case "dayPriceCoefficient7":
      value = getFinalValue(pricingSheetRow?.dayPriceCoefficient7);
      break;
    case "dayPriceCoefficientList":
      value = getFinalValue(pricingSheetRow?.dayPriceCoefficientList);
      break;
  }

  return value;
};
