import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { PricingSheetRow } from '../../../../shared/types';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import BackgroundProvider from '../../providers/Background/BackgroundProvider';
import {
  Alert,
  Backdrop,
  createTheme,
  DialogActions,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import DefaultTooltip from '../Tooltips/DefaultTooltip';

type CopySource = 'COMPARISON' | 'PROPOSAL' | 'CONTRACT';
export type CopyColumns = 'DAY' | 'MONTH' | 'BOTH';

const defaultCopySource = 'PROPOSAL';
const defaultCopyColumns = 'BOTH';

interface CopyPricesDialogProps {
  enabledSources: CopySource[];
  onCopyPricesComparison: (
    selectedRows: PricingSheetRow[],
    copyColumns: CopyColumns,
  ) => void;
  onCopyPricesProposal: (
    selectedRows: PricingSheetRow[],
    copyColumns: CopyColumns,
  ) => void;
  onCopyPricesContract?: (
    selectedRows: PricingSheetRow[],
    copyColumns: CopyColumns,
  ) => void;
  selectedRows: PricingSheetRow[];
  contractSheetName?: string;
  comparisonSheetName: string;
}

const CopyPricesDialog: React.FC<CopyPricesDialogProps> = ({
  onCopyPricesComparison,
  onCopyPricesProposal,
  onCopyPricesContract,
  selectedRows,
  contractSheetName,
  comparisonSheetName,
  enabledSources,
}) => {
  const theme = createTheme();
  const [copySource, setCopySource] =
    useState<CopySource>(defaultCopySource);
  const [copyColumns, setCopyColumns] = useState<CopyColumns>(
    defaultCopyColumns,
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setCopySource(defaultCopySource);
    setCopyColumns(defaultCopyColumns);
  };

  const { setNotification } = useContext(NotificationProvider);
  const { backgroundInfo } = useContext(BackgroundProvider);

  useEffect(() => {
    if (backgroundInfo.pricingBasis === 'DAY') {
      setCopyColumns('DAY');
    } else {
      setCopyColumns(defaultCopyColumns);
    }
  }, [backgroundInfo.pricingBasis]);

  const isRowsSelected = selectedRows.length > 0;

  const copyPrices = () => {
    try {
      if (copySource === 'COMPARISON') {
        onCopyPricesComparison(selectedRows, copyColumns);
        setNotification({
          type: 'SNACKBAR',
          duration: 2000,
          severity: 'success',
          message:
            'Hinnaston hinnat kopioitu valitusta vertailuhinnastosta!',
        });
      } else if (copySource === 'CONTRACT') {
        onCopyPricesContract &&
          onCopyPricesContract(selectedRows, copyColumns);
        setNotification({
          type: 'SNACKBAR',
          duration: 2000,
          severity: 'success',
          message: 'Hinnaston hinnat kopioitu sopimushinnastosta!',
        });
      } else {
        onCopyPricesProposal(selectedRows, copyColumns);
        setNotification({
          type: 'SNACKBAR',
          duration: 2000,
          severity: 'success',
          message: 'Hinnaston hinnat kopioitu ehdotushinnoista!',
        });
      }
    } catch (error) {
      setNotification({
        severity: 'error',
        message: 'Hinnaston hintoja kopioidessa tapahtui virhe!',
      });
    }
  };

  const getInfoText = () => {
    switch (copySource) {
      case 'PROPOSAL':
        if (copyColumns === 'DAY') {
          return (
            <>
              <Typography>{'EhdotusPVHinta -> PVHinta'}</Typography>
            </>
          );
        } else if (copyColumns === 'MONTH') {
          return (
            <>
              <Typography>{'EhdotusKKHinta -> KKHinta'}</Typography>
            </>
          );
        } else {
          return (
            <>
              <Typography>{'EhdotusPVHinta -> PVHinta'}</Typography>
              <Typography>{'EhdotusKKHinta -> KKHinta'}</Typography>
            </>
          );
        }
      case 'COMPARISON':
        if (copyColumns === 'DAY') {
          return (
            <>
              <Typography>{'VRTHintaPV -> PVHinta'}</Typography>
            </>
          );
        } else if (copyColumns === 'MONTH') {
          return (
            <>
              <Typography>{'VRTHintaKK -> KKHinta'}</Typography>
            </>
          );
        } else {
          return (
            <>
              <Typography>{'VRTHintaPV -> PVHinta'}</Typography>
              <Typography>{'VRTHintaKK -> KKHinta'}</Typography>
            </>
          );
        }
      case 'CONTRACT':
        if (copyColumns === 'DAY') {
          return (
            <>
              <Typography>{'SopimushintaPV -> PVHinta'}</Typography>
            </>
          );
        } else if (copyColumns === 'MONTH') {
          return (
            <>
              <Typography>{'SopimushintaKK -> KKHinta'}</Typography>
            </>
          );
        } else {
          return (
            <>
              <Typography>{'SopimushintaPV -> PVHinta'}</Typography>
              <Typography>{'SopimushintaKK -> KKHinta'}</Typography>
            </>
          );
        }
      default:
        return (
          <>
            <Typography>
              {'Virhe valittaessa kopioitavaa hintaa!'}
            </Typography>
          </>
        );
    }
  };

  const isSourceEnabled = (source: CopySource) => {
    return enabledSources.includes(source);
  };

  const selectSourceButton = (
    value: CopySource,
    disabledText: string,
    disabled: boolean,
    label: string,
  ) => {
    return disabled ? (
      <DefaultTooltip title={disabledText}>
        <ToggleButton disabled size="small" value={value}>
          {label}
        </ToggleButton>
      </DefaultTooltip>
    ) : (
      <ToggleButton size="small" value={value}>
        {label}
      </ToggleButton>
    );
  };

  return (
    <Box
      sx={{
        display: 'inline',
        // todo: can this be safely removed?
        // mb: backgroundInfo?.approverComment ? 0 : 1,
      }}
    >
      <DefaultTooltip title="Kopioi ehdotus- ja vertailuhintoja valituilta riveiltä">
        <span>
          <Button
            disabled={!isRowsSelected}
            onClick={handleOpen}
            sx={{ height: 26 }}
            size="small"
            variant="outlined"
          >
            <ContentCopyIcon style={{ fontSize: 16 }} />
            <Box
              sx={{
                [theme.breakpoints.down('xl')]: {
                  display: 'none',
                },
                [theme.breakpoints.up('xl')]: {
                  display: 'inline',
                },
              }}
            >
              Kopioi hinnat
            </Box>
          </Button>
        </span>
      </DefaultTooltip>
      <Dialog
        disableScrollLock
        fullWidth={true}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle>Kopioi valitut</DialogTitle>

        <Fade in={open}>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <FormLabel>Kopioitavat hinnat</FormLabel>
              <br />
              <ToggleButtonGroup
                style={{ marginTop: 8 }}
                color="primary"
                value={copySource}
                size="small"
                exclusive
                onChange={(event, value) => {
                  // Enforce that at least one button is active
                  if (value?.length) {
                    setCopySource(value);
                  }
                }}
              >
                <ToggleButton size="small" value="PROPOSAL">
                  Ehdotushinnat
                </ToggleButton>

                {isSourceEnabled('COMPARISON') &&
                  selectSourceButton(
                    'COMPARISON',
                    'Vertailuhinnastoa ei ole valittu',
                    comparisonSheetName?.length === 0,
                    'Vertailuhinnat',
                  )}
                {isSourceEnabled('CONTRACT') &&
                  selectSourceButton(
                    'CONTRACT',
                    'Sopimushinnastoa ei ole valittu',
                    contractSheetName?.length === 0,
                    'Sopimushinnat',
                  )}
              </ToggleButtonGroup>
            </Box>
            <Box sx={{ mt: 3 }}>
              <FormControl>
                <FormLabel>Kopioitavat sarakkeet</FormLabel>
                <RadioGroup
                  value={copyColumns}
                  row
                  onChange={(event) =>
                    setCopyColumns(event.target.value as CopyColumns)
                  }
                >
                  <FormControlLabel
                    disabled={backgroundInfo.pricingBasis === 'DAY'}
                    value="DAY"
                    control={<Radio />}
                    label="Päivähinnat"
                  />
                  <FormControlLabel
                    disabled={backgroundInfo.pricingBasis === 'DAY'}
                    value="MONTH"
                    control={<Radio />}
                    label="Kuukausihinnat"
                  />
                  <FormControlLabel
                    disabled={backgroundInfo.pricingBasis === 'DAY'}
                    value="BOTH"
                    control={<Radio />}
                    label="Molemmat"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Alert sx={{ mt: 2 }} icon={false} severity="info">
              <Typography fontWeight="bold">Kopioidaan</Typography>
              <Box sx={{ mt: 1 }}>
                <Typography>Hinnat</Typography>
                <Box sx={{ ml: 2 }}>
                  <Typography>
                    {copySource === 'PROPOSAL'
                      ? 'Ehdotushinnat'
                      : copySource === 'CONTRACT'
                        ? `Sopimushinnat ${contractSheetName}`
                        : `Vertailuhinnat ${comparisonSheetName}`}
                  </Typography>
                </Box>
                <Typography>Rivit</Typography>
                <Box sx={{ ml: 2 }}>
                  <Typography>{selectedRows.length} riviä</Typography>
                </Box>
                <Typography>Sarakkeet</Typography>
                <Box sx={{ ml: 2 }}>{getInfoText()}</Box>
              </Box>
            </Alert>

            <DialogActions
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Button color="inherit" onClick={handleClose}>
                  Peruuta
                </Button>
              </Box>
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    copyPrices();
                    handleClose();
                  }}
                >
                  Kopioi
                </Button>
              </Box>
            </DialogActions>
          </DialogContent>
        </Fade>
      </Dialog>
    </Box>
  );
};

export default CopyPricesDialog;
