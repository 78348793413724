import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { immer } from 'zustand/middleware/immer';
import {
  OfferCompare,
  OfferCompareState,
  OfferComparisonItems,
  OfferStep,
} from '../../../shared/types/offers';
import { ExternalPricingSheetItem } from '../../../shared/types';

type State = {
  activeStep: OfferStep;
  compare: OfferCompareState;
};

type Actions = {
  setActiveStep: (step: OfferStep) => void;
  setCompareState: (
    key: keyof OfferCompareState,
    state: Partial<
      Omit<OfferCompare, 'items'> & {
        items: ExternalPricingSheetItem[];
      }
    > | null,
  ) => void;
  clearCompareState: () => void;
};

const defaultState: OfferCompare = {
  id: undefined,
  items: null,
  name: '',
  selectedOption: undefined,
};

const INIT_COMPARE_STATE: OfferCompareState = {
  comparisonSheet: defaultState,
  contractSheet: defaultState,
};

export const useOfferStore = createWithEqualityFn(
  immer<State & Actions>((set) => ({
    activeStep: OfferStep.Details,
    compare: INIT_COMPARE_STATE,
    setActiveStep: (activeStep) => set(() => ({ activeStep })),
    setCompareState: (key, updatedState) =>
      set((state) => {
        if (updatedState === null) {
          // reset state
          state.compare[key] = defaultState;
          return;
        }
        state.compare[key] = {
          ...state.compare[key],
          ...updatedState,
          items: updatedState.items
            ? updatedState.items.reduce(
                (
                  acc,
                  { dayPrice, monthPrice, item: { catClass } },
                ) => ({
                  ...acc,
                  [catClass]: {
                    dayPrice: dayPrice
                      ? Number(dayPrice) * 10000
                      : null,
                    monthPrice: monthPrice
                      ? Number(monthPrice) * 10000
                      : null,
                  },
                }),
                {} as OfferComparisonItems,
              )
            : state.compare[key].items,
        };
      }),
    clearCompareState: () =>
      set(() => ({ compare: INIT_COMPARE_STATE })),
  })),
  shallow,
);
