import { CustomerType } from '../../../shared/types';

export const getCustomerTypeLabel = (type: CustomerType): string => {
  switch (type) {
    case CustomerType.MIRA:
      return 'Asiakas';
    case CustomerType.PROSPECT:
      return 'Prospekti';
    case CustomerType.PRIVATE:
      return 'Yksityisasiakas';
    default:
      console.warn('Customer type not recognized!');
      return '';
  }
};
