import React, { useContext, useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import NotificationProvider from '../../providers/Notification/NotificationProvider';

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export interface VisibleNotification {
  type: string;
  duration: number;
  severity: 'success' | 'warning' | 'error';
  message: string;
  key: number;
  title?: string;
  cancelText?: string;
  acceptText?: string;
  onAccept?: () => void;
}

export const Notification = <P extends any>(
  WrappedComponent: React.ComponentType<P>,
) => {
  function Notification(
    props: JSX.IntrinsicAttributes &
      P & { children?: React.ReactNode },
  ) {
    const { notification, setNotification } = useContext(
      NotificationProvider,
    );

    const [visibleNotificaton, setVisibleNotificaton] =
      useState<VisibleNotification>(notification);

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setOpen(false);
      setNotification(undefined);
    };

    useEffect(() => {
      if (visibleNotificaton?.message?.length > 0) {
        handleOpen();
      } else {
        handleClose();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleNotificaton]);

    useEffect(() => {
      if (notification?.message?.length > 0) {
        setVisibleNotificaton({
          ...notification,
          key: new Date().getTime(),
        });
      }
    }, [notification]);

    return (
      <>
        {visibleNotificaton?.type === 'SNACKBAR' ? (
          <>
            <Snackbar
              key={visibleNotificaton?.key}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={open}
              autoHideDuration={visibleNotificaton?.duration}
              onClose={handleClose}
            >
              <Alert
                variant="filled"
                onClose={handleClose}
                severity={visibleNotificaton?.severity}
                sx={{ width: '100%' }}
              >
                <Typography id="snackbar-text">
                  {visibleNotificaton?.message}
                </Typography>
              </Alert>
            </Snackbar>
          </>
        ) : visibleNotificaton?.type === 'MODAL' ? (
          <>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle
                sx={{
                  textAlign: 'center',
                }}
              >
                {visibleNotificaton?.title}
              </DialogTitle>
              <DialogContent>
                <Box
                  display="flex"
                  minWidth={500}
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <img
                      width={120}
                      src={'/rami-error.svg'}
                      alt="Error icon"
                    />
                  </Box>
                  <Box
                    p={2}
                    flex={3}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    {visibleNotificaton?.message}
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>
                  {visibleNotificaton?.cancelText ?? 'Sulje'}
                </Button>
                <Button
                  onClick={() => {
                    handleClose();
                    if (visibleNotificaton?.onAccept) {
                      visibleNotificaton.onAccept();
                    }
                  }}
                  color="primary"
                  variant="contained"
                >
                  {visibleNotificaton?.acceptText ?? 'Jatka'}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <>
            <Dialog
              key={visibleNotificaton?.key}
              disableScrollLock
              onClose={handleClose}
              open={open}
              fullWidth={true}
            >
              {visibleNotificaton && (
                <Alert severity={visibleNotificaton?.severity}>
                  <Typography id="modal-text">
                    {visibleNotificaton?.message}
                  </Typography>
                </Alert>
              )}
            </Dialog>
          </>
        )}
        <WrappedComponent {...props} />
      </>
    );
  }
  return Notification;
};

export default Notification;
