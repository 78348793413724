import Box from '@mui/material/Box';

import ClearIcon from '@mui/icons-material/Clear';

import Button from '@mui/material/Button';

import { useTheme } from '@mui/material';
import DefaultTooltip from '../Tooltips/DefaultTooltip';
import { GridApi } from 'ag-grid-community';

type ResetFiltersButtonProps = {
  gridApi: GridApi | undefined;
};

export const ResetFiltersButton = ({
  gridApi,
}: ResetFiltersButtonProps) => {
  const theme = useTheme();
  // FIXME:
  // gridApi?.addEventListener(
  //   'filterChanged',
  //   (event: FilterChangedEvent) => {
  //     const column = event.column;
  //     const columnName = column.getColId();
  //     const isVisible = event.visible;
  //     localStorage.setItem(
  //       `columnVisibility_${columnName}`,
  //       isVisible,
  //     );
  //   },
  // );
  return (
    <>
      <DefaultTooltip title="Tyhjennä suodatukset, jotka asetetaan sarakkeiden otsikoissa">
        <span>
          <Button
            // disabled={Object.keys(activeFilters || {}).length === 0}
            sx={{ height: 26 }}
            size="small"
            variant="outlined"
            onClick={() =>
              gridApi ? gridApi.setFilterModel(null) : null
            }
          >
            <ClearIcon style={{ fontSize: 16 }} />
            <Box
              sx={{
                [theme.breakpoints.down('xl')]: {
                  display: 'none',
                },
                [theme.breakpoints.up('xl')]: {
                  display: 'inline',
                },
              }}
            >
              Tyhjennä suodatukset
            </Box>
          </Button>
        </span>
      </DefaultTooltip>
    </>
  );
};
