import {
  useRef,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { LoadingState, useApi } from '../../hooks/useApi';

import { useQuery, useMutation } from '@tanstack/react-query';
// import { apiClient } from '../../config/apiClient';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import SaveIcon from '@mui/icons-material/Save';

import PricingProvider from '../../providers/Pricing/PricingProvider';
import RowsProvider from '../../providers/Rows/RowsProvider';
import NotificationProvider from '../../providers/Notification/NotificationProvider';
import useUserContext from '../../providers/User/UserProvider';

import AdminTable from './AdminTable';

import {
  PricingSheetRow,
  TableType,
  IndustryName,
  AgGridColumnView,
  ItemIndustryPercent,
} from '../../../../shared/types';

import AdminFilters from './AdminFilters';

import NoInternetConnectionAlert from '../../components/Common/NoInternetConnectionAlert';

import Saving from '../../components/PricingTable/Saving';

import CircularProgress from '@mui/material/CircularProgress';

import { postItems } from '../../services/items';

import EditItemsDialog from '../../components/PricingTable/EditItemsDialog';

import useOnlineStatus from '../../hooks/useOnlineStatus';
import useRowsHistory from '../../hooks/useRowsHistory';

import { createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

import SelectAdminColumns from './SelectAdminColumns';

import { sortByCatClass } from '../../../../shared/sortByCatClass';

import History from '../../components/History/History';
import { isHistoryChangedInAdminSide } from '../../components/History/changeChecks';
import {
  ExternalFilterName,
  ExternalFilterType,
  useFilters,
} from '../../hooks/useFilters';
import { industries } from '../../../../shared/industries';
import { useColumns } from '../../hooks/useColumns';
import { getItemIndustryPercents } from '../../services/itemIndustryPercents';
import { PRICE_PERCENTAGE_COEFFICIENT_KEYS } from '../../../../shared/constants';

import useItemIndustryPercentsContext from '../../providers/Rows/ItemIndustryPercents/ItemIndustryPercentsProvider';

import { getChangedRows } from './AdminSaveHelpers/helpers';
import { flatItems } from '../../utils/flatItems';
import { ItemIndustryPercentActions } from '../../providers/Rows/ItemIndustryPercents/itemIndustryPercentsReducer';
import { useHierarchy } from '../../hooks/useHierarchy';

const AdminTableContainer: React.FC = () => {
  const theme = createTheme();

  const agGridRef = useRef<any>();

  const { pricingSheetRows, dispatchPricingSheetRows, updateRow } =
    useContext(RowsProvider);

  const { setItemFilterValue } = useContext(PricingProvider);
  const { setNotification } = useContext(NotificationProvider);
  const { setHasUnsavedChanges } = useUserContext();

  const { itemIndustryPercents, dispatchItemIndustryPercents } =
    useItemIndustryPercentsContext();

  const isOnline = useOnlineStatus();
  const [
    rowsHistory,
    currentRowsInHistory,
    currentItemIndustryPercentsInHistory,
    setRowsHistory,
    undo,
    undoToIndex,
    redo,
    redoToIndex,
    currentIndex,
    setCurrentIndex,
  ] = useRowsHistory();

  const { data: hierarchyTree } = useHierarchy();
  const [items, isLoadingHierarchy, hierarchyError] = useApi<
    PricingSheetRow[]
  >(`/items?includeHidden=true&includeRemoved=true`);

  useEffect(() => {
    if (hierarchyError) {
      setNotification({
        severity: 'error',
        message: 'Virhe ladatessa tuotteita palvelimelta!',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hierarchyError]);

  const [loading, setLoading] = useState(true);
  const [savingState, setSavingState] = useState<boolean>(false);
  const [savingTimeStamp, setSavingTimeStamp] = useState<Date>();

  const [industry, setIndustry] = useState<IndustryName>(
    industries[0] as IndustryName,
  );

  const industryIndex = industries.indexOf(industry) + 1;

  const itemIndustryPercentsQuery = useQuery({
    queryKey: ['itemIndustryPercents'],
    queryFn: () => getItemIndustryPercents(),
    enabled: industryIndex !== undefined,
    // disable background fetching
    staleTime: Infinity,
  });

  const postItemsToServer = useMutation({ mutationFn: postItems });

  const handleUpdateItemIndustryItems = (
    itemIndustryPercents: ItemIndustryPercent[],
  ) => {
    dispatchItemIndustryPercents({
      type: ItemIndustryPercentActions.UpdateItems,
      itemIndustryPercents: itemIndustryPercents,
    });
  };

  // initial load
  useEffect(() => {
    if (!itemIndustryPercents) {
      handleUpdateItemIndustryItems(
        itemIndustryPercentsQuery.data as ItemIndustryPercent[],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemIndustryPercentsQuery]);

  // force itemIndustryPercents change
  // for some reason changes does not trigger ag grid to update in coefficientPercentValueGetter
  useEffect(() => {
    if (!!itemIndustryPercents) {
      updateGridColumnView(gridColumnView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemIndustryPercents]);

  const handleChangeItemIndustryItem = (
    catClass: string,
    industry: { id: number; name: IndustryName },
    field: string,
    newValue: any,
  ) => {
    dispatchItemIndustryPercents({
      type: ItemIndustryPercentActions.ChangeItem,
      catClass: catClass,
      industry: industry,
      field: field,
      newValue: newValue,
    });
  };

  const handleAddItemIndustryItem = (
    newItem: ItemIndustryPercent,
  ) => {
    dispatchItemIndustryPercents({
      type: ItemIndustryPercentActions.AddItems,
      newItems: newItem,
    });
  };

  const isLoadingItemIndustryPercents =
    itemIndustryPercentsQuery.isPending;

  useEffect(() => {
    setCurrentItemIndustryPercentsState(
      itemIndustryPercentsQuery.data as ItemIndustryPercent[],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingItemIndustryPercents]);

  const {
    columnDefs,
    groupingStyle,
    gridColumnView,
    groupingValues,
    setDefaultGroupingValues,
    updateGridColumnView,
    updateGroupingValues,
  } = useColumns(AgGridColumnView.ADMIN_COMMON, {
    items,
    industry,
    industryIndex,
    hierarchyTree,
    handleUpdateRowValue: updateRow,
    handleChangeItemIndustryItem,
    handleAddItemIndustryItem,
    itemIndustryPercents,
  });

  const {
    activeFilters,
    addFilter,
    toggleActiveFilter,
    isChecked,
    applyActiveFilters,
    getFilters,
    clearFilters,
    removeFilter,
  } = useFilters();

  const updateTimeStampToNewestChange = () => {
    setSavingTimeStamp(
      new Date(rowsHistory[rowsHistory.length - 1]?.timeStamp),
    );
  };

  const [error, setError] = useState<boolean>(false);

  // currentRowsState represents rows that are saved to db
  const [currentRowsState, setCurrentRowsState] = useState<
    PricingSheetRow[] | []
  >(items ? items : []);

  // currentRowsState represents itemIndustryPercents that are saved to db
  const [
    currentItemIndustryPercentsState,
    setCurrentItemIndustryPercentsState,
  ] = useState<ItemIndustryPercent[] | []>(
    itemIndustryPercentsQuery.data
      ? (itemIndustryPercentsQuery.data as ItemIndustryPercent[])
      : [],
  );

  useEffect(() => {
    if (
      items &&
      items.length > 0 &&
      isLoadingHierarchy !== LoadingState.IsLoading
    ) {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingHierarchy]);

  const isStateAndSaveSame =
    currentIndex === 0 && !savingTimeStamp
      ? true
      : rowsHistory.length > 1
        ? savingTimeStamp?.getTime() ===
          new Date(rowsHistory[currentIndex]?.timeStamp).getTime()
        : true;

  const isUnsavedChanges = useMemo(
    () =>
      isHistoryChangedInAdminSide(
        currentRowsState,
        pricingSheetRows,
        currentItemIndustryPercentsState,
        itemIndustryPercents,
      ),
    [
      currentRowsState,
      pricingSheetRows,
      currentItemIndustryPercentsState,
      itemIndustryPercents,
    ],
  );

  const shouldSaveRowsState = useMemo(
    () =>
      isHistoryChangedInAdminSide(
        currentRowsInHistory,
        pricingSheetRows,
        currentItemIndustryPercentsInHistory,
        itemIndustryPercents,
      ),
    [
      currentRowsInHistory,
      pricingSheetRows,
      currentItemIndustryPercentsInHistory,
      itemIndustryPercents,
    ],
  );

  useEffect(() => {
    setHasUnsavedChanges(isUnsavedChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnsavedChanges]);

  useEffect(() => {
    saveRowsState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnsavedChanges, pricingSheetRows, itemIndustryPercents]);

  const saveRowsState = () => {
    if (
      pricingSheetRows.length > 0 &&
      itemIndustryPercents &&
      shouldSaveRowsState &&
      !loading
    ) {
      setRowsHistory([
        ...rowsHistory,
        {
          timeStamp: Date.now(),
          rows: pricingSheetRows,
          itemIndustryPercents: itemIndustryPercents,
          roundingBasisSheet: 'TWODECIMALS',
          roundingBasisPdf: 'TWODECIMALS',
        },
      ]);
      setCurrentIndex(rowsHistory.length);
    }
  };

  const save = (
    rows: PricingSheetRow[],
    moveInHistory: boolean = false,
    itemIndustryPercents?: ItemIndustryPercent[],
  ) => {
    if (!moveInHistory) {
      saveItems(rows, itemIndustryPercents);
    }
  };

  const onSaveClick = () => {
    const newItemIndustryPercents = itemIndustryPercents?.filter(
      (obj) => {
        return !currentItemIndustryPercentsState.some((obj2) => {
          return (
            obj.catClass === obj2.catClass &&
            obj.industry.id === obj2.industry.id
          );
        });
      },
    );

    saveItems(
      pricingSheetRows,
      { new: newItemIndustryPercents, changed: itemIndustryPercents },
      false,
    );
  };

  const getRowsSortedByCatClass = (items: PricingSheetRow[]) =>
    sortByCatClass([...items]);

  const saveItems = async (
    rows: PricingSheetRow[],
    percentRows: any,
    moveInHistory: boolean = false,
  ) => {
    setError(false);
    setSavingState(true);

    const changedItemsWithChangedProperties = getChangedRows(
      currentRowsState,
      rows,
    );
    const changedPercentItemsWithChangedProperties = getChangedRows(
      currentItemIndustryPercentsState,
      percentRows.changed,
    );

    // totally new rows, (all percents were before 0%)
    const newPercentRows =
      percentRows.new?.map((i: ItemIndustryPercent) => {
        return {
          value: i,
          changedProperties: PRICE_PERCENTAGE_COEFFICIENT_KEYS,
        };
      }) || [];

    const combinedPercentItemsWithChangedProperties =
      changedPercentItemsWithChangedProperties.concat(newPercentRows);

    let itemsFromDbAfterSave = [...pricingSheetRows];
    let itemIndustryPercentsFromDbAfterSave = itemIndustryPercents
      ? [...itemIndustryPercents]
      : [];

    if (
      changedItemsWithChangedProperties.length > 0 ||
      combinedPercentItemsWithChangedProperties.length > 0 ||
      !isStateAndSaveSame
    ) {
      try {
        const response = await postItemsToServer.mutateAsync({
          items: changedItemsWithChangedProperties,
          itemIndustryPercents:
            combinedPercentItemsWithChangedProperties,
        });

        itemsFromDbAfterSave = [...response?.data?.items];
        itemIndustryPercentsFromDbAfterSave = [
          ...response?.data?.itemIndustryPercents,
        ];

        if (response?.status === 200) {
          setCurrentRowsState([...rows]);
          setNotification({
            type: 'SNACKBAR',
            duration: 3000,
            severity: 'success',
            message: 'Muutokset tallennettu!',
          });

          if (!moveInHistory) {
            setSavingTimeStamp(
              new Date(rowsHistory[currentIndex]?.timeStamp),
            );
          }
        } else {
          setNotification({
            type: 'SNACKBAR',
            duration: 3000,
            severity: 'error',
            message: 'Muutoksia tallentaessa tapahtui virhe!',
          });
          setError(true);
        }
      } catch (error) {
        setNotification({
          type: 'SNACKBAR',
          duration: 3000,
          severity: 'error',
          message: 'Muutoksia tallentaessa tapahtui virhe!',
        });
        setError(true);
      } finally {
        if (itemsFromDbAfterSave) {
          const newItemsFromDb = [
            ...getRowsSortedByCatClass(itemsFromDbAfterSave),
          ];

          const newItemIndustryPercentsFromDb = [
            ...itemIndustryPercentsFromDbAfterSave,
          ];

          const flattedPercents = flatItems(
            newItemIndustryPercentsFromDb,
          );

          setCurrentRowsState([...newItemsFromDb]);

          setCurrentItemIndustryPercentsState([...flattedPercents]);

          dispatchPricingSheetRows({
            type: 'updatePricingSheetRows',
            newRows: [...newItemsFromDb],
          });
          dispatchItemIndustryPercents({
            type: ItemIndustryPercentActions.UpdateItems,
            itemIndustryPercents: [...flattedPercents],
          });
        } else {
          setCurrentRowsState([...pricingSheetRows]);
          setCurrentItemIndustryPercentsState(
            itemIndustryPercents ? [...itemIndustryPercents] : [],
          );
        }
        setSavingState(false);
      }
    }

    setSavingState(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isUnsavedChanges && !error && isOnline) {
        saveItems(pricingSheetRows, itemIndustryPercents, false);
      }
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUnsavedChanges,
    error,
    pricingSheetRows,
    itemIndustryPercents,
    currentIndex,
  ]);

  // Initialize / clear state
  useEffect(() => {
    // These filters are active by default
    addFilter([
      {
        name: ExternalFilterName.ShowRemovedItems,
        type: ExternalFilterType.AND,
      },
      {
        name: ExternalFilterName.ShowOnlyInPricingRows,
        type: ExternalFilterType.AND,
      },
    ]);
    return () => {
      clearFilters();
      setHasUnsavedChanges(false);
      setItemFilterValue('');
      dispatchPricingSheetRows({
        type: 'updatePricingSheetRows',
        newRows: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gridColumnView === AgGridColumnView.ADMIN_KIT_NAME_LIST) {
      addFilter([
        {
          name: ExternalFilterName.showKitTypeOnly,
          type: ExternalFilterType.AND,
        },
      ]);
    } else {
      removeFilter({
        name: ExternalFilterName.showKitTypeOnly,
        type: ExternalFilterType.AND,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridColumnView]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mb: 1,
          [theme.breakpoints.down('xl')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Grid
          gap={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.down('lg')]: {
              flexDirection: 'column',
              width: '100%',
            },
          }}
        >
          <EditItemsDialog
            saveItems={saveItems}
            setCurrentRowsState={setCurrentRowsState}
            getRowsSortedByCatClass={getRowsSortedByCatClass}
            rowsHistory={rowsHistory}
            isUnsavedChanges={isUnsavedChanges}
            updateTimeStampToNewestChange={
              updateTimeStampToNewestChange
            }
            clearAllSelections={agGridRef.current?.clearAllSelections}
            industry={industry}
            industryIndex={industryIndex}
            setIndustry={setIndustry}
            gridColumnView={gridColumnView}
            updateGridColumnView={updateGridColumnView}
          />
        </Grid>
      </Box>
      <Grid
        gap={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            width: '100%',
            mt: 2,
          },
        }}
      >
        <Box
          gap={2}
          sx={{
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              display: 'inline',
            },
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          {!isOnline ? (
            <Box
              mr={2}
              sx={{
                [theme.breakpoints.down('md')]: {
                  mb: 2,
                },
              }}
            >
              <NoInternetConnectionAlert />
            </Box>
          ) : null}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box>
              <History
                rowsHistory={rowsHistory}
                currentIndex={currentIndex}
                undo={undo}
                undoToIndex={undoToIndex}
                redo={redo}
                redoToIndex={redoToIndex}
                save={save}
                tableType={TableType.AdminTable}
              />
            </Box>

            <Box sx={{ ml: 2 }}>
              <Saving
                saveButton={
                  <Button
                    endIcon={<SaveIcon />}
                    sx={{ height: 'fit-content' }}
                    disabled={
                      savingState ||
                      (!isUnsavedChanges && isStateAndSaveSame) ||
                      isLoadingHierarchy === LoadingState.IsLoading
                    }
                    variant="contained"
                    onClick={onSaveClick}
                  >
                    {savingState
                      ? 'Tallennetaan...'
                      : (!isUnsavedChanges && isStateAndSaveSame) ||
                          isLoadingHierarchy ===
                            LoadingState.IsLoading
                        ? 'Tallennettu'
                        : 'Tallenna'}
                  </Button>
                }
                savingTimeStamp={savingTimeStamp}
                rowsHistory={rowsHistory}
              />
            </Box>
          </Box>
        </Box>
        <SelectAdminColumns
          gridColumnView={gridColumnView}
          updateGridColumnView={updateGridColumnView}
          industry={industry}
          setIndustry={setIndustry}
          refreshCells={agGridRef.current?.refreshCells}
        />
      </Grid>
      <Divider />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          ml: -2,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'start',
          },
        }}
      >
        <AdminFilters
          toggleActiveFilter={toggleActiveFilter}
          isChecked={isChecked}
        />
      </Box>
      <Divider />

      <Box>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <AdminTable
              ref={agGridRef}
              items={items ? items : []}
              industry={industry}
              itemIndustryPercents={itemIndustryPercents}
              activeFilters={activeFilters}
              applyActiveFilters={applyActiveFilters}
              loading={loading}
              setCurrentRowsState={setCurrentRowsState}
              getRowsSortedByCatClass={getRowsSortedByCatClass}
              getFilters={getFilters}
              columnDefs={columnDefs}
              gridColumnView={gridColumnView}
              groupingStyle={groupingStyle}
              groupingValues={groupingValues}
              setDefaultGroupingValues={setDefaultGroupingValues}
              updateGroupingValues={updateGroupingValues}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default AdminTableContainer;
