import { PricingSheetRow, RowFieldType } from '../../../shared/types';
import { GroupingType } from '../hooks/useColumns';

export const getGroupFilterForGroup = (
  field: RowFieldType,
  value: string,
  groupingStyle: GroupingType,
) => {
  const isRootRow =
    field === 'productGroup' || field === 'pimProductGroup';
  const isFirstChildRow =
    field === 'productLine' || field === 'pimProductLine';
  const isSecondChildRow =
    field === 'category' || field === 'pimCategory';

  const isMiraGrouping = groupingStyle === 'MIRA';

  return (countRow: PricingSheetRow) => {
    return isRootRow
      ? isMiraGrouping
        ? countRow.productGroup === value
        : countRow.pimProductGroup === value
      : isFirstChildRow
        ? isMiraGrouping
          ? countRow.productLine === value
          : countRow.pimProductLine === value
        : isSecondChildRow
          ? isMiraGrouping
            ? countRow.category === value
            : countRow.pimCategory === value
          : false;
  };
};
