import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
  useContext,
} from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import { clone } from '../../../utils/genericUtils';
import {
  IndustryName,
  ItemIndustryPercent,
  PricingSheetRow,
} from '../../../../../shared/types';
import NotificationProvider from '../../../providers/Notification/NotificationProvider';
import constructNewRow from '../helpers/constructNewRow';
import { useTheme } from '@mui/material/styles';
import {
  DISCOUNT_LIMIT,
  COEFFICIENT_LIMIT,
  COEFFICIENT_LIMIT_LIGHT,
} from '../../../utils/pricingFormulas';
import { countRemovalDayPrice } from '../../../../../shared/countRemovalDayPrice';
import { countRemovalMonthPrice } from '../../../../../shared/countRemovalMonthPrice';
import { countPriceCoefficient } from '../../../utils/pricingFormulas';
import { ICellEditorParams } from 'ag-grid-community';
import ValidationTooltip from '../../Tooltips/ValidationTooltip';
import { PRICE_FACTOR } from '../../../../../shared/constants';

import { getSuffix } from '../../../../../shared/utils/getSuffix';

const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
const KEY_ARROW_LEFT = 'ArrowLeft';
const KEY_ARROW_RIGHT = 'ArrowRight';
const KEY_CTRL = 'Control';
const KEY_A = 'a';
const KEY_COMMA = ',';
const KEY_PERIOD = '.';
const KEY_SUBTRACT = '-';

/*
 * Passing props and ref should be done using by forwardRef.
 * However, for some reason, passing refs to CustomInput, do not work here.
 * Therefore we pass ref as a custom prop.
 * Issue in GitHub: https://github.com/s-yadav/react-number-format/issues/270
 */
const SmallTextField = ({
  // @ts-ignore
  inputRef,
  // @ts-ignore
  showZeroPriceColor,
  // @ts-ignore
  showWarningColor,
  // @ts-ignore
  showLightWarningColor,
  // @ts-ignore
  showBelow70WarningColor,
  // @ts-ignore
  showCriticalPriceColor,
  ...props
}) => {
  const theme = useTheme();

  return (
    <TextField
      inputRef={inputRef}
      InputProps={{
        style: {
          height: 30,
          width: '100%',
        },
      }}
      sx={
        showZeroPriceColor
          ? {
              backgroundColor: theme.palette.warning.dark,
              '.Mui-disabled': {
                // inspect view
                opacity: 1,
                WebkitTextFillColor: 'rgb(0, 50, 135)',
              },
            }
          : showWarningColor
            ? {
                backgroundColor: theme.palette.error.light,
                '.Mui-disabled': {
                  // inspect view
                  opacity: 1,
                  WebkitTextFillColor: 'rgb(0, 50, 135)',
                },
              }
            : showCriticalPriceColor
              ? {
                  backgroundColor: theme.palette.error.light,
                  '.Mui-disabled': {
                    // inspect view
                    opacity: 1,
                    WebkitTextFillColor: 'rgb(0, 50, 135)',
                  },
                }
              : showBelow70WarningColor
                ? {
                    backgroundColor: '#f48fb1',
                    '.Mui-disabled': {
                      // inspect view
                      opacity: 1,
                      WebkitTextFillColor: 'rgb(0, 50, 135)',
                    },
                  }
                : showLightWarningColor
                  ? {
                      backgroundColor: theme.palette.warning.light,
                      '.Mui-disabled': {
                        // inspect view
                        opacity: 1,
                        WebkitTextFillColor: 'rgb(0, 50, 135)',
                      },
                    }
                  : null
      }
      size="small"
      {...props}
    />
  );
};

export type NumericEditorV2Params =
  ICellEditorParams<PricingSheetRow> & {
    allowNegative?: boolean;
    required?: boolean;
    handleUpdateRowValue?: (row: PricingSheetRow) => void;
    handleChangeItemIndustryItem?: any;
    handleAddItemIndustryItem?: (
      newItem: ItemIndustryPercent,
    ) => void;
    industry?: IndustryName;
    industryIndex?: number;
    itemIndustryPercents?: any;
    defaultField?: string;
  };

const NumericEditorV2 = memo(
  forwardRef((props: NumericEditorV2Params, ref: any) => {
    const {
      allowNegative = true,
      column,
      data,
      handleUpdateRowValue = () => null,
      handleChangeItemIndustryItem,
      required = false,
      industry,
      industryIndex,
      itemIndustryPercents,
      handleAddItemIndustryItem,
      defaultField,
    } = props;
    const row = data ? clone(data) : undefined;
    const columnId = column.getColId();
    const field = defaultField ?? columnId;

    const createInitialState = () => {
      let startValue;
      let highlightAllOnFocus = true;

      if (props.eventKey === KEY_BACKSPACE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (props.eventKey && !isNaN(Number(props.eventKey))) {
        // if a letter was pressed, we start with the letter
        startValue = Number(props.eventKey);
        highlightAllOnFocus = false;
      } else {
        // otherwise we start with the current value
        startValue = props.value;
        if (props.eventKey === KEY_F2) {
          highlightAllOnFocus = false;
        }
      }

      return {
        value: startValue,
        initialValue: props.value,
        highlightAllOnFocus,
      };
    };

    const initialState = createInitialState();
    const [value, setValue] = useState<any>(initialState.value);
    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
      initialState.highlightAllOnFocus,
    );
    const refInput = React.createRef();

    // focus on the input
    useEffect(() => {
      // get ref from React component
      const eInput: any = refInput.current;
      eInput?.focus();
      if (highlightAllOnFocus) {
        eInput?.select();

        setHighlightAllOnFocus(false);
      } else {
        // when we started editing, we want the caret at the end, not the start.
        // this comes into play in two scenarios:
        //   a) when user hits F2
        //   b) when user hits a printable character
        const length = eInput?.value ? eInput?.value.length : 0;
        if (length > 0) {
          eInput?.setSelectionRange(length, length);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Utility Methods */
    const cancelBeforeStart =
      props.eventKey && '1234567890'.indexOf(props.eventKey) < 0;

    const isLeftOrRight = (event: { key: string }) => {
      return (
        [KEY_ARROW_LEFT, KEY_ARROW_RIGHT].indexOf(event.key) > -1
      );
    };

    const isCharNumeric = (charStr: string) => {
      return !!/\d/.test(charStr);
    };

    const isKeyPressedNumeric = (event: { key: any }) => {
      const charStr = event.key;
      return isCharNumeric(charStr);
    };

    const isDecimalSeparator = (event: { key: string }) => {
      return event.key === KEY_COMMA || event.key === KEY_PERIOD;
    };

    const isBackspace = (event: { key: string }) => {
      return event.key === KEY_BACKSPACE;
    };

    const finishedEditingPressed = (event: { key: any }) => {
      const key = event.key;
      return key === KEY_ENTER || key === KEY_TAB;
    };

    const isControl = (event: { key: string }) => {
      return event.key === KEY_CTRL;
    };

    const isA = (event: { key: string }) => {
      return event.key === KEY_A;
    };

    const isSubtract = (event: { key: string }) => {
      return event.key === KEY_SUBTRACT;
    };

    const onKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (inputFocused) {
        if (isLeftOrRight(event) || isBackspace(event)) {
          event.stopPropagation();
          return;
        }

        if (isControl(event) || isA(event)) {
          if (isA(event)) {
            const eInput: any = refInput.current;
            eInput?.focus();
            eInput?.select();

            setHighlightAllOnFocus(false);
          }
        }

        const fieldsSubtractNotAllowed = [
          'dayPrice',
          'monthPrice',
          'dayPriceCoefficient1',
          'dayPriceCoefficient2',
          'dayPriceCoefficient3',
          'dayPriceCoefficient4',
          'dayPriceCoefficient5',
          'dayPriceCoefficient6',
          'dayPriceCoefficient7',
          'dayPriceCoefficientList',
          'monthPriceCoefficient1',
          'monthPriceCoefficient2',
          'monthPriceCoefficient3',
          'monthPriceCoefficient4',
          'monthPriceCoefficient5',
          'monthPriceCoefficient6',
          'monthPriceCoefficient7',
          'monthPriceCoefficientList',
        ];

        const isNotAllowedSubtract = (
          event: React.KeyboardEvent<HTMLInputElement>,
        ) => {
          return (
            isSubtract(event) &&
            fieldsSubtractNotAllowed.includes(field)
          );
        };

        if (
          (!finishedEditingPressed(event) &&
            !isKeyPressedNumeric(event) &&
            !isSubtract(event) &&
            !isDecimalSeparator(event)) ||
          isNotAllowedSubtract(event)
        ) {
          if (event.preventDefault) event.preventDefault();
        }

        if (finishedEditingPressed(event)) {
          props.stopEditing();
        }
      }
    };

    const { setNotification } = useContext(NotificationProvider);

    const showError = () => {
      setNotification({
        type: 'SNACKBAR',
        duration: 4000,
        severity: 'error',
        message: 'Virhe päivittessä uutta arvoa!',
      });
    };

    const constructNewRowInCellEdit = () => {
      if (row) {
        // do not update value to cell if value is not changed in editor
        if (initialState.initialValue === value) {
          return;
        }

        const isItemIndustryPercentField =
          field === 'dayPricePercent1' ||
          field === 'dayPricePercent2' ||
          field === 'dayPricePercent3' ||
          field === 'dayPricePercent4' ||
          field === 'dayPricePercent5' ||
          field === 'dayPricePercent6' ||
          field === 'dayPricePercent7' ||
          field === 'dayPricePercentList' ||
          field === 'monthPricePercent1' ||
          field === 'monthPricePercent2' ||
          field === 'monthPricePercent3' ||
          field === 'monthPricePercent4' ||
          field === 'monthPricePercent5' ||
          field === 'monthPricePercent6' ||
          field === 'monthPricePercent7' ||
          field === 'monthPricePercentList';

        const itemIndustryPercentRow = itemIndustryPercents?.find(
          (itemIndustryPercentItem: any) =>
            itemIndustryPercentItem.catClass === row.catClass &&
            itemIndustryPercentItem.industry.name === industry,
        );
        const newRow = constructNewRow(field, row, value);

        if (
          newRow &&
          isItemIndustryPercentField &&
          industry &&
          handleAddItemIndustryItem
        ) {
          if (itemIndustryPercentRow) {
            handleChangeItemIndustryItem(
              itemIndustryPercentRow.catClass,
              itemIndustryPercentRow.industry,
              field,
              value * 100,
            );
          } else {
            // in case that item do have any percents
            let { id, ...restOfRow } = row;

            handleAddItemIndustryItem({
              ...restOfRow,
              itemId: row.id,
              [field]: value * 100,
              industryId: industryIndex,
              industry: { id: industryIndex, name: industry },
            } as ItemIndustryPercent);
          }

          return true;
        } else if (newRow) {
          handleUpdateRowValue(newRow);
          return true;
        } else {
          showError();
          return false;
        }
      }
    };

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the provider, on completion of editing
        // this does not return value to grid, we update value to provider
        getValue() {
          // update field we are editing to provider
          constructNewRowInCellEdit();
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return cancelBeforeStart;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          if (required && !value) {
            return true;
          }
          // will reject the number if it greater than 1,000,000
          // not very practical, but demonstrates the method.
          return value > 1000000;
        },
      };
    });

    const [inputFocused, setInputFocused] = useState(false);

    const handleOnFocus = () => {
      setInputFocused(true);
    };

    const onBlur = () => {
      setInputFocused(false);
    };

    const handleChange = (value: {
      floatValue: number | undefined;
    }) => {
      setValue(value.floatValue);
    };

    const removalDayPrice = countRemovalDayPrice(
      Number(row?.rentalReadyPrice),
      Number(row?.depreciationPeriod),
      Number(row?.targetUtilRate) / 100,
    );

    const removalMonthPrice = countRemovalMonthPrice(
      Number(row?.rentalReadyPrice),
      Number(row?.depreciationPeriod),
      Number(row?.targetUtilRate) / 100,
    );

    const coefficientDayPrice =
      value && removalDayPrice
        ? countPriceCoefficient(Number(value), removalDayPrice)
        : null;

    const coefficientMonthPrice =
      value && removalMonthPrice
        ? countPriceCoefficient(Number(value), removalMonthPrice)
        : null;

    const showPriceZeroWarning =
      value === 0 || value === undefined || value === null;

    const showDayPriceWarning =
      typeof coefficientDayPrice === 'number'
        ? coefficientDayPrice < COEFFICIENT_LIMIT
          ? true
          : false
        : false;

    const showMonthPriceWarning =
      typeof coefficientMonthPrice === 'number'
        ? coefficientMonthPrice < COEFFICIENT_LIMIT
          ? true
          : false
        : false;

    const showDayPriceLightWarning =
      typeof coefficientDayPrice === 'number'
        ? coefficientDayPrice < COEFFICIENT_LIMIT_LIGHT
          ? true
          : false
        : false;

    const showMonthPriceLightWarning =
      typeof coefficientMonthPrice === 'number'
        ? coefficientMonthPrice < COEFFICIENT_LIMIT_LIGHT
          ? true
          : false
        : false;

    const showDayPriceBelow70PercentWarning =
      typeof value === 'number'
        ? value < -(1 - DISCOUNT_LIMIT) * 100
          ? true
          : false
        : false;

    const showMonthPriceBelow70PercentWarning =
      typeof value === 'number'
        ? value < -(1 - DISCOUNT_LIMIT) * 100
          ? true
          : false
        : false;

    const showCriticalDayPriceWarning =
      typeof value === 'number' && row?.minDayPrice
        ? value < row?.minDayPrice / PRICE_FACTOR
          ? true
          : false
        : false;

    const showCriticalMonthPriceWarning =
      typeof value === 'number' && row?.minMonthPrice
        ? value < row?.minMonthPrice / PRICE_FACTOR
          ? true
          : false
        : false;

    return (
      <ValidationTooltip
        title={'Kenttä on pakollinen'}
        arrow
        open={required && !value}
      >
        <FormControl sx={{ width: '100%' }}>
          <NumberFormat
            id={`${row?.catClass}`}
            getInputRef={refInput} // we need this if we do not use customInput
            customInput={SmallTextField}
            showZeroPriceColor={
              (field === 'dayPrice' || field === 'monthPrice') &&
              showPriceZeroWarning
            }
            showWarningColor={
              field === 'dayPrice' && showDayPriceWarning
                ? true
                : field === 'monthPrice' && showMonthPriceWarning
                  ? true
                  : false
            }
            showCriticalPriceColor={
              field === 'dayPrice' && showCriticalDayPriceWarning
                ? true
                : field === 'monthPrice' &&
                    showCriticalMonthPriceWarning
                  ? true
                  : false
            }
            showBelow70WarningColor={
              field === 'discountDay' &&
              showDayPriceBelow70PercentWarning
                ? true
                : field === 'discountMonth' &&
                    showMonthPriceBelow70PercentWarning
                  ? true
                  : false
            }
            showLightWarningColor={
              field === 'dayPrice' && showDayPriceLightWarning
                ? true
                : field === 'monthPrice' && showMonthPriceLightWarning
                  ? true
                  : false
            }
            thousandSeparator={' '}
            allowedDecimalSeparators={['.', ',']}
            value={value}
            onValueChange={handleChange}
            onKeyDown={onKeyDown}
            onFocus={handleOnFocus}
            onBlur={onBlur}
            autoComplete="off"
            suffix={getSuffix(field)}
            inputRef={refInput}
            allowNegative={allowNegative}
          />
        </FormControl>
      </ValidationTooltip>
    );
  }),
);

export default NumericEditorV2;
