import { ValueGetterParams } from 'ag-grid-community';
import { PricingSheetRow } from '../../../../../shared/types';

const discountMonthGetter = (
  params: ValueGetterParams<PricingSheetRow>,
) => {
  const monthPrice = params.data?.monthPrice;
  const proposalMonthPrice = params.data?.proposalMonthPrice;

  return monthPrice && proposalMonthPrice
    ? Number(
        (
          ((monthPrice - proposalMonthPrice) / proposalMonthPrice) *
          100
        ).toFixed(2),
      )
    : null;
};

export default discountMonthGetter;
