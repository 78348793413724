import React, { useContext } from 'react';
import {
  AgGridColumnView,
  CriticalEquipmentRowKeys,
} from '../../../../../shared/types';
import {
  ImportType,
  ImportTableColumn,
  ImportChangesDataMapping,
} from '../../../../../shared/types/import';
import RowsProvider from '../../../providers/Rows/RowsProvider';
import { ExcelImportDialogCommonProps } from './ExcelImportDialog';
import { HeaderMapperTable } from './ExcelImportHeaderMapper';
import { getUpdatedRows } from './helpers';
import ExcelImportDialogBase from './ExcelImportDialogBase';

const ExcelImportCriticalEquipmentDialog = ({
  onClose,
  open,
}: ExcelImportDialogCommonProps) => {
  const { pricingSheetRows, dispatchPricingSheetRows } =
    useContext(RowsProvider);

  const importType = ImportType.CriticalEquipment;

  const tableColumns: ImportTableColumn<`criticalEquipmentItems.${CriticalEquipmentRowKeys}`>[] =
    [
      {
        key: 'criticalEquipmentItems.minPriceDay',
        name: 'Minimihinta pv',
      },
      {
        key: 'criticalEquipmentItems.minPriceMonth',
        name: 'Minimihinta kk',
      },
      { key: 'criticalEquipmentItems.from', name: 'Alkaa' },
      { key: 'criticalEquipmentItems.to', name: 'Päättyy' },
    ];

  const handleSave = (
    changesDataMapping: ImportChangesDataMapping,
  ) => {
    dispatchPricingSheetRows({
      type: 'updatePricingSheetRows',
      newRows: getUpdatedRows(pricingSheetRows, changesDataMapping),
    });
    onClose();
  };

  return (
    <ExcelImportDialogBase<AgGridColumnView.CRITICAL_EQUIPMENT>
      changesTableColumns={tableColumns}
      importType={importType}
      onClose={onClose}
      onSave={handleSave}
      open={open}
      rowData={pricingSheetRows}
      title={'Tuo kriittisen kaluston tiedot Excelistä'}
      renderHeaderMapper={(disabled) => (
        <HeaderMapperTable
          columns={tableColumns}
          disabled={disabled}
          importType={importType}
        />
      )}
    />
  );
};

export default ExcelImportCriticalEquipmentDialog;
