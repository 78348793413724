import {
  PricingSheetRow,
  RoundingBasis,
} from '../../../../../shared/types';

import { ValueGetterParams } from 'ag-grid-community';
import { getPriceRounded } from '../../../../../shared/getPriceRounded';

export const dayPriceGetter = (
  { data }: ValueGetterParams<PricingSheetRow>,
  roundingBasis: RoundingBasis,
) => {
  const dayPrice = data?.dayPrice;
  const showByOfferText = data?.showByOfferText;

  if (showByOfferText) {
    return showByOfferText
      ? 'Tarjouksen mukaan'
      : dayPrice
        ? dayPrice
        : null;
  }

  return getPriceRounded(dayPrice, roundingBasis);
};
