import { Envs } from '../../../shared/types';
import { api } from '../index';

export const getEnvs = async () => {
  return api
    .get('/envs')
    .then((response) => {
      return response.data as Envs;
    })
    .catch((error) => {
      return error?.response;
    });
};
