import { countDaysInBothRanges } from "../countDaysInBothRanges";
import { countDaysInDateRange } from "../countDaysInDateRange";

type DateRange = {
  start: Date;
  end: Date;
};

const isEquipmentActive = (
  sheetDateRange: DateRange,
  equipmentDateRange: DateRange,
  overlapPercentage: number,
) => {
  const overlapDays = countDaysInBothRanges(sheetDateRange, equipmentDateRange);

  const totalDays = countDaysInDateRange(
    sheetDateRange.start,
    sheetDateRange.end,
  );

  return overlapDays / totalDays >= overlapPercentage / 100;
};

export const isCriticalPriceActive = (
  sheetDateRange: DateRange,
  criticalDateRange: DateRange,
) => {
  return isEquipmentActive(sheetDateRange, criticalDateRange, 30);
};

export const isSurplusPriceActive = (
  sheetDateRange: DateRange,
  surplusDateRange: DateRange,
) => {
  return isEquipmentActive(sheetDateRange, surplusDateRange, 70);
};
