import { forwardRef, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import RowsProvider from '../../providers/Rows/RowsProvider';
import {
  AgGridColumnView,
  IndustryName,
  ItemGroup,
  ItemIndustryPercent,
  PricingSheetRow,
  TableType,
} from '../../../../shared/types';
import CircularProgress from '@mui/material/CircularProgress';
import EditViewDialog from '../../components/PricingTable/EditViewDialog';
import AgGridAdmin from './AgGridAdmin';
import { ExternalFilter } from '../../hooks/useFilters';
import { PricingSheetRowFilter } from '../../utils/pricingSheetRowFilters';
import { GroupingType } from '../../hooks/useColumns';
import { ColDef } from 'ag-grid-community';
import {
  FileDownloadOutlined,
  UploadFile,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import ExcelImportDialog from './ExcelImport/ExcelImportDialog';
import ExcelExportDialog from './ExcelExport/ExcelExportDialog';
import { useSortedGroupHierarchy } from '../../hooks/useSortedGroupHierarchy';

interface AdminTableProps {
  ref: any;
  items: PricingSheetRow[];
  industry: IndustryName;
  itemIndustryPercents: ItemIndustryPercent[] | undefined;
  activeFilters: ExternalFilter[];
  applyActiveFilters?: (row: PricingSheetRow) => boolean;
  getFilters: () => PricingSheetRowFilter[];
  loading: boolean;
  setCurrentRowsState: any;
  getRowsSortedByCatClass: (
    items: PricingSheetRow[],
  ) => PricingSheetRow[];
  groupingStyle: GroupingType;
  groupingValues: ItemGroup[];
  setDefaultGroupingValues: (value: GroupingType) => void;
  updateGroupingValues: (values: ItemGroup[]) => void;
  gridColumnView: AgGridColumnView;
  columnDefs: ColDef[];
}

const AdminTable = forwardRef<any, AdminTableProps>(
  (
    {
      items,
      industry,
      itemIndustryPercents,
      activeFilters,
      getFilters,
      loading,
      setCurrentRowsState,
      getRowsSortedByCatClass,
      applyActiveFilters,
      groupingStyle,
      groupingValues,
      setDefaultGroupingValues,
      updateGroupingValues,
      gridColumnView,
      columnDefs,
    },
    ref,
  ) => {
    const theme = useTheme();
    const {
      pricingSheetRows,
      dispatchPricingSheetRows,
      selectedRows,
    } = useContext(RowsProvider);
    const { isPending: isLoadingHierarchy, sortGroups } =
      useSortedGroupHierarchy();

    const [excelImportOpen, setExcelImportOpen] = useState(false);
    const [excelExportOpen, setExcelExportOpen] = useState(false);

    const showImportButton =
      gridColumnView !== AgGridColumnView.ADMIN_HIERARCHY &&
      gridColumnView !== AgGridColumnView.ADMIN_KIT_NAME_LIST;

    const getRowsWithFilters = (items: PricingSheetRow[]) => [
      ...items?.filter((row) => {
        return getFilters().every((activeFilter) =>
          activeFilter(row),
        );
      }),
    ];

    const initRows = (rows: PricingSheetRow[]) => {
      if (rows) {
        const rowsSorted = getRowsSortedByCatClass(rows);

        dispatchPricingSheetRows({
          type: 'updatePricingSheetRows',
          newRows: rowsSorted,
        });

        setCurrentRowsState(rowsSorted);
      }
    };

    useEffect(() => {
      if (items) {
        initRows(items);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const itemsCount = items?.length;
    const rowsCount = getRowsWithFilters([
      ...pricingSheetRows,
    ])?.length;

    const miraItemsCount = items?.filter(
      (row: PricingSheetRow) =>
        row.productGroup && row.productLine && row.category,
    )?.length;
    const miraRowsCount = getRowsWithFilters([
      ...pricingSheetRows,
    ])?.filter(
      (row: PricingSheetRow) =>
        row.productGroup && row.productLine && row.category,
    )?.length;

    const pimItemsCount = items?.filter(
      (row: PricingSheetRow) => row.pimPath,
    )?.length;
    const pimRowsCount = getRowsWithFilters([
      ...pricingSheetRows,
    ])?.filter((row: PricingSheetRow) => row.pimPath)?.length;

    const inPricingItemsCount = items?.filter(
      (row: PricingSheetRow) =>
        row.includeInPricing &&
        row.pimPath &&
        row.rentalReadyPrice &&
        row.targetUtilRate &&
        row.depreciationPeriod,
    ).length;
    const inPricingRowsCount = getRowsWithFilters([
      ...pricingSheetRows?.filter(
        (row: PricingSheetRow) =>
          row.includeInPricing &&
          row.pimPath &&
          row.rentalReadyPrice &&
          row.targetUtilRate &&
          row.depreciationPeriod,
      ),
    ]).length;

    const selectedRowsCount = selectedRows.length;

    const compactPreSelectionItemsCount = items?.filter(
      (row: PricingSheetRow) => row.belongsToCompactPreSelection,
    ).length;
    const compactPreSelectionRowsCount = getRowsWithFilters([
      ...pricingSheetRows?.filter(
        (row: PricingSheetRow) => row.belongsToCompactPreSelection,
      ),
    ]).length;

    const largePreSelectionItemsCount = items?.filter(
      (row: PricingSheetRow) => row.belongsToLargePreSelection,
    ).length;
    const largePreSelectionRowsCount = getRowsWithFilters([
      ...pricingSheetRows?.filter(
        (row: PricingSheetRow) => row.belongsToLargePreSelection,
      ),
    ]).length;

    const itemCountText = (
      <>
        Tuotteita yhteensä <b>{itemsCount}</b> ({rowsCount}), joista{' '}
        <b>{miraItemsCount}</b> ({miraRowsCount}) Mira-hierarkiassa ja{' '}
        <b>{pimItemsCount}</b> ({pimRowsCount}) PIM-hierarkiassa.
        Uuteen hinnastoon tulee tällä hetkellä{' '}
        <b>{inPricingItemsCount}</b> ({inPricingRowsCount}) tuotetta.
        Valittu <b>{selectedRowsCount}</b> tuotetta.
      </>
    );

    const PreSelectionItemCountText = (
      <>
        Suppeassa esivalinnassa <b>{compactPreSelectionItemsCount}</b>{' '}
        ({compactPreSelectionRowsCount}) , laajassa{' '}
        <b>{largePreSelectionItemsCount}</b> (
        {largePreSelectionRowsCount}) tuotetta.
      </>
    );
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Box>
            {loading || isLoadingHierarchy ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent={'space-between'}
                  alignItems={'flex-end'}
                  gap={2}
                  sx={{
                    [theme.breakpoints.up('md')]: {
                      flexWrap: 'nowrap',
                    },
                  }}
                >
                  <Grid item>
                    <Typography color="text.primary">
                      {itemCountText}
                    </Typography>
                    <Typography color="text.primary" sx={{ mt: 2 }}>
                      {PreSelectionItemCountText}
                    </Typography>
                  </Grid>
                  <Grid item flexShrink={0}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'grid',
                        gap: 2,
                        gridAutoFlow: 'column',
                        marginLeft: 'auto',
                      }}
                    >
                      {showImportButton && (
                        <Button
                          startIcon={<UploadFile />}
                          variant={'outlined'}
                          onClick={() => setExcelImportOpen(true)}
                        >
                          Tuo excelistä
                        </Button>
                      )}
                      <Button
                        startIcon={<FileDownloadOutlined />}
                        variant={'outlined'}
                        onClick={() => setExcelExportOpen(true)}
                      >
                        Lataa excel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <EditViewDialog
                    groupingStyle={groupingStyle}
                    groupingValues={groupingValues}
                    setDefaultGroupingValues={
                      setDefaultGroupingValues
                    }
                    updateGroupingValues={updateGroupingValues}
                  />
                </Box>

                <AgGridAdmin
                  ref={ref}
                  columnsDefs={columnDefs}
                  gridColumnView={gridColumnView}
                  groupingStyle={groupingStyle}
                  type={TableType.AdminTable}
                  rows={pricingSheetRows}
                  industry={industry}
                  itemIndustryPercents={itemIndustryPercents}
                  loading={loading}
                  externalFilters={activeFilters}
                  applyActiveFilters={applyActiveFilters}
                  initialGroupOrderComparator={sortGroups}
                />
              </>
            )}
          </Box>
        </Box>
        <ExcelImportDialog
          activeIndustry={industry}
          open={excelImportOpen}
          gridColumnView={gridColumnView}
          onClose={() => setExcelImportOpen(false)}
        />
        <ExcelExportDialog
          activeIndustry={industry}
          open={excelExportOpen}
          gridColumnView={gridColumnView}
          onClose={() => setExcelExportOpen(false)}
        />
      </>
    );
  },
);

export default AdminTable;
