import { PricingSheetRow } from '../../../../../shared/types';
import { countRemovalMonthPrice } from '../../../../../shared/countRemovalMonthPrice';

const removalMonthPriceFormatter = (props: {
  data: PricingSheetRow;
}) => {
  if (!props.data) {
    return '';
  }
  const { rentalReadyPrice, depreciationPeriod, targetUtilRate } =
    props?.data;

  const removalDayPrice = countRemovalMonthPrice(
    Number(rentalReadyPrice),
    Number(depreciationPeriod),
    Number(targetUtilRate) / 100,
  );

  return removalDayPrice ? removalDayPrice + ' €' : '';
};

export default removalMonthPriceFormatter;
