import { memo } from 'react';

import {
  IndustryName,
  ItemIndustryPercent,
  PricingSheetRow,
} from '../../../../../shared/types';
import coefficientPercentValueGetter from '../valueGetters/coefficientPercentValueGetter';
import { CoefficientPercentRenderer } from '../Renderers/CoefficientPercentRenderer';

import { GridColumnProps } from '../Columns/ColumnsBase';

import NumericEditorV2 from '../Editors/NumericEditorV2';
import { DAY_PRICE_COEFFICIENTS } from '../../../../../shared/constants';
import { getCatClassColDef } from '../helpers/getCatClassColDef';
import { ColDef, ValueGetterParams } from 'ag-grid-community';

interface OptionalColumnProps {
  items: PricingSheetRow[];
  industry: IndustryName;
  industryIndex: number;
  itemIndustryPercents: ItemIndustryPercent[];
  handleChangeItemIndustryItem: (
    catClass: string,
    industry: {
      id: number;
      name: IndustryName;
    },
    field: string,
    newValue: any,
  ) => void;
  handleAddItemIndustryItem: (newItem: ItemIndustryPercent) => void;
}

const AdminColumnsIndustryDay = ({
  gridGroupingValues,
  optionalColumnProps,
}: GridColumnProps<OptionalColumnProps>) => {
  const {
    items,
    industry,
    industryIndex,
    itemIndustryPercents,
    handleChangeItemIndustryItem,
    handleAddItemIndustryItem,
  } = optionalColumnProps;

  const dayPriceCoefficientsColumns = DAY_PRICE_COEFFICIENTS.map(
    ({ industryPercentKey, key, name }) =>
      ({
        field: industryPercentKey,
        headerName: name,
        valueGetter: (params: ValueGetterParams<PricingSheetRow>) =>
          coefficientPercentValueGetter(
            params,
            items,
            industryPercentKey,
            industry,

            itemIndustryPercents,
          ),
        cellRenderer: memo(CoefficientPercentRenderer),
        cellRendererParams: {
          coefficient: key,
        },
        editable: true,
        cellEditor: NumericEditorV2,
        cellEditorParams: {
          industry,
          industryIndex,
          itemIndustryPercents,
          handleChangeItemIndustryItem,
          handleAddItemIndustryItem,
        },
        cellStyle: () => ({
          color: '#000',
          borderLeft: '1px solid #ccc',
        }),
      }) as ColDef,
  );

  const groupingColumns: any = getCatClassColDef(gridGroupingValues);

  const finalColumns = groupingColumns.concat(
    dayPriceCoefficientsColumns,
  );

  return finalColumns;
};

export default AdminColumnsIndustryDay;
