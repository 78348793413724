import React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import GradingIcon from '@mui/icons-material/Grading';

import {
  DialogActions,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

export interface AcceptanceMatrixDialogProps {
  open: boolean;
  onClose: () => void;
}

function AcceptanceMatrixDialog(props: AcceptanceMatrixDialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const rows = [
    {
      col1: 'Tiskipäätös',
      col2: 'Arvioitu arvo / v. <0,1M€ \n Max. 1 v. sitoumus \n RR vakiosopimusehdot',
      col3: '',
      col4: '14 pv netto',
    },
    {
      col1: 'TP:n esimies',
      col2: 'Arvioitu arvo / v. <0,1M€ \n Max. 1 v. sitoumus \n RR vakiosopimusehdot',
      col3: '',
      col4: '14 pv netto',
    },
    {
      col1: 'Asiakkuuspäällikkö \n Telineliiketoiminnan projektijohto/työnjohto',
      col2: 'Arvioitu arvo / v. <0,1M€ \n Max. 1 v. sitoumus \n RR vakiosopimusehdot',
      col3: 'Arvioitu arvo / v. <0,1 M€ \n RR vakiosopimusehdot',
      col4: '21 pv netto',
    },
    {
      col1: 'Työpäällikkö',
      col2: 'Arvioitu arvo / v. <0,1M€ \n Max. 1 v. sitoumus \n RR vakiosopimusehdot',
      col3: '',
      col4: '21 pv netto',
    },
    {
      col1: 'Tuotepäällikkö',
      col2: 'Arvioitu arvo / v. <0,5M€ \n Max. 1 v. sitoumus \n RR vakiosopimusehdot',
      col3: '',
      col4: '',
    },
    {
      col1: 'Aluepäällikkö \n Palvelupäällikkö \n Myyntipäällikkö',
      col2: 'Arvioitu arvo / v. <0,5M€ \n Max. 1 v. sitoumus \n Kun sopimushedot poikkeavat RR vakiosopimusehdoista, sopimuksen vastuunrajoitus 15% sopimuksen arvosta',
      col3: 'Arvioitu arvo / v. <0,5 M€ \n RR vakiosopimusehdot tai sopimuksen vastuunrajoitus 15% sopimuksen arvosta',
      col4: '30 pv netto',
    },
    {
      col1: 'Liiketoimintajohtaja \n Myyntijohtaja',
      col2: 'Arvioitu arvo / v. <3M€ \nKun sopimushedot poikkeavat RR vakiosopimusehdoista, sopimuksen vastuunrajoitus 15% sopimuksen arvosta',
      col3: 'Arvioitu arvo / v. <1M€ \n RR vakiosopimusehdot tai sopimuksen vastuunrajoitus 15% sopimuksen arvosta',
      col4: '45 pv netto',
    },
    {
      col1: 'Toimitusjohtaja',
      col2: 'Arvioitu arvo / v. <5 M€ ',
      col3: 'Arvioitu arvo / v. <3 M€ ',
      col4: '60 pv netto',
    },
  ];

  return (
    <Dialog
      disableScrollLock
      onClose={handleClose}
      open={open}
      maxWidth={'lg'}
    >
      <DialogTitle>Hyväksymismatriisi</DialogTitle>
      <Box sx={{ paddingLeft: 3, paddingRight: 3, paddingBottom: 1 }}>
        <Table sx={{ whiteSpace: 'pre-line', padding: 8 }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>
                Päättäjä
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                {'Vuokrausliiketoiminta \n tarjous/sopimus'}
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                {'Projektiliiketoiminta \n tarjous/sopimus'}
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                Maksuehto
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.col1}>
                <TableCell>{row.col1}</TableCell>
                <TableCell>{row.col2}</TableCell>
                <TableCell>{row.col3}</TableCell>
                <TableCell>{row.col4}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <DialogActions sx={{ paddingRight: 3 }}>
        <Button autoFocus onClick={handleClose} variant="outlined">
          Sulje
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface AcceptanceMatrixProps {}
const AcceptanceMatrix: React.FC<AcceptanceMatrixProps> = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Button
        onClick={handleClickOpen}
        color="primary"
        variant="outlined"
        startIcon={<GradingIcon />}
      >
        Hyväksymismatriisi
      </Button>
      <AcceptanceMatrixDialog open={open} onClose={handleClose} />
    </Box>
  );
};

export default AcceptanceMatrix;
