import { DEFAULT_TIMEOUT } from '../../../shared/constants';
import { api } from '../index';

export const login = (email: string, password: string) => {
  const loginResponse = api
    .post('/session/login', { email, password })
    .then((response) => response)
    .catch((error) => error.response);
  return loginResponse;
};

export const checkSessionStatus = () => {
  const sessionResponse = api
    .get('/session/status', {
      timeout: DEFAULT_TIMEOUT,
    })
    .then((response) => response)
    .catch((error) => error.response);
  return sessionResponse;
};

export const logout = () => {
  const logoutResponse = api
    .delete('/session/logout')
    .then((response) => response)
    .catch((error) => error.response);
  return logoutResponse;
};
