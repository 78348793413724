import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  downloadOfferPdf,
  GetOfferPdfFnProps,
} from '../services/offerSheets';
import { DatabaseOfferWithItems } from '../../../shared/types/offers';
import { OFFER_SHEET_PDF_KEY } from '../../../shared/constants';
import { AxiosError } from 'axios';

type UseOfferPdfParams = GetOfferPdfFnProps &
  Omit<
    UseQueryOptions<
      DatabaseOfferWithItems,
      AxiosError,
      DatabaseOfferWithItems,
      [
        typeof OFFER_SHEET_PDF_KEY,
        number | string,
        string,
        boolean,
        boolean,
      ]
    >,
    'queryKey' | 'queryFn'
  >;

export const useOfferPdf = ({
  id,
  pdfName,
  draft,
  itemsOnly = false,
}: UseOfferPdfParams) =>
  useQuery({
    queryKey: [OFFER_SHEET_PDF_KEY, id, pdfName, draft, itemsOnly],
    queryFn: () =>
      downloadOfferPdf({
        id,
        draft,
        itemsOnly,
        pdfName,
      }),
    enabled: false,
  });

export const useOfferItemsPdf = ({
  id,
  pdfName,
  draft,
}: Omit<UseOfferPdfParams, 'itemsOnly'>) =>
  useOfferPdf({ id, pdfName, draft, itemsOnly: true });
