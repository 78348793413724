import { Checkbox } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { OfferItem } from '../../../../../shared/types';
import { useUpdateOfferItem } from '../../../hooks/useUpdateOfferItem';
import { memo } from 'react';
import { isSalesItem } from '../../../../../shared/helpers/isSalesItem';
import DefaultTooltip from '../../Tooltips/DefaultTooltip';

type OfferPricingBasisRendererProps =
  ICellRendererParams<OfferItem> & { includeRamiturvaOffer: boolean };

export const CheckboxRenderer = memo(
  (params: OfferPricingBasisRendererProps) => {
    const { column, data, includeRamiturvaOffer } = params;
    const { offerItemId, type } = data || {};
    const field = column?.getColId() as keyof OfferItem;

    // update offer sheet item mutation
    const { mutate: updateOfferItem } = useUpdateOfferItem();

    // show empty cell for sales items
    if (isSalesItem(type)) {
      return <></>;
    }
    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = event.target.checked;
      if (field) {
        updateOfferItem({
          id: String(offerItemId),
          item: {
            [field]: value,
          },
        });
      }
    };

    return (
      <DefaultTooltip
        style={{ display: 'inline' }}
        title={
          !includeRamiturvaOffer
            ? 'Tarjoukselle ei ole valittu RamiTurvaa'
            : ''
        }
      >
        <Checkbox
          style={{ paddingTop: 2 }}
          checked={data?.[field]}
          disabled={!includeRamiturvaOffer}
          onChange={handleChange}
        />
      </DefaultTooltip>
    );
  },
);
