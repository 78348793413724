import { UserRole } from '../../../shared/types';

const isAdminUser = (roles: UserRole[]) => {
  if (roles && roles?.includes('Pricing Admin')) {
    return true;
  }
  return false;
};

export default isAdminUser;
