import { PricingSheet } from "../types";

export const isEditingLocked = (
  pricingSheet: PricingSheet,
  userEmail: string,
) => {
  // No previous edits exists
  if (!pricingSheet.editedByUser) {
    return false;
  }

  // Always open for the previous editer
  if (pricingSheet.editedByUser === userEmail) {
    return false;
  }

  // Sheet is open if the sheet hasn't been edited for 1 minute
  if (
    pricingSheet?.editingOpenTimestamp &&
    (new Date().getTime() -
      new Date(pricingSheet?.editingOpenTimestamp).getTime()) /
      1000 >=
      60
  ) {
    return false;
  }
  return true;
};
