import { PricingSheetRow } from "./types";

export const sortByCatClass = (rows: PricingSheetRow[]) => {
  return rows.sort((a, b) => {
    const catClassComparison = Number(a.catClass) - Number(b.catClass);
    if (catClassComparison === 0) {
      return Number(a.offerItemId) - Number(b.offerItemId);
    } else {
      return catClassComparison;
    }
  });
};
