import {
  AgGridColumnView,
  CoefficientColumn,
  CoefficientType,
  CriticalEquipmentRowKeys,
  DayPriceCoefficientType,
  DayPricePercentCoefficientType,
  MonthPriceCoefficientType,
  MonthPricePercentCoefficientType,
  PercentCoefficientType,
  PriceCategory,
  TableSizeOption,
  SurplusEquipmentRowKeys,
} from "./types";
import { OfferState } from "./types/offers";

export const DEFAULT_TIMEOUT = 30000; // 30s
export const DIALOG_TRANSITION_DURATION = 200;
export const currencyMultiplier = 5000;

export const PRICE_FACTOR = 1000000;

export const DAYS_IN_MONTH = 30;

export const PRICING_TEAM_EDITING_RIGHTS_EMAIL =
  "hinnastojen.paivitys@ramirent.fi";

export const CRITICAL_EQUIPMENT_ROW_KEYS: Array<CriticalEquipmentRowKeys> = [
  "from",
  "minPriceDay",
  "minPriceMonth",
  "to",
];

export const SURPLUS_EQUIPMENT_ROW_KEYS: Array<SurplusEquipmentRowKeys> = [
  "surplusFrom",
  "changePercentage",
  "surplusTo",
];

export const PRICE_THRESHOLD_MIN = 4.9;
export const PRICE_THRESHOLD_MAX = 30.1;

export const TARGET_UTIL_OFFER_MARGIN = 0.25;

export const DAY_PRICE_COEFFICIENTS: Readonly<CoefficientColumn<"DAY">[]> = [
  {
    key: "dayPriceCoefficient1",
    industryPercentKey: "dayPricePercent1",
    name: "K1 (pv)",
  },
  {
    key: "dayPriceCoefficient2",
    industryPercentKey: "dayPricePercent2",
    name: "K2 (pv)",
  },
  {
    key: "dayPriceCoefficient3",
    industryPercentKey: "dayPricePercent3",
    name: "K3 (pv)",
  },
  {
    key: "dayPriceCoefficient4",
    industryPercentKey: "dayPricePercent4",
    name: "K4 (pv)",
  },
  {
    key: "dayPriceCoefficient5",
    industryPercentKey: "dayPricePercent5",
    name: "K5 (pv)",
  },
  {
    key: "dayPriceCoefficient6",
    industryPercentKey: "dayPricePercent6",
    name: "K6 (pv)",
  },
  {
    key: "dayPriceCoefficient7",
    industryPercentKey: "dayPricePercent7",
    name: "K7 (pv)",
  },
  {
    key: "dayPriceCoefficientList",
    industryPercentKey: "dayPricePercentList",
    name: "Listahinta (pv)",
  },
];

export const DAY_PRICE_COEFFICIENT_KEYS: Readonly<DayPriceCoefficientType[]> =
  DAY_PRICE_COEFFICIENTS.map(({ key }) => key);

export const DAY_PRICE_PERCENT_COEFFICIENT_KEYS: Readonly<
  DayPricePercentCoefficientType[]
> = DAY_PRICE_COEFFICIENTS.map(({ industryPercentKey }) => industryPercentKey);

export const MONTH_PRICE_COEFFICIENTS: Readonly<CoefficientColumn<"MONTH">[]> =
  [
    {
      key: "monthPriceCoefficient1",
      industryPercentKey: "monthPricePercent1",
      name: "K1 (kk)",
    },
    {
      key: "monthPriceCoefficient2",
      industryPercentKey: "monthPricePercent2",
      name: "K2 (kk)",
    },
    {
      key: "monthPriceCoefficient3",
      industryPercentKey: "monthPricePercent3",
      name: "K3 (kk)",
    },
    {
      key: "monthPriceCoefficient4",
      industryPercentKey: "monthPricePercent4",
      name: "K4 (kk)",
    },
    {
      key: "monthPriceCoefficient5",
      industryPercentKey: "monthPricePercent5",
      name: "K5 (kk)",
    },
    {
      key: "monthPriceCoefficient6",
      industryPercentKey: "monthPricePercent6",
      name: "K6 (kk)",
    },
    {
      key: "monthPriceCoefficient7",
      industryPercentKey: "monthPricePercent7",
      name: "K7 (kk)",
    },
    {
      key: "monthPriceCoefficientList",
      industryPercentKey: "monthPricePercentList",
      name: "Listahinta (kk)",
    },
  ];

export const MONTH_PRICE_COEFFICIENT_KEYS: Readonly<
  MonthPriceCoefficientType[]
> = MONTH_PRICE_COEFFICIENTS.map(({ key }) => key);

export const MONTH_PRICE_PERCENT_COEFFICIENT_KEYS: Readonly<
  MonthPricePercentCoefficientType[]
> = MONTH_PRICE_COEFFICIENTS.map(
  ({ industryPercentKey }) => industryPercentKey,
);

export const PRICE_COEFFICIENTS: Readonly<
  CoefficientColumn<"DAY" | "MONTH">[]
> = [
  // use slice to move list price to the end of the array
  ...DAY_PRICE_COEFFICIENTS.slice(0, -1),
  ...MONTH_PRICE_COEFFICIENTS.slice(0, -1),
  ...DAY_PRICE_COEFFICIENTS.slice(-1),
  ...MONTH_PRICE_COEFFICIENTS.slice(-1),
];

export const PRICE_COEFFICIENT_KEYS: Readonly<CoefficientType[]> =
  PRICE_COEFFICIENTS.map(({ key }) => key);

export const PRICE_PERCENTAGE_COEFFICIENT_KEYS: Readonly<
  PercentCoefficientType[]
> = PRICE_COEFFICIENTS.map(({ industryPercentKey }) => industryPercentKey);

export const PRICE_CATEGORIES: Record<PriceCategory, string> = {
  [PriceCategory.PriceCategory7]:
    "Hintakategoria 7 (Asiakkaan potentiaali 0-20k)",
  [PriceCategory.PriceCategory6]:
    "Hintakategoria 6 (Asiakkaan potentiaali 21-50k)",
  [PriceCategory.PriceCategory5]:
    "Hintakategoria 5 (Asiakkaan potentiaali 51-100k)",
  [PriceCategory.PriceCategory4]:
    "Hintakategoria 4 (Asiakkaan potentiaali 101k-300k)",
  [PriceCategory.PriceCategory3]:
    "Hintakategoria 3 (Asiakkaan potentiaali 301k-600k)",
  [PriceCategory.PriceCategory2]:
    "Hintakategoria 2 (Asiakkaan potentiaali 601k-1m)",
  [PriceCategory.PriceCategory1]:
    "Hintakategoria 1 (Asiakkaan potentiaali > 1m)",
};

export const ITEM_ROW_KEYS = [
  "rentalReadyPrice",
  "targetUtilRate",
  "depreciationPeriod",
  "includeInPricing",
  "pimProductGroup",
  "pimProductLine",
  "pimCategory",
  "pimPath",
  "belongsToCompactPreSelection",
  "belongsToLargePreSelection",
  "markedAsRemoved",
  "kitName",
  "avgRentalPeriod",
  "isRamigreen",
  ...PRICE_COEFFICIENT_KEYS,
  ...CRITICAL_EQUIPMENT_ROW_KEYS,
  ...SURPLUS_EQUIPMENT_ROW_KEYS,
];

export const ADMIN_COLUMNS = {
  [AgGridColumnView.ADMIN_COMMON]: "Yleinen",
  [AgGridColumnView.ADMIN_HIERARCHY]: "Hierarkia",
  [AgGridColumnView.ADMIN_DAY]: "Päivähintakertoimet",
  [AgGridColumnView.ADMIN_MONTH]: "Kuukausihintakertoimet",
  [AgGridColumnView.ADMIN_BOTH]: "Molemmat",
  [AgGridColumnView.CRITICAL_EQUIPMENT]: "Kriittinen kalusto",
  [AgGridColumnView.SURPLUS_EQUIPMENT]: "Ylitarjonta kalusto",
  [AgGridColumnView.ADMIN_INDUSTRY_DAY]: "Toimialamuutos% PV",
  [AgGridColumnView.ADMIN_INDUSTRY_MONTH]: "Toimialamuutos% KK",
  [AgGridColumnView.ADMIN_KIT_NAME_LIST]: "Nimikeluettelot",
} as const;

export const EXPORT_COLUMNS = {
  ...ADMIN_COLUMNS,
  PROPOSAL_DAY: "Ehdotushinta PV",
  PROPOSAL_MONTH: "Ehdotushinta KK",
} as const;

export const TABLE_SIZE_OPTIONS: TableSizeOption[] = [5, 10, 25, 100];

export const SUMMARY_COLORS = {
  warning: "#ef5350",
  warningBorder: "#f3817f",
  lightWarning: "rgb(255, 244, 229)",
  lightWarningBorder: "#ff9800",
  below70Border: "#fce4ec",
  success: "rgb(237, 247, 237)",
};

export const INCOMPLETE_OFFER_STATES: OfferState[] = [
  OfferState.Draft,
  OfferState.InternalRejection,
  OfferState.InternalApproval,
  OfferState.ReadyForSending,
];

export const CUSTOMER_RESPONDED_OFFER_STATES: OfferState[] = [
  OfferState.Ordered,
  OfferState.PartiallyOrdered,
  OfferState.AwaitingOrder,
  OfferState.Approved,
  OfferState.RequiresChanges,
  OfferState.Rejected,
];

export const MIRA_PRODUCT_GROUP = "Lisätyt MIRA tuotteet";
export const ALL_ITEMS_PRICING_SHEET_ID = 9000; // Pricing sheet that should contain price for every rentable item

// react-query queryKeys
export const ITEMS_QUERY_KEY = "items";
export const ITEM_PRICE_QUERY_KEY = "price";
export const OFFER_SHEET_QUERY_KEY = "offerSheet";
export const OFFER_APPROVAL_QUERY_KEY = "offerApproval";
export const OFFER_SHEET_PDF_KEY = "offerSheetPdf";
export const EXPORT_ITEMS_QUERY_KEY = ["export", "items"];
export const HIERARCHY_QUERY_KEY = "hierarchy";
export const MIRA_ITEM_SEARCH_QUERY_KEY = "miraSearch";
export const MIRA_CUSTOMER_BY_ID_QUERY_KEY = "miraCustomerById";
export const MIRA_RAMITURVA_PRICES_QUERY_KEY = "ramiturvaPriceSearch";
export const MIRA_RAMITURVA_NAMES_QUERY_KEY = "ramiturvaNameSearch";
