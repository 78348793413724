import { api } from '..';
import {
  GetMiraCustomerByIdRequestParams,
  GetMiraCustomerByIdResponseBody,
  GetMiraCustomersByIdsRequestParams,
  GetMiraCustomersByIdsResponseBody,
} from '../../../shared/types/customer';

/**
 * GET SINGLE CUSTOMER BY ID
 */
export const getMiraCustomerById = async (
  id?: GetMiraCustomerByIdRequestParams['id'],
): Promise<GetMiraCustomerByIdResponseBody> => {
  if (!id) {
    throw new Error(
      `Unable to fetch customer request: No ID was passed.`,
    );
  }
  const { data } = await api.get<GetMiraCustomerByIdResponseBody>(
    `/clients/mira/customer/${id}`,
  );
  return data;
};

/**
 * GET MULTIPLE CUSTOMERS BY ID
 */
export const getMiraCustomerByIds = async (
  ids?: GetMiraCustomersByIdsRequestParams['ids'],
): Promise<GetMiraCustomersByIdsResponseBody> => {
  if (!ids) {
    throw new Error(
      `Unable to fetch customer request: No IDs were passed.`,
    );
  }
  const { data } = await api.get<GetMiraCustomersByIdsResponseBody>(
    `/clients/mira/customers/${ids}`,
  );
  return data;
};
