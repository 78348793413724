import { DateRange, OfferItem } from "./types";

export const getSurplusRange = (item: OfferItem, rentalRange: DateRange) => {
  if (item.surplusEquipmentItem && item.surplusEquipmentItem.surplusFrom) {
    const start = new Date(item.surplusEquipmentItem.surplusFrom);
    const end = item.surplusEquipmentItem.surplusTo
      ? new Date(item.surplusEquipmentItem.surplusTo)
      : rentalRange.end;
    return { start, end };
  } else {
    return undefined;
  }
};
