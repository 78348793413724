import {
  PricingSheetRow,
  CoefficientType,
} from '../../../../../shared/types';
import {
  calculateDayPrice,
  calculateMonthPrice,
} from '../../../../../shared/calculatePrices';

import { PRICE_FACTOR } from '../../../../../shared/constants';

import { formatPriceWithEuroMark } from '../../../helpers/formatPriceWithEuroMark';

const getPrice = (
  coefficient: CoefficientType,
  row: PricingSheetRow,
) => {
  const price = coefficient.startsWith('day')
    ? calculateDayPrice(
        Number(row.rentalReadyPrice),
        Number(row.depreciationPeriod),
        Number(row.targetUtilRate) / 100,
        Number(row[coefficient]),
      )
    : calculateMonthPrice(
        Number(row.rentalReadyPrice),
        Number(row.depreciationPeriod),
        Number(row.targetUtilRate) / 100,
        Number(row[coefficient]),
      );

  const dividedPrice = price ? price / PRICE_FACTOR : price;

  return formatPriceWithEuroMark(dividedPrice ?? undefined);
};

interface CoefficientRendererProps {
  value: string;
  coefficient: CoefficientType;
  data: PricingSheetRow;
}
const CoefficientRenderer: React.FC<CoefficientRendererProps> = ({
  value,
  coefficient,
  data,
}) => {
  return (
    <>
      {value ?? 'Kerrointa ei löydy'}
      <span style={{ color: 'gray' }}>
        {coefficient && data && getPrice(coefficient, data)}
      </span>
    </>
  );
};

export default CoefficientRenderer;
