import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  formatRentalPotential,
  formatRevenue,
} from '../../components/Steps/InitialInformationStep';
import { processEnvs } from '../../config/processEnvs';
import useUserContext from '../../providers/User/UserProvider';
import {
  PricingSheet,
  PricingSheetState,
  RoundingBasis,
} from '../../../../shared/types';
import {
  formatDateTime,
  formatDateTolocaleDateString,
} from '../../utils/formatDateTimes';
import ArchiveDialog from './Dialogs/ArchiveDialog';
import RestoreDialog from './Dialogs/RestoreDialog';
import MiraStatusDialog from './Dialogs/MiraStatusDialog';
import RemoveDialog from './Dialogs/RemoveDialog';
import { currencyMultiplier } from '../../../../shared/constants';
import { formatPricingBasis } from '../../helpers/formatPricingBasis';

export const formatRoundingBasis = (roundingBasis: RoundingBasis) => {
  if (roundingBasis === 'TWODECIMALS') {
    return 'Kaksi desimaalia';
  } else if (roundingBasis === 'SIXDECIMALS') {
    return 'Kuusi desimaalia';
  } else if (roundingBasis === 'THREESIGNIFICANT') {
    return 'Kolme merkitsevää';
  } else {
    return 'Tuntematon';
  }
};

const TableRow = ({ header, value }: any) => {
  return (
    <tr>
      <td>
        <Typography
          variant="subtitle2"
          component="div"
          gutterBottom
          color="primary"
        >
          {header}
        </Typography>
      </td>
      <td>
        <Typography
          sx={{ ml: 1 }}
          variant="body2"
          component="div"
          gutterBottom
          color="text.primary"
        >
          {value}
        </Typography>
      </td>
    </tr>
  );
};

const priceSheetXlsxUrl = (id: string) =>
  `${processEnvs.BACKEND_URI}/pricingsheets/xlsx/${id}`;

const priceSheetXlsxKitUrl = (id: string) =>
  `${processEnvs.BACKEND_URI}/pricingsheets/xlsx-kit/${id}`;

const AdditionalInfo = ({
  pricingSheet,
  updateSheets,
}: {
  pricingSheet: PricingSheet;
  updateSheets: () => Promise<void>;
}) => {
  const [isOpenArchiveDialog, setIsOpenArchiveDialog] =
    React.useState(false);

  const [isOpenMiraStatusDialog, setIsMiraStatusDialog] =
    React.useState(false);

  const [isOpenRemoveDialog, setIsOpenRemoveDialog] =
    React.useState(false);

  const [isOpenRestoreDialog, setIsOpenRestoreDialog] =
    React.useState(false);

  const { userEmail, isAdmin } = useUserContext();

  const handleCloseArchiveDialog = () => {
    setIsOpenArchiveDialog(false);
  };

  const handleOpenArchiveDialog = () => {
    setIsOpenArchiveDialog(true);
  };
  const handleCloseRemoveDialog = () => {
    setIsOpenRemoveDialog(false);
  };

  const handleOpenRemoveDialog = () => {
    setIsOpenRemoveDialog(true);
  };

  const handleCloseRestoreDialog = () => {
    setIsOpenRestoreDialog(false);
  };

  const handleOpenRestoreDialog = () => {
    setIsOpenRestoreDialog(true);
  };

  const handleCloseMiraStatusDialog = () => {
    setIsMiraStatusDialog(false);
  };

  const handleOpenMiraStatusDialog = () => {
    setIsMiraStatusDialog(true);
  };

  const onClickDownloadExportFile = () => {
    window.open(priceSheetXlsxUrl(pricingSheet.id));
  };

  const onClickDownloadKitsExportFile = () => {
    window.open(priceSheetXlsxKitUrl(pricingSheet.id));
  };

  const isArchiveEnabled = () => {
    return (
      (isAdmin || pricingSheet.userEmail === userEmail) &&
      (pricingSheet.state === PricingSheetState.Draft ||
        pricingSheet.state === PricingSheetState.WarningDraft ||
        pricingSheet.state === PricingSheetState.OkDraft ||
        pricingSheet.state === PricingSheetState.Ready ||
        pricingSheet.state === PricingSheetState.Approved ||
        pricingSheet.state === PricingSheetState.Pending ||
        (pricingSheet.state === PricingSheetState.InUse &&
          pricingSheet.expired))
    );
  };

  const isRemoveEnabled = () => {
    return (
      (isAdmin || pricingSheet.userEmail === userEmail) &&
      (pricingSheet.state === PricingSheetState.Draft ||
        pricingSheet.state === PricingSheetState.WarningDraft ||
        pricingSheet.state === PricingSheetState.OkDraft)
    );
  };

  const formatExpiration = () => {
    const from = pricingSheet?.from
      ? formatDateTolocaleDateString(pricingSheet?.from)
      : null;
    const to = pricingSheet?.to
      ? formatDateTolocaleDateString(pricingSheet?.to)
      : null;
    return `${from} - ${to}`;
  };

  const isMiraStatusDisabled =
    !!pricingSheet.archivedAt ||
    (pricingSheet.state !== PricingSheetState.Ready &&
      pricingSheet.state !== PricingSheetState.InUse);

  return (
    <Grid
      sx={{ pb: 1, pt: 1 }}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <ArchiveDialog
        pricingSheet={pricingSheet}
        updateSheets={updateSheets}
        isOpenArchiveDialog={isOpenArchiveDialog}
        handleCloseArchiveDialog={handleCloseArchiveDialog}
      />
      <RestoreDialog
        pricingSheet={pricingSheet}
        updateSheets={updateSheets}
        isOpenRestoreDialog={isOpenRestoreDialog}
        handleOpenRestoreDialog={handleOpenRestoreDialog}
        handleCloseRestoreDialog={handleCloseRestoreDialog}
      />
      <RemoveDialog
        pricingSheet={pricingSheet}
        updateSheets={updateSheets}
        isOpenRemoveDialog={isOpenRemoveDialog}
        handleCloseRemoveDialog={handleCloseRemoveDialog}
      />
      <MiraStatusDialog
        isOpenMiraStatusDialog={isOpenMiraStatusDialog}
        handleCloseMiraStatusDialog={handleCloseMiraStatusDialog}
        pricingSheet={pricingSheet}
        updateSheets={updateSheets}
      />
      <table>
        <tbody>
          <TableRow
            header={'Asiakas'}
            value={pricingSheet?.customerName ?? '-'}
          />
          <TableRow
            header={'Asiakas Id'}
            value={pricingSheet?.customerId ?? '-'}
          />
          <TableRow
            header="Hinnaston taso"
            value={pricingSheet?.level ?? '-'}
          />
          <TableRow
            header="Hinnaston tyyppi"
            value={
              pricingSheet?.pricingBasis
                ? formatPricingBasis(pricingSheet.pricingBasis)
                : '-'
            }
          />
          <TableRow
            header="Työmaa"
            value={pricingSheet?.site ?? '-'}
          />
          <TableRow header="Voimassa" value={formatExpiration()} />
          <TableRow
            header="Pyöristys viennissä"
            value={
              pricingSheet?.roundingBasisSheet
                ? formatRoundingBasis(pricingSheet.roundingBasisSheet)
                : '-'
            }
          />
          <TableRow
            header="Pyöristys tulosteella"
            value={
              pricingSheet.roundingBasisPdf
                ? formatRoundingBasis(pricingSheet.roundingBasisPdf)
                : '-'
            }
          />
        </tbody>
      </table>
      <table style={{ marginLeft: 36 }}>
        <tbody>
          <TableRow
            header="Toimiala"
            value={pricingSheet?.industry ?? '-'}
          />
          <TableRow
            header="Liikevaihto"
            value={
              pricingSheet?.revenue
                ? formatRevenue(
                    (pricingSheet?.revenue as number) /
                      currencyMultiplier,
                  )
                : '-'
            }
          />
          <TableRow
            header="Vuokrapotentiaali"
            value={
              pricingSheet?.potentialBottom &&
              pricingSheet?.potentialTop
                ? formatRentalPotential(
                    (pricingSheet?.potentialBottom as number) /
                      currencyMultiplier,
                    (pricingSheet?.potentialTop as number) /
                      currencyMultiplier,
                  )
                : ''
            }
          />
          <TableRow
            header="Sitoutuminen"
            value={
              pricingSheet?.commitment
                ? `${pricingSheet?.commitment} %`
                : '-'
            }
          />
          <TableRow
            header="Alue"
            value={pricingSheet?.region ?? '-'}
          />
          <TableRow
            header="Toimii valtakunnallisesti"
            value={pricingSheet?.nationWide ? 'Kyllä' : 'Ei'}
          />
          <TableRow
            header="Alueellisesti merkittävä kohde"
            value={
              pricingSheet?.regionallySignificant ? 'Kyllä' : 'Ei'
            }
          />
          <TableRow
            header="Strategisesti merkittävä kohde"
            value={
              pricingSheet?.strategicallySignificant ? 'Kyllä' : 'Ei'
            }
          />
        </tbody>
      </table>
      <Grid alignItems="flex-start" columnSpacing={2}>
        <Alert style={{ minWidth: 300 }} severity="info" icon={false}>
          {`Hinnasto luotu: ${
            pricingSheet?.createdAt
              ? formatDateTime(pricingSheet.createdAt)
              : '-'
          }`}
          <br />
          {pricingSheet?.archivedAt
            ? `Hinnasto arkistoitu: ${
                pricingSheet?.archivedAt
                  ? formatDateTime(pricingSheet.archivedAt)
                  : '-'
              }
        `
            : `Hinnasto päivitetty: ${
                pricingSheet?.updatedAt
                  ? formatDateTime(pricingSheet.updatedAt)
                  : '-'
              }`}
          <br />
          <Grid
            sx={{ pb: 1, pt: 1 }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {!pricingSheet.archivedAt ? (
              <Button
                size="small"
                variant="outlined"
                disabled={!isArchiveEnabled()}
                color="error"
                onClick={handleOpenArchiveDialog}
              >
                Arkistoi
              </Button>
            ) : (
              <Button
                size="small"
                variant="outlined"
                disabled={!isArchiveEnabled()}
                color="primary"
                onClick={handleOpenRestoreDialog}
              >
                Palauta hinnasto
              </Button>
            )}
            {(pricingSheet.state === PricingSheetState.WarningDraft ||
              pricingSheet.state === PricingSheetState.OkDraft) && (
              <Button
                size="small"
                variant="outlined"
                disabled={!isRemoveEnabled()}
                color="error"
                onClick={handleOpenRemoveDialog}
              >
                Poista
              </Button>
            )}
          </Grid>
          <Divider />
          <Box sx={{ mt: 2 }}>
            {`Hinnasto viety Miraan: ${
              pricingSheet?.state === PricingSheetState.InUse
                ? 'Kyllä'
                : 'Ei'
            }`}
            <br />
            {`Hinnaston MiraId: ${
              pricingSheet?.miraInfo?.miraId ?? '-'
            }`}
            <br />
            {`Hinnasto liitetty asiakkaisiin: ${
              pricingSheet?.miraInfo?.customerIds ?? '-'
            }`}
            {isAdmin && (
              <>
                <Box sx={{ mt: 1 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={isMiraStatusDisabled}
                    color="primary"
                    onClick={handleOpenMiraStatusDialog}
                  >
                    Mira-status
                  </Button>
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Button
                    size="small"
                    onClick={() => onClickDownloadExportFile()}
                  >
                    Lataa tuotelista
                  </Button>
                  <Button
                    size="small"
                    onClick={() => onClickDownloadKitsExportFile()}
                  >
                    Lataa nimikeluettelot
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Alert>
      </Grid>
    </Grid>
  );
};

export default AdditionalInfo;
