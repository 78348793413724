import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { updateOfferApprovalRequest } from '../services/offerSheets';
import {
  DatabaseOfferApprovalRequestWithOffer,
  UpdateOfferApprovalRequestParams,
  UpdateOfferApprovalResponseBody,
} from '../../../shared/types/offers';
import { AxiosError } from 'axios';
import { OFFER_APPROVAL_QUERY_KEY } from '../../../shared/constants';
import { produce } from 'immer';

type DataResponseType = UpdateOfferApprovalResponseBody;
type ErrorType = AxiosError;
type VariablesType = Parameters<typeof updateOfferApprovalRequest>[0];

type UseUpdateOfferApprovalRequestParams = Omit<
  UseMutationOptions<DataResponseType, ErrorType, VariablesType>,
  'mutationFn'
>;

// updates the offer approval request query cache
export const updateOfferApprovalRequestCache = (
  queryClient: QueryClient,
  data: DatabaseOfferApprovalRequestWithOffer,
  variables: UpdateOfferApprovalRequestParams,
) => {
  queryClient.setQueryData<DatabaseOfferApprovalRequestWithOffer[]>(
    [OFFER_APPROVAL_QUERY_KEY],
    (oldData) =>
      produce(oldData, (updatedData) => {
        if (updatedData) {
          const updatedItemIndex = updatedData.findIndex(
            (item) => item.id === data.id,
          );
          if (updatedItemIndex !== -1) {
            updatedData[updatedItemIndex] = data;
          }
        }
      }),
  );
  // update single approval request cache
  queryClient.setQueryData(
    [OFFER_APPROVAL_QUERY_KEY, `${variables.approvalId}`],
    data,
  );
};

export const useUpdateOfferApprovalRequest = (
  options: UseUpdateOfferApprovalRequestParams = {},
) => {
  const queryClient = useQueryClient();
  return useMutation<DataResponseType, ErrorType, VariablesType>({
    ...options,
    mutationFn: updateOfferApprovalRequest,
    onSuccess: (data, variables, context) => {
      updateOfferApprovalRequestCache(queryClient, data, variables);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
