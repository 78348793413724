import React, { ChangeEvent } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface GenericCheckBoxFilterProps {
  checked: boolean;
  disabled?: boolean;
  handleChecked: (checked: boolean) => void;
  label: string;
  labelStyle?: object;
}
const GenericCheckBoxFilter = ({
  checked,
  disabled,
  handleChecked,
  label,
  labelStyle = {},
}: GenericCheckBoxFilterProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChecked(event.target.checked);
  };
  return (
    <>
      <FormControl component="fieldset" disabled={disabled}>
        <FormControlLabel
          style={labelStyle}
          value="start"
          control={
            <Checkbox checked={checked} onChange={handleChange} />
          }
          label={label}
          labelPlacement="end"
        />
      </FormControl>
    </>
  );
};

export default GenericCheckBoxFilter;
