import { PricingSheetRow } from '../../../../../shared/types';
import { ValueGetterParams } from 'ag-grid-community';
import { isSalesItem } from '../../../../../shared/helpers/isSalesItem';

const catClassValueGetter = (
  params: ValueGetterParams<PricingSheetRow>,
) => {
  if (!params.data) return null;
  const { catClass, name, pimDisplayName, type } = params.data;
  return isSalesItem(type)
    ? `${catClass} ${pimDisplayName}`
    : `${catClass} ${name}`;
};

export default catClassValueGetter;
