import React from 'react';

import Box from '@mui/material/Box';
import { Alert, AlertTitle } from '@mui/material';

interface CommentPresenterProps {
  header: string;
  comment: string;
}
const CommentPresenter: React.FC<CommentPresenterProps> = ({
  comment,
  header,
}) => {
  return (
    <>
      <Alert severity="info">
        <AlertTitle>{header}</AlertTitle>
        <Box sx={{ fontStyle: 'italic' }}>
          <blockquote>{comment}</blockquote>
        </Box>
      </Alert>
    </>
  );
};

export default CommentPresenter;
