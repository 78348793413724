import {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';

import {
  ExternalPricingSheetItem,
  Nullable,
  PricingSheet,
  PricingSheetItemResponse,
  SelectSheetOption,
} from '../../../../shared/types';

import DoneIcon from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import { createTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Select, { OnChangeValue, ActionMeta } from 'react-select';

import { getUniquesByKey } from '../../utils/getUniques';
import { TextField, Typography } from '@mui/material';

import DefaultTooltip from '../Tooltips/DefaultTooltip';
import {
  getPricingSheetItemsById,
  getPricingSheetItemsMira,
} from '../../services/pricingSheetItems';
import { getPricingSheetMira } from '../../services/pricingSheets';
import ExternalSheetOption from './ExternalSheetOption';

export type ExternalPricingSheet = {
  sheetName: string;
  sheetCode: string;
  LRTBasePriceGroupId: string;
  pricingPriority: number;
  dataAreaId: string;
};

interface SearchExternalSheetProps {
  tooltipTitle: string;
  selectPlaceholder: string;
  label?: string;
  handleClear?: () => void;
  handleExternalItemsResponse: (
    externalPricingSheetItems: ExternalPricingSheetItem[],
  ) => void;
  handleClearComparison?: () => void;
  pricingSheetId?: string;
  comparisonPricingSheets?: PricingSheet[];
  externalPricingSheetId: Nullable<number>;
  setExternalPricingSheetId: (value?: number) => void;
  handleExternalPricingSheetResponse?: (
    externalPricingSheet: ExternalPricingSheet,
  ) => void;
  handlePricingSheetItemsResponse?: (
    pricingSheetItems: PricingSheetItemResponse[],
  ) => void;
  onSelectionChange?: (newValue: SelectSheetOption) => void;
  miraSheetIdDefault?: string;
  externalSheetNameDefault?: string;
  selectedOptionDefault?: SelectSheetOption;
}

const isOptionMiraSheet = (option?: SelectSheetOption) => {
  if (option) {
    return option.value === 'isMiraSheet';
  }
  return false;
};

const SearchExternalSheet = forwardRef<any, SearchExternalSheetProps>(
  (
    {
      tooltipTitle,
      handleExternalItemsResponse,
      handleClear,
      handleClearComparison,
      pricingSheetId,
      comparisonPricingSheets,
      externalPricingSheetId,
      setExternalPricingSheetId,
      selectPlaceholder,
      handleExternalPricingSheetResponse,
      onSelectionChange,
      handlePricingSheetItemsResponse,
      miraSheetIdDefault,
      externalSheetNameDefault,
      selectedOptionDefault,
      label,
    },
    ref,
  ) => {
    const theme = createTheme();

    const selectRef: any = useRef();

    const [selectedOption, setSelectedOption] = useState<
      SelectSheetOption | undefined
    >(
      externalPricingSheetId && selectedOptionDefault
        ? selectedOptionDefault
        : undefined,
    );
    const [isMiraSheet, setIsMiraSheet] = useState(
      externalPricingSheetId &&
        isOptionMiraSheet(selectedOptionDefault)
        ? true
        : false,
    );
    const [miraSheetId, setMiraSheetId] = useState(
      miraSheetIdDefault ?? '',
    );
    const [miraSuccess, setMiraSuccess] = useState(
      externalSheetNameDefault ? true : false,
    );

    const [externalPricingSheetName, setExternalPricingSheetName] =
      useState<string | undefined>(
        isOptionMiraSheet(selectedOptionDefault) &&
          externalSheetNameDefault
          ? externalSheetNameDefault
          : undefined,
      );

    const [
      isLoadingExternalPricingSheetItems,
      setIsLoadingExternalPricingSheetItems,
    ] = useState(false);

    const [
      externalPricingSheetItemsError,
      setExternalPricingSheetItemsError,
    ] = useState(false);

    const [
      isLoadingExternalPricingSheet,
      setIsLoadingExternalPricingSheet,
    ] = useState(false);
    const [externalPricingSheetError, setExternalPricingSheetError] =
      useState(false);

    const fetchExternalSheet = async (id: string) => {
      try {
        setExternalPricingSheetError(false);
        setIsLoadingExternalPricingSheet(true);
        const response = await getPricingSheetMira(id);
        setExternalPricingSheetName(response.sheetName);
        handleExternalPricingSheetResponse &&
          handleExternalPricingSheetResponse(response);
      } catch (error) {
        setExternalPricingSheetError(true);
      } finally {
        setIsLoadingExternalPricingSheet(false);
      }
    };
    const fetchExternalPricingSheetItemsMira = async (id: string) => {
      try {
        setExternalPricingSheetItemsError(false);
        setIsLoadingExternalPricingSheetItems(true);
        const response = await getPricingSheetItemsMira(id);
        handleExternalItemsResponse(response);
        setMiraSuccess(true);
      } catch (error) {
        setExternalPricingSheetItemsError(true);
      } finally {
        setIsLoadingExternalPricingSheetItems(false);
      }
    };

    const fetchExternalPricingSheetItems = async (id: string) => {
      try {
        setIsLoadingExternalPricingSheetItems(true);
        const response = await getPricingSheetItemsById(id);
        handlePricingSheetItemsResponse &&
          handlePricingSheetItemsResponse(response);
      } catch (error) {
        setExternalPricingSheetItemsError(true);
      } finally {
        setIsLoadingExternalPricingSheetItems(false);
      }
    };

    const clearAllMiraData = () => {
      setMiraSheetId('');
      setIsMiraSheet(false);
      setMiraSuccess(false);
    };

    const clearAllExternalData = () => {
      setExternalPricingSheetId &&
        setExternalPricingSheetId(undefined);
      setExternalPricingSheetName(undefined);
    };

    const handleChange = (
      newValue: OnChangeValue<SelectSheetOption, false>,
      actionMeta: ActionMeta<SelectSheetOption>,
    ) => {
      clearAllMiraData();
      clearAllExternalData();
      setSelectedOption(undefined);
      if (actionMeta.action === 'clear') {
        handleClear && handleClear();
      }

      if (!newValue) {
        return;
      }

      onSelectionChange && onSelectionChange(newValue);

      if (newValue.value === 'isMiraSheet') {
        setIsMiraSheet(true);
      } else {
        fetchExternalPricingSheetItems(String(newValue?.id));
        setExternalPricingSheetId &&
          setExternalPricingSheetId(newValue?.id);
        setSelectedOption(newValue);
      }
    };

    const handleMiraSheetIdChange = (event: any) => {
      setMiraSheetId(event.target.value);
    };

    const handleFetchMira = () => {
      if (Number(miraSheetId) !== externalPricingSheetId) {
        setMiraSuccess(false);
        handleClearComparison && handleClearComparison();
        setExternalPricingSheetId &&
          setExternalPricingSheetId(Number(miraSheetId));
        fetchExternalPricingSheetItemsMira(miraSheetId);
        fetchExternalSheet(miraSheetId);
      }
    };

    const options: SelectSheetOption[] = comparisonPricingSheets
      ? getUniquesByKey(
          comparisonPricingSheets.map(
            (pricingSheet: PricingSheet) => {
              return {
                id: pricingSheet.id,
                value: pricingSheet.name,
                label: pricingSheet.name,
                state: pricingSheet.state,
                customerName: pricingSheet.customerName,
                from: pricingSheet.from,
                to: pricingSheet.to,
              };
            },
          ),
          'value',
        )
      : [];

    const mergedOptions: SelectSheetOption[] = [
      {
        id: 666,
        value: 'isMiraSheet',
        label: 'Hae mirasta',
      },
    ].concat(
      options
        ? options.filter(
            (sheet) => sheet.id !== Number(pricingSheetId),
          )
        : [],
    );

    useImperativeHandle(ref, () => {
      return {
        resetState() {
          setSelectedOption(undefined);
          selectRef?.current?.clearValue();
        },
      };
    });

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        width="auto"
      >
        <DefaultTooltip placement="top" title={tooltipTitle}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
            }}
          >
            {label && <Typography fontSize={14}>{label}</Typography>}
            <Select
              ref={selectRef}
              value={selectedOption}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: 400,
                  zIndex: 999,
                }),
              }}
              formatOptionLabel={ExternalSheetOption}
              isClearable={true}
              options={mergedOptions}
              placeholder={!selectedOption ? selectPlaceholder : null}
              onChange={handleChange}
            />
            {externalPricingSheetName && miraSuccess ? (
              <span
                style={{
                  fontSize: 12,
                  paddingTop: 2,
                  paddingLeft: 2,
                }}
              >
                {externalPricingSheetName}
              </span>
            ) : null}
          </Box>
        </DefaultTooltip>
        <Box sx={{ ml: 2, mt: -1 }}>
          {isMiraSheet && (
            <TextField
              onBlur={handleFetchMira}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  handleFetchMira();
                }
              }}
              style={{
                width: 100,
              }}
              onChange={handleMiraSheetIdChange}
              value={miraSheetId}
              size="small"
              variant="standard"
              label="Hinnaston Id"
            />
          )}

          {!!externalPricingSheetId &&
            isLoadingExternalPricingSheetItems && (
              <CircularProgress size={18} sx={{ mt: 2 }} />
            )}
          {!isLoadingExternalPricingSheet &&
            !isLoadingExternalPricingSheetItems &&
            !!externalPricingSheetId &&
            (externalPricingSheetItemsError ||
              (externalPricingSheetError && isMiraSheet)) && (
              <Close
                style={{
                  color: theme.palette.error.main,
                  marginTop: 16,
                }}
              />
            )}
          {!isLoadingExternalPricingSheet &&
            isMiraSheet &&
            miraSuccess && (
              <>
                <DoneIcon
                  style={{
                    color: theme.palette.success.main,
                    marginTop: 16,
                  }}
                />
              </>
            )}
        </Box>
      </Grid>
    );
  },
);

export default SearchExternalSheet;
