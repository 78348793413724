import React, { ReactNode } from 'react';

import Box from '@mui/material/Box';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

type SummaryListItem = {
  value: string;
  label: string;
  icon: ReactNode;
};

type SummaryListProps = {
  grid?: boolean;
  items: SummaryListItem[];
};

const SummaryList = ({
  grid = false,
  items = [],
  ...props
}: SummaryListProps & ListProps) => {
  return (
    <Box>
      <List
        {...props}
        sx={{
          bgcolor: 'background.paper',
          ...(grid && {
            display: 'grid',
            gridTemplateColumns:
              'repeat(auto-fill, minmax(300px, 1fr))',
          }),
          width: '100%',
          ...props.sx,
        }}
      >
        {items.map(({ label, icon, value }) => {
          return (
            <React.Fragment key={label}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>{icon}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{ color: 'primary' }}
                  secondaryTypographyProps={{ color: 'text.primary' }}
                  primary={label}
                  secondary={value}
                />
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
};

export default SummaryList;
